import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchItemV2Failed, fetchItemV2Success, updateItemV2Failed, updateItemV2Success } from '../actions/itemV2Actions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import { uploadItemFile } from '../utils/networkutil';

const baseUrl = getBaseUrl();

const getUpdatedMediaItems = (mediaItems, videoURL) => {
  let updatedMediaItems = [...mediaItems]
  let noVideo = true

  updatedMediaItems?.forEach((m, i) => {
    if (m.type === 2) {
      updatedMediaItems[i].content = `https://d10y2mtytldztj.cloudfront.net${videoURL}`
      noVideo = false
    }
  })

  if (noVideo) {
    updatedMediaItems = [
      ...updatedMediaItems,
      {
        content: `https://d10y2mtytldztj.cloudfront.net${videoURL}`,
        position: 1,
        type: 2
      }
    ]
  }

  return updatedMediaItems
}

function* fetchItemV2Async(action) {
  const { id } = action?.data;

  const response = yield call(
    request,
    `${baseUrl}/api/v2/item/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.err) {
    yield put(fetchItemV2Failed(response.err.toString()));
  } else {
    console.log('response.data', response.data);
    yield put(fetchItemV2Success(response.data));
  }
}

function* updateItemV2Async(action) {
  const {
    id,
    item,
    description,
    title,
    categoryId,
    media,
    priceAmount,
    priceCurrencyId,
    brandId,
    itemVideo,
  } = action.data

  let body = {}

  const updatedItem = {
    description: description || item.description,
    title: title || item.title,
    categoryId: categoryId || item.category_id,
    media: media || item.media,
    priceAmount:  priceAmount || item.price_amount,
    priceCurrencyId: priceCurrencyId || item.price_currency_id,
    brandId: brandId || item.brand_id 
  };

  if (itemVideo) {
    const response = yield call(uploadItemFile, 'item', id, itemVideo);
    if (response.err) {
      yield put(updateItemV2Failed(response.err.toString()));
      return;
    }

    let videoURL = response.data.fileUrl;
    videoURL = videoURL.split('shupperz-main-v2')[1]


    updatedItem.media.mediaItems = getUpdatedMediaItems(updatedItem.media.mediaItems, videoURL)
    
  }
  
  updatedItem.media = JSON.stringify(updatedItem.media)
  body.item = updatedItem;

  const response = yield call(
    request,
    `${baseUrl}/api/v2/item/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateItemV2Failed(response.err.toString()));
  } else {
    yield put(updateItemV2Success({ id }));
  }
}

function* updateItemV2() {
  yield takeLatest([`${types.UPDATE_ITEM_V2}`], updateItemV2Async);
}

function* fetchItemV2() {
  yield takeLatest([`${types.FETCH_ITEM_V2}`, `${types.UPDATE_ITEM_V2_SUCCESS}`], fetchItemV2Async);
}

export default function* itemv2saga() {
  yield all([
    updateItemV2(),
    fetchItemV2()
  ]);
}
