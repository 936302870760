/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../actionTypes';

export const fetchCompleted = query => ({
  type: types.LOGISTICS.FETCH_COMPLETED,
  query,
});

export const fetchMoreCompleted = () => ({
  type: types.LOGISTICS.FETCH_MORE_COMPLETED,
});

export const fetchCompletedSuccess = data => ({
  type: types.LOGISTICS.FETCH_COMPLETED_SUCCESS,
  data,
});

export const fetchCompletedFailed = reason => ({
  type: types.LOGISTICS.FETCH_COMPLETED_FAILED,
  reason,
});

export const makeRefund = id => ({
  type: types.LOGISTICS.MAKE_REFUND,
  id,
});

export const makeRefundSuccess = () => ({
  type: types.LOGISTICS.MAKE_REFUND_SUCCESS,
});

export const makeRefundFailed = reason => ({
  type: types.LOGISTICS.MAKE_REFUND_FAILED,
  reason,
});

export const fetchCompletedSortChanged = (column, direction) => ({
  type: types.LOGISTICS.FETCH_COMPLETED_SORT_CHANGED,
  column,
  direction,
});

export const fetchCompletedDateRangeChanges = (startDate, endDate) => ({
  type: types.LOGISTICS.FETCH_COMPLETED_DATERANGE_CHANGED,
  startDate,
  endDate,
});

export const downloadCsv = () => ({
	type: types.LOGISTICS.FETCH_COMPLETED,
	csv: true,
});


export const addStatusToComplete = id => ({
    type : types.LOGISTICS.ADD_SHIPPING_STATUS,
    id,
})

export const addStatusToCompletedSuccess = (data) => ({
    type : types.LOGISTICS.ADD_SHIPPING_STATUS_SUCCESS,
    data,
})

export const addStatusToCompletedError = (reason) => ({
    type : types.LOGISTICS.ADD_SHIPPING_STATUS_ERROR,
    reason,
})