import types from '../actions/actionTypes';

const initialState = {
  countries: [],
  zones: [],
  isLoading: false,
};

export default function countriesReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COUNTRIES:
      return Object.assign({}, state, {
        isLoading: true,
        countries: null,
      });
    case types.FETCH_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        countries: action.data,
      });
    case types.FETCH_COUNTRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_ZONES:
      return Object.assign({}, state, {
        isLoading: true,
        zones: null,
      });
    case types.FETCH_ZONES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        zones: action.data,
      });
    case types.FETCH_ZONES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
      });

    default:
      return state;
  }
}
