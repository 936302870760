/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import SetupIcon from 'material-ui/svg-icons/action/build';
import IconButton from 'material-ui/IconButton';
import { RaisedButton } from 'material-ui';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import NoPermission from '../../../components/nopermission';
import { getPermission } from '../../../utils/cookies';
import { decodePermissions } from '../../../utils/permissionUtil';
import PaginatedGrid from '../../../components/paginatedgrid';
import * as refundedActions from '../../../actions/logistics/refundedactions';
import SearchField from '../../../components/searchfield';
import SelectDialog from '../../../components/SelectDialog';
import { changePaymentStatus } from '../../../actions/logistics/singlePaymentActions';
import getBaseUrl from '../../../utils/envSetup';
import { tableData } from '../../../utils/tableData';

const baseUrl = getBaseUrl();

class Refunded extends Component {
    constructor(props) {
        super(props);
        const permission = decodePermissions(getPermission());
        this.state = {
            permission,
            changeStatus: false,
            itemToChangeStatus: null,
            itemToChangeStatusKey: null,
        };
    }

    componentDidMount() {
        if (this.state.permission.seeRefunded) {
            this.props.fetchRefunded();
        }
    }

    onDateRangeSet(startDate, endDate) {
        this.props.fetchRefundedDateRangeChanges(startDate, endDate);
    }

    doSearch(query) {
        this.props.fetchRefunded(query);
    }

    openStatusDialog = (itemToChangeStatus, itemToChangeStatusKey) => () => {
        this.setState({
            changeStatus: true,
            itemToChangeStatus,
            itemToChangeStatusKey,
        });
    }

    closeStatusDialog = () => {
        this.setState({
            changeStatus: false,
        });
    }

    render() {
        const { logisticsRefunded: { columnWidths, headerData } } = tableData
        const {
            onOpenDrawer, fetchMoreRefunded, refundedList, hasMore, isLoading, totalCount, sort,
            fetchRefundedSortChanged, startDate, endDate, changePaymentStatus
        } = this.props;
        const { permission, itemToChangeStatus, itemToChangeStatusKey, changeStatus } = this.state;
        const tableHeight = window.innerHeight - 217;
        return permission.seeCanceled ?
            <div className="logistics-container">
                <SelectDialog
                    items={['cancelled', 'fulfilled', 'pending', 'refunded', 'rejected', 'shipping']}
                    type="status"
                    handleChange={changePaymentStatus}
                    value="refunded"
                    payment={itemToChangeStatus}
                    position={itemToChangeStatusKey}
                    display={changeStatus}
                    close={this.closeStatusDialog}
                />
                <div className="App-header">
                    <IconButton onClick={onOpenDrawer}>
                        <NavigationMenu color="white" />
                    </IconButton>
                    <div className="header-search-area">
                        <SearchField onRequestSearch={query => this.doSearch(query)} />
                    </div>
                    <div className="logistics-date-range-picker-cont">
                        <DateRangePicker
                            drops="down"
                            opens="center"
                            startDate={startDate
                                ? moment(startDate)
                                : moment('01/01/2017', 'DD/MM/YYYY')
                            }
                            endDate={endDate ? moment(endDate) : moment()}
                            timePicker
                            timePicker24Hour
                            showDropdowns
                            onApply={(ev, picker) => {
                                this.onDateRangeSet(
                                    picker.startDate.toDate().toJSON(),
                                    picker.endDate.toDate().toJSON(),
                                );
                            }}
                            ranges={{
                                'All Times': [moment('01/01/2017', 'DD/MM/YYYY'), moment()],
                                'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                'Month to date': [moment().startOf('month'), moment()],
                            }}
                        >
                            <RaisedButton
                                label={startDate && endDate
                                    ? `${moment(startDate).format('lll')}
										- ${moment(endDate).format('lll')}`
                                    : 'Pick date range'}
                                primary
                            />
                        </DateRangePicker>
                    </div>
                    <a href={baseUrl + '/api/logistics/refunded?csv=true' + (startDate ?
                        `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '')} download >
                        <RaisedButton
                            label="Download CSV"
                            primary
                        />
                    </a>
                </div>

                <div className="logistics-collections-tabs">
                    <Link to="/logistics/pending" className="logistics-collections-tab">Pending</Link>
                    <Link to="/logistics/rejected" className="logistics-collections-tab">Rejected</Link>
                    <Link to="/logistics/canceled" className="logistics-collections-tab">Canceled</Link>
                    <Link to="/logistics/completed" className="logistics-collections-tab">Completed</Link>
                    <Link to="/logistics/refunded" className="logistics-collections-tab logistics-collections-tab-active">Refunded</Link>
                    <Link to="/logistics/shipping" className="logistics-collections-tab">Shipping</Link>
                </div>

                <PaginatedGrid
                    data={refundedList}
                    tableHeight={tableHeight}
                    columnWidths={columnWidths}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    header={headerData}
                    totalResults={totalCount}
                    sortableCols={headerData[0].id}
                    currentSort={sort}
                    onSort={(column, direction) => fetchRefundedSortChanged(column, direction)}
                    items={refundedList.map((item, key) => {
                        const dateTime = moment(item.created).format("YYYY-MM-DD HH:mm:ss");

                        return [
                            <Link to={`/logistics/payment/${item._id}`}><span>{dateTime}</span></Link>,
                            <span>{item.user && item.user.displayName}</span>,
                            <span>{item.user && item.user.email}</span>,
                            <span>{item.shupper && item.shupper.displayName}</span>,
                            <span>{item.totalPrice}</span>,
                            <span>{item.tip || 0}</span>,
                            <span>{item.tip ? item.totalPrice + item.tip : item.totalPrice}</span>,
                            <span>{item.paypalPaymentId}</span>,
                            <span>
                                <a href={`https://www.paypal.com/activity/payment/${item.paypalTransactionId}`} target="_blank">
                                    {item.paypalTransactionId}
                                </a>
                            </span>,
                            <span>{item._id}</span>,
                            <span>{item.status}</span>,
                            <span>
                                {
                                    permission.changePaymentStatus ?
                                        <IconButton onClick={this.openStatusDialog(item, key)}>
                                            <SetupIcon color="black" />
                                        </IconButton>
                                        : null
                                }
                            </span>
                        ]
                    })}
                    onLoadMore={fetchMoreRefunded}
                />
            </div>
            :
            <NoPermission onOpenDrawer={onOpenDrawer} />;
    }
}

Refunded.propTypes = {
    onOpenDrawer: PropTypes.func.isRequired,
    refundedList: PropTypes.array.isRequired,
    hasMore: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    fetchRefunded: PropTypes.func.isRequired,
    fetchMoreRefunded: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    fetchRefundedSortChanged: PropTypes.func.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    fetchRefundedDateRangeChanges: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    refundedList: state.logistics.refunded.logistics,
    hasMore: state.logistics.refunded.hasMore,
    isLoading: state.logistics.refunded.isLoading,
    totalCount: state.logistics.refunded.totalCount,
    sort: state.logistics.refunded.sort,
    startDate: state.logistics.refunded.startDate,
    endDate: state.logistics.refunded.endDate,
});

const mapDispatchToProps = dispatch => ({
    fetchRefunded: query => dispatch(refundedActions.fetchRefunded(query)),
    fetchMoreRefunded: () => dispatch(refundedActions.fetchMoreRefunded()),
    fetchRefundedSortChanged: (column, direction) =>
        dispatch(refundedActions.fetchRefundedSortChanged(column, direction)),
    fetchRefundedDateRangeChanges: (startDate, endDate) =>
        dispatch(refundedActions.fetchRefundedDateRangeChanges(startDate, endDate)),
    changePaymentStatus: ({ prevStatus, payment, status, position }) =>
        dispatch(changePaymentStatus({ prevStatus, payment, status, position })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Refunded);
