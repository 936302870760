import types from '../actions/actionTypes';
import {createReducer} from './helpers/redux-helpers';

const initialState = {
  offset: 0,
  limit: 100,
  events: [],
  searchQuery: '',
  countryFilter: '',
  brandFilter: '',
  createDateFilter: {},
  startDateFilter: {},
};

export default createReducer(
  {
    [types.FETCH_EVENTS_V2]: (state, action) => ({
        ...state,
        events: [],
        searchQuery: '',
        searchQuery: action?.data?.query,
        countryFilter: action?.data?.countryFilter,
        brandFilter: action?.data?.brandFilter,
        createDateFilter: action?.data?.createDateFilter,
        startDateFilter: action?.data?.startDateFilter
    }),

    [types.FETCH_EVENTS_V2_SUCCESS]: (state, action) => ({
      ...state,
      offset: state.offset + state.limit,
      totalCount: action.data.count,
      events: [...state.events, ...action.data.events],
      hasMore: state?.events?.length + action?.data?.events?.length < action?.data?.count,
      isLoading: false,
    }),
    [types.FETCH_EVENTS_V2_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasMore: false,
    }),
    [types.FETCH_MORE_EVENTS_V2]: state => ({
      ...state,
      isLoading: true,
    }),
  },
  initialState,
);
