/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
  fetchPendingFailed, fetchPendingSuccess, makeRejectFailed, makeRejectSuccess,
} from '../../actions/logistics/pendingactions';
import getBaseUrl from '../../utils/envSetup';

const baseUrl = getBaseUrl();

const selectLogisticsState = state => state.logistics.pending;

function* fetchPendingAsync() {
  const logisticsState = yield select(selectLogisticsState);
  const sorting = Object.keys(logisticsState.sort).map(key => `&sort[${key}]=${logisticsState.sort[key]}`).join('');
  const startDate = logisticsState.startDate ? `&startDate=${logisticsState.startDate}` : '';
  const endDate = logisticsState.endDate ? `&endDate=${logisticsState.endDate}` : '';
  const searchQuery = logisticsState.searchQuery ? `&userName=${logisticsState.searchQuery}` : '';
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/pending?limit=${logisticsState.limit}&skip=${logisticsState.offset}${sorting}${startDate}${endDate}${searchQuery}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchPendingFailed(response.err.toString()));
  } else {
    yield put(fetchPendingSuccess(response.data));
  }
}

function* makeRejectAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/reject`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: action.ids }),
    },
  );

  if (response.err) {
    yield put(makeRejectFailed(response.err.toString()));
  } else {
    yield put(makeRejectSuccess());
  }
}

function* fetchPending() {
  yield takeLatest([`${types.LOGISTICS.FETCH_PENDING}`, `${types.LOGISTICS.FETCH_MORE_PENDING}`,
  `${types.LOGISTICS.REJECT_PENDING_SUCCESS}`, `${types.LOGISTICS.FETCH_PENDING_SORT_CHANGED}`,
    `${types.LOGISTICS.FETCH_PENDING_DATERANGE_CHANGED}` ], fetchPendingAsync);
}

function* makeReject() {
  yield  takeLatest([`${types.LOGISTICS.REJECT_PENDING}`], makeRejectAsync)
}

export default function* pendingsaga() {
  yield all([
    fetchPending(),
    makeReject(),
  ]);
}
