/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../actionTypes';
 
export const fetchShipping = query => ({
    
  type: types.LOGISTICS.FETCH_SHIPPING,
  query,
});

export const selectShipper = shipper => ({
    type: types.LOGISTICS.SELECT_SHUPPER,
    shipper
})

export const fetchMoreShipping = () => ({
  type: types.LOGISTICS.FETCH_MORE_SHIPPING,
});

export const fetchShippingSuccess = data => ({
  type: types.LOGISTICS.FETCH_SHIPPING_SUCCESS,
  data,
});

export const fetchShippingFailed = reason => ({
  type: types.LOGISTICS.FETCH_SHIPPING_FAILED,
  reason,
});

export const fetchShippingSortChanged = (column, direction) => ({
  type: types.LOGISTICS.FETCH_SHIPPING_SORT_CHANGED,
  column,
  direction,
});

export const fetchShippingDateRangeChanges = (startDate, endDate) => ({
  type: types.LOGISTICS.FETCH_SHIPPING_DATERANGE_CHANGED,
  startDate,
  endDate,
});

