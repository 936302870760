/*
Kateryna Ivanova
30/03/2018

Reducer for a single bo user
*/
import types from '../actions/actionTypes';

const initialState = {
  bouser: null,
  isLoading: false,
  isUpdating: false,
  updateSuccess: false,
  updateError: null,
  isDeleting: false,
  deleteSuccess: false,
  deleteError: null,
  newPassword: null,
  regenerateError: null,
  isRegenerating: false,
};

export default function boUserReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_BO_USER:
      return Object.assign({}, state, {
        isLoading: true,
        bouser: null,
        updateSuccess: false,
        updateError: null,
      });
    case types.FETCH_BO_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bouser: action.data,
      });
    case types.FETCH_BO_USER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.UPDATE_BO_USER:
      return Object.assign({}, state, {
        isUpdating: true,
        updateSuccess: false,
      });
    case types.CREATE_BO_USER:
      return Object.assign({}, state, {
        isUpdating: true,
        updateSuccess: false,
      });
    case types.UPDATE_BO_USER_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        updateSuccess: true,
      });
    case types.UPDATE_BO_USER_FAILED:
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: action.reason,
        updateSuccess: false,
      });

    case types.DELETE_BO_USER:
      return Object.assign({}, state, {
        isDeleting: true,
        deleteSuccess: false,
      });
    case types.DELETE_BO_USER_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteSuccess: true,
      });
    case types.DELETE_BO_USER_FAILED:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteError: action.reason,
        deleteSuccess: false,
      });
    case types.BO_USER_REGENERATE_PASSWORD:
      return Object.assign({}, state, {
        isRegenerating: true,
        newPassword: null,
      });
    case types.BO_USER_REGENERATE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isRegenerating: false,
        newPassword: action.newPassword,
      });
    case types.BO_USER_REGENERATE_PASSWORD_FAILED:
      return Object.assign({}, state, {
        isRegenerating: false,
        regenerateError: action.reason,
      });
    default:
      return state;
  }
}
