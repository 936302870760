/*
Kateryna Ivanova
30/03/2018

Sagas for working with a single bo user
*/
import { takeLatest, put, call, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {
  fetchBOUserSuccess,
  fetchBOUserFailed,
  updateBOUserFailed,
  updateBOUserSuccess,
  deleteBOUserSuccess,
  deleteBOUserFailed,
  regeneratePasswordSuccess,
  regeneratePasswordFailed,
} from '../actions/bouseractions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* fetchBOUserAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/bouser/${action.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchBOUserFailed(response.err.toString()));
  } else {
    yield put(fetchBOUserSuccess(response.data));
  }
}

function* updateBOUserAsync(action) {
  const body = { };
  if (action.bouser.email) body.email = action.bouser.email;
  if (action.bouser.fullName) body.fullName = action.bouser.fullName;
  if (action.bouser.password) body.password = action.bouser.password;
  if (action.bouser.permission) body.permission = action.bouser.permission;
  if (action.bouser.status) body.status = action.bouser.status;
  if (action.bouser.phone) body.phone = action.bouser.phone;
  if (action.bouser.countryCode) body.countryCode = action.bouser.countryCode;
  body.isDisable2fa = action.bouser.isDisable2fa;

  const response = yield call(
    request,
    `${baseUrl}/api/bouser/${action.bouser._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateBOUserFailed(response.err.toString()));
  } else {
    yield put(updateBOUserSuccess());
  }
}

function* createBOUserAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/bouser`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        provider: 'admin',
        email: action.bouser.email,
        fullName: action.bouser.fullName,
        password: action.bouser.password,
        permission: action.bouser.permission,
        status: action.bouser.status,
        phone: action.bouser.phone,
        countryCode: action.bouser.countryCode,
        isDisable2fa: action.bouser.isDisable2fa,
      }),
    },
  );

  if (response.err) {
    yield put(updateBOUserFailed(response.err.toString()));
  } else {
    yield put(updateBOUserSuccess());
  }
}

function* deleteBOUserAsync(action) {
  // const bousersIds = action.ids.join(',')
  const response = yield call(
    request,
    `${baseUrl}/api/bouser/${action.ids[0]}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteBOUserFailed(response.err.toString()));
  } else {
    yield put(deleteBOUserSuccess());
  }
}

function* regeneratePasswordAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/bouser/regeneratepassword/${action.id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(regeneratePasswordFailed(response.err.toString()));
  } else {
    yield put(regeneratePasswordSuccess(response.data.password));
  }
}

function* fetchBOUser() {
  yield takeLatest([`${types.FETCH_BO_USER}`], fetchBOUserAsync);
}

function* updateBOUser() {
  yield takeLatest([`${types.UPDATE_BO_USER}`], updateBOUserAsync);
}

function* createBOUser() {
  yield takeLatest([`${types.CREATE_BO_USER}`], createBOUserAsync);
}

function* deleteBOUser() {
  yield takeLatest([`${types.DELETE_BO_USER}`], deleteBOUserAsync);
}

function* regeneratePassword() {
  yield takeLatest([`${types.BO_USER_REGENERATE_PASSWORD}`], regeneratePasswordAsync);
}

export default function* boUserSaga() {
  yield all([
    fetchBOUser(), updateBOUser(), createBOUser(), deleteBOUser(), regeneratePassword(),
  ]);
}
