import types from './actionTypes';

export const getUserProfileData = id => ({
  type: types.FETCH_USER_PROFILE_DATA,
  id,
});

export const fetchUserProfileSuccess = data => ({
  type: types.FETCH_USER_PROFILE_DATA_SUCCESS,
  data,
});

export const fetchUserProfileFailed = reason => ({
  type: types.FETCH_USER_PROFILE_DATA_FAILED,
  reason,
});

export const updateUserProfileShipping = (id, data) => ({
  type: types.UPDATE_USER_PROFILE_SHIPPING,
  id,
  data,
});

export const updateUserProfileShippingSuccess = data => ({
  type: types.UPDATE_USER_PROFILE_SHIPPING_SUCCESS,
  data,
});

export const updateUserProfileShippingFailed = reason => ({
  type: types.UPDATE_USER_PROFILE_SHIPPING_FAILED,
  reason,
});

export const updateUserProfileInfo = (id, data) => ({
  type: types.UPDATE_USER_PROFILE_INFO,
  id,
  data,
});

export const updateUserProfileInfoSuccess = data => ({
  type: types.UPDATE_USER_PROFILE_INFO_SUCCESS,
  data,
});

export const updateUserProfileInfoFailed = reason => ({
  type: types.UPDATE_USER_PROFILE_INFO_FAILED,
  reason,
});
