import types from './actionTypes';

export const getAllowedCountries = id => ({
  type: types.FETCH_ALLOWED_COUNTRIES,
  id,
});

export const fetchAllowedCountriesSuccess = data => ({
  type: types.FETCH_ALLOWED_COUNTRIES_SUCCESS,
  data,
});

export const fetchAllowedCountriesFailed = reason => ({
  type: types.FETCH_ALLOWED_COUNTRIES_FAILED,
  reason,
});
export const addEditAllowedCountry = data => ({
  type: types.ADD_EDIT_ALLOWED_COUNTRY,
  data,
});

export const addNewAllowedCountry = data => ({
  type: types.ADD_NEW_ALLOWED_COUNTRY,
  data,
});
export const addAllowedCountry = data => ({
  type: types.ADD_ALLOWED_COUNTRIES,

  data,
});

export const addAllowedCountrySuccess = data => ({
  type: types.ADD_ALLOWED_COUNTRIES_SUCCESS,
  data,
});

export const addAllowedCountryFailed = reason => ({
  type: types.ADD_ALLOWED_COUNTRIES_FAILED,
  reason,
});

export const deleteAllowedCountry = data => ({
  type: types.DELETE_ALLOWED_COUNTRIES,

  data,
});

export const deleteAllowedCountrySuccess = data => ({
  type: types.DELETE_ALLOWED_COUNTRIES_SUCCESS,
  data,
});

export const deleteAllowedCountryFailed = reason => ({
  type: types.DELETE_ALLOWED_COUNTRIES_FAILED,
  reason,
});

export const getCountryZones = id => ({
  type: types.FETCH_COUNTRIES_ZONES,
  id,
});

export const fetchMoreCountriesZones = () => ({
  type: types.FETCH_MORE_COUNTRIES_ZONES,
});

export const fetchCountryZonesSuccess = data => ({
  type: types.FETCH_COUNTRIES_ZONES_SUCCESS,
  data,
});

export const fetchCountryZonesFailed = reason => ({
  type: types.FETCH_COUNTRIES_ZONES_FAILED,
  reason,
});

export const addCountryZones = data => ({
  type: types.ADD_COUNTRIES_ZONES,
  data,
});

export const addCountryZonesSuccess = (data, id) => ({
  type: types.ADD_COUNTRIES_ZONES_SUCCESS,
  data,
  id,
});

export const addCountryZonesFailed = reason => ({
  type: types.ADD_COUNTRIES_ZONES_FAILED,
  reason,
});

export const addNewZone = (data, zone) => ({
  type: types.ADD_NEW_ZONE,
  data,
  zone,
});

export const addZonee = (data, zone) => ({
  type: types.ADD_ZONE,
  data,
  zone,
});
export const addZoneSuccess = (data, id) => ({
  type: types.ADD_ZONE_SUCCESS,
  data,
  id,
});

export const addZoneFailed = reason => ({
  type: types.ADD_ZONE_FAILED,
  reason,
});

export const updateCountryZones = (id, rowIndex) => ({
  type: types.UPDATE_COUNTRIES_ZONES,
  id,
  rowIndex,
});

export const updateCountryZonesSuccess = (newCountryObj, rowIndex) => ({
  type: types.UPDATE_COUNTRIES_ZONES_SUCCESS,
  newCountryObj,
  rowIndex,
});

export const updateCountryZonesFailed = reason => ({
  type: types.UPDATE_COUNTRIES_ZONES_FAILED,
  reason,
});

export const updateZone = (id, zoneRowIndex, zone) => ({
  type: types.UPDATE_ZONE,
  id,
  zoneRowIndex,
  zone,
});

export const updateZoneSuccess = (newZoneObj, zoneRowIndex) => ({
  type: types.UPDATE_ZONE_SUCCESS,
  newZoneObj,
  zoneRowIndex,
});

export const updateZoneFailed = reason => ({
  type: types.UPDATE_ZONE_FAILED,
  reason,
});

export const deleteCountryZones = (id, rowId) => ({
  type: types.DELETE_COUNTRIES_ZONES,
  id,
  rowId,
});

export const deleteCountryZonesSuccess = data => ({
  type: types.DELETE_COUNTRIES_ZONES_SUCCESS,
  data,
});

export const deleteCountryZonesFailed = reason => ({
  type: types.DELETE_COUNTRIES_ZONES_FAILED,
  reason,
});

export const deleteZone = (id, rowId, zone) => ({
  type: types.DELETE_ZONE,
  id,
  rowId,
  zone,
});

export const deleteZoneSuccess = data => ({
  type: types.DELETE_ZONE_SUCCESS,
  data,
});

export const deleteZoneFailed = reason => ({
  type: types.DELETE_ZONE_FAILED,
  reason,
});

export const fetchZone = id => ({
  type: types.FETCH_ZONE,
  id,
});

export const fetchZoneSuccess = data => ({
  type: types.FETCH_ZONE_SUCCESS,
  data,
});

export const fetchZoneFailed = reason => ({
  type: types.FETCH_ZONE_FAILED,
  reason,
});

export const addCountriesInCountriesZones = newCountryData => ({
  type: types.ADD_COUNTRY_IN_COUNTRIES_ZONES,
  payload: {newCountryData},
});

export const addCountriesInCountriesZonesSuccess = newCountryData => ({
  type: types.ADD_COUNTRY_IN_COUNTRIES_ZONES_SUCCESS,
  payload: {newCountryData},
});

export const addCountriesInCountriesZonesFailed = reason => ({
  type: types.ADD_COUNTRY_IN_COUNTRIES_ZONES_FAILED,
  reason,
});

export const deleteCountryInCountriesZones = index => ({
  type: types.DELETE_COUNTRY_IN_COUNTRIES_ZONES,
  payload: index,
});

export const deleteCountryInCountriesZonesSuccess = data => ({
  type: types.DELETE_COUNTRY_IN_COUNTRIES_ZONES_SUCCESS,
  data,
});

export const deleteCountryInCountriesZonesFailed = reason => ({
  type: types.DELETE_COUNTRY_IN_COUNTRIES_ZONES_FAILED,
  reason,
});

export const setIsEditCountry = value => ({
  type: types.SET_IS_EDIT_COUNTRY_COUNTRIES_ZONES,
  payload: value,
});

export const addNewCountryZone = data => ({
  type: types.ADD_NEW_COUNTRY_ZONE,
  payload: data,
});

export const removeNewCountryZone = (data, removeCountry) => ({
  type: types.REMOVE_NEW_COUNTRY_ZONE,
  payload: {data, removeCountry},
});
export const removeNewAllowedCountry = (data, removeCountry) => ({
  type: types.REMOVE_NEW_ALLOWED_COUNTRY,
  payload: {data, removeCountry},
});
export const removeNewZone = data => ({
  type: types.REMOVE_NEW_ZONE,
  payload: data,
});

export const editCurrentCountryZone = (data, indx) => ({
  type: types.EDIT_CURRENT_COUNTRY_ZONE,
  payload: {data, indx},
});

export const editCurrentZone = (data, indx) => ({
  type: types.EDIT_CURRENT_ZONE,
  payload: {data, indx},
});

export const fetchMoreCountriesAttributes = () => ({
  type: types.FETCH_MORE_COUNTRIES_ATTRIBUTES,
});

export const getCountriesAttributes = () => ({
  type: types.FETCH_COUNTRIES_ATTRIBUTES,
});

export const getCountriesAttributesSuccess = data => ({
  type: types.FETCH_COUNTRIES_ATTRIBUTES_SUCCESS,
  payload: data,
});

export const getCountriesAttributesFailed = reason => ({
  type: types.FETCH_COUNTRIES_ATTRIBUTES_FAILED,
  payload: reason,
});
export const getZonesCountriesAttributes = id => ({
  type: types.FETCH_ZONES_COUNTRIES_ATTRIBUTES,
  payload: {id},
});

export const getZonesCountriesAttributesSuccess = data => ({
  type: types.FETCH_ZONES_COUNTRIES_ATTRIBUTES_SUCCESS,
  payload: data,
});

export const getZonesCountriesAttributesFailed = reason => ({
  type: types.FETCH_ZONES_COUNTRIES_ATTRIBUTES_FAILED,
  payload: reason,
});

export const editCurrentCountryAttributesData = (data, index) => ({
  type: types.EDIT_CURRENT_ATTRIBUTES_COUNTRY,
  payload: {data, index},
});

export const editCurrentZoneAttributesData = (data, index) => ({
  type: types.EDIT_CURRENT_ATTRIBUTES_ZONE,
  payload: {data, index},
});

export const updateAttributeCountry = (countryId, attributes) => ({
  type: types.UPDATE_COUNTRY_ATTRIBUTES,
  payload: {countryId, attributes},
});

export const updateAttributeCountrySuccess = newCountryData => ({
  type: types.UPDATE_COUNTRY_ATTRIBUTES_SUCCESS,
  payload: newCountryData,
});

export const updateAttributeCountryFailed = reason => ({
  type: types.UPDATE_COUNTRY_ATTRIBUTES_FAILED,
  payload: reason,
});

export const addAttributeCountry = (countryId, attributes) => ({
  type: types.ADD_COUNTRY_ATTRIBUTES,
  payload: {countryId, attributes},
});

export const addAttributeCountrySuccess = newCountryData => ({
  type: types.ADD_COUNTRY_ATTRIBUTES_SUCCESS,
  payload: newCountryData,
});

export const addAttributeCountryFailed = reason => ({
  type: types.ADD_COUNTRY_ATTRIBUTES_FAILED,
  payload: reason,
});
