import React, { Component } from "react";
import { connect } from "react-redux";

import IconButton from "material-ui/IconButton";
import moment from "moment";
import * as checkinsActions from "../actions/checkinsActions";
import * as profileActions from "../actions/profileActions";
import RaisedButton from "material-ui/RaisedButton";
import "../static/styles/checkins.css";
import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import { FormGroup, FormControl, ControlLabel, Col } from "react-bootstrap";
import FlatButton from "material-ui/FlatButton";
import Select from "react-select";
import ImagePicker from "../components/imagepicker";
import Datetime from "react-datetime";
import LinearProgress from "material-ui/LinearProgress";
import ErrorAlert from "../components/ErrorAlert";
import SuccessAlert from "../components/SuccessAlert";
import { Dialog } from "material-ui";
import { getPermission } from "../utils/cookies";
import { decodePermissions } from "../utils/permissionUtil";
import "react-datetime/css/react-datetime.css";
import { withRouter } from "../utils/withRouter";

class Checkin extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      activeCheckin: {},
      firstName: "",
      limit: 20,
      skip: 0,
      errors: false,
      loadingImage: false,
      buttonDisabled: false,
      itemVideo: null,
      videoDialogOpen: false,
      videoUrl: "",
      success: false
    };
  }

  componentDidMount() {
    const { permission } = this.state;
    if (permission.canManageCheckins) {
      if (this.props.params.id) {
        this.props.getCheckinById(this.props.params.id);
      }
      this.props.getShuppers();
      this.props.getStores();
      this.props.fetchBrands();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectCheckin } = this.props.checkinsReducer;
    if (
      prevProps.checkinsReducer.selectCheckin !== selectCheckin &&
      selectCheckin
    ) {
      const { user: { displayName, _id: userId }, brand: { title, _id: brandId } = {}, status: statusLabel = {} } = selectCheckin
      const changedCheckin = {
        ...selectCheckin, user: {
          label: displayName,
          value: userId
        } || null,
        brand: {
          label: title,
          value: brandId
        },
        status: {
          label: statusLabel,
          value: {
            live: 1,
            upcoming: 0
          }[statusLabel] || 2
        } || null
      }

      this.setState({
        activeCheckin: changedCheckin,
        firstName: selectCheckin.user.displayName
      });
    }
    if (
      prevProps.checkinsReducer.newImageUrl !==
      this.props.checkinsReducer.newImageUrl
    ) {
      let changedUser = {};
      Object.assign(changedUser, this.state.activeCheckin);
      changedUser.image = this.props.checkinsReducer.newImageUrl;
      this.setState({
        activeCheckin: changedUser,
        loadingImage: false,
        buttonDisabled: false
      });
    }
    if (
      prevProps.checkinsReducer.newVideoUrl !==
      this.props.checkinsReducer.newVideoUrl
    ) {
      let changedUser = {};
      Object.assign(changedUser, this.state.activeCheckin);
      changedUser.video = this.props.checkinsReducer.newVideoUrl;
      this.setState({
        activeCheckin: changedUser,
        loadingImage: false,
        buttonDisabled: false
      });
    }
    if (
      prevProps.checkinsReducer.newVideoUrl !==
      this.props.checkinsReducer.newVideoUrl
    ) {
      let changedUser = {};
      Object.assign(changedUser, this.state.activeCheckin);
      changedUser.video = this.props.checkinsReducer.newVideoUrl;
      this.setState({
        activeCheckin: changedUser,
        loadingImage: false,
        buttonDisabled: false
      });
    }
    if (
      prevProps.checkinsReducer.saveCheckinSuccess !==
      this.props.checkinsReducer.saveCheckinSuccess &&
      this.props.checkinsReducer.saveCheckinSuccess
    ) {
      this.props.resetCheckins();
      this.setState({ success: true });
    }
    if (
      prevProps.checkinsReducer.newCheckin !==
      this.props.checkinsReducer.newCheckin &&
      this.props.checkinsReducer.newCheckin
    ) {
      this.props.navigate(
        `/checkin/edit/${this.props.checkinsReducer.newCheckin._id}`
      );
    }
  }
  getValidationState(name, email) {
    const length =
      (this.state.activeCheckin[name] &&
        this.state.activeCheckin[name].length) ||
      this.state.activeCheckin[name];
    if ((length && length > 0) || this.state.activeCheckin[name])
      return "success";
    return "error";
  }
  onChangeInput = (name, e, v) => {
    let changedCheckin = {};
    Object.assign(changedCheckin, this.state.activeCheckin);
    changedCheckin[name] = e.target.value;
    this.setState({ activeCheckin: changedCheckin });
  };
  onChangeDate = (e, v) => {
    let changedCheckin = {};
    Object.assign(changedCheckin, this.state.activeCheckin);
    changedCheckin.date = e;
    this.setState({ activeCheckin: changedCheckin });
  };
  onChangeSelect = (name, e) => {
    let changedCheckin = {};
    Object.assign(changedCheckin, this.state.activeCheckin);
    changedCheckin[name] = e;
    this.setState({ activeCheckin: changedCheckin });
  };
  getNewValueImage = (key, val) => {
    if (key === "icon") {
      this.setState({ newImageIcon: val });
    } else {
      this.setState({ newImageFile: val });
    }
  };
  setNewImage = val => {
    const { updateImageCheckin } = this.props;

    updateImageCheckin({ _id: this.props.params.id, newImage: val });
    this.setState({
      uploaderOpened: false,
      loadingImage: true,
      newImageFile: null,
      imageUserId: null,
      buttonDisabled: true
    });
  };
  setNewVideo = val => {
    const { updateVideoCheckin } = this.props;

    updateVideoCheckin({ _id: this.props.params.id, itemVideo: val });
    this.setState({
      itemVideo: null,
      loadingImage: true,
      profileImageFile: null,
      imageUserId: null,
      buttonDisabled: true
    });
  };
  handleSave = () => {
    this.setState({ showErrors: true });
    const { activeCheckin } = this.state;
    let changedCheckin = {};
    Object.assign(changedCheckin, activeCheckin);
    const { pathname } = this.props.location;

    const createMode = pathname.indexOf("create") !== -1;

    changedCheckin.brand = activeCheckin.brand && activeCheckin.brand.value;
    changedCheckin.user = activeCheckin.user && activeCheckin.user.value;
    changedCheckin.status = activeCheckin.status && activeCheckin.status.label;
    if (!changedCheckin.date) changedCheckin.date = moment();
    if (
      changedCheckin.brand &&
      changedCheckin.user &&
      changedCheckin.description &&
      changedCheckin.status
    ) {
      if (createMode) {
        this.props.saveCheckinById({ data: changedCheckin });
      } else {
        this.props.saveCheckinById({
          id: this.props.params.id,
          data: changedCheckin
        });
      }
    } else {
      this.setState({ errors: true });
    }
  };
  onOpenVideoDialog = url => {
    this.setState({
      videoDialogOpen: true,
      videoUrl: url
    });
  };

  render() {
    const {
      showErrors,
      newImageFile,
      newImageIcon,
      activeCheckin,
      errors,
      loadingImage,
      buttonDisabled,
      firstName,
      itemVideo,
      videoDialogOpen,
      videoUrl,
      success,
      permission
    } = this.state;

    const { shuppers, stores } = this.props.checkinsReducer;
    const { brands } = this.props.profileReducer;
    const { pathname } = this.props.location;
    const showMode = pathname.indexOf("show") !== -1;
    const createMode = pathname.indexOf("create") !== -1;
    return (
      <div>
        <div className="App-header">
          <IconButton onClick={() => this.props.navigate(-1)}>
            <NavigationArrowBack color="white" />
          </IconButton>
          {createMode ? null : <span>{firstName}</span>}
          <span className="header-placeholder" />
        </div>
        <div className="progressbar-container">
          {loadingImage && <LinearProgress mode="indeterminate" />}
        </div>
        {errors && (
          <ErrorAlert
            mainText="Error!"
            extraText="Fill in all fields"
            onClose={() => {
              this.setState({ errors: false });
            }}
          />
        )}
        {success && (
          <SuccessAlert
            mainText="Success!"
            extraText="Checkin is saved"
            onClose={() => {
              this.setState({ success: false });
            }}
          />
        )}
        <Dialog
          contentStyle={{ width: "450px" }}
          modal={false}
          open={videoDialogOpen}
          onRequestClose={() =>
            this.setState({ videoDialogOpen: false, videoUrl: null })
          }
        >
          <video src={videoUrl} controls width="400" height="400">
            Sorry, your browser doesn't support embedded videos, but don't
            worry, you can <a href={videoUrl}>download it</a>
            and watch it with your favorite video player!
          </video>
        </Dialog>
        {permission.canManageCheckins ? (
          <div className="profile-tabs flxc ac jc">
            <div className="react-bootstrap ">
              <Col sm={12} md={12} className="text-right margin-top-20">
                {!showMode ? (
                  <RaisedButton
                    disabled={showMode || buttonDisabled}
                    onClick={this.handleSave}
                    label="Save"
                    primary
                  />
                ) : null}
              </Col>
              <Col sm={6} md={6}>
                <FormGroup
                  controlId="formBasicText"
                  validationState={
                    showErrors ? this.getValidationState("description") : null
                  }
                >
                  <ControlLabel>Description *</ControlLabel>
                  <FormControl
                    disabled={showMode}
                    type="text"
                    value={activeCheckin && activeCheckin.description}
                    placeholder="Enter text"
                    onChange={e => this.onChangeInput("description", e)}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              <Col sm={6} md={6}>
                <FormGroup
                  controlId="formBasicText"
                  validationState={
                    showErrors ? this.getValidationState("status") : null
                  }
                >
                  <ControlLabel>Status *</ControlLabel>

                  <Select
                    isDisabled={showMode}
                    options={[
                      { label: "upcoming", value: 0 },
                      { label: "live", value: 1 },
                      { label: "finished", value: 2 }
                    ]}
                    onChange={e => this.onChangeSelect("status", e)}
                    value={activeCheckin && activeCheckin.status}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              <Col sm={6} md={6}>
                <FormGroup
                  controlId="formBasicText"
                  className="flex-column"
                  validationState={
                    showErrors ? this.getValidationState("date") : null
                  }
                >
                  <ControlLabel>Date *</ControlLabel>

                  <Datetime
                    inputProps={{ disabled: showMode }}
                    value={
                      activeCheckin && activeCheckin.date
                        ? moment(activeCheckin.date)
                        : moment()
                    }
                    onChange={e => this.onChangeDate(e)}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              <Col sm={6} md={6}>
                <FormGroup
                  controlId="formBasicText"
                  validationState={
                    showErrors ? this.getValidationState("user") : null
                  }
                >
                  <ControlLabel>Shupper *</ControlLabel>
                  <Select
                    options={[...shuppers, ...stores].map(item => {
                      return { label: item.displayName, value: item._id };
                    })}
                    isDisabled={showMode}
                    onChange={e => this.onChangeSelect("user", e)}
                    value={activeCheckin && activeCheckin.user}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              <Col sm={6} md={6}>
                <div className="form-group border-bottom">
                  <ControlLabel> Pictrue *</ControlLabel>
                  <div className="checkin-picture">
                    <ImagePicker
                      notShow={showMode}
                      disabled={showMode}
                      className="flxr jsb ac personal-info-image-picker"
                      imageClass="picker-preview"
                      placeholderUrl=""
                      style={{ maxWidth: "200px" }}
                      url={
                        newImageIcon
                          ? newImageIcon
                          : activeCheckin && activeCheckin.image
                      }
                      image={
                        newImageIcon
                          ? newImageIcon
                          : activeCheckin && activeCheckin.image
                      }
                      onImageSelected={file =>
                        this.getNewValueImage("image", file)
                      }
                      onError={console.error}
                      onFileRead={file => this.getNewValueImage("icon", file)}
                    />
                  </div>
                  {newImageFile ? (
                    <FlatButton
                      label="Apply"
                      primary
                      onClick={() => {
                        this.setNewImage(newImageFile);
                      }}
                    />
                  ) : null}
                </div>
              </Col>
              <Col sm={6} md={6}>
                <div className="form-group border-bottom">
                  <ControlLabel> Video *</ControlLabel>
                  <div
                    className={
                      !showMode ? "checkin-video" : "checkin-video-show-mode"
                    }
                  >
                    {activeCheckin && activeCheckin.video ? (
                      <a
                        href={null}
                        role="button"
                        onClick={event => {
                          event.preventDefault();
                          this.onOpenVideoDialog(activeCheckin.video);
                        }}
                      >
                        Watch Video
                      </a>
                    ) : null}
                    {!showMode ? (
                      <input
                        disabled={showMode}
                        type="file"
                        name="itemVideo"
                        accept="video/*"
                        onChange={ev =>
                          this.setState({ itemVideo: ev.target.files[0] })
                        }
                      />
                    ) : null}
                  </div>
                  {itemVideo ? (
                    <FlatButton
                      label="Apply"
                      primary
                      onClick={() => {
                        this.setNewVideo(itemVideo);
                      }}
                    />
                  ) : null}
                </div>
              </Col>
              <Col sm={6} md={6}>
                <FormGroup
                  controlId="formBasicText"
                  validationState={
                    showErrors ? this.getValidationState("brand") : null
                  }
                >
                  <ControlLabel>Brand *</ControlLabel>
                  <Select
                    isDisabled={showMode}
                    options={
                      brands && brands.events
                        ? brands.events.map(item => {
                          return { label: item.title, value: item._id };
                        })
                        : []
                    }
                    onChange={e => this.onChangeSelect("brand", e)}
                    value={activeCheckin && activeCheckin.brand}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h2>No Access</h2>
          </div>
        )}
      </div>
    );
  }
}

Checkin.propTypes = {};
const mapStateToProps = ({ checkinsReducer, profileReducer }) => ({
  checkinsReducer,
  profileReducer
});
const mapDispatchToProps = dispatch => ({
  getCheckinById: id => dispatch(checkinsActions.getCheckinById(id)),
  updateImageCheckin: data =>
    dispatch(checkinsActions.updateImageCheckin(data)),
  updateVideoCheckin: data =>
    dispatch(checkinsActions.updateVideoCheckin(data)),
  getShuppers: () => dispatch(checkinsActions.getShuppers()),
  fetchBrands: query => dispatch(profileActions.fetchBrands(query)),
  saveCheckinById: data => dispatch(checkinsActions.saveCheckinById(data)),
  resetCheckins: () => dispatch(checkinsActions.resetCheckins()),
  getStores: () => dispatch(checkinsActions.getStores())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Checkin)
);
