/*
Roman Safonov
20/03/2018

Reducer for a single collection
*/
import types from '../actions/actionTypes';

const initialState = {
  isDeleting: false,
  deleteSuccess: false,
  deleteError: null,
  isUpdating: false,
  updateError: null,
  isLoading: false,
  loadingError: null,
  collection: null,
  updateSuccess: false,
};

export default function collectionReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COLLECTION:
      return Object.assign({}, state, {
        isLoading: true,
        collection: null,
        updateSuccess: false,
        updateError: null,
      });
    case types.FETCH_COLLECTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        collection: action.data,
      });
    case types.FETCH_COLLECTION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        loadingError: action.reason,
      });
    case types.DELETE_COLLECTION:
      return Object.assign({}, state, {
        isDeleting: true,
        deleteSuccess: false,
      });
    case types.DELETE_COLLECTION_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteSuccess: true,
      });
    case types.DELETE_COLLECTION_FAILED:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteError: action.reason,
        deleteSuccess: false,
      });
    case types.UPDATE_COLLECTION:
      return Object.assign({}, state, {
        isUpdating: true,
        updateError: null,
        updateSuccess: false,
      });
    case types.UPDATE_COLLECTION_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        updateSuccess: true,
      });
    case types.UPDATE_COLLECTION_FAILED:
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: action.reason,
        updateSuccess: false,
      });
    default:
      return state;
  }
}
