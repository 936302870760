import types from '../actions/actionTypes';

const initialState = {
  offset: 0,
  limit: 100,
  orders: [],
  paymentDetails: [],
  searchQuery: '',
  dateFilter: {},
  paymentFilter: '',
  packageFilter: '',
  packageSubFilter: '',
  userCountryFilter: null,
  shupperCountryFilter: null,
};

export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ORDERS:
      const {data} = action;
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        orders: [],
        searchQuery: !data?.query ? '' : data?.query || state.searchQuery,
        paymentFilter: !data?.paymentFilter ? '' : data?.paymentFilter,
        packageFilter: !data?.packageFilter ? '' : data?.packageFilter,
        packageSubFilter: !data?.packageSubFilter ? '' : data?.packageSubFilter,
        userCountryFilter: !data?.userCountry ? '' : data?.userCountry,
        shupperCountryFilter: !data?.shupperCountry ? '' : data?.shupperCountry,
        dateFilter:
          action?.data?.dateFilter === null ? state.dateFilter : action?.data?.dateFilter || initialState.dateFilter,
      });
    case types.FETCH_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        offset: state.offset + state.limit,
        isLoading: false,
        orders: [...state.orders, ...action.data],
      });
    case types.FETCH_ORDERS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        loadingError: action.reason,
      });
    case types.FETCH_MORE_ORDERS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.FETCH_PAYMENT:
      return Object.assign({}, state, {
        isLoading: true,
        paymentDetails: initialState.paymentDetails,
      });
    case types.FETCH_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        paymentDetails: action.data,
      });
    case types.FETCH_PAYMENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        loadingError: action.reason,
      });
    default:
      return state;
  }
}
