import React, { useEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import '../static/styles/userlist.css';
import { Grid, IconButton, Typography } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { connect } from 'react-redux';
import { fetchItemV2 } from '../actions/itemV2Actions';
import { withRouter } from '../utils/withRouter';
import ItemDetails from '../components/item/ItemDetails';
import ItemMedia from '../components/item/ItemMedia';
import { fetchEventV2 } from '../actions/eventV2Actions';
import EventDetails from '../components/event/EventDetails';
import EventMedia from '../components/event/EventMedia';
import { tableData } from '../utils/tableData';
import TableData from '../components/table';

const EventV2 = ({eventV2Reducer, fetchEventV2, onOpenDrawer, params, ...props}) => {
  const { id } = params
  useEffect(() => {
    fetchEventV2({ id })
  }, [id])

  const event = eventV2Reducer?.event
  const { users: { usersList } } = tableData

  console.log('eventV2Reducer', eventV2Reducer)

  return <div>
    <div className="App-header">
      <IconButton onClick={onOpenDrawer}>
        <MenuIcon color='primary' />
      </IconButton>
      <span className="header-placeholder" />
    </div>
    <div className="paymentRequest-wrapper">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        xs={12}
        style={{ paddingRight: '0px' }}
      >
        <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
          <EventDetails event={ event } />
        </Grid>
        <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
          <EventMedia event={ event } />
        </Grid>

        <Grid item lg={12} xl={12} md={12} sm={12} xs={12} >
          <Typography variant="h5" className="paymentRequest-subtitle">
            USERS
          </Typography>
          <div className={`usersTable`} >
            <TableData
              tableName='users'
              tablePagination={true}
              data={event?.users}
              headerColumn={usersList}
              totalResults={event?.users.length}
              fetchMore={() => {}}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
};

const mapStateToProps = ({eventV2Reducer}) => ({
  eventV2Reducer,
});

const mapDispatchToProps = dispatch => ({
  fetchEventV2: data => dispatch(fetchEventV2(data)),
  dispatch,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventV2));
