/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../../actions/actionTypes';
import {createReducer} from '../helpers/redux-helpers';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 200,
  logistics: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  sort: {},
  startDate: null,
  endDate: null,
  searchQuery: '',
  shippers: [{value: '', label: 'All Shippers'}],
  selectedShipper: {value: '', label: 'All Shippers'},
};

export default createReducer(
  {
    [types.LOGISTICS.FETCH_SHIPPING]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      logistics: [],
      totalCount: 0,
      searchQuery: action.query,
    }),
    [types.LOGISTICS.FETCH_MORE_SHIPPING]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.LOGISTICS.FETCH_SHIPPING_SUCCESS]: (state, action) => ({
      ...state,
      offset: state.offset + state.limit,
      totalCount: action.data.count,
      logistics: [...state.logistics, ...action.data.logistics],
      hasMore: state.logistics.length + action.data.logistics.length < action.data.count,
      isLoading: false,
    }),
    [types.LOGISTICS.FETCH_SHIPPING_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasMore: false,
    }),
    [types.LOGISTICS.FETCH_SHIPPING_SORT_CHANGED]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      logistics: [],
      totalCount: 0,
      sort: procesSort(state.sort, action.column, action.direction),
    }),
    [types.LOGISTICS.FETCH_SHIPPING_DATERANGE_CHANGED]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      logistics: [],
      totalCount: 0,
      startDate: action.startDate,
      endDate: action.endDate,
    }),
    [types.LOGISTICS.REMOVE_SHIPPING_ITEMS]: (state, action) => ({
      ...state,
      logistics: [...state.logistics.filter(i => !action.ids.includes(i._id))],
      totalCount: state.totalCount - action.ids.length,
    }),
    [types.LOGISTICS.SELECT_SHUPPER]: (state, action) => ({
      ...state,
      selectedShipper: action.shipper,
      offset: 0,
      limit: 200,
      hasMore: false,
      isLoading: true,
      logistics: [],
    }),
    [types.LOGISTICS.ADD_SHIPPING_PICKUP_SUCCESS]: (state, action) => ({
      ...state,
    }),
    CHANGE_SHIPPING_STATUS: (state, {payload}) => ({
      ...state,
      logistics: [...state.logistics.slice(0, payload.position), ...state.logistics.slice(payload.position + 1)],
    }),
    ADD_SHIPPING_PAYMENT: (state, {payload}) => ({
      ...state,
      logistics: [{...payload.payment, status: 'shipping'}, ...state.logistics],
    }),
  },
  initialState,
);
