import React from 'react';
import PropTypes from 'prop-types';

const TextField = ({value, onChange, className, multiLine, caption, row}) => (
	<div className={`${className} ${row ? 'flxr' : 'flxc'}`}>
		<div className="caption">{caption}</div>
		{
			multiLine ?
				<textarea cols="30" rows="10" value={value} onChange={({target}) => onChange(target.value)} />
				: <input type="text" value={value} onChange={({target}) => onChange(target.value)} />
		}
	</div>
);

TextField.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	multiLine: PropTypes.bool,
	caption: PropTypes.string.isRequired,
	row: PropTypes.bool,
};

export default TextField;
