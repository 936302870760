/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 07/05/2018.
 */
import types from '../actions/actionTypes';

const initialState = {
  offset: 0,
  limit: 100,
  coupons: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COUPONS:
      return {
        ...state,
        isLoading: true,
        offset: 0,
        coupons: [],
        totalCount: 0,
      };
    case types.DELETE_COUPON_SUCCESS:
    case types.UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: true,
        offset: 0,
        coupons: [],
        totalCount: 0,
      };
    case types.FETCH_MORE_COUPONS:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_COUPONS_SUCCESS: {
      const coupons = [].concat(state.coupons).concat(action.data.coupons);
      return {
        ...state,
        offset: state.offset + state.limit,
        totalCount: action.data.count,
        coupons,
        hasMore: coupons.length < action.data.count,
        isLoading: false,
      };
    }
    case types.FETCH_COUPONS_FAILED:
      return {
        isLoading: false,
        hasMore: false,
      };
    default:
      return state;
  }
}

