import { Checkbox, FormControlLabel, TextField, withStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAllBrands } from '../../actions/brandsActions';
import useDebounce from '../../hooks/useDebounce';

const InputField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #1B1B1B',
        borderRadius: '8px',
        height: '48px',
        margin: '0px 0px 10px',
      },
    },
  },
})(TextField);

const BrandFilterField = ({
  name,
  inputText,
  inputHandler,
  country,
  itemName,
  handleChange,
  fetchAllBrands,
  brandsReducer,
  selectedBrandIds = [],
}) => {
  const widthStyle = {
    width: name ? 420 : 120,
  };
  const overflowStyle = {
    marginTop: 30,
    height: country?.length < 2 ? 70 : 200,
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const { brands } = brandsReducer

  const filterKeyword = useDebounce(inputText, 300)

  useEffect(() => {
    fetchAllBrands({ filterKeyword })
  }, [filterKeyword])

  const handleSelection = (event, brandId, itemName) => {
    const newSelectedBrandIds = [...selectedBrandIds]
    if (!newSelectedBrandIds.includes(brandId)){
      newSelectedBrandIds.push(brandId);
    } else {
      newSelectedBrandIds.splice(newSelectedBrandIds.indexOf(brandId), 1);
    }

    handleChange(null, newSelectedBrandIds, brandId, itemName)(event)
  }

  return (
    <div>
      <InputField
        style={widthStyle}
        autoFocus="autoFocus"
        value={inputText}
        onChange={e => inputHandler(e, itemName)}
        size="small"
        variant="outlined"
        placeholder="search"
      />
      <div style={overflowStyle}>
        {brands?.map((brand) => {
          return (
            <div>
              <FormControlLabel
                key={`${brand.id}-brand`}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedBrandIds.includes(brand.id)}
                    name="checked"
                    onChange={(e) => handleSelection(e, brand?.id, itemName)}
                  />
                }
                label={brand.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ brandsReducer }) => ({
  brandsReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchAllBrands: data => dispatch(fetchAllBrands(data)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandFilterField);
