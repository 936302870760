/*
Kateryna Ivanova
20/03/2018

Component that allows displaying data in grid, select rows, show loading progress
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import ContentBlock from 'material-ui/svg-icons/content/block';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import LinearProgress from 'material-ui/LinearProgress';
import './paginatedgrid.css';

const PaginatedGrid = (props) => {

  const { selection, onSelectRow,
    header, sortableCols = [], currentSort = {},
    onSort, columnWidths, hasMore, isLoading, items,
    tableHeight, onLoadMore, totalResults, data,
    displayRowCheckbox, displaySelectAll } = props

  const isSelected = (id) => {
    // return ¸¸¸¸props.selection.indexOf(id) !== -1
    return !!selection.find(item => item._id === id);
  }

  const handleRowSelection = (selectedRows) => {
    if (onSelectRow) onSelectRow(selectedRows);
  }

  const renderRow = (item, i, dataItem) => {

    return (
      <TableRow key={`r${i}`} selected={isSelected(dataItem._id)}>
        {
          item.map((rowItem, index) => (
            <TableRowColumn style={{ width: columnWidths[index] }} key={rowItem._id}>
              {rowItem}
            </TableRowColumn>))
        }
      </TableRow>
    );
  }

  const renderHeader = () => {

    return (
      <TableRow>
        {header.map((headerCol, i) => (
          <TableHeaderColumn style={{ width: columnWidths[i] }} key={headerCol.id}>
            <div className="header-item-container">
              {headerCol.title}
              {sortableCols.indexOf(headerCol.id) !== -1 &&
                <div className="sort-arrows">
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => onSort(headerCol.id, 1)}
                    onKeyPress={() => onSort(headerCol.id, 1)}
                    style={{ color: currentSort[headerCol.id] === 1 ? '#000' : '#cdcdcd' }}
                  >
                    ▲
                  </span>
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => onSort(headerCol.id, -1)}
                    onKeyPress={() => onSort(headerCol.id, -1)}
                    style={{ color: currentSort[headerCol.id] === -1 ? '#000' : '#cdcdcd' }}
                  >
                    ▼
                  </span>
                </div>
              }
            </div>
          </TableHeaderColumn>
        ))}
      </TableRow>
    );
  }

  const tableWidth = columnWidths.reduce((sum, value) => sum + value) + 52;
  return (
    <div className="paginated-grid-cont">
      <div className="progressbar-cont">
        {isLoading &&
          <LinearProgress mode="indeterminate" />
        }
      </div>
      <div className="results-count">
        Results: {!isLoading && totalResults ? totalResults : '...'}
      </div>
      <Table
        bodyStyle={{
          minWidth: `${tableWidth}px`,
        }}
        multiSelectables
        onRowSelection={d => handleRowSelection(d)}
        height={`${tableHeight}px`}
        fixedHeader
        className="users-table"
      >
        <TableHeader displaySelectAll={displaySelectAll}
        >
          {renderHeader()}
        </TableHeader>
        <TableBody displayRowCheckbox={displayRowCheckbox} deselectOnClickaway={false}>
          {items?.map((item, i) => renderRow(item, i, data[i]))}
        </TableBody>
      </Table>
      {!isLoading && hasMore &&
        <div className="show-more-container">
          <FlatButton onClick={() => onLoadMore()} label="Show more" secondary />
        </div>
      }
      {!isLoading && totalResults === 0
        ? (
          <div className="paginated-gris-no-results">
            <ContentBlock style={{ width: '100px', height: '100px' }} color="#d8d8d8" />
            <div>
              No Results
            </div>
          </div>
        )
        :
        null
      }
    </div>
  );

}

PaginatedGrid.propTypes = {
  tableHeight: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  displayRowCheckbox: PropTypes.bool,
  displaySelectAll: PropTypes.bool,
  header: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  sortableCols: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentSort: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.object),
  columnWidths: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.object.isRequired,
};

PaginatedGrid.defaultProps = {
  selection: [],
  displayRowCheckbox: true,
  displaySelectAll: true,
};

export default PaginatedGrid;
