import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    border: '2px solid #141B41',
    borderRadius: 5,
    backgroundColor: 'white',
  },
}));

const commonFonts = {
  fontSize: '14px',
  lineHeight: '15px',
  fontFamily: 'OverPass',
  fontStyle: 'normal',
  fontWeight: 500,
  letterSpacing: '0.05em',
};
const fontsHead = {...commonFonts, color: '#8D8D8D'};
const fontsBody = {...commonFonts, color: '#141B41'};

const fontsHeadSettingsCountriesAndZonesTab = {...commonFonts, color: '#8D8D8D', padding: '16px 0px 5px 16px'};

export const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiPaper: {
        root: {
          borderRadius: '25px',
          border: '2px solid #1B1B1B',
          //maxHeight: '580px',
        },
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: '580px',
          height: '300px',
        },
        responsiveBase: {
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'inherit',
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(20, 27, 65, 1)',
            borderRadius: '20px',
            border: 'blue',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'inherit',
          },
        },
      },
      MUIDataTableBodyCell: {
        root: fontsBody,
      },
      MUIDataTableHeadCell: {
        root: fontsHead,
      },
    },
  });

export const settingsCountriesAndZonesTab = () =>
  createTheme({
    overrides: {
      MuiPaper: {
        root: {
          borderRadius: '25px',
          border: '2px solid #1B1B1B',
        },
      },
      MUIDataTable: {
        responsiveBase: {
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'inherit',
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(20, 27, 65, 1)',
            borderRadius: '20px',
            border: 'blue',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'inherit',
          },
        },
      },
      MUIDataTableBodyCell: {
        root: fontsBody,
      },
      MUIDataTableHeadCell: {
        root: fontsHeadSettingsCountriesAndZonesTab,
      },
      MuiTableCell: {
        root: {
          padding: '0px 16px',
        },
        head: {
          padding: '16px 0px 5px',
          color: '#8D8D8D',
          position: 'sticky',
          right: 0,
          top: 0,
          background: '#ffffff',
          zIndex: 100,
        },
      },
    },
  });
