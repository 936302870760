
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import Datetime from "react-datetime"
import 'react-datetime/css/react-datetime.css'
import '../static/styles/userTotalTips.css'
import ActionDone from 'material-ui/svg-icons/action/done';
import IconButton from 'material-ui/IconButton';
import * as usersActions from '../actions/usersactions';
import { getPermission } from '../utils/cookies';
import NoPermission from '../components/nopermission';
import { decodePermissions } from '../utils/permissionUtil';
import { withRouter } from '../utils/withRouter';

class UserTotalTips extends Component {
    constructor(props) {
        super(props);
        const permission = decodePermissions(getPermission());
        this.state = {
            tips: [],
            year: moment(),
            permission,
            prevYear: null,
            total: 0,
        };
    }

    componentDidMount() {
        this.props.getTips(this.props.params.id, moment().format("YYYY"))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.usersReducerOld.loadingTips !== this.props.usersReducerOld.loadingTips && !this.props.usersReducerOld.loadingTips) {
            let total = 0
            let month = [{ month: "January", total: 0 }, { month: "February", total: 0 },
            { month: "March", total: 0 },
            { month: "April", total: 0 }, { month: "May", total: 0 }, { month: "June", total: 0 },
            { month: "July", total: 0 }, { month: "August", total: 0 }, { month: "September", total: 0 },
            { month: "October", total: 0 }, { month: "November", total: 0 }, { month: "December", total: 0 }]
            this.props.usersReducerOld.tips && this.props.usersReducerOld.tips.map(item => {
                total += item.tip
                month.map(el => {
                    if (moment(item.date).format("MMMM") === el.month) {
                        el.total += item.tip
                    }
                    return el
                })
                return item
            })
            this.setState({ tips: month, total })
        }
    }

    onChangeDate = (e) => {
        this.setState({ year: e, prevYear: this.state.year })
    }

    handleGetTips = () => {
        this.props.getTips(this.props.params.id, moment(this.state.year).format("YYYY"))

    }

    render() {
        const {
            usersReducerOld, onOpenDrawer,
        } = this.props;

        const {
            permission,
            tips,
            year,
            total
        } = this.state;

        return permission.users ?
            <div className="users-container">
                <div className="App-header">
                    <IconButton onClick={() => this.props.navigate(-1)}>
                        <NavigationArrowBack color="white" />
                    </IconButton>
                    <span className="tip-user-name">{usersReducerOld.tipUser}</span>
                    <div className="filter-group">
                        <Datetime
                            viewMode="years"
                            value={moment(year)}
                            onChange={(e) => this.onChangeDate(e)}
                            dateFormat="YYYY"
                            closeOnSelect={true}
                        />
                        <IconButton tooltip="Start Search" onClick={() => this.handleGetTips()}>
                            <ActionDone color={"white"} />
                        </IconButton>
                    </div>
                    <span className="header-placeholder" />
                </div>
                <div className="container">
                    <div className="margin-top-20 tip-header">
                        <h4>Monthly Total Tips</h4>
                        <div className="tip-total"> <h5>Total: </h5>{total}</div>
                    </div>
                    <table className="margin-top-20 table table-bordered">
                        <thead>
                            <tr>
                                <td>Month</td>
                                <td>Total Tips</td>
                            </tr>
                        </thead>
                        <tbody>
                            {tips && tips.map((tip, key) => {
                                return <tr key={key}>
                                    <td>{tip.month}</td>
                                    <td>{tip.total}</td>
                                </tr>
                            }
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </div >
            : <NoPermission onOpenDrawer={onOpenDrawer} />;
    }
}

UserTotalTips.propTypes = {
    usersReducerOld: PropTypes.object.isRequired,
    onOpenDrawer: PropTypes.func.isRequired,

};

const mapStateToProps = ({ usersReducerOld }) => ({
    usersReducerOld,
});

const mapDispatchToProps = dispatch => ({
    setActiveTips: (tips) => dispatch(usersActions.setActiveTips(tips)),
    getTips: (id, year) => dispatch(usersActions.getTips(id, year)),
    dispatch,
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserTotalTips));
