import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import { Dialog, IconButton, MenuItem, IconMenu } from 'material-ui';
import SearchField from '../components/searchfield';
import PaginatedGrid from '../components/paginatedgrid';
import * as itemsActions from '../actions/itemsactions';
import * as itemAction from '../actions/itemactions';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import withConfirm from '../utils/withConfirm';
import '../static/styles/itemlist.css';
import '../commoncss/styles.css';
import { withRouter } from '../utils/withRouter';
import { tableData } from '../utils/tableData';
class ItemList extends Component {
    constructor(props) {
        super(props);
        const permission = decodePermissions(getPermission());
        this.state = {
            permission,
            selectedItems: [],
            videoDialogOpen: false,
            videoUrl: null,
        };
    }

    componentDidMount() {
        if (this.state.permission.items) {
            this.props.fetchItems();
        }
    }

    doSearch(query) {
        this.props.fetchItems(query);
    }

    processAction(selectedAction) {
        const { selectedItems } = this.state;
        const { deleteItem } = this.props;

        switch (selectedAction) {
            case 'delete':
                if (selectedItems && selectedItems.length > 0) {
                    selectedItems.forEach((selectedItem) => {
                        deleteItem(selectedItem._id);
                    });
                }
                break;
            case 'edit':
                if (selectedItems && selectedItems.length > 0) {
                    this.props.navigate(`/item?id=${selectedItems[0]._id}`);
                }
                break;
            default:
                break;
        }
    }

    handleSelection = (selectedItems) => {
        const { itemsReducer: { items } } = this.props;
        const result = [];
        selectedItems.forEach((selectedItem) => {
            result.push(items[selectedItem]);
        });
        this.setState({ selectedItems: result });
    };

    onOpenVideoDialog = url => {
        this.setState({
            videoDialogOpen: true,
            videoUrl: url,
        });
    };

    render() {
        const { itemList: { columnWidths, headerData } } = tableData
        const {
            itemsReducer, fetchMoreItems, fetchItemsSortChanged, onOpenDrawer,
        } = this.props;
        const { selectedItems, permission, videoUrl, videoDialogOpen } = this.state;
        const tableHeight = window.innerHeight - 217;
        const deleteEnabled = selectedItems && selectedItems.length > 0;
        const editEnabled = selectedItems && selectedItems.length === 1;

        return permission.items ?
            <div className="items-container">
                <div className="App-header">
                    <IconButton onClick={onOpenDrawer}>
                        <NavigationMenu color="white" />
                    </IconButton>
                    <span>Item name</span>
                    <div className="header-search-area">
                        <SearchField
                            onRequestSearch={query => this.doSearch({
                                ...itemsReducer.searchQuery,
                                name: query,
                            })}
                        />
                    </div>
                    <span>Owner name</span>
                    <div className="header-search-area">
                        <SearchField
                            onRequestSearch={query => this.doSearch({
                                ...itemsReducer.searchQuery,
                                owner: query,
                            })}
                        />
                    </div>
                    <span className="header-placeholder" />
                    <IconMenu
                        iconButtonElement={<IconButton><MoreVertIcon color="#fff" /></IconButton>}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        <MenuItem
                            primaryText="Edit"
                            disabled={!editEnabled}
                            onClick={() => this.processAction('edit')}
                        />
                        <MenuItem
                            primaryText="Delete"
                            disabled={!deleteEnabled}
                            onClick={() => withConfirm(() => this.processAction('delete'))}
                        />
                    </IconMenu>
                </div>

                <div className="items-collections-tabs">
                    <Link to="/items" className="items-collections-tab items-collections-tab-active">Items</Link>
                    <Link to="/collections" className="items-collections-tab">Collections</Link>
                </div>

                <PaginatedGrid
                    data={itemsReducer.items}
                    tableHeight={tableHeight}
                    columnWidths={columnWidths}
                    hasMore={itemsReducer.hasMore}
                    isLoading={itemsReducer.isLoading}
                    header={headerData}
                    totalResults={itemsReducer.totalCount}
                    onSelectRow={this.handleSelection}
                    selection={selectedItems}
                    sortableCols={[headerData[1].id, headerData[2].id, headerData[3].id]}
                    currentSort={itemsReducer.sort}
                    onSort={(column, direction) => fetchItemsSortChanged(column, direction)}
                    items={itemsReducer.items.map(item => [
                        <img alt="" className="avatar" src={item.imageUrl} />,
                        <span><a href={`/item?id=${item._id}`}>{item.description}</a></span>,
                        <span>{moment(item.created).format('lll')}</span>,
                        <span>{moment(item.updated).format('lll')}</span>,
                        <span>
                            {item.videoUrl &&
                                <a href={null} role="button" onClick={event => {
                                    event.preventDefault();
                                    this.onOpenVideoDialog(item.videoUrl);
                                }}>
                                    Watch Video
                                </a>
                            }
                        </span>,
                        <span>{item.price}</span>,
                        <span>{item.owner ? item.owner.displayName : ''}</span>,
                        <span>{item.event ? item.event.title : ''}</span>,
                    ])}
                    onLoadMore={() => fetchMoreItems()}
                />
                <Dialog
                    contentStyle={{ width: '450px' }}
                    modal={false}
                    open={videoDialogOpen}
                    onRequestClose={() => this.setState({ videoDialogOpen: false, videoUrl: null })}
                >
                    <video src={videoUrl} controls width="400" height="400">
                        Sorry, your browser doesn't support embedded videos,
                        but don't worry, you can <a href={videoUrl}>download it</a>
                        and watch it with your favorite video player!
                    </video>
                </Dialog>
            </div>
            :
            <NoPermission onOpenDrawer={onOpenDrawer} />;
    }
}

ItemList.propTypes = {
    itemsReducer: PropTypes.object.isRequired,
    onOpenDrawer: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchMoreItems: PropTypes.func.isRequired,
    fetchItemsSortChanged: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
};

const mapStateToProps = ({ itemsReducer }) => ({
    itemsReducer,
});

const mapDispatchToProps = dispatch => ({
    fetchItems: query => dispatch(itemsActions.fetchItems(query)),
    fetchMoreItems: () => dispatch(itemsActions.fetchMoreItems()),
    fetchItemsSortChanged: (column, direction) =>
        dispatch(itemsActions.fetchItemsSortChanged(column, direction)),
    deleteItem: id => dispatch(itemAction.deleteItem(id)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemList));
