import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Paper, IconButton, Grid, Typography, List, ListItem, ListItemText, Collapse, Checkbox } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { decodePermissions, encodePermission } from '../utils/permissionUtil';
import { getUserId } from '../utils/cookies';
import withConfirm from '../utils/withConfirm';
import * as bouserActions from '../actions/bouseractions';
import { getPermission } from '../utils/cookies';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ToastContainer, toast } from "react-toastify";
import { AdminField, Email, FullName, Phone, RecoverEmailInput, Status, TypeField } from '../components/fields/bouserField';
import 'react-toastify/dist/ReactToastify.css';
import '../static/styles/bouser.css';
import { withRouter } from '../utils/withRouter';
import queryString from 'query-string';
import { ApplyPermissions } from '../components/BOUserPermissions';
import { ToastNotification } from '../utils/toastify';

const DEFAULT_COUNTRY_CODE = 'il'; // Israel

const btnStyle = {

  cancelBtn: {
    backgroundColor: 'transparent',
  },
  saveBtn: {
    textTransform: 'none',
    background: '#edff00',
    border: '2px solid #141b41',
  },
  recoveryButton: {
    marginTop: '29px',
    color: '#007AFF',
    borderRadius: '8px',
    border: '2px solid #007AFF',
    minWidth: '150px',
    textTransform: 'none',
    fontSize: '14px',
    height: '43px'
  }
}
class BOUser extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      id: null,
      email: null,
      fullName: null,
      status: null,
      password: null,
      errorTextFullName: '',
      permissionDecoded: {},
      errorTextPassword: '',
      phone: '',
      countryCode: DEFAULT_COUNTRY_CODE,
      errorPhone: '',
      isEnable2fa: false,
      permission,
      show: {},
      type: ''
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }


  locationQuery = this?.props?.location?.state?.userId
  componentDidMount() {
    if (this.locationQuery) {
      this.props.fetchBOUser(this.locationQuery);
    } else {
      this.setState({ id: 'new', status: 'active' });
    }
  }

  componentWillReceiveProps(newProps) {
    const { id } = this.state;
    const { boUserReducer: { bouser } } = newProps;
    if (
      newProps.boUserReducer &&
      newProps.boUserReducer.bouser &&
      newProps.boUserReducer.bouser._id !== id
    ) {
      this.setState({
        id: bouser._id || this.locationQuery,
        email: bouser.email,
        fullName: bouser.fullName,
        status: bouser.status,
        permissionDecoded: decodePermissions(bouser.permission),
        phone: bouser.phone,
        countryCode: bouser.countryCode,
        isEnable2fa: !bouser.isDisable2fa,
      });
    }
  }

  processPermission(permission, isSet) {

    const { permissionDecoded } = this.state;
    permissionDecoded[permission] = isSet;
    this.setState({ permissionDecoded });
  }

  validateData() {
    const {
      fullName, email, password, id, phone, isEnable2fa,
    } = this.state;
    if (!fullName) {
      this.setState({ errorTextFullName: 'Full name should not be empty' });
      return false;
    }
    if (!email) {
      this.setState({ errorTextEmail: 'E-mail should not be empty' });
      return false;
    }
    return true;
  }

  requestUpdate = () => {
    if (!this.validateData()) {
      return;
    }
    const {
      id, fullName, email, status, password, permissionDecoded, phone, countryCode, isEnable2fa,
      type } = this.state;

    const { createBOUser, updateBOUser } = this.props;

    const params = {
      _id: id === 'new' ? null : this.locationQuery,
      fullName,
      email,
      status,
      permission: encodePermission(permissionDecoded),
      password: id === 'new' ? password : undefined,
      phone,
      countryCode,
      isDisable2fa: !isEnable2fa,
      type
    };
    id === 'new' ? createBOUser(params) : updateBOUser(params)
  }

  handleClick(id) {
    this.setState((prevState) => ({
      ...prevState,
      show: {
        ...prevState.show,
        [id]: !prevState.show[id]
      }
    }));
  }

  handleChange({ target: { name, value } }) {
    this.setState(prevState => ({ ...prevState, [name]: value }))
  }
  requestRegeneratePassword() {
    this.props.regeneratePassword(this.state.id);
  }

  render() {
    const { boUserReducer } = this.props;
    const {
      id,
      fullName,
      errorTextFullName,
      email,
      errorTextEmail,
      status,
      permissionDecoded,
      password,
      errorTextPassword,
      phone,
      countryCode,
      errorPhone,
      isEnable2fa,
      open,
      type,
      permission,
    } = this.state;

    const isMyId = id && id !== 'new' && id === getUserId();

    const permissionsArr = [
      { label: 'Can access users', checked: permissionDecoded.users, type: 'users' },
      { label: 'Can access events', checked: permissionDecoded.events, type: 'events' },
      { label: 'Can access stores', checked: permissionDecoded.stores, type: 'stores' },
      { label: 'Can access payments', checked: permissionDecoded.payments, type: 'payments' },
      { label: 'Can manage BO users', checked: permissionDecoded.bousers, type: 'bousers' },
      { label: 'Can access items/collections', checked: permissionDecoded.items, type: 'items' },
      { label: 'Can access coupons', checked: permissionDecoded.coupons, type: 'coupons' },
      { label: 'Can access chats', checked: permissionDecoded.chats, type: 'chats' },
      { label: 'Can access set shupper/supershupper', checked: permissionDecoded.set_shupper_supershupper, type: 'set_shupper_supershupper' },
      { label: 'Can see payments is status PENDING', checked: permissionDecoded.seePending, type: 'seePending' },
      { label: 'Can see payments in status REJECTED', checked: permissionDecoded.seeRejected, type: 'seeRejected' },
      { label: 'Can see payments in status CANCELED', checked: permissionDecoded.seeCanceled, type: 'seeCanceled' },
      { label: 'Can see payments in status COMPLETED', checked: permissionDecoded.seeFulfilled, type: 'seeFulfilled' },
      { label: 'Can see payments in status REFUNDED', checked: permissionDecoded.seeRefunded, type: 'seeRefunded' },
      { label: 'Can see payments in status SHIPPING', checked: permissionDecoded.seeShipping, type: 'seeShipping' },
      { label: 'Can Refund a Payment', checked: permissionDecoded.refundPayment, type: 'refundPayment' },
      { label: 'Can Cancel a Payment', checked: permissionDecoded.cancelPayment, type: 'cancelPayment' },
      { label: 'Can Move Payment to Shipping', checked: permissionDecoded.movePaymentToShipping, type: 'movePaymentToShipping' },
      { label: 'Can Set Shupper of the Day', checked: permissionDecoded.setShupperOfTheDay, type: 'setShupperOfTheDay' },
      { label: 'Can grant permissions for other user', checked: permissionDecoded.grantPermissionsForOtherUsers, type: 'grantPermissionsForOtherUsers' },
      { label: 'Can change payment status', checked: permissionDecoded.changePaymentStatus, type: 'changePaymentStatus' },
      { label: 'Can setup duty off timetables', checked: permissionDecoded.canConfigureTimeTable, type: 'canConfigureTimeTable' },
      { label: 'Can see logs', checked: permissionDecoded.securitylogs, type: 'securitylogs' },
      { label: 'Can approve/decline brands', checked: permissionDecoded.canApproveBrands, type: 'canApproveBrands' },
      { label: 'Can approve/decline shuppers', checked: permissionDecoded.canApproveShuppers, type: 'canApproveShuppers' },
      { label: 'Can approve/decline brand reviews', checked: permissionDecoded.canManageReviews, type: 'canManageReviews' },
      { label: 'Can manage checkins', checked: permissionDecoded.canManageCheckins, type: 'canManageCheckins' },
      { label: 'Can forward chats', checked: permissionDecoded.canForwardChats, type: 'canForwardChats' },
    ]
    const rolesArr = [
      { id: 1, title: 'Type' },
      { id: 2, title: 'Admin' },
      { id: 3, title: 'BO Users' },
      { id: 4, title: 'Users' },
      { id: 5, title: 'Orders' },
    ]

    boUserReducer.isUpdating && toast.success(`BO User is successfully ${id === 'new' ? 'created' : 'updated'}`)
    boUserReducer.updateError && toast.error(`Error to ${id === 'new' ? 'create' : 'update'}`)
    console.log('bouser', boUserReducer)
    return id ?
      <div className='main' >
        <div className="App-header">
          <IconButton onClick={() => this.props.navigate(-1)}><ArrowBackIcon color="primary" /></IconButton>
        </div>
        <ToastNotification hideBar={false} position='top-center' />

        <Grid container direction='row' justifyContent='center' >
          <Typography variant='h4'>{id === 'new' ? 'ADD BO USER' : `EDIT USER-${boUserReducer?.bouser?.fullName}`}</Typography>
        </Grid>
        <Grid container justifyContent='center' className='grid-container' >
          <Paper className='paper'  >
            <Grid item >
              <form className="bouser-container" >
                <Grid container direction='column' spacing={1} >
                  <Grid item >
                    <Typography style={{ marginBottom: 20 }} variant='h5'>{id === 'new' && 'Profile Information'}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Full name</Typography>
                    <FullName
                      onChange={this.handleChange}
                      fullName={fullName}
                      error={errorTextFullName}
                      helperText={errorTextFullName} />
                  </Grid>
                  <Grid item>
                    <Typography>Email</Typography>
                    <Email
                      error={errorTextEmail}
                      helperText={errorTextEmail}
                      email={email}
                      onChange={this.handleChange} />
                  </Grid>
                  {id !== 'new' &&
                    <>
                      <Grid item>
                        <Grid container wrap='wrap' direction='row' justifyContent='space-between' spacing={2}>
                          <Grid item variant='outlined' lg={8} xl={8} md={8} sm={8} xs={12}>
                            <Typography>Email</Typography>
                            <RecoverEmailInput />
                          </Grid>
                          <Grid item lg={4} xl={4} md={4} sm={4} >
                            <Button variant='outlined'
                              style={btnStyle.recoveryButton}>Send Recovery Link</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={2}>
                          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                            <Typography>Phone number</Typography>
                            <Phone phone={phone} country={countryCode}
                              onChange={(phoneNumber, data) => this.setState({
                                phone: phoneNumber, countryCode: data.countryCode, errorPhone: '',
                              })} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography>Status</Typography>
                        <Status status={status} onChange={this.handleChange} />
                      </Grid>
                    </>
                  }

                  <Grid item>
                    <Typography variant='h5'>Permissions</Typography>
                  </Grid>
                  <Grid item>
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader">
                      {rolesArr.map((item, index) => {
                        return (
                          <div key={`${index}-items`}>
                            <ListItem button onClick={() => this.handleClick(item.id)}>
                              <ListItemText primary={item.title} />
                              {this.state.show[item.id] ? <ExpandMore /> : <ExpandLess style={{ transform: 'rotate(90deg)' }} />}
                            </ListItem>
                            <Collapse in={this.state.show[item.id]} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                <ListItem>
                                  {item.title === 'Type' &&
                                    <TypeField value={type} onChange={this.handleChange} />
                                  }
                                  {item.title === 'Admin' &&
                                    isMyId && permission.grantPermissionsForOtherUsers !== 0 &&
                                    <Paper style={{ width: '100%', maxHeight: '300px', overflow: 'auto', padding: 10 }}>

                                      {permissionsArr.map((item, index) => {
                                        return (
                                          <ApplyPermissions
                                            key={`${index}-permissions`}
                                            view={item.view}
                                            label={item.label}
                                            checked={item.checked}
                                            onCheck={(_, isSet) => this.processPermission(item.type, isSet)} />
                                        )
                                      })}
                                    </Paper>
                                  }
                                </ListItem>
                              </List>
                            </Collapse>
                          </div>
                        )
                      })}
                    </List>

                    <Grid container direction='row' justifyContent='space-between'>
                      <Grid item>
                        <Button onClick={() => this.setState({ fullName: "", email: '', permissionDecoded: {}, permission: {} })} variant='text' className='cancelBtn' style={btnStyle.cancelBtn}>
                          <Typography className='label-text'>Cancel</Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <div className="">{boUserReducer.isUpdating
                          ? <CircularProgress />
                          : <Button
                            style={btnStyle.saveBtn}
                            variant='contained'
                            className='saveBtn'
                            onClick={() => withConfirm(this.requestUpdate)}
                          >{id === 'new' ? 'Create' : 'Save'}</Button>}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper >
        </Grid>
      </div >
      : null;
  }
}

BOUser.propTypes = {
  location: PropTypes.object.isRequired,
  boUserReducer: PropTypes.object.isRequired,
  fetchBOUser: PropTypes.func.isRequired,
  updateBOUser: PropTypes.func.isRequired,
  createBOUser: PropTypes.func.isRequired,
  regeneratePassword: PropTypes.func.isRequired,
};

const mapStateToProps = ({ boUserReducer }) => ({
  boUserReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchBOUser: id => dispatch(bouserActions.fetchBOUser(id)),
  updateBOUser: store => dispatch(bouserActions.updateBOUser(store)),
  createBOUser: store => dispatch(bouserActions.createBOUser(store)),
  regeneratePassword: id => dispatch(bouserActions.regeneratePassword(id)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(BOUser));
