import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import IconButton from "material-ui/IconButton";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import { Dialog } from "material-ui";
import moment from "moment";
import * as checkinsActions from "../actions/checkinsActions";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import MenuItem from "material-ui/MenuItem";
import IconMenu from "material-ui/IconMenu";
import LinearProgress from "material-ui/LinearProgress";
import PaginatedGrid from "../components/paginatedgrid";
import "../static/styles/checkins.css";
import ImageViewer from "../components/imageviewer";
import { getPermission } from "../utils/cookies";
import { decodePermissions } from "../utils/permissionUtil";
import Select from "react-select";
import ActionDone from "material-ui/svg-icons/action/done";
import withConfirm from "../utils/withConfirm";
import { withRouter } from "../utils/withRouter";
import { tableData } from "../utils/tableData";
class Checkins extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());

    this.state = {
      permission,
      dataUser: null,
      limit: 20,
      skip: 0,
      isOpenImageViewer: false,
      videoDialogOpen: false,
      videoUrl: null,
      images: [],
      selectedCheckins: [],
      selectedShupper: null,
      selectedStatus: null
    };
  }

  componentDidMount() {
    const { limit, skip, permission } = this.state;
    if (permission.canManageCheckins) {
      this.props.getCheckins(skip, limit);
      this.props.getShuppers();
      this.props.getStores();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.checkinsReducer.deleteCheckinSuccess !==
      this.props.checkinsReducer.deleteCheckinSuccess &&
      this.props.checkinsReducer.deleteCheckinSuccess
    ) {
      const { limit, skip, selectedShupper, selectedStatus } = this.state;
      this.props.getCheckins(
        skip,
        limit,
        selectedShupper ? selectedShupper.value : null,
        selectedStatus ? selectedStatus.value : null
      );
      this.props.resetCheckins();
    }
  }
  closeImageViewer = () => {
    this.setState({
      isOpenImageViewer: false
    });
  };
  openImageViewer = images => {
    this.setState({
      isOpenImageViewer: true,
      images: [{ src: images }]
    });
  };

  onOpenVideoDialog = url => {
    this.setState({
      videoDialogOpen: true,
      videoUrl: url
    });
  };
  setSelected = selectedItems => {
    const {
      checkinsReducer: { checkins }
    } = this.props;
    const result = [];
    selectedItems.forEach(selectedItem => {
      result.push(checkins.checkins[selectedItem]);
    });
    this.setState({ selectedCheckins: result });
  };
  processAction(selectedAction) {
    const { selectedCheckins } = this.state;
    const { deleteCheckins } = this.props;
    switch (selectedAction) {
      case "delete":
        if (selectedCheckins && selectedCheckins.length > 0) {
          const ids = selectedCheckins.map(i => i._id);
          ids.forEach(item => {
            deleteCheckins(item);
            return item;
          });
        }
        break;
      case "edit":
        if (selectedCheckins && selectedCheckins.length > 0) {
          const checkin = selectedCheckins[0];
          this.props.navigate(`/checkin/edit/${checkin._id}`);
        }
        break;
      case "show":
        if (selectedCheckins && selectedCheckins.length > 0) {
          const checkin = selectedCheckins[0];
          this.props.navigate(`/checkin/show/${checkin._id}`);
        }
        break;
      case "create":
        this.props.navigate(`/checkin/create`);
        break;
      default:
        break;
    }
    this.setState({ selectedUsers: [] });
  }
  onChangeSelect = (e, name) => {
    this.setState({ [name]: e });
  };
  handleGetCheckins = () => {
    const { limit, skip, selectedShupper, selectedStatus } = this.state;

    if (this.state.permission.canManageCheckins) {
      this.props.getCheckins(
        skip,
        limit,
        selectedShupper ? selectedShupper.value : null,
        selectedStatus ? selectedStatus.value : null
      );
    }
  };
  render() {
    const { checkins: { columnWidths, headerData } } = tableData
    const { onOpenDrawer, checkinsReducer } = this.props;

    const {
      skip,
      limit,
      isOpenImageViewer,
      images,
      selectedShupper,
      permission,
      selectedStatus,
      videoDialogOpen,
      videoUrl,
      selectedCheckins
    } = this.state;

    const editEnabled = selectedCheckins && selectedCheckins.length === 1;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "white" : "blue",

        padding: 20
      }),
      control: () => ({
        width: 200,
        background: "white",
        display: "flex"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };
    return (
      <div>
        <div className="App-header checkins-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <div className="select-group">
            <Select
              styles={customStyles}
              onChange={e => this.onChangeSelect(e, "selectedShupper")}
              value={selectedShupper}
              isClearable={true}
              placeholder="Select Shupper"
              options={[
                ...checkinsReducer.shuppers,
                ...checkinsReducer.stores
              ].map(item => {
                return { label: item.displayName, value: item._id };
              })}
            />
            <Select
              styles={customStyles}
              onChange={e => this.onChangeSelect(e, "selectedStatus")}
              value={selectedStatus}
              isClearable={true}
              placeholder="Select Status"
              options={[
                { label: "upcoming", value: "upcoming" },
                { label: "live", value: "live" },
                { label: "finished", value: "finished" }
              ]}
            />
            <IconButton
              tooltip="Start Search"
              onClick={() => this.handleGetCheckins()}
            >
              <ActionDone color={"white"} />
            </IconButton>
          </div>
          <IconMenu
            iconButtonElement={
              <IconButton>
                <MoreVertIcon color="#fff" />
              </IconButton>
            }
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}
          >
            <MenuItem
              primaryText="Edit"
              disabled={!editEnabled}
              onClick={() => this.processAction("edit")}
            />
            <MenuItem
              primaryText="Show"
              disabled={!editEnabled}
              onClick={() => this.processAction("show")}
            />
            <MenuItem
              primaryText="Create Checkin"
              onClick={() => this.processAction("create")}
            />
            <MenuItem
              primaryText="Delete"
              disabled={!editEnabled}
              onClick={() => withConfirm(() => this.processAction("delete"))}
            />
          </IconMenu>
        </div>
        <div className="progressbar-container">
          {checkinsReducer.loading && <LinearProgress mode="indeterminate" />}
        </div>
        {permission.canManageCheckins ? (
          <div>
            <Dialog
              contentStyle={{ width: "450px" }}
              modal={false}
              open={videoDialogOpen}
              onRequestClose={() =>
                this.setState({ videoDialogOpen: false, videoUrl: null })
              }
            >
              <video src={videoUrl} controls width="400" height="400">
                Sorry, your browser doesn't support embedded videos, but don't
                worry, you can <a href={videoUrl}>download it</a>
                and watch it with your favorite video player!
              </video>
            </Dialog>
            <ImageViewer
              isOpen={isOpenImageViewer}
              onClose={this.closeImageViewer}
              images={images}
            />
            <PaginatedGrid
              data={
                checkinsReducer.checkins && checkinsReducer.checkins.checkins
              }
              columnWidths={columnWidths}
              header={headerData}
              hasMore={checkinsReducer.hasMore}
              selection={selectedCheckins}
              onSelectRow={this.setSelected}
              isLoading={checkinsReducer.isLoading}
              totalResults={
                (checkinsReducer.checkins &&
                  checkinsReducer.checkins.totalCheckins) ||
                0
              }
              onLoadMore={() =>
                this.props.getCheckins(
                  skip,
                  limit + 20,
                  selectedShupper ? selectedShupper.value : null,
                  selectedStatus ? selectedStatus.value : null
                )
              }
              items={
                checkinsReducer.checkins
                  ? checkinsReducer.checkins.checkins
                    .filter(item => !item.deleted)
                    .map(checkin => [
                      <span className="word-wrap">
                        {moment(checkin.date).format("DD MMMM YYYY")}
                      </span>,

                      <span className="word-wrap">
                        {checkin.user && checkin.user.displayName}
                      </span>,
                      <span className="word-wrap">
                        {checkin.brandStr
                          ? checkin.brandStr
                          : checkin.brand && checkin.brand.title}
                      </span>,

                      <span className="word-wrap">{checkin.status}</span>,
                      <span className="word-wrap">
                        {checkin.description}
                      </span>,
                      <span>
                        {checkin.video ? (
                          <a
                            href={null}
                            role="button"
                            onClick={event => {
                              event.preventDefault();
                              this.onOpenVideoDialog(checkin.video);
                            }}
                          >
                            Watch Video
                          </a>
                        ) : checkin.image ? (
                          <img
                            className="checkin-table-image"
                            onClick={() =>
                              this.openImageViewer(checkin.image)
                            }
                            onError={e => {
                              e.target.className = "display-none";
                            }}
                            src={checkin.image}
                            alt={checkin.status}
                          />
                        ) : null}
                      </span>
                    ])
                  : []
              }
            />
          </div>
        ) : (
          <div className="text-center">
            <h2>No Access</h2>
          </div>
        )}
      </div>
    );
  }
}

Checkins.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired
};
const mapStateToProps = ({ checkinsReducer }) => ({
  checkinsReducer
});
const mapDispatchToProps = dispatch => ({
  getCheckins: (skip, limit, shupper, status) =>
    dispatch(checkinsActions.getCheckins(skip, limit, shupper, status)),
  getShuppers: () => dispatch(checkinsActions.getShuppers()),
  getStores: () => dispatch(checkinsActions.getStores()),
  resetCheckins: () => dispatch(checkinsActions.resetCheckins()),
  deleteCheckins: ids => dispatch(checkinsActions.deleteCheckins(ids))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkins));
