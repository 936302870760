import types from './actionTypes';

export const fetchPackages = data => ({
  type: types.FETCH_PACKAGES,
  data
});

export const fetchMorePackages = () => ({
  type: types.FETCH_MORE_PACKAGES,
});

export const fetchPackagesSuccess = data => ({
  type: types.FETCH_PACKAGES_SUCCESS,
  data,
});

export const fetchPackagesFailed = reason => ({
  type: types.FETCH_PACKAGES_FAILED,
  reason,
});
