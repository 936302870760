/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 10/07/2018.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import IconButton from 'material-ui/IconButton';
import RaisedButton from 'material-ui/RaisedButton';
import ClearIcon from 'material-ui/svg-icons/content/clear';
import Chip from 'material-ui/Chip';
import PaginatedGrid from '../components/paginatedgrid';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import NoPermission from '../components/nopermission';
import ValuePicker from '../components/valuePicker';
import * as usersActions from '../actions/usersactions';
import '../static/styles/shupperofthedaylist.css';
import { tableData } from '../utils/tableData';

const columns = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
class ShupperOfTheDayList extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      shupperPickerOpen: false,
      selectedWeekday: '',
    };
  }

  componentDidMount() {
    if (this.state.permission.events) {
      this.props.getShuppersOfTheDay();
    }
  }

  onRemoveShupper = weekday => {
    this.props.removeShupperOfTheDay(weekday)
  };

  onSetShupper = shupper => {
    this.props.setShupperOfTheDay(this.state.selectedWeekday, shupper);
    this.setState({ shupperPickerOpen: false, selectedWeekDay: '' });
  };

  onOpenShuppersDialog = weekday => {
    this.setState({
      shupperPickerOpen: true,
      selectedWeekday: weekday,
    })
  };

  renderItems = items => {
    const renderedItems = [];
    for (let index = 0; index < columns.length; index++) {
      const correctItem = items.find(i => i.weekday === columns[index]);
      let component = null;
      if (correctItem) {
        component = () => (
          <span key={correctItem.shupper.displayName}>
            <p style={{ display: 'flex' }}>
              <span style={{ marginTop: 5 }}>
                <Chip>{correctItem.shupper.displayName}</Chip>
              </span>
              <IconButton
                onClick={() => { this.onRemoveShupper(correctItem.weekday) }}
                style={{
                  width: 20,
                  height: 20,
                  padding: 0,
                  margin: 0
                }}
              >
                <ClearIcon color="black" />
              </IconButton>
            </p>
            <RaisedButton onClick={() => { this.onOpenShuppersDialog(columns[index]) }}>Set</RaisedButton>
          </span>
        )
      } else {
        component = () => (
          <span key={`key${index}`}>
            <RaisedButton style={{ marginTop: '53px' }} onClick={() => { this.onOpenShuppersDialog(columns[index]) }}>Set</RaisedButton>
          </span>
        )
      }
      renderedItems.push(component());
    }
    return renderedItems;
  };

  render() {
    const { shupperoftheDayList: { columnWidths, headerData } } = tableData
    const {
      shuppersOfTheDay, onOpenDrawer, shuppersList, isShuppersLoading, getOnlyShuppers,
    } = this.props;
    const { permission, shupperPickerOpen, selectedWeekday } = this.state;
    const tableHeight = window.innerHeight - 177;
    const selectedShupper = shuppersOfTheDay?.find(shupper => shupper.weekday === selectedWeekday);

    return (
      permission.setShupperOfTheDay ?
        <div className="shupper-of-the-day-container">
          <div className="App-header">
            <IconButton onClick={onOpenDrawer}>
              <NavigationMenu color="white" />
            </IconButton>
            <span style={{ color: '#ffffff' }}>Shuppers of the Day</span>
          </div>
          <PaginatedGrid
            data={[shuppersOfTheDay]}
            tableHeight={tableHeight}
            columnWidths={columnWidths}
            isLoading={false}
            header={headerData}
            items={[this.renderItems(shuppersOfTheDay)]}
          />
          <ValuePicker
            open={shupperPickerOpen}
            title={`Enter the shupper's name for ${selectedWeekday}`}
            isLoading={isShuppersLoading}
            selected={selectedShupper ? [`${selectedShupper.shupper.displayName}`] : []}
            items={shuppersList.map(user => Object.assign({
              id: user._id,
              title: `${user.displayName}, ${user.location}`,
              image: user.profileImageURL,
            }, user))}
            onSearchValue={query => getOnlyShuppers(query)}
            onRequestClose={() => this.setState({ shupperPickerOpen: false, selectedWeekDay: '' })}
            onSelectValue={item => this.onSetShupper(item)}
            hasMore={false}
            onLoadMore={() => { }}
          />
        </div>
        :
        <NoPermission onOpenDrawer={onOpenDrawer} />
    )
  }
}

ShupperOfTheDayList.propTypes = {
  shuppersOfTheDay: PropTypes.array.isRequired,
  shuppersList: PropTypes.array.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  isShuppersLoading: PropTypes.bool.isRequired,
  hasMoreShuppers: PropTypes.bool.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchMoreUsers: PropTypes.func.isRequired,
  getShuppersOfTheDay: PropTypes.func.isRequired,
  setShupperOfTheDay: PropTypes.func.isRequired,
  removeShupperOfTheDay: PropTypes.func.isRequired,
  getOnlyShuppers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  shuppersOfTheDay: state.usersReducerOld.shuppersOfTheDay,
  shuppersList: state.usersReducerOld.shuppersList,
  isShuppersLoading: state.usersReducerOld.isLoading,
  hasMoreShuppers: state.usersReducerOld.hasMore
});

const mapDispatchToProps = dispatch => ({
  //fetchUsers: query => dispatch(usersActions.fetchUsers(query, null, ['shupper'])),
  //fetchMoreUsers: () => dispatch(usersActions.fetchMoreUsers()),
  getShuppersOfTheDay: () => dispatch(usersActions.getShuppersOfTheDay()),
  setShupperOfTheDay: (weekday, shupper) => dispatch(usersActions.setShupperOfTheDay(weekday, shupper)),
  removeShupperOfTheDay: weekday => dispatch(usersActions.removeShupperOfTheDay(weekday)),
  getOnlyShuppers: name => dispatch(usersActions.getOnlyShuppers(name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShupperOfTheDayList);
