import types from './actionTypes';

export const fetchPackage = data => ({
  type: types.FETCH_PACKAGE_DETAILS,
  data
});

export const fetchPackageSuccess = data => ({
  type: types.FETCH_PACKAGE_DETAILS_SUCCESS,
  data,
});

export const fetchPackageFailed = reason => ({
  type: types.FETCH_PACKAGE_DETAILS_FAILED,
  reason,
});

export const updatePackage = data => ({
  type: types.UPDATE_PACKAGE_DETAILS,
  data
});

export const updatePackageSuccess = data => ({
  type: types.UPDATE_PACKAGE_DETAILS_SUCCESS,
  data,
});

export const updatePackageFailed = reason => ({
  type: types.UPDATE_PACKAGE_DETAILS_FAILED,
  reason,
});
