/*
Kateryna Ivanova
20/03/2018

Sagas for working with a list of item
*/
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchItemsSuccess, fetchItemsFailed, deleteItemsFailed, deleteItemsSuccess } from '../actions/itemsactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectItemsState = state => state.itemsReducer;

function* fetchItemsAsync() {
  const itemsState = yield select(selectItemsState);
  const sorting = Object.keys(itemsState.sort).map(key => `&sort[${key}]=${itemsState.sort[key]}`).join('');
  let queryName = '';
  if (itemsState.searchQuery) {
    queryName = itemsState.searchQuery.name ? `&name=${itemsState.searchQuery.name}` : '';
  }
  let queryOwner = '';
  if (itemsState.searchQuery) {
    queryOwner = itemsState.searchQuery.owner ? `&owner=${itemsState.searchQuery.owner}` : '';
  }
  const response = yield call(
    request,
    `${baseUrl}/api/item?limit=${itemsState.limit}&skip=${itemsState.offset}${queryName}${queryOwner}${sorting}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchItemsFailed(response.err.toString()));
  } else {
    yield put(fetchItemsSuccess(response.data));
  }
}

function* deleteItemsAsync(action) {
  const itemsIds = action.ids.join(',');
  const response = yield call(
    request,
    `${baseUrl}/api/item?ids=${itemsIds}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteItemsFailed(response.err.toString()));
  } else {
    yield put(deleteItemsSuccess());
  }
}

function* fetchItems() {
  yield takeLatest([`${types.FETCH_ITEMS}`, `${types.FETCH_MORE_ITEMS}`, `${types.FETCH_ITEMS_SORT_CHANGED}`,
    `${types.DELETE_ITEM_SUCCESS}`, `${types.DELETE_ITEMS_SUCCESS}`, `${types.UPDATE_ITEM_SUCCESS}`], fetchItemsAsync);
}

function* deleteItems() {
  yield takeLatest([`${types.DELETE_ITEMS}`], deleteItemsAsync);
}

export default function* itemssaga() {
  yield all([
    fetchItems(),
    deleteItems(),
  ]);
}
