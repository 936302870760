import React from 'react';
import { connect } from 'react-redux';
import TextField from '../primitives/TextField';
import ImagePicker from '../imagepicker';

const PopupPreview = ({ popupIndex, popupHeader, popupText, updateValue, popupIcon, popupPickerImage, setPopupParameters }) => (
	<div className="flxc afs jsa popup-preview">
		<div className="title">Popup {popupIndex}</div>
		<TextField onChange={val => setPopupParameters({ popupHeader: val })} value={popupHeader} caption="Header" />
		<TextField onChange={val => setPopupParameters({ popupText: val })} value={popupText} caption="Text" multiLine />
		<ImagePicker
			className="flxr jsb ac image-picker"
			imageClass="picker-preview"
			placeholderUrl=''
			url={popupIcon || null}
			image={popupPickerImage || null}
			onImageSelected={file => setPopupParameters({ image: file })}
			onError={console.error}
			onFileRead={val => setPopupParameters({ icon: val })}
		/>
	</div>
);

export default connect()(PopupPreview);
