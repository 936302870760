import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchItemsV2Failed, fetchItemsV2Success } from '../actions/itemsV2Actions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectItemsV2State = state => state.itemsV2Reducer;

function* fetchItemsV2Async() {
  const itemsV2State = yield select(selectItemsV2State);
  const { limit, offset } = itemsV2State

  const searchQuery = itemsV2State?.searchQuery;
  const query = searchQuery ? `&q=${searchQuery}` : '';
  const itemCountry = itemsV2State?.itemCountry ? `&countryId=${itemsV2State?.itemCountry}` : '';
  const brandFilter = itemsV2State?.brandFilter ? `&brandIds=${encodeURIComponent(JSON.stringify(itemsV2State?.brandFilter))}` : '';

  const dateFilter = !itemsV2State?.dateFilter?.from
  ? ''
  : `&fromDate=${itemsV2State?.dateFilter?.from}&toDate=${itemsV2State?.dateFilter?.to}`;


  const response = yield call(
    request,
    `${baseUrl}/api/v2/item?limit=${limit}&skip=${offset}${dateFilter}${query}${itemCountry}${brandFilter}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchItemsV2Failed(response.err.toString()));
  } else {
      console.log('response.data', response.data)
    yield put(fetchItemsV2Success(response.data));
  }
}

function* fetchItemsV2() {
  yield takeLatest([`${types.FETCH_ITEMS_V2}`, `${types.FETCH_MORE_ITEMS_V2}`], fetchItemsV2Async);
}

export default function* itemsv2saga() {
  yield all([
    fetchItemsV2(),
  ]);
}
