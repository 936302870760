/*
Kateryna Ivanova
22/03/2018

Component for picking multiple values in popup
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { List, ListItem } from 'material-ui/List';
import FlatButton from 'material-ui/FlatButton';
import Checkbox from 'material-ui/Checkbox';

class Multipicker extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: [] };
  }

  componentWillReceiveProps(props) {
    this.setState({ selected: props.selected || [] });
  }

  onSelectValue(checked, id) {
    const { selected } = this.state;
    const newSelected = selected.filter(sel => sel !== id);
    if (checked) {
      newSelected.push(id);
    }
    this.setState({ selected: newSelected });
  }

  render() {
    const {
      onRequestClose,
      values,
      title,
      open,
      onDone,
    } = this.props;
    const { selected } = this.state;
    return values
      ?
        <Dialog
          title={title}
          actions={[<FlatButton
            label="Set"
            primary
            onClick={() => onDone(selected)}
          />]}
          modal={false}
          open={open}
          onRequestClose={() => onRequestClose()}
        >
          <List>
            { values.map(item => (
              <ListItem
                leftCheckbox={<Checkbox
                  onCheck={(_, checked) => this.onSelectValue(checked, item.id)}
                  checked={selected.indexOf(item.id) !== -1}
                />}
                key={item.id}
                primaryText={item.title}
              />
            )) }
          </List>
        </Dialog>
      : null;
  }
}

Multipicker.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDone: PropTypes.func.isRequired,
};

export default Multipicker;
