/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 07/05/2018.
 */
import types from './actionTypes';

export const fetchCoupons = query => ({
  type: types.FETCH_COUPONS,
  query,
});

export const fetchMoreCoupons = () => ({
  type: types.FETCH_MORE_COUPONS,
});

export const fetchCouponsSuccess = data => ({
  type: types.FETCH_COUPONS_SUCCESS,
  data,
});

export const fetchCouponsFailed = reason => ({
  type: types.FETCH_COUPONS_FAILED,
  reason,
});

