import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import NoPermission from '../components/nopermission';
import * as couponActions from '../actions/couponactions';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import withConfirm from '../utils/withConfirm';

import '../static/styles/coupon.css';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { withRouter } from '../utils/withRouter';

class Coupon extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      MIN_DISCOUNT_VALUE: 0,
      id: null,
      code: '',
      discountValue: 0,
      discountType: 'fixed',
      isEnabled: null,
      errorDiscountValue: null,
      errorCode: null,
      gtThan50: false,
    };
  }
  locationQuery = queryString.parse(this?.props?.location?.search).id
  componentDidMount() {
    this.callFetch(this.locationQuery);
  }

  componentWillReceiveProps(newProps) {
    const { id } = this.state;
    if (newProps.coupon && newProps.coupon._id !== id) {
      const { coupon } = newProps;
      this.setState({
        id: coupon._id,
        code: coupon.code,
        discountValue: coupon.discountValue,
        isEnabled: coupon.isEnabled,
        discountType: coupon.discountType,
      });
    }
  }

  callFetch(id) {
    if (this.state.permission.coupons) {
      if (id) {
        this.props.fetchCoupon(id);
      } else {
        this.setState({ id: 'new' });
      }
    }
  }

  verifyData() {
    const { discountValue, code, MIN_DISCOUNT_VALUE } = this.state;
    if (!discountValue) {
      this.setState({ errorDiscountValue: 'This field is required' });
      return false;
    }
    if (!code) {
      this.setState({ errorCode: 'This field is required' });
      return false;
    }
    if (discountValue < MIN_DISCOUNT_VALUE) {
      this.setState({ errorDiscountValue: 'Discount can\'t be negative' });
      return false;
    }
    return true;
  }

  requestUpdate = () => {
    const { updateCoupon, createCoupon, location } = this.props;
    const {
      id, code, discountValue, isEnabled, discountType
    } = this.state;

    if (!this.verifyData()) {
      window.scrollTo(0, 0);
      return;
    }

    const params = {
      _id: id === 'new' ? null : this.locationQuery,
      code,
      discountValue,
      discountType,
      isEnabled,
    };

    if (id === 'new') {
      createCoupon(params);
    } else {
      updateCoupon(params);
    }
  };

  fixedAmountValidations = value => {
    const val = parseFloat(value);
    const fixedToTwo = parseFloat(val.toFixed(2));
    if (fixedToTwo !== val && value) this.setState({
      errorDiscountValue: 'Amount can be only up to 2 decimals'
    });
    if (val > 50) this.setState({
      gtThan50: true,
    }); else this.setState({
      gtThan50: false,
    });
    if (val < 1) this.setState({
      errorDiscountValue: 'Amount can not be less than 1$'
    });
    if ((val >= 1 && fixedToTwo === val) || !value) this.setState({
      errorDiscountValue: null,
    });
  };

  setDiscountValue = type => ({ target }) => {
    if (type === 'fixed') this.fixedAmountValidations(target.value);
    this.setState({ discountValue: target.value })
  };

  render() {
    const {
      onOpenDrawer, updateSuccess, updateError, isUpdating,
    } = this.props;
    const {
      id, permission, code, discountValue, isEnabled, errorCode, errorDiscountValue,
      discountType, gtThan50,
    } = this.state;
    return permission.coupons ? id &&
      <div>
        <div className="App-header">
          <IconButton onClick={() => this.props.navigate(-1)}><NavigationArrowBack color="white" /></IconButton>
        </div>
        <form className="event-container">
          <TextField
            style={{ width: '100%' }}
            floatingLabelText="Coupon code"
            errorText={errorCode}
            onChange={ev => this.setState({ code: ev.target.value, errorCode: null })}
            value={code}
          />
          <RadioButtonGroup
            name="discountType"
            onChange={(_, value) => {
              this.setState({ discountType: value, errorDiscountValue: null, gtThan50: false, });
              if (value === 'fixed') this.fixedAmountValidations(discountValue);
            }}
            valueSelected={discountType}
          >
            <RadioButton key={1} value="fixed" label="Amount" />
            <RadioButton key={2} value="percent" label="Percent" />
          </RadioButtonGroup>
          <TextField
            style={{ width: '100%' }}
            type="number"
            floatingLabelText={`Coupon discount ${discountType === 'fixed' ? '$' : '%'}`}
            errorText={errorDiscountValue}
            onChange={this.setDiscountValue(discountType)}
            value={discountValue}
          />
          <Checkbox
            label="Enabled"
            checked={isEnabled}
            onCheck={() => this.setState({ isEnabled: !isEnabled })}
            style={{ margin: '20px 0' }}
          />
          {updateSuccess
            ?
            <div className="message-box success-message">
              {`Coupon is successfully ${id === 'new' ? 'created' : 'updated'}`}
            </div>
            :
            null
          }
          {
            updateError
              ?
              <div className="message-box error-message">
                {updateError}
              </div>
              :
              null
          }
          <div className="save-btn">
            {isUpdating
              ?
              <CircularProgress />
              :
              <RaisedButton
                backgroundColor="#16cd2f"
                onClick={() => !errorDiscountValue ?
                  withConfirm(
                    this.requestUpdate,
                    gtThan50 ? 'Are you sure? (the amount is higher than 50$)' : 'Are you sure?'
                  ) : () => { }
                }
                labelStyle={{ color: '#fff' }}
                label={id === 'new' ? 'Create coupon' : 'Save changes'}
              />
            }
          </div>
        </form>
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

Coupon.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  fetchCoupon: PropTypes.func.isRequired,
  updateCoupon: PropTypes.func.isRequired,
  createCoupon: PropTypes.func.isRequired,
  updateSuccess: PropTypes.bool.isRequired,
  updateError: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  coupon: state.couponReducer.coupon,
  updateSuccess: state.couponReducer.updateSuccess,
  updateError: state.couponReducer.updateError,
  isUpdating: state.couponReducer.isUpdating,
});

const mapDispatchToProps = dispatch => ({
  fetchCoupon: id => dispatch(couponActions.fetchCoupon(id)),
  updateCoupon: coupon => dispatch(couponActions.updateCoupon(coupon)),
  createCoupon: coupon => dispatch(couponActions.createCoupon(coupon)),
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Coupon));
