/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../../actions/actionTypes';
import { createReducer } from '../helpers/redux-helpers';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 200,
  logistics: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  isMakeRefund: false,
  makeRefundError: null,
  sort: {},
  startDate: null,
  endDate: null,
  searchQuery: '',
  idOfChangingStatus: ''
};

export default createReducer({
  [types.LOGISTICS.FETCH_COMPLETED]: (state, action) => ({
    ...state,
    isLoading: true,
    offset: 0,
    logistics: [],
    totalCount: 0,
    searchQuery: action.query,
  }),
  [types.LOGISTICS.ADD_SHIPPING_STATUS]: (state, action) => (
      {
      ...state,
      idOfChangingStatus: action.id
  }
  ),
  [types.LOGISTICS.ADD_SHIPPING_STATUS_SUCCESS]: (state, action) => {
    let indexOfItem = state.logistics.findIndex( i => i._id === action.data._id)
    let logistics = state.logistics
      if(indexOfItem !== -1) {
        logistics[indexOfItem] = action.data
    }  
    return ({
        ...state,
        logistics,
        idOfChangingStatus: ''
  })
  },    
  [types.LOGISTICS.FETCH_MORE_COMPLETED]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [types.LOGISTICS.FETCH_COMPLETED_SUCCESS]: (state, action) => ({
    ...state,
    offset: state.offset + state.limit,
    totalCount: action.data.count,
    logistics: [...state.logistics, ...action.data.logistics],
    hasMore: state.logistics.length + action.data.logistics.length < action.data.count,
    isLoading: false,
  }),
  [types.LOGISTICS.FETCH_COMPLETED_FAILED]: (state) => ({
    ...state,
    isLoading: false,
    hasMore: false,
  }),
  [types.LOGISTICS.MAKE_REFUND]: (state) => ({
    ...state,
    isMakeRefund: true,
    makeRefundError: null,
  }),
  [types.LOGISTICS.MAKE_REFUND_SUCCESS]: (state) => ({
    ...state,
    isMakeRefund: false,
    makeRefundError: null,
    isLoading: true,
    offset: 0,
    logistics: [],
    totalCount: 0,
  }),
  [types.LOGISTICS.MAKE_REFUND_FAILED]: (state, action) => ({
    ...state,
    isMakeRefund: false,
    makeRefundError: action.reason,
  }),
  [types.LOGISTICS.FETCH_COMPLETED_SORT_CHANGED]: (state, action) => ({
    ...state,
    isLoading: true,
    offset: 0,
    logistics: [],
    totalCount: 0,
    sort: procesSort(state.sort, action.column, action.direction),
  }),
  [types.LOGISTICS.FETCH_COMPLETED_DATERANGE_CHANGED]: (state, action) => ({
    ...state,
    isLoading: true,
    offset: 0,
    logistics: [],
    totalCount: 0,
    startDate: action.startDate,
    endDate: action.endDate,
  }),
    
  'CHANGE_FULFILLED_STATUS': (state, {payload}) => ({
    ...state,
    logistics: [ ...state.logistics.slice(0, payload.position), ...state.logistics.slice(payload.position + 1)],
  }),
  'ADD_FULFILLED_PAYMENT': (state, {payload}) => ({
    ...state,
    logistics: [ { ...payload.payment, status: 'fullfilled' }, ...state.logistics]
  }),
}, initialState);