import { Menu, MenuItem, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { userLocalStorage } from "../../config/local_storage";
import { FlagImage } from "./flagImage";

const languages = [
    { code: "EN", label: "United States", lang: "English" },
    { code: "RU", label: "Russian", lang: "Russian" }
];

export const LanguageMenu = props => {
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [lang, setLang] = React.useState({
        code: userLocalStorage.get("lang")
            ? userLocalStorage.get("lang")
            : "EN",
        lang: userLocalStorage.get("lang")
            ? languages.find(
                  language =>
                      language.code.toLocaleLowerCase() ===
                      userLocalStorage.get("lang")
              ).lang
            : "English"
    });
    const { i18n } = useTranslation("common");
    const toggleMenu = event => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = (lang, code) => {
        setAnchorMenu(null);
        setLang({ code, lang });
        i18n.changeLanguage(code.toLowerCase());
        userLocalStorage.set("lang", code.toLowerCase());
    };
    const disableOutline = {
        outline: "none",
        border: "none"
    };

    return (
        <React.Fragment>
            {lang.code && (
                <IconButton
                    aria-owns={anchorMenu ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                    className="lang-label"
                    style={disableOutline}
                >
                    <FlagImage countryCode={lang.code} />
                </IconButton>
            )}
            {lang.code.toUpperCase()}
            <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={e => setAnchorMenu(null)}
            >
                {languages &&
                    languages.map((item, index) => (
                        <MenuItem
                            key={`${item}-${index}`}
                            onClick={e => closeMenu(item.lang, item.code)}
                        >
                            <div style={{ marginRight: "5px" }}>
                                <FlagImage countryCode={item.code} />
                            </div>
                            {item.lang}
                        </MenuItem>
                    ))}
            </Menu>
        </React.Fragment>
    );
};
