import React from 'react';
import Radio from './Radio';

const RadioGroup = ({ name, items, onChange, value }) => (
	<div className="flxc jsb afs radio-group">
		{
			items.map((item, key) => (
				<Radio
					name={name}
					value={item.value}
					caption={item.caption}
					checked={item.value === value}
					onChange={({target}) => onChange(target.value)}
					key={key}
				/>
			))
		}
	</div>
);

export default RadioGroup;