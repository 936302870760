import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, Grid, Typography } from '@material-ui/core';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 320,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  close: {
    textTransform: 'none',
    textDecoration: 'underline',
    marginTop: 19,
  },
  confirmBtn: {
    marginTop: 18,
    borderRadius: 8,
    backgroundColor: '#EDFF00',
    border: '2px solid #141B41',
    textTransform: 'none',
    width: 130,
  },
  removeBtn: {
    marginTop: 18,
    borderRadius: 8,
    backgroundColor: 'rgba(229, 12, 49, 1)',
    '&:hover': {
      backgroundColor: "rgba(229, 12, 49, 1)",
    },
    fontFamily: 'Overpass',
    fontWeight: '600',
    fontSize: '14px',
    color: '#FFFFFF',
    // border: '2px solid #141B41',
    textTransform: 'none',
    width: 130,
  },
  typography: {
    margin: '0 0 15px 10px',
    fontFamily: 'OverPass',
    fontSize: 16,
    fontWeight: 600,
  },
}));

export function ModalWindow({ open, close, title, confirm, children }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const confirmBtnModal = () => {
    confirm();
    close();
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography className={classes.typography}>{title}</Typography>

      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Button className={classes.close} onClick={close}>
            <Typography>Close</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button className={children === 'Remove' ? classes.removeBtn : classes.confirmBtn} onClick={confirmBtnModal}>
            <Typography>{children ? `${children}` : 'Confirm'}</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  );
}
