import types from './actionTypes';

export const getUserData = (id) => {
    return {
        type: types.GET_USER_DATA,
        payload:
            { id }

    }
};
export const setUserData = (data) => ({
    type: types.SET_USER_DATA,
    payload: {
        data
    }
});
export const saveProfileData = (id) => {
    return {
        type: types.SAVE_PROFILE_DATA,
        payload:
            id

    }
};
export const saveProfileDataSuccess = (payload) => ({
    type: types.SAVE_PROFILE_DATA_SUCCEESS,
    payload
});
export const saveExtensionData = (data) => {
    return {
        type: types.SAVE_EXTENSION_DATA,
        payload:
            { data }

    }
};
export const saveExtensionDataSuccess = (data) => ({
    type: types.SAVE_EXTENSION_DATA_SUCCEESS,
    payload: {
        data
    }
});
export const fetchBrands = (query) => {
    return {
        type: types.FETCH_BRANDS,
        payload:
            { query }

    }
};
export const fetchBrandsSuccess = (data) => ({
    type: types.FETCH_BRANDS_SUCCESS,
    payload: {
        data
    }
});
export const updateProfileImage = (data) => ({
    type: types.UPDATE_PROFILE_IMAGE,
    payload: {
        data
    }
});
export const updateProfileImageSuccess = (data) => ({
    type: types.UPDATE_PROFILE_IMAGE_SUCCESS,
    payload: {
        data
    }
});
export const fetchTiers = (payload) => ({
    type: types.FETCH_TIERS,
    payload
});
export const fetchTiersSuccess = (payload) => ({
    type: types.FETCH_TIERS_SUCCESS,
    payload
});
export const getExtantionData = (id) => ({
    type: types.GET_EXTANTION_DATA,
    payload: id
});
export const setExtantionData = (payload) => ({
    type: types.SET_EXTANTION_DATA,
    payload
});
export const saveExtantionData = (id) => ({
    type: types.SAVE_EXTANTION_DATA,
    payload: id
});
export const saveExtantionDataSuccess = (payload) => ({
    type: types.SAVE_EXTANTION_DATA_SUCCESS,
    payload
});
// export const saveUserData = (id) => ({
//     type: types.SAVE_USER_DATA,
//     payload:id
// });
// export const saveUserDataSuccess = (payload) => ({
//     type: types.SAVE_USER_DATA_SUCCESS,
//    payload
// });
export const personalInfoActions = dispatch => ({

    fetchBrands: query => dispatch(fetchBrands(query)),
    getUserData: (id) => dispatch(getUserData(id)),

});



export default dispatch => ({
    saveProfileData: data => dispatch(saveProfileData(data)),
    getUserData: (id) => dispatch(getUserData(id)),
    fetchBrands: query => dispatch(fetchBrands(query)),
    saveExtensionData: data => dispatch(saveExtensionData(data)),
    updateProfileImage: data => dispatch(updateProfileImage(data)),
    fetchTiers: payload => dispatch(fetchTiers(payload)),
    getExtantionData: (payload) => dispatch(getExtantionData(payload)),
    saveExtantionData: (payload) => dispatch(getExtantionData(payload)),
    // saveUserData:(payload)=>dispatch(saveUserData(payload))
});
