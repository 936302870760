/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 24/06/2018.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from './lightBox';
// import Lightbox from 'react-images';


import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class ImageViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
        }
        this.image = null
    }

    onNextImage = () => {
        if (this.props.images.length > 1) {

            this.setState({ currentImage: (this.state.currentImage + 1) % this.props.images.length });
            // this.setState(prevState => ({
            //     currentImage: prevState.currentImage + 1,
            // }))
        }
    };

    onPrevImage = () => {
        if (this.props.images.length > 1) {
            // this.setState(prevState => ({
            //     currentImage: prevState.currentImage - 1,
            // }))
            this.setState({ currentImage: (this.state.currentImage + this.props.images.length - 1) % this.props.images.length });

        }


    };

    onClose = () => {
        this.setState({ currentImage: 0 });
        this.props.onClose();
    };
    onImageLoadError = (imageSrc, _srcType, errorEvent) => {
        console.error(`Could not load image at ${imageSrc}`, errorEvent); // eslint-disable-line no-console
    }
    render() {
        const { isOpen, images, orientation } = this.props;
        const { currentImage } = this.state;
        return (
            <div className="image-viewer">

                {images && images.length > 0 && isOpen && (
                    <Lightbox
                        mainSrc={images[currentImage].src}
                        crossOrigin="anonymous"
                        autoRotate={false}
                        ref={(ref) => this.image = ref}
                        nextSrc={images.length > 1 ? images[(this.state.currentImage + 1) % images.length].src : null}
                        prevSrc={images.length > 1 ? images[(this.state.currentImage + images.length - 1) % images.length].src : null}
                        onCloseRequest={this.onClose}
                        defaultRotate={orientation && orientation === 6 ? 90 : 0}
                        onPreMovePrevRequest={this.onPrevImage}
                        onPreMoveNextRequest={this.onNextImage}
                        onImageLoadError={this.onImageLoadError}

                    />
                )}
                {/* {images && images.length > 0 &&
                    <Lightbox
                        currentImage={currentImage}
                        images={images}
                        isOpen={isOpen}
                        onClickNext={this.onNextImage}
                        onClickPrev={this.onPrevImage}
                        onClose={this.onClose}
                        backdropClosesModal={true}
                    />
                } */}
            </div>
        )
    }

}

ImageViewer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired,
    orientation: PropTypes.number.isRequired,
};

export default ImageViewer;