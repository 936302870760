import { Button, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useState } from "react"
import { useNavigate } from "react-router"
import { ModalWindow } from "../modal/modal"

const useStyles = makeStyles({
  cancelBtn: {
    backgroundColor: 'transparent',
    width: '250px'
  },
  saveBtn: {
    textTransform: 'none',
    background: '#edff00',
    border: '2px solid #141b41',
    width: '250px',
    '&:hover': {
      backgroundColor: '#EDFF00',
    }
  },
  marginContainer: {
    marginTop: 20
  }
});

export const CancelApplyBtns = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState()
  const navigate = useNavigate()

  const checkWhichBtnClicked = (value) => {
    setValue(value)
    setOpen(!open)
  }
  return (
    <Grid container direction='row' justifyContent='space-between' className={classes.marginContainer}>
      <Grid item>
        <Button onClick={() => checkWhichBtnClicked(1)} variant='text' className='cancelBtn' >
          <Typography className='label-text'>Cancel</Typography>
        </Button>
      </Grid>
      <Grid item>
        <div className="saveBtn">
          <Button
            onClick={() => checkWhichBtnClicked(2)}
            disabled={props?.isSubmitting}
            variant='contained'
            className={classes.saveBtn}
          > {'Save'}</Button>
        </div>
      </Grid>
      {open && <ModalWindow
        title={value === 1 ? 'Do you really want to cancel?' : 'Do you really want to save?'}
        confirm={value === 1 ? () => navigate(-1) : () => props?.submitForm()}
        open={() => setOpen(true)}
        close={() => setOpen(false)}
      />}
    </Grid>
  )
}