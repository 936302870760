/*
Kateryna Ivanova
30/03/2018

Redux actions for a single bo user
*/
import types from './actionTypes';

export const fetchBOUser = id => ({
  type: types.FETCH_BO_USER,
  id,
});

export const fetchBOUserSuccess = data => ({
  type: types.FETCH_BO_USER_SUCCESS,
  data,
});

export const fetchBOUserFailed = reason => ({
  type: types.FETCH_BO_USER_FAILED,
  reason,
});

export const updateBOUser = bouser => ({
  type: types.UPDATE_BO_USER,
  bouser,
});

export const createBOUser = bouser => ({
  type: types.CREATE_BO_USER,
  bouser,
});

export const updateBOUserSuccess = () => ({
  type: types.UPDATE_BO_USER_SUCCESS,
});

export const updateBOUserFailed = reason => ({
  type: types.UPDATE_BO_USER_FAILED,
  reason,
});

export const deleteBOUser = ids => ({
  type: types.DELETE_BO_USER,
  ids,
});

export const deleteBOUserSuccess = () => ({
  type: types.DELETE_BO_USER_SUCCESS,
});

export const deleteBOUserFailed = reason => ({
  type: types.DELETE_BO_USER_FAILED,
  reason,
});

export const regeneratePassword = id => ({
  type: types.BO_USER_REGENERATE_PASSWORD,
  id,
});

export const regeneratePasswordSuccess = newPassword => ({
  type: types.BO_USER_REGENERATE_PASSWORD_SUCCESS,
  newPassword,
});

export const regeneratePasswordFailed = reason => ({
  type: types.BO_USER_REGENERATE_PASSWORD_FAILED,
  reason,
});
