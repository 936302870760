import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'material-ui/IconButton';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import { fetchBOUsers } from '../actions/bousersactions';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import '../static/styles/bousers.css';
import { Grid, Paper, Typography } from '@material-ui/core';
import TableData, { TableDataPermissions, TablePermissionData } from '../components/table/index';
import { tableData } from '../utils/tableData';

const ViewPermissions = ({ onOpenDrawer }) => {
  const permissions = decodePermissions(getPermission());
  const { bousers } = useSelector(({ boUsersReducer }) => boUsersReducer)
  const dispatch = useDispatch()
  const { permissions: { permissionsLabel } } = tableData

  useEffect(() => {
    if (permissions?.bousers) {
      dispatch(fetchBOUsers())
    }
  }, [])
  return permissions?.bousers ?
    <div>
      <div className="App-header" >
        <IconButton onClick={onOpenDrawer}>
          <NavigationMenu color="white" />
        </IconButton>
        <span className="header-placeholder" />
      </div>
      <div style={{ padding: 10 }}>
        <Grid container justifyContent='center'>
          <Typography variant="h5">View permissions</Typography>
        </Grid>
        <TablePermissionData data={permissionsLabel} header={bousers} tableName='viewPermissions' />
      </div>
    </div >
    : <NoPermission onOpenDrawer={onOpenDrawer} />;
}

export default ViewPermissions