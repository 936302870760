import React, { useState } from 'react';
import moment from 'moment';
import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import { packageDefine, packageSubstateDefine } from '../../utils/paymentUtil';
import { ReactComponent as Edit } from '../../../src/static/images/edit.svg';
import BarcodeInputField from './BarcodeInputField';
import { updatePackage } from '../../actions/packageActions';
import { connect } from 'react-redux';

const TypographyText = withStyles({
  root: {
    '&.MuiTypography-root': {
      fontFamily: 'Overpass',
      fontSize: '16px',
      color: '#8d8d8d',
      marginBottom: '10px',
    },
  },
})(Typography);

const PackageDetails = ({ updatePackage, packageInfo }) => {
  const [editMode, setEditMode] = useState(false)

  const handleBarcodeSubmit = (val) => {
    updatePackage({ uid: packageInfo.uid, fromUserId: packageInfo.from_user_uid, barcode: val })
    setEditMode(false)
  }

  return <>
    <Grid container direction="row" justifyContent="space-between">
      <Typography variant="h5" className="paymentRequest-subtitle">
        DETAILS
      </Typography>
    </Grid>
    <Paper className="info-wrapper paymentInfo-wrapper" >
      <Grid container>
        <Grid container className="payment-info-text" direction="column">
          <Typography variant="h6" className="payment-info-subtitle">
            Attached Receipt(s)
          </Typography>
          <Grid container direction="row" className="atached-imgGroup-wrapper">
          { packageInfo?.receipts?.map(receipt => (
            <div>
              <img className="atached-img-wrapper" src={receipt.receipt_url} />
            </div>
          )) }
          </Grid>
        </Grid>
        <Typography variant="h6" className="payment-info-subtitle">
          Package information
        </Typography>
        <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
          <TypographyText>Package Status</TypographyText>
          <div>
            <div className="status-info status-open">{ packageInfo?.state_id === 2 ? 'Closed-' + packageSubstateDefine[packageInfo?.substate_id] : packageDefine  [packageInfo?.state_id] }</div>
          </div>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
          <TypographyText>Package ID</TypographyText>
          <Typography>{packageInfo?.id ?? '-'}</Typography>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
          <TypographyText>Date created</TypographyText>
          <Typography>{packageInfo?.create_date ? moment(packageInfo?.create_date).format('L h:mm') : '-'} </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
          <TypographyText>Date updated</TypographyText>
          <Typography>{packageInfo?.update_date ? moment(packageInfo?.update_date).format('L h:mm') : '-'} </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
          <TypographyText>Barcode</TypographyText>
          { editMode ? (
            <BarcodeInputField
              value={packageInfo?.barcode?.barcode}
              onClose={() => setEditMode(false)}
              onSubmit={handleBarcodeSubmit}
            />
          ) : (
            <div style={{ display: 'flex' }}>
              <Typography>{packageInfo?.barcode?.barcode ?? '-'}</Typography>
              <Edit style={{ marginLeft: 15 }} onClick={ () => setEditMode(true) }/>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  </>
}

const mapDispatchToProps = dispatch => ({
  updatePackage: (data) => dispatch(updatePackage(data)),
  dispatch,
});

export default connect(
  () => {},
  mapDispatchToProps,
)(PackageDetails);