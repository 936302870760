/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
  fetchCanceledFailed, fetchCanceledSuccess
} from '../../actions/logistics/canceledactions';
import getBaseUrl from '../../utils/envSetup';

const baseUrl = getBaseUrl();

const selectLogisticsState = state => state.logistics.canceled;

function* fetchCanceledAsync() {
  const logisticsState = yield select(selectLogisticsState);
  const sorting = Object.keys(logisticsState.sort).map(key => `&sort[${key}]=${logisticsState.sort[key]}`).join('');
  const startDate = logisticsState.startDate ? `&startDate=${logisticsState.startDate}` : '';
  const endDate = logisticsState.endDate ? `&endDate=${logisticsState.endDate}` : '';
  const searchQuery = logisticsState.searchQuery ? `&userName=${logisticsState.searchQuery}` : '';
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/cancelled?limit=${logisticsState.limit}&skip=${logisticsState.offset}${sorting}${startDate}${endDate}${searchQuery}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchCanceledFailed(response.err.toString()));
  } else {
    yield put(fetchCanceledSuccess(response.data));
  }
}

function* fetchCanceled() {
  yield takeLatest([types.LOGISTICS.FETCH_CANCELED, types.LOGISTICS.FETCH_MORE_CANCELED,
    types.LOGISTICS.FETCH_CANCELED_SORT_CHANGED, types.LOGISTICS.FETCH_CANCELED_DATERANGE_CHANGED]
    , fetchCanceledAsync);
}


export default function* canceledsaga() {
  yield all([
    fetchCanceled(),
  ]);
}
