import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Paper from 'material-ui/Paper';
import Grid from '@material-ui/core/Grid';
import { decodePermissions } from '../utils/permissionUtil';
import { adminLogin, login2fa, requestPhone } from '../actions/authactions';
import { LanguageMenu } from '../components/language/languageMenu';
import { getPermission } from '../utils/cookies';
import { Link } from '@material-ui/core';
import '../static/styles/login.css';
import logo from '../static/images/logo.jpg';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoginFields, PhoneFields, TokenFields } from '../components/fields/loginField/index';

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: '50px',
  },
  paper: {
    padding: '30px',
    margin: '5px',
    height: '472px',
    width: '544px',
    border: '2px solid #141B41',
    display: 'block',
  },
  typography: {
    fontFamily: 'Overpass',
    fontSize: '24px',
  },
  error: {
    width: '100%',
    marginTop: 20,
    '& .Mui-error': {
      position: 'relative',
    },
    '& .MuiFormHelperText-root': { position: 'absolute', top: 54 },
  },
  button: {
    backgroundColor: '#141B41',
    height: '53px',
    marginTop: '25px',
    width: '100%',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: 'rgb(61, 57, 107)',
    },
  },
  forgotPass: {
    color: '#007AFF',
    marginTop: 15,
  },
}));

const DEFAULT_COUNTRY_CODE = 'il'; // Israel
const PHONE_MIN_LENGTH = 7;

const Login = () => {

  const { userId, username, authyId, isDisable2fa, loginError } = useSelector(({ loginReducer }) => loginReducer);
  const classes = useStyles();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const showLoginForm = !username;
  const showPhoneForm = !isDisable2fa && !authyId && username;
  const showTokenForm = !!authyId;
  const [showPassword, setShowPassword] = React.useState(false);
  const [{ phone, countryCode, errorPhone, email, password, authyToken }, setState] = React.useState({
    email: '',
    password: '',
    authyToken: '',
    phone: '',
    countryCode: DEFAULT_COUNTRY_CODE,
    errorPhone: '',
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate()

  const getFirstPermissionPossibleUrl = (permission) => {
    if (permission?.users) return '/users';
    if (permission?.events) return '/events';
    if (permission?.stores) return '/stores';
    if (permission?.bousers) return '/bousers';
    if (permission?.items) return '/items';
    if (permission?.securitylogs) return '/securitylogs';
    if (permission?.coupons) return '/coupons';
    if (permission?.chats) return '/chats';
    return '/';
  };

  React.useEffect(() => {
    if (username) {
      const permission = decodePermissions(getPermission());
      setTimeout(() => navigate(getFirstPermissionPossibleUrl(permission)), 2000);
    }
  }, [userId, username]);

  const handleSubmit = (values, props) => {
    const { email, password, authyToken, authyId } = values;
    setTimeout(() => {
      props.setSubmitting(false);
    }, 2500);

    showLoginForm && dispatch(adminLogin(email, password));
    showTokenForm && dispatch(login2fa(authyToken, authyId, email, password));
    showPhoneForm && requestPhoneNumber();
  };

  const requestPhoneNumber = () =>
    phone?.length < PHONE_MIN_LENGTH
      ? setState({ errorPhone: 'Phone number should not be empty' })
      : dispatch(requestPhone(phone, countryCode, email));

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('enterValidEmail')),
    password: Yup.string().min(6, 'The password must contain at least 6 characters').max(50, t('passContain'))
  });

  return (
    <>
      <div className="App-header">
        <LanguageMenu />
      </div>
      {userId && username ? (
        <div className="message-box success-message">{username ? `${t('hello')} ${username}` : t('loggedIn')}</div>
      ) : (
        <Grid container justifyContent="center" className={classes.grid}>
          <Paper className={classes.paper} zDepth={2}>
            <Grid container spacing={3} justifyContent="flex-start" direction="column">
              <Grid item>
                <img src={logo} href="" />
              </Grid>
              <Grid item>
                <Typography variant="h4" className={classes.typography}>
                  {t('login')}
                </Typography>
              </Grid>
              <Grid item>
                <Formik
                  initialValues={{ phone, countryCode, errorPhone, email, password, authyToken }}
                  onSubmit={handleSubmit}
                  validateOnBlur={true}
                  validateOnChange={false}
                  validationSchema={validationSchema}>
                  {props => (
                    <Form>
                      {showLoginForm && (
                        <LoginFields
                          t={t}
                          showPass={handleClickShowPassword}
                          loginError={loginError}
                          showPassword={showPassword}
                          {...props}
                        />
                      )}
                      {showPhoneForm && (
                        <PhoneFields
                          onChange={setState}
                          countryCode={countryCode}
                          phone={phone}
                          errorPhone={errorPhone}
                        />
                      )}
                      {showTokenForm && (
                        <TokenFields loginError={loginError} onChange={setState} authyToken={authyToken} {...props} />
                      )}
                      <Grid container justifyContent="flex-end">
                        <Link className={classes.forgotPass}>{t('forgotPassword')}</Link>
                      </Grid>
                      <Grid item>
                        <Button variant="text" className={classes.button} type="submit" disabled={props.isSubmitting}>
                          <Typography style={{ color: 'white' }}>
                            {showPhoneForm ? t('sendPhone') : t('login')}
                          </Typography>
                        </Button>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  );
};

Login.propTypes = {
  loginReducer: PropTypes.object.isRequired,
  adminLogin: PropTypes.func.isRequired,
  login2fa: PropTypes.func.isRequired,
  requestPhone: PropTypes.func.isRequired,
};

export default Login;
