export const defaultPopups = {
	1: {
		popupHeader: 'WE ARE ONLY 7 HOURS APART :)',
		popupText: 'I will contact you after I drink my coffee.',
		popupIcon: null,
		popupType: 1,
	},
	2: {
		popupHeader: 'I would love to help, but its the weekend here in NY',
		popupText: 'Let\'s chat on Monday.',
		popupIcon: null,
		popupType: 2,
	}
};

export const defaultTimetable = {
	0: {
		from: 0,
		to: 140400,
		popupType:2,
		nextDay: true,
	},
	1: {
		from: 18000,
		to: 54000,
		popupType: 1,
		nextDay: false,
	},
	2: {
		from: 18000,
		to: 54000,
		popupType: 1,
		nextDay: false,
	},
	3: {
		from: 18000,
		to: 54000,
		popupType: 1,
		nextDay: false,
	},
	4: {
		from: 18000,
		to: 54000,
		popupType: 1,
		nextDay: false,
	},
	5: {
		from: 75600,
		to: 172800,
		popupType: 2,
		nextDay: true,
	},
	6: {
		from: 0,
		to:	172800,
		popupType: 2,
		nextDay: true,
	}
};
