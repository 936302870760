import React from 'react';
import  Dialog from 'material-ui/Dialog';
import Tabs from './Tabs';

const TabsDialog = ({ tabs = [], contents = [], width = 300, height = 600, caption = 'Dialog', display = false, close, actions }) => (
    <Dialog
        contentStyle={{ width: `${width}px`, height:`${height}px` }}
        title={caption}
        modal={false}
        open={display}
        onRequestClose={close}
        actions={actions}
    >
        <Tabs tabs = {tabs} contents = {contents} width={`${width - 20}px`} />
    </Dialog>
);

export default TabsDialog;
