import {takeLatest, takeEvery, put, call, all, select} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import {
  fetchUserProfileFailed,
  fetchUserProfileSuccess,
  getUserProfileData,
  updateUserProfileShippingSuccess,
  updateUserProfileShippingFailed,
  updateUserProfileInfoSuccess,
  updateUserProfileInfoFailed,
} from '../actions/userProfileActions';

const baseUrl = getBaseUrl();

function* fetchUserProfileAsync(action) {
  const response = yield call(request, `${baseUrl}/api/user/v2/${action.id}?includeAddress=true`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(fetchUserProfileFailed(response.err.toString()));
  } else {
    yield put(fetchUserProfileSuccess(response.data));
  }
}
function* updateUserProfileShipping(action) {
  const {
    first_name,
    last_name,
    company,
    country_id,
    address1,
    address2,
    address_notes,
    city,
    zone_id,
    zip_code,
    tax_id,
    street,
    apartment,
    building,
    enterance,
    identification_number
  } = action.data;
  const response = yield call(request, `${baseUrl}/api/user/v2/${action.id}/address?includeAddress=true`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      first_name,
      last_name,
      company,
      country_id,
      address1,
      address2,
      address_notes,
      city,
      zone_id,
      zip_code,
      tax_id,
      street,
      apartment,
      building,
      enterance,
      identification_number
    }),
  });

  if (response.err) {
    yield put(updateUserProfileShippingFailed(response.err.toString()));
  } else {
    yield put(updateUserProfileShippingSuccess(response.data));
  }
}
function* updateUserProfileInfo(action) {
  const {first_name, last_name, phone_number, email, state_id, type_id} = action.data;
  console.log('SAGGA', action);
  const response = yield call(request, `${baseUrl}/api/user/v2/${action.id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({first_name, last_name, phone_number, email, state_id, type_id}),
  });

  if (response.err) {
    yield put(updateUserProfileInfoSuccess(response.err.toString()));
    console.log('ERROR', response?.err);
  } else {
    yield put(updateUserProfileInfoFailed(response.data));
  }
}

function* fetchUserProfile() {
  yield takeLatest([types.FETCH_USER_PROFILE_DATA], fetchUserProfileAsync);
}
function* updateUserShipping() {
  yield takeLatest([types.UPDATE_USER_PROFILE_SHIPPING], updateUserProfileShipping);
}
function* updateUserProfile() {
  yield takeLatest([types.UPDATE_USER_PROFILE_INFO], updateUserProfileInfo);
}

export default function* userProfileSaga() {
  yield all([fetchUserProfile(), updateUserShipping(), updateUserProfile()]);
}
