/*
Roman Safonov
22/03/2018

Redux actions for a single item
*/
import types from './actionTypes';

export const deleteItem = id => ({
  type: types.DELETE_ITEM,
  id,
});

export const deleteItemSuccess = () => ({
  type: types.DELETE_ITEM_SUCCESS,
});

export const deleteItemFailed = reason => ({
  type: types.DELETE_ITEM_FAILED,
  reason,
});

export const updateItem = data => ({
  type: types.UPDATE_ITEM,
  data,
});

export const updateItemSuccess = () => ({
  type: types.UPDATE_ITEM_SUCCESS,
});

export const updateItemFailed = reason => ({
  type: types.UPDATE_ITEM_FAILED,
  reason,
});


export const fetchItem = id => ({
  type: types.FETCH_ITEM,
  id,
});

export const fetchItemSuccess = data => ({
  type: types.FETCH_ITEM_SUCCESS,
  data,
});

export const fetchItemFailed = reason => ({
  type: types.FETCH_ITEM_FAILED,
  reason,
});
