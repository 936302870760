import React from "react";
import IconButton from "material-ui/IconButton";

import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import NoPermission from "../components/nopermission";
import { connect } from "react-redux";
import Tabs from "../components/Tabs";
import { decodePermissions } from "../utils/permissionUtil";
import { getPermission } from "../utils/cookies";
import Shupper from "../components/Shupper";
import getHandlers from "../actions/approveShuppers";
import { getProps } from "../reducers/approveShuppersReducer";
import Dialog from "material-ui/Dialog";
import RegistrationForm from "../components/profile/RegistrationFormTab";
import "../static/styles/approveShuppers.css";
import LinearProgress from "material-ui/LinearProgress";
import SuccessAlert from "../components/SuccessAlert";
import ErrorAlert from "../components/ErrorAlert";
import { RaisedButton } from "material-ui";

import getBaseUrl from "../utils/envSetup";
import { withRouter } from "../utils/withRouter";
const baseUrl = getBaseUrl();

const ApproveShuppersList = ({
    showCandidateInfo,
    shuppers,
    activeStatus,
    skip,
    isLoading,
    approveCandidate,
    declineCandidate,
}) =>
    shuppers && shuppers.length ? (
        <React.Fragment>
            {shuppers.map((candidate, index) => (
                <Shupper
                    declineCandidate={declineCandidate}
                    approveCandidate={approveCandidate}
                    showCandidateInfo={showCandidateInfo}
                    candidate={candidate}
                    index={index}
                    activeStatus={activeStatus}
                />
            ))}
        </React.Fragment>
    ) : isLoading ? (
        <div className="no-content">Loading...</div>
    ) : (
        <div className="no-content">No Shuppers</div>
    );

class ApproveShuppersPage extends React.Component {
    constructor(props) {
        super(props);
        const permission = decodePermissions(getPermission());
        this.state = {
            permission,
            modalOpen: false,
            activeStatus: "pending",
            selectedCandidate: null,
            saveSuccess: false,
            saveError: false,
        };
    }
    componentDidMount() {
        if (this.state.permission.canApproveShuppers) {
            this.props.fetchShuppers("pending");
        }
    }
    componentDidUpdate(prevProps) {
        if (
            (prevProps.approveSuccess !== this.props.approveSuccess &&
                this.props.approveSuccess) ||
            (prevProps.declineSuccess !== this.props.declineSuccess &&
                this.props.declineSuccess)
        ) {
            this.setState({ saveSuccess: true, saveError: false });
            setTimeout(() => this.setState({ saveSuccess: false }), 40000);
            this.props.resetApproveShupper();
            this.props.fetchShuppers(this.state.activeStatus);
        }
        if (
            prevProps.approveError !== this.props.approveError &&
            this.props.approveError
        ) {
            this.setState({ saveError: true, saveSuccess: false });
            setTimeout(() => this.setState({ saveError: false }), 40000);
            this.props.resetApproveShupper();
            this.props.fetchShuppers(this.state.activeStatus);
        }
    }
    onCloseModal = () => {
        this.setState({ modalOpen: false });
    };
    showCandidateInfo = (candidate) => {
        candidate.speciality = candidate.speciality
            .map((v) => {
                if (v.indexOf("other") !== -1) {
                    let newArr = v.split(" ");
                    delete newArr[0];
                    let other = newArr.join(" ");
                    return ["other", other];
                } else {
                    return v;
                }
            })
            .flat(1);
        candidate.salesNews = candidate.salesNews
            .map((v) => {
                if (v.indexOf("other") !== -1) {
                    let newArr = v.split(" ");
                    delete newArr[0];
                    let other = newArr.join(" ");
                    return ["other", other];
                } else {
                    return v;
                }
            })
            .flat(1);
        this.setState({ modalOpen: true, selectedCandidate: candidate });
    };
    changeActiveStatus = (e) => {
        let status;
        if (e === 0) {
            status = "pending";
        } else if (e === 1) {
            status = "approved";
        } else {
            status = "declined";
        }
        this.props.fetchShuppers(status);

        this.setState({ activeStatus: status });
    };
    approveCandidate = (id) => {
        this.props.approveShupper(id);
    };
    declineCandidate = (id) => {
        this.props.declineShupper(id);
    };
    render() {
        const { onOpenDrawer, isLoading, shuppers } = this.props;
        const {
            permission,
            activeStatus,
            modalOpen,
            selectedCandidate,
            saveSuccess,
            saveError,
        } = this.state;
        return permission.canApproveShuppers ? (
            <div>
                <div className="App-header candidate-header">
                    <IconButton onClick={() => this.props.navigate(-1)}>
                        <NavigationArrowBack color="white" />
                    </IconButton>
                    <div className="download-candidate-csv">
                        <a
                            href={baseUrl + "/api/candidates/csv"}
                            target="_blank"
                            download
                        >
                            <RaisedButton label="Download CSV" primary />
                        </a>
                    </div>
                </div>
                <div className="progressbar-container">
                    {isLoading && <LinearProgress mode="indeterminate" />}
                </div>{" "}
                {saveSuccess ? (
                    <SuccessAlert
                        mainText="Success!"
                        extraText="Successfully saved"
                        onClose={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                ) : null}
                {saveError ? (
                    <ErrorAlert
                        mainText="Error!"
                        extraText="This user was approved before"
                        onClose={() => {
                            this.setState({ saveError: false });
                        }}
                    />
                ) : null}
                <div className="page-title flxr ac jc">Manage Shuppers</div>
                <Dialog
                    modal={false}
                    open={modalOpen}
                    onRequestClose={this.onCloseModal}
                    scroll="body"
                    contentStyle={{ width: "650px", overflowY: "scroll" }}
                    bodyStyle={{ overflowY: "scroll" }}
                >
                    <RegistrationForm candidate={selectedCandidate} />
                </Dialog>
                <div className="approve-shuppers-tabs flxc ac jc">
                    <Tabs
                        tabs={["Pending", "Approved", "Declined"]}
                        contents={
                            <ApproveShuppersList
                                approveCandidate={this.approveCandidate}
                                declineCandidate={this.declineCandidate}
                                showCandidateInfo={this.showCandidateInfo}
                                activeStatus={activeStatus}
                                isLoading={isLoading}
                                shuppers={shuppers}
                            />
                        }
                        width="80%"
                        onChange={(e) => this.changeActiveStatus(e)}
                    />
                </div>
            </div>
        ) : (
            <NoPermission onOpenDrawer={onOpenDrawer} />
        );
    }
}

export default withRouter(connect(getProps, getHandlers)(ApproveShuppersPage));
