/*
Kateryna Ivanova
20/03/2018

Store create/update page
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import NoPermission from '../components/nopermission';
import ImagePicker from '../components/imagepicker';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import withConfirm from '../utils/withConfirm';
import * as storeActions from '../actions/storeactions';
import storePlaceholderImg from '../static/images/store-placeholder.png';
import storeCoverImg from '../static/images/store-cover.png';

import '../static/styles/store.css';
import { withRouter } from '../utils/withRouter';
class StoreDetails extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      id: null,
      displayName: null,
      email: null,
      shupperDescription: null,
      profileImageURL: null,
      coverImageURL: null,
      errorTextTitle: null,
      errorTextEmail: null,
      gpsLocation: null,
      location: null,
      fileProfile: null,
      fileCover: null,
      selectedProfileImage: null,
      selectedCoverImage: null,
      pinLocation: true,
      storeDeliveryFee: 0,
      file1SizeError: false,
      file2SizeError: false,
    };
  }
  locationQuery = queryString.parse(this?.props?.location?.search).id
  componentDidMount() {
    if (this.state.permission.stores) {
      if (this.locationQuery) {
        this.props.fetchStore(this.locationQuery);
      } else {
        this.setState({ id: 'new' });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { id } = this.state;
    if (
      newProps.storeReducer &&
      newProps.storeReducer.store &&
      newProps.storeReducer.store._id !== id
    ) {
      const { storeReducer: { store } } = newProps;
      this.setState({
        id: store._id,
        displayName: store.displayName,
        email: store.email,
        shupperDescription: store.shupperDescription,
        gpsLocation: (
          store.position &&
          store.position.coordinates &&
          store.position.coordinates.length === 2
        )
          ? store.position.coordinates
          : null,
        location: store.location,
        profileImageURL: store.profileImageURL,
        coverImageURL: store.coverImageURL,
        storeDeliveryFee: store.storeDeliveryFee,
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onFile1Error = () => this.setState({ file1SizeError: true });

  onFile2Error = () => this.setState({ file2SizeError: true });

  verifyData() {
    const { displayName, email } = this.state;
    if (!displayName) {
      this.setState({ errorTextTitle: 'This field is required' });
      return false;
    }
    if (!email) {
      this.setState({ errorTextEmail: 'This field is required' });
      return false;
    }
    return true;
  }

  requestUpdate = () => {
    const { updateStore, createStore } = this.props;
    const {
      id, email, displayName, shupperDescription, fileProfile, fileCover,
      gpsLocation, pinLocation, storeDeliveryFee, location,
    } = this.state;

    if (!this.verifyData()) {
      window.scrollTo(0, 0);
      return;
    }

    const params = {
      _id: id === 'new' ? null : this.locationQuery,
      email,
      displayName,
      shupperDescription,
      fileProfile,
      fileCover,
      coordinates: pinLocation ? gpsLocation : null,
      storeDeliveryFee,
      location,
    };
    if (id === 'new') {
      createStore(params);
    } else {
      updateStore(params);
    }
  };

  render() {
    const { storeReducer, onOpenDrawer } = this.props;
    const {
      id, displayName, shupperDescription, selectedProfileImage, selectedCoverImage,
      profileImageURL, coverImageURL, errorTextTitle, errorTextEmail, location, email,
      pinLocation, permission, storeDeliveryFee, file1SizeError, file2SizeError,
    } = this.state;
    return permission.stores ? id &&
      <div>
        <div className="App-header">
          <IconButton onClick={() => this.props.navigate(-1)}>
            <NavigationArrowBack color="white" />
          </IconButton>
        </div>
        <form className="store-container">
          <TextField
            style={{ width: '100%' }}
            floatingLabelText="Store name"
            errorText={errorTextTitle}
            onChange={ev => this.setState({ displayName: ev.target.value, errorTextTitle: null })}
            value={displayName}
          />
          <TextField
            style={{ width: '100%' }}
            floatingLabelText="Store description"
            onChange={ev => this.setState({
              shupperDescription: ev.target.value,
              errorTextTitle: null,
            })}
            value={shupperDescription}
          />
          <TextField
            style={{ width: '100%' }}
            type="number"
            floatingLabelText="Store Default Delivery Fee"
            onChange={ev => this.setState({
              storeDeliveryFee: ev.target.value,
              errorTextTitle: null,
            })}
            value={storeDeliveryFee}
          />
          <TextField
            style={{ width: '100%' }}
            floatingLabelText="E-mail"
            errorText={errorTextEmail}
            autoComplete="email"
            onChange={ev => this.setState({ email: ev.target.value, errorTextEmail: null })}
            value={email}
          />

          <div className="pick-images">
            <div className="img-picker-cont">
              <ImagePicker
                url={profileImageURL}
                image={selectedProfileImage}
                placeholderUrl={storePlaceholderImg}
                onImageSelected={file => this.setState({
                  fileProfile: file,
                  file1SizeError: false,
                })}
                onFileRead={im => this.setState({ selectedProfileImage: im })}
                onError={this.onFile1Error}
              />
            </div>
            <div className="img-picker-cont-divider" />
            <div className="img-picker-cont">
              <ImagePicker
                url={coverImageURL}
                image={selectedCoverImage}
                placeholderUrl={storeCoverImg}
                onImageSelected={file => this.setState({
                  fileCover: file,
                  file2SizeError: false,
                })}
                onFileRead={im => this.setState({ selectedCoverImage: im })}
                onError={this.onFile2Error}
              />
            </div>
          </div>

          <Checkbox
            label="Pin Location"
            checked={pinLocation}
            onCheck={() => this.setState({ pinLocation: !pinLocation })}
            style={{ margin: '20px 0' }}
          />

          <TextField
            style={{ width: '100%' }}
            floatingLabelText="Location"
            onChange={ev => this.setState({ location: ev.target.value, errorTextTitle: null })}
            value={location}
          />
          {storeReducer.updateSuccess &&
            <div className="message-box success-message">
              {`Store is successfully ${id === 'new' ? 'created' : 'updated'}`}
            </div>
          }
          {storeReducer.updateError &&
            <div className="message-box error-message">
              {storeReducer.updateError}
            </div>
          }
          <div className="save-btn">
            {storeReducer.isUpdating
              ?
              <CircularProgress />
              :
              <RaisedButton
                disabled={file1SizeError || file2SizeError}
                backgroundColor="#16cd2f"
                onClick={() => {
                  if (!(file1SizeError || file2SizeError)) {
                    withConfirm(this.requestUpdate);
                  }
                }}
                labelStyle={{ color: '#fff' }}
                label={id === 'new' ? 'Create store' : 'Save changes'}
              />
            }
          </div>
        </form>
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

StoreDetails.propTypes = {
  storeReducer: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  fetchStore: PropTypes.func.isRequired,
  updateStore: PropTypes.func.isRequired,
  createStore: PropTypes.func.isRequired,
};

const mapStateToProps = ({ storeReducer }) => ({
  storeReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchStore: id => dispatch(storeActions.fetchStore(id)),
  updateStore: store => dispatch(storeActions.updateStore(store)),
  createStore: store => dispatch(storeActions.createStore(store)),
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoreDetails));
