import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import ShuffleIcon from 'material-ui/svg-icons/av/shuffle';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import { RaisedButton, Chip, DropDownMenu, IconButton, IconMenu, MenuItem, Dialog, FlatButton } from 'material-ui';
import Comment from 'material-ui/svg-icons/communication/comment';
import SearchField from '../components/searchfield';
import NoPermission from '../components/nopermission';
import PaginatedGrid from '../components/paginatedgrid';
import * as eventsActions from '../actions/eventsactions';
import * as eventAction from '../actions/eventactions';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import withConfirm from '../utils/withConfirm';

import 'bootstrap/dist/css/bootstrap.css';
import '../static/styles//events.css';
import { withRouter } from '../utils/withRouter';
import { tableData } from '../utils/tableData';

const ShuffleConfirmModal = ({ close, display, eventId, action }) => display ?
  <Dialog
    contentStyle={{ width: '300px' }}
    title={'Reshuffle items in this event?'}
    actions={[
      <FlatButton label="Yes" primary onClick={() => {
        action(eventId);
        close();
      }}
      />,
      <FlatButton label="No" primary onClick={close} />]}
    modal={false}
    open={display}
    onRequestClose={close}
  /> : null;
class EventList extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      selectedEvents: [],
      shuffleConfirmDisplay: false,
      eventToShuffle: null,
    };
  }

  closeShuffleConfirmModal = () => {
    this.setState({
      shuffleConfirmDisplay: false,
      eventToShuffle: null,
    })
  };

  openShuffleConfirmModal = (id) => {
    this.setState({
      shuffleConfirmDisplay: true,
      eventToShuffle: id,
    })
  }
  componentDidMount() {
    if (this.state.permission.events) {
      this.props.fetchEvents();
    }
  }

  onDateRangeSet(startDate, endDate) {
    this.props.fetchEventsDateRangeChanges(startDate, endDate);
  }

  processAction(selectedAction) {
    const { selectedEvents } = this.state;
    const { deleteEvent } = this.props;
    const actions = {
      delete: () => selectedEvents && selectedEvents.length > 0 ?
        deleteEvent(selectedEvents.map(i => i._id)) : null,
      edit: () => selectedEvents && selectedEvents.length > 0 ?
        this.props.navigate(`/event?id=${selectedEvents[0]._id}`) : null,
      create: () => this.props.navigate('/event'),
    }

    actions[selectedAction]();
  }

  doSearch(query) {
    this.props.fetchEvents(query);
  }

  handleFilterVyType(val) {
    this.props.fetchEventsFilterByType(val);
  }

  handleSelection = (selectedItems) => {
    const { eventsReducer: { events } } = this.props;
    const result = [];
    selectedItems.forEach((selectedItem) => {
      result.push(events[selectedItem]);
    });
    this.setState({ selectedEvents: result });
  };

  render() {
    const { events: { columnWidths, headerData } } = tableData
    const {
      eventsReducer, eventReducer, fetchMoreEvents, fetchEventsSortChanged,
      onOpenDrawer, reshuffleEvent
    } = this.props;
    const { selectedEvents, permission, shuffleConfirmDisplay, eventToShuffle } = this.state;
    const tableHeight = window.innerHeight - 177;
    const canEdit = selectedEvents && selectedEvents.length === 1;
    const canDelete = selectedEvents && selectedEvents.length > 0;

    return permission.events ?
      <div className="events-container">
        <ShuffleConfirmModal
          display={shuffleConfirmDisplay}
          close={this.closeShuffleConfirmModal}
          eventId={eventToShuffle}
          action={reshuffleEvent}
        />
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <div className="header-search-area">
            <SearchField onRequestSearch={query => this.doSearch(query)} />
          </div>
          <div className="stores-date-range-picker-cont">
            <DateRangePicker
              drops="down"
              opens="center"
              startDate={eventsReducer.startDate
                ? moment(eventsReducer.startDate)
                : moment('01/01/2017', 'DD/MM/YYYY')}
              endDate={eventsReducer.endDate ? moment(eventsReducer.endDate) : moment()}
              timePicker
              timePicker24Hour
              showDropdowns
              onApply={(ev, picker) => {
                this.onDateRangeSet(
                  picker.startDate.toDate().toJSON(),
                  picker.endDate.toDate().toJSON(),
                );
              }}
              ranges={{
                'All Times': [moment('01/01/2017', 'DD/MM/YYYY'), moment()],
                'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Month to date': [moment().startOf('month'), moment()],
              }}
            >
              <RaisedButton
                label={eventsReducer.startDate && eventsReducer.endDate
                  ? `${moment(eventsReducer.startDate).format('lll')}
                    - ${moment(eventsReducer.endDate).format('lll')}`
                  : 'Pick date range'}
                primary
              />
            </DateRangePicker>
          </div>
          <span className="header-placeholder" />
          <DropDownMenu
            labelStyle={{ color: '#fff' }}
            value={eventsReducer.type}
            onChange={(_, __, val) => this.handleFilterVyType(val)}
          >
            <MenuItem value="" primaryText="All Event Types" />
            <MenuItem value="event" primaryText="Banner" />
            <MenuItem value="checkin" primaryText="Brand" />
          </DropDownMenu>
          <IconMenu
            iconButtonElement={<IconButton><MoreVertIcon color="#fff" /></IconButton>}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <MenuItem
              primaryText="Edit"
              disabled={!canEdit}
              onClick={() => this.processAction('edit')}
            />
            <MenuItem
              primaryText="Delete"
              disabled={!canDelete}
              onClick={() => withConfirm(() => this.processAction('delete'))}
            />
            <MenuItem
              primaryText="Create a new event"
              onClick={() => this.processAction('create')}
            />
          </IconMenu>
        </div>
        <PaginatedGrid
          data={eventsReducer.events}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          hasMore={eventsReducer.hasMore}
          isLoading={eventsReducer.isLoading || eventReducer.isDeleting}
          header={headerData}
          totalResults={eventsReducer.totalCount}
          onSelectRow={this.handleSelection}
          selection={this.state.selectedEvents}
          sortableCols={['title', 'dateStart', 'dateEnd', 'shuppers', 'stores']}
          currentSort={eventsReducer.sort}
          onSort={(column, direction) => fetchEventsSortChanged(column, direction)}
          items={eventsReducer.events.map(event => [
            <span><a href={`/event?id=${event._id}`}>{event.title}</a></span>,
            <span>{moment(event.dateStart).format('lll')}</span>,
            <span>{moment(event.dateEnd).format('lll')}</span>,
            <img alt="" className="avatar" src={event.imageUrl} />,
            <span>
              {event.shuppers.map(shupper => (
                <Chip key={shupper._id} style={{ marginBottom: '3px' }}>
                  {`${shupper.displayName} (${shupper.email})`}
                </Chip>
              ))}
            </span>,
            <span>
              {event.stores.map(store => (
                <Chip key={store._id} style={{ marginBottom: '3px' }}>
                  {store.displayName}
                </Chip>
              ))}
            </span>,
            <span>{event.location}</span>,
            <span>{event.type === "checkin" ? "brand" : event.type === "event" ? "banner" : ""}</span>,
            <span>
              <IconButton onClick={e => { e.stopPropagation(); this.openShuffleConfirmModal(event._id); }} >
                <ShuffleIcon color="black" />
              </IconButton>
            </span>,
            <span>
              {
                event.type === 'checkin' ?
                  <IconButton onClick={e => {
                    e.stopPropagation();
                    this.props.navigate('/reviews/' + event._id)
                  }}>
                    <Comment color="black" />
                  </IconButton> : null
              }
            </span>
          ])}
          onLoadMore={() => fetchMoreEvents()}
        />
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

EventList.propTypes = {
  eventReducer: PropTypes.object.isRequired,
  eventsReducer: PropTypes.object.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  fetchMoreEvents: PropTypes.func.isRequired,
  fetchEventsSortChanged: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  fetchEventsDateRangeChanges: PropTypes.func.isRequired,
  fetchEventsFilterByType: PropTypes.func.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
};

const mapStateToProps = ({ eventsReducer, eventReducer }) => ({
  eventsReducer, eventReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchEvents: query => dispatch(eventsActions.fetchEvents(query)),
  fetchMoreEvents: () => dispatch(eventsActions.fetchMoreEvents()),
  fetchEventsSortChanged: (column, direction) =>
    dispatch(eventsActions.fetchEventsSortChanged(column, direction)),
  deleteEvent: ids => dispatch(eventAction.deleteEvent(ids)),
  fetchEventsDateRangeChanges: (startDate, endDate) =>
    dispatch(eventsActions.fetchEventsDateRangeChanges(startDate, endDate)),
  fetchEventsFilterByType: type => dispatch(eventsActions.fetchEventsFilterByType(type)),
  reshuffleEvent: id => dispatch(eventsActions.reshuffleEvent(id)),
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventList));
