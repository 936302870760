import React, { useState, useEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import SearchField from '../components/searchfield';
import { getPermission } from '../utils/cookies';
import NoPermission from '../components/nopermission';
import { decodePermissions } from '../utils/permissionUtil';
import '../static/styles/userlist.css';
import { tableData } from '../utils/tableData';
import { IconButton, makeStyles, Menu, Typography } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Filters } from '../components/filters';
import calendar from '../static/images/calendar.svg';
import { Buttons } from '../components/filters/buttons';
import { CalendarRangePicker } from '../components/dateRangePicker';
import TableData from '../components/table/index';
import { filtersObj } from '../utils/filtersData';
import { connect, useSelector } from 'react-redux';
import { fetchMoreItemsV2, fetchItemsV2 } from '../actions/itemsV2Actions';
import { useDispatch } from 'react-redux';
import { withRouter } from '../utils/withRouter';

const useStyles = makeStyles({
  paper: {
    padding: 15,
  },
  title: {
    marginLeft: 12,
  },
  calendarBnts: {
    marginLeft: '40%',
  },
  calendar: {
    backgroundColor: '#141B41',
    borderRadius: '8px',
    padding: 18,
    height: 48,
    width: 180,
    margin: '10px 12px',
    '&:hover': {
      backgroundColor: '#141B41',
    },
  },
  calendarText: {
    color: 'white',
    marginRight: 55,
  },
  table: {
    padding: 13,
  },
});

const ItemListV2 = ({itemsV2Reducer, fetchItemsV2, onOpenDrawer, ...props}) => {
  const classes = useStyles();
  const permission = decodePermissions(getPermission());
  const dispatch = useDispatch();

  const [date, setDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const [state, setState] = React.useState({
    filterMenu: null,
  });

  const [filters, setFilters] = React.useState();
  const {filterMenu, filterName} = state;

  const {
    itemsV2: { itemsV2List }
  } = tableData;

  const { itemV2Filters } = filtersObj

  useEffect(() => {
    if (permission.users) {
      fetchItemsV2();
    }
  }, []);

  const items = itemsV2Reducer?.items

  const toggleFilter = React.useCallback(
    e => {
      setState({filterMenu: e});
    },
    [filterMenu],
  );

  const doSearch = (query, itemCountry, brandFilter, dateFilter) => {
    const data = {
      query,
      itemCountry,
      brandFilter,
      dateFilter,
    };
    fetchItemsV2(data);
  };

  const handleDateRange = ranges => {
    const {selection} = ranges;
    setDate([selection]);
  };

  const handleChange = (filterName, value, id, itemName, substate, subId) => event => {
    setFilters(prevState => ({
      ...prevState,
      [itemName ? itemName : value]: {
        [filterName]: event.target.checked,
        value: itemName ? value : 'default',
        id,
        substate: substate ?? 'default',
        subId: subId ?? 'default',
      },
    }));
  };

  const filterNotSelected = filterName => {
    return Object.keys(filterName)?.some(item => !filterName[item]);
  };

  const applyFilters = () => {
    const dateObj = {
      from: date[0]?.startDate?.setHours(0, 0, 0, 0),
      to: date[0]?.endDate?.setHours(23, 59, 59, 999)
    }

    const itemLocation = filters?.['item'] && filterNotSelected(filters?.['item']) ? '' : filters?.['item']?.id;
    const brandFilter = filters?.['brands']?.value;

    doSearch(null, itemLocation, brandFilter, dateObj);
  };

  return permission.users ? (
    <div>
      <div className="App-header">
        <IconButton onClick={onOpenDrawer}>
          <MenuIcon color="primary" />
        </IconButton>
        <span className="header-placeholder" />
      </div>
      <div className={classes.paper}>
        <Typography variant="h5" className={classes.title}>
          ITEMS
        </Typography>
        <div className="searchFilterContainer">
          <div className="calendar">
            <IconButton
              aria-haspopup="true"
              color="inherit"
              onClick={e => toggleFilter(e.currentTarget)}
              aria-controls="profile-menu"
              className={classes.calendar}>
              <img src={calendar} style={{marginRight: 35}} />
              <div>
                <Typography className={classes.calendarText}>Today</Typography>
              </div>
            </IconButton>
            <Menu
              id="menu-appbar"
              getContentAnchorEl={null}
              open={Boolean(filterMenu)}
              anchorEl={filterMenu}
              onClose={() => setState({filterMenu: null})}
              disableAutoFocusItem
              PaperProps={{
                className: classes.filterMenu,
              }}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              transformOrigin={{vertical: 'top', horizontal: 'center'}}>
              <div>
                <CalendarRangePicker date={date} handleChange={handleDateRange} />
              </div>
              <div className={classes.calendarBnts}>
                <Buttons setFilterMenu={() => setState({filterMenu: null})} applyFilters={applyFilters} date={date} />
              </div>
            </Menu>
          </div>
          <div className="userFilters">
            <Filters
              filters={filters}
              name={'itemsV2'}
              filterItems={itemV2Filters}
              applyFilters={applyFilters}
              handleChange={handleChange}
            />
          </div>
          <div className="userSearch" style={{paddingTop: 12}}>
            <SearchField
              placeholder="Search by ID, description or shupper name"
              doneColor={''}
              onRequestSearch={query => doSearch(query, null, null, null)}
            />
          </div>
        </div>
        <div className={`${classes.table} usersTable`}>
          <TableData
            width="100%"
            tableName="itemsV2"
            tablePagination={true}
            data={items}
            headerColumn={itemsV2List}
            fetchMore={() => dispatch(fetchMoreItemsV2())}
          />
        </div>
      </div>
    </div>
  ) : (
    <NoPermission onOpenDrawer={onOpenDrawer} />
  );
};

const mapStateToProps = ({itemsV2Reducer}) => ({
  itemsV2Reducer,
});

const mapDispatchToProps = dispatch => ({
  fetchItemsV2: data => dispatch(fetchItemsV2(data)),
  fetchMoreItemsV2: () => dispatch(fetchMoreItemsV2()),
  dispatch,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemListV2));
