/*
Roman Safonov
20/03/2018

Reducer for a list of items
*/
import types from '../actions/actionTypes';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 100,
  items: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: {},
  sort: {},
  isDeleting: false,
  deletionError: null,
};

export default function itemsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ITEMS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        items: [],
        totalCount: 0,
        searchQuery: action.query,
      });
    case types.FETCH_ITEMS_SORT_CHANGED: {
      const sorting = procesSort(state.sort, action.column, action.direction);
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        items: [],
        totalCount: 0,
        sort: sorting,
      });
    }
    case types.FETCH_MORE_ITEMS:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_ITEMS_SUCCESS: {
      const items = [].concat(state.items).concat(action.data.items);
      return Object.assign({}, state, {
        offset: state.offset + state.limit,
        totalCount: action.data.count,
        items,
        hasMore: items.length < action.data.count,
        isLoading: false,
      });
    }
    case types.FETCH_ITEMS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        hasMore: false,
      });
    case types.DELETE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        items: [],
        totalCount: 0,
      });
    case types.DELETE_ITEMS:
      return Object.assign({}, state, {
        isDeleting: true,
        deletionError: null,
      });
    case types.DELETE_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        deletionError: null,
        isLoading: true,
        offset: 0,
        items: [],
        totalCount: 0,
      });
    case types.DELETE_ITEMS_FAILED:
      return Object.assign({}, state, {
        isDeleting: false,
        deletionError: action.reason,
      });
    default:
      return state;
  }
}
