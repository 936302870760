import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';
import { createSelector } from 'reselect';



const initialState = {
    shuppers: [],
    approveSuccess: null,
    declineSuccess: null,
    approveError: null,

    isLoading: false,

};

const approveShuppersReducer = createReducer({
    [types.FETCH_SHUPPERS_SUCCESS]: (state, { payload }) => ({
        ...state,
        shuppers: payload.data,
        isLoading: false,
    }),
    [types.FETCH_SHUPPERS]: (state) => ({
        ...state,
        isLoading: true,
    }),
    [types.APPROVE_SHUPPER_SUCCESS]: (state) => ({
        ...state,
        approveSuccess: true,
    }),
    [types.DECLINE_SHUPPER_SUCCESS]: (state) => ({
        ...state,
        declineSuccess: true,
    }),
    [types.APPROVE_SHUPPER_ERROR]: (state) => ({
        ...state,
        approveError: true,
    }),
    [types.RESET_APPROVE_SHUPPER]: (state) => ({
        ...state,
        declineSuccess: false,
        approveSuccess: false,
        approveError: false,

    }),

}, initialState);


// SELECTORS


const approveShuppersStateSelector = _ => _.approveShuppersReducer;


export const getProps = createSelector(
    approveShuppersStateSelector,

    (
        { shuppers, isLoading, approveSuccess, declineSuccess, approveError },
    ) => ({
        shuppers, isLoading, approveSuccess, declineSuccess, approveError
    })
);

export default approveShuppersReducer;
