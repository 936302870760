import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';
import { createSelector } from 'reselect';

const initialState = {
    checkins: null,
    loading: false,
    hasMore: false,
    selectCheckin: null,
    shuppers: [],
    saveCheckinSuccess: false,
    newImageUrl: null,
    newVideoUrl: null,
    stores: [],
    newCheckin: null,
    deleteCheckinSuccess: false,
};

const checkinsReducer = createReducer({
    [types.GET_CHECKINS]: (state, { payload }) => ({
        ...state,
        loading: true,
    }),
    [types.GET_CHECKINS_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        checkins: payload.data,
        hasMore: payload.data.checkins.length < payload.data.totalCheckins,
    }),
    [types.SAVE_CHECKIN_BY_ID_SUCCESS]: (state, { payload }) => ({
        ...state,
        saveCheckinSuccess: true
    }),
    [types.SAVE_NEW_CHECKIN_SUCCESS]: (state, { payload }) => ({
        ...state,
        saveCheckinSuccess: true,
        newCheckin: payload.data
    }),

    [types.GET_CHECKIN_BY_ID_SUCCESS]: (state, { payload }) => ({
        ...state,
        selectCheckin: payload.data,
    }),

    [types.GET_SHUPPERS_SUCCESS]: (state, { payload }) => ({
        ...state,
        shuppers: payload.data,
    }),
    [types.GET_STORES_SUCCESS]: (state, { payload }) => ({
        ...state,
        stores: payload.data,
    }),
    [types.UPDATE_IMAGE_CHECKIN_SUCCESS]: (state, { payload }) => ({
        ...state,
        newImageUrl: payload.data,
    }),
    [types.UPDATE_VIDEO_CHECKIN_SUCCESS]: (state, { payload }) => ({
        ...state,
        newVideoUrl: payload.data,
    }),
    [types.RESET_CHECKINS]: (state) => ({
        ...state,
        saveCheckinSuccess: false,
        deleteCheckinSuccess: false
    }),
    [types.DELETE_CHECKINS_SUCCESS]: (state) => ({
        ...state,
        deleteCheckinSuccess: true,
    }),
}, initialState);


// SELECTORS
const checkinsStateSelector = _ => _.checkinsReducer;
export const getProps = createSelector(
    checkinsStateSelector,
    (
        { checkins, hasMore, loading, selectCheckin, shuppers, saveCheckinSuccess, newImageUrl, newVideoUrl, stores,
            deleteCheckinSuccess,
            newCheckin },
    ) => ({
        checkins, hasMore, loading, selectCheckin, shuppers, saveCheckinSuccess, newImageUrl, newVideoUrl, stores,
        deleteCheckinSuccess,
        newCheckin
    })
);

export default checkinsReducer;
