import React from 'react';
import SearchField from './searchfield';
import { List, ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';
import Subheader from 'material-ui/Subheader';



const MiniList = ({ items, chosen, choose, loadMore, onRequestSearch }) => (
    <React.Fragment>
        <SearchField onRequestSearch={onRequestSearch} doneColor="black" />
        <List style={{ height: '300px', overflow: 'scroll' }}>
        <Subheader>
          Results
        </Subheader>
        { items && items.length > 0 ?
            <React.Fragment>
                {
                    items.map(item => (
                        <ListItem
                            onClick={() => choose(item)}
                            key={item._id}
                            className={chosen && item.id === chosen.id ? 'active' : ''}
                            primaryText={item.title}
                            leftAvatar={<Avatar src={item.image} />}
                        />                
                    )) 
                }
            <div className="flxr jc ac user-mini-line red" onClick={loadMore}>Load More</div>
            </React.Fragment>
          : null
        }
      </List>
    </React.Fragment>
    
);
 export default MiniList;
 
