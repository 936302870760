import { takeLatest, put, call, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {
    fetchBrandsSuccess, approveBrandSuccess, declineBrandSuccess, approveBrandError, fetchPendingBrandsSuccess
} from '../actions/approveBrandsActions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* fetchBrandsForApprove({ payload }) {
    const response = yield call(
        request,
        `${baseUrl}/api/brand?status=${payload.status ? payload.status ? payload.status : "pending" : ''}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });

    yield put(fetchBrandsSuccess(response.data || [],
        response.data.length >= 20,
        response.data.length));
}
function* fetchPendingBrands({ payload }) {
    const response = yield call(
        request,
        `${baseUrl}/api/brand?status=${payload.status ? payload.status ? payload.status : "pending" : ''}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });

    yield put(fetchPendingBrandsSuccess(response.data || []));
}
function* declineBrand({ payload }) {
    const response = yield call(request,

        `${baseUrl}/api/brand/decline/${payload.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },

        })

    yield put(declineBrandSuccess(response));


}
function* approveBrand({ payload }) {

    const response = yield call(
        request,
        `${baseUrl}/api/brand/approve/${payload.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: null
        });
    if (response.err) {
        yield put(approveBrandError(response.err))
    } else yield put(approveBrandSuccess(response));
}

function* watchFetchBrandsForApprove() {
    yield takeLatest(types.FETCH_BRANDS_FOR_APPROVE, fetchBrandsForApprove);
}

function* watchApproveBrand() {
    yield takeLatest(types.APPROVE_BRAND, approveBrand);
}
function* watchDeclineBrand() {
    yield takeLatest(types.DECLINE_BRAND, declineBrand);
}
function* watchFetchPendingBrands() {
    yield takeLatest(types.FETCH_PENDING_BRANDS_FOR_APPROVE, fetchPendingBrands);
}


export default function* approveBrandsSaga() {
    yield all([
        watchApproveBrand(),
        watchFetchBrandsForApprove(),
        watchDeclineBrand(),
        watchFetchPendingBrands()
    ]);
}
