
import types from '../actions/actionTypes';

const initialState = {
  item: null,
  isLoading: false,
};

export default function itemV2Reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ITEM_V2:
      return {
        ...state,
        isLoading: true
      };
    case types.FETCH_ITEM_V2_SUCCESS: {
      return Object.assign({}, state, {
        item: action?.data?.item,
        isLoading: false,
      });
    }
    case types.FETCH_ITEM_V2_FAILED:
      return Object.assign({}, state, {
        isLoading: false
      });
    case types.UPDATE_ITEM_V2:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_ITEM_V2_SUCCESS: {
      return Object.assign({}, state, {
        item: action?.data?.item,
        isLoading: false,
      });
    }
    case types.UPDATE_ITEM_V2_FAILED:
      return Object.assign({}, state, {
        isLoading: false
      });
    default:
      return state;
  }
}
