import React from 'react';
import { connect } from 'react-redux';
import ArrowUpIcon from 'material-ui/svg-icons/navigation/expand-less';
import ArrowDownIcon from 'material-ui/svg-icons/navigation/expand-more';
import {IconButton} from 'material-ui';
import { switcherSelector } from '../../reducers/chatsReducer';
import { switcherActions } from '../../actions/chatsActions';


const SwitcherComponent = ({ jumpToMessage, idList, messageList, messageCount,
	                           activeIndex, selectedChat, authToken, messagesInChat }) => (
	<React.Fragment>
		<div className="flxc jsb ac">
			<IconButton
				tooltip="Previous Message"
				onClick={
					() => jumpToMessage(selectedChat,
						idList[activeIndex + 1],
						activeIndex + 1,
						authToken,
						messageList[idList[activeIndex + 1]],
						messagesInChat
					)
				}
				disabled={!messageCount || activeIndex === messageCount - 1}
			>
				<ArrowUpIcon />
			</IconButton>
			<div>
				{
					`${activeIndex + 1}/${messageCount}`
				}
			</div>
			<IconButton
				tooltip="Next Message"
				onClick={
					() => jumpToMessage(selectedChat,
						idList[activeIndex - 1],
						activeIndex - 1,
						authToken,
						messageList[idList[activeIndex - 1]],
						messagesInChat
					)
				}
				disabled={!messageCount || activeIndex <= 0}
			>
				<ArrowDownIcon />
			</IconButton>
		</div>
	</React.Fragment>
);

export default connect(switcherSelector, switcherActions)(SwitcherComponent);
