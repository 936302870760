import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PersonalInfoOld from "../components/profile/PersonalInfoOld";
// import RegistrationForm from '../components/profile/RegistrationForm'
import RegistrationForm from "../components/profile/RegistrationFormTab";
import { getPermission } from "../utils/cookies";
import { decodePermissions } from "../utils/permissionUtil";
import Tier from "../components/profile/Tier";
import Brands from "../components/profile/Brands";
import IconButton from "material-ui/IconButton";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import Tabs from "../components/Tabs";
import * as profileActions from "../actions/profileActions";
import * as bouserActions from "../actions/bouseractions";
import * as usersActions from "../actions/usersactions";
import RaisedButton from "material-ui/RaisedButton";
import "../static/styles/profile.css";
import LinearProgress from "material-ui/LinearProgress";
import { withRouter } from "../utils/withRouter";

class ProfileOld extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());

    this.state = {
      permission,
      dataUser: null,
      openRegeneratePassword: false,
      openAddBrands: false,
      showError: false,
      userListDialogOpened: false,
      userToLooseItems: null,
      profileImageIcon: null,
      selectedTier: null,
      selectedBrands: [],
      errors: false,
      buttonDisabled: false,
      errorUser: false,
      loadingImage: false,
      openNewPassword: false
    };
  }

  componentDidMount() {
    this.props.getUserData(this.props.params.id);
    this.props.fetchBrands();
    this.props.fetchTiers();
    this.props.getExtantionData(this.props.params.id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profileReducer.extantion !==
      this.props.profileReducer.extantion &&
      Object.keys(this.props.profileReducer.extantion).length !== 0
    ) {
      if (
        this.props.profileReducer.extantion &&
        this.props.profileReducer.extantion.regForm
      ) {
        this.props.profileReducer.extantion.regForm.speciality = this.props.profileReducer.extantion.regForm.speciality
          .map(v => {
            if (v.indexOf("other") !== -1) {
              let newArr = v.split(" ");
              delete newArr[0];
              let other = newArr.join(" ");
              return ["other", other];
            } else {
              return v;
            }
          })
          .flat(1);
        this.props.profileReducer.extantion.regForm.salesNews = this.props.profileReducer.extantion.regForm.salesNews
          .map(v => {
            if (v.indexOf("other") !== -1) {
              let newArr = v.split(" ");
              delete newArr[0];
              let other = newArr.join(" ");
              return ["other", other];
            } else {
              return v;
            }
          })
          .flat(1);
      }
    }
    if (
      prevProps.profileReducer.dataUser !==
      this.props.profileReducer.dataUser &&
      Object.keys(this.props.profileReducer.dataUser).length !== 0
    ) {
      this.setState({ dataUser: this.props.profileReducer.dataUser[0] });
    } else if (
      prevProps.profileReducer.dataUser !==
      this.props.profileReducer.dataUser &&
      Object.keys(this.props.profileReducer.dataUser).length === 0
    ) {
      this.setState({ errorUser: true });
    }
    if (
      prevProps.profileReducer.newImage !== this.props.profileReducer.newImage
    ) {
      let changedUser = {};
      Object.assign(changedUser, this.state.dataUser);
      changedUser.profileImageURL = this.props.profileReducer.newImage;
      this.setState({
        dataUser: changedUser,
        loadingImage: false,
        buttonDisabled: false
      });
    }

    if (
      (prevProps.profileReducer.extantion !==
        this.props.profileReducer.extantion &&
        this.props.profileReducer.extantion &&
        this.props.profileReducer.extantion.brands &&
        this.props.profileReducer.extantion.brands.length > 0 &&
        this.props.profileReducer.brands &&
        this.props.profileReducer.brands.events &&
        this.props.profileReducer.brands.events.length > 0) ||
      (prevProps.profileReducer.brands !== this.props.profileReducer.brands &&
        this.props.profileReducer.brands &&
        this.props.profileReducer.brands.events &&
        this.props.profileReducer.brands.events.length > 0 &&
        this.props.profileReducer.extantion &&
        this.props.profileReducer.extantion.brands &&
        this.props.profileReducer.extantion.brands.length > 0)
    ) {
      this.setState({
        selectedBrands: this.props.profileReducer.brands.events.filter(
          item =>
            this.props.profileReducer.extantion.brands.indexOf(item._id) !== -1
        )
      });
    }
    if (
      prevProps.profileReducer.extantion !==
      this.props.profileReducer.extantion &&
      this.props.profileReducer.extantion &&
      this.props.profileReducer.extantion.tier
    ) {
      this.setState({
        selectedTier: this.props.profileReducer.extantion.tier
      });
    }
    if (
      prevProps.profileReducer.saveUserSuccess !==
      this.props.profileReducer.saveUserSuccess
    ) {
      this.props.navigate("/users");
    }
    if (
      prevProps.boUserReducer.newPassword !==
      this.props.boUserReducer.newPassword &&
      this.props.boUserReducer.newPassword
    ) {
      this.setState({ openNewPassword: true });
      this.openDialogRegeneratePassword();
    }
  }
  onChangeInput = (e, name, value) => {
    const { dataUser } = this.state;
    let changedUser = {};
    Object.assign(changedUser, dataUser);
    changedUser[e] = value;
    this.setState({ dataUser: changedUser });
  };
  onChangeCheckbox = (name, value) => {
    const { dataUser } = this.state;
    let changedUser = {};
    Object.assign(changedUser, dataUser);
    let newArray =
      changedUser[name].indexOf(value) === -1
        ? [...changedUser[name], value]
        : changedUser[name].filter(el => el !== value);
    changedUser[name] = newArray;
    this.setState({ dataUser: changedUser });
  };
  onChangeRadioButton = (name, value) => {
    const { dataUser } = this.state;
    let changedUser = {};
    Object.assign(changedUser, dataUser);
    changedUser[name] = value;
    this.setState({ dataUser: changedUser });
  };
  getNewValueImage = (key, val) => {
    const { dataUser } = this.state;
    let changedUser = {};
    Object.assign(changedUser, dataUser);
    changedUser.profileImageURL = val;
    if (key === "icon") {
      this.setState({ profileImageIcon: val });
    } else {
      this.setState({ profileImageFile: val });
    }
  };
  setNewImage = val => {
    const { dataUser } = this.state;
    const { updateProfileImage } = this.props;
    let changedUser = {};
    Object.assign(changedUser, dataUser);
    changedUser.profileImageURL = val;

    updateProfileImage({ _id: dataUser._id, newImage: val });
    this.setState({
      uploaderOpened: false,
      avatarUploaderImage: null,
      profileImageURL: val,
      dataUser: changedUser,
      loadingImage: true,
      profileImageFile: null,
      imageUserId: null,
      buttonDisabled: true
    });
  };
  openDialogRegeneratePassword = () => {
    const { openRegeneratePassword } = this.state;

    this.setState({ openRegeneratePassword: !openRegeneratePassword });
  };

  openDialogAddBrands = () => {
    const { openAddBrands } = this.state;

    this.setState({ openAddBrands: !openAddBrands });
  };
  openDialogNewPassword = () => {
    const { openNewPassword } = this.state;

    this.setState({ openNewPassword: !openNewPassword });
  };
  regeneratePassword = () => {
    this.props.regeneratePassword(this.props.params.id);
  };

  searchBrands = query => {
    this.props.fetchBrands(query);
  };

  searchForUsers = (query, roles, roleFilter, statusFilter, genderFilter) => {
    this.props.fetchUsers(query, roles, roleFilter, statusFilter, genderFilter);
  };
  moveItems = ev => {
    const { dataUser } = this.state;
    ev.stopPropagation();
    this.searchForUsers("", ["shupper"], null, null, null);
    this.setState({
      userListDialogOpened: true,
      userToLooseItems: dataUser._id
    });
  };
  closeMoveItemsDialog = () => {
    this.setState({
      userListDialogOpened: false,
      userToLooseItems: null,
      userToGainItems: null
    });
  };
  chooseUserInMiniList = ({ id }) => this.setState({ userToGainItems: id });
  onChangeSelect = (e, name, val) => {
    this.setState({ selectedTier: val });
  };
  handleAddBrand = val => {
    const { selectedBrands } = this.state;
    selectedBrands.push(val);
    this.setState({ selectedBrands });
  };
  handleDeleteBrands = val => {
    const { selectedBrands } = this.state;
    let newSelectedBrands = selectedBrands.filter(item => item !== val);
    this.setState({ selectedBrands: newSelectedBrands });
  };
  handleSave = () => {
    const { dataUser, selectedTier, selectedBrands } = this.state;
    const {
      email,
      feedIndex,
      firstName,
      gender,
      lastName,
      location,
      pinnedLocation,
      profileImageURL,
      roles,
      status
    } = dataUser;
    this.setState({ showErrors: true });
    let changedUser = {};
    Object.assign(changedUser, dataUser);
    delete changedUser.address;
    delete changedUser.position;
    delete changedUser._id;
    delete changedUser.created;
    changedUser.displayName = `${firstName} ${lastName}`;
    if (
      email &&
      feedIndex &&
      firstName &&
      gender &&
      lastName &&
      location &&
      pinnedLocation &&
      profileImageURL &&
      roles.length > 0 &&
      status
    ) {
      this.setState({ errors: false, buttonDisabled: true });
    } else {
      this.setState({ errors: true });
      return;
    }
    this.props.saveProfileData({ id: this.props.params.id, data: changedUser });

    this.props.saveExtantionData({
      id: this.props.params.id,
      data: { tier: selectedTier, brands: selectedBrands.map(item => item._id) }
    });
  };
  render() {
    const {
      onOpenDrawer,
      usersReducer,
      moveItemsBetweenUsers,
      profileReducer
    } = this.props;
    const {
      dataUser,
      openRegeneratePassword,
      userListDialogOpened,
      profileImageFile,
      selectedBrands,
      openAddBrands,
      showErrors,
      userToLooseItems,
      userToGainItems,
      profileImageIcon,
      selectedTier,
      errors,
      buttonDisabled,
      errorUser,
      loadingImage,
      openNewPassword,
      permission
    } = this.state;
    const shupperCondition =
      (dataUser && dataUser.roles.indexOf("shupper") !== -1) ||
      (dataUser && dataUser.roles.indexOf("store") !== -1) ||
      (dataUser && dataUser.roles.indexOf("supershupper") !== -1);

    if (errorUser)
      return (
        <div>
          <div className="App-header">
            <IconButton onClick={onOpenDrawer}>
              <NavigationMenu color="white" />
            </IconButton>
          </div>
          <div className="text-center">
            <h2>User is not defined</h2>
          </div>
        </div>
      );
    return (
      <div>
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
        </div>
        <div className="progressbar-container">
          {loadingImage && <LinearProgress mode="indeterminate" />}
        </div>
        <div>
          <div className="profile-error">
            {errors ? "Fill all of the fields in Personal Info" : ""}
          </div>
          <div className="save-button">
            <RaisedButton
              disabled={buttonDisabled}
              onClick={this.handleSave}
              label="Save"
              primary
            />
          </div>
        </div>
        <div className="profile-tabs flxc ac jc">
          <Tabs
            tabs={["Personal Info", "Registration Form", "Tier", "Brands"]}
            disabled={
              shupperCondition ? [] : ["Registration Form", "Tier", "Brands"]
            }
            shupperCondition={shupperCondition}
            contents={[
              <PersonalInfoOld
                dataUser={dataUser}
                permission={permission}
                shupperCondition={shupperCondition}
                onChangeInput={this.onChangeInput}
                onChangeCheckbox={this.onChangeCheckbox}
                getNewValueImage={this.getNewValueImage}
                regeneratePassword={this.regeneratePassword}
                openRegeneratePassword={openRegeneratePassword}
                openDialogRegeneratePassword={this.openDialogRegeneratePassword}
                showErrors={showErrors}
                onChangeRadioButton={this.onChangeRadioButton}
                moveItems={this.moveItems}
                userToLooseItems={userToLooseItems}
                userToGainItems={userToGainItems}
                usersReducer={usersReducer}
                userListDialogOpened={userListDialogOpened}
                closeMoveItemsDialog={this.closeMoveItemsDialog}
                moveItemsBetweenUsers={moveItemsBetweenUsers}
                searchForUsers={this.searchForUsers}
                chooseUserInMiniList={this.chooseUserInMiniList}
                profileImageFile={profileImageFile}
                setNewImage={this.setNewImage}
                profileImageIcon={profileImageIcon}
                newPassword={this.props.boUserReducer.newPassword}
                openNewPassword={openNewPassword}
                openDialogNewPassword={this.openDialogNewPassword}
              />,
              <RegistrationForm dataUser={profileReducer.extantion} />,
              <Tier
                dataUser={dataUser}
                selectedTier={selectedTier}
                onChangeSelect={this.onChangeSelect}
                tiers={profileReducer.tiers}
              />,
              <Brands
                dataUser={dataUser}
                openAddBrands={openAddBrands}
                openDialogAddBrands={this.openDialogAddBrands}
                searchBrands={this.searchBrands}
                brands={profileReducer.brands}
                selectedBrands={selectedBrands}
                handleAddBrand={this.handleAddBrand}
                handleDeleteBrands={this.handleDeleteBrands}
              />
            ]}
            width="80%"
          />
        </div>
      </div>
    );
  }
}

ProfileOld.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  fetchTiers: PropTypes.func.isRequired,
  getExtantionData: PropTypes.func.isRequired,
  saveExtantionData: PropTypes.func.isRequired,
  regeneratePassword: PropTypes.func.isRequired,
  moveItemsBetweenUsers: PropTypes.func.isRequired,
  updateProfileImage: PropTypes.func.isRequired,
  saveProfileData: PropTypes.func.isRequired
};
const mapStateToProps = ({ usersReducer, profileReducer, boUserReducer }) => ({
  usersReducer,
  profileReducer,
  boUserReducer
});
const mapDispatchToProps = dispatch => ({
  fetchUsers: (query, roles, roleFilter, statusFilter, genderFilter) =>
    dispatch(
      usersActions.fetchUsers(
        query,
        roles,
        roleFilter,
        statusFilter,
        genderFilter
      )
    ),
  getUserData: id => dispatch(profileActions.getUserData(id)),
  fetchBrands: query => dispatch(profileActions.fetchBrands(query)),
  fetchTiers: payload => dispatch(profileActions.fetchTiers(payload)),
  getExtantionData: id => dispatch(profileActions.getExtantionData(id)),
  saveExtantionData: data => dispatch(profileActions.saveExtantionData(data)),
  regeneratePassword: id => dispatch(bouserActions.regeneratePassword(id)),
  moveItemsBetweenUsers: (from, to) =>
    dispatch(usersActions.moveItemsBetweenUsers(from, to)),
  updateProfileImage: user => dispatch(profileActions.updateProfileImage(user)),
  saveProfileData: data => dispatch(profileActions.saveProfileData(data))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileOld));
