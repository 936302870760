/*
Kateryna Ivanova
28/03/2018

Sagas for working with a list of sollections
*/
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchCollectionsSuccess, fetchCollectionsFailed, deleteCollectionsFailed, deleteCollectionsSuccess } from '../actions/collectionsactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectCollectionsState = state => state.collectionsReducer;

function* fetchCollectionsAsync() {
  const collectionsState = yield select(selectCollectionsState);
  const sorting = Object.keys(collectionsState.sort).map(key => `&sort[${key}]=${collectionsState.sort[key]}`).join('');
  let queryName = '';
  if (collectionsState.searchQuery) {
    queryName = collectionsState.searchQuery.name ? `&name=${collectionsState.searchQuery.name}` : '';
  }
  let queryOwner = '';
  if (collectionsState.searchQuery) {
    queryOwner = collectionsState.searchQuery.owner ? `&owner=${collectionsState.searchQuery.owner}` : '';
  }
  const response = yield call(
    request,
    `${baseUrl}/api/collection?limit=${collectionsState.limit}&skip=${collectionsState.offset}${queryName}${queryOwner}${sorting}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchCollectionsFailed(response.err.toString()));
  } else {
    yield put(fetchCollectionsSuccess(response.data));
  }
}

function* deleteCollectionsAsync(action) {
  const collectionsIds = action.ids.join(',');
  const response = yield call(
    request,
    `${baseUrl}/api/collection?ids=${collectionsIds}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteCollectionsFailed(response.err.toString()));
  } else {
    yield put(deleteCollectionsSuccess());
  }
}

function* fetchCollections() {
  yield takeLatest([`${types.FETCH_COLLECTIONS}`, `${types.FETCH_MORE_COLLECTIONS}`, `${types.FETCH_COLLECTIONS_SORT_CHANGED}`,
    `${types.DELETE_COLLECTION_SUCCESS}`, `${types.DELETE_COLLECTIONS_SUCCESS}`, `${types.UPDATE_COLLECTION_SUCCESS}`], fetchCollectionsAsync);
}

function* deleteCollections() {
  yield takeLatest([`${types.DELETE_COLLECTIONS}`], deleteCollectionsAsync);
}

export default function* collectionssaga() {
  yield all([
    fetchCollections(),
    deleteCollections(),
  ]);
}
