import React from 'react';
import './index.css'
import { Grid, Typography, Paper } from '@material-ui/core';

const PaymentRequestContent = ({ content }) => {
  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" className="payment-request-content-title-wrapper">
        <Typography variant="h5">
          Content
        </Typography>
      </Grid>
      <Paper className="payment-request-content-wrapper">
        <Grid container xs={12} justifyContent="space-between">
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="payment-request-content-titles">Content</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="payment-request-content-titles">Quantity</Typography>
          </Grid>
          { content?.map(contentItem => (
            <>
              <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className="payment-request-content-text">{ contentItem.content_type_name }</Typography>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className="payment-request-content-text">{ contentItem.quantity }</Typography>
              </Grid>
            </>
          ))}
        </Grid>
      </Paper>
    </div>
  );
};

export default PaymentRequestContent;
