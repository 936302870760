import types from './actionTypes';

export const fetchItemV2 = data => ({
  type: types.FETCH_ITEM_V2,
  data,
});

export const fetchItemV2Success = data => ({
  type: types.FETCH_ITEM_V2_SUCCESS,
  data,
});

export const fetchItemV2Failed = reason => ({
  type: types.FETCH_ITEM_V2_FAILED,
  reason,
});

export const updateItemV2 = data => ({
  type: types.UPDATE_ITEM_V2,
  data,
});

export const updateItemV2Success = data => ({
  type: types.UPDATE_ITEM_V2_SUCCESS,
  data,
});

export const updateItemV2Failed = reason => ({
  type: types.UPDATE_ITEM_V2_FAILED,
  reason,
});