/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import SetupIcon from "material-ui/svg-icons/action/build";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { RaisedButton } from "material-ui";
import IconButton from "material-ui/IconButton";
import NoPermission from "../../../components/nopermission";
import { getPermission } from "../../../utils/cookies";
import { decodePermissions } from "../../../utils/permissionUtil";
import PaginatedGrid from "../../../components/paginatedgridMulti";
import * as shippingActions from "../../../actions/logistics/shippingactions";
import * as shippingPickupActions from "../../../actions/logistics/shippingPickupActions";
import ImageViewer from "../../../components/imageviewer";
import SearchField from "../../../components/searchfield";
import SelectDialog from "../../../components/SelectDialog";
import { changePaymentStatus } from "../../../actions/logistics/singlePaymentActions";
import getBaseUrl from "../../../utils/envSetup";
import * as usersActions from "../../../actions/usersactions";
import ShippingPickup from "../../../containers/logistics/shippingPickup";
import { tableData } from "../../../utils/tableData";

const baseUrl = getBaseUrl();
class Shipping extends Component {
    constructor(props) {
        super(props);
        const permission = decodePermissions(getPermission());
        this.state = {
            permission,
            isOpenImageViewer: false,
            images: [],
            changeStatus: false,
            itemToChangeStatus: null,
            itemToChangeStatusKey: null,
            selected: [],
            selectedShipper: { value: "", label: "All Shippers" },
            tab: 0
        };
    }

    componentDidMount() {
        if (this.state.permission.seeShipping) {
            this.props.fetchShipping();
            this.props.fetchUsers('', ['shupper']);

        }
    }

    openImageViewer = images => {
        this.setState({
            isOpenImageViewer: true,
            images
        });
    };

    closeImageViewer = () => {
        this.setState({
            isOpenImageViewer: false
        });
    };

    openStatusDialog = (itemToChangeStatus, itemToChangeStatusKey) => () => {
        this.setState({
            changeStatus: true,
            itemToChangeStatus,
            itemToChangeStatusKey
        });
    };

    closeStatusDialog = () => {
        this.setState({
            changeStatus: false
        });
    };

    handleSelection = (selectedItems) => {
        const { shippingList } = this.props;
        const result = [];
        selectedItems.forEach((selectedItem) => {
            result.push(shippingList[selectedItem]);
        });
        this.setState({ selected: result });
    };

    onDateRangeSet(startDate, endDate) {
        this.props.fetchShippingDateRangeChanges(startDate, endDate);
    }

    doSearch(query) {
        this.props.fetchShipping(query);
    }
    selectShipper = (shipper) => {
        this.setState({
            selected: []
        })
        this.props.selectShipper(shipper)
    }
    changeTab = (tab) => {
        this.setState({
            tab,
            selected: []
        })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.savingShippingPickup && this.props.savingShippingPickup) {
            this.setState({
                selected: []
            })
        }
    }

    render() {
        const { logisticsShipping: { columnWidths, headerData } } = tableData
        const {
            onOpenDrawer, fetchMoreShipping, shippingList, hasMore, isLoading, totalCount, sort,
            fetchShippingSortChanged, startDate, endDate, changePaymentStatus, shippers, selectedShipper, saveShippingPickup, savingShippingPickup
        } = this.props;
        const { permission, isOpenImageViewer, images, itemToChangeStatus, itemToChangeStatusKey, changeStatus, selected, tab } = this.state;
        const tableHeight = window.innerHeight - 257;

        return permission.seeCanceled ?
            <div className="logistics-container">
                <SelectDialog
                    items={["cancelled", "fulfilled", "pending", "refunded", "rejected", "shipping"]}
                    type="status"
                    handleChange={changePaymentStatus}
                    value="shipping"
                    payment={itemToChangeStatus}
                    position={itemToChangeStatusKey}
                    display={changeStatus}
                    close={this.closeStatusDialog}
                />
                <div className="App-header">
                    <IconButton onClick={onOpenDrawer}>
                        <NavigationMenu color="white" />
                    </IconButton>
                    <div className="header-search-area">
                        <SearchField onRequestSearch={query => this.doSearch(query)} />
                    </div>
                    <div className="logistics-date-range-picker-cont">
                        <DateRangePicker
                            drops="down"
                            opens="center"
                            startDate={startDate
                                ? moment(startDate)
                                : moment("01/01/2017", "DD/MM/YYYY")
                            }
                            endDate={endDate ? moment(endDate) : moment()}
                            timePicker
                            timePicker24Hour
                            showDropdowns
                            onApply={(ev, picker) => {
                                this.onDateRangeSet(
                                    picker.startDate.toDate().toJSON(),
                                    picker.endDate.toDate().toJSON()
                                );
                            }}
                            ranges={{
                                "All Times": [moment("01/01/2017", "DD/MM/YYYY"), moment()],
                                "Last 24 hours": [moment().subtract(1, "days"), moment()],
                                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                                "Month to date": [moment().startOf("month"), moment()]
                            }}
                        >
                            <RaisedButton
                                label={startDate && endDate
                                    ? `${moment(startDate).format("lll")}
										- ${moment(endDate).format("lll")}`
                                    : "Pick date range"}
                                primary
                            />
                        </DateRangePicker>
                    </div>
                    <a href={baseUrl + "/api/logistics/shipping?csv=true" + (startDate ?
                        `&startDate=${startDate}` : "") + (endDate ? `&endDate=${endDate}` : "")} download>
                        <RaisedButton
                            label="Download CSV"
                            primary
                        />
                    </a>
                </div>

                <div className="logistics-collections-tabs">
                    <Link to="/logistics/pending" className="logistics-collections-tab">Pending</Link>
                    <Link to="/logistics/rejected" className="logistics-collections-tab">Rejected</Link>
                    <Link to="/logistics/canceled" className="logistics-collections-tab">Canceled</Link>
                    <Link to="/logistics/completed" className="logistics-collections-tab">Completed</Link>
                    <Link to="/logistics/refunded" className="logistics-collections-tab">Refunded</Link>
                    <Link to="/logistics/shipping"
                        className="logistics-collections-tab logistics-collections-tab-active">Shipping</Link>
                </div>
                <div className="logistics-collections-tabs">
                    <span onClick={() => this.changeTab(0)} className={["logistics-collections-tab", tab === 0 ? 'logistics-collections-tab-active' : ''].join(" ")}>Shipping</span>
                    <span onClick={() => this.changeTab(1)} className={["logistics-collections-tab", tab === 1 ? 'logistics-collections-tab-active' : ''].join(" ")} >Pickup</span>
                </div>
                {tab === 0 ? <PaginatedGrid
                    saveShippingPickup={saveShippingPickup}
                    data={shippingList}
                    savingShippingPickup={savingShippingPickup}
                    tableHeight={tableHeight}
                    columnWidths={columnWidths}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    onSelectRow={this.handleSelection}
                    selection={selected}
                    header={headerData}
                    shippers={shippers}
                    selectedShipper={selectedShipper}
                    selectShipper={this.selectShipper}
                    totalResults={totalCount}
                    sortableCols={headerData[0].id}
                    currentSort={sort}
                    onSort={(column, direction) => fetchShippingSortChanged(column, direction)}
                    items={shippingList.map((item, key) => {
                        const dateTime = moment(item.barcodeUpdated).format("YYYY-MM-DD HH:mm:ss");
                        const images = item.imageUrls ?
                            item.imageUrls.map(url => ({ src: url }))
                            : [];
                        return [
                            <Link to={`/logistics/payment/${item._id}`}><span>{dateTime}</span></Link>,
                            <span>
                                <img
                                    alt=""
                                    className="avatar viewer"
                                    src={images[0] || ""}
                                    onClick={() => images.length && this.openImageViewer(images)}
                                />
                            </span>,
                            <span>{item.itemsTotalPrice}</span>,
                            <span>{item._id}</span>,
                            <span>{item.user && item.user.displayName}</span>,
                            <span>{item.shupper && item.shupper.displayName} </span>,
                            <span> </span>,
                            <span> </span>,
                            <span> {item.description} </span>,
                            <span>{item.user && item.user._id}</span>,
                            <span>{item.user.address && `${item.user.address.street},${item.user.address.houseNumber}`}</span>,
                            <span>{item.user.address && item.user.address.city}</span>,
                            <span> </span>,
                            <span>{item.user.address && item.user.address.zipCode}</span>,
                            <span>{item.user.address && item.user.address.phoneNumber}</span>,
                            <span>
                                {
                                    permission.changePaymentStatus ?
                                        <IconButton onClick={this.openStatusDialog(item, key)}>
                                            <SetupIcon color="black" />
                                        </IconButton>
                                        : null
                                }
                            </span>
                        ];
                    })}
                    onLoadMore={fetchMoreShipping}
                /> : null}
                {tab === 1 ? <ShippingPickup /> : null}
                <ImageViewer isOpen={isOpenImageViewer} onClose={this.closeImageViewer} images={images} />
            </div>
            :
            <NoPermission onOpenDrawer={onOpenDrawer} />;
    }
}

Shipping.propTypes = {
    onOpenDrawer: PropTypes.func.isRequired,
    shippingList: PropTypes.array.isRequired,
    hasMore: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    fetchShipping: PropTypes.func.isRequired,
    fetchMoreShipping: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    fetchShippingSortChanged: PropTypes.func.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    fetchShippingDateRangeChanges: PropTypes.func.isRequired,
    shippers: PropTypes.array.isRequired,

};

const mapStateToProps = state => ({
    shippingList: state.logistics.shipping.logistics,
    hasMore: state.logistics.shipping.hasMore,
    isLoading: state.logistics.shipping.isLoading,
    totalCount: state.logistics.shipping.totalCount,
    bla: state.logistics.shippingPickup,
    sort: state.logistics.shipping.sort,
    startDate: state.logistics.shipping.startDate,
    endDate: state.logistics.shipping.endDate,
    shippers: state.logistics.shipping.shippers,
    selectedShipper: state.logistics.shipping.selectedShipper,
    savingShippingPickup: state.logistics.shippingPickup.savingShippingPickup


});

const mapDispatchToProps = dispatch => ({
    fetchShipping: query => dispatch(shippingActions.fetchShipping(query)),
    fetchMoreShipping: () => dispatch(shippingActions.fetchMoreShipping()),
    fetchShippingSortChanged: (column, direction) =>
        dispatch(shippingActions.fetchShippingSortChanged(column, direction)),
    fetchShippingDateRangeChanges: (startDate, endDate) =>
        dispatch(shippingActions.fetchShippingDateRangeChanges(startDate, endDate)),
    changePaymentStatus: ({ prevStatus, payment, status, position }) =>
        dispatch(changePaymentStatus({ prevStatus, payment, status, position })),
    fetchUsers: (query, roles, roleFilter, statusFilter, genderFilter) =>
        dispatch(usersActions.fetchUsers(query, roles, roleFilter, statusFilter, genderFilter)),
    selectShipper: shupper => dispatch(shippingActions.selectShipper(shupper)),
    saveShippingPickup: data => dispatch(shippingPickupActions.saveShippingPickup(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Shipping);
