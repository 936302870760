/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../actionTypes';

export const fetchRefunded = query => ({
  type: types.LOGISTICS.FETCH_REFUNDED,
  query,
});

export const fetchMoreRefunded = () => ({
  type: types.LOGISTICS.FETCH_MORE_REFUNDED,
});

export const fetchRefundedSuccess = data => ({
  type: types.LOGISTICS.FETCH_REFUNDED_SUCCESS,
  data,
});

export const fetchRefundedFailed = reason => ({
  type: types.LOGISTICS.FETCH_REFUNDED_FAILED,
  reason,
});

export const fetchRefundedSortChanged = (column, direction) => ({
  type: types.LOGISTICS.FETCH_REFUNDED_SORT_CHANGED,
  column,
  direction,
});

export const fetchRefundedDateRangeChanges = (startDate, endDate) => ({
  type: types.LOGISTICS.FETCH_REFUNDED_DATERANGE_CHANGED,
  startDate,
  endDate,
});