import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';

const initialState = {
  offset: 0,
  limit: 100,
  packages: [],
  searchQuery: '',
  fromCountryFilter: '',
  toCountryFilter: '',
  stateFilter: '',
  substateFilter: '',
  dateFilter: {},
};

export default createReducer(
  {
    [types.FETCH_PACKAGES]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      packages: [],
      searchQuery: action?.data?.searchQuery ?? initialState.searchQuery,
      fromCountryFilter: action?.data?.fromCountryFilter ?? initialState.fromCountryFilter,
      toCountryFilter: action?.data?.toCountryFilter ?? initialState.toCountryFilter,
      stateFilter: action?.data?.stateFilter ?? initialState.stateFilter,
      substateFilter: action?.data?.substateFilter ?? initialState.substateFilter,
      dateFilter: action?.data?.dateFilter ?? initialState.dateFilter
    }),

    [types.FETCH_PACKAGES_SUCCESS]: (state, action) => ({
      ...state,
      offset: state.offset + state.limit,
      totalCount: action.data.count,
      packages: [...state.packages, ...action.data.packages],
      hasMore: state?.packages?.length + action?.data?.packages?.length < action?.data?.count,
      isLoading: false,
    }),
    [types.FETCH_PACKAGES_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasMore: false,
    }),
    [types.FETCH_MORE_PACKAGES]: state => ({
      ...state,
      isLoading: true,
    }),
  },
  initialState,
);
