/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
  fetchRefundedFailed, fetchRefundedSuccess
} from '../../actions/logistics/refundedactions';
import getBaseUrl from '../../utils/envSetup';

const baseUrl = getBaseUrl();

const selectLogisticsState = state => state.logistics.refunded;

function* fetchRefundedAsync() {
  const logisticsState = yield select(selectLogisticsState);
  const sorting = Object.keys(logisticsState.sort).map(key => `&sort[${key}]=${logisticsState.sort[key]}`).join('');
  const startDate = logisticsState.startDate ? `&startDate=${logisticsState.startDate}` : '';
  const endDate = logisticsState.endDate ? `&endDate=${logisticsState.endDate}` : '';
  const searchQuery = logisticsState.searchQuery ? `&userName=${logisticsState.searchQuery}` : '';
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/refunded?limit=${logisticsState.limit}&skip=${logisticsState.offset}${sorting}${startDate}${endDate}${searchQuery}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchRefundedFailed(response.err.toString()));
  } else {
    yield put(fetchRefundedSuccess(response.data));
  }
}

function* fetchRefunded() {
  yield takeLatest([`${types.LOGISTICS.FETCH_REFUNDED}`, `${types.LOGISTICS.FETCH_MORE_REFUNDED}`,
    `${types.LOGISTICS.FETCH_REFUNDED_SORT_CHANGED}`, `${types.LOGISTICS.FETCH_REFUNDED_DATERANGE_CHANGED}`]
    , fetchRefundedAsync);
}

export default function* refundedsaga() {
  yield all([
    fetchRefunded(),
  ]);
}
