/*
Kateryna Ivanova
28/03/2018

Collections list page
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import DatePicker from 'material-ui/DatePicker';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import SocialPersonAdd from 'material-ui/svg-icons/social/person-add';
import Avatar from 'material-ui/Avatar';
import Chip from 'material-ui/Chip';
import { Card, CardActions, CardMedia, CardTitle } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import * as collectionActions from '../actions/collectionactions';
import * as usersActions from '../actions/usersactions';
import NoPermission from '../components/nopermission';
import ValuePicker from '../components/valuePicker';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';

import '../static/styles/collection.css';
import { withRouter } from '../utils/withRouter';

class Collection extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      id: null,
      description: null,
      items: [],
      created: null,
      updated: null,
      duration: null,
      errorDescription: '',
      ownerPickerOpen: false,
      owner: null,
    };
  }
  locationQuery = queryString.parse(this?.props?.location?.search).id
  componentDidMount() {
    if (this.state.permission.items) {
      if (this.locationQuery) {
        this.props.fetchCollection(this.locationQuery);
      } else {
        this.setState({ id: 'new' });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { id } = this.state;
    if (
      newProps.collectionReducer &&
      newProps.collectionReducer.collection &&
      newProps.collectionReducer.collection._id !== id
    ) {
      const { collectionReducer: { collection } } = newProps;
      this.setState({
        id: collection._id,
        description: collection.description,
        longDescription: collection.longDescription,
        items: collection.items,
        created: collection.created,
        updated: collection.updated,
        duration: collection.duration,
        owner: collection.owner,
      });
    }
  }

  validateData() {
    const { description } = this.state;
    if (!description) {
      this.setState({ errorDescription: 'Description should not be empty' });
      return false;
    }
    return true;
  }

  requestUpdate() {
    if (!this.validateData()) {
      return;
    }
    const {
      id, description, longDescription, created, updated, duration, items, owner,
    } = this.state;
    this.props.updateCollection({
      _id: id,
      description,
      longDescription,
      created,
      updated,
      duration,
      items: items.map(item => item._id),
      owner,
    });
  }

  removeItem(id) {
    const { items } = this.state;
    const updated = items.filter(item => item._id !== id);
    this.setState({ items: updated });
  }

  ownerSelected = (owner) => {
    this.setState({ owner });
  };

  render() {
    const { collectionReducer, onOpenDrawer, usersReducerOld, getOnlyShuppers } = this.props;
    const {
      description, longDescription, items, created, updated,
      errorDescription, permission, owner, ownerPickerOpen,
    } = this.state;

    return permission.items ?
      (collectionReducer.collection &&
        <div>
          <div className="App-header">
            <IconButton onClick={() => this.props.navigate(-1)}>
              <NavigationArrowBack color="white" />
            </IconButton>
          </div>
          <form className="collection-container">
            <TextField
              style={{ width: '100%' }}
              floatingLabelText="Description"
              errorText={errorDescription}
              onChange={ev => this.setState({ description: ev.target.value })}
              value={description}
            />
            <TextField
              style={{ width: '100%' }}
              floatingLabelText="Long description"
              onChange={ev => this.setState({ longDescription: ev.target.value })}
              value={longDescription}
            />
            <div className="collection-items">
              {items.map(item => (
                <Card key={item._id} style={{ marginBottom: '10px', width: '270px' }}>
                  <CardMedia overlay={<CardTitle title={item.description} />}>
                    {
                      item.videoUrl !== '' ?
                        <video src={item.videoUrl} autoPlay muted poster={item.videoPreviewImg} loop /> :
                        <img src={item.imageUrl} alt="" />
                    }
                  </CardMedia>
                  <CardActions>
                    <FlatButton label="Remove" onClick={() => this.removeItem(item._id)} />
                  </CardActions>
                </Card>
              ))}
            </div>
            <div className="label">
              <span>Edit owner</span>
              <FlatButton
                onClick={() => this.setState({ ownerPickerOpen: true })}
                style={{ marginLeft: '10px' }}
                icon={<SocialPersonAdd />}
              />
            </div>
            <div className="chip-container">
              <Chip
                disabled
                style={{ margin: 4 }}
              >
                {owner &&
                  <Avatar src={owner.profileImageURL} />
                }
                {`${owner.displayName}`}
              </Chip>
            </div>
            <DatePicker
              style={{ marginTop: '10px' }}
              value={created && moment(created).toDate()}
              onChange={(_, date) => this.setState({ created: moment(date).format() })}
              floatingLabelText="Created Date"
            />
            <DatePicker
              style={{ marginTop: '10px' }}
              value={updated && moment(updated).toDate()}
              onChange={(_, date) => this.setState({ updated: moment(date).format() })}
              floatingLabelText="Updated Date"
            />
            {collectionReducer.updateSuccess &&
              <div className="message-box success-message">
                Collection is successfully updated
              </div>
            }
            {collectionReducer.updateError &&
              <div className="message-box error-message">
                {collectionReducer.updateError}
              </div>
            }
            <div className="save-btn">
              {collectionReducer.isUpdating
                ?
                <CircularProgress />
                :
                <RaisedButton
                  backgroundColor="#16cd2f"
                  onClick={() => this.requestUpdate()}
                  labelStyle={{ color: '#fff' }}
                  label="Save changes"
                />
              }
            </div>
          </form>
          <ValuePicker
            open={ownerPickerOpen}
            title="Enter the shupper's name"
            isLoading={usersReducerOld.isLoading}
            selected={owner ? [`${owner.displayName}`] : []}
            items={usersReducerOld.shuppersList.map(user => Object.assign({
              id: user._id,
              title: `${user.displayName}, ${user.location}`,
              image: user.profileImageURL,
            }, user))}
            onSearchValue={query => getOnlyShuppers(query)}
            onRequestClose={() => this.setState({ ownerPickerOpen: false })}
            onSelectValue={item => this.ownerSelected(item)}
            hasMore={false}
            onLoadMore={() => { }}
          />
        </div>
      )
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

Collection.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  fetchCollection: PropTypes.func.isRequired,
  updateCollection: PropTypes.func.isRequired,
  collectionReducer: PropTypes.object.isRequired,
  usersReducerOld: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getOnlyShuppers: PropTypes.func.isRequired,
};

const mapStateToProps = ({ collectionReducer, usersReducerOld }) => ({
  collectionReducer, usersReducerOld,
});

const mapDispatchToProps = dispatch => ({
  fetchCollection: id => dispatch(collectionActions.fetchCollection(id)),
  updateCollection: data => dispatch(collectionActions.updateCollection(data)),
  fetchUsers: query => dispatch(usersActions.fetchUsers(query, null, ['shupper'])),
  fetchMoreUsers: () => dispatch(usersActions.fetchMoreUsers()),
  getOnlyShuppers: name => dispatch(usersActions.getOnlyShuppers(name)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Collection));
