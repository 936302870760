import React from 'react';
import { Button, Grid, Typography, Paper } from '@material-ui/core';
import { convertCountryToString, convertZoneToString } from '../../utils/countriesAndZones';

const PaymentRequestShippingAddress = props => {
  const { handleClickEdit, shupperAddress } = props;
  const getCountry = convertCountryToString(shupperAddress?.country_id)
  const getZone = convertZoneToString(shupperAddress?.zone_id)

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant="h5" className="paymentRequest-subtitle">
          SHIPPING ADDRESS
        </Typography>
        <a>
          <Typography className="editLink" onClick={() => handleClickEdit('Hi from Items')}>
            Edit
          </Typography>
        </a>
      </Grid>
      <Paper className="info-wrapper shipping-address-wrapper">
        <Grid container xs={12} justifyContent="space-between">
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shippingAddress-titles">First name</Typography>
            <Typography className="shippingAddress-text">{shupperAddress?.first_name ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shippingAddress-titles">Last name</Typography>
            <Typography className="shippingAddress-text">{shupperAddress?.last_name ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shippingAddress-titles">Country</Typography>
            <Typography className="shippingAddress-text">{getCountry?.[0]?.name ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shippingAddress-titles">State</Typography>
            <Typography className="shippingAddress-text">{(shupperAddress?.length < 2 && getZone?.[0]?.name) ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shippingAddress-titles">City</Typography>
            <Typography className="shippingAddress-text">{shupperAddress?.city ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shippingAddress-titles">ZIP CODE</Typography>
            <Typography className="shippingAddress-text">{shupperAddress?.zip_code ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shippingAddress-titles">Street</Typography>
            <Typography className="shippingAddress-text">{shupperAddress?.address1 ?? '-'}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default PaymentRequestShippingAddress;
