import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TableCell, TableRow, TextField, Typography, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableData from '../table';
import { ModalWindow } from '../modal/modal';
import { ToastNotification } from '../../utils/toastify';
import { ReactComponent as AddIcon } from '../../static/images/addIcon.svg';
import { ReactComponent as CancelIcon } from '../../static/images/deleteIcon.svg';
import { ReactComponent as DeleteIcon } from '../../static/images/trash.svg';
import { ReactComponent as Done } from '../../static/images/done.svg';
import { ReactComponent as EditIcon } from '../../static/images/editIcon.svg';
import { settingsCountriesAndZonesTab } from '../../components/table/tableStyles';
import {
  getCountryZones,
  fetchZone,
  updateCountryZones,
  deleteCountryZones,
  fetchMoreCountriesZones,
  addCountryZones,
  addNewCountryZone,
  removeNewCountryZone,
  editCurrentCountryZone,
  addNewZone,
  removeNewZone,
  editCurrentZone,
  updateZone,
  deleteZone,
  addZonee,
} from '../../actions/settingsActions';
import { zones } from '../../utils/zones'
import '../../static/styles/settingsTabs.css';
import { decodePermissions } from '../../utils/permissionUtil';
import { getPermission } from '../../utils/cookies';
import { Loading } from '../loader';
import { countries as allCountries } from '../../utils/countries';

const useStyles = makeStyles({
  paper: {
    border: 'none',
    backgroundColor: '#F3F3F3',
    maxHeight: '7.5em',
    width: '100%',
  },
  centerTextAlign: {
    textAlign: 'center',
  },
  iconWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
});

const CountriesAndZones = ({ countriesZonesData, zonesData }) => {

  const [isEditCountry, setIsEditCountry] = useState(false)

  const dispatch = useDispatch();
  const classes = useStyles();
  const permission = decodePermissions(getPermission());
  const [isEditExpandRow, setIsEditExpandRow] = useState(false);
  const [currentCountryIndex, setCurrentCountryIndex] = useState(null);
  const [currentExpandRowIndex, setCurrentExpandRowIndex] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [addZone, setAddZone] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteCountry, setIsDeleteCountry] = useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [zoneIndex, setZoneIndex] = React.useState(null)
  const [allowEditNextRow, setAllowEditNextRow] = React.useState(true)
  const [oldData, setOldData] = useState({});
  const [isFieldsChanged, setIsFieldsChanged] = useState(false)

  const countries = allCountries.map(country => {
    return { name: `${country.name}`, id: `${country.id}`, zone: `${country.zone}` };
  });
  const states = zones?.map(zone => {
    return { name: `${zone.name}`, id: `${zone.id}`, }
  })
  const fixOldValueOfCountry = countryIndex => {
    const tempValue = Object.assign({}, countriesZonesData[countryIndex]);
    setOldData(tempValue);
  };
  const fixOldValueOfExpandRow = (indexOfExpandRow, countryIndex) => {
    const tempValue = Object.assign({}, zonesData[indexOfExpandRow]);
    setOldData(tempValue);
  };

  const cancelCountryChanges = countryIndex => {

    dispatch(editCurrentCountryZone(oldData.taxes, countryIndex));
    setIsEditCountry(false);
    setAllowEditNextRow(true)
  };
  const cancelExpandRowChanges = (expandRowIndex, countryIndex) => {
    dispatch(editCurrentZone(oldData.taxes, expandRowIndex));
    setIsEditExpandRow(false);
    setAllowEditNextRow(true)
  };

  useEffect(() => {
    if (permission.users) {
      dispatch(getCountryZones());
      dispatch(fetchZone())
    }
  }, [])

  const deleteCountryRow = (index, rowIndex) => {
    dispatch(deleteCountryZones(index, rowIndex));
    setIsEditCountry(false);
  };

  const deleteExpandRow = (index, zoneIndex, countryIndex) => {
    dispatch(deleteZone(index, zoneIndex, 'zone'));
    setCurrentCountryIndex(countryIndex);
    setAllowEditNextRow(true)
  };

  const checkCountryValid = (value, id) => {
    const index = countries.findIndex(country => country.name === value || country.zone === id);
    const countryZone = countries[index]?.zone || '';
    return { index, countryZone };
  };
  const checkZoneValid = (value, id) => {
    const index = states.findIndex(state => state.name === value || state.id === id);
    const countryZone = states[index]?.id || '';
    return { index, countryZone };
  };
  const saveData = () => {
    const { index, countryZone } = checkCountryValid(countriesZonesData[currentCountryIndex]?.name, countriesZonesData[currentCountryIndex]?.id);
    if ((isEditCountry && !countriesZonesData[currentCountryIndex]?.name) || countriesZonesData[currentCountryIndex]?.name === '') return;
    if (isAdd && index === -1) return;
    const sendObj = {
      countryTax: countriesZonesData[currentCountryIndex],
      zoneId: countryZone
    }
    dispatch(
      isAdd
        ? addCountryZones(sendObj)
        : updateCountryZones(countriesZonesData[currentCountryIndex], rowIndex),
    );
    setIsEditCountry(false);
    setIsEditExpandRow(false);
    setIsAdd(false);
    setAllowEditNextRow(true)
    setIsFieldsChanged(false)
  };

  const saveZone = () => {
    const { index, countryZone } = checkZoneValid(zonesData[currentExpandRowIndex]?.name, zonesData[currentExpandRowIndex]?.id);
    if ((isEditExpandRow && !zonesData[currentExpandRowIndex]?.name) || zonesData[currentExpandRowIndex]?.name === '') return;
    if (index === -1) return;
    const sendObj = {
      countryTax: zonesData[currentExpandRowIndex],
      zoneId: countryZone
    }
    dispatch(
      addZone
        ? addZonee(sendObj, 'zone')
        : updateZone(zonesData[currentExpandRowIndex], zoneIndex, 'zone'),
    );
    setIsEditExpandRow(false);
    setAddZone(false)
    setAllowEditNextRow(true)
    setIsFieldsChanged(false)
  }

  const editCountry = dataIndex => {
    const newData = [...countriesZonesData];
    if (newData[dataIndex]?.taxes?.length < 1) {
      dispatch(editCurrentCountryZone(newData[dataIndex].taxes = [
        { zone_id: currentCountryIndex, tax_value: '', type_id: 1 },
        { zone_id: currentCountryIndex, tax_value: '', type_id: 2 },
      ], dataIndex))
    }

    const taxes = newData[dataIndex]?.taxes?.map(item => item?.type_id || null);
    const taxesWithoutNull = taxes.filter(item => item !== null)

    if (!taxesWithoutNull?.includes(1)) {
      dispatch(editCurrentCountryZone(newData[dataIndex]?.taxes.unshift({ zone_id: newData[currentCountryIndex].id, tax_value: '', type_id: 1 })), dataIndex);
    }
    if (!taxesWithoutNull?.includes(2)) {
      dispatch(editCurrentCountryZone(newData[dataIndex]?.taxes.push({ zone_id: newData[currentCountryIndex].id, tax_value: '', type_id: 2 })), dataIndex);
    }
    setIsAdd(false);
    setCurrentCountryIndex(dataIndex);
    setIsEditCountry(true);
    setAllowEditNextRow(false)
  };

  const editExpandRow = (index, dataIndex) => {
    const newData = [...zonesData];
    if (newData[index]?.taxes?.length < 1) {
      dispatch(editCurrentZone(newData[index].taxes = [
        { zone_id: currentExpandRowIndex, tax_value: '', type_id: 1 },
        { zone_id: currentExpandRowIndex, tax_value: '', type_id: 2 },
      ]), index)

    }
    const taxes = newData[index]?.taxes?.map(item => item?.type_id);
    const taxesWithoutNull = taxes.filter(item => item !== null)
    if (!taxesWithoutNull?.includes(1)) {
      newData[index]?.taxes.unshift({ zone_id: newData[currentExpandRowIndex].id, tax_value: '', type_id: 1 });
    }
    if (!taxesWithoutNull?.includes(2)) {
      newData[index]?.taxes.push({ zone_id: newData[currentExpandRowIndex].id, tax_value: '', type_id: 2 });
    }
    setAddZone(false);
    setCurrentCountryIndex(dataIndex);
    setCurrentExpandRowIndex(index);
    setIsEditExpandRow(true);
    setAllowEditNextRow(false)
  };

  const addCountry = () => {
    if (isEditCountry || isEditExpandRow) return;
    setIsAdd(true);
    setCurrentCountryIndex(0);
    const newCountryRow = {
      id: 0,
      name: '',
      zone: '',
      countryId: 'custom',
      taxes: [{ tax_value: '', type_id: 1 }, { tax_value: '', type_id: 2 }],
    };
    dispatch(addNewCountryZone(newCountryRow))
    setIsEditCountry(true)
  };

  const addZoneRow = (currentCountryIndex) => {
    if (isEditCountry || isEditExpandRow) return;
    setAddZone(true);
    setCurrentCountryIndex(currentCountryIndex);
    setCurrentExpandRowIndex(0);
    const zoneInput = {
      id: 0,
      name: '',
      zone: '',
      zoneName: 'custom',
      taxes: [{ tax_value: '' }, { tax_value: '' }]
    };
    dispatch(addNewZone(zoneInput))
    setIsEditExpandRow(true);
    setAllowEditNextRow(false)
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const newData = [...countriesZonesData];
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (name !== 'name' && !value.match(rgx)) return
    switch (name) {
      case 'name':
        newData[index][name] = value
        break;
      case 'localTax':
        newData[index].taxes[0].tax_value = value
        break;
      case 'importTax':
        newData[index].taxes[1].tax_value = value;
        break;
    }
    if (isEditCountry) {
      dispatch(editCurrentCountryZone(newData[index].taxes, index))
    }
    setIsFieldsChanged(true)
  };

  const handleChangeExpandableRow = (index, evnt) => {
    const { name, value } = evnt.target;
    const newZone = [...zonesData]
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (name !== 'name' && !value.match(rgx)) return
    switch (name) {
      case 'name':
        newZone[index][name] = value
        break;
      case 'zoneLocalTax':
        newZone[index].taxes[0].tax_value = value
        break;
      case 'zoneImportTax':
        newZone[index].taxes[1].tax_value = value
        break;
    }
    if (isEditExpandRow) {
      dispatch(editCurrentZone(newZone[index].taxes, index))
    }
    setIsFieldsChanged(true)
  };

  const setNewCountry = (index, name, id, zone) => {
    const newData = [...countriesZonesData];
    newData[index].name = name;
    dispatch(editCurrentCountryZone(newData[index]))
  };

  const setNewZone = (index, name, id) => {
    const newData = [...zonesData];
    newData[index].name = name;
    newData[index].zone = id;
    dispatch(editCurrentZone(newData[index]))
  };

  const cancelAddCountry = countryIndex => {
    if (isAdd) {
      dispatch(removeNewCountryZone(0))
    }
    if (!isAdd && isEditCountry) {
      cancelCountryChanges(countryIndex)
    }
    setIsEditCountry(false);
    setIsAdd(false)
    setAllowEditNextRow(true)
  };

  const cancelAddExpandRow = (expandRowIndex, countryIndex) => {
    if (addZone) {
      dispatch(removeNewZone(0))
    }
    if (!addZone && isEditExpandRow) {
      cancelExpandRowChanges(expandRowIndex)
    }
    setIsEditCountry(false);
    setAddZone(false)
    setIsEditExpandRow(false)
    setAllowEditNextRow(true)
  };
  const countryAndZonesColumns = [
    {
      name: 'name',
      label: 'Country & Zone',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              {!isEditCountry && data === 'United States of America (the)' && (
                <IconButton
                  onClick={() => addZoneRow(dataIndex.rowIndex)}>
                  <AddIcon />
                </IconButton>
              )}

              {isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
                <Autocomplete
                  id="name"
                  name="name"
                  style={{ width: 300 }}
                  clearOnBlur={true}
                  options={countries}
                  classes={{
                    option: classes.option,
                    paper: classes.paper,
                  }}
                  disabled={!isAdd ? true : false}
                  autoComplete={true}
                  selectOnFocus={true}
                  onFocusCapture={true}
                  autoHighlight
                  getOptionLabel={option => (option.name ? option.name : '')}
                  renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
                  onChange={(event, newValue) => {
                    setNewCountry(dataIndex.rowIndex, newValue?.name, newValue?.id, newValue?.zone);
                  }}
                  value={data}
                  inputValue={data}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth={true}
                      name="name"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'name',
                      }}
                      onChange={event => handleChange(dataIndex.rowIndex, event)}
                      placeholder="Not Defined"
                    />
                  )}
                />
              ) : (
                data
              )}
            </>
          );
        },
      },
    },
    {
      name: 'localTax',
      label: 'Local Tax',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                fullWidth={true}
                name="localTax"

                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChange(dataIndex.rowIndex, evnt)}
              />
            </div>
          ) : (
            data ?? 'Not Defined'
          );
        },
      },
    },
    {
      name: 'importTax',
      label: 'Import Tax',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                fullWidth={true}
                name="importTax"

                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChange(dataIndex.rowIndex, evnt)}
              />
            </div>
          ) : (
            data
          );
        },
      },
    },
    {
      name: 'action',
      label: '',
      options: {
        customHeadRender: first => {
          return (
            <TableCell>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="headerActionCellWrapper">
                <IconButton onClick={addCountry}>
                  <AddIcon />
                </IconButton>
                <Typography>ADD</Typography>
              </Grid>
            </TableCell>
          );
        },
        customBodyRender: (id, dataIndex, rowIndex) => {
          return (
            <div>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center" wrap="nowrap">
                {isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
                  <IconButton onClick={() => {
                    if (isFieldsChanged) {
                      saveData()
                    } else {
                      cancelCountryChanges(dataIndex.rowIndex)
                    }
                  }
                  }>
                    <Done />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      if (!isAdd && allowEditNextRow) {
                        editCountry(dataIndex.rowIndex);
                        setRowIndex(dataIndex.rowIndex)
                        fixOldValueOfCountry(dataIndex.rowIndex);
                      }
                    }}>
                    <EditIcon />
                  </IconButton>
                )}
                {isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
                  <IconButton
                    onClick={() => cancelAddCountry(dataIndex.rowIndex)}>
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      if (!isAdd && !isEditCountry && !isEditExpandRow) {
                        setIsDeleteCountry(true);
                        setCurrentCountryIndex(id);
                        setRowIndex(dataIndex.rowIndex)
                        setIsModalOpen(true);
                      }
                    }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </div>
          );
        },
      },
    },
  ];
  const options = {
    expandableRowsHeader: false,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    search: false,
    download: false,
    print: false,
    filter: false,
    fixedHeader: true,
    tableBodyMaxHeight: '550px',
    expandableRowsOnClick: false,
    //page: isAdd ? 0 : null,
    expandableRows: true,
    onChangeRowsPerPage: (num, sets) => {
      setRowsPerPage(num)
    },
    onTableChange: (action, tableState) => {
      const lastPage = tableState?.rowsPerPage * tableState?.page + tableState?.rowsPerPage === countriesZonesData?.length
      switch (action) {
        case "changePage":
          lastPage && dispatch(fetchMoreCountriesZones());
          break;
        case "changeRowsPerPage":
          lastPage && dispatch(fetchMoreCountriesZones());
          break;
      }
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      if (isEditCountry && rowIndex === currentCountryIndex) {
        return {
          style: { background: '#F3F3F3' },
        };
      }
    },
    //rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 25, 100],
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    rowsExpanded: [currentCountryIndex],
    renderExpandableRow: (rowData, rowMeta) => {
      const defineUsa = rowData[0]?.props?.children?.includes('United States of America (the)')
      return (
        <>
          {defineUsa && zonesData?.map((item, index) => {
            const defineZoneName = zones.filter(stateZone => stateZone.id === item?.id)
            return (<TableRow
              className={classes.customTableStyle}>
              {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
                <TableCell colSpan={2}>
                  <Autocomplete
                    id="name"
                    disabled={!addZone ? true : false}
                    name="name"
                    style={{ width: 300 }}
                    freeSolo
                    options={states}
                    classes={{
                      option: classes.option,
                      paper: classes.paper,
                    }}
                    autoComplete={true}
                    autoHighlight
                    getOptionLabel={option => (option.name ? option.name : '')}
                    renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
                    onChange={(event, newValue) => {
                      setNewZone(index, newValue?.name, newValue?.id);
                    }}
                    value={zonesData[index].zone === 'custom' ? zonesData[index].zoneName : zonesData[index].name}
                    inputValue={zonesData[index].zone === 'custom' ? zonesData[index].zoneName : zonesData[index].name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth={true}
                        name="name"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'name',
                        }}
                        onChange={e => handleChangeExpandableRow(index, e)}
                        placeholder="Not Defined"
                      />
                    )}
                  />
                </TableCell>
              ) : (
                <TableCell colSpan={rowData.length - 2}>{defineZoneName[0]?.['name']}</TableCell>
              )}
              {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
                <TableCell>
                  <TextField
                    fullWidth={true}
                    name="zoneLocalTax"
                    value={zonesData[index].taxes[0]?.tax_value}
                    placeholder="Not Defigned"
                    onChange={e => handleChangeExpandableRow(index, e)}
                  />
                </TableCell>
              ) : (
                <TableCell>{item?.taxes?.map(zone => zone?.type_id === 1 && zone?.tax_value)}</TableCell>
              )}
              {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
                <TableCell>
                  <TextField
                    fullWidth={true}
                    name="zoneImportTax"
                    value={zonesData[index].taxes[1]?.tax_value}
                    placeholder="Not Defigned"
                    onChange={e => handleChangeExpandableRow(index, e)}
                  />
                </TableCell>
              ) : (
                <TableCell>{item?.taxes?.map(zone => zone?.type_id === 2 && zone?.tax_value)}</TableCell>
              )}
              {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
                <TableCell>
                  <div className={classes.centerTextAlign}>
                    {
                      <IconButton onClick={() => {
                        if (isFieldsChanged) {
                          saveZone()
                        } else {
                          cancelExpandRowChanges(index, rowMeta.dataIndex)
                        }
                      }
                      }>
                        <Done />
                      </IconButton>
                    }
                    <IconButton
                      onClick={() => {
                        cancelAddExpandRow(index, rowMeta.dataIndex);
                      }}
                      className={classes.centerTextAlign}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </TableCell>
              ) : (
                <TableCell>
                  <div className={`${classes.iconWrapper}`}>
                    {
                      <IconButton
                        onClick={() => {
                          if (!addZone && allowEditNextRow) {
                            editExpandRow(index, rowMeta.dataIndex);
                            setZoneIndex(index)
                            fixOldValueOfExpandRow(index, rowMeta.dataIndex);
                          }
                        }}>
                        <EditIcon />
                      </IconButton>
                    }
                    <IconButton
                      onClick={() => {
                        if (!addZone && !isEditExpandRow) {
                          setIsDeleteCountry(false);
                          setIsModalOpen(true);
                          setCurrentExpandRowIndex(item?.id)
                          setCurrentCountryIndex(rowMeta.dataIndex)
                          setZoneIndex(index)
                        }
                      }}
                      className={classes.centerTextAlign}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </TableCell>
              )}
            </TableRow>
            )
          }
          )}
        </>
      );
    },
  };

  return (
    <div>
      <TableData
        headerColumn={countryAndZonesColumns}
        width="100%"
        tableName="countryZones"
        tablePagination={true}
        data={countriesZonesData}
        handleData={handleChange}
        optionsExpandableRow={options}
        customTheme={settingsCountriesAndZonesTab}
      />
      <ToastNotification position="top-right" hideBar={true} close={false} theme="colored" width="150px" className="toast-container" />
      <ModalWindow
        open={isModalOpen}
        close={() => setIsModalOpen(false)}
        title="Do you really want to remove it?"
        confirm={
          isDeleteCountry
            ? () => deleteCountryRow(currentCountryIndex, rowIndex)
            : () => deleteExpandRow(currentExpandRowIndex, zoneIndex, currentCountryIndex)
        }
        children="Remove"
      />
    </div>
  );
};
export default CountriesAndZones;
