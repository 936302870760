

import React from 'react';
import Dialog from 'material-ui/Dialog';
import SearchField from '../searchfield';
import FlatButton from 'material-ui/FlatButton';
import AddBrand from './AddBrand'
import ShowBrand from './ShowBrand'



const Tier = ({ dataUser, openAddBrands, openDialogAddBrands, searchBrands, brands, selectedBrands, handleAddBrand, handleDeleteBrands }) => (
    <React.Fragment>
        <Dialog
            title={
                `Add More Brands`
            }
            actions={[<FlatButton label="Done" primary onClick={openDialogAddBrands} />]}

            modal={false}
            open={openAddBrands}
            onRequestClose={() => {
                openDialogAddBrands()
            }}
        >
            <SearchField onRequestSearch={query => searchBrands(query, null, null, null, null)} doneColor={"black"} />
            <div className="add-brands-block">
                {brands && brands.events && brands.events.length > 0 ? <div> {brands.events.map((item, key) => {
                    return <AddBrand handleAddBrand={handleAddBrand} selectedBrands={selectedBrands} brand={item} key={key} />
                })}</div> : ""}</div>
        </Dialog>
        <div className="container">

            <div className="row">
                <div className="col-10 margin-top-20 ">
                    <p>Selected Brands</p>
                    {brands && brands.events && brands.events.length > 0 ?
                        <div className="brands">
                            {selectedBrands.map((item, key) => {
                                return <ShowBrand handleDeleteBrands={handleDeleteBrands} brand={item} key={key} />
                            })}
                        </div>
                        : ""}
                </div>
                <div className="col-2">
                    <FlatButton
                        label="Add Brand"
                        primary
                        className="add-brand-button"
                        onClick={openDialogAddBrands}
                    /></div>
            </div>
        </div>
    </React.Fragment>);

export default (Tier);
