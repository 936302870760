import types from './actionTypes';

export const approveShupper = (id) => {
    return {
        type: types.APPROVE_SHUPPER,
        payload:
            { id }

    }
};
export const approveShupperSuccess = (data) => {
    return {
        type: types.APPROVE_SHUPPER_SUCCESS,
        payload:
            { data }

    }
};
export const declineShupper = (id) => {
    return {
        type: types.DECLINE_SHUPPER,
        payload:
            { id }

    }
};
export const declineShupperSuccess = (id) => {
    return {
        type: types.DECLINE_SHUPPER_SUCCESS,
        payload:
            { id }

    }
};
// export const declineShupperError = (data) => {
//     return {
//         type: types.DECLINE_SHUPPER_ERROR,
//         payload:
//             { data }

//     }
// };
export const approveShupperError = (data) => {
    return {
        type: types.APPROVE_SHUPPER_ERROR,
        payload:
            { data }

    }
};
export const resetApproveShupper = (id) => {
    return {
        type: types.RESET_APPROVE_SHUPPER,


    }
};


export const fetchShuppers = (status) => {
    return {
        type: types.FETCH_SHUPPERS,
        payload:
            { status }

    }
};
export const fetchShuppersSuccess = (data) => ({
    type: types.FETCH_SHUPPERS_SUCCESS,
    payload: {
        data
    }
});




export default dispatch => ({
    approveShupper: (id) => dispatch(approveShupper(id)),
    fetchShuppers: query => dispatch(fetchShuppers(query)),
    declineShupper: (payload) => dispatch(declineShupper(payload)),
    resetApproveShupper: () => dispatch(resetApproveShupper()),
});
