import {all} from 'redux-saga/effects';

import authSaga from '../sagas/authsaga';
import packagesSaga from '../sagas/packagessaga';
import packageDetailsSaga from '../sagas/packagesaga';
import itemsSaga from '../sagas/itemssaga';
import itemSaga from '../sagas/itemsaga';
import itemsV2Saga from '../sagas/itemsv2saga';
import itemV2Saga from '../sagas/itemv2saga';
import usersSaga from '../sagas/userssaga';
import usersSagaOld from '../sagas/userssagaold';
import eventsSaga from '../sagas/eventssaga';
import eventSaga from '../sagas/eventsaga';
import eventsV2Saga from '../sagas/eventsv2saga';
import eventV2Saga from '../sagas/eventv2saga';
import storesSaga from '../sagas/storessaga';
import storeSaga from '../sagas/storesaga';
import boUserSaga from '../sagas/bousersaga';
import collectionsSaga from '../sagas/collectionssaga';
import collectionSaga from '../sagas/collectionsaga';
import boUsersSaga from '../sagas/bouserssaga';
import securityLogsSaga from '../sagas/securitylogssaga';
import couponsSaga from '../sagas/couponssaga';
import couponSaga from '../sagas/couponsaga';
import canceled from '../sagas/logistics/canceledsaga';
import completed from '../sagas/logistics/completedsaga';
import pending from '../sagas/logistics/pendingsaga';
import refundedsaga from '../sagas/logistics/refundedsaga';
import rejected from '../sagas/logistics/rejectedsaga';
import shipping from '../sagas/logistics/shippingsaga';
import singlePayment from '../sagas/logistics/singlePaymentSaga';
import inaccessibilitySaga from '../sagas/inaccessibility';
import chatSaga from '../sagas/chat';
import brandReviewsSaga from '../sagas/brandReview';
import profilesaga from '../sagas/profilesaga';
import checkinssaga from '../sagas/checkinssaga';
import approveShuppersSaga from '../sagas/approveShuppers';
import approveBrandsSaga from '../sagas/approveBrandsSaga';
import shippingPickupSaga from '../sagas/logistics/shippingPickup';
import packageSaga from '../sagas/logistics/packageSaga';
import countriesSaga from '../sagas/countriesListSaga';
import userProfileSaga from './userProfileSaga';
import settingsSaga from './settingsSaga';
import ordersSaga from '../sagas/orderssaga';
import brandsSaga from './brandssaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    packagesSaga(),
    packageDetailsSaga(),
    itemsSaga(),
    itemsV2Saga(),
    itemV2Saga(),
    brandsSaga(),
    ordersSaga(),
    itemSaga(),
    usersSaga(),
    usersSagaOld(),
    eventsSaga(),
    eventSaga(),
    eventsV2Saga(),
    eventV2Saga(),
    storesSaga(),
    storeSaga(),
    boUserSaga(),
    collectionsSaga(),
    collectionSaga(),
    boUsersSaga(),
    securityLogsSaga(),
    couponsSaga(),
    couponSaga(),
    canceled(),
    completed(),
    pending(),
    refundedsaga(),
    rejected(),
    shipping(),
    singlePayment(),
    inaccessibilitySaga(),
    chatSaga(),
    brandReviewsSaga(),
    profilesaga(),
    checkinssaga(),
    approveShuppersSaga(),
    approveBrandsSaga(),
    shippingPickupSaga(),
    packageSaga(),
    countriesSaga(),
    userProfileSaga(),
    settingsSaga(),
  ]);
}
