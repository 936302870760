import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';
import { createSelector } from 'reselect';

const defaultFilters = {
    date: {
        from: null,
        to: null,
    },
    friendlyName: '',
    support: false,
    flaggedOnly: false,
    inMessages: false,
    friendlyNameNew: '',
};

const initialState = {
    selectedUser: null,
    selectedChat: 1,
    chatList: {},
    wsConnection: null,
    connectedChats: { 1: null },
    connectedUnique: { 1: '' },
    chatMessages: { 1: [] },
    newlyCameMessages: { 1: [] },
    filters: { ...defaultFilters },
    lazyLoad: {
        chats: {
            skip: 0,
            full: false,
            limit: 50,
        },
        messages: {
            1: {
                skip: 0,
                full: false,
                skipDown: 0,
                fullDown: true,
            }
        }
    },
    authToken: '',
    previewImages: [],
    supportId: '',
    chatCount: 0,
    totalCount: 0,
    payments: {},
    lastMessageFrom: { 1: null },
    expandedVideo: { poster: null, videoUrl: null },
    foundMessages: { 1: [], },
    activeMessageSearch: -1,
    needsFiller: false,
    shuplistItems: [],

    orientation: 0,
    activeShuppers: [],
    chatUsers: {},
    saveForwardedChatsSuccess: null,
    userRolesForwardedMessages: null,
    stores: [],
};

const chatsReducer = createReducer({
    [types.SELECT_USER]: (state, { payload }) => ({
        ...state,
        selectedUser: payload.userId,
    }),
    [types.SET_CHATS_LIST]: (state, { payload }) => ({
        ...state,
        chatList: { ...payload.chats },
        totalCount: payload.totalCount,
        lazyLoad: {
            ...state.lazyLoad,
            chats: {
                ...state.lazyLoad.chats,
                skip: payload.skip || 0,
                full: payload.full,
            }
        },
        foundMessages: { ...payload.foundMessages }
    }),
    [types.ADD_CHATS_TO_LIST]: (state, { payload }) => ({
        ...state,
        chatList: { ...state.chatList, ...payload.chats },
        foundMessages: { ...state.foundMessages, ...payload.foundMessages }
    }),
    [types.SET_CONNECTION_PROPS]: (state, { payload }) => ({
        ...state,
        wsConnection: payload,
    }),
    [types.SELECT_CHAT]: (state, { payload }) => ({
        ...state,
        selectedChat: payload.chatId,
        connectedChats: { ...state.connectedChats, [payload.chatId]: `${payload.name1} - ${payload.name2}` },
        chatUsers: { ...state.chatUsers, [payload.chatId]: payload.users },
        chatMessages: { ...state.chatMessages, [payload.chatId]: [] },
        connectedUnique: { ...state.connectedUnique, [payload.chatId]: payload.uniqueName },
        lazyLoad: {
            ...state.lazyLoad,
            messages: {
                ...state.lazyLoad.messages,
                [payload.chatId]: {
                    skip: 0,
                    full: false,
                    skipDown: 0,
                    fullDown: true,
                },
            },
        },
        chatCount: !state.connectedChats[payload.chatId] ? state.chatCount + 1 : state.chatCount,
        activeMessageSearch: -1,
    }),
    [types.DISCONNECT_FROM_CHAT]: (state, { payload }) => ({
        ...state,
        connectedChats: { ...state.connectedChats, [payload.chatId]: null },
        chatMessages: { ...state.chatMessages, [payload.chatId]: [] },
        connectedUnique: { ...state.connectedUnique, [payload.chatId]: null },
        chatCount: state.chatCount - 1,
        chatUsers: []

    }),
    [types.SET_CHAT_MESSAGES]: (state, { payload }) => ({
        ...state,
        connectedChats: {
            ...state.connectedChats,
            [payload.chatId]: [...payload.messages],
        }
    }),
    [types.ADD_CHAT_MESSAGES]: (state, { payload }) => ({
        ...state,
        chatMessages: {
            ...state.chatMessages,
            [payload.chatId]: [...state.chatMessages[payload.chatId], ...payload.messages],
        }
    }),
    [types.PREPEND_CHAT_MESSAGES]: (state, { payload }) => ({
        ...state,
        chatMessages: {
            ...state.chatMessages,
            [payload.chatId]: [...payload.messages, ...state.chatMessages[payload.chatId]],
        }
    }),
    [types.CHANGE_SELECTED_CHAT]: (state, { payload }) => ({
        ...state,
        selectedChat: payload.chatId,
        activeMessageSearch: -1,
    }),
    [types.CHANGE_FILTER_PARAMS]: (state, { payload }) => ({
        ...state,
        filters: {
            ...state.filters,
            ...payload,
        }
    }),
    [types.GET_STORES_SUCCESS]: (state, { payload }) => ({
        ...state,
        stores: payload.data,
    }),
    [types.LOAD_CHATS_LIST]: (state) => ({
        ...state,
        filters: {
            ...state.filters,
            friendlyName: state.filters.friendlyNameNew,
        }
    }),
    [types.LOAD_MORE_CHATS]: (state, { payload }) => ({
        ...state,
        lazyLoad: {
            ...state.lazyLoad,
            chats: {
                ...state.lazyLoad.chats,
                ...payload.data,
            },
        }
    }),
    [types.LOAD_MORE_MESSAGES]: (state, { payload }) => ({
        ...state,
        lazyLoad: {
            ...state.lazyLoad,
            messages: {
                ...state.lazyLoad.messages,
                [payload.chatId]: {
                    ...state.lazyLoad.messages[payload.chatId],
                    ...payload.data
                },
            },
        }
    }),
    [types.LOAD_MORE_MESSAGES_DOWN]: (state, { payload }) => ({
        ...state,
        lazyLoad: {
            ...state.lazyLoad,
            messages: {
                ...state.lazyLoad.messages,
                [payload.chatId]: {
                    ...state.lazyLoad.messages[payload.chatId],
                    ...payload.data
                },
            },
        }
    }),
    [types.SET_CHATS_TOKEN]: (state, { payload }) => ({
        ...state,
        authToken: payload.token,
    }),
    [types.SET_PREVIEW_IMAGES]: (state, { payload }) => ({
        ...state,
        previewImages: [...payload.previewImages],
    }),
    [types.SET_PREVIEW_IMAGE_ORIENTATION]: (state, { payload }) => ({
        ...state,
        orientation: payload.orientation,
    }),

    [types.CLEAR_PREVIEW_IMAGE_ORIENTATION]: (state) => ({
        ...state,
        orientation: 0,
    }),
    [types.SET_SHUPPERS_DATA]: (state, { payload }) => ({
        ...state,
        activeShuppers: payload,
    }),
    [types.CLEAR_PREVIEW_IMAGES]: (state) => ({
        ...state,
        previewImages: [],
    }),
    [types.SET_FORWARDED_CHATS_SUCCESS]: (state) => ({
        ...state,
        saveForwardedChatsSuccess: true,
    }),
    [types.RESET_FORWARDED_CHATS]: (state) => ({
        ...state,
        saveForwardedChatsSuccess: null,
    }),
    [types.SET_DEFAULT_FILTERS]: (state) => ({
        ...state,
        filters: { ...defaultFilters },
    }),
    [types.SET_SUPPORT_ID]: (state, { payload }) => ({
        ...state,
        supportId: payload.supportId,
    }),
    [types.SET_PAYMENT_DATA]: (state, { payload }) => ({
        ...state,
        payments: {
            ...state.payments,
            [payload.id]: { ...payload.data }
        }
    }),
    [types.ADD_USER_ROLES]: (state, { payload }) => ({
        ...state,
        lastMessageFrom: { ...state.lastMessageFrom, ...payload.users },
    }),
    [types.ADD_FORWARDED_MESSAGE_USER_ROLES]: (state, { payload }) => ({
        ...state,
        userRolesForwardedMessages: payload.users,
    }),

    [types.REMOVE_FLAG_IN_STATE]: (state, { payload }) => ({
        ...state,
        chatList: {
            ...state.chatList,
            [payload.chatId]: {
                ...state.chatList[payload.chatId],
                lastMessage: {
                    ...state.chatList[payload.chatId].lastMessage,
                    flagged: false,
                },
            }
        }
    }),
    [types.EXPAND_CHAT_VIDEO]: (state, { payload }) => ({
        ...state,
        expandedVideo: {
            ...payload,
        }
    }),
    [types.CLOSE_CHAT_VIDEO]: (state) => ({
        ...state,
        expandedVideo: {
            poster: null,
            videoUrl: null,
        },
    }),
    [types.UPDATE_MESSAGE]: (state, { payload }) => ({
        ...state,
        chatMessages: {
            ...state.chatMessages,
            [payload.chatId]: state.chatMessages[payload.chatId].map((message) => payload.message._id === message._id ? payload.message : message),
        }

    }),
    [types.JUMP_TO_MESSAGE]: (state, { payload }) => ({
        ...state,
        activeMessageSearch: payload.newIndex,
        chatMessages: { ...state.chatMessages, [payload.chatId]: [] },
        lazyLoad: {
            ...state.lazyLoad,
            messages: {
                ...state.lazyLoad.messages,
                [payload.chatId]: {
                    skip: payload.messagesInChat - payload.message.index + 60,
                    full: false,
                    skipDown: payload.messagesInChat - payload.message.index,
                    fullDown: payload.messagesInChat - payload.message.index <= 5,
                },
            },
        },
    }),
    [types.ADD_NEWLY_MESSAGE]: (state, { payload }) => ({
        ...state,
        newlyCameMessages: {
            ...state.newlyCameMessages,
            [payload.chatId]: [
                ...(state.newlyCameMessages[payload.chatId] ? state.newlyCameMessages[payload.chatId] : []),
                ...payload.messages,
            ]
        }
    }),
    [types.REMOVE_NEWLY_MESSAGES]: (state, { payload }) => ({
        ...state,
        newlyCameMessages: {
            ...state.newlyCameMessages,
            [payload.chatId]: null,
        }
    }),
    [types.GET_ALL_SHUPLIST_ITEMS_SUCCESS]: (state, { payload }) => ({
        ...state,
        shuplistItems: payload.data
    }), [types.CLEAR_ALL_SHUPLIST_ITEMS]: (state, { payload }) => ({
        ...state,
        shuplistItems: []
    }),


}, initialState);


// SELECTORS


const chatsStateSelector = _ => _.chatsReducer;
const usersStateSelector = _ => _.usersReducer;

const uniqueNamesSelector = createSelector(
    chatsStateSelector,
    ({ connectedUnique, selectedChat }) => ({
        uniqueNames: connectedUnique,
        selectedUnique: connectedUnique[selectedChat]
    })
);

export const chatListSelector = createSelector(
    chatsStateSelector,
    ({ chatList, lastMessageFrom, lazyLoad, chatCount, totalCount, }) =>
        ({ chatList, lastMessageFrom, shouldChatsLoad: !lazyLoad.chats.full, totalCount })
);

export const messagesListSelector = createSelector(
    chatsStateSelector,
    uniqueNamesSelector,
    ({ chatMessages, lazyLoad, supportId, payments, authToken,
        selectedChat, filters, search, foundMessages, newlyCameMessages, shuplistItems, previewImages }, { selectedUnique }) =>
        ({
            chatMessages: chatMessages[selectedChat],
            shouldLoadUp: !lazyLoad.messages[selectedChat].full,
            shouldLoadDown: !lazyLoad.messages[selectedChat].fullDown,
            skip: lazyLoad.messages[selectedChat].skip,
            skipDown: lazyLoad.messages[selectedChat].skipDown,
            supportId,
            paymentData: payments,
            token: authToken,
            chatId: selectedChat,
            selectedUnique,
            foundMessageList: foundMessages[selectedChat] ?
                foundMessages[selectedChat].reduce((acc, { _id }) => [...acc, _id], []) : [],
            filters,
            newlyCameMessages: newlyCameMessages[selectedChat] || [],
            shuplistItems, previewImages
        })
);

export const chatTabsSelector = createSelector(
    chatsStateSelector,
    uniqueNamesSelector,
    ({ chatCount, connectedChats, selectedChat, chatUsers }, { selectedUnique }) => ({
        chatCount,
        displayNames: connectedChats,
        selectedUnique,
        selectedChat,
        chatUsers,
    })
);

export const filtersSelector = createSelector(
    chatsStateSelector,
    ({ filters }) => ({ filters }),
);

const lazyLoadSelector = createSelector(
    chatsStateSelector,
    ({ lazyLoad }) => ({
        shouldChatsLoad: !lazyLoad.chats.full,
        chatsSkip: lazyLoad.chats.skip,
        chatsLimit: lazyLoad.chats.limit,
    })
);

const previewImagesSelector = createSelector(
    chatsStateSelector,
    ({ previewImages }) => previewImages,
);
const setForwardedChatsSuccessSelector = createSelector(
    chatsStateSelector,
    ({ saveForwardedChatsSuccess }) => saveForwardedChatsSuccess,
);

const previewImageOrientationSelector = createSelector(
    chatsStateSelector,
    ({ orientation }) => orientation,
);
const activeShuppersSelector = createSelector(
    chatsStateSelector,
    ({ activeShuppers }) => activeShuppers,
);
const storesSelector = createSelector(
    chatsStateSelector,
    ({ stores }) => stores,
);
const userRolesSelector = createSelector(
    chatsStateSelector,
    ({ lastMessageFrom }) => lastMessageFrom
);

const expandedVideoSelector = createSelector(
    chatsStateSelector,
    ({ expandedVideo }) => expandedVideo
);
const userRolesForwardedMessagesSelector = createSelector(
    chatsStateSelector,
    ({ userRolesForwardedMessages }) => userRolesForwardedMessages
);

export const switcherSelector = createSelector(
    chatsStateSelector,
    ({ selectedChat, activeMessageSearch, foundMessages, authToken, chatList }) => ({
        selectedChat,
        activeIndex: activeMessageSearch,
        idList: foundMessages[selectedChat] ?
            foundMessages[selectedChat].reduce((acc, { _id }) => [...acc, _id], []) : [],
        messageList: foundMessages[selectedChat] ?
            foundMessages[selectedChat].reduce((acc, val) => ({ ...acc, [val._id]: val }), []) : {},
        messageCount: foundMessages[selectedChat] ? foundMessages[selectedChat].length : 0,
        authToken,
        messagesInChat: chatList[selectedChat] ? chatList[selectedChat].messages : 0,
    })
);
export const getProps = createSelector(
    chatsStateSelector,
    usersStateSelector,
    lazyLoadSelector,
    previewImagesSelector,
    previewImageOrientationSelector,
    activeShuppersSelector,
    userRolesSelector,
    expandedVideoSelector,
    filtersSelector,
    chatTabsSelector,
    setForwardedChatsSuccessSelector,
    userRolesForwardedMessagesSelector,
    storesSelector,
    (
        { selectedUser, wsConnection },
        { users },
        { shouldChatsLoad, chatsSkip, chatsLimit },
        previewImages,
        orientation,
        activeShuppers,

        lastMessageFrom,
        { videoUrl, poster },
        { filters },
        { displayNames, chatUsers, selectedChat },
        saveForwardedChatsSuccess,
        userRolesForwardedMessages, stores
    ) => ({
        selectedUser, socket: wsConnection,
        userList: users, shouldChatsLoad, chatsSkip,
        previewImages, orientation, activeShuppers, lastMessageFrom,
        chatsLimit, videoUrl, poster, filters, displayNames, chatUsers,
        selectedChat, saveForwardedChatsSuccess, userRolesForwardedMessages, stores
    })
);

export default chatsReducer;
