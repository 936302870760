import React from 'react';
import { Button, Grid, Typography, Paper } from '@material-ui/core';
import { userLocalStorage } from '../../config/local_storage';
import { convertCurrencyToString } from '../../utils/countriesAndZones';
import { countries } from '../../utils/countries';

const UserDetailsByRole = props => {
  const { userRole, handleClick, mainBtnText, dataUser, dataShupper, paymentRequest } = props;

  const getUserCountry = countries?.filter(country => country?.id === dataUser?.country_id)
  const getShupperCountry = countries?.filter(country => country?.id === dataShupper?.country_id)
  const userCCY = convertCurrencyToString(dataUser?.currency_id)
  const shupperCCY = convertCurrencyToString(dataShupper?.currency_id)

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant="h5" className="paymentRequest-subtitle">
          {userRole} DETAILS
        </Typography>
        <a>
          <Typography className="editLink" onClick={() => handleClick(dataUser?.id)}>
            Open Profile
          </Typography>
        </a>
      </Grid>
      <Paper className="info-wrapper">
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <img src={userRole === 'USER' ? dataUser?.profileImageURL : dataShupper?.profileImageURL} alt="avat" className="userAvatarImg" />
          </Grid>
          <Grid item xl={10} lg={10} md={10} sm={10} xs={10} justifyContent="space-between">
            <Grid container xs={12} justifyContent="space-between">
              <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className="userData-titles">First name</Typography>
                <Typography className="userData-text">{userRole === 'USER' ? dataUser?.first_name : dataShupper?.first_name}</Typography>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className="userData-titles">Last name</Typography>
                <Typography className="userData-text">{userRole === 'USER' ? dataUser?.last_name : dataShupper?.last_name}</Typography>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className="userData-titles">Country</Typography>
                <Typography className="userData-text">{userRole === 'USER' ? getUserCountry?.[0]?.name : getShupperCountry?.[0]?.name}</Typography>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className="userData-titles">CCY</Typography>
                <Typography className="userData-text">{userRole === 'USER' ? userCCY?.[0]?.code : shupperCCY?.[0]?.code}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button className="requestPayment-main-btn">{mainBtnText}</Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default UserDetailsByRole;
