import {ToastContainer} from 'react-toastify';

export const ToastNotification = ({
  closeOnClick,
  pauseOnFocusLoss,
  pauseOnHover,
  className,
  rtl,
  close,
  theme,
  width,
  position,
  hideBar,
  color,
  background,
}) => {
  return (
    <ToastContainer
      className={className}
      rtl={rtl}
      theme={theme}
      closeButton={close}
      pauseOnHover={false}
      style={{width: width}}
      toastStyle={{background: background, color: color}}
      position={position}
      autoClose={2000}
      hideProgressBar={hideBar}
      closeOnClick
      pauseOnFocusLoss
    />
  );
};
