/*
Roman Safonov
22/03/2018

Redux actions for items list loading
*/
import types from './actionTypes';

export const fetchItems = query => ({
  type: types.FETCH_ITEMS,
  query,
});

export const fetchItemsSortChanged = (column, direction) => ({
  type: types.FETCH_ITEMS_SORT_CHANGED,
  column,
  direction,
});

export const fetchMoreItems = () => ({
  type: types.FETCH_MORE_ITEMS,
});

export const fetchItemsSuccess = data => ({
  type: types.FETCH_ITEMS_SUCCESS,
  data,
});

export const fetchItemsFailed = reason => ({
  type: types.FETCH_ITEMS_FAILED,
  reason,
});

export const deleteItems = ids => ({
  type: types.DELETE_ITEMS,
  ids,
});

export const deleteItemsSuccess = () => ({
  type: types.DELETE_ITEMS_SUCCESS,
});

export const deleteItemsFailed = reason => ({
  type: types.DELETE_ITEMS_FAILED,
  reason,
});
