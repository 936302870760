import types from './actionTypes';

export const fetchEventV2 = data => ({
  type: types.FETCH_EVENT_V2,
  data,
});

export const fetchEventV2Success = data => ({
  type: types.FETCH_EVENT_V2_SUCCESS,
  data,
});

export const fetchEventV2Failed = reason => ({
  type: types.FETCH_EVENT_V2_FAILED,
  reason,
});

export const updateEventV2 = data => ({
  type: types.UPDATE_EVENT_V2,
  data,
});

export const updateEventV2Success = data => ({
  type: types.UPDATE_EVENT_V2_SUCCESS,
  data,
});

export const updateEventV2Failed = reason => ({
  type: types.UPDATE_EVENT_V2_FAILED,
  reason,
});