import types from './actionTypes';

export const fetchEventsV2 = data => ({
  type: types.FETCH_EVENTS_V2,
  data
});

export const fetchMoreEventsV2 = () => ({
  type: types.FETCH_MORE_EVENTS_V2,
});

export const fetchEventsV2Success = data => ({
  type: types.FETCH_EVENTS_V2_SUCCESS,
  data,
});

export const fetchEventsV2Failed = reason => ({
  type: types.FETCH_EVENTS_V2_FAILED,
  reason,
});

