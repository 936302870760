/*
Kateryna Ivanova
20/03/2018

Reducer for events list
*/
import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 100,
  events: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: '',
  sort: {},
  startDate: null,
  endDate: null,
  type: '',
};

export default createReducer({
    [types.FETCH_EVENTS]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      events: [],
      totalCount: 0,
      searchQuery: action.query,
    }),
    [types.DELETE_EVENT_SUCCESS]: (state, action) => ({
    }),
    [types.UPDATE_EVENT_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      events: [],
      totalCount: 0,
    }),
    [types.FETCH_EVENTS_DATERANGE_CHANGED]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      events: [],
      totalCount: 0,
      startDate: action.startDate,
      endDate: action.endDate,
    }),
    [types.FETCH_MORE_EVENTS]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [types.FETCH_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      offset: state.offset + state.limit,
      totalCount: action.data.count,
      events: [ ...state.events, ...action.data.events ],
      hasMore: state.events.length + action.data.events.length < action.data.count,
      isLoading: false,
    }),
    [types.FETCH_EVENTS_FAILED]: (state, action) => ({
      ...state,
      isLoading: false,
      hasMore: false,
    }),
    [types.FETCH_EVENTS_SORT_CHANGED]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      events: [],
      totalCount: 0,
      sort: procesSort(state.sort, action.column, action.direction),
    }),
    [types.FETCH_EVENTS_FILTER_BY_TYPE]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      events: [],
      totalCount: 0,
      type: action.evtype,
      searchQuery: action.query || '',
    }),
    [types.ACTION_DONE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.RESHUFFLE_EVENT]: (state) => ({
      ...state,
      isLoading: true,
    })
}, initialState);
