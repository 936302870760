import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { useDispatch } from 'react-redux';
import i18next from './i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './routes/components/AppRoutes'
import { fetchPendingBrands } from './actions/approveBrandsActions';

const Application = () => {

  const [state, setState] = React.useState({
    open: false

  });
  const dispatch = useDispatch()
  const onOpenDrawer = () => {
    setState({ open: !state.open });
  };
  const closeDrawer = () => {
    //dispatch(fetchPendingBrands('pending'))
    setState({ open: false });
  };
  return (
    <I18nextProvider i18n={i18next}>
      <Router>
        <AppRoutes closeDrawer={closeDrawer} onOpenDrawer={onOpenDrawer} open={state.open} />
      </Router>
    </I18nextProvider>
  )
};

export default Application;
