import { makeStyles, MenuItem, Paper, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ApplyPermissions } from "../../BOUserPermissions";
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { FieldStyled } from '../styleField'

const useStyles = makeStyles((theme) => ({
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    }
  },
}));

export const FullName = ({ fullName, error, helperText, onChange }) => {
  const classes = useStyles()
  return (
    <FieldStyled
      fullWidth='true'
      placeholder="Full Name"
      variant='outlined'
      name='fullName'
      error={error}
      helperText={helperText}
      onChange={onChange}
      value={fullName}
      InputProps={{
        classes: { input: classes.input }
      }}
    />
  )
}
export const Email = ({ error, helperText, email, onChange }) => {
  const classes = useStyles()
  return (
    <FieldStyled
      fullWidth='true'
      variant='outlined'
      placeholder="@"
      name="email"
      error={error}
      helperText={helperText}
      onChange={onChange}
      value={email}
      InputProps={{
        classes: { input: classes.input }
      }}
    />
  )
}

export const Phone = ({ phone, country, errorPhone, onChange }) => {
  return (
    <ReactPhoneInput
      value={phone}
      containerStyle={{ marginTop: '0px', border: '2px solid #1B1B1B', borderRadius: 8 }}
      inputClass={{ marginLeft: '40px', }}
      inputStyle={{ width: '100%', height: '38px' }}
      inputExtraProps={{
        name: "tel",
        required: true,
        autoFocus: true
      }}
      country={country}
      onChange={onChange}
      placeholder=''
    >
      <span key="1" className="error-phone">
        {errorPhone}
      </span>
    </ReactPhoneInput >
  )
}

export const Status = ({ status, onChange }) => {
  return (
    <FieldStyled
      value={status}
      onChange={onChange}
      select
      placeholder='select'
      name='status'
      variant='outlined'
      fullWidth='true'
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: () => null,
          style: { marginTop: '50px' }
        },
      }} >
      {['active', 'pending', 'blocked'].map((item, index) => {
        return <MenuItem key={`${index}-status`} value={item}>{item}</MenuItem>
      })
      }
    </FieldStyled>
  )
}

export const TypeField = ({ value, onChange }) => {
  return (
    <FieldStyled
      value={value}
      onChange={onChange}
      select
      name='type'
      placeholder='Select'
      variant='outlined'
      fullWidth='true'
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: () => null,
          style: { marginTop: '50px' }
        },
      }}
    >
      {
        ['Admin', 'Local user', 'Headquarter user'].map((item, index) => {
          return (
            <MenuItem style={{ backgroundColor: 'none', background: 'none' }} key={`${index}-menuItem`} value={item}>{item}</MenuItem>
          )
        })
      }
    </FieldStyled >
  )
}
export const RecoverEmailInput = () => {
  return (
    <FieldStyled variant='outlined' fullWidth='true' />
  )
}
