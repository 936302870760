/**
 * Created by Berezin Igor
 * Full Stack Developer
 * on 25/06/2018.
 */

import "./style.css";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import IconButton from "material-ui/IconButton";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import NoPermission from "../../../components/nopermission";
import { getPermission } from "../../../utils/cookies";
import { ensureValue } from "../../../utils/utils";
import { decodePermissions } from "../../../utils/permissionUtil";
import { fetchSinglePayment } from "../../../actions/logistics/singlePaymentActions";
import ImageViewer from "../../../components/imageviewer";
import { makeRefund } from "../../../actions/logistics/completedactions";
import SelectDialog from "../../../components/SelectDialog";
import { changePaymentStatus } from "../../../actions/logistics/singlePaymentActions";
import { withRouter } from "../../../utils/withRouter";

const capitalize = str => str && str[0].toUpperCase() + str.slice(1);

const checkPermissionByStatus = (payment, permission) =>
  payment
    ? permission[
    payment.status !== "cancelled"
      ? `see${capitalize(payment.status)}`
      : "seeCanceled"
    ]
    : false;

class SinglePayment extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());

    this.state = {
      permission,
      isOpenImageViewer: false,
      images: [],
      isOpenRefundAcceptModal: false,
      changeStatus: false,
      itemToChangeStatus: null,
      itemToChangeStatusKey: null
    };
  }

  componentDidMount() {
    const { id } = this.props.params;
    this.props.fetchSinglePayment(id);
  }

  openImageViewer = images => {
    this.setState({
      isOpenImageViewer: true,
      images
    });
  };

  closeImageViewer = () => {
    this.setState({
      isOpenImageViewer: false
    });
  };

  openStatusDialog = (itemToChangeStatus, itemToChangeStatusKey = 0) => () => {
    this.setState({
      changeStatus: true,
      itemToChangeStatus,
      itemToChangeStatusKey
    });
  };

  closeStatusDialog = () => {
    this.setState({
      changeStatus: false
    });
  };

  openRefundModal = () => {
    this.setState({
      isOpenRefundAcceptModal: true
    });
  };

  closeRefundModal = () => {
    this.setState({
      isOpenRefundAcceptModal: false
    });
  };

  checkStatus = (neededStatus, status) =>
    !Array.isArray(neededStatus) || neededStatus.indexOf(status) !== -1;

  renderItemRow = (caption, path, obj, neededStatus) => {
    const val = ensureValue(path, obj);
    return val && this.checkStatus(neededStatus, obj.status) ? (
      <div className="single-item-row">
        <span className="caption">{caption}</span>
        <span>{val}</span>
      </div>
    ) : null;
  };

  render() {
    const {
      onOpenDrawer,
      payment,
      isLoading,
      refundPayment,
      changePaymentStatus
    } = this.props;
    const {
      permission,
      isOpenImageViewer,
      isOpenRefundAcceptModal,
      itemToChangeStatus,
      itemToChangeStatusKey,
      changeStatus
    } = this.state;
    const allowed = checkPermissionByStatus(payment, permission);
    const images =
      (payment &&
        ((payment.imageUrls &&
          payment.imageUrls.map(url => ({ src: url || "" }))) ||
          (payment.receipts &&
            payment.receipts.map(receipt => ({
              src: receipt.imageUrl || ""
            }))))) ||
      [];

    if (isLoading) return <span>Loading...</span>;
    if (payment && allowed)
      return (
        <React.Fragment>
          <div className="App-header">
            <IconButton onClick={onOpenDrawer}>
              <NavigationMenu color="white" />
            </IconButton>
          </div>
          <div className="single-item-container">
            <SelectDialog
              items={[
                "cancelled",
                "fulfilled",
                "pending",
                "refunded",
                "rejected",
                "shipping"
              ]}
              type="status"
              handleChange={changePaymentStatus}
              value={payment.status}
              payment={itemToChangeStatus}
              position={itemToChangeStatusKey}
              display={changeStatus}
              close={this.closeStatusDialog}
            />
            <div className="single-item-row title">
              <span className="caption">Record {payment.id}</span>
            </div>
            {[
              this.renderItemRow("Barcode datetime", "created", payment, [
                "shipping"
              ]),
              this.renderItemRow("Date created", "created", payment),
              this.renderItemRow("User", "user.displayName", payment),
              this.renderItemRow("User Email", "user.email", payment),
              this.renderItemRow(
                "Shupper Name",
                "shupper.displayName",
                payment
              ),
              this.renderItemRow(
                "Number of Receipts",
                "receipts.length",
                payment
              ),
              this.renderItemRow("Promo Code", "promoCode.code", payment, [
                "fulfilled"
              ]),
              this.renderItemRow(
                "Promo Code Discount Value",
                "promoCode.discountValue",
                payment,
                ["fulfilled"]
              ),
              this.renderItemRow(
                "Promo Code Discount Type",
                "promoCode.discountType",
                payment,
                ["fulfilled"]
              ),
              this.renderItemRow("Shipping Cost", "deliveryFee", payment),
              this.renderItemRow(
                "Goods Value (Net Transaction)",
                "itemsTotalPrice",
                payment
              ),
              this.renderItemRow("Taxes", "taxes", payment),
              this.renderItemRow("Shupper Fee", "payeeFee", payment),
              this.renderItemRow("Our Fee", "shupperzFee", payment),
              this.renderItemRow(
                "Track Manual Changes (Shupper fee)",
                "defaultShupperzFee",
                payment
              ),
              this.renderItemRow(
                "PayPal Invoice Number",
                "paypalPaymentId",
                payment,
                ["fulfilled", "refunded"]
              ),
              this.renderItemRow(
                "PayPal Transaction ID",
                "paypalTransactionId",
                payment,
                ["fulfilled", "refunded"]
              ),
              this.renderItemRow("Tracking Code", "trackingCode", payment, [
                "fulfilled"
              ]),
              this.renderItemRow("Street", "user.address.street", payment, [
                "fulfilled",
                "shipping"
              ]),
              this.renderItemRow(
                "House Number",
                "user.address.houseNumber",
                payment,
                ["shipping"]
              ),
              this.renderItemRow("City", "user.address.city", payment, [
                "fulfilled"
              ]),
              this.renderItemRow(
                "Country",
                "user.address.countryName",
                payment,
                ["fulfilled"]
              ),
              this.renderItemRow("Zip Code", "user.address.zipCode", payment, [
                "fulfilled",
                "shipping"
              ]),
              this.renderItemRow(
                "Phone Number",
                "user.address.phoneNumber",
                payment,
                ["fulfilled", "shipping"]
              )
            ]}
            <div className="single-item-row">
              <span className="caption">Prices</span>
              <span>
                {payment &&
                  payment.receipts.map(receipt => receipt.price).join(", ")}
              </span>
            </div>

            <div className="single-item-row">
              <span className="caption">Image(s)</span>
              <span>
                <img
                  alt=""
                  className="avatar viewer"
                  src={images[0] ? images[0].src : ""}
                  onClick={() => images.length && this.openImageViewer(images)}
                />
              </span>
            </div>
            {permission.changePaymentStatus ? (
              <FlatButton
                label="Change Status"
                primary
                onClick={this.openStatusDialog(payment)}
              />
            ) : null}
            {payment.canBeRefunded ? (
              <FlatButton
                label="Refund"
                primary
                onClick={this.openRefundModal}
              />
            ) : null}

            <ImageViewer
              isOpen={isOpenImageViewer}
              onClose={this.closeImageViewer}
              images={images}
            />
          </div>
          <Dialog
            title="Refund payment?"
            actions={[
              <FlatButton
                label="Yes"
                primary
                onClick={refundPayment(payment._id)}
              />,
              <FlatButton label="No" primary onClick={this.closeRefundModal} />
            ]}
            modal={false}
            open={isOpenRefundAcceptModal}
            onRequestClose={this.closeRefundModal}
          />
        </React.Fragment>
      );
    else return <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

SinglePayment.propTypes = {
  onOpenDrawer: PropTypes.func,
  payment: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  makeRefund: PropTypes.func
};

const mapStateToProps = state => ({
  payment: state.logistics.singlePayment.item,
  isLoading: state.logistics.singlePayment.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchSinglePayment: id => dispatch(fetchSinglePayment(id)),
  refundPayment: id => () => dispatch(makeRefund(id)),
  changePaymentStatus: ({ prevStatus, payment, status, position }) =>
    dispatch(changePaymentStatus({ prevStatus, payment, status, position }))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SinglePayment));
