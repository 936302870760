/*
Kateryna Ivanova
20/03/2018

Configure Redux store
*/

import createSagaMiddleware from 'redux-saga';
import {createStore, applyMiddleware, compose} from 'redux';

import reducers from '../reducers';
import sagas from '../sagas';

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  /*   if (process.env.NODE_ENV === 'development') {
    const {logger} = require('redux-logger');
    middlewares.push(logger);
  } */
  const composeEnchantres = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers, composeEnchantres(applyMiddleware(...middlewares)));
  sagaMiddleware.run(sagas);
  return store;
}

export default configureStore();
