/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../actionTypes';

export const fetchPending = query => ({
  type: types.LOGISTICS.FETCH_PENDING,
  query,
});

export const fetchMorePending = () => ({
  type: types.LOGISTICS.FETCH_MORE_PENDING,
});

export const fetchPendingSuccess = data => ({
  type: types.LOGISTICS.FETCH_PENDING_SUCCESS,
  data,
});

export const fetchPendingFailed = reason => ({
  type: types.LOGISTICS.FETCH_PENDING_FAILED,
  reason,
});

export const makeReject = ids => ({
  type: types.LOGISTICS.REJECT_PENDING,
  ids,
});

export const makeRejectSuccess = () => ({
  type: types.LOGISTICS.REJECT_PENDING_SUCCESS,
});

export const makeRejectFailed = reason => ({
  type: types.LOGISTICS.REJECT_PENDING_FAILED,
  reason,
});

export const fetchPendingSortChanged = (column, direction) => ({
  type: types.LOGISTICS.FETCH_PENDING_SORT_CHANGED,
  column,
  direction,
});

export const fetchPendingDateRangeChanges = (startDate, endDate) => ({
  type: types.LOGISTICS.FETCH_PENDING_DATERANGE_CHANGED,
  startDate,
  endDate,
});