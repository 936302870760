import { CircularProgress, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    left: '50%',
    top: '50%'
  }
}));
export const Loading = () => {
  const classes = useStyles()
  return <CircularProgress color="inherit" className={classes.loading
  } />
}