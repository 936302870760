
import types from '../../actions/actionTypes';
import { createReducer } from '../helpers/redux-helpers';

const initialState = {
    savingShippingPickup : false,
    shippingPickups : [],
    isLoaded: false,
    addedPickup: [],
    limit: 200,
    offset:0,
    isLoading: false,
    hasMore: false,
    error: false,
    logistics: []
};

export default createReducer({
    [types.LOGISTICS.ADD_SHIPPING_PICKUP]: (state, action) => ({
        ...state,
        addedPickup: action.data,
        savingShippingPickup: true
    }),
    [types.LOGISTICS.ADD_SHIPPING_PICKUP_SUCCESS]: (state, action) => ({
        ...state,
        savingShippingPickup: false
    }),
    [types.LOGISTICS.ADD_SHIPPING_PICKUP_FAILURE]: (state, action) => ({
        ...state,
        savingShippingPickup: false,
        error: true
    }),
    [types.LOGISTICS.FETCH_SHIPPING_PICKUP_SUCCESS]: (state, action) => ({
    ...state,
    offset: state.offset + state.limit,
    totalCount: action.data.count,
    logistics: [...state.logistics, ...action.data.logistics],
    hasMore: state.logistics.length + action.data.logistics.length < action.data.count,
    isLoading: false,
    }),
    [types.LOGISTICS.FETCH_SHIPPING_PICKUP_FAILURE]: (state, action) => ({
            ...state
    }),
    [types.LOGISTICS.FETCH_SHIPPING_PICKUP]: (state, action) => ({
            ...state,
            isLoading: true
    }),
    [types.LOGISTICS.FETCH_MORE_SHIPPING_PICKUP] : (state, action) => ({
        ...state,
        
    }),
    [types.LOGISTICS.CLEAR_SHIPPING_PICKUP_DATA] : (state, action) => ({
        ...initialState
    })
    },
    initialState);

