import {takeLatest, put, call, all, select} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {fetchCouponsFailed, fetchCouponsSuccess} from '../actions/couponsactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import {fetchCountrySuccess, fetchCountryFailed, fetchZonesFailed, fetchZonesSuccess} from '../actions/countryActions';
import {userLocalStorage} from '../config/local_storage';

const baseUrl = getBaseUrl();

const selectCountriesState = state => state.countryReducer;

function* fetchCountriesAsync(action) {
  const response = yield call(request, `${baseUrl}/api/proxy/v2/dictionaries/countries`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(fetchCountryFailed(response.err.toString()));
  } else {
    yield put(fetchCountrySuccess(response?.data?.response?.countries));
    userLocalStorage.set('countries', response?.data?.response?.countries);
  }
}
function* fetchZonesAsync(action) {
  const response = yield call(request, `${baseUrl}/api/proxy/v2/dictionaries/zones`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(fetchZonesFailed(response.err.toString()));
  } else {
    yield put(fetchZonesSuccess(response?.data?.response?.zones));
    userLocalStorage.set('zones', response?.data?.response?.zones);
  }
}

function* fetchCountries() {
  yield takeLatest([`${types.FETCH_COUNTRIES}`], fetchCountriesAsync);
}
function* fetchZones() {
  yield takeLatest([`${types.FETCH_ZONES}`], fetchZonesAsync);
}

export default function* countriesSaga() {
  yield all([fetchCountries(), fetchZones()]);
}
