import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "material-ui/IconButton";
import { Button, Grid, Typography, Paper, makeStyles, withStyles } from "@material-ui/core";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import PropTypes from "prop-types";
import { RecentPaymentAttempts } from '../../components/profile/RecentPaymentsAttempts';
import { getPermission } from "../../utils/cookies";
import { decodePermissions } from "../../utils/permissionUtil";
import { withRouter } from "../../utils/withRouter";
import { fetchBOUser } from "../../actions/bouseractions";
import { getUserData } from "../../actions/profileActions";
import PersonalInfo from "../../components/profile/PersonalInfo";
import { Shipping } from "./shipping";
import { RecentSession } from "../../components/profile/recentSession";
import { RecentOrders } from '../../components/profile/RecentOrders';
import { RecentPackages } from '../../components/profile/RecentPackages';
import "../../static/styles/profile.css";
import { getUserProfileData } from "../../actions/userProfileActions";
import ShippingAddress from "../../components/shipping/ShippingAddress";

const useStyles = makeStyles({
  disableButton: {
    color: 'red',
    border: '2px solid #E60C31',
    borderRadius: 8,
    textTransform: 'none',
    width: 103,
    height: 40
  }
});

const Profile = (props) => {
  const id = props.params.id
  const classes = useStyles();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const permission = decodePermissions(getPermission());
  const { userProfile } = useSelector(({ userProfileReducer }) => userProfileReducer)

  React.useEffect(() => {
    dispatch(getUserProfileData(id))
  }, [id])
  return (
    <div>
      <div className="App-header">
        <IconButton onClick={props?.onOpenDrawer}>
          <NavigationMenu color="white" />
        </IconButton>
      </div>
      <div className="userProfile-wrapper" >
        <div className="userProfile-title-wrapper">
          <div>
            <Typography variant="h4">USER PROFILE - {userProfile?.user?.first_name}&nbsp;{userProfile?.user?.last_name}&nbsp;{userProfile?.user?.id}</Typography>
          </div>
          <div>
            <Button className={classes.disableButton}>Disable</Button>
          </div>
        </div>
        <Grid container wrap='wrap' justifyContent='space-between' spacing={2}>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
            <PersonalInfo
              userInfo={userProfile?.user}
              permission={permission}
              id={id}
            />
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
            <ShippingAddress address={userProfile?.address} />
          </Grid>

          {/* <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
            <Grid container item xs={12} spacing={2} >
              <Grid item xs={4} >
                <Typography variant="h5" className="profileIndicators-title">BALANCE</Typography>
                <Paper className="profileIndicators">99.99</Paper>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5" className="profileIndicators-title">ORDERS</Typography>
                <Paper className="profileIndicators">3</Paper>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: '0px' }}>
                <Typography variant="h5" className="profileIndicators-title">PACKAGES</Typography>
                <Paper className="profileIndicators">1</Paper>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginTop: '-100px' }}>
              <RecentSession />
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Shipping shippingInfo={userProfile?.address} id={id} />
          </Grid> */}
        </Grid>
        {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <RecentOrders />
        </Grid>
        <Grid container xs={12} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <RecentPackages />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <RecentPaymentAttempts />
          </Grid>
        </Grid> */}
      </div>
    </div >
  );
}

Profile.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
};

export default withRouter(Profile);

