import types from './actionTypes';

export const fetchMoreOrders = () => ({
  type: types.FETCH_MORE_ORDERS,
});
export const fetchOrders = data => ({
  type: types.FETCH_ORDERS,
  data,
});
export const fetchOrdersSuccess = data => ({
  type: types.FETCH_ORDERS_SUCCESS,
  data,
});
export const fetchOrdersFailed = reason => ({
  type: types.FETCH_ORDERS_FAILED,
  reason,
});

export const fetchPayment = query => ({
  type: types.FETCH_PAYMENT,
  query,
});
export const fetchPaymentSuccess = data => ({
  type: types.FETCH_PAYMENT_SUCCESS,
  data,
});
export const fetchPaymentFailed = reason => ({
  type: types.FETCH_PAYMENT_FAILED,
  reason,
});
