import React from 'react';
import { connect } from 'react-redux';
import { RaisedButton, Checkbox } from 'material-ui';
import moment from 'moment';
import { filtersSelector } from '../../reducers/chatsReducer';
import { filtersActions } from '../../actions/chatsActions';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import SearchField from '../searchfield';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const validateSearchField = text => text.length < 3;

const Filters = ({filters, changeFilterParams, setNameFilter, loadChatsList, resetTheFilters}) => (
	<div className="filters flxr jfs ac">
		<span>{ filters.inMessages ? 'Text:' : 'User Name:'}</span>
		<div className="header-search-area flxr ac jc">
			<SearchField
				onChange={setNameFilter}
				value={filters.friendlyNameNew}
				onRequestSearch={
					filters.inMessages && validateSearchField(filters.friendlyNameNew) ?
						() => {} :
						loadChatsList(filters.friendlyNameNew, filters.date, filters.support, 0, false, true, 50, 0, filters.inMessages)
				}
				notification={
					filters.inMessages && validateSearchField(filters.friendlyName)  ?
					'At least 3 symbols required' : null
				}
			/>
			<Checkbox
				onCheck={ev => (changeFilterParams({
					inMessages: ev.target.checked,
				}))}
				style={{width: '10px', marginLeft: '20px'}}
				checked={filters.inMessages}
			/>
			Search in messages
		</div>
      <DateRangePicker
        startDate={filters.date.from ? moment(filters.date.from).format('l') : moment()}
        endDate={filters.date.to ? moment(filters.date.to).format('l') : moment()}
        onApply={(_, {startDate, endDate}) => {
          const from = moment(startDate.toDate());
          const to = moment(endDate.toDate());
          changeFilterParams({
            date: {
              from: from.format('l'),
              to: to.format('l'),
            }
          })
        }}
        ranges={{
          'All Times': [moment('01/01/2017', 'DD/MM/YYYY'), moment()],
          'Last 24 hours': [moment().subtract(1, 'days'), moment()],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'Month to date': [moment().startOf('month'), moment()],
        }}
      >
        <RaisedButton
          label={filters.date.from ? `${filters.date.from} - ${filters.date.to}` : 'Choose date range'}
          primary
          className="datepicker-btn"
        />
      </DateRangePicker>

      <Checkbox
        onCheck={ev => (changeFilterParams({
          support: ev.target.checked,          
        }))}
        style={{width: '10px', marginLeft: '20px'}}
        checked={filters.support}
      />
      Only Support

      <Checkbox
        onCheck={ev => (changeFilterParams({
          flaggedOnly: ev.target.checked,          
        }))}
        style={{width: '10px', marginLeft: '20px'}}
        checked={filters.flaggedOnly}
      />
      Only Flagged 

      <RaisedButton
        label="Reset"
        onClick={resetTheFilters}
        style={{marginLeft: '20px'}}
      />
      <RaisedButton
        label="Search"
        primary
        onClick={loadChatsList(filters.friendlyNameNew, filters.date, filters.support, 0, false, true, 50, 0, filters.inMessages)}
        style={{marginLeft: '20px'}}
      />
    </div>
  );

  export default connect(filtersSelector, filtersActions)(Filters);