/*
Kateryna Ivanova
20/03/2018

Component for a value picker from list with searchfield
*/
import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import NavigationMoreHoriz from 'material-ui/svg-icons/navigation/more-horiz';
import { List, ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';
import Subheader from 'material-ui/Subheader';
import IconButton from 'material-ui/IconButton';
import LinearProgress from 'material-ui/LinearProgress';
import './valuepicker.css';
import SearchField from '../../components/searchfield';

const ValuePicker = ({
  onRequestClose,
  onSelectValue,
  open,
  onSearchValue,
  title,
  items,
  isLoading,
  selected,
  hasMore,
  onLoadMore,
}) => (
  <Dialog
    bodyStyle={{ padding: '0 24px' }}
    title={title}
    actions={[<FlatButton
      label="Done"
      primary
      onClick={() => onRequestClose()}
    />]}
    modal={false}
    open={open}
    onRequestClose={() => onRequestClose()}
  >
    <div className="value-picker-container">
      <SearchField onRequestSearch={query => onSearchValue(query)} doneColor="#000" />
      { selected &&
        <div className="seleted-shuppers">
          { selected.join(', ') }
        </div>
      }
      { isLoading &&
        <div className="progress">
          <LinearProgress />
        </div>
      }
      <List style={{ height: '300px', overflow: 'scroll' }}>
        <Subheader>
          Results
        </Subheader>
        { items && items.length > 0 &&
          items.map(item => (
            <ListItem
              onClick={() => onSelectValue(item)}
              key={item.id}
              primaryText={item.title}
              leftAvatar={<Avatar src={item.image} />}
            />
          ))
        }
      </List>
      { hasMore &&
      <div className="value-picker-more">
        <IconButton
          iconStyle={{ width: '20px', height: '20px' }}
          onClick={onLoadMore}
        >
          <NavigationMoreHoriz color="#000" />
        </IconButton>
      </div> }
    </div>
  </Dialog>
);

ValuePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSearchValue: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSelectValue: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

export default ValuePicker;
