import { takeLatest, put, call, all, select, takeEvery } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchEventsSuccess, fetchEventsFailed, actionDone } from '../actions/eventsactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import { fetchEventsV2Failed, fetchEventsV2Success } from '../actions/eventsV2Actions';

const baseUrl = getBaseUrl();

const selectEventsV2State = state => state.eventsV2Reducer;

function* fetchEventsV2Async() {
  const eventsV2State = yield select(selectEventsV2State);
  console.log('eventsV2State', eventsV2State)

  const { limit, offset } = eventsV2State

  const searchQuery = eventsV2State.searchQuery ? `&q=${eventsV2State.searchQuery}` : '';

  const countryId = eventsV2State?.countryFilter ? `&countryId=${eventsV2State?.countryFilter}` : '';
  const brandFilter = eventsV2State?.brandFilter ? `&brandIds=${encodeURIComponent(JSON.stringify(eventsV2State?.brandFilter))}` : '';
  
  
  const createDateFilter = !eventsV2State?.createDateFilter?.from
  ? ''
  : `&createFromDate=${eventsV2State?.createDateFilter?.from}&createToDate=${eventsV2State?.createDateFilter?.to}`;
  
  const startDateFilter = !eventsV2State?.startDateFilter?.from
  ? ''
  : `&startFromDate=${eventsV2State?.startDateFilter?.from}&startToDate=${eventsV2State?.startDateFilter?.to}`;
  
  const filters = `${searchQuery}${createDateFilter}${startDateFilter}${countryId}${brandFilter}`;
  

  const response = yield call(
    request,
    `${baseUrl}/api/v2/event?limit=${limit}&skip=${offset}${filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchEventsV2Failed(response.err.toString()));
  } else {
    yield put(fetchEventsV2Success(response.data));
  }
}

function* fetchEventsV2() {
  yield takeLatest([types.FETCH_EVENTS_V2], fetchEventsV2Async);
}

export default function* eventsSaga() {
  yield all([
    fetchEventsV2()
  ]);
}
