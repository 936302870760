/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../actionTypes';

export const fetchRejected = query => ({
  type: types.LOGISTICS.FETCH_REJECTED,
  query,
});

export const fetchMoreRejected = () => ({
  type: types.LOGISTICS.FETCH_MORE_REJECTED,
});

export const fetchRejectedSuccess = data => ({
  type: types.LOGISTICS.FETCH_REJECTED_SUCCESS,
  data,
});

export const fetchRejectedFailed = reason => ({
  type: types.LOGISTICS.FETCH_REJECTED_FAILED,
  reason,
});

export const fetchRejectedSortChanged = (column, direction) => ({
  type: types.LOGISTICS.FETCH_REJECTED_SORT_CHANGED,
  column,
  direction,
});

export const fetchRejectedDateRangeChanges = (startDate, endDate) => ({
  type: types.LOGISTICS.FETCH_REJECTED_DATERANGE_CHANGED,
  startDate,
  endDate,
});