/*
Kateryna Ivanova
20/03/2018

Component that allows displaying data in grid, select rows, show loading progress
*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import FlatButton from "material-ui/FlatButton";
import ContentBlock from "material-ui/svg-icons/content/block";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";
import LinearProgress from "material-ui/LinearProgress";
import "./paginatedgrid.css";
import { RaisedButton } from "material-ui";
import SelectComponent from "../SelectComponent";
import ShippingPickUpModal from "../shippingPickup";
import moment from "moment";

class PaginatedGrid extends Component {
  state = {
    isOpen: false,
    shippingPickupDate: null,
    shippingPickupStart: null
  };

  isSelected(id) {
    // return this.props.selection.indexOf(id) !== -1
    return !!this.props.selection.find(item => item._id === id);
  }

  handleRowSelection(selectedRows) {
    if (this.props.onSelectRow) this.props.onSelectRow(selectedRows);
  }

  renderRow(item, i, dataItem) {
    const { columnWidths } = this.props;
    return (
      <TableRow key={`r${i}`} selected={this.isSelected(dataItem._id)}>
        {item.map((rowItem, index) => (
          <TableRowColumn
            style={{ width: columnWidths[index] }}
            key={rowItem._id + index}
          >
            {rowItem}
          </TableRowColumn>
        ))}
      </TableRow>
    );
  }

  renderHeader() {
    const {
      header,
      sortableCols = [],
      currentSort = {},
      onSort,
      columnWidths
    } = this.props;
    return (
      <TableRow>
        {header.map((headerCol, i) => (
          <TableHeaderColumn
            style={{ width: columnWidths[i] }}
            key={headerCol.id}
          >
            {headerCol.select ? (
              <div className="header-item-container">
                <SelectComponent
                  shippers={this.props.shippers}
                  selectedShipper={this.props.selectedShipper}
                  selectShipper={this.props.selectShipper}
                  width={columnWidths[i]}
                />
              </div>
            ) : (
              <div className="header-item-container">
                {headerCol.title}
                {sortableCols.indexOf(headerCol.id) !== -1 && (
                  <div className="sort-arrows">
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => onSort(headerCol.id, 1)}
                      onKeyPress={() => onSort(headerCol.id, 1)}
                      style={{
                        color:
                          currentSort[headerCol.id] === 1 ? "#000" : "#cdcdcd"
                      }}
                    >
                      ▲
                    </span>
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => onSort(headerCol.id, -1)}
                      onKeyPress={() => onSort(headerCol.id, -1)}
                      style={{
                        color:
                          currentSort[headerCol.id] === -1 ? "#000" : "#cdcdcd"
                      }}
                    >
                      ▼
                    </span>
                  </div>
                )}
              </div>
            )}
          </TableHeaderColumn>
        ))}
      </TableRow>
    );
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  addShippingPickup = () => {
    if (this.props.savingShippingPickup) return;
    let data = {
      dateFrom: moment(this.state.shippingPickupDate)
        .utc()
        .set({
          hour: moment(this.state.shippingPickupStart).get("hour"),
          minute: moment(this.state.shippingPickupStart).get("minute"),
          second: moment(this.state.shippingPickupStart).get("second")
        })
        .format()
    };
    data.dateTo = moment(data.dateFrom)
      .utc()
      .add(2, "hours")
      .format();
    let arr = [];
    this.props.selection.forEach(pay => {
      arr.push({
        payment: pay._id,
        shupper: pay.shupper._id,
        ...data
      });
    });
    this.props.saveShippingPickup(arr);
  };

  changeDate = date => {
    this.setState({
      shippingPickupDate: moment(date)
    });
  };

  changeTime = (type, value) => {
    this.setState({
      [type]: value
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (!nextProps.savingShippingPickup && this.props.savingShippingPickup) {
      this.toggleModal();
    }
  }

  render() {
    const {
      hasMore,
      isLoading,
      items,
      tableHeight,
      onLoadMore,
      totalResults,
      columnWidths,
      data,
      displayRowCheckbox,
      savingShippingPickup
    } = this.props;
    const tableWidth = columnWidths.reduce((sum, value) => sum + value) + 92;
    const {
      isOpen,
      shippingPickupDate,
      shippingPickupStart
    } = this.state;
    return (
      <div className="paginated-grid-cont">
        <div className="progressbar-cont">
          {isLoading && <LinearProgress mode="indeterminate" />}
        </div>
        <div className="results-count multi">
          <RaisedButton
            disabled={!this.props.selection.length}
            label={"Setup Pickup"}
            primary
            onClick={this.toggleModal}
          />
          <span>
            Results: {!isLoading && totalResults ? totalResults : "..."}{" "}
          </span>
        </div>
        <ShippingPickUpModal
          isOpen={isOpen}
          onRequestClose={this.toggleModal}
          onRequestSubmit={this.addShippingPickup}
          shippingPickupDate={shippingPickupDate}
          shippingPickupStart={shippingPickupStart}
          changeDate={this.changeDate}
          changeTime={this.changeTime}
          savingShippingPickup={savingShippingPickup}
        />
        <Table
          bodyStyle={{
            minWidth: `${tableWidth}px`
          }}
          multiSelectable={true}
          onRowSelection={d => this.handleRowSelection(d)}
          height={`${tableHeight}px`}
          fixedHeader
          className="users-table"
        >
          <TableHeader
            displaySelectAll={false}
            className={"paginated-grid-multi"}
          >
            {this.renderHeader()}
          </TableHeader>
          <TableBody
            className={"paginated-grid-body-multi"}
            displayRowCheckbox={displayRowCheckbox}
            deselectOnClickaway={false}
          >
            {items.map((item, i) => this.renderRow(item, i, data[i]))}
          </TableBody>
        </Table>
        {!isLoading &&
          hasMore && (
            <div className="show-more-container">
              <FlatButton
                onClick={() => onLoadMore()}
                label="Show more"
                secondary
              />
            </div>
          )}
        {!isLoading && totalResults === 0 ? (
          <div className="paginated-gris-no-results">
            <ContentBlock
              style={{ width: "100px", height: "100px" }}
              color="#d8d8d8"
            />
            <div>No Results</div>
          </div>
        ) : null}
      </div>
    );
  }
}

PaginatedGrid.propTypes = {
  tableHeight: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  displayRowCheckbox: PropTypes.bool,
  displaySelectAll: PropTypes.bool,
  header: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  sortableCols: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentSort: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.object),
  columnWidths: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.object.isRequired
};

PaginatedGrid.defaultProps = {
  selection: [],
  displayRowCheckbox: true,
  displaySelectAll: true
};

export default PaginatedGrid;
