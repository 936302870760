

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import { connect } from 'react-redux';
import moment from 'moment';

import MenuItem from 'material-ui/MenuItem';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { RaisedButton } from 'material-ui';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import withConfirm from '../utils/withConfirm';
import SearchField from '../components/searchfield';
import PaginatedGrid from '../components/paginatedgrid';
import * as storesActions from '../actions/storesactions';
import * as storeAction from '../actions/storeactions';

import '../static/styles/storelist.css';
import '../commoncss/daterangepicker.css';
import { withRouter } from '../utils/withRouter';
import { tableData } from '../utils/tableData';
class StoreList extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      selectedStores: [],
    };
  }

  componentDidMount() {
    if (this.state.permission.stores) {
      this.props.fetchStores();
    }
  }

  onDateRangeSet(startDate, endDate) {
    this.props.fetchStoresDateRangeChanges(startDate, endDate);
  }

  doSearch(query) {
    this.props.fetchStores(query);
  }

  processAction(selectedAction) {
    const { selectedStores } = this.state;
    const { deleteStore } = this.props;
    switch (selectedAction) {
      case 'delete':
        if (selectedStores && selectedStores.length > 0) {
          const ids = selectedStores.map(i => i._id);
          deleteStore(ids);
        }
        break;
      case 'edit':
        if (selectedStores && selectedStores.length > 0) {
          const store = selectedStores[0];
          this.props.navigate(`/store?id=${store._id}`);
        }
        break;
      case 'create':
        this.props.navigate('/store');
        break;
      default:
        break;
    }
  }

  handleSelection = (selectedItems) => {
    const { storesReducer: { stores } } = this.props;
    const result = [];
    selectedItems.forEach((selectedItem) => {
      result.push(stores[selectedItem]);
    });
    this.setState({ selectedStores: result });
  };

  render() {
    const { storeList: { columnWidths, headerData } } = tableData

    const {
      storesReducer, fetchMoreStores, fetchStoresSortChanged, onOpenDrawer,
    } = this.props;
    const { selectedStores, permission } = this.state;
    const tableHeight = window.innerHeight - 177;
    const actionsEnabled = selectedStores && selectedStores.length > 0;

    return permission.stores ?
      <div className="stores-container">
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <div className="header-search-area">
            <SearchField onRequestSearch={query => this.doSearch(query)} />
          </div>
          <div className="stores-date-range-picker-cont">
            <DateRangePicker
              drops="down"
              opens="center"
              startDate={storesReducer.startDate
                ? moment(storesReducer.startDate)
                : moment('01/01/2017', 'DD/MM/YYYY')
              }
              endDate={storesReducer.endDate ? moment(storesReducer.endDate) : moment()}
              timePicker
              timePicker24Hour
              showDropdowns
              onApply={(ev, picker) => {
                this.onDateRangeSet(
                  picker.startDate.toDate().toJSON(),
                  picker.endDate.toDate().toJSON(),
                );
              }}
              ranges={{
                'All Times': [moment('01/01/2017', 'DD/MM/YYYY'), moment()],
                'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Month to date': [moment().startOf('month'), moment()],
              }}
            >
              <RaisedButton
                label={storesReducer.startDate && storesReducer.endDate
                  ? `${moment(storesReducer.startDate).format('lll')}
									- ${moment(storesReducer.endDate).format('lll')}`
                  : 'Pick date range'}
                primary
              />
            </DateRangePicker>
          </div>
          <span className="header-placeholder" />
          <IconMenu
            iconButtonElement={<IconButton><MoreVertIcon color="#fff" /></IconButton>}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <MenuItem
              primaryText="Edit"
              disabled={!actionsEnabled}
              onClick={() => this.processAction('edit')}
            />
            <MenuItem
              primaryText="Delete"
              disabled={!actionsEnabled}
              onClick={() => withConfirm(() => this.processAction('delete'))}
            />
            <MenuItem
              primaryText="Create a new store"
              onClick={() => this.processAction('create')}
            />
          </IconMenu>
        </div>

        <PaginatedGrid
          data={storesReducer.stores}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          hasMore={storesReducer.hasMore}
          isLoading={storesReducer.isLoading}
          header={headerData}
          totalResults={storesReducer.totalCount}
          onSelectRow={this.handleSelection}
          selection={selectedStores}
          sortableCols={[headerData[2].id, headerData[3].id, headerData[4].id]}
          currentSort={storesReducer.sort}
          onSort={(column, direction) => fetchStoresSortChanged(column, direction)}
          items={storesReducer.stores.map(store => [
            <img alt="" className="avatar" src={store.coverImageURL} />,
            <img alt="" className="avatar" src={store.profileImageURL} />,
            <span><a href={`/store?id=${store._id}`}>{store.displayName}</a></span>,
            <span>{store.shupperDescription}</span>,
            <span>{moment(store.created).format('lll')}</span>,
            <span>{moment(store.created).format('lll')}</span>,
            <span>
              {store.position && store.position.coordinates
                ? store.position.coordinates.join(', ')
                : ''
              }
            </span>,
          ])}
          onLoadMore={() => fetchMoreStores()}
        />
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

StoreList.propTypes = {
  storesReducer: PropTypes.object.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  fetchStores: PropTypes.func.isRequired,
  fetchMoreStores: PropTypes.func.isRequired,
  fetchStoresSortChanged: PropTypes.func.isRequired,
  fetchStoresDateRangeChanges: PropTypes.func.isRequired,
  deleteStore: PropTypes.func.isRequired,
};

const mapStateToProps = ({ storesReducer }) => ({
  storesReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchStores: query => dispatch(storesActions.fetchStores(query)),
  fetchMoreStores: () => dispatch(storesActions.fetchMoreStores()),
  fetchStoresSortChanged: (column, direction) =>
    dispatch(storesActions.fetchStoresSortChanged(column, direction)),
  fetchStoresDateRangeChanges: (startDate, endDate) =>
    dispatch(storesActions.fetchStoresDateRangeChanges(startDate, endDate)),
  deleteStore: id => dispatch(storeAction.deleteStore(id)),
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoreList));
