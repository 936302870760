import React from 'react';
import moment from 'moment';
import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import { itemDefine } from '../../utils/itemV2Util';
import { convertCountryToString } from '../../utils/countriesAndZones';

const TypographyText = withStyles({
  root: {
    '&.MuiTypography-root': {
      fontFamily: 'Overpass',
      fontSize: '16px',
      color: '#8d8d8d',
      marginBottom: '10px',
    },
  },
})(Typography);

const ItemDetails = ({ item}) => {
  console.log(item)
  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant="h5" className="paymentRequest-subtitle">
          ITEM
        </Typography>
      </Grid>
      <Paper className='info-wrapper paymentInfo-wrapper'>
        <Grid container>
          <Typography variant="h6" className="payment-info-subtitle">
            Details
          </Typography>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Item ID</TypographyText>
            <Typography>{item?.id}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Date Created</TypographyText>
            <Typography>{moment(item?.create_date).format('L h:mm')}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Country</TypographyText>
            <Typography>{convertCountryToString(item?.country_id)?.[0]?.name}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Shupper Name</TypographyText>
            <Typography>{item?.shupper_full_name}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Brand</TypographyText>
            <Typography>{item?.brand_id}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>State</TypographyText>
            <Typography>{itemDefine[item?.state_id]}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Category</TypographyText>
            <Typography>{item?.category_id}</Typography>

          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Price</TypographyText>
            <Typography>{item?.price}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Currency</TypographyText>
            <Typography>{item?.priceCurrency}</Typography>
          </Grid>

          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Description</TypographyText>
            <Typography>{item?.description}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ItemDetails;
