import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import { IconButton, Dialog, FlatButton, RaisedButton } from "material-ui";
import FowrardedMessages from "../components/chat/FowrardedMessages";
import ImageViewer from "../components/imageviewer";
import NoPermission from "../components/nopermission";
import { getPermission } from "../utils/cookies";
import { decodePermissions } from "../utils/permissionUtil";
import getHandlers from "../actions/chatsActions";
import { getProps } from "../reducers/chatsReducer";
import {
  Filters,
  ChatsList,
  MessagesList,
  ChatsTabs,
  Switcher
} from "../components/chat";
import VideoViewer from "../components/VideoViewer";
import { config } from "../utils/envSetup";
import SuccessAlert from "../components/SuccessAlert";
import "../static/styles/chat-page.css";

const validateAmount = shuppers => !shuppers || shuppers.length < 1;

const AUTORENEW_CHATS_DELAY = config("autoRenewChatsDelay");

const UnflagDialog = ({
  messageToUnflag,
  chatToUnflag,
  opened,
  onClose,
  removeFlagFromChat
}) => (
  <Dialog
    contentStyle={{ width: "300px" }}
    title={"Unflag this chat?"}
    actions={[
      <FlatButton
        label="Yes"
        primary
        onClick={() => {
          removeFlagFromChat(messageToUnflag, chatToUnflag);
          onClose();
        }}
      />,
      <FlatButton label="No" primary onClick={onClose} />
    ]}
    modal={false}
    open={opened}
    onRequestClose={onClose}
  />
);

class ChatPage extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      messageField: "",
      unflagDialogOpened: false,
      messageToUnflag: null,
      chatToUnflag: null,
      autoRefreshInterval: null,
      videoViewerOpened: false,
      openForwardedMessagesDialog: false,
      shupperForwardedMessages: null,
      userForwardedMessages: null,
      selectedForwardedMessages: [],
      validationMessageAmountShuppers: "",
      selectedChatForwardedMessages: null,
      openSuccessAlert: false,
      usersForSelect: []
    };
  }

  componentDidMount() {
    if (this.state.permission.chats) {
      this.props.getShuppersData();
      this.props.getStores();
      this.props.makeConnection();
      this.props.loadChatsList("", { from: null, to: null }, false)();
      if (!this.state.autoRefreshInterval)
        this.setState({
          autoRefreshInterval: setInterval(
            () =>
              this.props.loadChatsList(
                this.props.filters.friendlyName,
                this.props.filters.date,
                this.props.filters.support,
                0,
                false,
                true,
                this.props.chatsLimit,
                this.props.chatsSkip,
                this.props.filters.inMessages
              )(),
            AUTORENEW_CHATS_DELAY * 1000
          )
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.saveForwardedChatsSuccess !==
        this.props.saveForwardedChatsSuccess &&
      this.props.saveForwardedChatsSuccess
    ) {
      this.saveSuccessForwardedMessages();
      this.props.resetForwardedChats();
    }
    if (
      prevProps.userRolesForwardedMessages !==
        this.props.userRolesForwardedMessages &&
      this.props.userRolesForwardedMessages
    ) {
      let noShuppers = [];
      let shuppers = [];
      this.props.userRolesForwardedMessages.map(item => {
        if (
          item.roles.indexOf("shupper") + 1 === false ||
          !item.roles.indexOf("supershupper") + 1 === false
        ) {
          return noShuppers.push(item._id);
        }
        if (item.roles.indexOf("shupper") + 1) {
          return shuppers.push(item._id);
        }

        return false;
      });
      if (noShuppers.length === 0) {
        this.setState({ usersForSelect: shuppers });
      } else {
        this.setState({ usersForSelect: noShuppers });
      }
    }
    if (
      prevProps.displayNames !== this.props.prevProps &&
      this.state.selectedChatForwardedMessages
    ) {
      if (!this.props.displayNames[this.state.selectedChatForwardedMessages]) {
        this.setState({ selectedChatForwardedMessages: null });
      }
    }
    if (
      prevState.openSuccessAlert !== this.state.openSuccessAlert &&
      this.state.openSuccessAlert
    ) {
      setTimeout(() => this.setState({ openSuccessAlert: false }), 40000);
    }
  }

  closeUnflagDialog = () =>
    this.setState({
      unflagDialogOpened: false,
      messageToUnflag: null
    });

  openUnflagDialog = ({ _id }, chatId) => e => {
    e.stopPropagation();
    this.setState({
      messageToUnflag: _id,
      chatToUnflag: chatId,
      unflagDialogOpened: true
    });
  };

  expandVideo = (url, poster) => {
    this.setState({
      videoViewerOpened: true
    });
    this.props.expandChatsVideo(url, poster);
  };

  closeVideo = () => {
    this.setState({
      videoViewerOpened: false
    });
    this.props.closeChatsVideo();
  };
  closeDialogForwardedMessages = () => {
    this.setState({
      openForwardedMessagesDialog: false,
      shupperForwardedMessages: null,
      userForwardedMessages: null
    });
  };

  saveSuccessForwardedMessages = () => {
    this.setState({
      openForwardedMessagesDialog: false,
      shupperForwardedMessages: null,
      userForwardedMessages: null,
      selectedForwardedMessages: [],
      validationMessageAmountShuppers: "",
      selectedChatForwardedMessages: null,
      openSuccessAlert: true
    });
  };
  changeShupperForwardedMessages = (e, ev, val) => {
    this.setState({ shupperForwardedMessages: e });
  };
  changeUsersForwardedMessages = (e, ev, val) => {
    this.setState({ userForwardedMessages: e });
  };
  changeCheckboxForwardedMessages = (id, user, chatId) => {
    const {
      selectedForwardedMessages,
      selectedChatForwardedMessages
    } = this.state;
    let newSelectedChats;
    if (selectedForwardedMessages.indexOf(id) + 1) {
      newSelectedChats = selectedForwardedMessages.filter(item => item !== id);
    } else {
      newSelectedChats = selectedForwardedMessages.concat(id);
    }
    this.setState({
      selectedForwardedMessages: newSelectedChats,
      selectedChatForwardedMessages:
        newSelectedChats.length === 0
          ? null
          : !selectedChatForwardedMessages
            ? chatId
            : selectedChatForwardedMessages
    });
  };
  handleForwardChats = () => {
    const {
      shupperForwardedMessages,
      selectedForwardedMessages,
      userForwardedMessages,
      usersForSelect
    } = this.state;
    if (
      validateAmount(shupperForwardedMessages) &&
      validateAmount(userForwardedMessages)
    ) {
      this.setState({
        validationMessageAmountShuppers: "Must select a shupper and user"
      });
    } else {
      this.props.setForwardedChats({
        shupper: shupperForwardedMessages.value,
        user:
          usersForSelect.length === 1
            ? usersForSelect[0]
            : userForwardedMessages.value,
        messages: selectedForwardedMessages
      });
    }
  };
  openSuccessAlert = () => {};
  openDialogForwardedMessages = () => {
    const { chatUsers, getUserRoles } = this.props;
    const { selectedChatForwardedMessages } = this.state;
    this.setState({ openForwardedMessagesDialog: true });
    if (
      Object.keys(chatUsers).length !== 0 && selectedChatForwardedMessages
        ? chatUsers[selectedChatForwardedMessages]
            .map(item => item.identity)
            .join(",")
        : "Chat user are empty"
    ) {
      getUserRoles(
        chatUsers[selectedChatForwardedMessages].map(item => item.identity),
        true
      );
    }
  };
  render() {
    const {
      permission,
      videoViewerOpened,
      messageToUnflag,
      chatToUnflag,
      unflagDialogOpened,
      openForwardedMessagesDialog,
      shupperForwardedMessages,
      selectedForwardedMessages,
      validationMessageAmountShuppers,
      selectedChatForwardedMessages,
      userForwardedMessages,
      openSuccessAlert,
      usersForSelect
    } = this.state;
    const {
      onOpenDrawer,
      setNameFilter,
      selectedChat,
      socket,
      changeFilterParams,
      filters,
      loadChatsList,
      chatsSkip,
      previewImages,
      clearPreviewImages,
      resetTheFilters,
      removeFlagFromChat,
      videoUrl,
      poster,
      orientation,
      clearPreviewImageOrientation,
      activeShuppers,
      chatUsers,
      stores
    } = this.props;
    return permission.chats ? (
      <React.Fragment>
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <Filters
            changeFilterParams={changeFilterParams}
            setNameFilter={setNameFilter}
            loadChatsList={loadChatsList}
            reset={resetTheFilters}
          />
        </div>
        {openSuccessAlert ? (
          <SuccessAlert
            mainText="Success!"
            extraText="Forwarded chats added successfully"
            onClose={() => {
              this.setState({ openSuccessAlert: false });
            }}
          />
        ) : null}
        <ImageViewer
          isOpen={previewImages.length}
          images={previewImages}
          onClose={() => {
            clearPreviewImages();
            clearPreviewImageOrientation();
          }}
          orientation={orientation}
        />

        {permission.canForwardChats ? (
          <FowrardedMessages
            open={openForwardedMessagesDialog}
            value={shupperForwardedMessages}
            activeShuppers={[...activeShuppers, ...stores]}
            handleForwardChats={this.handleForwardChats}
            notification={validationMessageAmountShuppers}
            selectedForwardedMessages={selectedForwardedMessages}
            changeShupperForwardedMessages={this.changeShupperForwardedMessages}
            closeDialog={this.closeDialogForwardedMessages}
            users={chatUsers ? chatUsers[selectedChatForwardedMessages] : []}
            usersForSelect={usersForSelect}
            changeUsersForwardedMessages={this.changeUsersForwardedMessages}
            userForwardedMessages={userForwardedMessages}
          />
        ) : null}
        <VideoViewer
          videoUrl={videoUrl}
          poster={poster}
          display={videoViewerOpened}
          closeRequest={this.closeVideo}
        />
        <UnflagDialog
          removeFlagFromChat={removeFlagFromChat}
          messageToUnflag={messageToUnflag}
          chatToUnflag={chatToUnflag}
          onClose={this.closeUnflagDialog}
          opened={unflagDialogOpened}
        />
        <div className="forward-chat-button">
          {permission.canForwardChats ? (
            <RaisedButton
              label={`Forward ${selectedForwardedMessages.length} chats`}
              secondary
              disabled={selectedForwardedMessages.length < 2}
              onClick={this.openDialogForwardedMessages}
            />
          ) : null}
        </div>
        <div className="content">
          <div className="flxc ac">
            <div className="flxr ac jc columns">
              <div className="column chats-column">
                {
                  <ChatsList
                    socket={socket}
                    skip={chatsSkip}
                    loadChatsList={loadChatsList}
                    filters={filters}
                    removeFlagFromChat={removeFlagFromChat}
                    openUnflagDialog={this.openUnflagDialog}
                  />
                }
              </div>
              <div className="column chat-column">
                <div className="column-title flxr ac">Chat messages:</div>

                <ChatsTabs socket={socket} />
                <MessagesList
                  changeCheckboxForwardedMessages={
                    this.changeCheckboxForwardedMessages
                  }
                  chatId={selectedChat}
                  permissions={permission}
                  expandChatsVideo={this.expandVideo}
                  socket={socket}
                  selectedChatForwardedMessages={selectedChatForwardedMessages}
                  selectedForwardedMessages={selectedForwardedMessages}
                />
              </div>
              {filters.inMessages ? <Switcher /> : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <NoPermission onOpenDrawer={onOpenDrawer} />
    );
  }
}

ChatPage.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired
};

const mapStateToProps = getProps;

const mapDispatchToProps = getHandlers;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatPage);
