import React from 'react';
import '../static/styles/alert.css'



const SuccessAlert = ({ mainText, extraText, isOpen, onClose }) => (
    <React.Fragment>
        <div className="alert alert-success alert-dismissible fade show App-alert" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
            </button>
            {mainText ? <strong>{mainText}</strong> : null} {extraText}
        </div>
    </React.Fragment>

);
export default SuccessAlert;


