/*
Kateryna Ivanova
20/03/2018

Reducer for a list of users
*/
import types from '../actions/actionTypes';
import {createReducer} from './helpers/redux-helpers';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 100,
  users: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: '',
  sort: {},
  genderList: ['male', 'female', 'other'],
  genderFilter: '',
  roleList: ['user', 'shupper', 'admin', 'supershupper', 'store', 'influencer'],
  roleFilter: [null],
  statusList: ['active', 'archived'],
  statusFilter: '',
  newPassword: null,
  regenerateError: null,
  isRegenerating: false,
  shuppersOfTheDay: [],
  shuppersList: [],
  tips: [],
  loadingTips: false,
  tipUser: '',
};

export default createReducer(
  {
    [types.FETCH_USERS]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      users: [],
      totalCount: 0,
      searchQuery: action.query === '' ? '' : action.query || state.searchQuery,
      roles: action.roles || state.roles,
      roleFilter: action.roleFilter === null ? state.roleFilter : action.roleFilter || initialState.roleFilter,
      statusFilter:
        action.statusFilter === null ? state.statusFilter : action.statusFilter || initialState.statusFilter,
      genderFilter:
        action.genderFilter === null ? state.genderFilter : action.genderFilter || initialState.genderFilter,
    }),
    [types.FETCH_MORE_USERS]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.FETCH_USERS_SUCCESS]: (state, action) => ({
      ...state,
      offset: state.offset + state.limit,
      totalCount: action.data.count,
      users: [...state.users, ...action.data.users],
      hasMore: state.users.length + action.data.users.length < action.data.count,
      isLoading: false,
    }),
    [types.FETCH_USERS_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasMore: false,
    }),
    [types.FETCH_USERS_SORT_CHANGED]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      users: [],
      totalCount: 0,
      sort: procesSort(state.sort, action.column, action.direction),
    }),
    [types.UPDATE_USER]: state => ({
      ...state,
      isUpdatingUser: true,
      updateUserSuccess: false,
      updateUserError: null,
    }),
    [types.UPDATE_USER_SUCCESS]: state => ({
      ...state,
      isUpdatingUser: false,
      updateUserSuccess: true,
      isLoading: true,
      offset: 0,
      users: [],
      totalCount: 0,
    }),
    [types.UPDATE_USER_FAILED]: (state, action) => ({
      ...state,
      isUpdatingUser: false,
      updateUserSuccess: false,
      updateUserError: action.reason,
    }),
    [types.CLEAR_USERS_FILTERS]: state => ({
      ...state,
      users: [],
      offset: 0,
      genderFilter: initialState.genderFilter,
      roleFilter: initialState.roleFilter,
      statusFilter: initialState.statusFilter,
      query: '',
      searchQuery: '',
    }),
    [types.USER_REGENERATE_PASSWORD]: state => ({
      ...state,
      isRegenerating: true,
      newPassword: null,
    }),
    [types.USER_REGENERATE_PASSWORD_SUCCESS]: (state, action) => ({
      ...state,
      isRegenerating: false,
      newPassword: action.newPassword,
    }),
    [types.USER_REGENERATE_PASSWORD_FAILED]: (state, action) => ({
      ...state,
      isRegenerating: false,
      regenerateError: action.reason,
    }),
    [types.CLEAR_REGENERATED_PASSWORD]: state => ({
      ...state,
      newPassword: initialState.newPassword,
    }),
    [types.GET_SHUPPERS_OF_THE_DAY]: state => ({
      ...state,
      isLoading: true,
      shuppersOfTheDay: [],
    }),
    [types.GET_SHUPPERS_OF_THE_DAY_SUCCESS]: (state, action) => ({
      ...state,
      shuppersOfTheDay: [...state.shuppersOfTheDay, ...action.data],
      isLoading: false,
    }),
    [types.GET_SHUPPERS_OF_THE_DAY_FAILED]: state => ({
      ...state,
      isLoading: false,
    }),
    [types.SET_SHUPPER_OF_THE_DAY_SUCCESS]: state => ({
      ...state,
      isLoading: true,
      shuppersOfTheDay: [],
    }),
    [types.REMOVE_SHUPPER_OF_THE_DAY_SUCCESS]: state => ({
      ...state,
      isLoading: true,
      shuppersOfTheDay: [],
    }),
    [types.GET_ONLY_SHUPPERS]: state => ({
      ...state,
      isLoading: true,
      shuppersList: [],
    }),
    [types.GET_ONLY_SHUPPERS_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      shuppersList: action.data,
    }),
    [types.GET_ONLY_SHUPPERS_FAILED]: state => ({
      ...state,
      isLoading: false,
      shuppersList: [],
    }),
    [types.MOVE_ITEMS_FROM_USER_TO_ANOTHER]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.STOP_LOADING_USERS]: state => ({
      ...state,
      isLoading: false,
    }),
    [types.GET_TIPS]: (state, action) => ({
      ...state,
      loadingTips: true,
      tips: [],
    }),
    [types.GET_TIPS_SUCCESS]: (state, action) => ({
      ...state,
      loadingTips: false,

      tips: action.payload,
    }),
    [types.SET_ACTIVE_TIPS]: (state, action) => ({
      ...state,
      tipUser: action.payload,
    }),
  },
  initialState,
);
