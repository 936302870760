import types from './actionTypes';
import { setPreviewImageOrientation} from '../actions/chatsActions';

export const loadReviewByBrand = (id, status) => ({
    type: types.LOAD_REVIEWS_BY_BRAND,
    payload: {
        id, status
    }
});

export const setBrandId = id => ({
    type: types.SET_BRAND_ID,
    payload: {
        id
    }
});

export const setLoadBrandError = text => ({
    type: types.SET_LOAD_ERROR,
    payload: {
        text
    }
});

export const setReviewList = (reviews, haveMore, skip) => ({
    type: types.SET_REVIEWS_LIST,
    payload: {
        reviews, haveMore, skip
    }
});

export const prependReviewList = (list, haveMore, skip) => ({
    type: types.PREPEND_REVIEWS_LIST,
    payload: {
        list, haveMore, skip,
    }
});

export const changeReviewStatus = (reviewId, status, index) => ({
    type: types.UPDATE_REVIEW_STATUS,
    payload: {
        reviewId, status, index
    }
});

export const changeActiveStatus = (status, id) => ({
    type: types.CHANGE_STATUS_OF_ACTIVE_REVIEW,
    payload: {
        status, id,
    }
});

export const loadMoreReviews = (id, status, skip) => ({
    type: types.LOAD_MORE_REVIEWS,
    payload: {
        id, status, skip,
    }
});

export const setSaveReviewSuccess = () => ({
    type: types.SET_SAVE_REVIEW_SUCCESS,
});

export const updateReviewFields = (key, data, reviewId) => ({
    type: types.UPDATE_REVIEW_FIELDS,
    payload: {
        reviewId, data, key
    },
});

export const startEditingReview = id => ({
    type: types.START_EDIT_REVIEW,
    payload: {
        id,
    }
});

export const cancelEditingReview = id => ({
    type: types.CANCEL_EDIT_REVIEW,
    payload: {
        id
    }
});

export const setNewValue = (id, key, val) => ({
    type: types.SET_NEW_VALUE_REVIEW,
    payload: {
        id, key, val
    }
});

export const setNewImage = (key, src) => ({
    type: types.SET_NEW_REVIEW_IMAGE,
    payload: { key, src },
});

export const setNewVideo = (key, src, posterSrc) => ({
    type: types.SET_NEW_REVIEW_VIDEO,
    payload: { key, src, posterSrc },
});

export const setBrandName = brandName => ({
    type: types.SET_BRAND_NAME,
    payload: { brandName },
});

export const reviewActions = dispatch => ({
    approveReview: (id, index) => () => dispatch(changeReviewStatus(id, 'approved', index)),
    declineReview: (id, index) => () => dispatch(changeReviewStatus(id, 'declined', index)),
    updateReviewFields: (key, data, reviewId) => dispatch(updateReviewFields(key, data, reviewId)),
    startEditingReview: id => dispatch(startEditingReview(id)),
    cancelEditingReview: id => dispatch(cancelEditingReview(id)),
    setNewValue: (id, key, val) => dispatch(setNewValue(id, key, val)),
    setNewImage: (id, src) => () => dispatch(setNewImage(id, src)),
    setNewVideo: (id, src, posterSrc) => () => dispatch(setNewVideo(id, src, posterSrc)),
    setPreviewImageOrientation: orientation => dispatch(setPreviewImageOrientation(orientation)),

});

export const reviewListActions = dispatch => ({
    loadMoreReviews: (id, status, skip) => () => dispatch(loadMoreReviews(id, status, skip)),
});

export default dispatch => ({
    setBrandId: id => dispatch(setBrandId(id)),
    loadReviewByBrand: (id, status) => dispatch(loadReviewByBrand(id, status)),
    changeActiveStatus: (status, id) => dispatch(changeActiveStatus(status, id)),
    setPreviewImageOrientation: orientation => () => dispatch(setPreviewImageOrientation(orientation)),

});
