/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 08/05/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchCouponsFailed, fetchCouponsSuccess } from '../actions/couponsactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectCouponsState = state => state.couponsReducer;

function* fetchCouponsAsync() {
  const eventsState = yield select(selectCouponsState);
  const response = yield call(
    request,
    `${baseUrl}/api/coupon?limit=${eventsState.limit}&skip=${eventsState.offset}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchCouponsFailed(response.err.toString()));
  } else {
    yield put(fetchCouponsSuccess(response.data));
  }
}

function* fetchItems() {
  yield takeLatest([`${types.FETCH_COUPONS}`, `${types.FETCH_MORE_COUPONS}`,
    `${types.DELETE_COUPON_SUCCESS}`, `${types.UPDATE_COUPON_SUCCESS}`], fetchCouponsAsync);
}

export default function* couponsSaga() {
  yield all([
    fetchItems(),
  ]);
}
