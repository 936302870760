/* eslint-disable no-bitwise */
const masksPermissions = {
  users: 0b1,
  events: 0b10,
  stores: 0b100,
  payments: 0b1000,
  bousers: 0b10000,
  items: 0b100000,
  securitylogs: 0b1000000,
  coupons: 0b10000000,
  chats: 0b100000000,
  set_shupper_supershupper: 0b1000000000,
  seePending: 0b10000000000,
  seeRejected: 0b100000000000,
  seeCanceled: 0b1000000000000,
  seeFulfilled: 0b10000000000000,
  seeRefunded: 0b100000000000000,
  seeShipping: 0b1000000000000000,
  refundPayment: 0b10000000000000000,
  cancelPayment: 0b100000000000000000,
  movePaymentToShipping: 0b1000000000000000000,
  setShupperOfTheDay: 0b10000000000000000000,
  grantPermissionsForOtherUsers: 0b100000000000000000000,
  changePaymentStatus: 0b1000000000000000000000,
  canConfigureTimeTable: 0b10000000000000000000000,
  canManageReviews: 0b100000000000000000000000,
  canApproveShuppers: 0b10000000000000000000000000,
  canManageCheckins: 0b100000000000000000000000000,
  canApproveBrands: 0b1000000000000000000000000000,
  canForwardChats: 0b10000000000000000000000000000,
};

export function decodePermissions(encoded) {
  const result = {};
  for (let permissionKey in masksPermissions) {
    result[permissionKey] = encoded & masksPermissions[permissionKey];
  }

  return result;
}

export function encodePermission(decoded) {
  let permission = 0;
  for (let permissionKey in decoded) {
    if (decoded[permissionKey]) permission |= masksPermissions[permissionKey];
  }
  return permission;
}
