import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import { fetchAllBrandsFailed, fetchAllBrandsSuccess } from '../actions/brandsActions';

const baseUrl = getBaseUrl();

const selectBrandsState = state => state.brandsReducer;

function* fetchAllBrandsAsync() {
  const brandsState = yield select(selectBrandsState);

  const q = brandsState.filterKeyword ? `?q=${brandsState.filterKeyword}` : ''

  const response = yield call(
    request,
    `${baseUrl}/api/v2/brand${q}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchAllBrandsFailed(response.err.toString()));
  } else {
    console.log('response.data', response.data)
    yield put(fetchAllBrandsSuccess(response.data));
  }
}

function* fetchAllBrands() {
  yield takeLatest([`${types.FETCH_ALL_BRANDS}`], fetchAllBrandsAsync);
}

export default function* brandssaga() {
  yield all([
    fetchAllBrands(),
  ]);
}
