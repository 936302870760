import React from 'react'
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import { addDays, subDays, startOfYear, endOfDay, isSameDay } from "date-fns";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const CalendarRangePicker = ({ date, handleChange }) => {
  const now = React.useRef(new Date());
  const staticRanges = defaultStaticRanges.filter(i => !i.label.includes('This'))

  return (
    <DateRangePicker
      onChange={handleChange}
      direction='vertical'
      showDateDisplay={false}
      inputRanges={[]}
      maxDate={now.current}
      showSelectionPreview={false}
      moveRangeOnFirstSelection={false}
      showMonthAndYearPickers={false}
      showMonthArrow={true}
      className="datePicker"
      ranges={date}
      staticRanges={[
        ...staticRanges,
        {
          label: "This Year",
          range: () => ({
            startDate: startOfYear(new Date()),
            endDate: endOfDay(new Date())
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        },
        {
          label: "Clear dates",
          range: () => ({
            startDate: null,
            endDate: null
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        }
      ]}
    />
  )
}