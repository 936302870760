import types from '../actions/actionTypes';

const initialState = {
  offset: 0,
  limit: 100,
  allowedCountries: [],
  countriesZones: [],
  zone: [],
  countriesAttributes: [],
  zonesUsaAttributes: [],
  totalCount: 0,
  isLoading: false,
  error: null,
  isEditCountry: false,
  indexId: null,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MORE_COUNTRIES_ZONES:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
      });
    case types.FETCH_MORE_COUNTRIES_ATTRIBUTES:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
      });
    case types.FETCH_ALLOWED_COUNTRIES:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        allowedCountries: [],
      });
    case types.FETCH_ALLOWED_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        allowedCountries: action.data,
      });
    case types.FETCH_ALLOWED_COUNTRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });
    case types.ADD_EDIT_ALLOWED_COUNTRY:
      return Object.assign({}, state, {});
    case types.ADD_NEW_ALLOWED_COUNTRY:
      return Object.assign({}, state, {
        allowedCountries: [action.data, ...state.allowedCountries],
      });
    case types.ADD_ALLOWED_COUNTRIES:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
      });
    case types.ADD_ALLOWED_COUNTRIES_SUCCESS:
      const add = [
        ...state.allowedCountries,
        {
          id: action.data.id,
          seller_zone: action.data.seller_zone_id,
          buyer_zone: action.data.buyer_zone_id,
        },
      ];
      add.shift();
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        allowedCountries: add,
      });
    case types.ADD_ALLOWED_COUNTRIES_FAILED:
      return Object.assign({}, state, {
        error: action.reason,
        isLoading: true,
      });

    case types.DELETE_ALLOWED_COUNTRIES:
      const deletedCountry = [...state.allowedCountries].filter(country => country.id !== parseFloat(action.data));
      const deletedAllowed = deletedCountry.filter(country => country.id !== parseFloat(action.data));

      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        allowedCountries: deletedAllowed,
      });
    case types.DELETE_ALLOWED_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
      });
    case types.DELETE_ALLOWED_COUNTRIES_FAILED:
      return Object.assign({}, state, {
        error: action.reason,
        isLoading: true,
      });

    case types.FETCH_COUNTRIES_ZONES:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        countriesZones: [],
      });
    case types.FETCH_COUNTRIES_ZONES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        offset: state.offset + state.limit,
        countriesZones: [...state.countriesZones, ...action.data],
      });
    case types.FETCH_COUNTRIES_ZONES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });
    case types.ADD_NEW_COUNTRY_ZONE:
      return Object.assign({}, state, {
        countriesZones: [action.payload, ...state.countriesZones],
      });
    case types.ADD_COUNTRIES_ZONES:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
      });
    case types.ADD_COUNTRIES_ZONES_SUCCESS:
      const addData = [...state.countriesZones];
      const findIndex = state.countriesZones.findIndex(i => i.id === action.data.id);
      addData[findIndex] = action.data;
      addData[findIndex].taxes = action.data.taxes.filter(item => item !== null);
      addData.shift();
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        countriesZones: addData,
      });
    case types.ADD_COUNTRIES_ZONES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });

    case types.ADD_ZONE_SUCCESS:
      const addZone = [...state.zone];
      const findZoneIndex = state.zone.findIndex(i => i.id === action.data.id);
      addZone[findZoneIndex] = action.data;
      addZone[findZoneIndex].taxes = action.data.taxes.filter(item => item !== null);
      addZone.shift();
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        zone: addZone,
      });
    case types.ADD_ZONE_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });

    case types.UPDATE_COUNTRIES_ZONES:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
      });

    case types.UPDATE_COUNTRIES_ZONES_SUCCESS:
      const updateCountry = [...state.countriesZones];
      updateCountry[action.rowIndex] = action.newCountryObj;
      updateCountry[action.rowIndex].taxes = action.newCountryObj.taxes.filter(item => item !== null);

      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        countriesZones: updateCountry,
      });
    case types.UPDATE_ZONE:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
      });
    case types.UPDATE_ZONE_SUCCESS:
      const updateZone = [...state.zone];
      updateZone[action.zoneRowIndex] = action.newZoneObj;
      updateZone[action.zoneRowIndex].taxes = action.newZoneObj.taxes.filter(item => item !== null);

      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        zone: updateZone,
      });

    case types.UPDATE_ZONE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
      });

    case types.UPDATE_COUNTRIES_ZONES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.reason,
      });

    case types.DELETE_COUNTRIES_ZONES:
      const newData = [...state.countriesZones];

      newData[action.rowId].taxes = [];
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        countriesZones: newData,
      });
    case types.DELETE_COUNTRIES_ZONES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
      });
    case types.DELETE_COUNTRIES_ZONES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });

    case types.DELETE_ZONE:
      const deleteData = [...state.zone];

      deleteData[action.rowId].taxes = [];
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        zone: deleteData,
      });
    case types.DELETE_ZONE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
      });
    case types.DELETE_ZONE_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });
    case types.FETCH_ZONE:
      return Object.assign({}, state, {
        isLoading: true,

        zone: [],
      });
    case types.FETCH_ZONE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,

        zone: action.data,
      });
    case types.FETCH_ZONE_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });

    case types.ADD_COUNTRY_IN_COUNTRIES_ZONES: {
      const newData = [action.payload.newCountryData, ...state.countriesZones];
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        countriesZones: newData,
        isEditCountry: false,
      });
    }
    case types.ADD_COUNTRY_IN_COUNTRIES_ZONES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        countriesZones: action.data,
      });
    case types.ADD_COUNTRY_IN_COUNTRIES_ZONES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.reason,
      });

    case types.SET_IS_EDIT_COUNTRY_COUNTRIES_ZONES:
      return Object.assign({}, state, {
        isEditCountry: action.payload,
      });

    case types.ADD_NEW_COUNTRY_ZONE:
      return Object.assign({}, state, {
        countriesZones: [action.payload, ...state.countriesZones],
      });
    case types.ADD_NEW_ZONE:
      return Object.assign({}, state, {
        zone: [action.data, ...state.zone],
      });

    case types.EDIT_CURRENT_COUNTRY_ZONE:
      const editData = [...state.countriesZones];
      editData[action.payload.indx].taxes = action.payload.data;
      return Object.assign({}, state, {
        countriesZones: editData,
      });

    case types.EDIT_CURRENT_ZONE:
      const editZone = [...state.zone];
      editZone[action.payload.indx].taxes = action.payload.data;

      return Object.assign({}, state, {
        zone: editZone,
      });
    case types.REMOVE_NEW_ZONE:
      return {
        ...state,
        zone: [...state.zone.filter((item, index) => index !== action.payload)],
      };

    case types.REMOVE_NEW_COUNTRY_ZONE:
      return {
        ...state,
        countriesZones: [...state.countriesZones.filter((item, index) => index !== action.payload.data)],
      };
    case types.REMOVE_NEW_ALLOWED_COUNTRY:
      return {
        ...state,
        allowedCountries: [...state.allowedCountries.filter((item, index) => index !== action.payload.data)],
      };

    case types.FETCH_COUNTRIES_ATTRIBUTES: {
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        countriesAttributes: [],
      });
    }
    case types.FETCH_COUNTRIES_ATTRIBUTES_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        offset: state.offset + state.limit,
        countriesAttributes: [...state.countriesAttributes, ...action.payload.data],
      });
    }

    case types.FETCH_COUNTRIES_ATTRIBUTES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.reason,
      });

    case types.FETCH_ZONES_COUNTRIES_ATTRIBUTES: {
      return {...state, isLoading: true};
    }
    case types.FETCH_ZONES_COUNTRIES_ATTRIBUTES_SUCCESS: {
      return {...state, zonesUsaAttributes: action.payload.data, isLoading: false};
    }

    case types.FETCH_ZONES_COUNTRIES_ATTRIBUTES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.reason,
      });

    case types.EDIT_CURRENT_ATTRIBUTES_COUNTRY: {
      const editData = [...state.countriesAttributes];
      editData[action.payload.index].attributes = action.payload.data;
      const oldValues = action.payload.data.filter(id => id.attribute_id === 6)[0].array_of_num_value;

      editData[action.payload.index].attributes.filter(id => id.attribute_id === 6)[0].array_of_num_value =
        oldValues ?? [null, null, null];

      return {...state, countriesAttributes: editData};
    }

    case types.EDIT_CURRENT_ATTRIBUTES_ZONE: {
      const editData = [...state.zonesUsaAttributes];
      editData[action.payload.index].attributes = action.payload.data;
      const oldValues = action.payload.data.filter(id => id.attribute_id === 6)[0].array_of_num_value;
      editData[action.payload.index].attributes.filter(id => id.attribute_id === 6)[0].array_of_num_value =
        oldValues ?? [null, null, null];

      return {...state, zonesUsaAttributes: editData};
    }

    case types.UPDATE_COUNTRY_ATTRIBUTES: {
      return {...state, isLoading: true};
    }

    case types.UPDATE_COUNTRY_ATTRIBUTES_SUCCESS: {
      const isUpdateZone = action.payload.id < 51;
      const newData = isUpdateZone ? [...state.zonesUsaAttributes] : [...state.countriesAttributes];
      const findIndex = newData.findIndex(item => item.id === action.payload.id);
      for (let i = 0; i < action.payload.attributes.length; i++) {
        const index = newData[findIndex].attributes.findIndex(
          attribute => attribute.attributeTypeId === action.payload.attributes[i].attribute_id,
        );
        newData[findIndex].attributes[index] = action.payload.attributes[i];
      }
      if (isUpdateZone) {
        return Object.assign({}, state, {
          isLoading: false,
          zonesUsaAttributes: newData,
        });
      }
      return Object.assign({}, state, {
        isLoading: false,
        countriesAttributes: newData,
      });
    }

    case types.UPDATE_COUNTRY_ATTRIBUTES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.reason,
      });
    case types.ADD_COUNTRY_ATTRIBUTES: {
      return {...state, isLoading: true};
    }

    case types.ADD_COUNTRY_ATTRIBUTES_SUCCESS: {
      const isUpdateZone = action.payload.id < 51;
      const newData = isUpdateZone
        ? JSON.parse(JSON.stringify(state.zonesUsaAttributes))
        : JSON.parse(JSON.stringify(state.countriesAttributes));
      const findIndex = newData.findIndex(obj => obj.id === action.payload.id);
      for (let i = 0; i < action?.payload?.attributes?.length; i++) {
        const index = newData[findIndex].attributes.findIndex(
          attribute => attribute.attributeTypeId === action.payload.attributes[i].attribute_id,
        );
        newData[findIndex].attributes[index] = action.payload.attributes[i];
      }
      if (isUpdateZone) {
        return Object.assign({}, state, {
          isLoading: false,
          zonesUsaAttributes: newData,
        });
      }
      return Object.assign({}, state, {
        isLoading: false,
        offset: 0,
        countriesAttributes: newData,
      });
    }

    case types.ADD_COUNTRY_ATTRIBUTES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.reason,
      });

    default:
      return state;
  }
}
