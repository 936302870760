import {
    takeLatest,
    put,
    call,
    all
} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {
    fetchShuppersSuccess,
    approveShupperSuccess,
    declineShupperSuccess,
    approveShupperError
} from '../actions/approveShuppers';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* fetchShuppersForApprove({
    payload
}) {
    const response = yield call(
        request,
        `${baseUrl}/api/candidates?${payload.status ? 'status=' + payload.status : ''}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });
    // if (response.Error) {
    //     return put(setLoadBrandError(response.Error));
    // }
    yield put(fetchShuppersSuccess(response.data || [],
        response.data.length >= 20,
        response.data.length));
}

function* declineShupper({
    payload
}) {
    yield call(request,
        `${baseUrl}/api/candidates/decline/${payload.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
    yield put(declineShupperSuccess());
}

function* approveShupper({
    payload
}) {

    const response = yield call(
        request,
        `${baseUrl}/api/candidates/approve/${payload.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: null
        });
    if (response.err) {
        yield put(approveShupperError(response.err))
    } else yield put(approveShupperSuccess(response));
}

function* watchFetchShuppersForApprove() {
    yield takeLatest(types.FETCH_SHUPPERS, fetchShuppersForApprove);
}

function* watchApproveShupper() {
    yield takeLatest(types.APPROVE_SHUPPER, approveShupper);
}

function* watchDeclineShupper() {
    yield takeLatest(types.DECLINE_SHUPPER, declineShupper);
}


export default function* approveShuppersSaga() {
    yield all([
        watchApproveShupper(),
        watchFetchShuppersForApprove(),
        watchDeclineShupper(),
    ]);
}
