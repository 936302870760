import React from "react";
import TextField from "material-ui/TextField";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import Checkbox from "material-ui/Checkbox";
import moment from "moment";
import ImagePicker from "../imagepicker";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import MiniList from "../MiniList";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { DialogCommon } from "../dialog";

const useStyles = makeStyles({
  input: {
    width: '100%'
  }
});
const PersonalInfoOld = ({
  usersReducer,
  dataUser,
  onChangeInput,
  onChangeCheckbox,
  getNewValueImage,
  setNewImage,
  regeneratePassword,
  openRegeneratePassword,
  openDialogRegeneratePassword,
  showErrors,
  onChangeRadioButton,
  moveItemsBetweenUsers,
  userListDialogOpened,
  fetchMoreUsers,
  closeMoveItemsDialog,
  profileImageFile,
  profileImageIcon,
  newPassword,
  openNewPassword,
  openDialogNewPassword,
  userToLooseItems,
  userToGainItems,
  moveItems,
  searchForUsers,
  chooseUserInMiniList,
  shupperCondition,
  permission
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  return (
    <React.Fragment>
      <DialogCommon
        contentStyle={{ width: "400px", height: "500px" }}
        title={"Move owned items to:"}
        actions={[
          <FlatButton
            label="Close"
            primary
            onClick={() => closeMoveItemsDialog()}
          />,
          <FlatButton
            label="Apply"
            primary
            onClick={() => {
              moveItemsBetweenUsers(userToLooseItems, userToGainItems);
              closeMoveItemsDialog();
            }}
          />
        ]}
        modal={false}
        open={userListDialogOpened}
        onRequestClose={() => closeMoveItemsDialog()}
      >
        <React.Fragment>
          <MiniList
            items={usersReducer.users.map(user => ({
              id: user._id,
              title: `${user.displayName}, ${user.location}`,
              image: user.profileImageURL
            }))}
            chosen={{ id: userToGainItems }}
            choose={chooseUserInMiniList}
            loadMore={fetchMoreUsers}
            onRequestSearch={query =>
              searchForUsers(query, ["shupper"], null, null, null)
            }
          />
        </React.Fragment>
      </DialogCommon>

      <DialogCommon
        contentStyle={{ width: "300px" }}
        title={`Are you sure regenerate password for
            ${dataUser && dataUser.displayName} ?`}
        modal={false}
        open={openRegeneratePassword}
        onRequestClose={() => {
          openDialogRegeneratePassword();
        }}
      >
        <RaisedButton
          label="Regenerate"
          primary
          style={{ marginTop: "15px", width: "100%" }}
          onClick={regeneratePassword}
        />
      </DialogCommon>
      <DialogCommon
        contentStyle={{ width: "300px" }}
        title={`New password: 
            ${newPassword} `}
        modal={false}
        open={openNewPassword}
        onRequestClose={() => {
          openDialogNewPassword();
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-3 form-group">
            <TextField
              className={classes.input}
              type="text"
              floatingLabelText={`First Name`}
              errorText={
                showErrors && dataUser && !dataUser.firstName
                  ? `Enter First Name`
                  : null
              }
              onChange={(e, v) => onChangeInput("firstName", e, v)}
              value={(dataUser && dataUser.firstName) || ""}
            />
          </div>
          <div className="col-3 form-group">
            <TextField
              className={classes.input}
              type="text"
              floatingLabelText={`Last Name`}
              errorText={
                showErrors && dataUser && !dataUser.lastName
                  ? `Enter Last Name`
                  : null
              }
              onChange={(e, v) => onChangeInput("lastName", e, v)}
              value={(dataUser && dataUser.lastName) || ""}
            />
          </div>
          <div className="col-6 form-group">
            <TextField
              className={classes.input}
              type="text"
              floatingLabelText={`Login Email`}
              errorText={
                showErrors && dataUser && !dataUser.email
                  ? "Enter Login Email"
                  : null
              }
              onChange={(e, v) => onChangeInput("email", e, v)}
              value={(dataUser && dataUser.email) || ""}
            />
          </div>
          <div className="col-6 form-group">
            <TextField
              className={classes.input}
              type="text"
              floatingLabelText={`Location`}
              errorText={
                showErrors && dataUser && !dataUser.location
                  ? `Enter Location`
                  : null
              }
              onChange={(e, v) => onChangeInput("location", e, v)}
              value={(dataUser && dataUser.location) || ""}
            />
          </div>
          <div className="col-3 form-group">
            <TextField
              className={classes.input}
              type="number"
              floatingLabelText={`Pinned Location`}
              errorText={
                showErrors && dataUser && !dataUser.pinnedLocation
                  ? `Enter Pinned Location`
                  : null
              }
              onChange={(e, v) => onChangeInput("pinnedLocation", e, v)}
              value={(dataUser && dataUser.pinnedLocation) || ""}
            />
          </div>
          <div className="col-3 form-group">
            <TextField
              className={classes.input}
              floatingLabelText={`Feed Index`}
              errorText={
                showErrors && dataUser && !dataUser.feedIndex
                  ? `Enter Feed Index`
                  : null
              }
              type="number"
              onChange={(e, v) => onChangeInput("feedIndex", e, v)}
              value={(dataUser && dataUser.feedIndex) || ""}
            />
          </div>
          <div className="col-6 form-group">
            <h5>Status</h5>
            <RadioButtonGroup
              name="discountType"
              onChange={(_, value) => {
                onChangeRadioButton("status", value);
              }}
              label="Status"
              labelPosition={"left"}
              errorText={
                showErrors && dataUser && !dataUser.status ? `Enter Status` : null
              }
              style={{ display: "flex" }}
              valueSelected={dataUser && dataUser.status}
            >
              <RadioButton
                key={1}
                value="active"
                style={{ display: "flex", flexDirection: "row" }}
                label="Active"
              />
              <RadioButton key={2} value="archived" label="Archived" />
            </RadioButtonGroup>
          </div>
          <div
            className="col-6 form-group"
            style={
              showErrors && dataUser && !dataUser.gender ? { color: "red" } : {}
            }
          >
            <h5>Gender</h5>
            <RadioButtonGroup
              name="gender"
              onChange={(_, value) => {
                onChangeRadioButton("gender", value);
              }}
              style={
                showErrors && dataUser && !dataUser.gender
                  ? {
                    display: "flex",
                    color: "red",
                    borderBottom: "1px solid red"
                  }
                  : { display: "flex" }
              }
              label={"Gender"}
              valueSelected={dataUser && dataUser.gender}
            >
              <RadioButton key={1} value="female" label="Female" />
              <RadioButton key={2} value="male" label="Male" />
              <RadioButton key={2} value="other" label="Other" />
            </RadioButtonGroup>
          </div>
          <div className="col-6 form-group border-bottom">
            <h5>Profile Pictrue</h5>
            <div
              className="profile-picture"
              style={{
                display: "flex",
                alignItems: "flex-end",
                maxWidth: "200px"
              }}
            >
              <ImagePicker
                className="flxr jsb ac personal-info-image-picker"
                imageClass="picker-preview"
                placeholderUrl=""
                style={{ maxWidth: "200px" }}
                url={
                  profileImageIcon ? null : dataUser && dataUser.profileImageURL
                }
                image={profileImageIcon ? profileImageIcon : null}
                onImageSelected={file => getNewValueImage("image", file)}
                onError={console.error}
                onFileRead={file => getNewValueImage("icon", file)}
              />
            </div>
            {profileImageFile ? (
              <FlatButton
                label="Apply"
                primary
                onClick={() => {
                  setNewImage(profileImageFile);
                }}
              />
            ) : null}
          </div>
          <div
            className="col-6 form-group border-bottom"
            style={
              showErrors && dataUser && dataUser?.roles?.length === 0
                ? { color: "red" }
                : {}
            }
          >
            <div
              style={
                showErrors && dataUser && dataUser?.roles?.length === 0
                  ? { borderBottom: "1px solid red" }
                  : {}
              }
            >
              <h5>Roles</h5>
              <Checkbox
                label="User"
                checked={
                  dataUser && dataUser?.roles?.indexOf("user") + 1 ? true : false
                }
                // onCheck={() => onChangeCheckbox("roles", "user")}
                style={{ margin: "20px 0" }}
              />
              <Checkbox
                label="Shupper"
                disabled={!permission.set_shupper_supershupper}
                checked={
                  dataUser && dataUser?.roles?.indexOf("shupper") + 1 ? true : false
                }
                onCheck={() => onChangeCheckbox("roles", "shupper")}
                style={{ margin: "20px 0" }}
              />
              <Checkbox
                label="Super Shupper"
                disabled={!permission.set_shupper_supershupper}
                checked={
                  dataUser && dataUser?.roles?.indexOf("supershupper") + 1
                    ? true
                    : false
                }
                onCheck={() => onChangeCheckbox("roles", "supershupper")}
                style={{ margin: "20px 0" }}
              />
              <Checkbox
                label="Influencer"
                disabled={!permission.set_shupper_supershupper}
                checked={
                  dataUser && dataUser?.roles?.indexOf("influencer") + 1
                    ? true
                    : false
                }
                onCheck={() => onChangeCheckbox("roles", "influencer")}
                style={{ margin: "20px 0" }}
              />
            </div>
          </div>
          <div className="col-4 pointer  form-group">
            <h4 className=" action-button" onClick={openDialogRegeneratePassword}>
              Regenerate password
            </h4>
          </div>
          <div className="col-4 pointer  form-group">
            <h4
              className="action-button-border action-button"
              onClick={ev => {
                if (shupperCondition) {
                  moveItems(ev);
                }
              }}
            >
              Move items
            </h4>
          </div>
          <div className="col-4 pointer  form-group">
            <h4
              className=" action-button"
              onClick={() => {
                if (shupperCondition) {
                  navigate("inaccessibility/" + dataUser._id);
                }
              }}
            >
              Setup duty off
            </h4>
          </div>
          <div className="col-6 border-top">
            <h5>Date created</h5>
          </div>
          <div className="col-6 text-right border-top">
            {moment(dataUser && dataUser.created).format("MMMM Do YYYY")}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default PersonalInfoOld;