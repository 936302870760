/*
Kateryna Ivanova
30/03/2018

Reducer for a single bo user
*/
import types from '../actions/actionTypes';

const initialState = {
  userProfile: null,
  isLoading: false,
};

export default function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USER_PROFILE_DATA:
      return Object.assign({}, state, {
        isLoading: true,
        userProfile: null,
      });
    case types.FETCH_USER_PROFILE_DATA_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        userProfile: action.data,
      });
    case types.FETCH_USER_PROFILE_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case types.UPDATE_USER_PROFILE_INFO:
      return Object.assign({}, state, {
        isLoading: true,
        userProfile: null,
      });
    case types.UPDATE_USER_PROFILE_INFO_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        userProfile: action.data,
      });
    case types.UPDATE_USER_PROFILE_INFO_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.UPDATE_USER_PROFILE_SHIPPING:
      return Object.assign({}, state, {
        isLoading: true,
        userProfile: null,
      });
    case types.UPDATE_USER_PROFILE_SHIPPING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        userProfile: action.data,
      });
    case types.UPDATE_USER_PROFILE_SHIPPING_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });

    default:
      return state;
  }
}
