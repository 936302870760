import React, { useState, useEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import SearchField from '../components/searchfield';
import { getPermission } from '../utils/cookies';
import NoPermission from '../components/nopermission';
import { decodePermissions } from '../utils/permissionUtil';
import '../static/styles/userlist.css';
import { tableData } from '../utils/tableData';
import { IconButton, makeStyles, Menu, Typography } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Filters } from '../components/filters';
import calendar from '../static/images/calendar.svg'
import { Buttons } from '../components/filters/buttons';
import { CalendarRangePicker } from '../components/dateRangePicker';
import TableData from '../components/table/index';
import { filtersObj } from '../utils/filtersData';
import { useSelector } from 'react-redux';
import { fetchMoreOrders, fetchOrders } from '../actions/ordersActions';
import { useDispatch } from 'react-redux';
import { withRouter } from '../utils/withRouter';

const useStyles = makeStyles({
  paper: {
    padding: 15,
  },
  title: {
    marginLeft: 12,
  },
  calendarBnts: {
    marginLeft: '40%'
  },
  calendar: {
    backgroundColor: '#141B41',
    borderRadius: '8px',
    padding: 18,
    height: 48,
    width: 180,
    margin: '10px 12px',
    '&:hover': {
      backgroundColor: '#141B41',
    }
  },
  calendarText: {
    color: 'white',
    marginRight: 55
  },
  table: {
    padding: 13,
  }
});

const Orders = ({ ordersReducer, onOpenDrawer, ...props }) => {
  const classes = useStyles()
  const permission = decodePermissions(getPermission());
  const { orders } = useSelector(({ ordersReducer }) => ordersReducer)
  const dispatch = useDispatch()

  const [date, setDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);
  const [state, setState] = React.useState({
    filterMenu: null
  });
  const [filters, setFilters] = React.useState()
  const { filterMenu, filterName } = state
  const { payments: { paymentsList } } = tableData
  const { paymentFilters } = filtersObj

  useEffect(() => {
    if (permission.users) {
      dispatch(fetchOrders());
    }
  }, [])

  const toggleFilter = React.useCallback((e) => {
    setState({ filterMenu: e })
  }, [filterMenu])

  const doSearch = (query, paymentFilter, packageFilter, packageSubFilter, shupperCountry, userCountry, dateFilter) => {
    const data = {
      query,
      paymentFilter,
      packageFilter,
      packageSubFilter,
      shupperCountry,
      userCountry,
      dateFilter
    }
    dispatch(fetchOrders(data))
  }

  const handleDateRange = (ranges) => {
    const { selection } = ranges;
    setDate([selection]);
  }

  const handleChange = (filterName, value, id, itemName, substate, subId) => (event) => {

    setFilters(prevState => ({
      ...prevState,
      [itemName ? itemName : value]:
        { [filterName]: event.target.checked, value: itemName ? value : 'default', id, substate: substate ?? 'default', subId: subId ?? 'default' }
    }))
  }

  const filterNotSelected = (filterName) => {
    return Object.keys(filterName)?.some((item) => !filterName[item])
  }

  const applyFilters = () => {
    const dateObj = {
      from: date[0]?.startDate?.toLocaleDateString("en-US"),
      to: date[0]?.endDate?.toLocaleDateString("en-US")
    }

    const shupperLocation = filters?.['shupper'] && filterNotSelected(filters?.['shupper']) ? '' : filters?.['shupper']?.id
    const userLocation = filters?.['user'] && filterNotSelected(filters?.['user']) ? '' : filters?.['user']?.id
    const paymentFilter = filters?.['payment'] && filterNotSelected(filters?.['payment']) ? '' : filters?.['payment']?.id
    const packageFilter = filters?.['package'] && filterNotSelected(filters?.['package']) ? '' : filters?.['package']?.subId
    const packageSubFilter = filters?.['package'] && filterNotSelected(filters?.['package']) ? '' : filters?.['package']?.substate
    doSearch(null, paymentFilter, packageFilter, packageSubFilter, shupperLocation, userLocation, dateObj)
  }

  return permission.users ?
    <div>
      <div className="App-header">
        <IconButton onClick={onOpenDrawer}>
          <MenuIcon color='primary' />
        </IconButton>
        <span className="header-placeholder" />
      </div>
      <div className={classes.paper}>
        <Typography variant="h5" className={classes.title}>PAYMENT REQUESTS</Typography>
        <div className="searchFilterContainer">
          <div className='calendar'>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              onClick={e => toggleFilter(e.currentTarget)}
              aria-controls="profile-menu"
              className={classes.calendar}
            >
              <img src={calendar} style={{ marginRight: 35 }} />
              <div>
                <Typography className={classes.calendarText}>Today</Typography>
              </div>
            </IconButton>
            <Menu
              id="menu-appbar"
              getContentAnchorEl={null}
              open={Boolean(filterMenu)}
              anchorEl={filterMenu}
              onClose={() => setState({ filterMenu: null })}
              disableAutoFocusItem
              PaperProps={{
                className: classes.filterMenu
              }}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}>
              <div >
                <CalendarRangePicker date={date} handleChange={handleDateRange} />
              </div>
              <div className={classes.calendarBnts}>
                <Buttons setFilterMenu={() => setState({ filterMenu: null })} applyFilters={applyFilters} date={date} />
              </div>
            </Menu>
          </div>
          <div className='userFilters'>
            <Filters
              filters={filters}
              name={'orders'}
              filterItems={paymentFilters}
              applyFilters={applyFilters}
              handleChange={handleChange} />
          </div>
          <div className='userSearch' style={{ paddingTop: 12 }}>
            <SearchField placeholder='Search by Payment request ID, Package ID, Shupper/User name or email' doneColor={''} onRequestSearch={query => doSearch(query, null, null, null, null)} />
          </div>
        </div>
        <div className={`${classes.table} usersTable`} >
          <TableData
            width='100%'
            tableName='payments'
            tablePagination={true}
            data={orders || []}
            headerColumn={paymentsList}
            fetchMore={() => dispatch(fetchMoreOrders())}
          /></div>
      </div>
    </div >
    : <NoPermission onOpenDrawer={onOpenDrawer} />
}

export default withRouter(Orders)