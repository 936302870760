import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from 'query-string';
import { connect } from "react-redux";
import moment from "moment";
import SocialPersonAdd from "material-ui/svg-icons/social/person-add";
import AddItem from "material-ui/svg-icons/av/library-add";
import FlatButton from "material-ui/FlatButton";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import TextField from "material-ui/TextField";
import DatePicker from "material-ui/DatePicker";
import TimePicker from "material-ui/TimePicker";
import Chip from "material-ui/Chip";
import Avatar from "material-ui/Avatar";
import RaisedButton from "material-ui/RaisedButton";
import CircularProgress from "material-ui/CircularProgress";
import Checkbox from "material-ui/Checkbox";
import IconButton from "material-ui/IconButton";
import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import ImagePicker from "../components/imagepicker";
import ValuePicker from "../components/valuePicker";
import NoPermission from "../components/nopermission";
import MiniList from "../components/MiniList";
import { getPermission } from "../utils/cookies";
import { decodePermissions } from "../utils/permissionUtil";
import { validateUrl } from "../utils/utils";
import withConfirm from "../utils/withConfirm";
import * as itemsActions from "../actions/itemsactions";
import * as eventActions from "../actions/eventactions";
import * as eventsActions from "../actions/eventsactions";
import { reshuffleEvent } from "../actions/eventsactions";
import * as userActions from "../actions/usersactions";
import * as storesActions from "../actions/storesactions";
import * as collectionsActions from "../actions/collectionsactions";
import TabsDialog from "../components/TabsDialog";

import "../static/styles//event.css";
import { withRouter } from "../utils/withRouter";

class Event extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      id: null,
      shupperPickerOpen: false,
      storePickerOpen: false,
      shuppers: [],
      stores: [],
      dateStart: null,
      dateEnd: null,
      title: null,
      profileImageURL: null,
      errorTextTitle: null,
      errorTextDateStart: null,
      errorTextDateEnd: null,
      errorWithDates: null,
      pinLocation: true,
      gpsLocation: null,
      selectedProfileImage: null,
      fileProfile: null,
      fileCover: null,
      type: null,
      description: null,
      about: null,
      fileSizeError: false,
      location: null,
      items: [],
      itemPickerOpen: false,
      password: null,
      addLinkDialogOpened: false,
      chosenItemForLink: null,
      chosenType: null,
      urlValid: false
    };
  }
  locationQuery = queryString.parse(this?.props?.location?.search).id
  componentDidMount() {
    this.callFetch(this.locationQuery);
  }

  componentWillReceiveProps(newProps) {
    const { id } = this.state;
    const {
      eventReducer: { event }
    } = newProps;
    if (
      newProps.eventReducer &&
      newProps.eventReducer.event &&
      newProps.eventReducer.event._id !== id
    ) {
      this.setState({
        id: event._id,
        dateStart: event.dateStart,
        dateEnd: event.dateEnd,
        title: event.title,
        profileImageURL: event.imageUrl,
        gpsLocation:
          event.position &&
            event.position.coordinates &&
            event.position.coordinates.length === 2
            ? event.position.coordinates
            : null,
        shuppers: event.shuppers,
        stores: event.stores,
        type:
          event.type === "event" || event.type === "checkin"
            ? event.type
            : "event",
        description: event.description,
        about: event.about,
        location: event.location,
        items: event.items || [],
        password: event.password,
        linkTo: event.linkTo
      });
    }
  }

  onSelectStore(store) {
    const stores = this.state.stores.filter(item => item._id !== store._id);
    stores.push(store);
    this.setState({ stores });
  }

  onSelectItem(newItem) {
    const items = this.state.items.filter(item => item._id !== newItem._id);
    items.push(newItem);
    this.setState({ items });
  }

  onFileError = () => this.setState({ fileSizeError: true });

  setEndDate(date) {
    const updated = this.changeDateInMoment(this.state.dateEnd, date);
    this.setState({ dateEnd: updated.format(), errorTextDateEnd: null });
  }

  setEndTime(date) {
    const updated = this.changeTimeInMoment(this.state.dateEnd, date);
    this.setState({ dateEnd: updated.format(), errorTextDateEnd: null });
  }

  setStartDate(date) {
    const updated = this.changeDateInMoment(this.state.dateStart, date);
    this.setState({
      dateStart: updated.format(),
      errorTextDateStart: null,
      errorWithDates: null
    });
  }

  setStartTime(date) {
    const updated = this.changeTimeInMoment(this.state.dateStart, date);
    this.setState({
      dateStart: updated.format(),
      errorTextDateStart: null,
      errorWithDates: null
    });
  }

  removeShupper(shupper) {
    const shuppers = this.state.shuppers.filter(
      item => item._id !== shupper._id
    );
    this.setState({ shuppers });
  }

  removeStore(store) {
    const stores = this.state.stores.filter(item => item._id !== store._id);
    this.setState({ stores });
  }

  removeItem(oldItem) {
    const items = this.state.items.filter(item => item._id !== oldItem._id);
    this.setState({ items });
  }

  changeTimeInMoment = (baseTime, newTime) => {
    const baseMoment = baseTime ? moment(baseTime) : moment();
    const newMoment = moment(newTime);
    baseMoment.set("hour", newMoment.get("hour"));
    baseMoment.set("minute", newMoment.get("minute"));
    return baseMoment;
  };

  changeDateInMoment = (baseDate, newDate) => {
    const baseMoment = baseDate ? moment(baseDate) : moment();
    const newMoment = moment(newDate);
    baseMoment.set("date", newMoment.get("date"));
    baseMoment.set("month", newMoment.get("month"));
    baseMoment.set("year", newMoment.get("year"));
    return baseMoment;
  };

  shupperSelected(shupper) {
    const shuppers = this.state.shuppers.filter(
      item => item._id !== shupper._id
    );
    shuppers.push(shupper);
    this.setState({ shuppers });
  }

  callFetch(id) {
    if (this.state.permission.events) {
      if (id) {
        this.props.fetchEvent(id);
      } else {
        this.setState({ id: "new", type: "event" });
      }
    }
  }

  closeLinkDialog = () => {
    this.setState({
      addLinkDialogOpened: false
    });
  };

  openLinkDialog = () => {
    this.setState({
      addLinkDialogOpened: true
    });
  };

  verifyData() {
    const { title, dateStart, dateEnd } = this.state;
    if (!title) {
      this.setState({ errorTextTitle: "This field is required" });
      return false;
    }
    if (!dateStart) {
      this.setState({ errorTextDateStart: "This field is required" });
      return false;
    }
    if (!dateEnd) {
      this.setState({ errorTextDateEnd: "This field is required" });
      return false;
    }
    if (moment(dateStart).isAfter(moment(dateEnd))) {
      this.setState({ errorWithDates: "Start date is after the end date" });
      return false;
    }

    return true;
  }

  requestUpdate = () => {
    const { updateEvent, createEvent } = this.props;
    const {
      id,
      title,
      dateStart,
      dateEnd,
      shuppers,
      stores,
      gpsLocation,
      pinLocation,
      fileProfile,
      fileCover,
      type,
      description,
      about,
      location,
      items,
      password,
      linkTo
    } = this.state;

    if (!this.verifyData()) {
      window.scrollTo(0, 0);
      return;
    }

    const params = {
      _id: id === "new" ? null : this.locationQuery,
      title,
      dateStart,
      dateEnd,
      shuppers: shuppers.map(shupper => shupper._id) || [],
      stores: stores.map(store => store._id) || [],
      fileProfile,
      fileCover,
      coordinates: pinLocation ? gpsLocation : null,
      type,
      description,
      about,
      location,
      items: items.map(item => item._id || []),
      password,
      linkTo
    };
    if (id === "new") {
      createEvent(params);
    } else {
      updateEvent(params);
    }
  };
  // add link modal methods //

  chooseItemForLink = type => id =>
    this.setState({
      chosenItemForLink: id,
      chosenType: type
    });

  searchForUsers(query, roles, roleFilter, statusFilter, genderFilter) {
    this.props.fetchUsers(query, roles, roleFilter, statusFilter, genderFilter);
  }

  // end add link modal methods //

  render() {
    const {
      eventReducer,
      usersReducerOld,
      storesReducer,
      fetchStores,
      fetchMoreStores,
      onOpenDrawer,
      itemsReducer,
      fetchItems,
      fetchMoreItems,
      getOnlyShuppers,
      reshuffleEvent,
      fetchMoreUsers,
      collectionsReducer,
      fetchCollections,
      fetchMoreCollections,
      eventsReducer,
      fetchBrands,
      fetchMoreBrands
    } = this.props;
    const {
      id,
      shuppers,
      stores,
      dateStart,
      dateEnd,
      title,
      errorTextTitle,
      errorTextDateStart,
      errorTextDateEnd,
      errorWithDates,
      pinLocation,
      profileImageURL,
      selectedProfileImage,
      permission,
      type,
      description,
      about,
      fileSizeError,
      location,
      items,
      password,
      addLinkDialogOpened,
      chosenItemForLink,
      chosenType,
      linkTo
    } = this.state;
    const showPasswordField = type === "checkin";

    return permission.events ? (
      id && (
        <div>
          <div className="App-header">
            <IconButton onClick={() => this.props.navigate(-1)}>
              <NavigationArrowBack color="white" />
            </IconButton>
          </div>
          <form className="event-container">
            <TextField
              style={{ width: "100%" }}
              floatingLabelText="Event name"
              errorText={errorTextTitle}
              onChange={ev =>
                this.setState({
                  title: ev.target.value,
                  errorTextTitle: null
                })
              }
              value={title}
            />
            {errorWithDates && (
              <div className="message-box error-message">{errorWithDates}</div>
            )}
            <div className="date-container">
              <DatePicker
                floatingLabelText="Start date"
                errorText={errorTextDateStart}
                onChange={(_, date) => this.setStartDate(date)}
                value={dateStart && moment(dateStart).toDate()}
              />
              <TimePicker
                format="24hr"
                floatingLabelText="Start time"
                onChange={(_, date) => this.setStartTime(date)}
                value={dateStart && moment(dateStart).toDate()}
              />
            </div>
            <div className="date-container">
              <DatePicker
                floatingLabelText="End date"
                errorText={errorTextDateEnd}
                onChange={(_, date) => this.setEndDate(date)}
                value={dateEnd && moment(dateEnd).toDate()}
              />
              <TimePicker
                format="24hr"
                floatingLabelText="End time"
                onChange={(_, date) => this.setEndTime(date)}
                value={dateEnd && moment(dateEnd).toDate()}
              />
            </div>
            <TextField
              style={{ width: "100%" }}
              floatingLabelText="Event description"
              onChange={ev => this.setState({ description: ev.target.value })}
              value={description}
            />
            {type === "checkin" ? (
              <TextField
                style={{ width: "100%" }}
                floatingLabelText="Brand 'about' field"
                onChange={ev => this.setState({ about: ev.target.value })}
                value={about}
                multiLine={true}
              />
            ) : null}
            <RadioButtonGroup
              style={{ margin: "16px 0" }}
              valueSelected={type}
              onChange={(_, typeValue) => this.setState({ type: typeValue })}
            >
              <RadioButton value="event" label="Event" />
              <RadioButton value="checkin" label="Checkin event" />
            </RadioButtonGroup>
            {showPasswordField && (
              <TextField
                style={{ width: "100%" }}
                floatingLabelText="Checkin password"
                onChange={ev => this.setState({ password: ev.target.value })}
                value={password}
              />
            )}
            <div className="pick-images">
              <ImagePicker
                url={profileImageURL}
                image={selectedProfileImage}
                onImageSelected={file =>
                  this.setState({
                    fileProfile: file,
                    fileSizeError: false
                  })
                }
                onError={this.onFileError}
                onFileRead={im => this.setState({ selectedProfileImage: im })}
              />
            </div>
            <div className="label">
              <span>Add shuppers</span>
              <FlatButton
                onClick={() => this.setState({ shupperPickerOpen: true })}
                style={{ marginLeft: "10px" }}
                icon={<SocialPersonAdd />}
              />
            </div>
            <div className="chip-container">
              {shuppers.map(shupper => (
                <Chip
                  key={shupper._id}
                  onRequestDelete={() => this.removeShupper(shupper)}
                  style={{ margin: 4 }}
                >
                  <Avatar src={shupper.profileImageURL} />
                  {`${shupper.displayName}`}
                </Chip>
              ))}
            </div>
            <div className="label">
              <span>Add stores</span>
              <FlatButton
                onClick={() => this.setState({ storePickerOpen: true })}
                style={{ marginLeft: "10px" }}
                icon={<SocialPersonAdd />}
              />
            </div>
            <div className="chip-container">
              {stores.map(store => (
                <Chip
                  key={store._id}
                  onRequestDelete={() => this.removeStore(store)}
                  style={{ margin: 4 }}
                >
                  <Avatar src={store.profileImageURL} />
                  {store.displayName}
                </Chip>
              ))}
            </div>
            <div className="label">
              <span>Add items</span>
              <FlatButton
                onClick={() => this.setState({ itemPickerOpen: true })}
                style={{ marginLeft: "10px" }}
                icon={<SocialPersonAdd />}
              />
            </div>
            <div className="chip-container">
              {items.map(item => (
                <Chip
                  key={item._id}
                  onRequestDelete={() => this.removeItem(item)}
                  style={{ margin: 4 }}
                >
                  <Avatar src={item.imageUrl} />
                  {item.description}
                </Chip>
              ))}
            </div>
            {type !== "checkin" ? (
              linkTo && linkTo.data ? (
                <React.Fragment>
                  <div className="label">
                    <span>Banner link:</span>
                  </div>
                  <div className="chip-container">
                    <Chip
                      key={linkTo.data}
                      onRequestDelete={() => {
                        this.setState({ linkTo: null });
                      }}
                      style={{ margin: 4 }}
                    >
                      <Avatar src={linkTo.imageUrl ? linkTo.imageUrl : null} />
                      {linkTo.type + ": " + (linkTo.displayName || linkTo.data)}
                    </Chip>
                  </div>
                </React.Fragment>
              ) : (
                <div className="label">
                  <span>Add press result</span>
                  <FlatButton
                    onClick={this.openLinkDialog}
                    style={{ marginLeft: "10px" }}
                    icon={<AddItem />}
                  />
                </div>
              )
            ) : null}
            <Checkbox
              label="Pin Location"
              checked={pinLocation}
              onCheck={() => this.setState({ pinLocation: !pinLocation })}
              style={{ margin: "20px 0" }}
            />
            <TextField
              style={{ width: "100%" }}
              floatingLabelText="Location"
              onChange={ev => this.setState({ location: ev.target.value })}
              value={location}
            />
            {eventReducer.updateSuccess && (
              <div className="message-box success-message">
                {`Event is successfully ${id === "new" ? "created" : "updated"
                  }`}
              </div>
            )}
            {eventReducer.updateError && (
              <div className="message-box error-message">
                {eventReducer.updateError}
              </div>
            )}
            <div className="save-btn">
              {id !== "new" ? (
                <RaisedButton
                  backgroundColor="#16cd2f"
                  onClick={reshuffleEvent(id)}
                  labelStyle={{ color: "#fff" }}
                  label={"Reshuffle items"}
                />
              ) : null}
            </div>
            <div className="save-btn">
              {eventReducer.isUpdating ? (
                <CircularProgress />
              ) : (
                <RaisedButton
                  disabled={fileSizeError}
                  backgroundColor="#16cd2f"
                  onClick={() => {
                    if (!fileSizeError) withConfirm(this.requestUpdate);
                  }}
                  labelStyle={{ color: "#fff" }}
                  label={id === "new" ? "Create event" : "Save changes"}
                />
              )}
            </div>
          </form>
          <ValuePicker
            open={this.state.shupperPickerOpen}
            title="Enter the shupper's name"
            isLoading={usersReducerOld.isLoading}
            selected={shuppers.map(shupper => `${shupper.displayName}`)}
            items={usersReducerOld.shuppersList.map(user => ({
              ...user,
              id: user._id,
              title: `${user.displayName}, ${user.location}`,
              image: user.profileImageURL
            }))}
            onSearchValue={query => getOnlyShuppers(query)}
            onRequestClose={() => this.setState({ shupperPickerOpen: false })}
            onSelectValue={item => this.shupperSelected(item)}
            hasMore={false}
            onLoadMore={() => { }}
          />
          <ValuePicker
            open={this.state.storePickerOpen}
            title="Enter the store name"
            isLoading={storesReducer.isLoading}
            selected={stores.map(store => store.displayName)}
            items={storesReducer.stores.map(store => ({
              ...store,
              id: store._id,
              title: store.displayName,
              image: store.profileImageURL
            }))}
            onSearchValue={query => fetchStores(query)}
            onRequestClose={() => this.setState({ storePickerOpen: false })}
            onSelectValue={item => this.onSelectStore(item)}
            hasMore={storesReducer.hasMore}
            onLoadMore={() => fetchMoreStores()}
          />
          <ValuePicker
            open={this.state.itemPickerOpen}
            title="Enter item description"
            isLoading={itemsReducer.isLoading}
            selected={items.map(item => item.description)}
            items={itemsReducer.items.map(item => ({
              ...item,
              id: item._id,
              title: item.description,
              image: item.imageUrl
            }))}
            onSearchValue={query => fetchItems(query)}
            onRequestClose={() => this.setState({ itemPickerOpen: false })}
            onSelectValue={item => this.onSelectItem(item)}
            hasMore={itemsReducer.hasMore}
            onLoadMore={() => fetchMoreItems()}
          />
          {type !== "checkin" ? (
            <TabsDialog
              tabs={[
                "Shupper",
                "Item",
                "Collections",
                "Store",
                "Brand",
                "External"
              ]}
              contents={[
                <MiniList
                  items={usersReducerOld.users.map(user => ({
                    id: user._id,
                    title: `${user.displayName}, ${user.location}`,
                    image: user.profileImageURL
                  }))}
                  chosen={chosenItemForLink}
                  choose={this.chooseItemForLink("shupper")}
                  loadMore={fetchMoreUsers}
                  onRequestSearch={query =>
                    this.searchForUsers(query, ["shupper"], null, null, null)
                  }
                />,
                <MiniList
                  items={itemsReducer.items.map(item => ({
                    id: item._id,
                    title: item.description,
                    image: item.imageUrl
                  }))}
                  chosen={chosenItemForLink}
                  choose={this.chooseItemForLink("item")}
                  loadMore={fetchMoreItems}
                  onRequestSearch={query => fetchItems(query)}
                />,
                <MiniList
                  items={collectionsReducer.collections.map(item => ({
                    id: item._id,
                    title: item.description,
                    image: null
                  }))}
                  chosen={chosenItemForLink}
                  choose={this.chooseItemForLink("collection")}
                  loadMore={fetchMoreCollections}
                  onRequestSearch={query => fetchCollections(query)}
                />,
                <MiniList
                  items={storesReducer.stores.map(store => ({
                    id: store._id,
                    title: store.displayName,
                    image: store.profileImageURL
                  }))}
                  chosen={chosenItemForLink}
                  choose={this.chooseItemForLink("store")}
                  loadMore={fetchMoreStores}
                  onRequestSearch={query => fetchStores(query)}
                />,
                <MiniList
                  items={eventsReducer.events.map(brand => ({
                    id: brand._id,
                    title: brand.title,
                    image: brand.imageUrl
                  }))}
                  chosen={chosenItemForLink}
                  choose={this.chooseItemForLink("brand")}
                  loadMore={fetchMoreBrands}
                  onRequestSearch={query => fetchBrands(query)}
                />,
                <div className="flxc ac jc external-input-container">
                  <input
                    value={chosenItemForLink ? chosenItemForLink.id : ""}
                    type="text"
                    className="external-link"
                    onChange={({ target }) =>
                      this.setState({
                        chosenType: "external",
                        chosenItemForLink: { id: target.value },
                        urlValid: validateUrl(target.value)
                      })
                    }
                  />
                  <span
                    style={{
                      display: this.state.urlValid ? "none" : "block",
                      color: "red"
                    }}
                  >
                    Enter valid url
                  </span>
                </div>
              ]}
              caption="Add Link To Event"
              display={addLinkDialogOpened}
              close={this.closeLinkDialog}
              width={800}
              height={400}
              actions={[
                <FlatButton
                  label="Close"
                  primary
                  onClick={this.closeLinkDialog}
                />,
                <FlatButton
                  label="Done"
                  primary
                  onClick={() => {
                    if (
                      (this.state.chosenType === "external" &&
                        this.state.urlValid) ||
                      this.state.chosenType !== "external"
                    ) {
                      this.setState({
                        linkTo: {
                          type: chosenType,
                          data: chosenItemForLink.id,
                          displayName: chosenItemForLink.title,
                          imageUrl: chosenItemForLink.image
                        }
                      });
                      this.closeLinkDialog();
                    }
                  }}
                />
              ]}
            />
          ) : null}
        </div>
      )
    ) : (
      <NoPermission onOpenDrawer={onOpenDrawer} />
    );
  }
}

Event.propTypes = {
  fetchEvent: PropTypes.func.isRequired,
  fetchStores: PropTypes.func.isRequired,
  fetchMoreStores: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  fetchMoreItems: PropTypes.func.isRequired,
  eventReducer: PropTypes.object.isRequired,
  usersReducerOld: PropTypes.object.isRequired,
  storesReducer: PropTypes.object.isRequired,
  itemsReducer: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onOpenDrawer: PropTypes.object.isRequired,
  getOnlyShuppers: PropTypes.func.isRequired
};

const mapStateToProps = ({
  eventReducer,
  usersReducerOld,
  storesReducer,
  itemsReducer,
  collectionsReducer,
  eventsReducer
}) => ({
  eventReducer,
  usersReducerOld,
  storesReducer,
  itemsReducer,
  collectionsReducer,
  eventsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchEvent: id => dispatch(eventActions.fetchEvent(id)),
  fetchStores: query => dispatch(storesActions.fetchStores(query)),
  fetchMoreStores: () => dispatch(storesActions.fetchMoreStores()),
  fetchCollections: query =>
    dispatch(collectionsActions.fetchCollections({ name: query })),
  fetchMoreCollections: () =>
    dispatch(collectionsActions.fetchMoreCollections()),
  updateEvent: event => dispatch(eventActions.updateEvent(event)),
  createEvent: event => dispatch(eventActions.createEvent(event)),
  fetchItems: query => dispatch(itemsActions.fetchItems({ name: query })),
  fetchMoreItems: () => dispatch(itemsActions.fetchMoreItems()),
  getOnlyShuppers: name => dispatch(userActions.getOnlyShuppers(name)),
  reshuffleEvent: id => () => dispatch(reshuffleEvent(id)),
  fetchMoreUsers: () => dispatch(userActions.fetchMoreUsers()),
  fetchUsers: (query, roles, roleFilter, statusFilter, genderFilter) =>
    dispatch(
      userActions.fetchUsers(
        query,
        roles,
        roleFilter,
        statusFilter,
        genderFilter
      )
    ),
  fetchBrands: query =>
    dispatch(eventsActions.fetchEventsFilterByType("checkin", query)),
  fetchMoreBrands: () => dispatch(eventsActions.fetchMoreEvents()),
  dispatch
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Event));
