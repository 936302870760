/*
Kateryna Ivanova
28/03/2018

Collections list page
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import DatePicker from 'material-ui/DatePicker';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import Dialog from 'material-ui/Dialog';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import SocialPersonAdd from 'material-ui/svg-icons/social/person-add';
import FlatButton from 'material-ui/FlatButton';
import Avatar from 'material-ui/Avatar';
import Chip from 'material-ui/Chip';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import ValuePicker from '../components/valuePicker';
import * as usersActions from '../actions/usersactions';
import * as itemActions from '../actions/itemactions';
import withConfirm from '../utils/withConfirm';
import ImagePicker from '../components/imagepicker';
import Checkbox from 'material-ui/Checkbox';

import '../static/styles//item.css';
import { withRouter } from '../utils/withRouter';


class Item extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      id: null,
      description: null,
      created: null,
      errorDescription: '',
      updated: null,
      ownerPickerOpen: false,
      owner: null,
      videoModalOpen: false,
      imageUrl: null,
      selectedProfileImage: null,
      fileProfile: null,
      fileSizeError: false,
      itemVideo: null,
    };
  }
  locationQuery = queryString.parse(this?.props?.location?.search).id

  componentDidMount() {
    if (this.state.permission.items) {
      if (this.locationQuery) {
        this.props.fetchItem(this.locationQuery);
      } else {
        this.setState({ id: 'new' });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { id } = this.state;
    if (newProps.itemReducer && newProps.itemReducer.item && newProps.itemReducer.item._id !== id) {
      const { itemReducer: { item } } = newProps;
      this.setState({
        id: item._id,
        description: item.description,
        created: item.created,
        updated: item.updated,
        owner: item.owner,
        imageUrl: item.imageUrl,
        isInEvent: item.isInEvent
      });
    }
  }

  validateData() {
    const { description } = this.state;
    if (!description) {
      this.setState({ errorDescription: 'Description should not be empty' });
      return false;
    }
    return true;
  }

  requestUpdate = () => {
    if (!this.validateData()) {
      return;
    }
    const {
      id, description, created, updated, owner, fileProfile, itemVideo, isInEvent
    } = this.state;
    this.props.updateItem({
      _id: id, description, created, updated, owner, fileProfile, itemVideo, isInEvent
    });
  };

  ownerSelected = (owner) => {
    this.setState({ owner });
  };

  onOpenModal = () => {
    this.setState({ videoModalOpen: true });
  };

  onCloseModal = () => {
    this.setState({
      videoModalOpen: false,
    });
  };

  onFileError = () => this.setState({ fileSizeError: true });

  render() {
    const {
      itemReducer, onOpenDrawer, usersReducerOld, getOnlyShuppersAndStores,
    } = this.props;
    const {
      description, created, errorDescription, permission, updated, owner,
      videoModalOpen, imageUrl, selectedProfileImage, isInEvent
    } = this.state;

    return permission.items ?
      <div>
        <div className="App-header">
          <IconButton onClick={() => this.props.navigate(-1)}>
            <NavigationArrowBack color="white" />
          </IconButton>
        </div>
        {itemReducer.item
          ?
          <form className="item-container">
            <div className="pick-images">
              <ImagePicker
                url={imageUrl}
                image={selectedProfileImage}
                onImageSelected={file => this.setState({
                  fileProfile: file,
                  fileSizeError: false,
                })}
                onError={this.onFileError}
                onFileRead={im => this.setState({ selectedProfileImage: im })}
              />
            </div>
            {itemReducer.item.videoUrl &&
              <div>
                <a href={null} role="button" onClick={event => {
                  event.preventDefault();
                  this.onOpenModal();
                }}>
                  Watch Video
                </a>
              </div>
            }
            <div>
              <label>
                Upload video:
                <input
                  type="file"
                  name="itemVideo"
                  accept="video/*"
                  onChange={ev => this.setState({ itemVideo: ev.target.files[0] })}
                />
              </label>
            </div>
            <TextField
              style={{ width: '100%' }}
              floatingLabelText="Description"
              errorText={errorDescription}
              onChange={ev => this.setState({ description: ev.target.value })}
              value={description}
            />
            <DatePicker
              style={{ marginTop: '10px' }}
              value={created && moment(created).toDate()}
              onChange={(_, date) => this.setState({ created: moment(date).format() })}
              floatingLabelText="Created Date"
            />
            <DatePicker
              style={{ marginTop: '10px' }}
              value={updated && moment(updated).toDate()}
              onChange={(_, date) => this.setState({ updated: moment(date).format() })}
              floatingLabelText="Updated Date"
            />
            <div className="label">
              <span>Edit owner</span>
              <FlatButton
                onClick={() => this.setState({ ownerPickerOpen: true })}
                style={{ marginLeft: '10px' }}
                icon={<SocialPersonAdd />}
              />
            </div>
            <div className="chip-container">
              <Chip
                disabled
                style={{ margin: 4 }}
              >
                {owner &&
                  <Avatar src={owner.profileImageURL} />
                }
                {`${owner.displayName}`}
              </Chip>
            </div>
            <Checkbox
              label="Is in Event Only"
              checked={isInEvent}
              onCheck={() => this.setState({ isInEvent: !isInEvent })}
              style={{ margin: '20px 0' }}
            />
            {itemReducer.updateSuccess &&
              <div className="message-box success-message">
                Item is successfully updated
              </div>
            }
            {itemReducer.updateError &&
              <div className="message-box error-message">
                {itemReducer.updateError}
              </div>
            }
            <div className="save-btn">
              {itemReducer.isUpdating
                ?
                <CircularProgress />
                :
                <RaisedButton
                  backgroundColor="#16cd2f"
                  onClick={() => withConfirm(this.requestUpdate)}
                  labelStyle={{ color: '#fff' }}
                  label="Save changes"
                />
              }
            </div>
            <Dialog
              modal={false}
              open={videoModalOpen}
              onRequestClose={this.onCloseModal}
              contentStyle={{ width: '450px' }}
            >
              <video src={itemReducer.item.videoUrl} controls width="400" height="400">
                Sorry, your browser doesn't support embedded videos,
                but don't worry, you can <a href={itemReducer.item.videoUrl}>download it</a>
                and watch it with your favorite video player!
              </video>
            </Dialog>
          </form>
          : null}
        <ValuePicker
          open={this.state.ownerPickerOpen}
          title="Enter the shupper's name"
          isLoading={usersReducerOld.isLoading}
          selected={owner ? [`${owner.displayName}`] : []}
          items={usersReducerOld.shuppersList.map(user => Object.assign({
            id: user._id,
            title: `${user.displayName}, ${user.location}`,
            image: user.profileImageURL,
          }, user))}
          onSearchValue={query => getOnlyShuppersAndStores(query)}
          onRequestClose={() => this.setState({ ownerPickerOpen: false })}
          onSelectValue={item => this.ownerSelected(item)}
          hasMore={false}
          onLoadMore={() => { }}
        />
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

Item.propTypes = {
  location: PropTypes.object.isRequired,
  itemReducer: PropTypes.object.isRequired,
  usersReducerOld: PropTypes.object.isRequired,
  fetchItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  getOnlyShuppersAndStores: PropTypes.func.isRequired,
};

const mapStateToProps = ({ itemReducer, usersReducerOld }) => ({
  itemReducer, usersReducerOld,
});

const mapDispatchToProps = dispatch => ({
  fetchItem: id => dispatch(itemActions.fetchItem(id)),
  updateItem: data => dispatch(itemActions.updateItem(data)),
  getOnlyShuppersAndStores: name => dispatch(usersActions.getOnlyShuppersAndStores(name)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item));
