/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
  fetchShippingFailed, fetchShippingSuccess
} from '../../actions/logistics/shippingactions';
import getBaseUrl from '../../utils/envSetup';

const baseUrl = getBaseUrl();

const selectLogisticsState = state => state.logistics.shipping;

function* fetchShippingAsync() {
  const logisticsState = yield select(selectLogisticsState);
  const sorting = Object.keys(logisticsState.sort).map(key => `&sort[${key}]=${logisticsState.sort[key]}`).join('');
  const startDate = logisticsState.startDate ? `&startDate=${logisticsState.startDate}` : '';
  const endDate = logisticsState.endDate ? `&endDate=${logisticsState.endDate}` : '';
  const searchQuery = logisticsState.searchQuery ? `&userName=${logisticsState.searchQuery}` : '';
  const shupperQuery = logisticsState.selectedShipper&& logisticsState.selectedShipper.value.length?`&shupper=${logisticsState.selectedShipper.value}`:''
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/shipping?limit=${logisticsState.limit}&skip=${logisticsState.offset}${sorting}${startDate}${endDate}${searchQuery}${shupperQuery}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchShippingFailed(response.err.toString()));
  } else {
    yield put(fetchShippingSuccess(response.data));
  }
}

function* fetchShipping(shupper) {
  yield takeLatest([`${types.LOGISTICS.FETCH_SHIPPING}`, `${types.LOGISTICS.FETCH_MORE_SHIPPING}`,
    `${types.LOGISTICS.FETCH_SHIPPING_SORT_CHANGED}`, `${types.LOGISTICS.FETCH_SHIPPING_DATERANGE_CHANGED}`,`${types.LOGISTICS.SELECT_SHUPPER}`
  ], fetchShippingAsync);
}

export default function* shippingsaga(shupper) {
    
  yield all([
    fetchShipping(shupper),
  ]);
}
