/*
Kateryna Ivanova
20/03/2018

Component for a search field
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionDone from 'material-ui/svg-icons/action/done';
import IconButton from 'material-ui/IconButton';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import './searchfield.css';
import searchImg from '../../static/images/search.svg';

const searchStyle = {
	editField: {

		backgroundImage: `url(${searchImg})`
	},
	clearBtn: {
		position: 'absolute',
		right: '40px',
		color: "red"
	}
};

class SearchField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			text: '',
		};
	}

	render() {
		const { placeholder, doneColor, onRequestSearch, onChange, value, notification } = this.props;
		const { text } = this.state;
		return (
			<div className="search-container flxc ac jc" style={{ position: 'relative' }}>
				<div className="flxr ac jc">
					<input
						onKeyPress={(ev) => { if (ev.which === 13) onRequestSearch(text); }}
						style={searchStyle.editField}
						className="search-field"
						placeholder={placeholder}
						onChange={onChange ? onChange : ev => this.setState({ text: ev.target.value })}
						type="text"
						value={value || text}
					/>
					<IconButton
						style={searchStyle.clearBtn}
						tooltip="Clear"
						onClick={() => {
							if (!onChange && text) {
								this.setState({ text: '' });
								onRequestSearch('');
							} else onChange({ target: { value: '' } })

						}}
					>
						<NavigationClose color="#141B41" />
					</IconButton>

					<IconButton tooltip="Start Search" onClick={() => {
						if (text) {
							onRequestSearch(text)
						}
					}}>
						<ActionDone color={doneColor} />
					</IconButton>
				</div>
				{
					notification ?
						<div className="validation-note">{notification}</div>
						: null
				}
			</div >);
	}
}

SearchField.defaultProps = {
	doneColor: '#fff',
};

SearchField.propTypes = {
	onRequestSearch: PropTypes.func.isRequired,
	doneColor: PropTypes.string,
};

export default SearchField;
