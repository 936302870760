const REVIEWS_APP_TAG = 'reviewsPage/';
const CHAT_APP_TAG = 'chatsPage/';
const INACCESSIBILITY_APP_TAG = 'inaccessibility/';
const PROFILE_APP_TAG = 'profilePage/';
const CHECKINS_APP_TAG = 'checkinsPage/';
const APPROVE_SHUPPERS_APP_TAG = 'approveShuppersPage/';
const BRANDS_APP_TAG = 'brandsPage/';

export default {
  ADMIN_LOGIN: 'ADMIN_LOGIN',
  ADMIN_LOGOUT: 'ADMIN_LOGOUT',
  ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  ADMIN_LOGIN_FAILED: 'ADMIN_LOGIN_FAILED',
  ADMIN_LOGOUT_SUCCESS: 'ADMIN_LOGOUT_SUCCESS',
  LOGIN_2FA_SUCCESS: 'LOGIN_2FA_SUCCESS',
  LOGIN_2FA_FAILED: 'LOGIN_2FA_FAILED',
  LOGIN_2FA: 'SEND_2FA',
  REQUEST_PHONE: 'REQUEST_PHONE',
  REQUEST_PHONE_SUCCESS: 'REQUEST_PHONE_SUCCESS',
  REQUEST_PHONE_FAILED: 'REQUEST_PHONE_FAILED',

  FETCH_ZONES: 'FETCH_ZONES',
  FETCH_ZONES_SUCCESS: 'FETCH_ZONES_SUCCESS',
  FETCH_ZONES_FAILED: 'FETCH_ZONES_FAILED',

  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILED: 'FETCH_COUNTRIES_FAILED',

  //settings
  FETCH_ALLOWED_COUNTRIES: 'FETCH_ALLOWED_COUNTRIES',
  FETCH_ALLOWED_COUNTRIES_SUCCESS: 'FETCH_ALLOWED_COUNTRIES_SUCCESS',
  FETCH_ALLOWED_COUNTRIES_FAILED: 'FETCH_ALLOWED_COUNTRIES_FAILED',

  ADD_NEW_ALLOWED_COUNTRY: 'ADD_NEW_ALLOWED_COUNTRY',
  ADD_EDIT_ALLOWED_COUNTRY: 'ADD_EDIT_ALLOWED_COUNTRY',

  ADD_ALLOWED_COUNTRIES: 'ADD_ALLOWED_COUNTRIES',
  ADD_ALLOWED_COUNTRIES_SUCCESS: 'ADD_ALLOWED_COUNTRIES_SUCCESS',
  ADD_ALLOWED_COUNTRIES_FAILED: 'ADD_ALLOWED_COUNTRIES_FAILED',

  DELETE_ALLOWED_COUNTRIES: 'DELETE_ALLOWED_COUNTRIES',
  DELETE_ALLOWED_COUNTRIES_SUCCESS: 'DELETE_ALLOWED_COUNTRIES_SUCCESS',
  DELETE_ALLOWED_COUNTRIES_FAILED: 'DELETE_ALLOWED_COUNTRIES_FAILED',

  FETCH_MORE_COUNTRIES_ZONES: 'FETCH_MORE_COUNTRIES_ZONES',
  FETCH_COUNTRIES_ZONES: 'FETCH_COUNTRIES_ZONES',
  FETCH_COUNTRIES_ZONES_SUCCESS: 'FETCH_COUNTRIES_ZONES_SUCCESS',
  FETCH_COUNTRIES_ZONES_FAILED: 'FETCH_COUNTRIES_ZONES_FAILED',

  FETCH_ZONE: 'FETCH_ZONE',
  FETCH_ZONE_SUCCESS: 'FETCH_ZONE_SUCCESS',
  FETCH_ZONE_FAILED: 'FETCH_ZONE_FAILED',

  ADD_COUNTRIES_ZONES: 'ADD_COUNTRIES_ZONES',
  ADD_COUNTRIES_ZONES_SUCCESS: 'ADD_COUNTRIES_ZONES_SUCCESS',
  ADD_COUNTRIES_ZONES_FAILED: 'ADD_COUNTRIES_ZONES_FAILED',

  ADD_ZONE: 'ADD_ZONE',
  ADD_NEW_ZONE: 'ADD_NEW_ZONE',
  ADD_ZONE_SUCCESS: 'ADD_ZONE_SUCCESS',
  ADD_ZONE_FAILED: 'ADD_ZONE_FAILED',

  ADD_NEW_COUNTRY_ZONE: 'ADD_NEW_COUNTRY_ZONE',
  REMOVE_NEW_COUNTRY_ZONE: 'REMOVE_NEW_COUNTRY_ZONE',
  REMOVE_NEW_ALLOWED_COUNTRY: 'REMOVE_NEW_ALLOWED_COUNTRY',
  REMOVE_NEW_ZONE: 'REMOVE_NEW_ZONE',
  EDIT_CURRENT_COUNTRY_ZONE: 'EDIT_CURRENT_COUNTRY_ZONE',
  EDIT_CURRENT_ZONE: 'EDIT_CURRENT_ZONE',

  UPDATE_ZONE: 'UPDATE_ZONE',
  UPDATE_ZONE_SUCCESS: 'UPDATE_ZONE_SUCCESS',
  UPDATE_ZONE_FAILED: 'UPDATE_ZONE_FAILED',

  UPDATE_COUNTRIES_ZONES: 'UPDATE_COUNTRIES_ZONES',
  UPDATE_COUNTRIES_ZONES_SUCCESS: 'UPDATE_COUNTRIES_ZONES_SUCCESS',
  UPDATE_COUNTRIES_ZONES_FAILED: 'UPDATE_COUNTRIES_ZONES_FAILED',

  DELETE_COUNTRIES_ZONES: 'DELETE_COUNTRIES_ZONES',
  DELETE_COUNTRIES_ZONES_SUCCESS: 'DELETE_COUNTRIES_ZONES_SUCCESS',
  DELETE_COUNTRIES_ZONES_FAILED: 'DELETE_COUNTRIES_ZONES_FAILED',

  DELETE_ZONE: 'DELETE_ZONE',
  DELETE_ZONE_SUCCESS: 'DELETE_ZONE_SUCCESS',
  DELETE_ZONE_FAILED: 'DELETE_ZONE_FAILED',

  ADD_COUNTRY_IN_COUNTRIES_ZONES: 'ADD_COUNTRY_IN_COUNTRIES_ZONES',
  ADD_COUNTRY_IN_COUNTRIES_ZONES_SUCCESS: 'ADD_COUNTRY_IN_COUNTRIES_ZONES_SUCCESS',
  ADD_COUNTRY_IN_COUNTRIES_ZONES_FAILED: 'ADD_COUNTRY_IN_COUNTRIES_ZONES_FAILED',

  FETCH_MORE_COUNTRIES_ATTRIBUTES: 'FETCH_MORE_COUNTRIES_ATTRIBUTES',
  FETCH_COUNTRIES_ATTRIBUTES: 'FETCH_COUNTRIES_ATTRIBUTES',
  FETCH_COUNTRIES_ATTRIBUTES_SUCCESS: 'FETCH_COUNTRIES_ATTRIBUTES_SUCCESS',
  FETCH_COUNTRIES_ATTRIBUTES_FAILED: 'FETCH_COUNTRIES_ATTRIBUTES_FAILED',

  FETCH_ZONES_COUNTRIES_ATTRIBUTES: 'FETCH_ZONES_COUNTRIES_ATTRIBUTES',
  FETCH_ZONES_COUNTRIES_ATTRIBUTES_SUCCESS: 'FETCH_ZONES_COUNTRIES_ATTRIBUTES_SUCCESS',
  FETCH_ZONES_COUNTRIES_ATTRIBUTES_FAILED: 'FETCH_ZONES_COUNTRIES_ATTRIBUTES_FAILED',

  EDIT_CURRENT_ATTRIBUTES_COUNTRY: 'EDIT_CURRENT_ATTRIBUTES_COUNTRY',

  EDIT_CURRENT_ATTRIBUTES_ZONE: 'EDIT_CURRENT_ATTRIBUTES_ZONE',

  UPDATE_COUNTRY_ATTRIBUTES: 'UPDATE_COUNTRY_ATTRIBUTES',
  UPDATE_COUNTRY_ATTRIBUTES_SUCCESS: 'UPDATE_COUNTRY_ATTRIBUTES_SUCCESS',
  UPDATE_COUNTRY_ATTRIBUTES_FAILED: 'UPDATE_COUNTRY_ATTRIBUTES_FAILED',

  ADD_COUNTRY_ATTRIBUTES: 'ADD_COUNTRY_ATTRIBUTES',
  ADD_COUNTRY_ATTRIBUTES_SUCCESS: 'ADD_COUNTRY_ATTRIBUTES_SUCCESS',
  ADD_COUNTRY_ATTRIBUTES_FAILED: 'ADD_COUNTRY_ATTRIBUTES_FAILED',

  DELETE_COUNTRY_IN_COUNTRIES_ZONES: 'DELETE_COUNTRY_IN_COUNTRIES_ZONES',
  DELETE_COUNTRY_IN_COUNTRIES_ZONES_SUCCESS: 'DELETE_COUNTRY_IN_COUNTRIES_ZONES_SUCCESS',
  DELETE_COUNTRY_IN_COUNTRIES_ZONES_FAILED: 'DELETE_COUNTRY_IN_COUNTRIES_ZONES_FAILED',

  SET_IS_EDIT_COUNTRY_COUNTRIES_ZONES: 'SET_IS_EDIT_COUNTRY_COUNTRIES_ZONES',

  ///
  FETCH_ALL_USERS: 'FETCH_ALL_USERS',
  FETCH_ALL_USERS_SUCCESS: 'FETCH_ALL_USERS_SUCCESS',
  FETCH_ALL_USERS_FAILED: 'FETCH_ALL_USERS_FAILED',

  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_SORT_CHANGED: 'FETCH_USERS_SORT_CHANGED',
  FETCH_MORE_USERS: 'FETCH_MORE_USERS',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILED: 'FETCH_USERS_FAILED',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',
  BULK_UPDATE_USERS: 'BULK_UPDATE_USERS',
  CLEAR_USERS_FILTERS: 'CLEAR_USERS_FILTER',
  USER_REGENERATE_PASSWORD: 'USER_REGENERATE_PASSWORD',
  USER_REGENERATE_PASSWORD_SUCCESS: 'USER_REGENERATE_PASSWORD_SUCCESS',
  USER_REGENERATE_PASSWORD_FAILED: 'USER_REGENERATE_PASSWORD_FAILED',
  CLEAR_REGENERATED_PASSWORD: 'CLEAR_REGENERATED_PASSWORD',
  USER_UPDATE_PROFILE_IMAGE: 'USER_UPDATE_PROFILE_IMAGE',
  MOVE_ITEMS_FROM_USER_TO_ANOTHER: 'MOVE_ITEMS_FROM_USER_TO_ANOTHER',
  GET_SHUPPERS_OF_THE_DAY: 'GET_SHUPPERS_OF_THE_DAY',
  GET_SHUPPERS_OF_THE_DAY_SUCCESS: 'GET_SHUPPERS_OF_THE_DAY_SUCCESS',
  GET_SHUPPERS_OF_THE_DAY_FAILED: 'GET_SHUPPERS_OF_THE_DAY_FAILED',
  SET_SHUPPER_OF_THE_DAY: 'SET_SHUPPER_OF_THE_DAY',
  SET_SHUPPER_OF_THE_DAY_SUCCESS: 'SET_SHUPPER_OF_THE_DAY_SUCCESS',
  SET_SHUPPER_OF_THE_DAY_FAILED: 'SET_SHUPPER_OF_THE_DAY_FAILED',
  REMOVE_SHUPPER_OF_THE_DAY: 'REMOVE_SHUPPER_OF_THE_DAY',
  REMOVE_SHUPPER_OF_THE_DAY_SUCCESS: 'REMOVE_SHUPPER_OF_THE_DAY_SUCCESS',
  REMOVE_SHUPPER_OF_THE_DAY_FAILED: 'REMOVE_SHUPPER_OF_THE_DAY_FAILED',
  GET_ONLY_SHUPPERS: 'GET_ONLY_SHUPPERS',
  GET_ONLY_SHUPPERS_SUCCESS: 'GET_ONLY_SHUPPERS_SUCCESS',
  GET_ONLY_SHUPPERS_FAILED: 'GET_ONLY_SHUPPERS_FAILED',
  GET_ONLY_SHUPPERS_AND_STORES: 'GET_ONLY_SHUPPERS_AND_STORES',
  STOP_LOADING_USERS: 'STOP_LOADING_USERS',
  SET_ACTIVE_TIPS: 'SET_ACTIVE_TIPS',
  GET_TIPS: 'GET_TIPS',
  GET_TIPS_SUCCESS: 'GET_TIPS_SUCCESS',

  FETCH_EVENTS: 'FETCH_EVENTS',
  FETCH_EVENTS_SORT_CHANGED: 'FETCH_EVENTS_SORT_CHANGED',
  FETCH_EVENTS_FILTER_BY_TYPE: 'FETCH_EVENTS_FILTER_BY_TYPE',
  FETCH_MORE_EVENTS: 'FETCH_MORE_EVENTS',
  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILED: 'FETCH_EVENTS_FAILED',
  FETCH_EVENTS_DATERANGE_CHANGED: 'FETCH_EVENTS_DATERANGE_CHANGED',
  UPDATE_EVENT_LINK: 'UPDATE_EVENT_LINK',

  FETCH_EVENT: 'FETCH_EVENT',
  FETCH_EVENT_SUCCESS: 'FETCH_EVENT_SUCCESS',
  FETCH_EVENT_FAILED: 'FETCH_EVENT_FAILED',
  UPDATE_EVENT: 'UPDATE_EVENT',
  UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAILED: 'UPDATE_EVENT_FAILED',
  CREATE_EVENT: 'CREATE_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILED: 'DELETE_EVENT_FAILED',
  RESHUFFLE_EVENT: 'RESHUFFLE_EVENT',
  ACTION_DONE: 'ACTION_DONE',

  FETCH_STORE: 'FETCH_STORE',
  FETCH_STORE_SUCCESS: 'FETCH_STORE_SUCCESS',
  FETCH_STORE_FAILED: 'FETCH_STORE_FAILED',
  UPDATE_STORE: 'UPDATE_STORE',
  UPDATE_STORE_SUCCESS: 'UPDATE_STORE_SUCCESS',
  UPDATE_STORE_FAILED: 'UPDATE_STORE_FAILED',
  CREATE_STORE: 'CREATE_STORE',
  DELETE_STORE: 'DELETE_STORE',
  DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
  DELETE_STORE_FAILED: 'DELETE_STORE_FAILED',

  FETCH_STORES: 'FETCH_STORES',
  FETCH_STORES_DATERANGE_CHANGED: 'FETCH_STORES_DATERANGE_CHANGED',
  FETCH_STORES_SORT_CHANGED: 'FETCH_STORES_SORT_CHANGED',
  FETCH_MORE_STORES: 'FETCH_MORE_STORES',
  FETCH_STORES_SUCCESS: 'FETCH_STORES_SUCCESS',
  FETCH_STORES_FAILED: 'FETCH_STORES_FAILED',

  FETCH_EVENTS_V2: 'FETCH_EVENTS_V2',
  FETCH_MORE_EVENTS_V2: 'FETCH_MORE_EVENTS_V2',
  FETCH_EVENTS_V2_SUCCESS: 'FETCH_EVENTS_V2_SUCCESS',
  FETCH_EVENTS_V2_FAILED: 'FETCH_EVENTS_V2_FAILED',

  FETCH_EVENT_V2: 'FETCH_EVENT_V2',
  FETCH_EVENT_V2_SUCCESS: 'FETCH_EVENT_V2_SUCCESS',
  FETCH_EVENT_V2_FAILED: 'FETCH_EVENT_V2_FAILED',
  UPDATE_EVENT_V2: 'UPDATE_EVENT_V2',
  UPDATE_EVENT_V2_SUCCESS: 'UPDATE_EVENT_V2_SUCCESS',
  UPDATE_EVENT_V2_FAILED: 'UPDATE_EVENT_V2_FAILED',

  FETCH_ITEMS: 'FETCH_ITEMS',
  FETCH_ITEMS_SORT_CHANGED: 'FETCH_ITEMS_SORT_CHANGED',
  FETCH_MORE_ITEMS: 'FETCH_MORE_ITEMS',
  FETCH_ITEMS_SUCCESS: 'FETCH_ITEMS_SUCCESS',
  FETCH_ITEMS_FAILED: 'FETCH_ITEMS_FAILED',

  FETCH_ITEMS_V2: 'FETCH_ITEMS_V2',
  FETCH_ITEMS_V2_SUCCESS: 'FETCH_ITEMS_V2_SUCCESS',
  FETCH_ITEMS_V2_FAILED: 'FETCH_ITEMS_V2_FAILED',
  FETCH_MORE_ITEMS_V2: 'FETCH_MORE_ITEMS_V2',

  FETCH_ITEM_V2: 'FETCH_ITEM_V2',
  FETCH_ITEM_V2_SUCCESS: 'FETCH_ITEM_V2_SUCCESS',
  FETCH_ITEM_V2_FAILED: 'FETCH_ITEM_V2_FAILED',

  UPDATE_ITEM_V2: 'UPDATE_ITEM_V2',
  UPDATE_ITEM_V2_SUCCESS: 'UPDATE_ITEM_V2_SUCCESS',
  UPDATE_ITEM_V2_FAILED: 'UPDATE_ITEM_V2_FAILED',

  FETCH_ALL_BRANDS: 'FETCH_ALL_BRANDS',
  FETCH_ALL_BRANDS_SUCCESS: 'FETCH_ALL_BRANDS_SUCCESS',
  FETCH_ALL_BRANDS_FAILED: 'FETCH_ALL_BRANDS_FAILED',

  DELETE_ITEM: 'DELETE_ITEM',
  DELETE_ITEM_SUCCESS: 'DELETE_ITEM_SUCCESS',
  DELETE_ITEM_FAILED: 'DELETE_ITEM_FAILED',
  DELETE_ITEMS: 'DELETE_ITEMS',
  DELETE_ITEMS_SUCCESS: 'DELETE_ITEMS_SUCCESS',
  DELETE_ITEMS_FAILED: 'DELETE_ITEMS_FAILED',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UPDATE_ITEM_SUCCESS: 'UPDATE_ITEMS_SUCCESS',
  UPDATE_ITEM_FAILED: 'UPDATE_ITEMS_FAILED',

  FETCH_PACKAGES: 'FETCH_PACKAGES',
  FETCH_MORE_PACKAGES: 'FETCH_MORE_PACKAGES',
  FETCH_PACKAGES_SUCCESS: 'FETCH_PACKAGES_SUCCESS',
  FETCH_PACKAGES_FAILED: 'FETCH_PACKAGES_FAILED',

  FETCH_PACKAGE_DETAILS: 'FETCH_PACKAGE_DETAILS',
  FETCH_PACKAGE_DETAILS_SUCCESS: 'FETCH_PACKAGE_DETAILS_SUCCESS',
  FETCH_PACKAGE_DETAILS_FAILED: 'FETCH_PACKAGE_DETAILS_FAILED',

  UPDATE_PACKAGE_DETAILS: 'UPDATE_PACKAGE_DETAILS',
  UPDATE_PACKAGE_DETAILS_SUCCESS: 'UPDATE_PACKAGE_DETAILS_SUCCESS',
  UPDATE_PACKAGE_DETAILS_FAILED: 'UPDATE_PACKAGE_DETAILS_FAILED',

  FETCH_ITEM: 'FETCH_ITEM',
  FETCH_ITEM_SUCCESS: 'FETCH_ITEM_SUCCESS',
  FETCH_ITEM_FAILED: 'FETCH_ITEM_FAILED',

  FETCH_COLLECTIONS: 'FETCH_COLLECTIONS',
  FETCH_COLLECTIONS_SORT_CHANGED: 'FETCH_COLLECTIONS_SORT_CHANGED',
  FETCH_MORE_COLLECTIONS: 'FETCH_MORE_COLLECTIONS',
  FETCH_COLLECTIONS_SUCCESS: 'FETCH_COLLECTIONS_SUCCESS',
  FETCH_COLLECTIONS_FAILED: 'FETCH_COLLECTIONS_FAILED',

  DELETE_COLLECTION: 'DELETE_COLLECTION',
  DELETE_COLLECTION_SUCCESS: 'DELETE_COLLECTION_SUCCESS',
  DELETE_COLLECTION_FAILED: 'DELETE_COLLECTION_FAILED',
  DELETE_COLLECTIONS: 'DELETE_COLLECTIONS',
  DELETE_COLLECTIONS_SUCCESS: 'DELETE_COLLECTIONS_SUCCESS',
  DELETE_COLLECTIONS_FAILED: 'DELETE_COLLECTIONS_FAILED',
  UPDATE_COLLECTION: 'UPDATE_COLLECTION',
  UPDATE_COLLECTION_SUCCESS: 'UPDATE_COLLECTION_SUCCESS',
  UPDATE_COLLECTION_FAILED: 'UPDATE_COLLECTION_FAILED',
  FETCH_COLLECTION: 'FETCH_COLLECTION',
  FETCH_COLLECTION_SUCCESS: 'FETCH_COLLECTION_SUCCESS',
  FETCH_COLLECTION_FAILED: 'FETCH_COLLECTION_FAILED',

  FETCH_BO_USERS: 'FETCH_BO_USERS',
  FETCH_BO_USERS_SUCCESS: 'FETCH_BO_USERS_SUCCESS',
  FETCH_BO_USERS_FAILED: 'FETCH_BO_USERS_FAILED',
  FETCH_BO_USERS_SORT_CHANGED: 'FETCH_BO_USERS_SORT_CHANGED',
  FETCH_MORE_BO_USERS: 'FETCH_MORE_BO_USERS',

  FETCH_BO_USER: 'FETCH_BO_USER',
  FETCH_BO_USER_SUCCESS: 'FETCH_BO_USER_SUCCESS',
  FETCH_BO_USER_FAILED: 'FETCH_BO_USER_FAILED',
  UPDATE_BO_USER: 'UPDATE_BO_USER',
  CREATE_BO_USER: 'CREATE_BO_USER',
  UPDATE_BO_USER_SUCCESS: 'UPDATE_BO_USER_SUCCESS',
  UPDATE_BO_USER_FAILED: 'UPDATE_BO_USER_FAILED',
  DELETE_BO_USER: 'DELETE_BO_USER',
  DELETE_BO_USER_SUCCESS: 'DELETE_BO_USER_SUCCESS',
  DELETE_BO_USER_FAILED: 'DELETE_BO_USER_FAILED',
  BO_USER_REGENERATE_PASSWORD: 'BO_USER_REGENERATE_PASSWORD',
  BO_USER_REGENERATE_PASSWORD_SUCCESS: 'BO_USER_REGENERATE_PASSWORD_SUCCESS',
  BO_USER_REGENERATE_PASSWORD_FAILED: 'BO_USER_REGENERATE_PASSWORD_FAILED',

  FETCH_LOGS: 'FETCH_LOGS',
  FETCH_MORE_LOGS: 'FETCH_MORE_LOGS',
  FETCH_LOGS_SUCCESS: 'FETCH_LOGS_SUCCESS',
  FETCH_LOGS_FAILED: 'FETCH_LOGS_FAILED',

  FETCH_COUPONS: 'FETCH_COUPONS',
  FETCH_MORE_COUPONS: 'FETCH_MORE_LOGS',
  FETCH_COUPONS_SUCCESS: 'FETCH_COUPONS_SUCCESS',
  FETCH_COUPONS_FAILED: 'FETCH_COUPONS_FAILED',

  FETCH_COUPON: 'FETCH_COUPON',
  FETCH_COUPON_SUCCESS: 'FETCH_COUPON_SUCCESS',
  FETCH_COUPON_FAILED: 'FETCH_COUPON_FAILED',
  UPDATE_COUPON: 'UPDATE_COUPON',
  UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAILED: 'UPDATE_COUPON_FAILED',
  CREATE_COUPON: 'CREATE_COUPON',
  DELETE_COUPON: 'DELETE_COUPON',
  DELETE_COUPON_SUCCESS: 'DELETE_COUPON_SUCCESS',
  DELETE_COUPON_FAILED: 'DELETE_COUPON_FAILED',

  LOGISTICS: {
    FETCH_CANCELED: 'FETCH_CANCELED',
    FETCH_CANCELED_SORT_CHANGED: 'FETCH_CANCELED_SORT_CHANGED',
    FETCH_CANCELED_DATERANGE_CHANGED: 'FETCH_CANCELED_DATERANGE_CHANGED',
    FETCH_MORE_CANCELED: 'FETCH_MORE_CANCELED',
    FETCH_CANCELED_SUCCESS: 'FETCH_CANCELED_SUCCESS',
    FETCH_CANCELED_FAILED: 'FETCH_CANCELED_FAILED',

    FETCH_COMPLETED: 'FETCH_COMPLETED',
    FETCH_COMPLETED_SORT_CHANGED: 'FETCH_COMPLETED_SORT_CHANGED',
    FETCH_COMPLETED_DATERANGE_CHANGED: 'FETCH_COMPLETED_DATERANGE_CHANGED',
    FETCH_MORE_COMPLETED: 'FETCH_MORE_COMPLETED',
    FETCH_COMPLETED_SUCCESS: 'FETCH_COMPLETED_SUCCESS',
    FETCH_COMPLETED_FAILED: 'FETCH_COMPLETED_FAILED',
    MAKE_REFUND: 'MAKE_REFUND',
    MAKE_REFUND_SUCCESS: 'MAKE_REFUND_SUCCESS',
    MAKE_REFUND_FAILED: 'MAKE_REFUND_FAILED',

    FETCH_PENDING: 'FETCH_PENDING',
    FETCH_PENDING_SORT_CHANGED: 'FETCH_PENDING_SORT_CHANGED',
    FETCH_PENDING_DATERANGE_CHANGED: 'FETCH_PENDING_DATERANGE_CHANGED',
    FETCH_MORE_PENDING: 'FETCH_MORE_PENDING',
    FETCH_PENDING_SUCCESS: 'FETCH_PENDING_SUCCESS',
    FETCH_PENDING_FAILED: 'FETCH_PENDING_FAILED',
    REJECT_PENDING: 'REJECT_PENDING',
    REJECT_PENDING_SUCCESS: 'REJECT_PENDING_SUCCESS',
    REJECT_PENDING_FAILED: 'REJECT_PENDING_FAILED',

    FETCH_REFUNDED: 'FETCH_REFUNDED',
    FETCH_REFUNDED_SORT_CHANGED: 'FETCH_REFUNDED_SORT_CHANGED',
    FETCH_REFUNDED_DATERANGE_CHANGED: 'FETCH_REFUNDED_DATERANGE_CHANGED',
    FETCH_MORE_REFUNDED: 'FETCH_MORE_REFUNDED',
    FETCH_REFUNDED_SUCCESS: 'FETCH_REFUNDED_SUCCESS',
    FETCH_REFUNDED_FAILED: 'FETCH_REFUNDED_FAILED',

    FETCH_REJECTED: 'FETCH_REJECTED',
    FETCH_REJECTED_SORT_CHANGED: 'FETCH_REJECTED_SORT_CHANGED',
    FETCH_REJECTED_DATERANGE_CHANGED: 'FETCH_REJECTED_DATERANGE_CHANGED',
    FETCH_MORE_REJECTED: 'FETCH_MORE_REJECTED',
    FETCH_REJECTED_SUCCESS: 'FETCH_REJECTED_SUCCESS',
    FETCH_REJECTED_FAILED: 'FETCH_REJECTED_FAILED',

    FETCH_SHIPPING: 'FETCH_SHIPPING',
    FETCH_SHIPPING_SORT_CHANGED: 'FETCH_SHIPPING_SORT_CHANGED',
    FETCH_SHIPPING_DATERANGE_CHANGED: 'FETCH_SHIPPING_DATERANGE_CHANGED',
    FETCH_MORE_SHIPPING: 'FETCH_MORE_SHIPPING',
    FETCH_SHIPPING_SUCCESS: 'FETCH_SHIPPING_SUCCESS',
    FETCH_SHIPPING_FAILED: 'FETCH_SHIPPING_FAILED',

    FETCH_SINGLE_PAYMENT: 'FETCH_SINGLE_PAYMENT',
    FETCH_SINGLE_PAYMENT_SUCCESS: 'FETCH_SINGLE_PAYMENT_SUCCESS',
    FETCH_SINGLE_PAYMENT_FAILED: 'FETCH_SINGLE_PAYMENT_FAILED',

    SELECT_SHUPPER: 'SELECT_SHUPPER',

    ADD_PAYMENT: 'ADD_*_PAYMENT',
    CHANGE_PAYMENT_STATUS: 'CHANGE_*_STATUS',

    ADD_SHIPPING_STATUS: 'ADD_SHIPPING_STATUS',
    ADD_SHIPPING_STATUS_SUCCESS: 'ADD_SHIPPING_STATUS_SUCCESS',
    ADD_SHIPPING_STATUS_ERROR: 'ADD_SHIPPING_STATUS_ERROR',

    FETCH_SHIPPING_PICKUP: 'FETCH_SHIPPING_PICKUP',
    FETCH_SHIPPING_PICKUP_SUCCESS: 'FETCH_SHIPPING_PICKUP_SUCCESS',
    FETCH_SHIPPING_PICKUP_FAILURE: 'FETCH_SHIPPING_PICKUP_FAILURE',

    CLEAR_SHIPPING_PICKUP_DATA: 'CLEAR_SHIPPING_PICKUP_DATA',
    REMOVE_SHIPPING_ITEMS: 'REMOVE_SHIPPING_ITEMS',

    ADD_SHIPPING_PICKUP: 'ADD_SHIPPING_PICKUP',
    ADD_SHIPPING_PICKUP_SUCCESS: 'ADD_SHIPPING_PICKUP_SUCCESS',
    ADD_SHIPPING_PICKUP_FAILURE: 'ADD_SHIPPING_PICKUP_FAILURE',

    FETCH_MORE_SHIPPING_PICKUP: 'FETCH_MORE_SHIPPING_PICKUP',

    FETCH_PACKAGE: 'FETCH_PACKAGE',
    FETCH_PACKAGE_ERROR: 'FETCH_PACKAGE_ERROR',
    FETCH_PACKAGE_SUCCESS: 'FETCH_PACKAGE_SUCCESS',
    CLOSE_PACKAGE_MODAL: 'CLOSE_PACKAGE_MODAL',
    SAVE_PACKAGE: 'SAVE_PACKAGE',
    SAVE_PACKAGE_SUCCESS: 'SAVE_PACKAGE_SUCCESS',
    SAVE_PACKAGE_ERROR: 'SAVE_PACKAGE_ERROR',
  },
  //Chat actions
  CONNECT_TO_CHAT: CHAT_APP_TAG + 'CONNECT_TO_CHAT',
  LOAD_CHAT_MESSAGES: CHAT_APP_TAG + 'LOAD_CHAT_MESSAGES',
  LOAD_CHATS_LIST: CHAT_APP_TAG + 'LOAD_CHATS_LIST',
  SELECT_USER: CHAT_APP_TAG + 'SELECT_USER',
  SET_CHATS_LIST: CHAT_APP_TAG + 'SET_CHATS_LIST',
  SELECT_CHAT: CHAT_APP_TAG + 'SELECT_CHAT',
  MAKE_WS_CONNECTION: CHAT_APP_TAG + 'MAKE_WS_CONNECTION',
  SET_CONNECTION_PROPS: CHAT_APP_TAG + 'SET_CONNECTION_PROPS',
  SET_CHAT_MESSAGES: CHAT_APP_TAG + 'SET_CHAT_MESSAGES',
  ADD_CHAT_MESSAGES: CHAT_APP_TAG + 'ADD_CHAT_MESSAGES',
  PREPEND_CHAT_MESSAGES: CHAT_APP_TAG + 'PREPEND_CHAT_MESSAGES',
  SEND_MESSAGE: CHAT_APP_TAG + 'SEND_MESSAGE',
  CHANGE_SELECTED_CHAT: CHAT_APP_TAG + 'CHANGE_SELECTED_CHAT',
  DISCONNECT_FROM_CHAT: CHAT_APP_TAG + 'DISCONNECT_FROM_CHAT',
  CHANGE_FILTER_PARAMS: CHAT_APP_TAG + 'CHANGE_FILTER_PARAMS',
  LOAD_MORE_CHATS: CHAT_APP_TAG + 'LOAD_MORE_CHATS',
  LOAD_MORE_MESSAGES: CHAT_APP_TAG + 'LOAD_MORE_MESSAGES',
  ADD_CHATS_TO_LIST: CHAT_APP_TAG + 'ADD_CHATS_TO_LIST',
  SET_CHATS_TOKEN: CHAT_APP_TAG + 'SET_CHATS_TOKEN',
  SET_PREVIEW_IMAGES: CHAT_APP_TAG + 'SET_PREVIEW_IMAGES',
  CLEAR_PREVIEW_IMAGES: CHAT_APP_TAG + 'CLEAR_PREVIEW_IMAGES',
  RESET_CHATS_FILTERS: CHAT_APP_TAG + 'RESET_CHATS_FILTERS',
  SET_DEFAULT_FILTERS: CHAT_APP_TAG + 'SET_DEFAULT_FILTERS',
  SET_SUPPORT_ID: CHAT_APP_TAG + 'SET_SUPPORT_ID',
  GET_PAYMENT_DATA: CHAT_APP_TAG + 'GET_PAYMENT_DATA',
  SET_PAYMENT_DATA: CHAT_APP_TAG + 'SET_PAYMENT_DATA',
  GET_USER_ROLES: CHAT_APP_TAG + 'GET_USER_ROLES',
  ADD_USER_ROLES: CHAT_APP_TAG + 'SET_USER_ROLES',
  REMOVE_FLAG_FROM_CHAT: CHAT_APP_TAG + 'REMOVE_FLAG_FROM_CHAT',
  REMOVE_FLAG_IN_STATE: CHAT_APP_TAG + 'REMOVE_FLAG_IN_STATE',
  EXPAND_CHAT_VIDEO: CHAT_APP_TAG + 'EXPAND_CHAT_VIDEO',
  CLOSE_CHAT_VIDEO: CHAT_APP_TAG + 'CLOSE_CHAT_VIDEO',
  UPDATE_MESSAGE: CHAT_APP_TAG + 'UPDATE_MESSAGE',
  SEARCH_IN_CHANNELS: CHAT_APP_TAG + 'UPDATE_MESSAGE',
  JUMP_TO_MESSAGE: CHAT_APP_TAG + 'JUMP_TO_MESSAGE',
  LOAD_MORE_MESSAGES_DOWN: CHAT_APP_TAG + 'LOAD_MORE_MESSAGES_DOWN',
  ADD_NEWLY_MESSAGE: CHAT_APP_TAG + 'ADD_NEWLY_MESSAGE',
  REMOVE_NEWLY_MESSAGES: CHAT_APP_TAG + 'REMOVE_NEWLY_MESSAGES',
  HANDLE_NEW_MESSAGE: CHAT_APP_TAG + 'HANDLE_NEW_MESSAGE',
  SET_PREVIEW_IMAGE_ORIENTATION: CHAT_APP_TAG + 'SET_PREVIEW_IMAGE_ORIENTATION',
  GET_ALL_SHUPLIST_ITEMS: CHAT_APP_TAG + 'GET_ALL_SHUPLIST_ITEMS',
  GET_ALL_SHUPLIST_ITEMS_SUCCESS: CHAT_APP_TAG + 'GET_ALL_SHUPLIST_ITEMS_SUCCESS',
  CLEAR_ALL_SHUPLIST_ITEMS: CHAT_APP_TAG + 'CLEAR_ALL_SHUPLIST_ITEMS',

  CLEAR_PREVIEW_IMAGE_ORIENTATION: CHAT_APP_TAG + 'CLEAR_PREVIEW_IMAGE_ORIENTATION',
  SET_SHUPPERS_DATA: CHAT_APP_TAG + 'SET_SHUPPERS_DATA',
  GET_SHUPPERS_DATA: CHAT_APP_TAG + 'GET_SHUPPERS_DATA',
  SET_FORWARDED_CHATS: CHAT_APP_TAG + 'SET_FORWARDED_CHATS',
  SET_FORWARDED_CHATS_SUCCESS: CHAT_APP_TAG + 'SET_FORWARDED_CHATS_SUCCESS',
  RESET_FORWARDED_CHATS: CHAT_APP_TAG + 'RESET_FORWARDED_CHATS',
  GET_USERS_ROLES: CHAT_APP_TAG + 'GET_USERS_ROLES',
  SET_USERS_ROLES: CHAT_APP_TAG + 'SET_USERS_ROLES',
  ADD_FORWARDED_MESSAGE_USER_ROLES: CHAT_APP_TAG + 'ADD_FORWARDED_MESSAGE_USER_ROLES',

  //Inaccesibility actions
  SET_USER_ID: INACCESSIBILITY_APP_TAG + 'SET_USER_ID',
  SET_DAY_PARAMS: INACCESSIBILITY_APP_TAG + 'SET_DAY_PARAMS',
  SET_DAYS: INACCESSIBILITY_APP_TAG + 'SET_DAYS',
  SET_POPUPS: INACCESSIBILITY_APP_TAG + 'SET_POPUPS',
  SET_POPUP_PARAMS: INACCESSIBILITY_APP_TAG + 'SET_POPUP_PARAMS',
  SAVE_TIMETABLE_CHANGES: INACCESSIBILITY_APP_TAG + 'SAVE_TIMETABLE_CHANGES',
  CREATE_NEW_TIMETABLE: INACCESSIBILITY_APP_TAG + 'CREATE_NEW_TIMETABLE',
  UPDATE_POPUP_IMAGE: INACCESSIBILITY_APP_TAG + 'UPDATE_POPUP_IMAGE',
  TIME_TABLE_STOP_LOAD: INACCESSIBILITY_APP_TAG + 'TIME_TABLE_STOP_LOAD',
  SET_UPDATE_FLAG: INACCESSIBILITY_APP_TAG + 'SET_UPDATE_FLAG',
  SET_TIMETABLE_SAVE_ERROR: INACCESSIBILITY_APP_TAG + 'SET_TIMETABLE_SAVE_ERROR',
  SET_TIMETABLE_SAVE_SUCCESS: INACCESSIBILITY_APP_TAG + 'SET_TIMETABLE_SAVE_SUCCESS',
  SET_DEFAULT_TIMETABLE: INACCESSIBILITY_APP_TAG + 'SET_DEFAULT_TIMETABLE',
  GET_DEFAULT_VALUES: INACCESSIBILITY_APP_TAG + 'GET_DEFAULT_VALUES',
  UPDATE_DEFAULT_POPUPS_FLAG: INACCESSIBILITY_APP_TAG + 'UPDATE_DEFAULT_POPUPS_FLAG',
  RESET_POPUPS: INACCESSIBILITY_APP_TAG + 'RESET_POPUPS',
  //BRAND REVIEW ACTIONS
  SET_BRAND_ID: REVIEWS_APP_TAG + 'SET_BRAND_ID',
  SET_LOAD_ERROR: REVIEWS_APP_TAG + 'SET_LOAD_ERROR',
  SET_REVIEWS_LIST: REVIEWS_APP_TAG + 'SET_REVIEWS_LIST',
  PREPEND_REVIEWS_LIST: REVIEWS_APP_TAG + 'PREPEND_REVIEWS_LIST',
  LOAD_REVIEWS_BY_BRAND: REVIEWS_APP_TAG + 'LOAD_REVIEWS_BY_BRAND',
  UPDATE_REVIEW_STATUS: REVIEWS_APP_TAG + 'UPDATE_REVIEW_STATUS',
  CHANGE_STATUS_OF_ACTIVE_REVIEW: REVIEWS_APP_TAG + 'CHANGE_STATUS_OF_ACTIVE_REVIEW',
  LOAD_MORE_REVIEWS: REVIEWS_APP_TAG + 'LOAD_MORE_REVIEWS',
  SET_SAVE_REVIEW_SUCCESS: REVIEWS_APP_TAG + 'SET_SAVE_REVIEW_SUCCESS',
  UPDATE_REVIEW_FIELDS: REVIEWS_APP_TAG + 'UPDATE_REVIEW_FIELDS',
  START_EDIT_REVIEW: REVIEWS_APP_TAG + 'START_EDIT_REVIEW',
  CANCEL_EDIT_REVIEW: REVIEWS_APP_TAG + 'CANCEL_EDIT_REVIEW',
  SET_NEW_VALUE_REVIEW: REVIEWS_APP_TAG + 'SET_NEW_VALUE_REVIEW',
  SET_NEW_REVIEW_IMAGE: REVIEWS_APP_TAG + 'SET_NEW_REVIEW_IMAGE',
  SET_NEW_REVIEW_VIDEO: REVIEWS_APP_TAG + 'SET_NEW_REVIEW_VIDEO',
  SET_BRAND_NAME: REVIEWS_APP_TAG + 'SET_BRAND_NAME',

  //PROFILE ACTIONS
  GET_USER_DATA: PROFILE_APP_TAG + 'GET_USER_DATA',
  SET_USER_DATA: PROFILE_APP_TAG + 'SET_USER_DATA',
  FETCH_BRANDS: PROFILE_APP_TAG + 'FETCH_BRANDS',
  FETCH_BRANDS_SUCCESS: PROFILE_APP_TAG + 'FETCH_BRANDS_SUCCESS',
  SAVE_PROFILE_DATA: PROFILE_APP_TAG + 'SAVE_PROFILE_DATA',
  SAVE_PROFILE_DATA_SUCCEESS: PROFILE_APP_TAG + 'SAVE_PROFILE_DATA_SUCCEESS',
  SAVE_EXTENSION_DATA: PROFILE_APP_TAG + 'SAVE_EXTENSION_DATA',
  SAVE_EXTENSION_DATA_SUCCEESS: PROFILE_APP_TAG + 'SAVE_EXTENSION_DATA_SUCCEESS',
  UPDATE_PROFILE_IMAGE: PROFILE_APP_TAG + 'UPDATE_PROFILE_IMAGE',
  UPDATE_PROFILE_IMAGE_SUCCESS: PROFILE_APP_TAG + 'UPDATE_PROFILE_IMAGE_SUCCESS',
  FETCH_TIERS: PROFILE_APP_TAG + 'FETCH_TIERS',
  FETCH_TIERS_SUCCESS: PROFILE_APP_TAG + 'FETCH_TIERS_SUCCESS',
  GET_EXTANTION_DATA: PROFILE_APP_TAG + 'GET_EXTANTION_DATA',
  SET_EXTANTION_DATA: PROFILE_APP_TAG + 'SET_EXTANTION_DATA',
  SAVE_EXTANTION_DATA: PROFILE_APP_TAG + 'SAVE_EXTANTION_DATA',
  SAVE_EXTANTION_DATA_SUCCESS: PROFILE_APP_TAG + 'SAVE_EXTANTION_DATA_SUCCESS',
  FETCH_USER_PROFILE_DATA: 'FETCH_USER_PROFILE_DATA',
  FETCH_USER_PROFILE_DATA_SUCCESS: 'FETCH_USER_PROFILE_DATA_SUCCESS',
  FETCH_USER_PROFILE_DATA_FAILED: 'FETCH_USER_PROFILE_DATA_FAILED',
  UPDATE_USER_PROFILE_SHIPPING: 'UPDATE_USER_PROFILE_SHIPPING',
  UPDATE_USER_PROFILE_SHIPPING_SUCCESS: 'UPDATE_USER_PROFILE_SHIPPING_SUCCESS',
  UPDATE_USER_PROFILE_SHIPPING_FAILED: 'UPDATE_USER_PROFILE_SHIPPING_FAILED',

  UPDATE_USER_PROFILE_INFO: 'UPDATE_USER_PROFILE_INFO',
  UPDATE_USER_PROFILE_INFO_SUCCESS: 'UPDATE_USER_PROFILE_INFO_SUCCESS',
  UPDATE_USER_PROFILE_INFO_FAILED: 'UPDATE_USER_PROFILE_INFO_FAILED',

  // SAVE_USER_DATA:PROFILE_APP_TAG+"SAVE_USER_DATA",
  // SAVE_USER_DATA_SUCCESS:PROFILE_APP_TAG+"SAVE_USER_DATA_SUCCESS",

  //CHECKINS PAGE
  GET_CHECKINS: CHECKINS_APP_TAG + 'GET_CHECKINS',
  GET_CHECKINS_SUCCESS: CHECKINS_APP_TAG + 'GET_CHECKINS_SUCCESS',
  SELECT_CHECKIN: CHECKINS_APP_TAG + 'SELECT_CHECKIN',
  GET_CHECKIN_BY_ID: CHECKINS_APP_TAG + 'GET_CHECKIN_BY_ID',
  GET_CHECKIN_BY_ID_SUCCESS: CHECKINS_APP_TAG + 'GET_CHECKIN_BY_ID_SUCCESS',
  GET_SHUPPERS: CHECKINS_APP_TAG + 'GET_SHUPPERS',
  GET_SHUPPERS_SUCCESS: CHECKINS_APP_TAG + 'GET_SHUPPERS_SUCCESS',
  UPDATE_IMAGE_CHECKIN_SUCCESS: CHECKINS_APP_TAG + 'UPDATE_IMAGE_CHECKIN_SUCCESS',
  UPDATE_IMAGE_CHECKIN: CHECKINS_APP_TAG + 'UPDATE_IMAGE_CHECKIN',
  UPDATE_VIDEO_CHECKIN_SUCCESS: CHECKINS_APP_TAG + 'UPDATE_VIDEO_CHECKIN_SUCCESS',
  UPDATE_VIDEO_CHECKIN: CHECKINS_APP_TAG + 'UPDATE_VIDEO_CHECKIN',
  SAVE_CHECKIN_BY_ID: CHECKINS_APP_TAG + 'SAVE_CHECKIN_BY_ID',
  SAVE_CHECKIN_BY_ID_SUCCESS: CHECKINS_APP_TAG + 'SAVE_CHECKIN_BY_ID_SUCCESS',
  RESET_CHECKINS: CHECKINS_APP_TAG + 'RESET_CHECKINS',
  GET_STORES: CHECKINS_APP_TAG + 'GET_STORES',
  GET_STORES_SUCCESS: CHECKINS_APP_TAG + 'GET_STORES_SUCCESS',
  SAVE_NEW_CHECKIN_SUCCESS: CHECKINS_APP_TAG + 'SAVE_NEW_CHECKIN_SUCCESS',
  DELETE_CHECKINS: CHECKINS_APP_TAG + 'DELETE_CHECKINS',
  DELETE_CHECKINS_SUCCESS: CHECKINS_APP_TAG + 'DELETE_CHECKINS_SUCCESS',

  //APPROVE SHUPPERS ACTIONS
  FETCH_SHUPPERS: APPROVE_SHUPPERS_APP_TAG + 'FETCH_SHUPPERS',
  FETCH_SHUPPERS_SUCCESS: APPROVE_SHUPPERS_APP_TAG + 'FETCH_SHUPPERS_SUCCESS',
  APPROVE_SHUPPER: APPROVE_SHUPPERS_APP_TAG + 'APPROVE_SHUPPER',
  APPROVE_SHUPPER_SUCCESS: APPROVE_SHUPPERS_APP_TAG + 'APPROVE_SHUPPER_SUCCESS',
  APPROVE_SHUPPER_ERROR: APPROVE_SHUPPERS_APP_TAG + 'APPROVE_SHUPPER_ERROR',
  DECLINE_SHUPPER: APPROVE_SHUPPERS_APP_TAG + 'DECLINE_SHUPPER',
  DECLINE_SHUPPER_SUCCESS: APPROVE_SHUPPERS_APP_TAG + 'DECLINE_SHUPPER_SUCCESS',
  DECLINE_SHUPPER_ERROR: APPROVE_SHUPPERS_APP_TAG + 'DECLINE_SHUPPER_ERROR',
  RESET_APPROVE_SHUPPER: APPROVE_SHUPPERS_APP_TAG + 'RESET_APPROVE_SHUPPER',

  //BRANDS ACTIONS
  FETCH_BRANDS_FOR_APPROVE_SUCCESS: BRANDS_APP_TAG + 'FETCH_BRANDS_FOR_APPROVE_SUCCESS',
  FETCH_BRANDS_FOR_APPROVE: BRANDS_APP_TAG + 'FETCH_BRANDS_FOR_APPROVE',
  APPROVE_BRAND: BRANDS_APP_TAG + 'APPROVE_SHUPPER',
  APPROVE_BRAND_SUCCESS: BRANDS_APP_TAG + 'APPROVE_SHUPPER_SUCCESS',
  APPROVE_BRAND_ERROR: BRANDS_APP_TAG + 'APPROVE_BRAND_ERROR',
  DECLINE_BRAND: BRANDS_APP_TAG + 'DECLINE_BRAND',
  DECLINE_BRAND_SUCCESS: BRANDS_APP_TAG + 'DECLINE_BRAND_SUCCESS',
  DECLINE_BRAND_ERROR: BRANDS_APP_TAG + 'DECLINE_BRAND_ERROR',
  RESET_APPROVE_BRAND: BRANDS_APP_TAG + 'RESET_APPROVE_BRAND',
  CHANGE_STATUS: BRANDS_APP_TAG + 'CHANGE_STATUS',
  FETCH_PENDING_BRANDS_FOR_APPROVE_SUCCESS: BRANDS_APP_TAG + 'FETCH_PENDING_BRANDS_FOR_APPROVE_SUCCESS',
  FETCH_PENDING_BRANDS_FOR_APPROVE: BRANDS_APP_TAG + 'FETCH_PENDING_BRANDS_FOR_APPROVE',

  //ORDERS ACTIONS
  FETCH_ORDERS: 'FETCH_ORDERS',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILED: 'FETCH_ORDERS_FAILED',
  FETCH_MORE_ORDERS: 'FETCH_MORE_ORDERS',
  FETCH_PAYMENT: 'FETCH_PAYMENT',
  FETCH_PAYMENT_SUCCESS: 'FETCH_PAYMENT_SUCCESS',
  FETCH_PAYMENT_FAILED: 'FETCH_PAYMENT_FAILED',
};
