/*
Kateryna Ivanova
20/03/2018

Reducer for a list of stores
*/
import types from '../actions/actionTypes';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 100,
  stores: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: '',
  sort: {},
  startDate: null,
  endDate: null,
};

export default function storesReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_STORES:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        stores: [],
        totalCount: 0,
        searchQuery: action.query,
      });
    case types.DELETE_STORE_SUCCESS:
    case types.UPDATE_STORE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        stores: [],
        totalCount: 0,
      });
    case types.FETCH_STORES_DATERANGE_CHANGED:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        stores: [],
        totalCount: 0,
        startDate: action.startDate,
        endDate: action.endDate,
      });
    case types.FETCH_MORE_STORES:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_STORES_SUCCESS: {
      const stores = [].concat(state.stores).concat(action.data.stores);
      return Object.assign({}, state, {
        offset: state.offset + state.limit,
        totalCount: action.data.count,
        stores,
        hasMore: stores.length < action.data.count,
        isLoading: false,
      });
    }
    case types.FETCH_STORES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        hasMore: false,
      });
    case types.FETCH_STORES_SORT_CHANGED: {
      const sorting = procesSort(state.sort, action.column, action.direction);
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        stores: [],
        totalCount: 0,
        sort: sorting,
      });
    }
    default:
      return state;
  }
}
