import React, { useMemo } from 'react';
import { Paper, IconButton, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import { ToastNotification } from '../../utils/toastify';
import { useNavigate } from 'react-router';
import { Country, TextFields, Zones } from '../../components/fields/shippingField';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfileData, updateUserProfileShipping } from '../../actions/userProfileActions';
import { withRouter } from '../../utils/withRouter';
import { CancelApplyBtns } from '../../components/buttons';
import { decodePermissions } from '../../utils/permissionUtil';
import { getPermission } from '../../utils/cookies';
import NoPermission from '../../components/nopermission';
import { Loading } from '../../components/loader';
import { zones } from '../../utils/zones';
import { countries } from '../../utils/countries';
import { addressTypes } from '../../utils/address';


const EditShipping = (props) => {
  const data = useSelector(({ userProfileReducer }) => userProfileReducer)
  const saveId = React.useRef(props.params.id)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const permission = decodePermissions(getPermission());

  const defineCountry = countries?.filter(country => country?.id === data?.userProfile?.address?.country_id)
  const defineCountryBasedOnProfile = countries?.filter(country => country?.id === data?.userProfile?.user?.country_id)
  const defineZone = zones?.find(zone => zone?.id === Number(data?.userProfile?.address?.zone_id))

  const zone = defineZone?.iso2_name

  const country = defineCountry[0]?.iso2_name

  const countryBasedOnProfile = defineCountryBasedOnProfile[0]?.iso2_name

  React.useEffect(() => {
    if (permission.users) {
      dispatch(getUserProfileData(props.params.id))
    }
  }, [props.params.id])

  const currentAddressType = useMemo(() => {
    return country == 'US' ? addressTypes.A : addressTypes.B
  }, [country])

  const shouldDisplayField = (field) => {
    if (field.address_type) {
      return field.address_type == currentAddressType
    }
    return true
  }

  const handleSubmit = (values, props) => {

    const {
      country_id,
      city,
      address1,
      address2,
      address_notes,
      zone_id,
      zip,
      taxId,
      first_name,
      last_name,
      company,
      street,
      apartment,
      building,
      enterance,
      identification_number,
    } = values
    const countryToNumber = countries?.find(c => c.iso2_name === country_id)
    const zoneToNumber = zones?.find(z => z.iso2_name === zone_id)

    const data = {
      first_name: first_name || '',
      last_name: last_name || '',
      company: company || '',
      country_id: countryToNumber?.['id'] || '',
      zone_id: ((country_id === 'US' && zoneToNumber?.['id']) ?? 286) || country_id !== 'US' && countryToNumber['zone'] || countryBasedOnProfile['id'],
      city: city || '',
      address1: address1 || '',
      address2: address2 || '',
      address_notes: address_notes || '',
      zip_code: zip || '',
      tax_id: taxId || '',
      street: street || '',
      apartment: apartment || '',
      building: building || '',
      enterance: enterance || '',
      identification_number: identification_number || '',
    }

    dispatch(updateUserProfileShipping(saveId.current, data))
    setTimeout(() => {
      props.setSubmitting(false);
      dispatch(getUserProfileData(saveId.current))
    }, 1500);
  };
  const validationSchema = yup.object().shape({
    first_name: yup.string().trim().matches(/^[aA-zZ\s]+$/, "First name must contain only (A-z) letters.").required('can not be empty'),
    last_name: yup.string().trim().matches(/^[aA-zZ\s]+$/, "Last name must contain only (A-z) letters.").required('can not be empty'),
    city: yup.string().trim().matches(/^[aA-zZ\s]+$/, "City name must contain only (A-z) letters.").required('can not be empty'),
    zip: yup.number().typeError('only number').required('can not be empty'),
    zone_id: yup.string().when({
      is: () => currentAddressType == addressTypes.A,
      then: yup.string().required('can not be empty')            
    }),
    address1: yup.string().trim().matches(/^[A-Za-z0-9*\.?\s]+$/g, "Address 1  must contain only numbers and letters.").when({
      is: () => currentAddressType == addressTypes.A,
      then: yup.string().required('can not be empty')            
    }),
    street: yup.string().when({
      is: () => currentAddressType == addressTypes.B,
      then: yup.string().required('can not be empty')            
    }),
    apartment: yup.string(),
    building: yup.string().when({
      is: () => currentAddressType == addressTypes.B,
      then: yup.string().required('can not be empty')            
    }),
    enterance: yup.string(),
    identification_number: yup.string().when({
      is: () => currentAddressType == addressTypes.B,
      then: yup.string().required('can not be empty')            
    }),
  });

  const shippingFields = [
    { id: 1, title: 'First Name *', name: 'first_name', type: 'text' },
    { id: 2, title: 'Last Name *', name: 'last_name', type: 'text' },
    { id: 3, title: 'Company Name (optional)', name: 'company', type: 'text' },
    { id: 5, title: '', name: '', type: 'zone_select', address_type: addressTypes.A },
    { id: 6, title: 'City *', name: 'city', type: 'text' },
    { id: 7, title: 'Address 1 *', name: 'address1', type: 'text', address_type: addressTypes.A},
    { id: 8, title: 'Address 2 (optional)', name: 'address2', type: 'text', address_type: addressTypes.A },
    { id: 9, title: 'Street *', name: 'street', type: 'text', address_type: addressTypes.B },
    { id: 10, title: 'Apartment (optional)', name: 'apartment', type: 'text', address_type: addressTypes.B },
    { id: 11, title: 'Building *', name: 'building', type: 'text', address_type: addressTypes.B },
    { id: 12, title: 'Enterance (optional)', name: 'enterance', type: 'text', address_type: addressTypes.B },
    { id: 13, title: 'Identification Number *', name: 'identification_number', type: 'text', address_type: addressTypes.B },
    { id: 14, title: 'Address notes  (optional)', name: 'address_notes', type: 'text' },
    { id: 15, title: 'ZIP code *', name: 'zip', type: 'text' }
  ]


  if (!data?.userProfile) return <Loading />
  return permission.users ?
    <div>
      <div className="App-header">
        <IconButton onClick={() => navigate(-1)}><ArrowBackIcon color="primary" /></IconButton>
      </div>
      <ToastNotification hideBar={false} position='top-center' />
      <Grid container direction='row' justifyContent='center' >
        <Typography variant='h4'>{`EDIT SHIPPING ADDRESS-${data?.userProfile?.user?.id}`}</Typography>
      </Grid>
      <Grid container justifyContent='center' className='grid-container'  >
        <Paper className='paper' >
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item>
              <Typography>ID {data?.userProfile?.address?.user_id}</Typography>
            </Grid>
            <Grid item className="dateCreateText">
              <Typography >Date created </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Formik
              enableReinitialize={true}
              validateOnBlur={false}
              validateOnChange={true}
              initialValues={{
                first_name: data?.userProfile?.address?.first_name,
                last_name: data?.userProfile?.address?.last_name,
                company: data?.userProfile?.address?.company,
                country_id: country ?? countryBasedOnProfile,
                zone_id: zone,
                city: data?.userProfile?.address?.city,
                address1: data?.userProfile?.address?.address1,
                address2: data?.userProfile?.address?.address2,
                address_notes: data?.userProfile?.address?.address_notes,
                zip: data?.userProfile?.address?.zip_code,
                taxId: data?.userProfile?.address?.tax_id,
                street: data?.userProfile?.address?.street,
                apartment: data?.userProfile?.address?.apartment,
                building: data?.userProfile?.address?.building,
                enterance: data?.userProfile?.address?.enterance,
                identification_number: data?.userProfile?.address?.identification_number,
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}>
              {props => (
                <Form>
                  {shippingFields.map((item, index) => {
                    return (
                      shouldDisplayField(item) && <Grid item key={item?.id}>
                        <Typography>{item?.title}</Typography>
                        {{
                          text: <TextFields name={item?.name} {...props} />,
                          select_country: <Country name={item?.name} />,
                          zone_select: <Zones name="zone_id" {...props} />
                        }[item.type]}
                      </Grid>
                    )
                  })}
                  <CancelApplyBtns {...props} />
                </Form>
              )}
            </Formik>
          </Grid>
        </Paper >
      </Grid >
    </div >

    : <NoPermission onOpenDrawer={props.onOpenDrawer} />
}

export default withRouter(EditShipping)