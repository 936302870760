import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Done from 'material-ui/svg-icons/action/done';
import DoneAll from 'material-ui/svg-icons/action/done-all';
import { checkPermissionByStatus, urlify, highlight } from '../../utils/utils';
import { config } from '../../utils/envSetup';
import MediaPreview from './MediaPreview';
import { messageListActions } from '../../actions/chatsActions';
import { messagesListSelector } from '../../reducers/chatsReducer';
import { IconButton, Checkbox } from 'material-ui';
import SendIcon from 'material-ui/svg-icons/content/send';
import Dialog from 'material-ui/Dialog';
import { RaisedButton } from 'material-ui';

const ITEMS_PER_DATA_SET = config('chatsItemsPerPage');

const MessageInput = ({ display, onChange, text, send }) => display ?
    <div className="send-message-block flxr ac jsb">
        <textarea
            value={text}
            onChange={onChange}
            id=""
            cols="30"
            rows="10"
        />
        <IconButton
            className='send-message'
            tooltip="Send Message"
            onClick={send}
        >
            <SendIcon />
        </IconButton>
    </div> : null;

const getPaymentImages = (payment, rec) => (
    (payment && rec[payment._id]) && rec[payment._id].receipts ? (
        rec[payment._id].receipts.map(receipt => ({ src: receipt.imageUrl || '' }))
    ) : []);

const chooseMessageType = (getShuplistAllItems, openShupListModal, body, attrs, permissions,
    setPreviewImages, paymentData, getPaymentsData, expandChatsVideo, foundMessagesList, searchedText,
    _id, setPreviewImageOrientation) => {
    if (attrs !== '') {
        try {
            const parsed = JSON.parse(attrs);
            const messageType = Object.keys(parsed)[0];
            if (messageType && ['payment', 'item', 'imageUrl', 'collection', 'video', 'shuplist'].includes(messageType)) {
                return {
                    payment: (
                        <PaymentMessage {...parsed} permissions={permissions} setPreviewImages={setPreviewImages}
                            expandChatsVideo={expandChatsVideo}
                            paymentData={paymentData} setPreviewImageOrientation={setPreviewImageOrientation}
                            getPaymentsData={getPaymentsData} />
                    ),
                    item: (
                        <ItemMessage {...parsed} setPreviewImages={setPreviewImages} body={body}
                            expandChatsVideo={expandChatsVideo} setPreviewImageOrientation={setPreviewImageOrientation}
                            foundMessagesList={foundMessagesList} searchedText={searchedText} _id={_id} />
                    ),
                    imageUrl: (
                        <MediaAttachmentMessage {...parsed} setPreviewImages={setPreviewImages}
                            expandChatsVideo={expandChatsVideo}
                            setPreviewImageOrientation={setPreviewImageOrientation} />
                    ),
                    collection: (
                        <CollectionMessage {...parsed} setPreviewImages={setPreviewImages}
                            expandChatsVideo={expandChatsVideo}
                            setPreviewImageOrientation={setPreviewImageOrientation} />
                    ),
                    video: (
                        <MediaAttachmentMessage {...parsed} setPreviewImages={() => {
                        }} expandChatsVideo={expandChatsVideo} />
                    ),
                    shuplist: (
                        <ShupListMessage {...parsed} getShuplistAllItems={getShuplistAllItems}
                            openShupListModal={openShupListModal} setPreviewImages={setPreviewImages}
                            expandChatsVideo={expandChatsVideo}
                            setPreviewImageOrientation={setPreviewImageOrientation} />
                    )
                }[messageType];
            } else return <DefaultMessage body={body} foundMessagesList={foundMessagesList}
                searchedText={searchedText} _id={_id} />
        } catch (e) {
            return <DefaultMessage body='Error Parsing The Message' />
        }
    }
    if (body !== '') return <DefaultMessage body={body} foundMessagesList={foundMessagesList}
        searchedText={searchedText} _id={_id} />;
};

const handleLazyLoadMessages = (handler, chatId, uniqueName, skip, token, up = true) => () => {
    handler(
        chatId, uniqueName, token, up ? skip + ITEMS_PER_DATA_SET : skip - ITEMS_PER_DATA_SET,
        true, up
    )();
};

const DefaultMessage = ({ body, foundMessagesList, searchedText, _id }) => (
    <div dangerouslySetInnerHTML={{ __html: highlight(urlify(body), _id, foundMessagesList, searchedText) }} />
);



class PaymentMessage extends React.Component {
    componentDidMount() {
        const { getPaymentsData, payment, paymentData } = this.props;
        if (!paymentData || !paymentData[payment._id]) getPaymentsData(payment._id);
    }

    render() {
        const { payment, permissions, setPreviewImages, paymentData,
            setPreviewImageOrientation, expandChatsVideo } = this.props;
        return (
            paymentData && paymentData[payment._id] ?
                <div
                    className={'item-message flxc jc ac payment ' + payment.status}
                >
                    <b>Payment: </b><br />
                    <div className="receipts flxr ac">
                        {
                            payment._id ? getPaymentImages(payment, paymentData).map((val, index) => (
                                <MediaPreview expandChatsVideo={expandChatsVideo} imageUrl={val.src}
                                    setPreviewImageOrientation={setPreviewImageOrientation}
                                    setPreviewImages={setPreviewImages} key={index} />
                            )) : 'No receipts'
                        }
                    </div>
                    <a href={checkPermissionByStatus(payment, permissions) ? '/logistics/payment/' + payment._id : null}
                        target="_blank">
                        <div><b>Id: </b> {payment._id}</div>
                        <div><b>Status: </b> {payment.status === 'fulfilled' ? 'completed' : payment.status}</div>
                        <div><b>Items total price: </b> {paymentData[payment._id].itemsTotalPrice}</div>
                        <div><b>Discount: </b> {paymentData[payment._id].promoCode ?
                            (
                                paymentData[payment._id].discount +
                                '$ (' + paymentData[payment._id].promoCode.code +
                                ' - ' + paymentData[payment._id].promoCode.discountValue +
                                (paymentData[payment._id].promoCode.discountType === 'fixed' ? '$' : '%') +
                                ')'
                            ) : 'No Coupon'}
                        </div>
                        <div><b>Processing fee: </b> {(paymentData[payment._id].deliveryFee ? paymentData[payment._id].deliveryFee : 0) + (paymentData[payment._id].payeeFee ? paymentData[payment._id].payeeFee : 0)}</div>
                        <div><b>Tip: </b> {(paymentData[payment._id].tip ? paymentData[payment._id].tip : 0)}</div>

                        <div><b>Taxes: </b> {paymentData[payment._id].taxes}</div>
                        <div><b>Total price: </b> {paymentData[payment._id].totalPrice}</div>
                    </a>
                </div> : null
        )
    }
}

const MediaAttachmentMessage = ({ imageUrl, setPreviewImages, video, expandChatsVideo, setPreviewImageOrientation }) => (
    <MediaPreview
        imageUrl={imageUrl}
        videoUrl={video ? video.videoUrl : null}
        videoPreviewImg={video ? video.videoPreview : null}
        setPreviewImages={setPreviewImages}
        expandChatsVideo={expandChatsVideo}
        setPreviewImageOrientation={setPreviewImageOrientation}
    />
);

const ItemMessage = ({ item, setPreviewImages, body, _id, foundMessagesList, searchedText, setPreviewImageOrientation, expandChatsVideo }) => (
    <div className="flxr ac">
        {
            item ?
                <div className="item-message flxc jc afs">
                    <b>Item: </b>
                    <MediaPreview expandChatsVideo={expandChatsVideo} setPreviewImageOrientation={setPreviewImageOrientation} imageUrl={item.imageUrl} videoUrl={item.videoUrl} videoPreviewImg={item.videoPreviewImg} setPreviewImages={setPreviewImages} />
                    <b>
                        {
                            item.description
                        }
                    </b>
                </div>
                : null
        }
        {body ? <div dangerouslySetInnerHTML={{ __html: highlight(urlify(body), _id, foundMessagesList, searchedText) }} /> : null}
    </div>
);

const ShupListMessage = ({ shuplist, setPreviewImages, setPreviewImageOrientation, expandChatsVideo, openShupListModal, getShuplistAllItems }) => (
    <div className="flxr ac">
        {
            shuplist ?
                <div className="item-message flxc jc afs">
                    <b>Shuplist: </b>
                    <MediaPreview videoUrl={shuplist.video} expandChatsVideo={expandChatsVideo} imageUrl={shuplist.imageUrl} setPreviewImageOrientation={setPreviewImageOrientation} setPreviewImages={setPreviewImages} />
                    {
                        shuplist.text
                    }
                    <b>ID: {shuplist.shuplistId}</b>
                    <RaisedButton
                        label={"See all shuplist"}
                        primary
                        // onClick={openShupListModal}
                        onClick={() => getShuplistAllItems(shuplist.shuplistId)}
                    />
                </div>
                : null
        }
    </div>
);



const CollectionMessage = ({ collection, setPreviewImages, setPreviewImageOrientation, expandChatsVideo }) => (
    <React.Fragment>
        <b>Collection: </b>
        <div className="collection-message-container flxr jsc ac">
            {
                collection.items ? collection.items.map((val, index) => (
                    <div className="item flxc jc ac" key={index}>
                        <MediaPreview expandChatsVideo={expandChatsVideo} imageUrl={val.imageUrl} setPreviewImages={setPreviewImages} setPreviewImageOrientation={setPreviewImageOrientation} />
                        <span>{val.description}</span>
                    </div>
                )) : null
            }
        </div>
        <b>{collection.description}</b>
    </React.Fragment>
);


const Message = ({
    body, foundMessagesList, searchedText, read, from,
    date, attrs, index, permissions, setPreviewImages, supportId,
    paymentData, getPaymentsData, expandChatsVideo, _id,
    setPreviewImageOrientation, openShupListModal, getShuplistAllItems,
    hangeCheckboxForwardedMessages, chatId, changeCheckboxForwardedMessages,
    selectedChatForwardedMessages, selectedForwardedMessages
}) => (
        <div className={`column-item ${from.identity === supportId ? 'flxrvs' : 'flxr'} ac`} key={index}>
            <div className="flxr ac message-user">
                <div className="flxc jsb afs">
                    <div className="flxr jsb ac">
                        <div className="user-avatar">
                            <img src={from.attributes.profileImageUrl} alt="" className="avatar" />
                        </div>
                        <div className="user-name">{from.friendlyName} <br /> {from.online ? <div className="online-status">Online</div> : null} </div>
                    </div>
                    <div className="message-date">
                        {moment(date).format('lll')}
                    </div>
                </div>
            </div>
            <div className="message-text">
                {
                    chooseMessageType(getShuplistAllItems, openShupListModal, body, attrs, permissions,
                        setPreviewImages, paymentData, getPaymentsData, expandChatsVideo, foundMessagesList,
                        searchedText, _id, setPreviewImageOrientation)
                }
            </div>

            <div className="read-status">
                <div className="message-checkbox">
                    {permissions.canForwardChats ? <Checkbox
                        style={{ width: '10px', marginLeft: '20px' }}
                        onCheck={() => changeCheckboxForwardedMessages(_id, from, chatId)}
                        checked={selectedForwardedMessages.indexOf(_id) + 1}
                        disabled={selectedChatForwardedMessages ? chatId !== selectedChatForwardedMessages : false}
                    /> : null}</div>
                {
                    read ? <DoneAll style={{ height: '15px', width: '15px', color: 'green' }} /> : <Done style={{ height: '15px', width: '15px', color: 'green' }} />
                }
            </div>
        </div>);


class MessagesList extends React.Component {
    constructor(props) {
        super(props);
        this.messageContainer = null;
        this.state = {
            lastMessage: '',
            firstTimeOpenChat: true,
            messageField: '',
            openModalShupList: false,
            shupListItems: []
        }
    }

    typeMessage = (e) => {
        this.setState({ messageField: e.target.value });
    };

    sendMessage = (send, chat, uniqueName, socket) => () =>
        this.state.messageField !== '' &&
        (
            send(chat, uniqueName, this.state.messageField, socket),
            this.setState({ messageField: '' })
        );

    createMessageContainerRef = elem => this.messageContainer = elem;

    componentWillReceiveProps({ chatMessages }) {
        if (this.messageContainer) {
            const msgCount = chatMessages.length - 1;
            if (msgCount !== -1) {
                const newLastMessage = chatMessages[msgCount].updatedAt;
                const scrollDiff = this.messageContainer.scrollHeight - this.messageContainer.scrollTop;
                const blockHeight = this.messageContainer.clientHeight;
                if (this.state.lastMessage !== newLastMessage && (scrollDiff === blockHeight || this.state.firstTimeOpenChat)) {
                    this.setState({ firstTimeOpenChat: false });
                    if (this.messageContainer) setTimeout(() => this.messageContainer.scrollTop += 9999, 100);
                    this.setState({ lastMessage: newLastMessage });
                }
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.shuplistItems !== prevProps.shuplistItems && this.props.shuplistItems.items) {
            this.openShupListModal(this.props.shuplistItems.items)
        }

    }
    getShuplistAllItems = (id) => {
        this.props.getAllShuplistItems(id)
    }
    openShupListModal = (items) => {
        this.setState({ openModalShupList: true, shupListItems: items })
    }
    onCloseModal = () => {
        this.setState({ openModalShupList: false, shupListItems: [] })
        this.props.clearAllShuplistItems()
    }
    render() {
        const {
            chatMessages, shouldLoadUp, shouldLoadDown, skip, chatId, selectedUnique,
            loadMessages, token, permissions, setPreviewImages, supportId, paymentData,
            getPaymentsData, expandChatsVideo, sendMessageToChat, socket, filters, foundMessageList,
            skipDown, newlyCameMessages, setPreviewImageOrientation, changeCheckboxForwardedMessages,
            selectedChatForwardedMessages, selectedForwardedMessages
        } = this.props;
        const { messageField, shupListItems, openModalShupList } = this.state;
        return (
            <React.Fragment>

                <Dialog
                    modal={false}
                    open={openModalShupList}
                    className="dialog-shuplist"
                    onRequestClose={this.onCloseModal}
                    style={{ paddingTop: "100px", overflowY: "scroll" }}
                    bodyStyle={{ overflowY: "scroll" }}
                    contentStyle={{ minWidth: '650px', width: "80%", paddingTop: "0" }}
                >
                    <div className="shuplist-all-items">
                        {shupListItems.length > 0 ? shupListItems.map(item => {
                            return (
                                <div className="shuplist-one-item">
                                    {item.item ?
                                        <MediaPreview
                                            shuplist
                                            expandChatsVideo={expandChatsVideo}
                                            videoUrl={item && item.item.videoUrl ? item.item.videoUrl : null}
                                            videoPreviewImg={item.item && item.item.videoPreview ? item.item.videoPreview : null}
                                            imageUrl={item.item && item.item.imageUrl}
                                            setPreviewImageOrientation={setPreviewImageOrientation}
                                            setPreviewImages={setPreviewImages} />
                                        : <MediaPreview
                                            shuplist
                                            expandChatsVideo={expandChatsVideo}
                                            videoUrl={item && item.videoUrl ? item.videoUrl :
                                                item && item.video ? item.video : null}
                                            videoPreviewImg={item && item.videoPreview ? item.videoPreview : null}
                                            imageUrl={item && item.image}
                                            setPreviewImageOrientation={setPreviewImageOrientation}
                                            setPreviewImages={setPreviewImages} />}

                                    <div>{item.item && item.item.description &&
                                        <p className="item-info"><span>Description:</span> <h5>{item.item.description}</h5></p>}</div>
                                    <div>{item.item && item.item.brand &&
                                        <p className="item-info"><span>Brand:</span> <h5>{item.item.brand}</h5></p>
                                    }</div>
                                    <div>{item && item.description && <p><h5>Description:</h5> {item.description}</p>}</div>
                                    <div>{item && item.brand &&
                                        <p className="item-info"><span>Brand:</span> <h5>{item.brand}</h5></p>
                                    }</div>
                                    <div>{item && item.comment &&
                                        <p className="item-info"><span>Comment:</span> <h5>{item.comment}</h5></p>
                                    }</div>

                                </div>)
                        }) : null}
                    </div>

                </Dialog>
                <div className="messages-block" ref={this.createMessageContainerRef}>
                    {
                        chatMessages && chatMessages.length ?
                            [
                                shouldLoadUp ?
                                    <div
                                        className="column-item flxr ac jc red"
                                        onClick={
                                            handleLazyLoadMessages(
                                                loadMessages, chatId, selectedUnique,
                                                skip, token, true
                                            )
                                        }>Show More
									</div> : null,
                                chatMessages.map(({ body, readed, from, createdAt, attributes, _id }, index) => (
                                    <Message
                                        getShuplistAllItems={this.getShuplistAllItems}
                                        openShupListModal={this.openShupListModal}
                                        changeCheckboxForwardedMessages={changeCheckboxForwardedMessages}
                                        body={body}
                                        read={readed}
                                        from={from}
                                        date={createdAt}
                                        attrs={attributes}
                                        index={index}
                                        permissions={permissions}
                                        setPreviewImages={setPreviewImages}
                                        supportId={supportId}
                                        paymentData={paymentData}
                                        getPaymentsData={getPaymentsData}
                                        expandChatsVideo={expandChatsVideo}
                                        foundMessagesList={foundMessageList}
                                        searchedText={filters.inMessages ? filters.friendlyName : null}
                                        _id={_id}
                                        chatId={chatId}
                                        setPreviewImageOrientation={setPreviewImageOrientation}
                                        selectedChatForwardedMessages={selectedChatForwardedMessages}
                                        selectedForwardedMessages={selectedForwardedMessages}
                                    />
                                )),
                                newlyCameMessages && newlyCameMessages.length ? (
                                    <React.Fragment>
                                        <div className="column-item flxr ac jc green">...</div>
                                        {newlyCameMessages.map(({ body, readed, from, createdAt, attributes, _id }, index) => (

                                            <Message
                                                getShuplistAllItems={this.getShuplistAllItems}
                                                openShupListModal={this.openShupListModal}
                                                changeCheckboxForwardedMessages={changeCheckboxForwardedMessages}
                                                body={body}
                                                read={readed}
                                                from={from}
                                                date={createdAt}
                                                attrs={attributes}
                                                index={index}
                                                permissions={permissions}
                                                setPreviewImages={setPreviewImages}
                                                setPreviewImageOrientation={setPreviewImageOrientation}
                                                supportId={supportId}
                                                paymentData={paymentData}
                                                getPaymentsData={getPaymentsData}
                                                expandChatsVideo={expandChatsVideo}
                                                foundMessagesList={foundMessageList}
                                                searchedText={filters.inMessages ? filters.friendlyName : null}
                                                _id={_id}
                                                chatId={chatId}
                                                selectedChatForwardedMessages={selectedChatForwardedMessages}
                                                selectedForwardedMessages={selectedForwardedMessages}
                                            />))
                                        }
                                    </React.Fragment>
                                ) : null,
                                shouldLoadDown ?
                                    <div
                                        className="column-item flxr ac jc red"
                                        onClick={
                                            handleLazyLoadMessages(
                                                loadMessages, chatId, selectedUnique,
                                                skipDown, token, false
                                            )
                                        }>Show More
									</div> : null,
                            ] : null
                    }
                </div>
                <MessageInput
                    display={chatId !== 1}
                    text={messageField}
                    onChange={this.typeMessage}
                    send={this.sendMessage(sendMessageToChat, chatId, selectedUnique, socket)}
                />
            </React.Fragment >
        )
    };
}

export default connect(messagesListSelector, messageListActions)(MessagesList);