import React, { useEffect } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from 'material-ui/IconButton';
import PaymentsRequestPackage from '../../components/paymentsRequests/paymentRequestPackage';
import PaymentInfoDetails from '../../components/paymentsRequests/paymentInfoDetails';
import UserDetailsByRole from '../../components/paymentsRequests/userDetailsByRole';
import PaymentRequestItems from '../../components/paymentsRequests/paymentRequestItems';
import PaymentRequestShippingAddress from '../../components/paymentsRequests/paymentRequestShippingAddress';
import PaymentRequestRecentPayments from '../../components/paymentsRequests/paymentRequestRecentPayments';
import '../../static/styles/paymentRequest.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from '../../utils/withRouter';
import { fetchPayment } from '../../actions/ordersActions';
import getSymbolFromCurrency from 'currency-symbol-map'
import { convertCurrencyToString } from '../../utils/countriesAndZones';
import ShippingAddress from '../../components/shipping/ShippingAddress';
import PaymentRequestContent from '../../components/paymentsRequests/paymentRequestContent';

const useStyles = makeStyles({
  cancelRequestButton: {
    color: '#E50C31',
    border: '2px solid #E60C31',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    backgroundColor: '#ffffff',
  },
});

const PaymentRequests = props => {
  const classes = useStyles();
  const payments = useSelector(({ ordersReducer }) => ordersReducer)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPayment(props?.params?.id));
  }, [])

  const getCurrencies = payments?.paymentDetails?.attempts?.map(ccy => {
    return {
      shupperCCY: ccy.shupper_currency_id,
      userCCY: ccy.client_currency_id
    }
  })

  const shupperCCY = convertCurrencyToString(payments?.paymentDetails?.paymentRequest?.currency_id)
  const shupperSign = getSymbolFromCurrency(shupperCCY?.[0]?.code)
  const userCCY = convertCurrencyToString(getCurrencies?.[0]?.userCCY)
  const userSing = getSymbolFromCurrency(userCCY?.[0]?.code)

  const handleClickEdit = (value) => { };
  const openProfile = (userID) => { };

  if (!'errorPayment') {
    return (
      <>
        <div className="App-header">
          <IconButton onClick={props?.onOpenDrawer}>
            <MenuIcon classes={{ root: classes.menuIcon }} />
            <div classes={{ root: classes.menuIcon }}></div>
          </IconButton>
        </div>
        <div className="text-center">
          <h2>Payment Request is not defined</h2>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="App-header">
        <IconButton onClick={props?.onOpenDrawer}>
          <MenuIcon classes={{ root: classes.menuIcon }} />
          <div classes={{ root: classes.menuIcon }}></div>
        </IconButton>
      </div>
      <div className="paymentRequest-wrapper">
        <div className="paymentRequest-title-wrapper">
          <div>
            <Typography variant="h4" className="paymentRequest-title">
              PAYMENT REQUEST {payments?.paymentDetails?.paymentRequest?.external_uid}
            </Typography>
          </div>
          {/* <div>
            <Button className={classes.cancelRequestButton}>Cancel Request</Button>
          </div> */}
        </div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
          xs={12}
          style={{ paddingRight: '0px' }}>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
            <PaymentInfoDetails
              shupperSign={shupperSign}
              userSing={userSing}
              getCurrencies={getCurrencies}
              attempts={payments?.paymentDetails?.attempts}
              mainBtnText="Open Chat History"
              totalItemCost={payments?.paymentDetails?.totalItemCost}
              paymentRequest={payments?.paymentDetails?.paymentRequest}
              exchangeRate={props?.location?.state?.exchangeRate} />
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} className="paymentsRequestPackage-wrapper">
            <PaymentsRequestPackage mainBtnText="Track shipping" paymentId={payments?.paymentDetails?.paymentRequest?.uid} packageInfoDetails={payments?.paymentDetails?.package} />
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
            <UserDetailsByRole userRole="SHUPPER" paymentRequest={payments?.paymentDetails?.paymentRequest} dataShupper={payments?.paymentDetails?.shupper} mainBtnText="Send Message" handleClick={openProfile} />
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} className="paymentsRequestPackage-wrapper">
            <UserDetailsByRole userRole="USER" paymentRequest={payments?.paymentDetails?.paymentRequest} dataUser={payments?.paymentDetails?.user} mainBtnText="Send Message" handleClick={openProfile} />
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
            <PaymentRequestContent content={payments?.paymentDetails?.paymentRequest?.content}/>
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
            <ShippingAddress address={payments?.paymentDetails?.supperAddress} />
          </Grid>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12} >
            <PaymentRequestRecentPayments
              getCurrencies={getCurrencies}
              exchangeRate={props?.location?.state?.exchangeRate}
              total={payments?.paymentDetails?.totalItemCost}
              handleClickEdit={handleClickEdit}
              successfulInfo={payments?.paymentDetails?.paymentRequest}
              attempts={payments?.paymentDetails?.attempts} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withRouter(PaymentRequests);
