/**
 * Created by Berezin Igor
 * Full Stack Developer
 * on 25/06/2018.
 */
import types from '../../actions/actionTypes';
import { createReducer } from '../helpers/redux-helpers';

const initialState = {
    isLoading: true,
    item: null,
};

const singlePaymentReducer = createReducer({
    [types.LOGISTICS.FETCH_SINGLE_PAYMENT_SUCCESS]: (state, action) => ({
        ...state,
        isLoading: false,
        item: {
            ...action.data,
        },
    }),
}, initialState)

export default singlePaymentReducer
