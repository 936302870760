/**
 * Created by Igor Berezin
 * Full Stack Developer
 * on 25/06/2018.
 */
import { takeEvery, takeLatest, put, call, all } from 'redux-saga/effects';
import moment from 'moment';

import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
  fetchSinglePaymentFailed, fetchSinglePaymentSuccess, addPayment
} from '../../actions/logistics/singlePaymentActions';
import getBaseUrl, { config } from '../../utils/envSetup';

const CHATS_URL = config('chatsUrl');
const baseUrl = getBaseUrl();

function* fetchSinglePaymentAsync(id) {
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/get/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchSinglePaymentFailed(response.err.toString()));
  } else {
    const resData = response.data[0];
    resData.created = moment(response.data[0].created).format("YYYY-MM-DD HH:mm:ss")
    if(resData.status === 'fulfilled') resData.canBeRefunded = true;
    yield put(fetchSinglePaymentSuccess(resData));
  }
}

function* changePaymentStatus({payload}) {
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/status/change/${payload.payment._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: payload.status, url: CHATS_URL })
    },
  );

  if(response.err) console.error(response.err);
  else yield put(addPayment(payload));
}

function* fetchSinglePayment() {
  yield takeLatest([`${types.LOGISTICS.FETCH_SINGLE_PAYMENT}`], ({ id }) => fetchSinglePaymentAsync(id));
}


function* watchChangePaymentStatus() {
  yield takeEvery(action => /^CHANGE_.+_STATUS$/.test(action.type), changePaymentStatus);
}

export default function* singlePaymentSaga(id) {
  yield all([
    fetchSinglePayment(id),
    watchChangePaymentStatus(),
  ]);
}
