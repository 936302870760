import { Checkbox, FormControlLabel, TextField, withStyles } from "@material-ui/core";


const InputField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #1B1B1B',
        borderRadius: '8px',
        height: '48px',
        margin: '0px 0px 10px',
      },
    },
  },
})(TextField);

export const CountryFilterField = ({ clearInput, name, inputText, inputHandler, country, itemName, onChange, countryName, handleChange }) => {
  const widthStyle = {
    width: 420
  }
  const overflowStyle = {
    marginTop: 30,
    height: country?.length < 2 ? 70 : 200,
    overflowY: 'auto',
    overflowX: 'hidden'
  }
  return (
    <div>
      <InputField style={widthStyle} autoFocus='autoFocus' value={inputText} onChange={(e) => inputHandler(e, itemName)} size='small' variant='outlined' placeholder='search' />
      <div style={overflowStyle}>
        {country?.map((item, index) => {
          return (
            <div>
              <FormControlLabel
                key={`${item.id}-country`}
                control={<Checkbox color='primary' checked={
                  countryName?.id === item?.id && countryName?.[item?.name]
                } name="checkedC" onChange={handleChange(item?.name, item?.iso2_name, item?.id, itemName)
                } />}
                label={item.name}
              />
            </div>
          )
        })}</div>
    </div>
  )
}