/*
Roman Safonov
22/03/2018

Redux actions for collection
*/
import types from './actionTypes';

export const deleteCollection = id => ({
  type: types.DELETE_COLLECTION,
  id,
});

export const deleteCollectionSuccess = () => ({
  type: types.DELETE_COLLECTION_SUCCESS,
});

export const deleteCollectionFailed = reason => ({
  type: types.DELETE_COLLECTION_FAILED,
  reason,
});

export const updateCollection = data => ({
  type: types.UPDATE_COLLECTION,
  data,
});

export const updateCollectionSuccess = () => ({
  type: types.UPDATE_COLLECTION_SUCCESS,
});

export const updateCollectionFailed = reason => ({
  type: types.UPDATE_COLLECTION_FAILED,
  reason,
});

export const fetchCollection = id => ({
  type: types.FETCH_COLLECTION,
  id,
});

export const fetchCollectionSuccess = data => ({
  type: types.FETCH_COLLECTION_SUCCESS,
  data,
});

export const fetchCollectionFailed = reason => ({
  type: types.FETCH_COLLECTION_FAILED,
  reason,
});
