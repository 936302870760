import React, { Component } from "react";
import "./completed.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import SetupIcon from "material-ui/svg-icons/action/build";
import IconButton from "material-ui/IconButton";
import MenuItem from "material-ui/MenuItem";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import IconMenu from "material-ui/IconMenu";
import Checkbox from "material-ui/Checkbox";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { RaisedButton } from "material-ui";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import NoPermission from "../../../components/nopermission";
import { getPermission } from "../../../utils/cookies";
import { decodePermissions } from "../../../utils/permissionUtil";
import PaginatedGrid from "../../../components/paginatedgrid";
import * as completedActions from "../../../actions/logistics/completedactions";
import * as packageActions from "../../../actions/logistics/packageActions";
import ImageViewer from "../../../components/imageviewer";
import SearchField from "../../../components/searchfield";
import SelectDialog from "../../../components/SelectDialog";
import { changePaymentStatus } from "../../../actions/logistics/singlePaymentActions";
import getBaseUrl from "../../../utils/envSetup";
import Package from "../../../containers/logistics/package";
import "../index.css";
import { tableData } from "../../../utils/tableData";
const baseUrl = getBaseUrl();

const chooseImageMapping = item => {
  if (item.receipts.length)
    return item.receipts.map(({ imageUrl }) => ({ src: imageUrl || "" }));
  if (item.imageUrls) return item.imageUrls.map(url => ({ src: url }));
  return [];
};

const VALID_SHUPPER_ADDRESS_STATUS = "active";
const SHIPPING_STATUS = "ready for shipping";
class Completed extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      isOpenImageViewer: false,
      images: [],
      selected: [],
      confirmDialogOpen: false,
      enabledRefund: false,
      changeStatus: false,
      itemToChangeStatus: null,
      itemToChangeStatusKey: null
    };
  }

  componentDidMount() {
    if (this.state.permission.seeFulfilled) {
      this.props.fetchCompleted();
    }
  }

  openImageViewer = images => {
    this.setState({
      isOpenImageViewer: true,
      images
    });
  };

  closeImageViewer = () => {
    this.setState({
      isOpenImageViewer: false
    });
  };

  openStatusDialog = (itemToChangeStatus, itemToChangeStatusKey) => () => {
    this.setState({
      changeStatus: true,
      itemToChangeStatus,
      itemToChangeStatusKey
    });
  };

  closeStatusDialog = () => {
    this.setState({
      changeStatus: false
    });
  };

  makeRefund = () => {
    const { selected } = this.state;
    const { makeRefund } = this.props;
    makeRefund(selected[0]);
  };

  handleSelection = selectedItems => {
    const { completedList } = this.props;
    const result = [];
    selectedItems.forEach(selectedItem => {
      result.push(completedList[selectedItem]);
    });
    this.setState({ selected: result });
  };

  openConfirmRefundDialog = () => {
    this.setState({
      confirmDialogOpen: true
    });
  };

  closeConfirmRefundDialog = () => {
    this.setState({
      confirmDialogOpen: false,
      enabledRefund: false
    });
  };

  onDateRangeSet(startDate, endDate) {
    this.props.fetchCompletedDateRangeChanges(startDate, endDate);
  }

  doSearch(query) {
    this.props.fetchCompleted(query);
  }

  addStatusToCompleted(id) {
    this.props.addStatusToComplete(id);
  }

  isShupperAddressPresent = item => {
    if (item.shupper && item.shupper.address) {
      const validCountriesName = [
        "Israel",
        "ישראל",
        "ארצות הברית",
        "United States Of America",
        "Usa",
        "Соединенные Штаты Америки",
        "United States"
      ].map(i => i.toLowerCase());
      return (
        item.shupper.address.status === VALID_SHUPPER_ADDRESS_STATUS &&
        validCountriesName.includes(
          item.shupper.address.countryName.toLowerCase()
        )
      );
    } else {
      return false;
    }
  };

  render() {
    const { logisticsCompleted: { columnWidths, headerData } } = tableData
    const {
      onOpenDrawer,
      fetchMoreCompleted,
      completedList,
      hasMore,
      isLoading,
      totalCount,
      sort,
      fetchCompletedSortChanged,
      startDate,
      endDate,
      changePaymentStatus,
      idOfChangingStatus,
      isPackageModalOpen
    } = this.props;
    const {
      permission,
      isOpenImageViewer,
      images,
      selected,
      confirmDialogOpen,
      enabledRefund,
      itemToChangeStatus,
      itemToChangeStatusKey,
      changeStatus
    } = this.state;
    const tableHeight = window.innerHeight - 217;
    const isDisabledRefund = !(
      permission.refundPayment && selected.length === 1
    );

    return permission.seeFulfilled ? (
      <div className="logistics-container">
        {isPackageModalOpen ? <Package /> : null}
        <SelectDialog
          items={[
            "cancelled",
            "fulfilled",
            "pending",
            "refunded",
            "rejected",
            "shipping"
          ]}
          type="status"
          handleChange={changePaymentStatus}
          value="fulfilled"
          payment={itemToChangeStatus}
          position={itemToChangeStatusKey}
          display={changeStatus}
          close={this.closeStatusDialog}
        />
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <div className="header-search-area">
            <SearchField onRequestSearch={query => this.doSearch(query)} />
          </div>
          <div className="logistics-date-range-picker-cont">
            <DateRangePicker
              drops="down"
              opens="center"
              startDate={
                startDate
                  ? moment(startDate)
                  : moment("01/01/2017", "DD/MM/YYYY")
              }
              endDate={endDate ? moment(endDate) : moment()}
              timePicker
              timePicker24Hour
              showDropdowns
              onApply={(ev, picker) => {
                this.onDateRangeSet(
                  picker.startDate.toDate().toJSON(),
                  picker.endDate.toDate().toJSON()
                );
              }}
              ranges={{
                "All Times": [moment("01/01/2017", "DD/MM/YYYY"), moment()],
                "Last 24 hours": [moment().subtract(1, "days"), moment()],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "Month to date": [moment().startOf("month"), moment()]
              }}
            >
              <RaisedButton
                label={
                  startDate && endDate
                    ? `${moment(startDate).format("lll")}
									- ${moment(endDate).format("lll")}`
                    : "Pick date range"
                }
                primary
              />
            </DateRangePicker>
          </div>
          <a
            href={
              baseUrl +
              "/api/logistics/fulfilled?csv=true" +
              (startDate ? `&startDate=${startDate}` : "") +
              (endDate ? `&endDate=${endDate}` : "")
            }
            download
          >
            <RaisedButton label="Download CSV" primary />
          </a>
          <span className="header-placeholder" />
          <IconMenu
            iconButtonElement={
              <IconButton>
                <MoreVertIcon color="#fff" />
              </IconButton>
            }
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}
          >
            <MenuItem
              primaryText="Make Refund"
              disabled={isDisabledRefund}
              onClick={this.openConfirmRefundDialog}
            />
          </IconMenu>
        </div>

        <div className="logistics-collections-tabs">
          <Link to="/logistics/pending" className="logistics-collections-tab">
            Pending
          </Link>
          <Link to="/logistics/rejected" className="logistics-collections-tab">
            Rejected
          </Link>
          <Link to="/logistics/canceled" className="logistics-collections-tab">
            Canceled
          </Link>
          <Link
            to="/logistics/completed"
            className="logistics-collections-tab logistics-collections-tab-active"
          >
            Completed
          </Link>
          <Link to="/logistics/refunded" className="logistics-collections-tab">
            Refunded
          </Link>
          <Link to="/logistics/shipping" className="logistics-collections-tab">
            Shipping
          </Link>
        </div>
        <PaginatedGrid
          data={completedList}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          hasMore={hasMore}
          isLoading={isLoading}
          header={headerData}
          totalResults={totalCount}
          onSelectRow={this.handleSelection}
          selection={selected}
          sortableCols={headerData[0].id}
          currentSort={sort}
          onSort={(column, direction) =>
            fetchCompletedSortChanged(column, direction)
          }
          items={completedList.map((item, key) => {
            const dateTime = moment(item.created).format("YYYY-MM-DD HH:mm:ss");
            const images = chooseImageMapping(item);
            const isShupperAddressPresent = this.isShupperAddressPresent(item);
            return [
              <Link to={`/logistics/payment/${item._id}`}>
                <span>{dateTime}</span>
              </Link>,
              <span>{item.user && item.user.displayName}</span>,
              <span>{item.user && item.user.email}</span>,
              <span>{item.shupper && item.shupper.displayName}</span>,
              <span>{item.receipts.length}</span>,
              <span>
                {images.length ? (
                  <img
                    alt=""
                    className="avatar viewer"
                    src={images[0].src || ""}
                    onClick={() => this.openImageViewer(images)}
                  />
                ) : null}
              </span>,
              <span>{item.promoCode && item.promoCode.code}</span>,
              <span>
                {item.promoCode &&
                  `${item.promoCode.discountValue} ${item.promoCode.discountType === "fixed" ? "$" : "%"
                  }`}
              </span>,
              <span>
                {item.receipts.map(receipt => receipt.price).join(", ")}
              </span>,
              <span>{item.itemsTotalPrice}</span>,
              <span>{item.deliveryFee}</span>,
              <span>{item.taxes}</span>,
              <span>{item.shupperzFee}</span>,
              <span>{item.payeeFee}</span>,
              <span>{item.totalPrice}</span>,
              <span>{item.tip || 0}</span>,
              <span>
                {item.tip ? item.totalPrice + item.tip : item.totalPrice}
              </span>,
              <span>{item._id}</span>,
              <span>{item.defaultShupperzFee}</span>,
              <span>{item.paypalPaymentId}</span>,
              <span>
                <a
                  href={`https://www.paypal.com/activity/payment/${item.paypalTransactionId
                    }`}
                  target="_blank"
                >
                  {item.paypalTransactionId}
                </a>
              </span>,
              <span>{item.trackingCode}</span>,

              <span>
                {item.address && item.address.address
                  ? item.address.address
                  : item.user.address && item.user.address.address}
              </span>,
              <span>
                {item.address && item.address.city
                  ? item.address.city
                  : item.user.address && item.user.address.city}
              </span>,
              <span>
                {item.address && item.address.countryName
                  ? item.address.countryName
                  : item.user.address && item.user.address.countryName}
              </span>,
              <span>
                {item.address && item.address.zipCode
                  ? item.address.zipCode
                  : item.user.address && item.user.address.zipCode}
              </span>,
              <span>
                {item.address && item.address.phoneNumber
                  ? item.address.phoneNumber
                  : item.user.address && item.user.address.phoneNumber}
              </span>,
              <span>
                {item.address &&
                  item.address.state &&
                  item.address.state === "undefined"
                  ? ""
                  : item.address && item.address.state
                    ? item.address.state
                    : item.user.address &&
                      item.user.address.state &&
                      item.user.address.state !== "undefined"
                      ? item.user.address.state
                      : ""}
              </span>,
              <span>
                {item.address && item.address.identityNumber
                  ? item.address.identityNumber
                  : item.user.address.identityNumber}
              </span>,
              <span className="brand-block">
                {item.receipts && item.receipts.length > 0
                  ? item.receipts
                    .filter(item => item.brandStr)
                    .map(item => item.brandStr)
                    .join(", ")
                  : //   item.receipts.map(item=>{
                  //   return <span className="brand-item"><img className="brand-image" src={item.imageUrl}/><span>{item.brandStr}</span></span>
                  // })
                  ""}
              </span>,
              <div className={"completed-button"}>
                {item.status === "fulfilled" &&
                  item.trackingCode &&
                  item.trackingCode.length ? (
                  item.shippingStatus === SHIPPING_STATUS ? (
                    "Moved to Shipping"
                  ) : (
                    <div className={"completed-button"}>
                      <RaisedButton
                        disabled={
                          isPackageModalOpen || !isShupperAddressPresent
                        }
                        label={
                          item._id === idOfChangingStatus
                            ? "Loading..."
                            : "Prepare Shipping"
                        }
                        primary
                        onClick={() =>
                          this.props.createPackage(
                            item._id,
                            item.trackingCode,
                            item.user._id,
                            item.shupper._id,
                            item.user.email,
                            item.shupper.email
                          )
                        }
                      />
                      {!isShupperAddressPresent ? (
                        <p className={"completed-p"}>
                          Shupper address is not valid
                        </p>
                      ) : null}
                    </div>
                  )
                ) : null}
              </div>,
              <span>
                {permission.changePaymentStatus ? (
                  <IconButton onClick={this.openStatusDialog(item, key)}>
                    <SetupIcon color="black" />
                  </IconButton>
                ) : null}
              </span>
            ];
          })}
          onLoadMore={fetchMoreCompleted}
        />
        <ImageViewer
          isOpen={isOpenImageViewer}
          onClose={this.closeImageViewer}
          images={images}
        />
        <Dialog
          contentStyle={{ width: "300px" }}
          title={`Confirm refund with`}
          actions={[
            <FlatButton
              label="Yes"
              disabled={!enabledRefund}
              primary
              onClick={() => {
                this.makeRefund();
                this.closeConfirmRefundDialog();
              }}
            />,
            <FlatButton
              label="No"
              primary
              onClick={this.closeConfirmRefundDialog}
            />
          ]}
          modal={false}
          open={confirmDialogOpen}
          onRequestClose={() => this.setState({ feedIndexDialogOpen: false })}
        >
          {selected[0] && [
            <p key={0}>{`user: ${selected[0].user.displayName}`}</p>,
            <p key={1}>{`shupper: ${selected[0].shupper.displayName}`}</p>,
            <p key={2}>{`amount: ${selected[0].totalPrice}`}</p>,
            <p key={3}>{`created: ${moment(selected[0].created).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}</p>
          ]}
          <Checkbox
            checked={enabledRefund}
            label="I understand and want to make refund"
            onCheck={(_, isSet) => this.setState({ enabledRefund: isSet })}
          />
        </Dialog>
      </div>
    ) : (
      <NoPermission onOpenDrawer={onOpenDrawer} />
    );
  }
}

Completed.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  completedList: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  fetchCompleted: PropTypes.func.isRequired,
  fetchMoreCompleted: PropTypes.func.isRequired,
  makeRefund: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  fetchCompletedSortChanged: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  fetchCompletedDateRangeChanges: PropTypes.func.isRequired,
  idOfChangingStatus: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  completedList: state.logistics.completed.logistics,
  hasMore: state.logistics.completed.hasMore,
  isLoading: state.logistics.completed.isLoading,
  totalCount: state.logistics.completed.totalCount,
  sort: state.logistics.completed.sort,
  startDate: state.logistics.completed.startDate,
  endDate: state.logistics.completed.endDate,
  idOfChangingStatus: state.logistics.completed.idOfChangingStatus,
  isPackageModalOpen: state.logistics.packageItem.isOpen
});

const mapDispatchToProps = dispatch => ({
  fetchCompleted: query => dispatch(completedActions.fetchCompleted(query)),
  fetchMoreCompleted: () => dispatch(completedActions.fetchMoreCompleted()),
  makeRefund: id => dispatch(completedActions.makeRefund(id)),
  fetchCompletedSortChanged: (column, direction) =>
    dispatch(completedActions.fetchCompletedSortChanged(column, direction)),
  fetchCompletedDateRangeChanges: (startDate, endDate) =>
    dispatch(
      completedActions.fetchCompletedDateRangeChanges(startDate, endDate)
    ),
  changePaymentStatus: ({ prevStatus, payment, status, position }) =>
    dispatch(changePaymentStatus({ prevStatus, payment, status, position })),
  downloadCsv: () => dispatch(completedActions.downloadCsv()),
  addStatusToComplete: id => dispatch(completedActions.addStatusToComplete(id)),
  createPackage: (
    id,
    tr_code,
    user_id,
    shupper_id,
    user_email,
    shupper_email
  ) =>
    dispatch(
      packageActions.createPackage(
        id,
        tr_code,
        user_id,
        shupper_id,
        user_email,
        shupper_email
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Completed);
