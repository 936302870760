export const paymentDefine = {
  1: 'Pending',
  2: 'Canceled',
  3: 'Completed',
};
export const packageDefine = {
  1: 'Open',
  3: 'Sent',
  4: 'Received',
  5: 'Confirmed',
  6: 'Disputed',
};
export const packageSubstateDefine = {
  1: 'Missing information',
  2: 'Ready for Shipping',
  3: 'Pickup Scheduled',
  4: 'Dropped',
};
export const paymentDetailsState = {
  1: 'Sent',
  2: 'Approved',
  3: 'Error',
  4: 'Pending',
  5: 'Canceled',
  6: 'Autorized',
  7: 'Received',
};
