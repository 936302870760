/*
Kateryna Ivanova
20/03/2018

Redux actions for events list loading
*/
import types from './actionTypes';

export const fetchEvent = id => ({
  type: types.FETCH_EVENT,
  id,
});

export const fetchEventSuccess = data => ({
  type: types.FETCH_EVENT_SUCCESS,
  data,
});

export const fetchEventFailed = reason => ({
  type: types.FETCH_EVENT_FAILED,
  reason,
});

export const updateEvent = event => ({
  type: types.UPDATE_EVENT,
  event,
});

export const createEvent = event => ({
  type: types.CREATE_EVENT,
  event,
});

export const updateEventSuccess = () => ({
  type: types.UPDATE_EVENT_SUCCESS,
});

export const updateEventFailed = reason => ({
  type: types.UPDATE_EVENT_FAILED,
  reason,
});

export const deleteEvent = ids => ({
  type: types.DELETE_EVENT,
  ids,
});

export const deleteEventSuccess = () => ({
  type: types.DELETE_EVENT_SUCCESS,
});

export const deleteEventFailed = reason => ({
  type: types.DELETE_EVENT_FAILED,
  reason,
});

