import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import MenuIcon from '@material-ui/icons/Menu';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box } from '@material-ui/core';
import AllowedCountriesTab from '../components/settingsTabs/AllowedCountriesTab';

import CountriesAndZones from '../components/settingsTabs/CountriesAndZones';
import Attributes from '../components/settingsTabs/Attributes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchZone, getCountryZones } from '../actions/settingsActions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#000000',
  },
})(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: 'Overpass',
    borderBottom: '2px solid grey',
    fontSize: '14px',
    color: '#000000',
    padding: '0',
    '&:hover': {
      color: '#000000',
      opacity: 1,
    },
    '&$selected': {
      color: '#000000',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: '#000000',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  title: {
    padding: '23px 30px 0 30px'
  },
  tabsTitleWrapper: {
    padding: '0px 30px'
  }
}));

const Settings = ({ onOpenDrawer, ...props }) => {
  const classes = useStyles();
  const countriesZonesData = useSelector(({ settingsReducer }) => settingsReducer?.countriesZones);
  const zonesData = useSelector(({ settingsReducer }) => settingsReducer?.zone);

  const dispatch = useDispatch();

  const [value, setValue] = useState(1);
  useEffect(() => {

    dispatch(getCountryZones());
    dispatch(fetchZone());

  }, [])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="App-header">
        <IconButton onClick={onOpenDrawer}>
          <MenuIcon color="primary" />
        </IconButton>
      </div>
      <div className={classes.title}>
        <Typography variant="h5" className="settings-main-title">
          SETTINGS
        </Typography>
      </div>
      <div className={classes.root}>
        <div className={classes.tabsTitleWrapper}>
          <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
            <StyledTab label="ALLOWED COUNTRIES" {...a11yProps(0)} />
            <StyledTab label="TAXES &amp; ZONES" {...a11yProps(1)} />
            <StyledTab label="ATTRIBUTES" {...a11yProps(2)} />
          </StyledTabs>
        </div>
        <TabPanel value={value} index={0}>
          <AllowedCountriesTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CountriesAndZones countriesZonesData={countriesZonesData} zonesData={zonesData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Attributes />
        </TabPanel>
      </div>
    </>
  );
};

export default Settings;
