import React from 'react';
import moment from 'moment';
import { Button, Grid, Typography, Paper, withStyles } from '@material-ui/core';


const TypographyText = withStyles({
  root: {
    '&.MuiTypography-root': {
      fontFamily: 'Overpass',
      fontSize: '16px',
      color: '#8d8d8d',
      marginBottom: '10px',
    },
  },
})(Typography);

const PaymentsRequestPackage = (props) => {
  const { mainBtnText, packageInfoDetails, paymentId } = props;

  return (
    <Grid container direction="row" justifyContent="space-between" >
      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Typography variant="h5" className="paymentRequest-subtitle">
            PACKAGE
          </Typography>
          <Typography variant="h6" className="editLink">
            Edit
          </Typography>
        </Grid>
        <Paper className="info-wrapper paymentInfo-wrapper" >
          <Grid container>
            <Grid container className="payment-info-text" direction="column">
              <Typography variant="h6" className="payment-info-subtitle">
                Attached Receipt
              </Typography>
              <Grid container direction="row" className="atached-imgGroup-wrapper">
                <div>
                  <img className="atached-img-wrapper" src={packageInfoDetails?.receipts} /></div>
              </Grid>
            </Grid>
            <Typography variant="h6" className="payment-info-subtitle">
              Package information
            </Typography>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Package Status</TypographyText>
              <div>
                <div className={`status-info ${packageInfoDetails?.state ? 'status-open' : 'status-notCreated'}`}>{packageInfoDetails?.state ?? 'Not created'}</div>
              </div>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Package ID</TypographyText>
              <Typography>{packageInfoDetails?.id ?? '-'}</Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Barcode</TypographyText>
              <Typography>{packageInfoDetails?.barcode ?? '-'}</Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Date created</TypographyText>
              <Typography>{packageInfoDetails?.create_date ? moment(packageInfoDetails?.create_date).format('L h:mm') : '-'} </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Date updated</TypographyText>
              <Typography>{packageInfoDetails?.update_date ? moment(packageInfoDetails?.update_date).format('L h:mm') : '-'} </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Shipping Method</TypographyText>
              <Typography>{packageInfoDetails?.couriers ?? '-'}</Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Shipping Estimate</TypographyText>
              <Typography>{packageInfoDetails?.shipping_estimate_in_days ? packageInfoDetails?.shipping_estimate_in_days + ' days' : '-'
              } </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
              <TypographyText>Delivery Status</TypographyText>
              <Typography>{packageInfoDetails?.delivery_state ?? '-'}</Typography>
            </Grid>
            <Typography variant="h6" className="payment-info-subtitle">
              Included payment requests
            </Typography>
            {packageInfoDetails?.payment_uids?.map(item => {
              return (
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                  <TypographyText> {paymentId === item ? 'Payment ID(current)' : 'Payment ID'}</TypographyText>
                  <a href={`${paymentId === item ? 'javascript:void(0)' : `/payment-details/${item}`}`}>{item}</a>
                </Grid>
              )
            })}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="track-shipping-btnWrapper">
              <Button disabled={packageInfoDetails?.uid ? false : true} className={`${!packageInfoDetails?.uid ? 'requestPayment-disabled-btn' : 'requestPayment-main-btn'}`}>{mainBtnText}</Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PaymentsRequestPackage;
