import types from './actionTypes';

export const fetchAllBrands = data => ({
  type: types.FETCH_ALL_BRANDS,
  data,
});

export const fetchAllBrandsSuccess = data => ({
  type: types.FETCH_ALL_BRANDS_SUCCESS,
  data,
});

export const fetchAllBrandsFailed = reason => ({
  type: types.FETCH_ALL_BRANDS_FAILED,
  reason,
});

