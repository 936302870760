import React, { PureComponent } from "react";
import { connect } from 'react-redux'
import * as shippingPickupActions from "../../../actions/logistics/shippingPickupActions";
import PaginatedGrid from "../../../components/paginatedgrid";
import moment from 'moment'
import { tableData } from "../../../utils/tableData";
class ShippingPickup extends PureComponent {

    componentDidMount() {
        this.props.fetchShippingPickup()
    }
    componentWillUnmount() {
        this.props.clearShippingPickupData()
    }
    handleSelection = () => {

    }

    render() {
        const { shippingPickup: { columnWidths, headerData } } = tableData

        const { shippingPickupList, totalCount, hasMore, isLoading, fetchMoreShippingPickup } = this.props
        const tableHeight = window.innerHeight - 217;
        return <div className="logistics-container 123">
            <PaginatedGrid
                data={shippingPickupList}
                tableHeight={tableHeight}
                columnWidths={columnWidths}
                hasMore={hasMore}
                isLoading={isLoading}
                onSelectRow={this.handleSelection}
                header={headerData}
                totalResults={totalCount}
                items={shippingPickupList.map((item, key) => {
                    return [
                        <span>{`${moment.utc(item.dateFrom).format('DD.MM.YYYY HH:mm')} - ${moment.utc(item.dateTo).format('DD.MM.YYYY HH:mm')}`}</span>,
                        <span>{item.shupper && item.shupper.displayName}</span>,
                        <span>{item.shupper && item.shupper.location}</span>,
                        <span>Completed</span>,
                    ]
                })}
                onLoadMore={fetchMoreShippingPickup}
            />

        </div>;
    }

}

function mapStateToProps(state) {
    return {
        shippingPickupList: state.logistics.shippingPickup.logistics,
        hasMore: state.logistics.shippingPickup.hasMore,
        isLoading: state.logistics.shippingPickup.isLoading,
        totalCount: state.logistics.shippingPickup.totalCount,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchShippingPickup: query => dispatch(shippingPickupActions.fetchShippingPickUp(query)),
        fetchMoreShippingPickup: query => dispatch(shippingPickupActions.fetchMoreShippingPickup),
        clearShippingPickupData: () => dispatch(shippingPickupActions.clearShippingPickupData())
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippingPickup);

