import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import SearchField from '../components/searchfield';
import * as usersActions from '../actions/usersactions';
import { getPermission } from '../utils/cookies';
import NoPermission from '../components/nopermission';
import { decodePermissions } from '../utils/permissionUtil';
import { withRouter } from '../utils/withRouter';
import { tableData } from '../utils/tableData';
import { IconButton, makeStyles, Menu, Typography } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Filters } from '../components/filters';
import calendar from '../static/images/calendar.svg'
import { Buttons } from '../components/filters/buttons';
import { CalendarRangePicker } from '../components/dateRangePicker';
import TableData from '../components/table/index';
import { filtersObj } from '../utils/filtersData';


const useStyles = makeStyles({
    paper: {
        padding: 15,
    },
    title: {
        marginLeft: 12,
    },
    calendarBnts: {
        marginLeft: '40%'
    },
    calendar: {
        backgroundColor: '#141B41',
        borderRadius: '8px',
        padding: 18,
        height: 48,
        width: 180,
        margin: '10px 12px',
        '&:hover': {
            backgroundColor: '#141B41',
        }
    },
    calendarText: {
        color: 'white',
        marginRight: 55
    },
    table: {
        padding: 13,
    }
});

const UserList = React.memo(({ usersReducer, fetchMoreUsers, onOpenDrawer, ...props }) => {
    const classes = useStyles()
    const permission = decodePermissions(getPermission());

    const [date, setDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection"
        }
    ]);
    console.log('ysersReducer', usersReducer)
    const [state, setState] = React.useState({
        filterMenu: null
    });

    const [countryFilter, setCountryFilter] = React.useState()
    const [typeFilter, setTypeFilter] = useState()
    const [statusFilter, setStatusFilter] = useState()

    const { filterMenu, filterName } = state
    const { users: { usersList } } = tableData
    const { userFilters } = filtersObj

    useEffect(() => {

        if (permission.users) {
            props.fetchAllUsers();
        }
    }, [])


    const toggleFilter = React.useCallback((e) => {
        setState({ filterMenu: e })
    }, [filterMenu])

    const clearFilters = () => {
        props.clearFilters();
    };

    const [defineFilters, setDefineFilters] = useState()

    const doSearch = (query, roles, type, statusFilter, locationFilter, dateFilter) => {

        const data = {
            query,
            roles,
            type,
            statusFilter,
            locationFilter,
            dateFilter
        }
        setDefineFilters(data)
        props.fetchAllUsers(data)
    }

    const handleDateRange = (ranges) => {
        const { selection } = ranges;
        setDate([selection]);
    }

    const handleChange = (filterName, value, id) => (event) => {

        if (value.length === 2) setCountryFilter({ [filterName]: event.target.checked, value, id })
        if (value === 'status') setStatusFilter({ filterName, id })
        if (value === 'type') setTypeFilter({ filterName, id })
    }

    const applyFilters = () => {
        const dateObj = {
            from: date[0]?.startDate?.setHours(0, 0, 0, 0),
            to: date[0]?.endDate?.setHours(23, 59, 59, 999)
        }
        const type = typeFilter?.id
        const status = statusFilter?.id || statusFilter?.id === 0 && '0'
        const countryNotSelected = countryFilter && Object?.keys(countryFilter)?.some((k) => !countryFilter[k])
        const location = countryNotSelected ? '' : countryFilter?.id
        doSearch(null, null, type, status, location ?? '', dateObj)
    }

    return permission.users ?
        <div>
            <div className="App-header">
                <IconButton onClick={onOpenDrawer}>
                    <MenuIcon color='primary' />
                </IconButton>
                <span className="header-placeholder" />
            </div>
            <div className={classes.paper}>
                <Typography variant="h5" className={classes.title}>USERS</Typography>
                <div className="searchFilterContainer">
                    <div className='calendar'>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={e => toggleFilter(e.currentTarget)}
                            aria-controls="profile-menu"
                            className={classes.calendar}
                        >
                            <img src={calendar} style={{ marginRight: 35 }} />
                            <div>
                                <Typography className={classes.calendarText}>Today</Typography>
                            </div>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            getContentAnchorEl={null}
                            open={Boolean(filterMenu)}
                            anchorEl={filterMenu}
                            onClose={() => setState({ filterMenu: null })}
                            disableAutoFocusItem
                            PaperProps={{
                                className: classes.filterMenu
                            }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}>
                            <div >
                                <CalendarRangePicker date={date} handleChange={handleDateRange} />
                            </div>
                            <div className={classes.calendarBnts}>
                                <Buttons setFilterMenu={() => setState({ filterMenu: null })} applyFilters={applyFilters} date={date} />
                            </div>
                        </Menu>
                    </div>
                    <div className='userFilters'>
                        <Filters
                            countryName={countryFilter}
                            status={statusFilter}
                            type={typeFilter}
                            filterItems={userFilters}
                            applyFilters={applyFilters}
                            handleChange={handleChange} />
                    </div>
                    <div className='userSearch' style={{ paddingTop: 12 }}>
                        <SearchField doneColor={''} onRequestSearch={query => doSearch(query, null, null, null, null)} />
                    </div>
                </div>
                <div className={`${classes.table} usersTable`} >
                    <TableData
                        search={defineFilters}
                        tableName='users'
                        hasMore={usersReducer.hasMore}
                        tablePagination={true}
                        isLoading={usersReducer.isLoading}
                        data={usersReducer.users}
                        headerColumn={usersList}
                        totalResults={usersReducer.totalCount}
                        fetchMore={() => fetchMoreUsers()}
                    /></div>
            </div>
        </div >
        : <NoPermission onOpenDrawer={onOpenDrawer} />;
})

UserList.propTypes = {
    usersReducer: PropTypes.object.isRequired,
    onOpenDrawer: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
};

const mapStateToProps = ({ usersReducer }) => ({
    usersReducer
});

const mapDispatchToProps = dispatch => ({
    fetchAllUsers: (query, roles, roleFilter, statusFilter, genderFilter) =>
        dispatch(usersActions.fetchAllUsers(query, roles, roleFilter, statusFilter, genderFilter)),
    clearFilters: () => dispatch(usersActions.clearUsersFilters()),
    fetchMoreUsers: () => dispatch(usersActions.fetchMoreUsers()),
    dispatch,
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserList));