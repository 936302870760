import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import { DropDownMenu } from 'material-ui';


const Tier = ({ tiers, onChangeSelect, selectedTier }) => (
    <React.Fragment>
        <div className="container">
            <div className="row">
                <div className="col-12 ">
                    <DropDownMenu
                        value={selectedTier}
                        onChange={(e, val) => onChangeSelect(e, "tier", val)}
                        multiple={false}
                        style={{ minWidth: "100%" }}
                    >
                        <MenuItem value={null} primaryText="Select tier" />
                        {tiers.map((item, key) => (
                            <MenuItem key={key} value={item.id.value} primaryText={`Tier ${item.id.value}`} />
                        ))
                        }
                    </DropDownMenu>
                </div>
                <div className="col-6">
                    <div className="form-group"><div className="label">Upload content per week</div>
                        <p className="tier-value">{tiers && tiers.length > 0 &&
                            selectedTier ? tiers[selectedTier - 1].content.value : ""}</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group"><div className="label">Working hours per week</div>
                        <p className="tier-value">{tiers && tiers.length > 0 && selectedTier &&
                            tiers[selectedTier - 1].hours.value}</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group"><div className="label">Check-ins per week</div>
                        <p className="tier-value">{tiers && tiers.length > 0 && selectedTier &&
                            tiers[selectedTier - 1].checkins.value}</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group"><div className="label">Lives per month</div>
                        <p className="tier-value">{tiers && tiers.length > 0 && selectedTier &&
                            tiers[selectedTier - 1].lives.value}</p>
                    </div>

                </div>
                <div className="col-6">
                    <div className="form-group"><div className="label">Brands</div>
                        <p className="tier-value">{tiers && tiers.length > 0 &&
                            selectedTier && tiers[selectedTier - 1].brands.value}</p>
                    </div>

                </div>
                <div className="col-6">
                    <div className="form-group"><div className="label">Personal Social media</div>
                        <p className="tier-value">{tiers && tiers.length > 0 && selectedTier &&
                            tiers[selectedTier - 1].media.value}</p>
                    </div>

                </div>
                <div className="col-12">
                    <div className="form-group"><div className="label">Insights</div>
                        <p className="tier-value">{tiers && tiers.length > 0 && selectedTier &&
                            tiers[selectedTier - 1].insights.value}</p>
                    </div>

                </div>
                <div className="col-12">
                    <div className="form-group"><div className="label">Photoshoot sessions per month</div>
                        <p className="tier-value">{tiers && tiers.length > 0 && selectedTier &&
                            tiers[selectedTier - 1].photoshoot.value}</p>
                    </div>
                </div>
            </div>
        </div>

    </React.Fragment>);


export default (Tier);
