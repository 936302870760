import { IconButton, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getPermission } from "../../utils/cookies";
import { decodePermissions } from "../../utils/permissionUtil";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid } from "react-bootstrap";
import TableData from "../table";
import { withRouter } from "../../utils/withRouter";
import { tableData } from "../../utils/tableData";
import NoPermission from "../nopermission";

const PaymentRequestResponses = ({ onOpenDrawer, location }) => {
  const permission = decodePermissions(getPermission());
  const navigate = useNavigate();

  const {
    paymentRequestResponses: { headerData },
  } = tableData;

  return permission.users ?
    <div className="main">
      <div className="App-header">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon color="primary" />
        </IconButton>
      </div>
      <Grid container direction="row" justifyContent="space-between" style={{ margin: '24px 0px 16px' }}>
        <Typography variant="h5">PAYMENT REQUEST RESPONSES</Typography>
      </Grid>
      <div style={{ padding: 13 }}>
        <TableData
          width='99%'
          tableName="paymentRequestResponses"
          data={location?.state?.requestResponses}
          headerColumn={headerData}
          tablePagination={true}
        />
      </div>
    </div>
    : <NoPermission onOpenDrawer={onOpenDrawer} />
}

export default withRouter(PaymentRequestResponses)