import React from 'react';

const LightBox = ({ display, closeRequest, children }) => (
    display ?
    <div className="flxc ac jc lightbox-overlay">
        <div className="close-lightbox" onClick={closeRequest}>CLOSE</div>
        {children}
    </div> : null
);


export default LightBox;