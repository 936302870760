/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 07/05/2018.
 */
import types from '../actions/actionTypes';

const initialState = {
  coupon: null,
  isLoading: false,
  isUpdating: false,
  updateSuccess: false,
  updateError: null,
  isDeleting: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COUPON:
      return {
        ...state,
        isLoading: true,
        coupon: null,
        updateSuccess: false,
        updateError: null,
      };
    case types.FETCH_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupon: action.data,
      };
    case types.FETCH_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case types.UPDATE_COUPON:
      return {
        ...state,
        isUpdating: true,
        updateSuccess: false,
      };
    case types.CREATE_COUPON:
      return {
        ...state,
        isUpdating: true,
        updateSuccess: false,
      };
    case types.UPDATE_COUPON_SUCCESS:
      return {
        state,
        isUpdating: false,
        updateSuccess: true,
      };
    case types.UPDATE_COUPON_FAILED:
      return {
        ...state,
        isUpdating: false,
        updateError: action.reason,
        updateSuccess: false,
      };

    case types.DELETE_COUPON:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
      };
    case types.DELETE_COUPON_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
      };
    case types.DELETE_COUPON_FAILED:
      return {
        ...state,
        isDeleting: false,
        deleteError: action.reason,
        deleteSuccess: false,
      };
    default:
      return state;
  }
}
