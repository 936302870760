/**
 * Created by Berezin Igor
 * NodeJs Developer
 * on 25/06/2018.
 */
import types from '../actionTypes';

export const fetchSinglePayment = id => ({
  type: types.LOGISTICS.FETCH_SINGLE_PAYMENT,
  id
});

export const fetchSinglePaymentSuccess = data => ({
  type: types.LOGISTICS.FETCH_SINGLE_PAYMENT_SUCCESS,
  data,
});

export const fetchSinglePaymentFailed = reason => ({
  type: types.LOGISTICS.FETCH_SINGLE_PAYMENT_FAILED,
  reason,
});

export const changePaymentStatus = ({prevStatus, payment, status, position}) => ({
type: types.LOGISTICS.CHANGE_PAYMENT_STATUS.replace(/\*/ig, prevStatus.toUpperCase()),
  payload: {
    payment, status, position,
  }
});

export const addPayment = ({payment, status}) => ({
  type: types.LOGISTICS.ADD_PAYMENT.replace(/\*/ig, status.toUpperCase()),
    payload: {
      payment, status,
    }
  })
