import React from 'react';
import moment from 'moment';
import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import { eventsV2Define } from '../../utils/eventV2Util';
import { convertUsersCountryToString } from '../../utils/countriesAndZones';

const TypographyText = withStyles({
    root: {
        '&.MuiTypography-root': {
        fontFamily: 'Overpass',
        fontSize: '16px',
        color: '#8d8d8d',
        marginBottom: '10px',
        },
    },
})(Typography);

const EventDetails = ({event}) => {
    const item = {}
    return (
        <div>
            <Grid container direction="row" justifyContent="space-between">
                <Typography variant="h5" className="paymentRequest-subtitle">
                    EVENT
                </Typography>
            </Grid>
            <Paper className="info-wrapper paymentInfo-wrapper">
                <Grid container>
                <Typography variant="h6" className="payment-info-subtitle">
                    Details
                </Typography>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Event ID</TypographyText>
                    <Typography>{event?.id}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Shupper Name</TypographyText>
                    <Typography>{event?.shupper_full_name}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Country</TypographyText>
                    <Typography>{convertUsersCountryToString(event?.shupper_country_id)?.[0]?.name}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Date Created</TypographyText>
                    <Typography>{moment(event?.create_date).format('L h:mm')}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>State</TypographyText>
                    <Typography>{eventsV2Define[event?.state_id]}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>End Date</TypographyText>
                    <Typography>{moment(event?.from_date).format('L h:mm')}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Start Date</TypographyText>
                    <Typography>{moment(event?.to_date).format('L h:mm')}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Brand</TypographyText>
                    <Typography>{event?.item_brand_name}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Max Participants</TypographyText>
                    <Typography>{event?.max_participants}</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
                    <TypographyText>Description</TypographyText>
                    <Typography>{event?.description}</Typography>
                </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default EventDetails;
