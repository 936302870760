export const countries = [
  {
    id: 1,
    name: 'Afghanistan',
    iso3_name: 'AFG',
    iso2_name: 'AF',
    zone: 51,
  },
  {
    id: 2,
    name: 'Albania',
    iso3_name: 'ALB',
    iso2_name: 'AL',
    zone: 52,
  },
  {
    id: 3,
    name: 'Algeria',
    iso3_name: 'DZA',
    iso2_name: 'DZ',
    zone: 53,
  },
  {
    id: 4,
    name: 'American Samoa',
    iso3_name: 'ASM',
    iso2_name: 'AS',
    zone: 54,
  },
  {
    id: 5,
    name: 'Andorra',
    iso3_name: 'AND',
    iso2_name: 'AD',
    zone: 55,
  },
  {
    id: 6,
    name: 'Angola',
    iso3_name: 'AGO',
    iso2_name: 'AO',
    zone: 56,
  },
  {
    id: 7,
    name: 'Anguilla',
    iso3_name: 'AIA',
    iso2_name: 'AI',
    zone: 57,
  },
  {
    id: 8,
    name: 'Antarctica',
    iso3_name: 'ATA',
    iso2_name: 'AQ',
    zone: 58,
  },
  {
    id: 9,
    name: 'Antigua and Barbuda',
    iso3_name: 'ATG',
    iso2_name: 'AG',
    zone: 59,
  },
  {
    id: 10,
    name: 'Argentina',
    iso3_name: 'ARG',
    iso2_name: 'AR',
    zone: 60,
  },
  {
    id: 11,
    name: 'Armenia',
    iso3_name: 'ARM',
    iso2_name: 'AM',
    zone: 61,
  },
  {
    id: 12,
    name: 'Aruba',
    iso3_name: 'ABW',
    iso2_name: 'AW',
    zone: 62,
  },
  {
    id: 13,
    name: 'Australia',
    iso3_name: 'AUS',
    iso2_name: 'AU',
    zone: 63,
  },
  {
    id: 14,
    name: 'Austria',
    iso3_name: 'AUT',
    iso2_name: 'AT',
    zone: 64,
  },
  {
    id: 15,
    name: 'Azerbaijan',
    iso3_name: 'AZE',
    iso2_name: 'AZ',
    zone: 65,
  },
  {
    id: 16,
    name: 'Bahamas (the)',
    iso3_name: 'BHS',
    iso2_name: 'BS',
    zone: 66,
  },
  {
    id: 17,
    name: 'Bahrain',
    iso3_name: 'BHR',
    iso2_name: 'BH',
    zone: 67,
  },
  {
    id: 18,
    name: 'Bangladesh',
    iso3_name: 'BGD',
    iso2_name: 'BD',
    zone: 68,
  },
  {
    id: 19,
    name: 'Barbados',
    iso3_name: 'BRB',
    iso2_name: 'BB',
    zone: 69,
  },
  {
    id: 20,
    name: 'Belarus',
    iso3_name: 'BLR',
    iso2_name: 'BY',
    zone: 70,
  },
  {
    id: 21,
    name: 'Belgium',
    iso3_name: 'BEL',
    iso2_name: 'BE',
    zone: 71,
  },
  {
    id: 22,
    name: 'Belize',
    iso3_name: 'BLZ',
    iso2_name: 'BZ',
    zone: 72,
  },
  {
    id: 23,
    name: 'Benin',
    iso3_name: 'BEN',
    iso2_name: 'BJ',
    zone: 73,
  },
  {
    id: 24,
    name: 'Bermuda',
    iso3_name: 'BMU',
    iso2_name: 'BM',
    zone: 74,
  },
  {
    id: 25,
    name: 'Bhutan',
    iso3_name: 'BTN',
    iso2_name: 'BT',
    zone: 75,
  },
  {
    id: 26,
    name: 'Bolivia (Plurinational State of)',
    iso3_name: 'BOL',
    iso2_name: 'BO',
    zone: 76,
  },
  {
    id: 27,
    name: 'Bonaire, Sint Eustatius and Saba',
    iso3_name: 'BES',
    iso2_name: 'BQ',
    zone: 77,
  },
  {
    id: 28,
    name: 'Bosnia and Herzegovina',
    iso3_name: 'BIH',
    iso2_name: 'BA',
    zone: 78,
  },
  {
    id: 29,
    name: 'Botswana',
    iso3_name: 'BWA',
    iso2_name: 'BW',
    zone: 79,
  },
  {
    id: 30,
    name: 'Bouvet Island',
    iso3_name: 'BVT',
    iso2_name: 'BV',
    zone: 80,
  },
  {
    id: 31,
    name: 'Brazil',
    iso3_name: 'BRA',
    iso2_name: 'BR',
    zone: 81,
  },
  {
    id: 32,
    name: 'British Indian Ocean Territory (the)',
    iso3_name: 'IOT',
    iso2_name: 'IO',
    zone: 82,
  },
  {
    id: 33,
    name: 'Brunei Darussalam',
    iso3_name: 'BRN',
    iso2_name: 'BN',
    zone: 83,
  },
  {
    id: 34,
    name: 'Bulgaria',
    iso3_name: 'BGR',
    iso2_name: 'BG',
    zone: 84,
  },
  {
    id: 35,
    name: 'Burkina Faso',
    iso3_name: 'BFA',
    iso2_name: 'BF',
    zone: 85,
  },
  {
    id: 36,
    name: 'Burundi',
    iso3_name: 'BDI',
    iso2_name: 'BI',
    zone: 86,
  },
  {
    id: 37,
    name: 'Cabo Verde',
    iso3_name: 'CPV',
    iso2_name: 'CV',
    zone: 87,
  },
  {
    id: 38,
    name: 'Cambodia',
    iso3_name: 'KHM',
    iso2_name: 'KH',
    zone: 88,
  },
  {
    id: 39,
    name: 'Cameroon',
    iso3_name: 'CMR',
    iso2_name: 'CM',
    zone: 89,
  },
  {
    id: 40,
    name: 'Canada',
    iso3_name: 'CAN',
    iso2_name: 'CA',
    zone: 90,
  },
  {
    id: 41,
    name: 'Cayman Islands (the)',
    iso3_name: 'CYM',
    iso2_name: 'KY',
    zone: 91,
  },
  {
    id: 42,
    name: 'Central African Republic (the)',
    iso3_name: 'CAF',
    iso2_name: 'CF',
    zone: 92,
  },
  {
    id: 43,
    name: 'Chad',
    iso3_name: 'TCD',
    iso2_name: 'TD',
    zone: 93,
  },
  {
    id: 44,
    name: 'Chile',
    iso3_name: 'CHL',
    iso2_name: 'CL',
    zone: 94,
  },
  {
    id: 45,
    name: 'China',
    iso3_name: 'CHN',
    iso2_name: 'CN',
    zone: 95,
  },
  {
    id: 46,
    name: 'Christmas Island',
    iso3_name: 'CXR',
    iso2_name: 'CX',
    zone: 96,
  },
  {
    id: 47,
    name: 'Cocos (Keeling) Islands (the)',
    iso3_name: 'CCK',
    iso2_name: 'CC',
    zone: 97,
  },
  {
    id: 48,
    name: 'Colombia',
    iso3_name: 'COL',
    iso2_name: 'CO',
    zone: 98,
  },
  {
    id: 49,
    name: 'Comoros (the)',
    iso3_name: 'COM',
    iso2_name: 'KM',
    zone: 99,
  },
  {
    id: 50,
    name: 'Congo (the Democratic Republic of the)',
    iso3_name: 'COD',
    iso2_name: 'CD',
    zone: 100,
  },
  {
    id: 51,
    name: 'Congo (the)',
    iso3_name: 'COG',
    iso2_name: 'CG',
    zone: 101,
  },
  {
    id: 52,
    name: 'Cook Islands (the)',
    iso3_name: 'COK',
    iso2_name: 'CK',
    zone: 102,
  },
  {
    id: 53,
    name: 'Costa Rica',
    iso3_name: 'CRI',
    iso2_name: 'CR',
    zone: 103,
  },
  {
    id: 54,
    name: 'Croatia',
    iso3_name: 'HRV',
    iso2_name: 'HR',
    zone: 104,
  },
  {
    id: 55,
    name: 'Cuba',
    iso3_name: 'CUB',
    iso2_name: 'CU',
    zone: 105,
  },
  {
    id: 56,
    name: 'Curaçao',
    iso3_name: 'CUW',
    iso2_name: 'CW',
    zone: 106,
  },
  {
    id: 57,
    name: 'Cyprus',
    iso3_name: 'CYP',
    iso2_name: 'CY',
    zone: 107,
  },
  {
    id: 58,
    name: 'Czechia',
    iso3_name: 'CZE',
    iso2_name: 'CZ',
    zone: 108,
  },
  {
    id: 59,
    name: "Côte d'Ivoire",
    iso3_name: 'CIV',
    iso2_name: 'CI',
    zone: 109,
  },
  {
    id: 60,
    name: 'Denmark',
    iso3_name: 'DNK',
    iso2_name: 'DK',
    zone: 110,
  },
  {
    id: 61,
    name: 'Djibouti',
    iso3_name: 'DJI',
    iso2_name: 'DJ',
    zone: 111,
  },
  {
    id: 62,
    name: 'Dominica',
    iso3_name: 'DMA',
    iso2_name: 'DM',
    zone: 112,
  },
  {
    id: 63,
    name: 'Dominican Republic (the)',
    iso3_name: 'DOM',
    iso2_name: 'DO',
    zone: 113,
  },
  {
    id: 64,
    name: 'Ecuador',
    iso3_name: 'ECU',
    iso2_name: 'EC',
    zone: 114,
  },
  {
    id: 65,
    name: 'Egypt',
    iso3_name: 'EGY',
    iso2_name: 'EG',
    zone: 115,
  },
  {
    id: 66,
    name: 'El Salvador',
    iso3_name: 'SLV',
    iso2_name: 'SV',
    zone: 116,
  },
  {
    id: 67,
    name: 'Equatorial Guinea',
    iso3_name: 'GNQ',
    iso2_name: 'GQ',
    zone: 117,
  },
  {
    id: 68,
    name: 'Eritrea',
    iso3_name: 'ERI',
    iso2_name: 'ER',
    zone: 118,
  },
  {
    id: 69,
    name: 'Estonia',
    iso3_name: 'EST',
    iso2_name: 'EE',
    zone: 119,
  },
  {
    id: 70,
    name: 'Eswatini',
    iso3_name: 'SWZ',
    iso2_name: 'SZ',
    zone: 120,
  },
  {
    id: 71,
    name: 'Ethiopia',
    iso3_name: 'ETH',
    iso2_name: 'ET',
    zone: 121,
  },
  {
    id: 72,
    name: 'Falkland Islands (the) [Malvinas]',
    iso3_name: 'FLK',
    iso2_name: 'FK',
    zone: 122,
  },
  {
    id: 73,
    name: 'Faroe Islands (the)',
    iso3_name: 'FRO',
    iso2_name: 'FO',
    zone: 123,
  },
  {
    id: 74,
    name: 'Fiji',
    iso3_name: 'FJI',
    iso2_name: 'FJ',
    zone: 124,
  },
  {
    id: 75,
    name: 'Finland',
    iso3_name: 'FIN',
    iso2_name: 'FI',
    zone: 125,
  },
  {
    id: 76,
    name: 'France',
    iso3_name: 'FRA',
    iso2_name: 'FR',
    zone: 126,
  },
  {
    id: 77,
    name: 'French Guiana',
    iso3_name: 'GUF',
    iso2_name: 'GF',
    zone: 127,
  },
  {
    id: 78,
    name: 'French Polynesia',
    iso3_name: 'PYF',
    iso2_name: 'PF',
    zone: 128,
  },
  {
    id: 79,
    name: 'French Southern Territories (the)',
    iso3_name: 'ATF',
    iso2_name: 'TF',
    zone: 129,
  },
  {
    id: 80,
    name: 'Gabon',
    iso3_name: 'GAB',
    iso2_name: 'GA',
    zone: 130,
  },
  {
    id: 81,
    name: 'Gambia (the)',
    iso3_name: 'GMB',
    iso2_name: 'GM',
    zone: 131,
  },
  {
    id: 82,
    name: 'Georgia',
    iso3_name: 'GEO',
    iso2_name: 'GE',
    zone: 132,
  },
  {
    id: 83,
    name: 'Germany',
    iso3_name: 'DEU',
    iso2_name: 'DE',
    zone: 133,
  },
  {
    id: 84,
    name: 'Ghana',
    iso3_name: 'GHA',
    iso2_name: 'GH',
    zone: 134,
  },
  {
    id: 85,
    name: 'Gibraltar',
    iso3_name: 'GIB',
    iso2_name: 'GI',
    zone: 135,
  },
  {
    id: 86,
    name: 'Greece',
    iso3_name: 'GRC',
    iso2_name: 'GR',
    zone: 136,
  },
  {
    id: 87,
    name: 'Greenland',
    iso3_name: 'GRL',
    iso2_name: 'GL',
    zone: 137,
  },
  {
    id: 88,
    name: 'Grenada',
    iso3_name: 'GRD',
    iso2_name: 'GD',
    zone: 138,
  },
  {
    id: 89,
    name: 'Guadeloupe',
    iso3_name: 'GLP',
    iso2_name: 'GP',
    zone: 139,
  },
  {
    id: 90,
    name: 'Guam',
    iso3_name: 'GUM',
    iso2_name: 'GU',
    zone: 140,
  },
  {
    id: 91,
    name: 'Guatemala',
    iso3_name: 'GTM',
    iso2_name: 'GT',
    zone: 141,
  },
  {
    id: 92,
    name: 'Guernsey',
    iso3_name: 'GGY',
    iso2_name: 'GG',
    zone: 142,
  },
  {
    id: 93,
    name: 'Guinea',
    iso3_name: 'GIN',
    iso2_name: 'GN',
    zone: 143,
  },
  {
    id: 94,
    name: 'Guinea-Bissau',
    iso3_name: 'GNB',
    iso2_name: 'GW',
    zone: 144,
  },
  {
    id: 95,
    name: 'Guyana',
    iso3_name: 'GUY',
    iso2_name: 'GY',
    zone: 145,
  },
  {
    id: 96,
    name: 'Haiti',
    iso3_name: 'HTI',
    iso2_name: 'HT',
    zone: 146,
  },
  {
    id: 97,
    name: 'Heard Island and McDonald Islands',
    iso3_name: 'HMD',
    iso2_name: 'HM',
    zone: 147,
  },
  {
    id: 98,
    name: 'Holy See (the)',
    iso3_name: 'VAT',
    iso2_name: 'VA',
    zone: 148,
  },
  {
    id: 99,
    name: 'Honduras',
    iso3_name: 'HND',
    iso2_name: 'HN',
    zone: 149,
  },
  {
    id: 100,
    name: 'Hong Kong',
    iso3_name: 'HKG',
    iso2_name: 'HK',
    zone: 150,
  },
  {
    id: 101,
    name: 'Hungary',
    iso3_name: 'HUN',
    iso2_name: 'HU',
    zone: 151,
  },
  {
    id: 102,
    name: 'Iceland',
    iso3_name: 'ISL',
    iso2_name: 'IS',
    zone: 152,
  },
  {
    id: 103,
    name: 'India',
    iso3_name: 'IND',
    iso2_name: 'IN',
    zone: 153,
  },
  {
    id: 104,
    name: 'Indonesia',
    iso3_name: 'IDN',
    iso2_name: 'ID',
    zone: 154,
  },
  {
    id: 105,
    name: 'Iran (Islamic Republic of)',
    iso3_name: 'IRN',
    iso2_name: 'IR',
    zone: 155,
  },
  {
    id: 106,
    name: 'Iraq',
    iso3_name: 'IRQ',
    iso2_name: 'IQ',
    zone: 156,
  },
  {
    id: 107,
    name: 'Ireland',
    iso3_name: 'IRL',
    iso2_name: 'IE',
    zone: 157,
  },
  {
    id: 108,
    name: 'Isle of Man',
    iso3_name: 'IMN',
    iso2_name: 'IM',
    zone: 158,
  },
  {
    id: 109,
    name: 'Israel',
    iso3_name: 'ISR',
    iso2_name: 'IL',
    zone: 159,
  },
  {
    id: 110,
    name: 'Italy',
    iso3_name: 'ITA',
    iso2_name: 'IT',
    zone: 160,
  },
  {
    id: 111,
    name: 'Jamaica',
    iso3_name: 'JAM',
    iso2_name: 'JM',
    zone: 161,
  },
  {
    id: 112,
    name: 'Japan',
    iso3_name: 'JPN',
    iso2_name: 'JP',
    zone: 162,
  },
  {
    id: 113,
    name: 'Jersey',
    iso3_name: 'JEY',
    iso2_name: 'JE',
    zone: 163,
  },
  {
    id: 114,
    name: 'Jordan',
    iso3_name: 'JOR',
    iso2_name: 'JO',
    zone: 164,
  },
  {
    id: 115,
    name: 'Kazakhstan',
    iso3_name: 'KAZ',
    iso2_name: 'KZ',
    zone: 165,
  },
  {
    id: 116,
    name: 'Kenya',
    iso3_name: 'KEN',
    iso2_name: 'KE',
    zone: 166,
  },
  {
    id: 117,
    name: 'Kiribati',
    iso3_name: 'KIR',
    iso2_name: 'KI',
    zone: 167,
  },
  {
    id: 118,
    name: "Korea (the Democratic People's Republic of)",
    iso3_name: 'PRK',
    iso2_name: 'KP',
    zone: 168,
  },
  {
    id: 119,
    name: 'Korea (the Republic of)',
    iso3_name: 'KOR',
    iso2_name: 'KR',
    zone: 169,
  },
  {
    id: 120,
    name: 'Kuwait',
    iso3_name: 'KWT',
    iso2_name: 'KW',
    zone: 170,
  },
  {
    id: 121,
    name: 'Kyrgyzstan',
    iso3_name: 'KGZ',
    iso2_name: 'KG',
    zone: 171,
  },
  {
    id: 122,
    name: "Lao People's Democratic Republic (the)",
    iso3_name: 'LAO',
    iso2_name: 'LA',
    zone: 172,
  },
  {
    id: 123,
    name: 'Latvia',
    iso3_name: 'LVA',
    iso2_name: 'LV',
    zone: 173,
  },
  {
    id: 124,
    name: 'Lebanon',
    iso3_name: 'LBN',
    iso2_name: 'LB',
    zone: 174,
  },
  {
    id: 125,
    name: 'Lesotho',
    iso3_name: 'LSO',
    iso2_name: 'LS',
    zone: 175,
  },
  {
    id: 126,
    name: 'Liberia',
    iso3_name: 'LBR',
    iso2_name: 'LR',
    zone: 176,
  },
  {
    id: 127,
    name: 'Libya',
    iso3_name: 'LBY',
    iso2_name: 'LY',
    zone: 177,
  },
  {
    id: 128,
    name: 'Liechtenstein',
    iso3_name: 'LIE',
    iso2_name: 'LI',
    zone: 178,
  },
  {
    id: 129,
    name: 'Lithuania',
    iso3_name: 'LTU',
    iso2_name: 'LT',
    zone: 179,
  },
  {
    id: 130,
    name: 'Luxembourg',
    iso3_name: 'LUX',
    iso2_name: 'LU',
    zone: 180,
  },
  {
    id: 131,
    name: 'Macao',
    iso3_name: 'MAC',
    iso2_name: 'MO',
    zone: 181,
  },
  {
    id: 132,
    name: 'Madagascar',
    iso3_name: 'MDG',
    iso2_name: 'MG',
    zone: 182,
  },
  {
    id: 133,
    name: 'Malawi',
    iso3_name: 'MWI',
    iso2_name: 'MW',
    zone: 183,
  },
  {
    id: 134,
    name: 'Malaysia',
    iso3_name: 'MYS',
    iso2_name: 'MY',
    zone: 184,
  },
  {
    id: 135,
    name: 'Maldives',
    iso3_name: 'MDV',
    iso2_name: 'MV',
    zone: 185,
  },
  {
    id: 136,
    name: 'Mali',
    iso3_name: 'MLI',
    iso2_name: 'ML',
    zone: 186,
  },
  {
    id: 137,
    name: 'Malta',
    iso3_name: 'MLT',
    iso2_name: 'MT',
    zone: 187,
  },
  {
    id: 138,
    name: 'Marshall Islands (the)',
    iso3_name: 'MHL',
    iso2_name: 'MH',
    zone: 188,
  },
  {
    id: 139,
    name: 'Martinique',
    iso3_name: 'MTQ',
    iso2_name: 'MQ',
    zone: 189,
  },
  {
    id: 140,
    name: 'Mauritania',
    iso3_name: 'MRT',
    iso2_name: 'MR',
    zone: 190,
  },
  {
    id: 141,
    name: 'Mauritius',
    iso3_name: 'MUS',
    iso2_name: 'MU',
    zone: 191,
  },
  {
    id: 142,
    name: 'Mayotte',
    iso3_name: 'MYT',
    iso2_name: 'YT',
    zone: 192,
  },
  {
    id: 143,
    name: 'Mexico',
    iso3_name: 'MEX',
    iso2_name: 'MX',
    zone: 193,
  },
  {
    id: 144,
    name: 'Micronesia (Federated States of)',
    iso3_name: 'FSM',
    iso2_name: 'FM',
    zone: 194,
  },
  {
    id: 145,
    name: 'Moldova (the Republic of)',
    iso3_name: 'MDA',
    iso2_name: 'MD',
    zone: 195,
  },
  {
    id: 146,
    name: 'Monaco',
    iso3_name: 'MCO',
    iso2_name: 'MC',
    zone: 196,
  },
  {
    id: 147,
    name: 'Mongolia',
    iso3_name: 'MNG',
    iso2_name: 'MN',
    zone: 197,
  },
  {
    id: 148,
    name: 'Montenegro',
    iso3_name: 'MNE',
    iso2_name: 'ME',
    zone: 198,
  },
  {
    id: 149,
    name: 'Montserrat',
    iso3_name: 'MSR',
    iso2_name: 'MS',
    zone: 199,
  },
  {
    id: 150,
    name: 'Morocco',
    iso3_name: 'MAR',
    iso2_name: 'MA',
    zone: 200,
  },
  {
    id: 151,
    name: 'Mozambique',
    iso3_name: 'MOZ',
    iso2_name: 'MZ',
    zone: 201,
  },
  {
    id: 152,
    name: 'Myanmar',
    iso3_name: 'MMR',
    iso2_name: 'MM',
    zone: 202,
  },
  {
    id: 153,
    name: 'Namibia',
    iso3_name: 'NAM',
    iso2_name: 'NA',
    zone: 203,
  },
  {
    id: 154,
    name: 'Nauru',
    iso3_name: 'NRU',
    iso2_name: 'NR',
    zone: 204,
  },
  {
    id: 155,
    name: 'Nepal',
    iso3_name: 'NPL',
    iso2_name: 'NP',
    zone: 205,
  },
  {
    id: 156,
    name: 'Netherlands (the)',
    iso3_name: 'NLD',
    iso2_name: 'NL',
    zone: 206,
  },
  {
    id: 157,
    name: 'New Caledonia',
    iso3_name: 'NCL',
    iso2_name: 'NC',
    zone: 207,
  },
  {
    id: 158,
    name: 'New Zealand',
    iso3_name: 'NZL',
    iso2_name: 'NZ',
    zone: 208,
  },
  {
    id: 159,
    name: 'Nicaragua',
    iso3_name: 'NIC',
    iso2_name: 'NI',
    zone: 209,
  },
  {
    id: 160,
    name: 'Niger (the)',
    iso3_name: 'NER',
    iso2_name: 'NE',
    zone: 210,
  },
  {
    id: 161,
    name: 'Nigeria',
    iso3_name: 'NGA',
    iso2_name: 'NG',
    zone: 211,
  },
  {
    id: 162,
    name: 'Niue',
    iso3_name: 'NIU',
    iso2_name: 'NU',
    zone: 212,
  },
  {
    id: 163,
    name: 'Norfolk Island',
    iso3_name: 'NFK',
    iso2_name: 'NF',
    zone: 213,
  },
  {
    id: 164,
    name: 'Northern Mariana Islands (the)',
    iso3_name: 'MNP',
    iso2_name: 'MP',
    zone: 214,
  },
  {
    id: 165,
    name: 'Norway',
    iso3_name: 'NOR',
    iso2_name: 'NO',
    zone: 215,
  },
  {
    id: 166,
    name: 'Oman',
    iso3_name: 'OMN',
    iso2_name: 'OM',
    zone: 216,
  },
  {
    id: 167,
    name: 'Pakistan',
    iso3_name: 'PAK',
    iso2_name: 'PK',
    zone: 217,
  },
  {
    id: 168,
    name: 'Palau',
    iso3_name: 'PLW',
    iso2_name: 'PW',
    zone: 218,
  },
  {
    id: 169,
    name: 'Palestine, State of',
    iso3_name: 'PSE',
    iso2_name: 'PS',
    zone: 219,
  },
  {
    id: 170,
    name: 'Panama',
    iso3_name: 'PAN',
    iso2_name: 'PA',
    zone: 220,
  },
  {
    id: 171,
    name: 'Papua New Guinea',
    iso3_name: 'PNG',
    iso2_name: 'PG',
    zone: 221,
  },
  {
    id: 172,
    name: 'Paraguay',
    iso3_name: 'PRY',
    iso2_name: 'PY',
    zone: 222,
  },
  {
    id: 173,
    name: 'Peru',
    iso3_name: 'PER',
    iso2_name: 'PE',
    zone: 223,
  },
  {
    id: 174,
    name: 'Philippines (the)',
    iso3_name: 'PHL',
    iso2_name: 'PH',
    zone: 224,
  },
  {
    id: 175,
    name: 'Pitcairn',
    iso3_name: 'PCN',
    iso2_name: 'PN',
    zone: 225,
  },
  {
    id: 176,
    name: 'Poland',
    iso3_name: 'POL',
    iso2_name: 'PL',
    zone: 226,
  },
  {
    id: 177,
    name: 'Portugal',
    iso3_name: 'PRT',
    iso2_name: 'PT',
    zone: 227,
  },
  {
    id: 178,
    name: 'Puerto Rico',
    iso3_name: 'PRI',
    iso2_name: 'PR',
    zone: 228,
  },
  {
    id: 179,
    name: 'Qatar',
    iso3_name: 'QAT',
    iso2_name: 'QA',
    zone: 229,
  },
  {
    id: 180,
    name: 'Republic of North Macedonia',
    iso3_name: 'MKD',
    iso2_name: 'MK',
    zone: 230,
  },
  {
    id: 181,
    name: 'Romania',
    iso3_name: 'ROU',
    iso2_name: 'RO',
    zone: 231,
  },
  {
    id: 182,
    name: 'Russian Federation (the)',
    iso3_name: 'RUS',
    iso2_name: 'RU',
    zone: 232,
  },
  {
    id: 183,
    name: 'Rwanda',
    iso3_name: 'RWA',
    iso2_name: 'RW',
    zone: 233,
  },
  {
    id: 184,
    name: 'Réunion',
    iso3_name: 'REU',
    iso2_name: 'RE',
    zone: 234,
  },
  {
    id: 185,
    name: 'Saint Barthélemy',
    iso3_name: 'BLM',
    iso2_name: 'BL',
    zone: 235,
  },
  {
    id: 186,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    iso3_name: 'SHN',
    iso2_name: 'SH',
    zone: 236,
  },
  {
    id: 187,
    name: 'Saint Kitts and Nevis',
    iso3_name: 'KNA',
    iso2_name: 'KN',
    zone: 237,
  },
  {
    id: 188,
    name: 'Saint Lucia',
    iso3_name: 'LCA',
    iso2_name: 'LC',
    zone: 238,
  },
  {
    id: 189,
    name: 'Saint Martin (French part)',
    iso3_name: 'MAF',
    iso2_name: 'MF',
    zone: 239,
  },
  {
    id: 190,
    name: 'Saint Pierre and Miquelon',
    iso3_name: 'SPM',
    iso2_name: 'PM',
    zone: 240,
  },
  {
    id: 191,
    name: 'Saint Vincent and the Grenadines',
    iso3_name: 'VCT',
    iso2_name: 'VC',
    zone: 241,
  },
  {
    id: 192,
    name: 'Samoa',
    iso3_name: 'WSM',
    iso2_name: 'WS',
    zone: 242,
  },
  {
    id: 193,
    name: 'San Marino',
    iso3_name: 'SMR',
    iso2_name: 'SM',
    zone: 243,
  },
  {
    id: 194,
    name: 'Sao Tome and Principe',
    iso3_name: 'STP',
    iso2_name: 'ST',
    zone: 244,
  },
  {
    id: 195,
    name: 'Saudi Arabia',
    iso3_name: 'SAU',
    iso2_name: 'SA',
    zone: 245,
  },
  {
    id: 196,
    name: 'Senegal',
    iso3_name: 'SEN',
    iso2_name: 'SN',
    zone: 246,
  },
  {
    id: 197,
    name: 'Serbia',
    iso3_name: 'SRB',
    iso2_name: 'RS',
    zone: 247,
  },
  {
    id: 198,
    name: 'Seychelles',
    iso3_name: 'SYC',
    iso2_name: 'SC',
    zone: 248,
  },
  {
    id: 199,
    name: 'Sierra Leone',
    iso3_name: 'SLE',
    iso2_name: 'SL',
    zone: 249,
  },
  {
    id: 200,
    name: 'Singapore',
    iso3_name: 'SGP',
    iso2_name: 'SG',
    zone: 250,
  },
  {
    id: 201,
    name: 'Sint Maarten (Dutch part)',
    iso3_name: 'SXM',
    iso2_name: 'SX',
    zone: 251,
  },
  {
    id: 202,
    name: 'Slovakia',
    iso3_name: 'SVK',
    iso2_name: 'SK',
    zone: 252,
  },
  {
    id: 203,
    name: 'Slovenia',
    iso3_name: 'SVN',
    iso2_name: 'SI',
    zone: 253,
  },
  {
    id: 204,
    name: 'Solomon Islands',
    iso3_name: 'SLB',
    iso2_name: 'SB',
    zone: 254,
  },
  {
    id: 205,
    name: 'Somalia',
    iso3_name: 'SOM',
    iso2_name: 'SO',
    zone: 255,
  },
  {
    id: 206,
    name: 'South Africa',
    iso3_name: 'ZAF',
    iso2_name: 'ZA',
    zone: 256,
  },
  {
    id: 207,
    name: 'South Georgia and the South Sandwich Islands',
    iso3_name: 'SGS',
    iso2_name: 'GS',
    zone: 257,
  },
  {
    id: 208,
    name: 'South Sudan',
    iso3_name: 'SSD',
    iso2_name: 'SS',
    zone: 258,
  },
  {
    id: 209,
    name: 'Spain',
    iso3_name: 'ESP',
    iso2_name: 'ES',
    zone: 259,
  },
  {
    id: 210,
    name: 'Sri Lanka',
    iso3_name: 'LKA',
    iso2_name: 'LK',
    zone: 260,
  },
  {
    id: 211,
    name: 'Sudan (the)',
    iso3_name: 'SDN',
    iso2_name: 'SD',
    zone: 261,
  },
  {
    id: 212,
    name: 'Suriname',
    iso3_name: 'SUR',
    iso2_name: 'SR',
    zone: 262,
  },
  {
    id: 213,
    name: 'Svalbard and Jan Mayen',
    iso3_name: 'SJM',
    iso2_name: 'SJ',
    zone: 263,
  },
  {
    id: 214,
    name: 'Sweden',
    iso3_name: 'SWE',
    iso2_name: 'SE',
    zone: 264,
  },
  {
    id: 215,
    name: 'Switzerland',
    iso3_name: 'CHE',
    iso2_name: 'CH',
    zone: 265,
  },
  {
    id: 216,
    name: 'Syrian Arab Republic',
    iso3_name: 'SYR',
    iso2_name: 'SY',
    zone: 266,
  },
  {
    id: 217,
    name: 'Taiwan (Province of China)',
    iso3_name: 'TWN',
    iso2_name: 'TW',
    zone: 267,
  },
  {
    id: 218,
    name: 'Tajikistan',
    iso3_name: 'TJK',
    iso2_name: 'TJ',
    zone: 268,
  },
  {
    id: 219,
    name: 'Tanzania, United Republic of',
    iso3_name: 'TZA',
    iso2_name: 'TZ',
    zone: 269,
  },
  {
    id: 220,
    name: 'Thailand',
    iso3_name: 'THA',
    iso2_name: 'TH',
    zone: 270,
  },
  {
    id: 221,
    name: 'Timor-Leste',
    iso3_name: 'TLS',
    iso2_name: 'TL',
    zone: 271,
  },
  {
    id: 222,
    name: 'Togo',
    iso3_name: 'TGO',
    iso2_name: 'TG',
    zone: 272,
  },
  {
    id: 223,
    name: 'Tokelau',
    iso3_name: 'TKL',
    iso2_name: 'TK',
    zone: 273,
  },
  {
    id: 224,
    name: 'Tonga',
    iso3_name: 'TON',
    iso2_name: 'TO',
    zone: 274,
  },
  {
    id: 225,
    name: 'Trinidad and Tobago',
    iso3_name: 'TTO',
    iso2_name: 'TT',
    zone: 275,
  },
  {
    id: 226,
    name: 'Tunisia',
    iso3_name: 'TUN',
    iso2_name: 'TN',
    zone: 276,
  },
  {
    id: 227,
    name: 'Turkey',
    iso3_name: 'TUR',
    iso2_name: 'TR',
    zone: 277,
  },
  {
    id: 228,
    name: 'Turkmenistan',
    iso3_name: 'TKM',
    iso2_name: 'TM',
    zone: 278,
  },
  {
    id: 229,
    name: 'Turks and Caicos Islands (the)',
    iso3_name: 'TCA',
    iso2_name: 'TC',
    zone: 279,
  },
  {
    id: 230,
    name: 'Tuvalu',
    iso3_name: 'TUV',
    iso2_name: 'TV',
    zone: 280,
  },
  {
    id: 231,
    name: 'Uganda',
    iso3_name: 'UGA',
    iso2_name: 'UG',
    zone: 281,
  },
  {
    id: 232,
    name: 'Ukraine',
    iso3_name: 'UKR',
    iso2_name: 'UA',
    zone: 282,
  },
  {
    id: 233,
    name: 'United Arab Emirates (the)',
    iso3_name: 'ARE',
    iso2_name: 'AE',
    zone: 283,
  },
  {
    id: 234,
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    iso3_name: 'GBR',
    iso2_name: 'GB',
    zone: 284,
  },
  {
    id: 235,
    name: 'United States Minor Outlying Islands (the)',
    iso3_name: 'UMI',
    iso2_name: 'UM',
    zone: 285,
  },
  {
    id: 236,
    name: 'United States of America (the)',
    iso3_name: 'USA',
    iso2_name: 'US',
    zone: 286,
  },
  {
    id: 237,
    name: 'Uruguay',
    iso3_name: 'URY',
    iso2_name: 'UY',
    zone: 287,
  },
  {
    id: 238,
    name: 'Uzbekistan',
    iso3_name: 'UZB',
    iso2_name: 'UZ',
    zone: 288,
  },
  {
    id: 239,
    name: 'Vanuatu',
    iso3_name: 'VUT',
    iso2_name: 'VU',
    zone: 289,
  },
  {
    id: 240,
    name: 'Venezuela (Bolivarian Republic of)',
    iso3_name: 'VEN',
    iso2_name: 'VE',
    zone: 290,
  },
  {
    id: 241,
    name: 'Viet Nam',
    iso3_name: 'VNM',
    iso2_name: 'VN',
    zone: 291,
  },
  {
    id: 242,
    name: 'Virgin Islands (British)',
    iso3_name: 'VGB',
    iso2_name: 'VG',
    zone: 292,
  },
  {
    id: 243,
    name: 'Virgin Islands (U.S.)',
    iso3_name: 'VIR',
    iso2_name: 'VI',
    zone: 293,
  },
  {
    id: 244,
    name: 'Wallis and Futuna',
    iso3_name: 'WLF',
    iso2_name: 'WF',
    zone: 294,
  },
  {
    id: 245,
    name: 'Western Sahara',
    iso3_name: 'ESH',
    iso2_name: 'EH',
    zone: 295,
  },
  {
    id: 246,
    name: 'Yemen',
    iso3_name: 'YEM',
    iso2_name: 'YE',
    zone: 296,
  },
  {
    id: 247,
    name: 'Zambia',
    iso3_name: 'ZMB',
    iso2_name: 'ZM',
    zone: 297,
  },
  {
    id: 248,
    name: 'Zimbabwe',
    iso3_name: 'ZWE',
    iso2_name: 'ZW',
    zone: 298,
  },
  {
    id: 249,
    name: 'Åland Islands',
    iso3_name: 'ALA',
    iso2_name: 'AX',
    zone: 299,
  },
];
