/*
Kateryna Ivanova
22/03/2018

Component for picking a single in popup
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

class Singlepicker extends Component {
    constructor(props) {
        super(props);
        this.state = { selected: null };
    }

    componentWillReceiveProps(props) {
        this.setState({ selected: props.selected });
    }

    render() {
        const {
            onRequestClose,
            values,
            title,
            open,
            onDone,
        } = this.props;
        const { selected } = this.state;
        return values
            ?
            <Dialog
                title={title}
                actions={[<FlatButton
                    label="Set"
                    primary
                    onClick={() => onDone(selected)}
                />]}
                modal={false}
                open={open}
                onRequestClose={() => onRequestClose()}
            >
                <RadioButtonGroup
                    name="singlepick"
                    onChange={(_, value) => this.setState({ selected: value })}
                    valueSelected={selected}
                >
                    {values.map(item => (
                        <RadioButton
                            key={item.id}
                            value={item.id}
                            label={item.title}
                        />
                    ))}
                </RadioButtonGroup>
            </Dialog>
            :
            null;
    }
}

Singlepicker.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.string.isRequired,
    onDone: PropTypes.func.isRequired,
};

export default Singlepicker;
