/*
Kateryna Ivanova
20/03/2018

Sagas for working with a list of users
*/
import {takeLatest, takeEvery, put, call, all, select} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {
  fetchUsersSuccess,
  fetchUsersFailed,
  updateUserSuccess,
  updateUserFailed,
  regeneratePasswordFailed,
  regeneratePasswordSuccess,
  getShuppersOfTheDaySuccess,
  getShuppersOfTheDayFailed,
  setShupperOfTheDaySuccess,
  setShupperOfTheDayFailed,
  removeShupperOfTheDaySuccess,
  removeShupperOfTheDayFailed,
  updateUser as updateUserAction,
  getOnlyShuppersSuccess,
  getOnlyShuppersFailed,
  stopLoadingUsers,
  getTipsSuccess,
} from '../actions/usersactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import uploadImage from '../utils/networkutil';

const baseUrl = getBaseUrl();

const selectUsersState = state => state.usersReducerOld;

function* fetchUsersAsync(action) {
  const usersState = yield select(selectUsersState);
  const statusFilter = usersState.statusFilter ? `&status=${usersState.statusFilter}` : '';
  const genderFilter = usersState.genderFilter ? `&gender=${usersState.genderFilter}` : '';
  const roleFilter = action.roles
    ? action.roles.reduce((prev, curr) => `${prev}&role[]=${curr}`, '')
    : usersState.roleFilter && usersState.roleFilter.filter(i => i !== null).length
    ? `${usersState.roleFilter.filter(i => i !== null).reduce((prev, curr) => `${prev}&role[]=${curr}`, '')}`
    : '';
  const otherFilters = `${roleFilter}${statusFilter}${genderFilter}`;
  const sorting = Object.keys(usersState.sort)
    .map(key => `&sort[${key}]=${usersState.sort[key]}`)
    .join('');
  let searchQuery = '';
  if (usersState.searchQuery) {
    const isEmail = usersState.searchQuery.includes('@');
    searchQuery = isEmail
      ? `&email=${usersState.searchQuery}`
      : `&name=${usersState.searchQuery}&location=${usersState.searchQuery}`;
  }
  const response = yield call(
    request,
    `${baseUrl}/api/user?limit=${usersState.limit}&skip=${usersState.offset}${searchQuery}${sorting}${otherFilters}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchUsersFailed(response.err.toString()));
  } else {
    yield put(fetchUsersSuccess(response.data));
  }
}

function* updateUserAsync(action) {
  const body = {};
  const {status, pinnedLocation, feedIndex, roles, newImage, displayName} = action.data;
  if (status) body.status = status;
  if (pinnedLocation || pinnedLocation === '') body.pinnedLocation = pinnedLocation;
  if (feedIndex || feedIndex === '') body.feedIndex = feedIndex;
  if (roles) body.roles = roles;
  if (newImage) body.profileImageURL = newImage;
  if (displayName && displayName !== '') body.displayName = displayName;

  const response = yield call(request, `${baseUrl}/api/user/${action.data._id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.err) {
    yield put(updateUserFailed(response.err.toString()));
  } else {
    yield put(updateUserSuccess(response.data));
  }
}

function* bulkUpdateUserAsync(action) {
  const ids = action.ids.join(',');
  const status = action.data.status ? `&status=${action.data.status}` : '';
  const roles = action.data.roles ? `&roles=${action.data.roles.join(',')}` : '';
  const response = yield call(request, `${baseUrl}/api/user?ids=${ids}${status}${roles}`, {
    method: 'PUT',
  });
  if (response.err) {
    yield put(updateUserFailed(response.err.toString()));
  } else {
    yield put(updateUserSuccess(response.data));
  }
}

function* regeneratePasswordAsync(action) {
  const response = yield call(request, `${baseUrl}/api/user/regeneratepassword/${action.id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(regeneratePasswordFailed(response.err.toString()));
  } else {
    yield put(regeneratePasswordSuccess(response.data.password));
  }
}

function* regeneratePassword() {
  yield takeEvery(types.USER_REGENERATE_PASSWORD, regeneratePasswordAsync);
}

function* updateUserProfileImageAsync(action) {
  const {newImage, _id} = action.user;
  let profileImageURL = null;
  if (newImage) {
    const response = yield call(uploadImage, 'event', _id, newImage);
    if (response.err) {
      yield put(updateUserFailed(response.err.toString()));
      return;
    }
    profileImageURL = response.data.imgUrl;
  }
  if (profileImageURL) yield put(updateUserAction({newImage: profileImageURL, _id}));
}

function* getShuppersOfTheDayAsync() {
  const response = yield call(request, `${baseUrl}/api/user/shupper-of-the-day`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(getShuppersOfTheDayFailed(response.err.toString()));
  } else {
    yield put(getShuppersOfTheDaySuccess(response.data));
  }
}

function* setShupperOfTheDayAsync(action) {
  const body = {};
  const {weekday, shupper} = action;
  if (weekday) body.weekday = weekday;
  if (shupper) body.shupper = shupper;

  const response = yield call(request, `${baseUrl}/api/user/shupper-of-the-day`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.err) {
    yield put(setShupperOfTheDayFailed(response.err.toString()));
  } else {
    yield put(setShupperOfTheDaySuccess());
  }
}

function* moveItemsBetweenUsers({payload}) {
  const body = {
    owner: payload.from,
    newOwner: payload.to,
  };
  const response = yield call(request, `${baseUrl}/api/user/change-items-owner`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (response.err) console.err(response.err);
  yield put(stopLoadingUsers());
}

function* removeShupperOfTheDayAsync(action) {
  const {weekday} = action;

  const response = yield call(request, `${baseUrl}/api/user/shupper-of-the-day?weekday=${weekday}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(removeShupperOfTheDayFailed(response.err.toString()));
  } else {
    yield put(removeShupperOfTheDaySuccess());
  }
}
// TODO: refactor this (why do we need more functions to fetch users if we have one universal? p.s. it is not used here

function* fetchShuppersAsync({name}) {
  const response = yield call(request, `${baseUrl}/api/user/shuppers?name=${name || ''}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(getOnlyShuppersFailed(response.err.toString()));
  } else {
    yield put(getOnlyShuppersSuccess(response.data));
  }
}
function* fetchTips({payload}) {
  const response = yield call(request, `${baseUrl}/api/payment/tips?user=${payload.id}&year=${payload.year}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
  } else {
    yield put(getTipsSuccess(response.data));
  }
}

function* fetchShuppersAndStoresAsync({name}) {
  const response = yield call(request, `${baseUrl}/api/user/shuppers?name=${name || ''}&stores=true`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(getOnlyShuppersFailed(response.err.toString()));
  } else {
    yield put(getOnlyShuppersSuccess(response.data));
  }
}

function* updateUserProfileImage() {
  yield takeEvery(types.USER_UPDATE_PROFILE_IMAGE, updateUserProfileImageAsync);
}

function* fetchItems() {
  yield takeLatest(
    [
      types.FETCH_USERS,
      types.FETCH_MORE_USERS,
      types.FETCH_USERS_SORT_CHANGED,
      types.UPDATE_USER_SUCCESS,
      types.CLEAR_USERS_FILTERS,
    ],
    fetchUsersAsync,
  );
}

function* updateUser() {
  yield takeEvery(types.UPDATE_USER, updateUserAsync);
}

function* bulkUpdateUser() {
  yield takeEvery(types.BULK_UPDATE_USERS, bulkUpdateUserAsync);
}

function* getShuppersOfTheDay() {
  yield takeEvery(
    [types.GET_SHUPPERS_OF_THE_DAY, types.SET_SHUPPER_OF_THE_DAY_SUCCESS, types.REMOVE_SHUPPER_OF_THE_DAY_SUCCESS],
    getShuppersOfTheDayAsync,
  );
}

function* setShupperOfTheDay() {
  yield takeEvery(types.SET_SHUPPER_OF_THE_DAY, setShupperOfTheDayAsync);
}

function* removeShupperOfTheDay() {
  yield takeEvery(types.REMOVE_SHUPPER_OF_THE_DAY, removeShupperOfTheDayAsync);
}

function* fetchShuppers() {
  yield takeEvery(types.GET_ONLY_SHUPPERS, fetchShuppersAsync);
}

function* fetchShuppersAndStores() {
  yield takeEvery(types.GET_ONLY_SHUPPERS_AND_STORES, fetchShuppersAndStoresAsync);
}

function* watchMoveItemsToAnotherUser() {
  yield takeEvery(types.MOVE_ITEMS_FROM_USER_TO_ANOTHER, moveItemsBetweenUsers);
}
function* watchGetTips() {
  yield takeEvery(types.GET_TIPS, fetchTips);
}

export default function* usersSagaOld() {
  yield all([
    fetchItems(),
    updateUser(),
    bulkUpdateUser(),
    regeneratePassword(),
    updateUserProfileImage(),
    getShuppersOfTheDay(),
    setShupperOfTheDay(),
    removeShupperOfTheDay(),
    fetchShuppers(),
    fetchShuppersAndStores(),
    watchMoveItemsToAnotherUser(),
    watchGetTips(),
  ]);
}
