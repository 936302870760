import React from 'react';
import { Dialog, RaisedButton } from 'material-ui';
import Select from 'react-select'

class FowrardedMessages extends React.Component {
    constructor(props) {
        super(props)
        this.imageCanvas = null
    }

    render() {
        const { open, closeDialog, value, changeShupperForwardedMessages, selectedForwardedMessages,
            notification, activeShuppers, handleForwardChats, users, changeUsersForwardedMessages,
            userForwardedMessages, usersForSelect } = this.props
        return <div>
            <Dialog
                actions={[<RaisedButton
                    label={`Forward ${selectedForwardedMessages.length} chats`}
                    secondary
                    disabled={usersForSelect.length > 1 ? !userForwardedMessages || !value : !value}
                    onClick={handleForwardChats}
                />]}
                style={{ height: "100%", minHeight: "00px" }}
                modal={false}
                open={open}
                onRequestClose={closeDialog}
            >
                <div className="select-block-fowarded-messages">


                    {usersForSelect.length > 1 ? <Select value={userForwardedMessages}
                        style={{ height: "100%", marginBottom: "15px" }}
                        className="select-forwarded-messages"
                        placeholder="Select user"
                        onChange={changeUsersForwardedMessages}
                        options={users && users.length > 0 ? users.map(item => {
                            return { label: item.friendlyName, value: item.identity }
                        }) : []} /> : null}
                    <Select value={value}
                        style={{ height: "100%" }}
                        placeholder="Select shupper"
                        onChange={changeShupperForwardedMessages}
                        options={activeShuppers && activeShuppers.length > 0 ?
                            activeShuppers.map(item => {
                                return { label: item.displayName, value: item._id }
                            }) : []} />
                    {notification ? <span className="error-forwarded-messages"> {notification}</span> : null}
                </div>
            </Dialog>
        </div >
    }
}
export default FowrardedMessages;


