class Cookies {
  constructor() {
    this.cookies = localStorage.getItem('cookies');
    if (JSON.parse(this.cookies) === null) localStorage.setItem('cookies', JSON.stringify({}));
    this._restoreFromLocalStorage();
  }

  setToStorage(key, value) {
    const cookiesVal = JSON.parse(localStorage.getItem('cookies'));
    cookiesVal[key] = value;
    localStorage.setItem('cookies', JSON.stringify(cookiesVal));
  }

  _restoreFromLocalStorage() {
    const savedCookies = JSON.parse(localStorage.getItem('cookies'));
    Object.keys(savedCookies).map(val => {
      return this.setToCookies(val, savedCookies[val]);
    });
  }

  setToCookies(key, value) {
    document.cookie = `${key}=${value}`;
  }

  set(key, value) {
    this.setToStorage(key, value);
    this.setToCookies(key, value);
  }

  get(key) {
    const cookies = JSON.parse(localStorage.getItem('cookies'));
    return cookies[key] ? cookies[key] : null;
  }
}

const cookies = new Cookies();

export function setUserId(userId, permission) {
  cookies.set('userid', userId);
  cookies.set('permission', permission);
}

export function getUserId() {
  return cookies.get('userid') || '';
}

export function getPermission() {
  return cookies.get('permission') || 0;
}

export function logout() {
  setUserId('');
}
