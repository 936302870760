import React, { useMemo } from 'react';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { updateEventV2 } from '../../actions/eventV2Actions';
import { connect } from 'react-redux';

const EventMedia = ({ event, updateEventV2 }) => {
    const hiddenFileInput = React.useRef(null);

    const handleUpload = () => {
        hiddenFileInput.current.click();
    };

    const handleVideoFileChange = (e) => {
        updateEventV2({ id: event?.id, eventVideo: e.target.files[0] });
    }

    const vid_url = event?.vid_url

    return (
        <div>
            <Grid container direction="row" justifyContent="space-between">
                <Typography variant="h5" className="paymentRequest-subtitle">
                    MEDIA
                </Typography>
            </Grid>
            <Paper className='info-wrapper paymentInfo-wrapper'>
                <Grid container justifyContent='center'>
                <video key={vid_url} controls>
                    <source src={vid_url} type="video/mp4" />
                </video>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="track-shipping-btnWrapper">
                    <input
                    type="file"
                    name="eventVideo"
                    accept="video/*"
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    onChange={handleVideoFileChange}
                    />
                    <Button className={'requestPayment-main-btn'} onClick={handleUpload}>Upload New Video</Button>
                </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
  updateEventV2: data => dispatch(updateEventV2(data)),
});

export default connect(
  state => state,
  mapDispatchToProps,
)(EventMedia);