/*
Kateryna Ivanova
29/03/2018

Reducer for a BO users list
*/
import types from '../actions/actionTypes';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 100,
  bousers: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: '',
  sort: {},
};

export default function boUsersReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_BO_USERS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        bousers: [],
        totalCount: 0,
        searchQuery: action.query,
      });
    case types.DELETE_BO_USER_SUCCESS:
    case types.UPDATE_BO_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        bousers: [],
        totalCount: 0,
      });
    case types.FETCH_BO_USERS_SORT_CHANGED: {
      const sorting = procesSort(state.sort, action.column, action.direction);
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        bousers: [],
        totalCount: 0,
        sort: sorting,
      });
    }
    case types.FETCH_MORE_BO_USERS:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_BO_USERS_SUCCESS: {
      const bousers = [].concat(state.bousers).concat(action.data.bousers);
      return Object.assign({}, state, {
        offset: state.offset + state.limit,
        totalCount: action.data.count,
        bousers,
        hasMore: bousers.length < action.data.count,
        isLoading: false,
      });
    }
    case types.FETCH_BO_USERS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        hasMore: false,
      });
    default:
      return state;
  }
}
