/*
Roman Safonov
22/03/2018

Sagas for working with a single item
*/
import { takeLatest, put, call, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import request from '../utils/networkRequest';
import { deleteItemSuccess, deleteItemFailed, updateItemFailed, updateItemSuccess, fetchItemFailed, fetchItemSuccess } from '../actions/itemactions';
import getBaseUrl from '../utils/envSetup';
import { uploadItemFile } from '../utils/networkutil';

const baseUrl = getBaseUrl();


function* deleteItemAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/item/${action.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteItemFailed(response.err.toString()));
  } else {
    yield put(deleteItemSuccess());
  }
}

function* updateItemAsync(action) {
  const body = {};
  let profileImageURL = null;
  let videoURL = null;

  if (action.data.description !== undefined) body.description = action.data.description;
  if (action.data.created !== undefined) body.created = action.data.created;
  if (action.data.updated !== undefined) body.updated = action.data.updated;
  if (action.data.isInEvent !== undefined) body.isInEvent = action.data.isInEvent;
  if (action.data.owner) body.owner = action.data.owner;
  if (action.data.fileProfile) {
    const response = yield call(uploadItemFile, 'item', action.data._id, action.data.fileProfile);
    if (response.err) {
      yield put(updateItemFailed(response.err.toString()));
      return;
    }
    profileImageURL = response.data.fileUrl;
  }
  if (action.data.itemVideo) {
    const response = yield call(uploadItemFile, 'item', action.data._id, action.data.itemVideo);
    if (response.err) {
      yield put(updateItemFailed(response.err.toString()));
      return;
    }
    videoURL = response.data.fileUrl;
  }
  if (profileImageURL) body.imageUrl = profileImageURL;
  if (videoURL) body.videoUrl = videoURL;
  const response = yield call(
    request,
    `${baseUrl}/api/item/${action.data._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateItemFailed(response.err.toString()));
  } else {
    yield put(updateItemSuccess());
  }
}

function* fetchItemAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/item/${action.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchItemFailed(response.err.toString()));
  } else {
    yield put(fetchItemSuccess(response.data));
  }
}

function* deleteItem() {
  yield takeLatest([`${types.DELETE_ITEM}`], deleteItemAsync);
}

function* updateItem() {
  yield takeLatest([`${types.UPDATE_ITEM}`], updateItemAsync);
}

function* fetchItem() {
  yield takeLatest([`${types.FETCH_ITEM}`], fetchItemAsync);
}

export default function* itemSaga() {
  yield all([
    deleteItem(),
    updateItem(),
    fetchItem(),
  ]);
}
