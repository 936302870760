/*
Kateryna Ivanova
20/03/2018

Request to API
*/
import 'isomorphic-fetch';
import { logout } from './cookies';

function checkStatus(response) {
  if ((response.status >= 200 && response.status < 300) || response.status === 304) {
    return response.json().then(data => ({ data }));
  } else if (response.status === 401) {
    logout();
    return response.json().then(data => ({ err: data.message }));
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export default function request(url, options) {
  const optionsWithToken = Object.assign({}, options);
  optionsWithToken.credentials = 'include';
  optionsWithToken.headers = optionsWithToken.headers || {};
  return fetch(url, optionsWithToken)
    .then(checkStatus)
    .catch(err => ({ err }));
}
