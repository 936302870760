import {connect} from 'react-redux'
import PackageComponent from '../../../components/PackageComponent'
import * as packageActions from "../../../actions/logistics/packageActions";



const mapStateToProps = state => ({
    isLoadingSearch: state.logistics.packageItem.isLoadingSearch,
    tr_code: state.logistics.packageItem.tr_code,
    search_error: state.logistics.packageItem.search_error,
    package: state.logistics.packageItem.package,
    isOpen: state.logistics.packageItem.isOpen,
    isSaving: state.logistics.packageItem.isSaving,
    save_error: state.logistics.packageItem.save_error,
    addresses: state.logistics.packageItem.addresses,
    
});

function mapDispatchToProps(dispatch) {
    return {
        onRequestClose: () => dispatch(packageActions.closePackageModal()),
        savePackage: (dimensions, userAddress) =>dispatch(packageActions.savePackage(dimensions, userAddress))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PackageComponent)