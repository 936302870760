import React, { useState } from 'react';
import ActionDone from 'material-ui/svg-icons/action/done';
import IconButton from 'material-ui/IconButton';
import NavigationClose from 'material-ui/svg-icons/navigation/close';


const searchStyle = {
	editField: {
    width: 180
	},
	clearBtn: {
		position: 'absolute',
		right: '40px',
		color: "red"
	}
};

const BarcodeInputField = ({ onClose, onSubmit, value }) => {
		const [text, setText] = useState(value)

		return (
			<div className="search-container flxc ac jc" style={{ position: 'relative' }}>
				<div className="flxr ac jc">
					<input
						style={searchStyle.editField}
						className="search-field"
						onChange={e => setText(e.target.value)}
						type="text"
						value={text}
					/>
					<IconButton
						style={searchStyle.clearBtn}
						tooltip="Clear"
						onClick={onClose}
					>
						<NavigationClose color="#141B41" />
					</IconButton>

					<IconButton tooltip="Start Search" onClick={() => onSubmit(text)}>
						<ActionDone />
					</IconButton>
				</div>
			</div >);
}

export default BarcodeInputField;
