/**
 * Created by Slava Kravchina
 * Edited by Roman Safonov
 * NodeJs Developer
 * on 07/05/2018.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuItem from 'material-ui/MenuItem';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import EnabledIcon from 'material-ui/svg-icons/navigation/check';
import DisabledIcon from 'material-ui/svg-icons/navigation/close';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import withConfirm from '../utils/withConfirm';
import NoPermission from '../components/nopermission';
import PaginatedGrid from '../components/paginatedgrid';
import * as couponsActions from '../actions/couponsactions';
import * as couponActions from '../actions/couponactions';
import '../static/styles/couponList.css';
import { withRouter } from '../utils/withRouter';
import { tableData } from '../utils/tableData';
class CouponList extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      selectedCoupons: [],
    };
  }

  componentDidMount() {
    if (this.state.permission.coupons) {
      this.props.fetchCoupons();
    }
  }

  doSearch(query) {
    this.props.fetchCoupons(query);
  }

  processAction(selectedAction) {
    const { selectedCoupons } = this.state;
    const { deleteCoupon } = this.props;
    switch (selectedAction) {
      case 'delete':
        if (selectedCoupons && selectedCoupons.length > 0) {
          const ids = selectedCoupons.map(i => i._id);
          deleteCoupon(ids);
        }
        break;
      case 'edit':
        if (selectedCoupons && selectedCoupons.length > 0) {
          const coupon = selectedCoupons[0];
          this.props.navigate(`/coupon?id=${coupon._id}`);
        }
        break;
      case 'create':
        this.props.navigate('/coupon');
        break;
      default:
        break;
    }
  }

  handleSelection = (selectedItems) => {
    const { coupons } = this.props;
    const result = [];
    selectedItems.forEach((selectedItem) => {
      result.push(coupons[selectedItem]);
    });
    this.setState({ selectedCoupons: result });
  };

  render() {
    const { couponList: { columnWidths, headerData } } = tableData
    const {
      fetchMoreCoupons, onOpenDrawer, hasMore, isLoading, isDeleting, totalCount, coupons,
    } = this.props;
    const { selectedCoupons, permission } = this.state;
    const tableHeight = window.innerHeight - 177;
    const canEdit = selectedCoupons && selectedCoupons.length === 1;
    const canDelete = selectedCoupons && selectedCoupons.length > 0;
    const isEnabledIcon = value => (
      <IconButton>
        {value ?
          <EnabledIcon color="#07de2b" />
          :
          <DisabledIcon color="#f33535" />
        }
      </IconButton>);
    return permission.coupons ?
      <div className="coupons-container">
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <span className="header-placeholder" />
          <IconMenu
            iconButtonElement={<IconButton><MoreVertIcon color="#fff" /></IconButton>}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <MenuItem
              primaryText="Edit"
              disabled={!canEdit}
              onClick={() => this.processAction('edit')}
            />
            <MenuItem
              primaryText="Delete"
              disabled={!canDelete}
              onClick={() => withConfirm(() => this.processAction('delete'))}
            />
            <MenuItem
              primaryText="Create a new coupon"
              onClick={() => this.processAction('create')}
            />
          </IconMenu>
        </div>

        <PaginatedGrid
          data={coupons}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          hasMore={hasMore}
          isLoading={isLoading || isDeleting}
          header={headerData}
          totalResults={totalCount}
          onSelectRow={this.handleSelection}
          selection={this.state.selectedCoupons}
          items={coupons.map(coupon => [
            <span><a href={`/coupon?id=${coupon._id}`}>{coupon.code}</a></span>,
            <span>{`${coupon.discountValue} ${coupon.discountType === 'fixed' ? '$' : '%'}`}</span>,
            <span>{isEnabledIcon(coupon.isEnabled)}</span>,
          ])}
          onLoadMore={() => fetchMoreCoupons()}
        />
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

CouponList.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  coupons: PropTypes.array.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  fetchCoupons: PropTypes.func.isRequired,
  fetchMoreCoupons: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  hasMore: state.couponsReducer.hasMore,
  isLoading: state.couponsReducer.isLoading,
  isDeleting: state.couponReducer.isDeleting,
  totalCount: state.couponsReducer.totalCount,
  coupons: state.couponsReducer.coupons,
});

const mapDispatchToProps = dispatch => ({
  fetchCoupons: query => dispatch(couponsActions.fetchCoupons(query)),
  fetchMoreCoupons: () => dispatch(couponsActions.fetchMoreCoupons()),
  deleteCoupon: ids => dispatch(couponActions.deleteCoupon(ids)),
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CouponList));
