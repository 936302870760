import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import { put, call, takeLatest, takeEvery, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import req from '../utils/networkRequest';
import io from 'socket.io-client';
import md5 from 'md5';
import moment from 'moment';
import {
    loadChatMessages, loadChatsList,
    setChatsList, connectToChat as connectToChatAction,
    setConnectionProperties, addChatMessages,
    loadMoreChats, loadMoreMessages,
    addChatsToList, setChatsToken,
    prependChatMessages, changeSelectedChat,
    setDefaultFilters, setSupportId,
    setPaymentsData, getUserRoles,
    addUserRoles, removeFlagInState,
    updateMessage, getPaymentsData as getPayment,
    getAllShuplistItemsSuccess,
    loadMoreMessagesDown, handleAddMessage,
    addNewlyMessage, removeNewlyMessages, jumpToMessage as jumpTo,
    setShuppersData,
    setForwardedChatsSuccess, addForwardedMessageUserRoles
} from '../actions/chatsActions';
import { switcherSelector } from '../reducers/chatsReducer';
import { config } from '../utils/envSetup';
const CHATS_URL = config('chatsUrl');
const API_URL = config('baseUrl');

const checkForAuthError = async response => {
    if (response.status === 401) {
        const res = await req(`${API_URL}/api/service/support-token`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        },
        );
        if (res.data && res.data.token) {
            localStorage.setItem('chatsToken', res.data.token);
            window.location.reload();
        }
    }
};

const ITEMS_PER_DATA_SET = config('chatsItemsPerPage');

let supportId = null;

function* makeConnection({ payload }) {
    const token = localStorage.getItem('chatsToken');
    yield put(setChatsToken(token));
    const socket = io(CHATS_URL, { path: '', query: `token=${token}&os=IOS&deviceToken=admintools`, resource: '', transports: ['websocket'] });

    const response = yield call(
        req,
        `${API_URL}/api/service/config?chatAdminID`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        },
    );

    supportId = response.data ? response.data.chatAdminID : null;

    yield put(setSupportId(supportId));

    socket.on('server', function (data) {
        // console.warn(data);
    });

    socket.on('message', ({ message }) => {
        const { channel } = message;
        payload.dispatch(handleAddMessage(channel, [message]));
    });

    socket.on('updatemessage', ({ message }) => {
        const { channel } = message;
        if (message.attributes && JSON.parse(message.attributes).payment) {
            payload.dispatch(getPayment(JSON.parse(message.attributes).payment._id));
        }
        return payload.dispatch(updateMessage(channel, message));
    });

    yield put(setConnectionProperties(socket))
}

function* connectToChat({ payload }) {
    const { uniqueName, socket, chatId } = payload;
    const token = localStorage.getItem('chatsToken');
    socket.emit('admin', { action: "actionChannel", channelId: uniqueName, actionChannel: 'join' });
    const store = yield select();
    if (store.chatsReducer.filters.inMessages) {
        const { authToken, idList, messageList, messagesInChat } = switcherSelector(store);
        if (idList[0]) yield put(jumpTo(chatId, idList[0], 0, authToken, messageList[idList[0]], messagesInChat))
    } else yield put(loadChatMessages(chatId, uniqueName, token));
}

function* getPaymentData({ payload }) {
    const response = yield call(
        req,
        `${API_URL}/api/logistics/get/${payload.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    yield put(setPaymentsData(payload.id, response.data[0] ? response.data[0] : {}));
    if (payload.message) yield put(updateMessage(payload.message.channel, payload.message));
}
function* getShuppersData() {
    const response = yield call(
        req,
        `${API_URL}/api/user/shuppers`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    yield put(setShuppersData(response.data[0] ? response.data.filter(user => {
        if (user.status === 'active') return true 
        else return false;
    }) : {}));
}
function* setForwardedChats({ payload }) {
    const token = localStorage.getItem('chatsToken');
    const response = yield call(
        fetch,
        `${CHATS_URL}admin/chats/move`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization-token': token
            },
            body: JSON.stringify(payload),
        },
    );
    const data = yield response.json();

    yield put(setForwardedChatsSuccess(data ? data : {}));
}
function* loadMessages({ payload }) {
    const { uniqueName, chatId, token, lazy, up } = payload;
    let limit;
    let { skip = 0 } = payload;
    if (skip < 0) {
        limit = -skip;
        skip = 0;
    }
    const queryString = `?limit=${limit || ITEMS_PER_DATA_SET}${skip || skip === 0 ? '&skip=' + skip : ''}`;
    const res = yield call(
        fetch,
        `${CHATS_URL}chat/channel/${uniqueName}${queryString}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization-token': token
            },
        },
    );

    const data = yield res.json();

    if (!data.error) {
        if (!lazy) {
            yield put(addChatMessages(chatId, data.messages.reverse()));
            yield put(loadMoreMessages(skip, data.messages.length !== ITEMS_PER_DATA_SET, chatId));
        } else {
            if (up) {
                yield put(prependChatMessages(chatId, data.messages.reverse()));
                yield (put(loadMoreMessages(skip, data.messages.length !== ITEMS_PER_DATA_SET, chatId)));
            } else {
                yield put(addChatMessages(chatId, data.messages.reverse()));
                yield put(loadMoreMessagesDown(skip, data.messages.length !== ITEMS_PER_DATA_SET, chatId));
                if (data.messages.length !== ITEMS_PER_DATA_SET) yield put(removeNewlyMessages(chatId));
            }
        }
    }
}

function* selectChat({ payload }) {
    const { uniqueName, socket, chatId, name1, name2 } = payload;
    yield put(connectToChatAction(uniqueName, chatId, socket, name1, name2));
}

let chatHash = '';

function* loadChats({ payload }) {
    const { friendlyName, id, skip = 0, lazy, shouldLoadUp, support, limit, skipOld, inMessages } = payload;
    let { to, from } = payload;
    if (shouldLoadUp) {
        const token = localStorage.getItem('chatsToken');
        if (from && to) {
            from = moment(from).hours(0).minute(0).zone(0);
            to = moment(to).hours(23).minute(59).zone(0);
        }
        let queryString = '?limit=' + (limit ? limit : ITEMS_PER_DATA_SET);
        queryString += `${friendlyName ? inMessages ? `&searchMessage=${friendlyName}` : `&friendlyName=${friendlyName}` : ''}` +
            `${from ? `&startDate=${from.format('lll')}` : ''}${to ? `&endDate=${to.format('lll')}` : ''}` +
            `${id ? `&id=${id}` : ''}${skip || skip === 0 ? `&skip=${skip}` : ''}`;
        if (support) {
            if (!supportId) console.error('Error retreiving supportId')
            else queryString += `&id=${supportId}`;
        }
        const res = yield call(
            fetch,
            `${CHATS_URL}admin/chats${queryString}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'authorization-token': token
                },
            },
        );
        yield checkForAuthError(res);
        const response = yield res.json();
        const channels = response && response.channels;
        const lastMessageIds = [];
        const foundMessages = {};
        const chats = channels ? response.channels.reduce(
            (acc, val) => {
                if (val.lastMessage) lastMessageIds.push(val.lastMessage.from.identity);
                if (inMessages) foundMessages[val._id] = val.foundMessages.reverse();
                return { ...acc, [val._id]: { ...val } }
            },
            {}
        ) : {};
        const totalCount = response && response.totalCount ? response.totalCount : 0;
        if (!lazy) {
            yield put(setChatsList(chats, channels && channels.length < ITEMS_PER_DATA_SET, totalCount, skipOld, foundMessages));
        } else {
            yield put(addChatsToList(chats, foundMessages));
            yield put(loadMoreChats(skip, channels && channels.length < ITEMS_PER_DATA_SET, skip + ITEMS_PER_DATA_SET));
        }
        if (lastMessageIds.length) {
            const newHash = md5(JSON.stringify(lastMessageIds));
            if (newHash !== chatHash) {
                yield put(getUserRoles(lastMessageIds));
                chatHash = newHash;
            }
        }
    }
    const store = yield select();
    if (store.chatsReducer.filters.inMessages &&
        store.chatsReducer.selectedChat &&
        store.chatsReducer.chatList[store.chatsReducer.selectedChat]) {
        const { authToken, idList, messageList, messagesInChat, selectedChat } = switcherSelector(store);
        if (idList[0]) yield put(jumpTo(selectedChat, idList[0], 0, authToken, messageList[idList[0]], messagesInChat))
    }
}

function* disconnectFromChat({ payload }) {
    const { uniqueName, socket, displayNames, selectedChat, chatId } = payload;
    socket.emit('admin', { action: "actionChannel", channelId: uniqueName, actionChannel: 'leave' });
    if (chatId === selectedChat) {
        let firstConnected;
        for (let id in displayNames) {
            if (displayNames[id] && id !== selectedChat) {
                firstConnected = id;
                break;
            }
        }
        yield put(changeSelectedChat(firstConnected || 1));
    }
}

function* sendMessage({ payload }) {
    const { message, socket, uniqueName } = payload;
    yield socket.emit('admin', { action: "sendMessage", message, channelId: uniqueName });
}

function* requestUsersRoles({ payload }) {
    const { users, forwardedMessages } = payload;
    const queryString = users.join(',');
    const res = yield call(req,
        `${API_URL}/api/user/roles/${queryString}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        },
    );
    if (forwardedMessages) {
        yield put(addForwardedMessageUserRoles(res.data));
    } else {
        if (res.data) {
            const result = res.data.reduce((acc, val) => ({ ...acc, [val._id]: val.roles }), {});
            yield put(addUserRoles(result));
        } else console.error(res);
    }
}

function* requestUnflagChat({ payload }) {
    const token = localStorage.getItem('chatsToken');

    const res = yield call(
        fetch,
        `${CHATS_URL}admin/message/setflag?_id=${payload.id}&flagged=false`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization-token': token
            },
        },
    );
    if (res.status !== 204) console.error(res.error);
    else yield put(removeFlagInState(payload.id, payload.chatId));
}

function* getAllShuplistItems({ payload }) {
    const { id } = payload;
    const res = yield call(
        req,
        `${API_URL}/api/shuplist/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // 'authorization-token': token
            },
        },
    );
    yield put(getAllShuplistItemsSuccess(res.data));
}

function* jumpToMessage({ payload }) {
    const { chatId, token, messageId } = payload;

    const queryString = `?limitUp=5&limitDown=5`;
    const res = yield call(
        fetch,
        `${CHATS_URL}chat/channel/${chatId}/jumpto/${messageId}${queryString}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization-token': token
            },
        },
    );

    const data = yield res.json();
    const messages = [...data.down.reverse(), data.message, ...data.up];
    if (!data.error) {
        yield put(addChatMessages(chatId, messages));
    }
}

function* handleNewMessage({ payload }) {
    const { chatId, messages } = payload;
    const { chatsReducer } = yield select();
    if (chatsReducer.lazyLoad.messages[chatId].fullDown) yield put(addChatMessages(chatId, messages));
    else yield put(addNewlyMessage(chatId, messages));
}


function* resetTheFilters() {
    yield put(setDefaultFilters());
    yield put(loadChatsList('', { from: null, to: null }, false));
}

function* watchConnectToChat() {
    yield takeLatest(types.CONNECT_TO_CHAT, connectToChat);
}

function* watchLoadMessages() {
    yield takeLatest(types.LOAD_CHAT_MESSAGES, loadMessages);
}

function* watchLoadChatsList() {
    yield takeEvery(types.LOAD_CHATS_LIST, loadChats);
}

function* watchSelectChat() {
    yield takeLatest(types.SELECT_CHAT, selectChat);
}

function* watchMakeConnection() {
    yield takeLatest(types.MAKE_WS_CONNECTION, makeConnection);
}

function* watchSendMessage() {
    yield takeEvery(types.SEND_MESSAGE, sendMessage);
}

function* watchDisconnectFromChat() {
    yield takeEvery(types.DISCONNECT_FROM_CHAT, disconnectFromChat);
}

function* watchResetFilters() {
    yield takeEvery(types.RESET_CHATS_FILTERS, resetTheFilters);
}

function* watchGetPaymentData() {
    yield takeEvery(types.GET_PAYMENT_DATA, getPaymentData);
}
function* watchGetShuppersData() {
    yield takeEvery(types.GET_SHUPPERS_DATA, getShuppersData);
}

function* watchGetUserRoles() {
    yield takeEvery(types.GET_USER_ROLES, requestUsersRoles);
}

function* watchUnflagChatRequest() {
    yield takeEvery(types.REMOVE_FLAG_FROM_CHAT, requestUnflagChat);
}

function* watchJumpToMessage() {
    yield takeLatest(types.JUMP_TO_MESSAGE, jumpToMessage);
};

function* watchHandleMessage() {
    yield takeLatest(types.HANDLE_NEW_MESSAGE, handleNewMessage);
};
function* watchGetShuplistItems() {
    yield takeLatest(types.GET_ALL_SHUPLIST_ITEMS, getAllShuplistItems);
};
function* watchForwardedChats() {
    yield takeLatest(types.SET_FORWARDED_CHATS, setForwardedChats);
};

export default function* chatsSaga() {

    yield all([
        watchConnectToChat(),
        watchLoadMessages(),
        watchLoadChatsList(),
        watchSelectChat(),
        watchMakeConnection(),
        watchSendMessage(),
        watchDisconnectFromChat(),
        watchResetFilters(),
        watchGetPaymentData(),
        watchGetUserRoles(),
        watchUnflagChatRequest(),
        watchJumpToMessage(),
        watchHandleMessage(),
        watchGetShuplistItems(),

        watchGetShuppersData(),
        watchForwardedChats()
    ]);
}
