import types from '../actionTypes';


export const createPackage = (id, tr_code, user_id, shupper_id, user_email, shupper_email ) => ({
    type : types.LOGISTICS.FETCH_PACKAGE,
    id, tr_code, user_id, shupper_id, user_email, shupper_email
})
export const createPackageSuccess = (data) => ({
    type : types.LOGISTICS.FETCH_PACKAGE_SUCCESS,
    package : data
})

export const createPackageError = (error_by_load) => ({
    type : types.LOGISTICS.FETCH_PACKAGE_ERROR,
    error_by_load
})

export const closePackageModal = ()=> ({
    type: types.LOGISTICS.CLOSE_PACKAGE_MODAL
})

export const savePackage = (Dimensions, userAddress) => ({
    type : types.LOGISTICS.SAVE_PACKAGE,
    Dimensions, userAddress
})

export const savePackageSuccess = (data) => ({
    type : types.LOGISTICS.SAVE_PACKAGE_SUCCESS,
    data    
})

export const savePackageError = (error) => ({ 
    type : types.LOGISTICS.SAVE_PACKAGE_ERROR,
    save_error: error
})