/*
Kateryna Ivanova
20/03/2018

Redux actions for stores list loading
*/
import types from './actionTypes';

export const fetchStores = query => ({
  type: types.FETCH_STORES,
  query,
});

export const fetchStoresDateRangeChanges = (startDate, endDate) => ({
  type: types.FETCH_STORES_DATERANGE_CHANGED,
  startDate,
  endDate,
});

export const fetchMoreStores = () => ({
  type: types.FETCH_MORE_STORES,
});

export const fetchStoresSuccess = data => ({
  type: types.FETCH_STORES_SUCCESS,
  data,
});

export const fetchStoresFailed = reason => ({
  type: types.FETCH_STORES_FAILED,
  reason,
});

export const fetchStoresSortChanged = (column, direction) => ({
  type: types.FETCH_STORES_SORT_CHANGED,
  column,
  direction,
});
