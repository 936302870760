/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import SetupIcon from 'material-ui/svg-icons/action/build';
import IconMenu from 'material-ui/IconMenu';
import { RaisedButton } from 'material-ui';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import NoPermission from '../../../components/nopermission';
import { getPermission } from '../../../utils/cookies';
import { decodePermissions } from '../../../utils/permissionUtil';
import PaginatedGrid from '../../../components/paginatedgrid';
import * as pendingActions from '../../../actions/logistics/pendingactions';
import ImageViewer from '../../../components/imageviewer';
import withConfirm from '../../../utils/withConfirm';
import SearchField from '../../../components/searchfield';
import SelectDialog from '../../../components/SelectDialog';
import { changePaymentStatus } from '../../../actions/logistics/singlePaymentActions';
import getBaseUrl from '../../../utils/envSetup';
import { tableData } from '../../../utils/tableData';

const baseUrl = getBaseUrl();
class Pending extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      isOpenImageViewer: false,
      images: [],
      selected: [],
      changeStatus: false,
      itemToChangeStatus: null,
      itemToChangeStatusKey: null,
    };
  }

  componentDidMount() {
    if (this.state.permission.seeCanceled) {
      this.props.fetchPending();
    }
  }

  openImageViewer = images => {
    this.setState({
      isOpenImageViewer: true,
      images,
    });
  };

  closeImageViewer = () => {
    this.setState({
      isOpenImageViewer: false,
    });
  };

  makeReject = () => {
    const { selected } = this.state;
    const { makeReject } = this.props;
    const ids = selected.map(selectedItem => selectedItem._id);
    makeReject(ids);
  };

  handleSelection = (selectedItems) => {
    const { pendingList } = this.props;
    const result = [];
    selectedItems.forEach((selectedItem) => {
      result.push(pendingList[selectedItem]);
    });
    this.setState({ selected: result });
  };

  onDateRangeSet(startDate, endDate) {
    this.props.fetchPendingDateRangeChanges(startDate, endDate);
  }

  doSearch(query) {
    this.props.fetchPending(query);
  }

  openStatusDialog = (itemToChangeStatus, itemToChangeStatusKey) => () => {
    this.setState({
      changeStatus: true,
      itemToChangeStatus,
      itemToChangeStatusKey,
    });
  }

  closeStatusDialog = () => {
    this.setState({
      changeStatus: false,
    });
  }

  render() {
    const { logisticsPending: { columnWidths, headerData } } = tableData
    const {
      onOpenDrawer, fetchMorePending, pendingList, hasMore, isLoading, totalCount, sort,
      fetchPendingSortChanged, startDate, endDate, changePaymentStatus
    } = this.props;
    const { permission, isOpenImageViewer, images, selected, changeStatus, itemToChangeStatus, itemToChangeStatusKey } = this.state;
    const tableHeight = window.innerHeight - 217;
    const rejectConfirmMessage = 'Are you sure to cancel to the selected items?';
    const isDisabledReject = !(permission.cancelPayment && selected.length > 0);

    return permission.seePending ?
      <div className="logistics-container">
        <SelectDialog
          items={['cancelled', 'fulfilled', 'pending', 'refunded', 'rejected', 'shipping']}
          type="status"
          handleChange={changePaymentStatus}
          value="pending"
          payment={itemToChangeStatus}
          position={itemToChangeStatusKey}
          display={changeStatus}
          close={this.closeStatusDialog}
        />
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <div className="header-search-area">
            <SearchField onRequestSearch={query => this.doSearch(query)} />
          </div>
          <div className="logistics-date-range-picker-cont">
            <DateRangePicker
              drops="down"
              opens="center"
              startDate={startDate
                ? moment(startDate)
                : moment('01/01/2017', 'DD/MM/YYYY')
              }
              endDate={endDate ? moment(endDate) : moment()}
              timePicker
              timePicker24Hour
              showDropdowns
              onApply={(ev, picker) => {
                this.onDateRangeSet(
                  picker.startDate.toDate().toJSON(),
                  picker.endDate.toDate().toJSON(),
                );
              }}
              ranges={{
                'All Times': [moment('01/01/2017', 'DD/MM/YYYY'), moment()],
                'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Month to date': [moment().startOf('month'), moment()],
              }}
            >
              <RaisedButton
                label={startDate && endDate
                  ? `${moment(startDate).format('lll')}
										- ${moment(endDate).format('lll')}`
                  : 'Pick date range'}
                primary
              />
            </DateRangePicker>
            <a href={baseUrl + '/api/logistics/pending?csv=true' + (startDate ?
              `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '')} download >
              <RaisedButton
                label="Download CSV"
                primary
              />
            </a>
          </div>
          <span className="header-placeholder" />
          <IconMenu
            iconButtonElement={<IconButton><MoreVertIcon color="#fff" /></IconButton>}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <MenuItem
              primaryText="Reject Payment"
              disabled={isDisabledReject}
              onClick={() => withConfirm(this.makeReject, rejectConfirmMessage)}
            />
          </IconMenu>
        </div>

        <div className="logistics-collections-tabs">
          <Link to="/logistics/pending" className="logistics-collections-tab logistics-collections-tab-active">Pending</Link>
          <Link to="/logistics/rejected" className="logistics-collections-tab">Rejected</Link>
          <Link to="/logistics/canceled" className="logistics-collections-tab">Canceled</Link>
          <Link to="/logistics/completed" className="logistics-collections-tab">Completed</Link>
          <Link to="/logistics/refunded" className="logistics-collections-tab">Refunded</Link>
          <Link to="/logistics/shipping" className="logistics-collections-tab">Shipping</Link>
        </div>

        <PaginatedGrid
          data={pendingList}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          hasMore={hasMore}
          isLoading={isLoading}
          header={headerData}
          totalResults={totalCount}
          onSelectRow={this.handleSelection}
          selection={selected}
          sortableCols={headerData[0].id}
          currentSort={sort}
          onSort={(column, direction) => fetchPendingSortChanged(column, direction)}
          items={pendingList.map((item, key) => {
            const dateTime = moment(item.created).format("YYYY-MM-DD HH:mm:ss");
            const images = item.receipts && item.receipts.map(receipt => ({ src: receipt.imageUrl || '' }));
            return [
              <Link to={`/logistics/payment/${item._id}`}><span>{dateTime}</span></Link>,
              <span>{item.user && item.user.displayName}</span>,
              <span>{item.user && item.user.email}</span>,
              <span>{item.shupper && item.shupper.displayName}</span>,
              <span>{item.receipts.length}</span>,
              <span>
                <img
                  alt=""
                  className="avatar viewer"
                  src={images[0] ? images[0].src : ''}
                  onClick={() => images.length && this.openImageViewer(images)}
                />
              </span>,
              <span>
                {
                  item.receipts.map(receipt => receipt.price).join(', ')
                }
              </span>,
              <span>{item.itemsTotalPrice}</span>,
              <span>{item.deliveryFee}</span>,
              <span>{item.taxes}</span>,
              <span>{item.payeeFee}</span>,
              <span>{item.shupperzFee}</span>,
              <span>{item.defaultShupperzFee}</span>,
              <span>{item._id}</span>,
              <span>
                {
                  permission.changePaymentStatus ?
                    <IconButton onClick={this.openStatusDialog(item, key)}>
                      <SetupIcon color="black" />
                    </IconButton>
                    : null
                }
              </span>
            ]
          })}
          onLoadMore={fetchMorePending}
        />
        <ImageViewer isOpen={isOpenImageViewer} onClose={this.closeImageViewer} images={images} />
      </div>
      :
      <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

Pending.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  pendingList: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  fetchPending: PropTypes.func.isRequired,
  fetchMorePending: PropTypes.func.isRequired,
  makeReject: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  fetchPendingSortChanged: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  fetchPendingDateRangeChanges: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pendingList: state.logistics.pending.logistics,
  hasMore: state.logistics.pending.hasMore,
  isLoading: state.logistics.pending.isLoading,
  totalCount: state.logistics.pending.totalCount,
  sort: state.logistics.pending.sort,
  startDate: state.logistics.pending.startDate,
  endDate: state.logistics.pending.endDate,
});

const mapDispatchToProps = dispatch => ({
  fetchPending: query => dispatch(pendingActions.fetchPending(query)),
  fetchMorePending: () => dispatch(pendingActions.fetchMorePending()),
  makeReject: (ids) => dispatch(pendingActions.makeReject(ids)),
  fetchPendingSortChanged: (column, direction) =>
    dispatch(pendingActions.fetchPendingSortChanged(column, direction)),
  fetchPendingDateRangeChanges: (startDate, endDate) =>
    dispatch(pendingActions.fetchPendingDateRangeChanges(startDate, endDate)),
  changePaymentStatus: ({ prevStatus, payment, status, position }) =>
    dispatch(changePaymentStatus({ prevStatus, payment, status, position })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pending);
