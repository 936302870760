import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfileData, updateUserProfileInfo } from '../../actions/userProfileActions';
import { decodePermissions } from '../../utils/permissionUtil';
import { getPermission } from '../../utils/cookies';
import { Paper, IconButton, Grid, Typography, LinearProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Country,
  CountryCodePhone,
  TextField,
  SelectField,
} from '../../components/fields/userFields/index';
import 'react-toastify/dist/ReactToastify.css';
import '../../static/styles/editUserProfile.css';
import { withRouter } from '../../utils/withRouter';
import NoPermission from '../nopermission';
import { CancelApplyBtns } from '../buttons';
import { userLocalStorage } from '../../config/local_storage';
import human from '../../static/images/human.svg'
import { Loading } from '../loader';
import { countries } from '../../utils/countries';

const EditUserProfile = (props) => {
  const permission = decodePermissions(getPermission());
  const data = useSelector(({ userProfileReducer }) => userProfileReducer?.userProfile || {})

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [phoneState, setPhoneState] = React.useState(props?.location.state.phone)

  const handlePhone = (e) => {
    setPhoneState(e)
  }
  React.useEffect(() => {
    if (permission.users) {
      dispatch(getUserProfileData(props?.params?.id))
    }
  }, [props?.params?.id])


  const onSubmit = (values, prop) => {
    const { firstName, lastName, phone, status, type, email, country } = values
    const countryToNumber = countries?.find(c => c.iso2_name === country)

    const data = {
      first_name: firstName,
      last_name: lastName,
      state_id: status === 'active' ? 1 : 2,
      phone_number: phoneState,
      type_id: type === 'user' ? 1 : 2,
      email: email,
      country: countryToNumber['id']
    }
    dispatch(updateUserProfileInfo(props?.params?.id, data))

    setTimeout(() => {
      prop.setSubmitting(false);
      dispatch(getUserProfileData(props?.params?.id))
    }, 1500);
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().matches(/^[aA-zZ\s]+$/, "First name must contain only (A-z) letters."),
    lastName: yup.string().matches(/^[aA-zZ\s]+$/, 'Last name must contain only  (A-z) letters.'),
    email: yup.string().email('Incorrect email'),
  });
  const defineCountry = countries?.filter(country => country?.id === data?.user?.country_id)
  const country = defineCountry[0]?.iso2_name

  if (!data.user) return <Loading />

  return permission.users ?
    <div className="main">
      <div className="App-header">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon color="primary" />
        </IconButton>
      </div>
      <div className='editUser-wrapper'>
        <Grid container justifyContent='center'>
          <Grid item >
            <Grid container alignItems="center" className="mainTitle-wrapper">
              <Typography variant="h4" className="editUser-title">
                EDIT USER - {data?.user?.first_name} {data?.user?.last_name}
              </Typography>
            </Grid>
            <Paper className="editUser-paper-wrapper">
              <Grid container direction="row" justifyContent="space-between">
                <Typography>ID {data?.user?.id}</Typography>
                <Typography>Date created 13/07/2022</Typography>
              </Grid>
              <Grid container direction="column">
                <div className="userEditAvatarImg">
                  <img src={data?.user?.profileImageURL} alt="avat" className='userAvatarWrapper'/>
                </div>
                <Typography className="removeAvatar-text">Remove avatar</Typography>
              </Grid>
              <Formik enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={false}
                initialValues={{
                  firstName: data?.user?.first_name,
                  lastName: data?.user?.last_name,
                  email: data?.user?.email,
                  status: data?.user?.state_id === 1 && 'active' || data?.user?.state_id === 2 && 'inactive' || '',
                  type: data?.user?.type_id === 1 && 'user' || data?.user?.type_id === 2 && 'shupper',
                  country: country,
                  ccy: 'USD'
                }} onSubmit={onSubmit} validationSchema={validationSchema}>
                {props => (
                  <Form>
                    <Grid container direction="row" justifyContent="space-between" spacing={2} style={{ maxWidth: 650 }}>
                      <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                        <Typography className="input-title">First name*</Typography>
                        <TextField name="firstName" type="text" {...props} />
                      </Grid>
                      <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                        <Typography className="input-title">Last name*</Typography>
                        <TextField name="lastName" type="text" {...props} />
                      </Grid>
                      <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                        <Typography className="input-title">Type</Typography>
                        <SelectField name="type" renderItems={[{ id: 1, title: 'user' }, { id: 2, title: 'shupper' }]} />
                      </Grid>
                      <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                        <Typography className="input-title">Status</Typography>
                        <SelectField name="status" renderItems={[{ id: 3, title: 'active' }, { id: 4, title: 'inactive' }]} />
                      </Grid>
                      <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                        <Typography className="input-title">Country</Typography>
                        <Country name="country" />
                      </Grid>
                      <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                        <Typography className="input-title">CCY</Typography>
                        <SelectField name='ccy' renderItems={[{ id: 8, title: 'USD' }]} disabled='true' />
                      </Grid>
                      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                        <Typography className="input-title-countryCode">Phone Number</Typography>
                        <CountryCodePhone value={phoneState} setValue={handlePhone} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="input-title">Email</Typography>
                        <TextField name="email" {...props} />
                      </Grid>
                    </Grid>
                    <Grid >
                      <CancelApplyBtns {...props} />
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
    : <NoPermission onOpenDrawer={props.onOpenDrawer} />
};

export default withRouter(EditUserProfile);
