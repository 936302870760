import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';

const initialState = {
  package: [],
  id: null
};

export default createReducer(
  {
    [types.FETCH_PACKAGE_DETAILS]: (state, action) => ({
      ...state,
      id: action.data.id
    }),

    [types.FETCH_PACKAGE_DETAILS_SUCCESS]: (state, action) => ({
      ...state,
      package: action.data.package
    }),
    [types.FETCH_PACKAGE_DETAILS_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasMore: false,
    }),
    [types.UPDATE_PACKAGE_DETAILS]: (state, action) => ({
      ...state,
      id: action.data.id ?? state.id
    }),

    [types.UPDATE_PACKAGE_DETAILS_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [types.UPDATE_PACKAGE_DETAILS_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasMore: false,
    })
  },
  initialState,
);
