/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 18/04/2018.
 */

import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchLogsSuccess, fetchLogsFailed } from '../actions/securitylogactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectSecurityLogsState = state => state.securityLogsReducer;

function* fetchSecurityLogsAsync() {
  const securityLogsState = yield select(selectSecurityLogsState);
  const response = yield call(
    request,
    `${baseUrl}/api/securitylog?limit=${securityLogsState.limit}&skip=${securityLogsState.offset}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchLogsFailed(response.err.toString()));
  } else {
    yield put(fetchLogsSuccess(response.data));
  }
}

function* fetchSecurityLogs() {
  yield takeLatest([`${types.FETCH_LOGS}`, `${types.FETCH_MORE_LOGS}`], fetchSecurityLogsAsync);
}

export default function* securityLogsSaga() {
  yield all([
    fetchSecurityLogs(),
  ]);
}
