/*
Kateryna Ivanova
20/03/2018

Sagas for working with a single event
*/
import { takeLatest, put, call, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchEventSuccess, fetchEventFailed,
  updateEventFailed, updateEventSuccess, deleteEventSuccess, deleteEventFailed } from '../actions/eventactions';
import request from '../utils/networkRequest';
import uploadImage from '../utils/networkutil';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* fetchEventAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/event/${action.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchEventFailed(response.err.toString()));
  } else {
    yield put(fetchEventSuccess(response.data));
  }
}

function* updateEventAsync(action) {
  let profileImageURL = null;
  if (action.event.fileProfile) {
    const response = yield call(uploadImage, 'event', action.event._id, action.event.fileProfile);
    if (response.err) {
      yield put(updateEventFailed(response.err.toString()));
      return;
    }
    profileImageURL = response.data.imgUrl;
  }

	const body = {
		title: action.event.title,
		dateStart: action.event.dateStart,
		dateEnd: action.event.dateEnd,
		shuppers: action.event.shuppers,
		stores: action.event.stores,
		type: action.event.type,
		position: { coordinates: action.event.coordinates },
		description: action.event.description,
		location: action.event.location,
		items: action.event.items,
		password: action.event.password,
		about: action.event.about,
		linkTo: action.event.linkTo,
	};
  if (profileImageURL) body.imageUrl = profileImageURL;
  const response = yield call(
    request,
    `${baseUrl}/api/event/${action.event._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateEventFailed(response.err.toString()));
  } else {
    yield put(updateEventSuccess());
  }
}

function* createEventAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/event`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
	    title: action.event.title,
	    dateStart: action.event.dateStart,
	    dateEnd: action.event.dateEnd,
	    shuppers: action.event.shuppers,
	    stores: action.event.stores,
	    position: { coordinates: action.event.coordinates },
	    type: action.event.type,
	    description: action.event.description,
	    location: action.event.location,
	    items: action.event.items,
	    password: action.event.password,
	    linkTo: action.event.linkTo,
	    about: action.event.about,
      }),
    },
  );

  if (response.err) {
    yield put(updateEventFailed(response.err.toString()));
    return;
  }

  const id = response.data._id;
  let imageUrl = null;
  if (action.event.fileProfile) {
    const responseImg = yield call(uploadImage, 'event', id, action.event.fileProfile);
    if (responseImg.err) {
      yield put(updateEventFailed(responseImg.err.toString()));
      return;
    }
    imageUrl = responseImg.data.imgUrl;
  }
  if (imageUrl) {
    const result = yield call(
      request,
      `${baseUrl}/api/event/${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageUrl }),
      },
    );
    if (result.err) {
      yield put(updateEventFailed(result.err.toString()));
      return;
    }
  }

  yield put(updateEventSuccess());
}

function* deleteEventAsync(action) {
  const eventIds = action.ids.join(',');
  const response = yield call(
    request,
    `${baseUrl}/api/event?ids=${eventIds}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteEventFailed(response.err.toString()));
  } else {
    yield put(deleteEventSuccess());
  }
}

function* updateEventLink({payload}) {
  const response = yield call(
    request,
    `${baseUrl}/api/event/${payload.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        linkTo: {
          type: payload.type,
          data: payload.data,
          imageUrl: payload.image,
          displayName: payload.title,
        }
      }),
    },
  );

  if (response.err) {
    yield put(updateEventFailed(response.err.toString()));
    return;
  }
}

function* fetchItems() {
  yield takeLatest(types.FETCH_EVENT, fetchEventAsync);
}

function* updateEvent() {
  yield takeLatest(types.UPDATE_EVENT, updateEventAsync);
}

function* createEvent() {
  yield takeLatest(types.CREATE_EVENT, createEventAsync);
}

function* deleteEvent() {
  yield takeLatest(types.DELETE_EVENT, deleteEventAsync);
}

function* watchUpdateEventLink() {
  yield takeLatest(types.UPDATE_EVENT_LINK, updateEventLink);
}

export default function* eventSaga() {
  yield all([
    fetchItems(), updateEvent(), createEvent(), deleteEvent(),
    watchUpdateEventLink(),
  ]);
}
