/*
Roman Safonov
20/03/2018

Reducer for a single item
*/
import types from '../actions/actionTypes';

const initialState = {
  isDeleting: false,
  deleteSuccess: false,
  deleteError: null,
  isUpdating: false,
  updateError: null,
  isLoading: false,
  item: null,
  updateSuccess: false,
};

export default function itemReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ITEM:
      return Object.assign({}, state, {
        isLoading: true,
        item: null,
        updateSuccess: false,
        updateError: null,
      });
    case types.FETCH_ITEM_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        item: action.data,
      });
    case types.FETCH_ITEM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        loadingError: action.reason,
      });
    case types.DELETE_ITEM:
      return Object.assign({}, state, {
        isDeleting: true,
        deleteSuccess: false,
      });
    case types.DELETE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteSuccess: true,
      });
    case types.DELETE_ITEM_FAILED:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteError: action.reason,
        deleteSuccess: false,
      });

    case types.UPDATE_ITEM:
      return Object.assign({}, state, {
        isUpdating: true,
        updateError: null,
        updateSuccess: false,
      });
    case types.UPDATE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        updateSuccess: true,
      });
    case types.UPDATE_ITEM_FAILED:
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: action.reason,
        updateSuccess: false,
      });
    default:
      return state;
  }
}
