/*
Kateryna Ivanova
20/03/2018

Redux actions for authorization
*/
import types from './actionTypes';

export const adminLogin = (login, password) => ({
  type: types.ADMIN_LOGIN,
  login,
  password,
});

export const adminLoginSuccess = user => ({
  type: types.ADMIN_LOGIN_SUCCESS,
  user,
});

export const adminLoginFailed = reason => ({
  type: types.ADMIN_LOGIN_FAILED,
  reason,
});

export const adminLogout = () => ({
  type: types.ADMIN_LOGOUT,
});

export const adminLogoutSuccessed = () => ({
  type: types.ADMIN_LOGOUT_SUCCESS,
});

export const login2faSucceded = user => ({
  type: types.LOGIN_2FA_SUCCESS,
  user,
});

export const login2faFailed = reason => ({
  type: types.LOGIN_2FA_FAILED,
  reason,
});

export const login2fa = (token, authyId, login, password) => ({
  type: types.LOGIN_2FA,
  token,
  authyId,
  login,
  password,
});

export const requestPhone = (phone, countryCode, login) => ({
  type: types.REQUEST_PHONE,
  phone,
  countryCode,
  login,
});

export const requestPhoneSuccess = authyId => ({
  type: types.REQUEST_PHONE_SUCCESS,
  authyId,
});

export const requestPhoneFailed = reason => ({
  type: types.REQUEST_PHONE_FAILED,
  reason,
});
