/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import { saveShippingPickupFailed, saveShippingPickupSuccess, fetchShippingPickUpFailed, fetchShippingPickUpSuccess, removeShippingItems } from '../../actions/logistics/shippingPickupActions';
import getBaseUrl from '../../utils/envSetup';

const baseUrl = getBaseUrl();

const selectLogisticsState = state => state.logistics.shippingPickup;

function* saveShippingPickupAsync() {
    const logisticsState = yield select(selectLogisticsState);
        
    const response = yield call(
        request,
        `${baseUrl}/api/logistics/pickup`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data : logisticsState.addedPickup})
        },
    );

    if(response.err) {
        yield put(saveShippingPickupFailed(response.err))
    } else {
        const arr = logisticsState.addedPickup.map(i => i.payment);
        yield put(removeShippingItems(arr))
        yield put(saveShippingPickupSuccess(response.data));

    } 
}

function* fetchShippingPickupsAsync() {
    const logisticsState = yield select(selectLogisticsState);

    const response = yield call(
        request,
        `${baseUrl}/api/logistics/pickup/data?limit=${logisticsState.limit}&skip=${logisticsState.offset}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    if (response.err) {
        yield put(fetchShippingPickUpFailed(response.err.toString()));
    } else {
        yield put(fetchShippingPickUpSuccess(response.data));
    }
}

function* saveShippingPickUp() {
    yield takeLatest([`${types.LOGISTICS.ADD_SHIPPING_PICKUP}`
    ], saveShippingPickupAsync);
}

function* fetchShippingPickup() {
    yield takeLatest([`${types.LOGISTICS.FETCH_SHIPPING_PICKUP}`, `${types.LOGISTICS.FETCH_MORE_SHIPPING_PICKUP}`
    ], fetchShippingPickupsAsync);
}

export default function* shippingPickupSaga() {
    yield all([
        saveShippingPickUp(),
        fetchShippingPickup()
    ]);
}
