
import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';

const initialState = {
  brands: [],
  filterKeyword: ''
};

export default createReducer(
  {
    [types.FETCH_ALL_BRANDS]: (state, action) => ({
      ...state,
      filterKeyword: action.data.filterKeyword,
    }),

    [types.FETCH_ALL_BRANDS_SUCCESS]: (state, action) => ({
      ...state,
      brands: action.data.brands,
    }),
    [types.FETCH_ALL_BRANDS_FAILED]: state => ({
      ...state,
      brands: []
    })
  },
  initialState,
);
