import {takeLatest, takeEvery, put, call, all, select} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import {
  addAllowedCountryFailed,
  addAllowedCountrySuccess,
  addCountryZonesFailed,
  addCountryZonesSuccess,
  deleteAllowedCountryFailed,
  deleteAllowedCountrySuccess,
  deleteCountryZonesSuccess,
  fetchAllowedCountriesFailed,
  fetchAllowedCountriesSuccess,
  fetchCountryZonesFailed,
  fetchCountryZonesSuccess,
  updateCountryZonesFailed,
  updateCountryZonesSuccess,
  fetchZoneFailed,
  fetchZoneSuccess,
  deleteCountryZonesFailed,
  updateCountryInCountryZones,
  updateZoneSuccess,
  addZoneSuccess,
  getCountriesAttributesSuccess,
  getCountriesAttributesFailed,
  updateAttributeCountrySuccess,
  updateAttributeCountryFailed,
  addAttributeCountrySuccess,
  addAttributeCountryFailed,
  getZonesCountriesAttributesSuccess,
  getZonesCountriesAttributesFailed,
} from '../actions/settingsActions';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';

const baseUrl = getBaseUrl();

function* fetchAllowedCountries(action) {
  const response = yield call(request, `${baseUrl}/api/settings/allowedCountryZones`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(fetchAllowedCountriesFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    yield put(fetchAllowedCountriesSuccess(response.data.data));
  }
}
function* addAllowedCountries(action) {
  const {buyerZone, sellerZone} = action.data;

  const response = yield call(request, `${baseUrl}/api/settings/allowedCountryZones`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({sellerZone: parseFloat(sellerZone), buyerZone: parseFloat(buyerZone)}),
  });

  if (response.err) {
    yield put(addAllowedCountryFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    yield put(addAllowedCountrySuccess(response.data));
    toast.success(!response.err && 'Saved');
  }
}
function* deleteAllowedCountries(action) {
  const id = action?.data;
  const response = yield call(request, `${baseUrl}/api/settings/allowedCountryZones/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({id: parseFloat(id)}),
  });

  if (response.err) {
    yield put(deleteAllowedCountryFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    yield put(deleteAllowedCountrySuccess(response.data.data));
    toast.success('Saved');
  }
}

function* deleteCountriesZones(action) {
  const id = action?.id;

  const response = yield call(request, `${baseUrl}/api/settings/countryZonesTaxes/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({id: parseFloat(id)}),
  });

  if (response.err) {
    yield put(deleteCountryZonesFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    toast.success(!response.err && 'Saved');
  }
}

const countriesAndZones = state => state.settingsReducer;

function* fetchCountriesZones(action) {
  const countriesZones = yield select(countriesAndZones);

  const response = yield call(
    request,
    `${baseUrl}/api/settings/countryZonesTaxes?limit=${countriesZones.limit}&skip=${countriesZones.offset}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchCountryZonesFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    yield put(fetchCountryZonesSuccess(response.data.data));
  }
}

function* fetchZoneState(action) {
  const response = yield call(request, `${baseUrl}/api/settings/countryZonesTaxes/236`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(fetchZoneFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    yield put(fetchZoneSuccess(response.data.data));
  }
}
function* addCountriesZones(action) {
  const {data} = action;

  const response = yield call(request, `${baseUrl}/api/settings/countryZonesTaxes`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      countryZoneId: parseFloat(data.zoneId),
      localTax: parseFloat(data.countryTax.taxes[0].tax_value),
      importTax: parseFloat(data.countryTax.taxes[1].tax_value),
    }),
  });

  if (response.err) {
    yield put(addCountryZonesFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    if (action.zone) {
      yield put(addZoneSuccess(response.data, data.zoneId));
      toast.success(!response.err && 'Saved');
    } else {
      yield put(addCountryZonesSuccess(response.data, data.zoneId));
      toast.success(!response.err && 'Saved');
    }
  }
}
function* updateCountriesZones(action) {
  const {
    id: {id},
  } = action;

  const response = yield call(request, `${baseUrl}/api/settings/countryZonesTaxes/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      localTax: parseFloat(action?.id?.taxes[0]?.tax_value),
      importTax: parseFloat(action?.id?.taxes[1]?.tax_value),
    }),
  });
  if (response.err) {
    yield put(updateCountryZonesFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    if (action.zone) {
      yield put(updateZoneSuccess(response.data, action.zoneRowIndex));
      toast.success(!response.err && 'Saved');
    } else {
      yield put(updateCountryZonesSuccess(response.data, action.rowIndex));
      toast.success(!response.err && 'Saved');
    }
  }
}
const attributesCountries = state => state.settingsReducer;

function* getAllCountriesAttributes(action) {
  const attributes = yield select(attributesCountries);
  const response = yield call(
    request,
    `${baseUrl}/api/settings/countryZoneAttributes?limit=${attributes.limit}&skip=${attributes.offset}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(getCountriesAttributesFailed(response.err.toString()));
  } else {
    yield put(getCountriesAttributesSuccess(response?.data));
  }
}
function* getZoneCountriesAttributes(action) {
  const {id} = action.payload;
  const response = yield call(request, `${baseUrl}/api/settings/countryZoneAttributes/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(getZonesCountriesAttributesFailed(response.err.toString()));
  } else {
    yield put(getZonesCountriesAttributesSuccess(response?.data));
  }
}

function* updateAttributesOfCountry(action) {
  const {countryId, attributes} = action.payload;

  const response = yield call(request, `${baseUrl}/api/settings/countryZoneAttributes`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      countryZoneId: countryId,
      attributes: attributes,
    }),
  });
  if (response.err) {
    yield put(addAttributeCountryFailed(response.err.toString()));
    yield put(updateAttributeCountryFailed(response.err.toString()));
    toast.error(response.err.toString());
  } else {
    yield put(addAttributeCountrySuccess(response.data));
    yield put(updateAttributeCountrySuccess(response.data));
    toast.success(!response.err && 'Saved');
  }
}

function* fetchItems() {
  yield takeLatest([types.FETCH_ALLOWED_COUNTRIES], fetchAllowedCountries);
}
function* addAllowedCountry() {
  yield takeLatest([types.ADD_ALLOWED_COUNTRIES], addAllowedCountries);
}
function* deleteAllowedCountry() {
  yield takeLatest([types.DELETE_ALLOWED_COUNTRIES], deleteAllowedCountries);
}

function* fetchCountryZones() {
  yield takeLatest([types.FETCH_COUNTRIES_ZONES, types.FETCH_MORE_COUNTRIES_ZONES], fetchCountriesZones);
}
function* addCountryZones() {
  yield takeLatest([types.ADD_COUNTRIES_ZONES, types.ADD_ZONE], addCountriesZones);
}
function* updateCountryZones() {
  yield takeLatest([types.UPDATE_COUNTRIES_ZONES, types.UPDATE_ZONE], updateCountriesZones);
}
function* removeCountryZones() {
  yield takeLatest([types.DELETE_COUNTRIES_ZONES, types.DELETE_ZONE], deleteCountriesZones);
}

function* getZone() {
  yield takeLatest([types.FETCH_ZONE], fetchZoneState);
}

function* getCountriesAttributes() {
  yield takeLatest(
    [types.FETCH_COUNTRIES_ATTRIBUTES, types.FETCH_MORE_COUNTRIES_ATTRIBUTES],
    getAllCountriesAttributes,
  );
}
function* updateAttributeCountry() {
  yield takeLatest([types.UPDATE_COUNTRY_ATTRIBUTES, types.ADD_COUNTRY_ATTRIBUTES], updateAttributesOfCountry);
}

function* getZonesCountriesAttributes() {
  yield takeLatest([types.FETCH_ZONES_COUNTRIES_ATTRIBUTES], getZoneCountriesAttributes);
}
export default function* settingsSaga() {
  yield all([
    fetchItems(),
    addAllowedCountry(),
    deleteAllowedCountry(),
    fetchCountryZones(),
    getZone(),
    removeCountryZones(),
    updateCountryZones(),
    addCountryZones(),
    getCountriesAttributes(),
    updateAttributeCountry(),
    getZonesCountriesAttributes(),
  ]);
}
