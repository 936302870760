import React from 'react';
import RadioGroup from '../primitives/RadioGroup';
import TimePicker from 'material-ui/TimePicker/TimePicker';
import moment from 'moment';
import Checkbox from 'material-ui/Checkbox';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const SECONDS_IN_A_DAY = 86400;

const timeToSecondsConverter = (time, nextDay) => {
	const midnight = moment().startOf('day').unix();
	const currentTime = moment(time).unix();
	return nextDay ? (currentTime - midnight + SECONDS_IN_A_DAY) : ((currentTime - midnight) % SECONDS_IN_A_DAY);
};

const secondsToTimeConverter = seconds => {
	const midnight = moment().startOf('day');
	return midnight.add(seconds,'seconds').toDate();
};

const Day = ({ from, to, popupType, day, changeRadio, radioId, setDayParameters, nextDay }) => (
	<div className="flxc ac jsb day">
		<div className="flxr jfs ac title">{daysOfWeek[day]}</div>
		<div className="flxr ac jsb wf">
			<span>From:</span>
			<TimePicker
				style={{width: '170px', overflow: 'hidden'}}
				value={secondsToTimeConverter(from)}
				format={'ampm'}
				id={"from" + radioId}
				onChange={(_, date) => setDayParameters({ from: timeToSecondsConverter(date) })}
			/>
		</div>
		<div className="flxr ac jsb wf">
			<span>To:</span>
			<TimePicker
				style={{width: '170px', overflow: 'hidden'}}
				value={secondsToTimeConverter(to)}
				fromat={'ampm'}
				id={"to" + radioId}
				onChange={(_, date) => setDayParameters({ to: timeToSecondsConverter(date, nextDay) })}
			/>
		</div>
		<Checkbox
			label="Next day"
			checked={nextDay}
			onCheck={() => setDayParameters({ to: timeToSecondsConverter(secondsToTimeConverter(to), !nextDay), nextDay: !nextDay })}
			style={{margin: '20px 0'}}
		/>
		<RadioGroup
			items={[
				{ value: 1, caption: 'Popup 1', },
				{ value: 2, caption: 'Popup 2', }
			]}
			name={radioId}
			value={popupType}
			onChange={val => setDayParameters({ popupType: parseInt(val, 10) })}
		/>
	</div>
);

export default Day;
