/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
    createPackageSuccess, createPackageError,savePackageError
} from '../../actions/logistics/packageActions';
import getBaseUrl from '../../utils/envSetup';
//import * as completedActions from "../../actions/logistics/completedactions";

const baseUrl = getBaseUrl();

const selectPackageItemState = state => state.logistics.packageItem;

function* fetchPackageDataAsync() {
    const packageIem = yield select(selectPackageItemState);
    let tr_code = packageIem.tr_code;
    let user_id = packageIem.user_id;
    let shupper_id = packageIem.shupper_id;
    const url = `${baseUrl}/api/logistics/package/info/${tr_code}?user_id=${user_id}&shupper_id=${shupper_id}`
    const response = yield call(
        request,
        url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    if (response.err) {
        yield put(createPackageError(response.err.response.statusText));
    } else {
        yield put(createPackageSuccess(response.data));
    }
}

function* fetchOpenPackageModal() {
    yield takeLatest([`${types.LOGISTICS.FETCH_PACKAGE}`], fetchPackageDataAsync);
}

function* savePackageAsync(){
    const packageItem = yield select(selectPackageItemState);
    const url = `${baseUrl}/api/logistics/package/save`
    const userAddress = packageItem.userAddress
   
    const body = {
        Dimensions : packageItem.Dimensions,
        ids: packageItem.package.map(i => i._id),
        userAddress,
        tr_code: packageItem.tr_code,
        shupper_id: packageItem.shupper_id,
        user_email: packageItem.user_email,
        shupper_email: packageItem.shupper_email
    }

    const response = yield call(
        request,
        url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        },
    );

    console.log(response);  


    if(response.err || response.data.Data.Error) {
        yield put(savePackageError(response.data))

    } else {
        // yield put(savePackageSuccess(response))
        // yield put(completedActions.fetchCompleted)
    }
}

function* savePackage() {
    yield takeLatest([`${types.LOGISTICS.SAVE_PACKAGE}`], savePackageAsync);
}

export default function* completedsaga() {
    yield all([
        fetchOpenPackageModal(),
        savePackage()
    ]);
}
