/*
Kateryna Ivanova
29/03/2018

Redux actions for bo users
*/
import types from './actionTypes';

export const fetchBOUsers = searchQuery => ({
  type: types.FETCH_BO_USERS,
  searchQuery,
});

export const fetchBOUsersSortChanged = (column, direction) => ({
  type: types.FETCH_BO_USERS_SORT_CHANGED,
  column,
  direction,
});

export const fetchMoreBOUsers = () => ({
  type: types.FETCH_MORE_BO_USERS,
});

export const fetchBOUsersSuccess = data => ({
  type: types.FETCH_BO_USERS_SUCCESS,
  data,
});

export const fetchBOUsersFailed = reason => ({
  type: types.FETCH_BO_USERS_FAILED,
  reason,
});
