import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { ReactComponent as CopyIcon } from '../../static/images/copy_icon.svg';
import '../../static/styles/personalInfo.css';
import { toast } from "react-toastify";
import { ToastNotification } from "../../utils/toastify";
import { countries } from "../../utils/countries";
import moment from 'moment';

const useStyles = makeStyles({
  input: {
    width: '100%'
  },
  gridItem: {
    padding: 4
  },
  text: {
    lineHeight: '15px',
    letterSpacing: '0.01em',
    color: '#8D8D8D',
    fontWeight: 500,
    fontFamily: 'OverPass',
    fontStyle: 'normal',
    marginTop: 10
  },
  chat: {
    border: '2px solid #141B41',
    background: '#EDFF00',
    marginTop: '40px',
    width: '100%',
    '&:hover': {
      backgroundColor: '#EDFF00',
    }
  },
  edit: {
    cursor: 'pointer'
  }
});

const PersonalInfo = ({
  userInfo,
  id,
  permission }) => {

  const navigate = useNavigate()
  const classes = useStyles()

  const handleCopyToClipboard = (copyValue) => {
    navigator.clipboard.writeText(copyValue)
    toast.info('Copied', { icon: <CopyIcon style={{ backgroundColor: 'none', background: 'none', color: 'red' }} /> })
  }
  const [hover, setHover] = React.useState(false);
  const onHover = () => {
    setHover(!hover)
  }
  const defineCountry = countries?.filter(country => country?.id === userInfo?.country_id)

  return (
    <React.Fragment>
      <ToastNotification width={170} color='white' background='#007AFF' hideBar={true} position='top-right' close={false} />
      <div >
        <Grid container direction="row" justifyContent="space-between" className="profileInfoTitleWrapper">
          <Grid item><Typography variant="h5">Profile</Typography></Grid>
          <Grid item><Typography variant="h6" color="primary" className={classes.edit} onClick={() => navigate(`/profile/edit/${id}`, { state: { phone: userInfo?.phone_number } })}>Edit</Typography></Grid>
        </Grid>
        <Paper className="profileInfoWrapper">
          <Grid container direction="row" justifyContent='space-between' wrap='wrap' spacing={0}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Typography className={classes.text}>ID {userInfo?.id} </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="dateCreateText">
              <Typography className={classes.text}>Date created { moment(userInfo?.create_date).format('L h:mm')}</Typography>
            </Grid>
            <Grid container xs={12}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <img src={userInfo?.profileImageURL} className="userAvatarWrapper" />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Button className={classes.chat}>Open Chat</Button>
              </Grid>
            </Grid>

            <Grid xl={6} lg={6} md={6} sm={6} xs={6} className={classes.gridItem}>
              <Typography className={classes.text}>First name</Typography>
              {userInfo?.first_name}
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={6} className={classes.gridItem}>
              <Typography className={classes.text} >Last name</Typography>
              {userInfo?.last_name}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.gridItem}>
              <Typography className={classes.text}> Type</Typography>
              {userInfo?.type_id === 1 ? 'User' : 'Shupper'}
              <div className="userRoles"></div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.gridItem}>
              <Typography className={classes.text}>Status</Typography>
              {userInfo?.state_id === 1 ? 'Active' : 'Inactive'}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.gridItem}>
              <Typography className={classes.text}>Country</Typography>
              {defineCountry[0]?.iso2_name}
            </Grid>
            <Grid item lg={6} xl={6} sm={6} xs={6} md={6} className={classes.gridItem}>
              <Typography className={classes.text}>CCY</Typography>
              USD
            </Grid>
            <Grid item lg={12} xl={12} sm={12} xs={12} md={12} style={{ width: 100 }} className={classes.gridItem}>
              <Typography className={classes.text} >Email</Typography>
              {userInfo?.email} <CopyIcon onClick={() => handleCopyToClipboard(userInfo?.email)} className="copyIcon" />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.gridItem}>
              <Typography className={classes.text}>Phone number </Typography>
              {userInfo?.phone_number} <CopyIcon onClick={() => handleCopyToClipboard(userInfo?.phone_number)} className="copyIcon" />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </React.Fragment >
  );
}
export default PersonalInfo;
