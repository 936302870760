import types from './actionTypes';

export const fetchItemsV2 = data => ({
  type: types.FETCH_ITEMS_V2,
  data,
});

export const fetchMoreItemsV2 = () => ({
  type: types.FETCH_MORE_ITEMS_V2,
});

export const fetchItemsV2Success = data => ({
  type: types.FETCH_ITEMS_V2_SUCCESS,
  data,
});

export const fetchItemsV2Failed = reason => ({
  type: types.FETCH_ITEMS_V2_FAILED,
  reason,
});
