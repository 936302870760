import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import EditorModeEdit from 'material-ui/svg-icons/editor/mode-edit';
import ClearIcon from 'material-ui/svg-icons/content/clear';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import InputIcon from 'material-ui/svg-icons/action/input';
import IconMenu from 'material-ui/IconMenu';
import Chip from 'material-ui/Chip';
import DropDownMenu from 'material-ui/DropDownMenu';
import SecurityIcon from 'material-ui/svg-icons/hardware/security';
import WatchIcon from 'material-ui/svg-icons/action/watch-later';
import RaisedButton from 'material-ui/RaisedButton';
import ImagePicker from '../components/imagepicker';
import SearchField from '../components/searchfield';
import PaginatedGrid from '../components/paginatedgrid';
import Multipicker from '../components/multipicker';
import Singlepicker from '../components/singlepicker';
import * as usersActions from '../actions/usersactions';
import { getPermission } from '../utils/cookies';
import NoPermission from '../components/nopermission';
import { decodePermissions } from '../utils/permissionUtil';
import MiniList from '../components/MiniList';

import '../static/styles/userlist.css';
import { withRouter } from '../utils/withRouter';

const headerData = [
  { id: 'profileImageURL', title: 'Profile Image' },
  { id: 'displayName', title: 'Name' },
  { id: 'firstName', title: 'First Name' },
  { id: 'lastName', title: 'Last Name' },
  { id: 'gender', title: 'Gender' },
  { id: 'email', title: 'E-mail' },
  { id: 'created', title: 'Create date' },
  { id: 'status', title: 'Status' },
  { id: 'location', title: 'Location' },
  { id: 'phoneNumber', title: 'Phone' },
  { id: 'roles', title: 'Role' },
  { id: 'pinnedLocation', title: 'Pinned Location' },
  { id: 'feedIndex', title: 'Feed index' },
  { id: 'regeneratePassword', title: 'Regenerate password' },
  { id: 'moveItems', title: 'Move Items' },
  { id: 'setInaccessibility', title: 'Setup Duty Off' },
  { id: 'monthlyTotalTips', title: 'Monthly total tips' }


];
const columnWidths = [100, 120, 100, 100, 120, 120, 200, 150, 100, 150, 150, 200, 100, 100, 100, 100, 150];

const SelectFilter = ({
  handleChange, items, type, value, multiple,
}) => (
  <DropDownMenu
    value={value}
    onChange={handleChange}
    className="filter-dropdown"
    multiple={multiple}
  >
    <MenuItem value={type === 'role' ? null : ''} primaryText={type} />
    {
      items?.map(item => (
        <MenuItem key={item} value={item} primaryText={item} />
      ))
    }
  </DropDownMenu>
);

SelectFilter.defaultProps = {
  multiple: false,
};

SelectFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

class OldUserList extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      selectedUsers: [],
      rolesPickerOpen: false,
      rolesPickerUser: null,
      statusPickerOpen: false,
      statusPickerUser: null,
      pinnedLocDialogOpen: false,
      pinnedLocDialogUser: null,
      feedIndexDialogOpen: false,
      feedIndexDialogUser: null,
      pinnedLoc: null,
      feedIndex: null,
      permission,
      regeneratePasswordDialogOpen: false,
      regeneratePasswordDialogUser: null,
      uploaderOpened: false,
      avatarUploaderImage: null,
      profileImageFile: null,
      userToLooseItems: null,
      userToGainItems: null,
      userListDialogOpened: null,
      displayNameDialogUser: null,
      displayNameDialogOpen: false,
      displayNameInDialog: '',
    };
  }

  componentDidMount() {
    if (this.state.permission.users) {
      this.props.fetchUsers();
    }
  }
  componentWillMount() {
    this.clearFilters()
  }
  onRolesSelectionDone(roles) {
    const { rolesPickerUser } = this.state;
    this.props.updateUser({ _id: rolesPickerUser._id, roles });
    this.setState({ rolesPickerOpen: false });
  }

  onStatusSelectionDone(status) {
    const { statusPickerUser } = this.state;
    this.props.updateUser({ _id: statusPickerUser._id, status });
    this.setState({ statusPickerOpen: false });
  }

  onSetPinnedLoc() {
    const { pinnedLocDialogUser, pinnedLoc } = this.state;
    this.props.updateUser({ _id: pinnedLocDialogUser._id, pinnedLocation: pinnedLoc });
    this.setState({ pinnedLocDialogOpen: false });
  }

  onSetDisplayName() {
    const { displayNameInDialog, displayNameDialogUser } = this.state;
    this.props.updateUser({ _id: displayNameDialogUser._id, displayName: displayNameInDialog });
    this.setState({ displayNameDialogOpen: false });
  }

  onSetFeedIndex() {
    const { feedIndexDialogUser, feedIndex } = this.state;
    this.props.updateUser({ _id: feedIndexDialogUser._id, feedIndex });
    this.setState({ feedIndexDialogOpen: false });
  }

  onRegeneratePassword = () => {
    this.props.regeneratePassword(this.state.regeneratePasswordDialogUser._id);
  };

  handleSelection = (selectedItems) => {

    const { usersReducerOld: { users } } = this.props;
    const result = [];
    selectedItems?.forEach((selectedItem) => {
      result.push(users[selectedItem]);
    });
    this.setState({ selectedUsers: result });
  };

  clearFilters = () => {
    this.props.clearFilters();
  };

  changeGenderFilter = (event, key, value) => {
    this.doSearch(null, null, null, null, value);
  };

  changeStatusFilter = (event, key, value) => {
    this.doSearch(null, null, null, value, null);
  };

  changeRoleFilter = (event, key, value) => {
    const filteredRoles = value.filter(i => i !== null);
    const result = filteredRoles.length === 0 ? [null] : filteredRoles;
    this.doSearch(null, null, result, null, null);
  };

  processAction(selectedAction) {
    const { selectedUsers } = this.state;
    const { bulkUpdateUsers } = this.props;
    if (selectedUsers && selectedUsers.length > 0) {
      const userIds = selectedUsers.map(i => i._id);
      switch (selectedAction) {
        case 'setshupper':
          bulkUpdateUsers(userIds, { roles: ['shupper'] });
          break;
        case 'setsupershupper':
          bulkUpdateUsers(userIds, { roles: ['supershupper'] });
          break;
        case 'archive':
          bulkUpdateUsers(userIds, { status: 'archive' });
          break;
        case 'active':
          bulkUpdateUsers(userIds, { status: 'active' });
          break;
        default:
          break;
      }
    }
  }

  searchForUsers(query, roles, roleFilter, statusFilter, genderFilter) {
    this.props.fetchUsers(query, roles, roleFilter, statusFilter, genderFilter);
  }

  doSearch(query, roles, roleFilter, statusFilter, genderFilter) {
    this.props.fetchUsers(query, roles, roleFilter, statusFilter, genderFilter);
  }

  chooseUserInMiniList = ({ id }) => this.setState({ userToGainItems: id });
  getTotalTipsOfMonth = (tips) => {
    const month = moment().format("MMMM")

    let total = 0
    tips.map(item => {

      if (moment(item.date).format("MMMM") === month) total += item.tip
      return item
    })
    return total
  }
  render() {
    const {
      usersReducerOld, props, fetchMoreUsers, fetchUsersSortChanged, onOpenDrawer, clearRegeneratedPassword, updateProfileImage,
      moveItemsBetweenUsers, setActiveTips,
    } = this.props;

    const {
      selectedUsers, rolesPickerOpen, rolesPickerUser, statusPickerOpen, statusPickerUser,
      pinnedLocDialogUser, pinnedLocDialogOpen, pinnedLoc,
      feedIndexDialogUser, feedIndexDialogOpen, feedIndex, permission,
      regeneratePasswordDialogUser, regeneratePasswordDialogOpen, uploaderOpened,
      avatarUploaderImage, imageUserId, profileImageFile, userListDialogOpened,
      userToLooseItems, userToGainItems, displayNameDialogUser, displayNameInDialog,
      displayNameDialogOpen,
    } = this.state;
    const tableHeight = window.innerHeight - 177;
    console.log('this.state.selectedUser', this.state.selectedUsers)
    const actionsEnabled = selectedUsers && selectedUsers.length > 0;
    const {
      genderList, genderFilter, statusList, statusFilter, roleList, roleFilter,
    } = usersReducerOld;
    const setShupperDisabled = actionsEnabled && permission.set_shupper_supershupper;
    return permission.users ?

      <div className="users-container">
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <div className="header-search-area">
            <SearchField onRequestSearch={query => this.doSearch(query, null, null, null, null)} />
          </div>
          <div className="filters-container">
            <SelectFilter
              items={genderList}
              type="gender"
              value={genderFilter}
              handleChange={this.changeGenderFilter}
            />
            <SelectFilter
              items={statusList}
              type="status"
              value={statusFilter}
              handleChange={this.changeStatusFilter}
            />
            <SelectFilter
              items={roleList}
              type="role"
              value={roleFilter}
              handleChange={this.changeRoleFilter}
              multiple
            />
            <IconButton tooltip="Clear filters" onClick={this.clearFilters}>
              <ClearIcon color="#fff" />
            </IconButton>
          </div>
          <span className="header-placeholder" />
          <IconMenu
            iconButtonElement={<IconButton><MoreVertIcon color="#fff" /></IconButton>}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <MenuItem
              primaryText="Set Shupper"
              disabled={!setShupperDisabled}
              onClick={() => this.processAction('setshupper')}
            />
            <MenuItem
              primaryText="Set Super Shupper"
              disabled={!setShupperDisabled}
              onClick={() => this.processAction('setsupershupper')}
            />
            <MenuItem
              primaryText="Archive"
              disabled={!actionsEnabled}
              onClick={() => this.processAction('archive')}
            />
            <MenuItem
              primaryText="Activate"
              disabled={!actionsEnabled}
              onClick={() => this.processAction('active')}
            />
          </IconMenu>
        </div>
        <PaginatedGrid
          data={usersReducerOld.users}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          hasMore={usersReducerOld.hasMore}
          isLoading={usersReducerOld.isLoading}
          header={headerData}
          totalResults={usersReducerOld.totalCount}
          onSelectRow={this.handleSelection}
          selection={selectedUsers}
          sortableCols={[
            'displayName', 'gender', 'email', 'created', 'status',
            'location', 'pinnedLocation',
          ]}
          currentSort={usersReducerOld.sort}
          onSort={(column, direction) => fetchUsersSortChanged(column, direction)}
          items={usersReducerOld.users.map((user, key) => [

            <span>
              <img alt="" className="avatar" src={user.profileImageURL} />
              <IconButton
                iconStyle={{ width: '20px', height: '20px' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.setState({ uploaderOpened: true, avatarUploaderImage: user.profileImageURL, imageUserId: user._id });
                }}
              >
                <EditorModeEdit color="#cdcdcd" />
              </IconButton>
            </span>,
            <span>
              <Link to={`/profileold/${user._id}`}>{user.displayName}</Link>
              <IconButton
                iconStyle={{ width: '20px', height: '20px' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.setState({
                    displayNameDialogUser: user,
                    displayNameDialogOpen: true,
                    displayNameInDialog: user.displayName,
                  });
                }}
              >
                <EditorModeEdit color="#cdcdcd" />
              </IconButton>
            </span>,
            <span>
              {user.firstName}
            </span>,
            <span>
              {user.lastName}
            </span>,
            <span>{user.gender}</span>,
            <span>{user.email}</span>,
            <span>{moment(user.created).format('LL')}</span>,
            <span className="pinned-loc-in-table">
              <IconButton
                iconStyle={{ width: '20px', height: '20px' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.setState({ statusPickerUser: user, statusPickerOpen: true });
                }}
              >
                <EditorModeEdit color="#cdcdcd" />
              </IconButton>
              <span>{user.status}</span>
            </span>,
            <span>{user.location}</span>,
            <span>{user.address.phoneNumber}</span>,
            <span>
              <IconButton
                iconStyle={{ width: '20px', height: '20px' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.setState({ rolesPickerUser: user, rolesPickerOpen: true });
                }}
                disabled={!permission.set_shupper_supershupper}
              >
                <EditorModeEdit color="#cdcdcd" />
              </IconButton>
              <span>
                {user.roles.map(role => (
                  <Chip key={role} className="user-role-in-table">
                    {role}
                  </Chip>
                ))}
              </span>
            </span>,
            <span className="pinned-loc-in-table">
              <IconButton
                iconStyle={{ width: '20px', height: '20px' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.setState({
                    pinnedLocDialogUser: user,
                    pinnedLocDialogOpen: true,
                    pinnedLoc: user.pinnedLocation,
                  });
                }}
              >
                <EditorModeEdit color="#cdcdcd" />
              </IconButton>
              <span>{user.pinnedLocation}</span>
            </span>,
            <span className="pinned-loc-in-table">
              <IconButton
                iconStyle={{ width: '20px', height: '20px' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.setState({
                    feedIndexDialogUser: user,
                    feedIndexDialogOpen: true,
                    feedIndex: user.feedIndex,
                  });
                }}
              >
                <EditorModeEdit color="#cdcdcd" />
              </IconButton>
              {user.feedIndex}
            </span>,
            <span>
              <IconButton
                iconStyle={{ width: '20px', height: '20px' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.setState({
                    regeneratePasswordDialogUser: user,
                    regeneratePasswordDialogOpen: true,
                  });
                }}
              >
                <SecurityIcon color="#cdcdcd" />
              </IconButton>
            </span>,
            <span>{
              user.roles.includes('shupper') || user.roles.includes('supershupper') ?
                <IconButton
                  iconStyle={{ width: '20px', height: '20px' }}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.searchForUsers('', ['shupper'], null, null, null);
                    this.setState({ userListDialogOpened: true, userToLooseItems: user._id });
                  }}
                >
                  <InputIcon />
                </IconButton> : null
            }
            </span>,
            <span>
              {
                user.roles.includes('shupper') || user.roles.includes('supershupper') || user.roles.includes('store') ?
                  <IconButton onClick={() => this.props.navigate('/inaccessibility/' + user._id, { replace: true })}>
                    <WatchIcon color="black" />
                  </IconButton>
                  : null
              }
            </span>,
            <span>{user.tips && user.tips.length > 0 ?
              <Link onClick={() => setActiveTips(`${user.firstName} ${user.lastName}`)} to={`/users/tip/${user._id}`}>
                {this.getTotalTipsOfMonth(user.tips)}</Link> : "0"}</span>
          ])}
          onLoadMore={() => fetchMoreUsers()}
        />

        <Dialog
          contentStyle={{ width: '400px', height: '500px' }}
          title={
            'Move owned items to:'
          }
          actions={[
            <FlatButton label="Close" primary onClick={() => this.setState({ userListDialogOpened: false, userToLooseItems: null, userToGainItems: null, })} />,
            <FlatButton label="Apply" primary onClick={() => {
              moveItemsBetweenUsers(userToLooseItems, userToGainItems);
              this.setState({ userListDialogOpened: false, userToLooseItems: null, userToGainItems: null, });
            }} />
          ]}
          modal={false}
          open={userListDialogOpened}
          onRequestClose={() => this.setState({ userListDialogOpened: false })}
        >
          <React.Fragment>
            <MiniList
              items={usersReducerOld.users.map(user => ({
                id: user._id,
                title: `${user.displayName}, ${user.location}`,
                image: user.profileImageURL,
              }))}
              chosen={{ id: userToGainItems }}
              choose={this.chooseUserInMiniList}
              loadMore={fetchMoreUsers}
              onRequestSearch={query => this.searchForUsers(query, ['shupper'], null, null, null)}
            />
          </React.Fragment>
        </Dialog>

        <Dialog
          contentStyle={{ width: '300px' }}
          title={
            `Upload new avatar for this user`
          }
          actions={[
            <ImagePicker
              url={typeof avatarUploaderImage === 'string' ? avatarUploaderImage : null}
              image={typeof avatarUploaderImage === 'object' ? avatarUploaderImage : null}
              onImageSelected={file => this.setState({
                profileImageFile: file,
                fileSizeError: false,
              })}
              onError={console.error}
              onFileRead={im => this.setState({ avatarUploaderImage: im })}
            />,
            profileImageFile ? <FlatButton label="Apply" primary onClick={() => {
              updateProfileImage({ _id: imageUserId, newImage: profileImageFile })
              this.setState({ uploaderOpened: false, avatarUploaderImage: null, profileImageFile: null, imageUserId: null });
            }} /> : null,
            <FlatButton label="Close" primary onClick={() => this.setState({ uploaderOpened: false, avatarUploaderImage: null, })} />
          ]}
          modal={false}
          open={uploaderOpened}
          onRequestClose={() => this.setState({ pinnedLocDialogOpen: false })}
        >

        </Dialog>

        <Multipicker
          onDone={roles => this.onRolesSelectionDone(roles)}
          open={rolesPickerOpen}
          onRequestClose={() => this.setState({ rolesPickerOpen: false })}
          values={[
            { id: 'shupper', title: 'Shupper' }, { id: 'supershupper', title: 'Super Shupper' }, { id: 'influencer', title: 'Influencer' }
          ]}
          selected={rolesPickerUser && rolesPickerUser.roles}
          title={`Select roles for ${rolesPickerUser && rolesPickerUser.displayName}`}
        />
        <Singlepicker
          onDone={status => this.onStatusSelectionDone(status)}
          open={statusPickerOpen}
          onRequestClose={() => this.setState({ statusPickerOpen: false })}
          values={[{ id: 'active', title: 'Active' }, { id: 'archive', title: 'Archived' }]}
          selected={statusPickerUser && statusPickerUser.status}
          title={`Set status for ${statusPickerUser && statusPickerUser.displayName}`}
        />
        <Dialog
          contentStyle={{ width: '300px' }}
          title={
            `Set pinned location for ${pinnedLocDialogUser && pinnedLocDialogUser.displayName}`
          }
          actions={[<FlatButton label="Set" primary onClick={() => this.onSetPinnedLoc()} />]}
          modal={false}
          open={pinnedLocDialogOpen}
          onRequestClose={() => this.setState({ pinnedLocDialogOpen: false })}
        >
          <TextField
            type="number"
            floatingLabelText="Pinned Location"
            onChange={(_, text) => this.setState({ pinnedLoc: text })}
            value={pinnedLoc}
          />
        </Dialog>
        <Dialog
          contentStyle={{ width: '300px' }}
          title={
            `Display name ${displayNameDialogUser && displayNameDialogUser.displayName}`
          }
          actions={[<FlatButton label="Set" primary onClick={() => this.onSetDisplayName()} />]}
          modal={false}
          open={displayNameDialogOpen}
          onRequestClose={() => this.setState({ displayNameDialogOpen: false })}
        >
          <TextField
            type="text"
            floatingLabelText="Display name"
            onChange={(_, text) => this.setState({ displayNameInDialog: text })}
            value={displayNameInDialog}
          />
        </Dialog>
        <Dialog
          contentStyle={{ width: '300px' }}
          title={`Set feed index for ${feedIndexDialogUser && feedIndexDialogUser.displayName}`}
          actions={[<FlatButton label="Set" primary onClick={() => this.onSetFeedIndex()} />]}
          modal={false}
          open={feedIndexDialogOpen}
          onRequestClose={() => this.setState({ feedIndexDialogOpen: false })}
        >
          <TextField
            type="number"
            floatingLabelText="Feed Index"
            onChange={(_, text) => this.setState({ feedIndex: text })}
            value={feedIndex}
          />
        </Dialog>
        <Dialog
          contentStyle={{ width: '300px' }}
          title={
            `Are you sure regenerate password for
            ${regeneratePasswordDialogUser && regeneratePasswordDialogUser.displayName} ?`
          }
          modal={false}
          open={regeneratePasswordDialogOpen}
          onRequestClose={() => {
            this.setState({ regeneratePasswordDialogOpen: false });
            clearRegeneratedPassword();
          }}
        >
          {usersReducerOld.newPassword &&
            <span>
              {`New password: ${usersReducerOld.newPassword}`}
            </span>
          }
          <RaisedButton
            label="Regenerate"
            primary
            style={{ marginTop: '15px', width: '100%' }}
            onClick={this.onRegeneratePassword}
          />
        </Dialog>
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

OldUserList.propTypes = {
  usersReducerOld: PropTypes.object.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  bulkUpdateUsers: PropTypes.func.isRequired,
  fetchMoreUsers: PropTypes.func.isRequired,
  fetchUsersSortChanged: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  regeneratePassword: PropTypes.func.isRequired,
  clearRegeneratedPassword: PropTypes.func.isRequired,
};

const mapStateToProps = ({ usersReducerOld }) => ({
  usersReducerOld,
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: (query, roles, roleFilter, statusFilter, genderFilter) =>
    dispatch(usersActions.fetchUsers(query, roles, roleFilter, statusFilter, genderFilter)),
  bulkUpdateUsers: (ids, data) => dispatch(usersActions.bulkUpdateUsers(ids, data)),
  fetchMoreUsers: () => dispatch(usersActions.fetchMoreUsers()),
  fetchUsersSortChanged: (column, direction) =>
    dispatch(usersActions.fetchUsersSortChanged(column, direction)),
  updateUser: data => dispatch(usersActions.updateUser(data)),
  clearFilters: () => dispatch(usersActions.clearUsersFilters()),
  regeneratePassword: id => dispatch(usersActions.regeneratePassword(id)),
  clearRegeneratedPassword: () => dispatch(usersActions.clearRegeneratedPassword()),
  updateProfileImage: user => dispatch(usersActions.updateUserProfileImage(user)),
  moveItemsBetweenUsers: (from, to) => dispatch(usersActions.moveItemsBetweenUsers(from, to)),
  setActiveTips: (tips) => dispatch(usersActions.setActiveTips(tips)),
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(OldUserList));