/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
  fetchCompletedFailed, fetchCompletedSuccess, makeRefundFailed, makeRefundSuccess,
    addStatusToCompletedSuccess, addStatusToCompletedError
} from '../../actions/logistics/completedactions';
import getBaseUrl from '../../utils/envSetup';

const baseUrl = getBaseUrl();

const selectLogisticsState = state => state.logistics.completed;

function* fetchCompletedAsync() {
  const logisticsState = yield select(selectLogisticsState);
  const sorting = Object.keys(logisticsState.sort).map(key => `&sort[${key}]=${logisticsState.sort[key]}`).join('');
  const startDate = logisticsState.startDate ? `&startDate=${logisticsState.startDate}` : '';
  const endDate = logisticsState.endDate ? `&endDate=${logisticsState.endDate}` : '';
  const searchQuery = logisticsState.searchQuery ? `&userName=${logisticsState.searchQuery}` : '';
  const url = `${baseUrl}/api/logistics/fulfilled?limit=${logisticsState.limit}&skip=${logisticsState.offset}${sorting}${startDate}${endDate}${searchQuery}`;
  const response = yield call(
    request,
	  url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchCompletedFailed(response.err.toString()));
  } else {
  	yield put(fetchCompletedSuccess(response.data));
  }
}

function* makeRefundAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/refund`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: action.id }),
    },
  );

  if (response.err) {
    yield put(makeRefundFailed(response.err.toString()));
  } else {
    yield put(makeRefundSuccess());
  }
}

function* addStatusToCompleteAsync(action) {
    const url = `${baseUrl}/api/logistics/fulfilled/add`
    const response = yield call(
        request,
        url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: action.id }),
        },
    );
    
    if(response.err) {
        yield put(addStatusToCompletedError(response.err))
    } else {
        yield put(addStatusToCompletedSuccess(response.data))

    }
}

function* fetchCompleted() {
  yield takeLatest([`${types.LOGISTICS.FETCH_COMPLETED}`, `${types.LOGISTICS.FETCH_MORE_COMPLETED}`,
    `${types.LOGISTICS.MAKE_REFUND_SUCCESS}`, `${types.LOGISTICS.FETCH_COMPLETED_SORT_CHANGED}`,
    `${types.LOGISTICS.FETCH_COMPLETED_DATERANGE_CHANGED}`], fetchCompletedAsync);
}

function* makeRefund() {
  yield  takeLatest([`${types.LOGISTICS.MAKE_REFUND}`], makeRefundAsync)
}

function* addStatusToCompleted() {
    yield takeLatest([`${types.LOGISTICS.ADD_SHIPPING_STATUS}` ], addStatusToCompleteAsync)
}

export default function* completedsaga() {
  yield all([
    fetchCompleted(),
    makeRefund(),
    addStatusToCompleted()
  ]);
}
