import {takeLatest, takeEvery, put, call, all, select} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {fetchAllUsersSuccess, fetchAllUsersFailed} from '../actions/usersactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectUsersState = state => state.usersReducer;

function* fetchAllUsersAsync(action) {
  const usersState = yield select(selectUsersState);

  const dateFilter = !usersState?.dateFilter.from
    ? ''
    : `&fromDate=${usersState?.dateFilter?.from}&toDate=${usersState?.dateFilter?.to}`;

  const statusFilter = usersState.statusFilter ? `&status=${usersState.statusFilter}` : '';
  const locationFilter = usersState.locationFilter ? `&country=${usersState.locationFilter}` : '';
  const typeFilter = usersState.typeFilter ? `&type=${usersState.typeFilter} ` : '';
  const otherFilters = `${typeFilter}${statusFilter}${locationFilter}${dateFilter}`;

  const nameRegex = /^[a-zA-Z ]+$/;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let searchQuery = '';
  const query = usersState?.searchQuery;

  if (query) {
    const isEmail = emailRegex.test(String(query));
    const isName = nameRegex.test(String(query));
    const q = `&q=${query}`;
    searchQuery = (isName && q) || (isEmail ? q : q);
  }
  const response = yield call(
    request,
    `${baseUrl}/api/user/search/v2?limit=${usersState.limit}&skip=${usersState.offset}${searchQuery}${otherFilters}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchAllUsersFailed(response.err.toString()));
  } else {
    yield put(fetchAllUsersSuccess(response.data));
    console.log('user saga respojse', response);
  }
}

function* fetchItems() {
  yield takeLatest([types.FETCH_ALL_USERS, types.FETCH_MORE_USERS], fetchAllUsersAsync);
}

export default function* usersSaga() {
  yield all([fetchItems()]);
}
