import React from 'react';
import { Player } from 'video-react';
import LightBox from './LightBox';
import 'video-react/dist/video-react.css';


const VideoViewer = ({ display, videoUrl, poster, closeRequest }) => (
    <LightBox display={display} closeRequest={closeRequest} >
        <Player
            poster={poster}
            aspectRation='auto'
            controls
            height={600}
            fluid={false}
        >
            <source src={videoUrl&&videoUrl.replace(/^http:\/\//i, 'https://')} type="video/mp4" />
        </Player>
        {/* <video src={videoUrl} controls width="400" height="400">
            Sorry, your browser doesn't support embedded videos,
            but don't worry, you can <a href={videoUrl}>download it</a>
            and watch it with your favorite video player!
          </video> */}
    </LightBox>
);

export default VideoViewer;