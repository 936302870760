import React from 'react';
import moment from 'moment';
import { Button, Grid, Typography, Paper, withStyles } from '@material-ui/core';


const TypographyText = withStyles({
  root: {
    '&.MuiTypography-root': {
      fontFamily: 'Overpass',
      fontSize: '16px',
      color: '#8d8d8d',
      marginBottom: '10px',
    },
  },
})(Typography);

const PaymentInfoDetails = ({ totalItemCost, shupperCCY, shupperSign, userCCY, userSing, getCurrencies, attempts, paymentRequest, mainBtnText, exchangeRate }) => {

  const statusPaymentObj = {
    1: 'pending',
    2: 'canceled',
    3: 'complete',
    4: ''
  }
  const excRate = paymentRequest?.item_cost_amount / paymentRequest?.amount_value


  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant="h5" className="paymentRequest-subtitle">
          PAYMENT
        </Typography>
        <Typography variant="h6" className="editLink">
          Edit
        </Typography>
      </Grid>
      <Paper className='info-wrapper paymentInfo-wrapper'>
        <Grid container>
          <Typography variant="h6" className="payment-info-subtitle">
            Payment Information
          </Typography>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Status</TypographyText>
            <div>
              <div className={`status-info status-${statusPaymentObj?.[paymentRequest?.state_id]}`}>
                {statusPaymentObj?.[paymentRequest?.state_id ?? 4]?.charAt(0)?.toUpperCase() +
                  statusPaymentObj?.[paymentRequest?.state_id ?? 4]?.slice(1)?.toLowerCase()}
              </div>
            </div>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Payment ID</TypographyText>
            <Typography>{paymentRequest?.id}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Date Created</TypographyText>
            <Typography>{moment(paymentRequest?.creation_date).format('L h:mm')}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Date Updated</TypographyText>
            <Typography>{moment(paymentRequest?.update_date).format('L h:mm')}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Shupperz CCY</TypographyText>
            <Typography>{shupperSign ?? ''}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>User CCY</TypographyText>
            <Typography>{userSing ?? ''}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Exchange Rate</TypographyText>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${1 * (exchangeRate ?? 0)})`}
              </span> {(shupperSign ?? '') + 1}
            </Typography>
          </Grid>
          <Typography variant="h6" className="payment-info-subtitle">
            Payment Details
          </Typography>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Items cost</TypographyText>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${Number(paymentRequest?.user_item_cost_amount || paymentRequest?.amount_value * exchangeRate || 0)?.toFixed(2)})`}
              </span> {shupperSign && shupperSign + (Number(paymentRequest?.amount_value || 0)?.toFixed(2) ?? '')}
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Local tax</TypographyText>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${Number(paymentRequest?.user_local_tax_amount || paymentRequest?.local_tax_value * exchangeRate || 0)?.toFixed(2)})`}
              </span> {shupperSign && shupperSign + Number(paymentRequest?.local_tax_value || 0)?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Shupperz fee</TypographyText>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${Number(paymentRequest?.user_fee_amount || paymentRequest?.shupperz_fee_value * exchangeRate || 0)?.toFixed(2)})`}
              </span> {shupperSign && shupperSign + Number(paymentRequest?.shupperz_fee_value || 0)?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <TypographyText>Shupperz tip</TypographyText>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${Number(paymentRequest?.successfulInfo?.tip_amount || 0)?.toFixed(2)})`}
              </span>{shupperSign && shupperSign + Number(paymentRequest?.successfulInfo?.tip_amount / excRate || 0)?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Shipping</TypographyText>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${Number(paymentRequest?.successfulInfo?.shipping_amount || 0)?.toFixed(2)})`}
              </span>{shupperSign && shupperSign + Number(paymentRequest?.successfulInfo?.shipping_amount / excRate || 0)?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" className="payment-info-text">
            <TypographyText>Import tax</TypographyText>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${Number(paymentRequest?.successfulInfo?.import_tax_amount || 0)?.toFixed(2)})`}
              </span>
              {shupperSign && shupperSign + Number(paymentRequest?.successfulInfo?.import_tax_amount / excRate || 0)?.toFixed(2)}
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Typography className="payment-info-total">Total items cost</Typography>
            <Typography>
              <span className="payment-text-span">
                {`(${userSing ?? ''}${(paymentRequest?.amount ? Number(paymentRequest?.amount)?.toFixed(2) : (
                  Number(paymentRequest?.amount_value * exchangeRate) +
                  Number(paymentRequest?.local_tax_value * exchangeRate) +
                  Number(paymentRequest?.shupperz_fee_value * exchangeRate)
                )?.toFixed(2))}
                  )`}
              </span>{shupperSign && shupperSign + (totalItemCost ||
                (
                  Number(paymentRequest?.amount_value) +
                  Number(paymentRequest?.local_tax_value) +
                  Number(paymentRequest?.shupperz_fee_value) +
                  Number(paymentRequest?.successfulInfo?.tip_amount / excRate || 0) +
                  Number(paymentRequest?.successfulInfo?.shipping_amount / excRate || 0) +
                  Number(paymentRequest?.successfulInfo?.import_tax_amount / excRate || 0)
                )?.toFixed(2))}
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button className="requestPayment-main-btn">{mainBtnText}</Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default PaymentInfoDetails;
