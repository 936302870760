
import types from '../../actions/actionTypes';
import { createReducer } from '../helpers/redux-helpers';

const initialState = {
    isLoadingSearch: false,
    tr_code: null,
    search_error: null,
    package: [],
    isOpen: false,
    Dimensions : null,
    userAddress: null,
    isSaving: false,
    save_error: null,
    user_id: null,
    shupper_id: null,
    addresses: [],
    user_email: null,
    shupper_email:null
    
};

export default createReducer({
    [types.LOGISTICS.FETCH_PACKAGE]: (state, action) => {
        return {
        ...state,
                isLoadingSearch: true,
                tr_code: action.tr_code,
                user_id: action.user_id,
                shupper_id:action.shupper_id,
                isOpen: true,
                user_email:action.user_email,
                shupper_email:action.shupper_email,
        }
    },
    [types.LOGISTICS.FETCH_PACKAGE_SUCCESS]: (state, action) => ({
        ...state,
        isLoadingSearch: false,
        package: action.package.data.orders,
        addresses: action.package.data.addresses,
        search_error: null
    }),
    [types.LOGISTICS.FETCH_PACKAGE_ERROR]: (state, action) => ({
        ...state,
        isLoadingSearch: false,
        search_error: action.error_by_load
    }),
    [types.LOGISTICS.CLOSE_PACKAGE_MODAL]: (state, action) => ({
        ...initialState }),
    [types.LOGISTICS.SAVE_PACKAGE] : (state, actions) => ({
        ...state,
        Dimensions: actions.Dimensions,
        userAddress: actions.userAddress,
        isSaving: true
    }),
    [types.LOGISTICS.SAVE_PACKAGE_SUCCESS] : (state, actions) => ({
        ...initialState,
        saved_success: true,
        addresses: []
    }),
    [types.LOGISTICS.SAVE_PACKAGE_ERROR] : (state, actions) => ({
        ...state,
        save_error: actions.save_error,
    })
}, initialState);
