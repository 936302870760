/*
Kateryna Ivanova
20/03/2018

Sagas for working with a single store
*/
import { takeLatest, put, call, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchStoreSuccess, fetchStoreFailed, updateStoreFailed,
  updateStoreSuccess, deleteStoreSuccess, deleteStoreFailed } from '../actions/storeactions';
import request from '../utils/networkRequest';
import uploadImage from '../utils/networkutil';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* fetchStoreAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/store/${action.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchStoreFailed(response.err.toString()));
  } else {
    yield put(fetchStoreSuccess(response.data));
  }
}

function* updateStoreAsync(action) {
  let profileImageURL = null;
  if (action.store.fileProfile) {
    const responseProfileImg = yield call(uploadImage, 'store', action.store._id, action.store.fileProfile);
    if (responseProfileImg.err) {
      yield put(updateStoreFailed(responseProfileImg.err.toString()));
      return;
    }
    profileImageURL = responseProfileImg.data.imgUrl;
  }

  let coverImageURL = null;
  if (action.store.fileCover) {
    const responseCoverImg = yield call(uploadImage, 'store', action.store._id, action.store.fileCover);
    if (responseCoverImg.err) {
      yield put(updateStoreFailed(responseCoverImg.err.toString()));
      return;
    }
    coverImageURL = responseCoverImg.data.imgUrl;
  }

  const body = {
    provider: 'admin',
    email: action.store.email,
    displayName: action.store.displayName,
    shupperDescription: action.store.shupperDescription,
    position: { coordinates: action.store.coordinates },
    storeDeliveryFee: action.store.storeDeliveryFee,
    location: action.store.location,
  };

  if (profileImageURL) body.profileImageURL = profileImageURL;
  if (coverImageURL) body.coverImageURL = coverImageURL;

  const response = yield call(
    request,
    `${baseUrl}/api/store/${action.store._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateStoreFailed(response.err.toString()));
  } else {
    yield put(updateStoreSuccess());
  }
}

function* createStoreAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/store`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        provider: 'admin',
        email: action.store.email,
        displayName: action.store.displayName,
        shupperDescription: action.store.shupperDescription,
        coordinates: action.store.coordinates ? action.store.coordinates.join(',') : null,
        location: action.store.location,
        storeDeliveryFee: action.store.storeDeliveryFee,
      }),
    },
  );

  if (response.err) {
    yield put(updateStoreFailed(response.err.toString()));
    return;
  }

  const id = response.data._id;
  let profileImageURL = null;
  if (action.store.fileProfile) {
    const responseProfileImg = yield call(uploadImage, 'store', id, action.store.fileProfile);
    if (responseProfileImg.err) {
      yield put(updateStoreFailed(responseProfileImg.err.toString()));
      return;
    }
    profileImageURL = responseProfileImg.data.imgUrl;
  }

  let coverImageURL = null;
  if (action.store.fileCover) {
    const responseCoverImg = yield call(uploadImage, 'store', id, action.store.fileCover);
    if (responseCoverImg.err) {
      yield put(updateStoreFailed(responseCoverImg.err.toString()));
      return;
    }
    coverImageURL = responseCoverImg.data.imgUrl;
  }

  if (coverImageURL || profileImageURL) {
    const result = yield call(
      request,
      `${baseUrl}/api/store/${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ profileImageURL, coverImageURL }),
      },
    );
    if (result.err) {
      yield put(updateStoreFailed(result.err.toString()));
      return;
    }
  }

  yield put(updateStoreSuccess());
}

function* deleteStoreAsync(action) {
  const storeIds = action.ids.join(',');
  const response = yield call(
    request,
    `${baseUrl}/api/store?ids=${storeIds}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteStoreFailed(response.err.toString()));
  } else {
    yield put(deleteStoreSuccess());
  }
}

function* fetchItems() {
  yield takeLatest([`${types.FETCH_STORE}`], fetchStoreAsync);
}

function* updateStore() {
  yield takeLatest([`${types.UPDATE_STORE}`], updateStoreAsync);
}

function* createStore() {
  yield takeLatest([`${types.CREATE_STORE}`], createStoreAsync);
}

function* deleteStore() {
  yield takeLatest([`${types.DELETE_STORE}`], deleteStoreAsync);
}

export default function* storeSaga() {
  yield all([
    fetchItems(), updateStore(), createStore(), deleteStore(),
  ]);
}
