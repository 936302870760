import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableCell, TextField, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import TableData from '../../components/table';
import { ModalWindow } from '../modal/modal';
import { ToastNotification } from '../../utils/toastify';
import { ReactComponent as AddIcon } from '../../static/images/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../static/images/deleteIcon.svg';
import { ReactComponent as Done } from '../../static/images/done.svg';
import { ReactComponent as TrashIcon } from '../../static/images/trash.svg';
import '../../static/styles/settingsTabs.css';
import { decodePermissions } from '../../utils/permissionUtil';
import { getPermission } from '../../utils/cookies';
import { useDispatch, useSelector } from 'react-redux';
import { addAllowedCountry, addEditAllowedCountry, addNewAllowedCountry, deleteAllowedCountry, getAllowedCountries, removeNewAllowedCountry } from '../../actions/settingsActions';
import { withRouter } from '../../utils/withRouter';
import { Loading } from '../loader';
import { settingsCountriesAndZonesTab } from '../table/tableStyles';
import { countries as allCountries } from '../../utils/countries';

const useStyles = makeStyles({
  // customTableStyle: {
  //   '& .MuiTableCell-root': {
  //     padding: 0,
  //   },
  // },
  centerTextAlign: {
    textAlign: 'center',
  },
});

const StyledAutocomplete = withStyles({
  root: {
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      padding: 0,
    },
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
})(Autocomplete);

const AllowedCountriesTab = () => {
  const permission = decodePermissions(getPermission());
  const dispatch = useDispatch()
  const allowedCountries = useSelector(({ settingsReducer }) => settingsReducer || {})
  const dataAllowedCountries = allowedCountries?.allowedCountries
  const classes = useStyles();
  const [currentRow, setCurrentRow] = useState(0);
  const [edit, setEdit] = useState(false);
  const [errorTextSupply, setErrorTextSupply] = useState('');
  const [errorTextDemand, setErrorTextDemand] = useState('');
  const [currentRowIndex, setCurrentRowIndex] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const countries = allCountries?.map(country => {
    return { name: `${country.name}`, zone: `${country.zone}` };
  });

  const addTableRows = () => {
    const rowsInput = {
      seller_zone: '',
      buyer_zone: '',
    };
    setEdit(true);
    dispatch(addNewAllowedCountry(rowsInput))
  };

  const deleteTableRows = (tableRow) => {
    if (!tableRow) {
      dispatch(deleteAllowedCountry(currentRow))
    }
    setEdit(false);
  };

  useEffect(() => {
    if (permission.users) {
      dispatch(getAllowedCountries());
    }
  }, [])

  const getInputValue = (column, name, zone) => {
    setCurrentRow(0);
    const newData = [...dataAllowedCountries];
    newData[currentRow][column] = name;
    dispatch(addEditAllowedCountry(newData))
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const newData = [...dataAllowedCountries];
    if (name === 'seller') newData[index][name] = value;
    if (name === 'buyer') newData[index][name] = value;
    dispatch(addEditAllowedCountry(newData))
  };

  const checkCountryValid = (seller, buyer) => {
    const indexSeller = countries.findIndex(country => country.name === seller);
    const countryZoneSeller = countries[indexSeller]?.zone || '';
    const indexBuyer = countries.findIndex(country => country.name === buyer);
    const countryZoneBuyer = countries[indexBuyer]?.zone || '';
    return { indexSeller, indexBuyer, countryZoneSeller, countryZoneBuyer };
  };

  const saveData = () => {
    !dataAllowedCountries[currentRowIndex].buyer && setErrorTextDemand('Not Defined')
    !dataAllowedCountries[currentRowIndex].seller && setErrorTextSupply('Not Defined')
    const { indexSeller, indexBuyer, countryZoneSeller, countryZoneBuyer } = checkCountryValid(dataAllowedCountries[currentRowIndex]?.seller, dataAllowedCountries[currentRowIndex]?.buyer);
    if ((edit && dataAllowedCountries[currentRowIndex]?.seller === '') || dataAllowedCountries[currentRowIndex]?.buyer === '') return;
    if (edit && (indexSeller === -1 || indexBuyer === -1)) return;
    const dataObj = {
      sellerZone: countryZoneSeller,
      buyerZone: countryZoneBuyer
    }
    dispatch(addAllowedCountry(dataObj))
    setEdit(false);
  };

  const cancelEdit = () => {
    if (edit) {
      dispatch(removeNewAllowedCountry(0))
    }
    setErrorTextDemand('')
    setErrorTextSupply('')
    setEdit(false)
  }


  const settingsColumns = [
    {
      name: 'supplyCountries',
      label: 'Supply Countries',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          setCurrentRowIndex(0);
          return edit && dataIndex.rowIndex === currentRowIndex ? (
            <StyledAutocomplete
              id="country-select"
              name="seller"
              disableClearable
              style={{ width: 300 }}
              options={countries}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={option => option.name}
              renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
              onChange={(event, newValue) => {
                getInputValue('seller', newValue?.name, newValue?.zone);
              }}
              value={data}
              inputValue={data}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth="true"
                  name="seller"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-country',
                  }}
                  onChange={event => handleChange(dataIndex.rowIndex, event)}
                  error={errorTextSupply === '' ? false : true}
                  variant="filled"
                />
              )}
            />
          ) : (
            data
          );
        },
      },
    },
    {
      name: 'demandCountries',
      label: 'Demand Countries',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return edit && dataIndex.rowIndex === currentRowIndex ? (
            <StyledAutocomplete
              id="country-select"
              disableClearable
              name="buyer"
              style={{ width: 300 }}
              options={countries}
              classes={{
                option: classes.option,
              }}
              value={data}
              inputValue={data}
              autoHighlight
              getOptionLabel={option => option.name}
              renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
              onChange={(event, newValue) => {
                getInputValue('buyer', newValue?.name, newValue?.zone);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth="true"
                  name="buyer"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  onChange={event => handleChange(dataIndex.rowIndex, event)}
                  error={errorTextDemand === '' ? false : true}
                  variant="filled"
                />
              )}
            />
          ) : (
            data
          );
        },
      },
    },
    {
      name: 'action',
      label: '',
      options: {
        customHeadRender: first => {
          return (
            <TableCell className={classes.centerTextAlign}>
              <IconButton onClick={!edit && addTableRows}>
                <AddIcon />
              </IconButton>
            </TableCell>
          );
        },
        customBodyRender: (id, value) => {
          return (
            <div>
              <div className={classes.centerTextAlign}>
                {edit && value.rowIndex === currentRowIndex ? (
                  <>
                    <IconButton onClick={saveData}>
                      <Done />
                    </IconButton>
                    <IconButton onClick={cancelEdit}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    onClick={() => {
                      setCurrentRow(id);
                      !edit && setIsModalOpen(true);
                    }}
                    className={classes.centerTextAlign}>
                    <TrashIcon />
                  </IconButton>
                )}
              </div>
            </div>
          );
        },
      },
    },
  ];

  if (!allowedCountries.allowedCountries) return <Loading />
  return (
    <div>
      <TableData
        customTheme={settingsCountriesAndZonesTab}
        width="100%"
        tableName="allowedCountries"
        tablePagination={false}
        data={dataAllowedCountries}
        headerColumn={settingsColumns}
        isLoading={allowedCountries.isLoading}
      />
      <ToastNotification position="top-right" hideBar={true} close={false} theme="colored" width="150px" className="toast-container" />
      <ModalWindow
        open={isModalOpen}
        close={() => setIsModalOpen(false)}
        title="Do you really want to remove it?"
        confirm={deleteTableRows}
        children="Remove"
      />
    </div>
  );
};
export default withRouter(AllowedCountriesTab);
