/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import types from '../actionTypes';

export const fetchCanceled = query => ({
  type: types.LOGISTICS.FETCH_CANCELED,
  query,
});

export const fetchMoreCanceled = () => ({
  type: types.LOGISTICS.FETCH_MORE_CANCELED,
});

export const fetchCanceledSuccess = data => ({
  type: types.LOGISTICS.FETCH_CANCELED_SUCCESS,
  data,
});

export const fetchCanceledFailed = reason => ({
  type: types.LOGISTICS.FETCH_CANCELED_FAILED,
  reason,
});

export const fetchCanceledSortChanged = (column, direction) => ({
  type: types.LOGISTICS.FETCH_CANCELED_SORT_CHANGED,
  column,
  direction,
});

export const fetchCanceledDateRangeChanges = (startDate, endDate) => ({
  type: types.LOGISTICS.FETCH_CANCELED_DATERANGE_CHANGED,
  startDate,
  endDate,
});
