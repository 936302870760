import types from './actionTypes';

export const approveBrand = (id) => {
    return {
        type: types.APPROVE_BRAND,
        payload:
            { id }

    }
};
export const approveBrandSuccess = (data) => {
    return {
        type: types.APPROVE_BRAND_SUCCESS,
        payload:
            { data }

    }
};
export const declineBrand = (id) => {
    return {
        type: types.DECLINE_BRAND,
        payload:
            { id }

    }
};
export const declineBrandSuccess = (id) => {
    return {
        type: types.DECLINE_BRAND_SUCCESS,
        payload:
            { id }

    }
};

export const approveBrandError = (data) => {
    return {
        type: types.APPROVE_BRAND_ERROR,
        payload:
            { data }

    }
};
export const resetApproveBrand = (id) => {
    return {
        type: types.RESET_APPROVE_BRAND,


    }
};


export const fetchBrands = (status) => {
    return {
        type: types.FETCH_BRANDS_FOR_APPROVE,
        payload:
            { status }

    }
};
export const fetchBrandsSuccess = (data) => ({
    type: types.FETCH_BRANDS_FOR_APPROVE_SUCCESS,
    payload: {
        data
    }
});

export const changeStatus = (status) => ({
    type: types.CHANGE_STATUS,
    payload: {
        status
    }
});
export const fetchPendingBrands = (status) => ({
    type: types.FETCH_PENDING_BRANDS_FOR_APPROVE,
    payload: {
        status
    }
});
export const fetchPendingBrandsSuccess = (data) => ({
    type: types.FETCH_PENDING_BRANDS_FOR_APPROVE_SUCCESS,
    payload: {
        data
    }
});
export default dispatch => ({
    approveBrand: (id) => dispatch(approveBrand(id)),
    fetchBrands: query => dispatch(fetchBrands(query)),
    declineBrand: (payload) => dispatch(declineBrand(payload)),
    resetApproveBrand: () => dispatch(resetApproveBrand()),
    changeStatus: (status) => dispatch(changeStatus(status)),
    fetchPendingBrands: (status) => dispatch(fetchPendingBrands(status))
});
