import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IconButton, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { fetchBOUsers, fetchMoreBOUsers } from '../actions/bousersactions';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import Button from '@material-ui/core/Button';
import '../static/styles/bousers.css';
import { Paper, Typography } from '@material-ui/core';
import SearchField from '../components/searchfield';
import { tableData } from '../utils/tableData';
import { Filters } from '../components/filters';
import TableData from '../components/table/index';
import { filtersObj } from '../utils/filtersData';

const useStyles = makeStyles({
  paper: {
    padding: 10,
  },
  title: {
    marginLeft: 12
  },
  btnAddBO: {
    fontFamily: 'Overpass',
    backgroundColor: '#EDFF00', border: '2px solid #000000',
    borderRadius: '8px',
    height: 48,
    minWidth: 120,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#EDFF00',
    }
  },
  filterMenu: {
    maxHeight: 400,
    width: 180,
    borderRadius: 10
  },
  table: { padding: 13 }
});

const BOUsers = ({ onOpenDrawer }) => {
  const permissions = decodePermissions(getPermission());

  const { bousers, isLoading, totalCount } = useSelector(({ boUsersReducer }) => boUsersReducer)
  const [search, setSearch] = useState('')
  const [filteredItems, setFilteredItems] = useState([]);
  const [activeFilters, setActiveFilter] = useState()
  const [statusFilter, setStatusFilter] = useState()
  const [typeFilter, setTypeFilter] = useState()
  const [countryFilter, setCountryFilter] = useState()
  const [filterOn, setFilterOn] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()

  const { bousers: { bousersList } } = tableData
  const { boFilters } = filtersObj

  useEffect(() => {
    if (permissions?.bousers) {
      dispatch(fetchBOUsers())
    }
  }, [])

  const boUsers = search &&
    bousers?.filter(person =>
      person?.fullName?.toLowerCase()?.includes(search?.toLocaleLowerCase()) ||
      person?.email?.toLowerCase()?.includes(search?.toLocaleLowerCase())
    ) || filterOn && filteredItems || bousers

  const doSearch = (query) => {
    setSearch(query)
  }
  const handleChange = (filterName, value, id) => (event) => {
    value.length === 2 && setCountryFilter({ [filterName]: event.target.checked })
    value === 'type' && setTypeFilter({ filterName, id, value })
    value === 'status' && setStatusFilter({ filterName, id, value })
  }

  const applyFilters = () => {
    const { filterName } = statusFilter
    if (filterName === 'Active' || filterName === 'Pending') {
      const newFilter = bousers.filter(item => filterName === 'Active' && item.status === 'active' || filterName === 'Pending' && item.status === 'pending');
      console.log('newFilter', newFilter)
      setFilteredItems([...newFilter])
      setFilterOn(true)
    } else {
      filterName === 'All' && setFilteredItems([...bousers])
    }
  }

  return permissions?.bousers ?
    <div>
      <div className="App-header" >
        <IconButton onClick={onOpenDrawer}>
          <MenuIcon color='primary' />
        </IconButton>
        <span className="header-placeholder" />
      </div>
      <div className={classes.paper}>
        <Typography variant="h5" className={classes.title}>BO USERS</Typography>
        <div className='searchContainer'>
          <div className='iconFilter'>
            <Filters
              country={countryFilter}
              type={typeFilter}
              status={statusFilter}
              activeFilters={activeFilters}
              handleChange={handleChange}
              filterItems={boFilters}
              applyFilters={applyFilters} />
          </div>
          <div className='searchField'>
            <SearchField onRequestSearch={query => doSearch(query)} doneColor={''} />
          </div>
          <div className='buttonAdd'>
            <a href='/bouser'>
              <Button variant="text"
                onClick={() => navigate('/bouser')}
                className={classes.btnAddBO}>ADD BO USER</Button>
            </a>
          </div>
        </div>
        <div className={classes.table}>
          <TableData
            width='100%'
            tableName='bousers'
            isLoading={isLoading}
            data={boUsers}
            headerColumn={bousersList}
            totalResults={totalCount}
            fetchMore={() => fetchMoreBOUsers()}
          />
        </div>
      </div>
    </div>
    : <NoPermission onOpenDrawer={onOpenDrawer} />;
}

BOUsers.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  fetchBOUsers: PropTypes.func.isRequired,
  fetchMoreBOUsers: PropTypes.func.isRequired,
  fetchBOUsersSortChanged: PropTypes.func.isRequired,
  deleteBOUser: PropTypes.func.isRequired,
  boUsersReducer: PropTypes.object.isRequired,
};

export default BOUsers
