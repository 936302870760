import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchEventV2Failed, fetchEventV2Success, updateEventV2Failed, updateEventV2Success } from '../actions/eventV2Actions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import uploadImage, { uploadEventFile, uploadItemFile } from '../utils/networkutil';

const baseUrl = getBaseUrl();

const getUpdatedMediaItems = (mediaItems, videoURL) => {
  let updatedMediaItems = [...mediaItems]
  let noVideo = true

  updatedMediaItems?.forEach((m, i) => {
    if (m.type === 2) {
      updatedMediaItems[i].content = `https://d10y2mtytldztj.cloudfront.net${videoURL}`
      noVideo = false
    }
  })

  if (noVideo) {
    updatedMediaItems = [
      ...updatedMediaItems,
      {
        content: `https://d10y2mtytldztj.cloudfront.net${videoURL}`,
        position: 1,
        type: 2
      }
    ]
  }

  return updatedMediaItems
}

function* fetchEventV2Async(action) {
  const { id } = action?.data;

  const response = yield call(
    request,
    `${baseUrl}/api/v2/event/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.err) {
    yield put(fetchEventV2Failed(response.err.toString()));
  } else {
    console.log('response.data', response.data);
    yield put(fetchEventV2Success(response.data));
  }
}

// For now only use for update
function* updateEventV2Async(action) {
  const {
    id,
    eventVideo,
  } = action.data

  let body = {}

  if (eventVideo) {
    const response = yield call(uploadEventFile, 'event', id, eventVideo);

    if (response.err) {
      yield put(updateEventV2Failed(response.err.toString()));
      return;
    }

    let videoURL = response.data.fileUrl;
    videoURL = videoURL.split('shupperz-main-v2')[1]
    videoURL = `https://d10y2mtytldztj.cloudfront.net${videoURL}`

    body.vid_url = videoURL
  }

  const response = yield call(
    request,
    `${baseUrl}/api/v2/event/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateEventV2Failed(response.err.toString()));
  } else {
    yield put(updateEventV2Success({ id }));
  }
}

function* updateEventV2() {
  yield takeLatest([`${types.UPDATE_EVENT_V2}`], updateEventV2Async);
}

function* fetchEventV2() {
  yield takeLatest([`${types.FETCH_EVENT_V2}`, `${types.UPDATE_EVENT_V2_SUCCESS}`], fetchEventV2Async);
}

export default function* eventv2saga() {
  yield all([
    updateEventV2(),
    fetchEventV2()
  ]);
}
