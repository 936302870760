/*
Kateryna Ivanova
20/03/2018

Reducer for a single store
*/
import types from '../actions/actionTypes';

const initialState = {
  store: null,
  isLoading: false,
  isUpdating: false,
  updateSuccess: false,
  updateError: null,
  isDeleting: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_STORE:
      return Object.assign({}, state, {
        isLoading: true,
        store: null,
        updateSuccess: false,
        updateError: null,
      });
    case types.FETCH_STORE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        store: action.data,
      });
    case types.FETCH_STORE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.UPDATE_STORE:
      return Object.assign({}, state, {
        isUpdating: true,
        updateSuccess: false,
      });
    case types.CREATE_STORE:
      return Object.assign({}, state, {
        isUpdating: true,
        updateSuccess: false,
      });
    case types.UPDATE_STORE_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        updateSuccess: true,
      });
    case types.UPDATE_STORE_FAILED:
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: action.reason,
        updateSuccess: false,
      });

    case types.DELETE_STORE:
      return Object.assign({}, state, {
        isDeleting: true,
        deleteSuccess: false,
      });
    case types.DELETE_STORE_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteSuccess: true,
      });
    case types.DELETE_STORE_FAILED:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteError: action.reason,
        deleteSuccess: false,
      });
    default:
      return state;
  }
}
