import React from 'react';
import ExifOrientationImg from 'react-exif-orientation-img'

class MediaPreview extends React.Component {
    constructor(props) {
        super(props)
        this.state = { elementHeight: 0 }
        this.imageCanvas = null
        this.imageFile = null
    }
    render() {
        const { videoUrl, imageUrl, videoPreviewImg, setPreviewImages, className, expandChatsVideo, shuplist, setPreviewImageOrientation } = this.props
        const { elementHeight } = this.state
        return <div className={className || "item-message-media"}
            style={!shuplist && this.imageCanvas && this.imageCanvas.state.orientation === 6 && elementHeight
                // || !shuplist && this.imageCanvas && this.imageCanvas.state.orientation === 1 && elementHeight
                ? { height: elementHeight, maxHeight: "unset" } : {}}
        >
            {
                videoUrl ?
                    <video src={videoUrl && videoUrl.replace(/^http:\/\//i, 'https://')} autoPlay muted poster={videoPreviewImg} loop className="message-media" onClick={(e) => { e.stopPropagation(); expandChatsVideo(videoUrl, videoPreviewImg) }} />
                    :
                    imageUrl ?
                        <ExifOrientationImg
                            crossOrigin="anonymous"
                            style={!shuplist ? {
                                height: '100%', maxWidth: elementHeight === 0 ? "100%" : elementHeight,
                                objectFit: 'cover'
                            } : {}} src={imageUrl && imageUrl.replace(/^http:\/\//i, 'https://')} alt="" className="message-media"
                            ref={(ref) => this.imageCanvas = ref}
                            id={`image${imageUrl}`}
                            onLoad={() => {
                                this.setState({ elementHeight: document.getElementById(`image${imageUrl}`) && document.getElementById(`image${imageUrl}`).clientWidth, url: imageUrl })
                            }
                            }
                            onClick={(e) => {
                                e.stopPropagation(); setPreviewImages([imageUrl])(); setPreviewImageOrientation(this.imageCanvas.state.orientation)
                            }} />
                        : null
            }
        </div>
    }
}
// const MediaPreview = ({ videoUrl, imageUrl, videoPreviewImg, setPreviewImages, className, expandChatsVideo }) => (
// 	<div className={className || "item-message-media"}>
// 		{
// 			videoUrl ?
// 				<video autoPlay muted poster={videoPreviewImg} loop className="message-media" onClick={(e) => { e.stopPropagation(); expandChatsVideo(videoUrl, videoPreviewImg) }} >
// 					<source src={videoUrl} />
// 				</video> :
// 				imageUrl ? <img src={imageUrl} alt="" className="message-media" onClick={(e) => { e.stopPropagation(); setPreviewImages([imageUrl])() }} /> : null
// 		}
// 	</div>
// );
export default MediaPreview;
