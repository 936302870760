/**
 * Created by Berezin Igor
 * Full Stack Developer
 * on 25/06/2018.
 */

const call = (func, state, action) => typeof func === 'function' && func(state, action);

export const createReducer = (list, initialState) => 
    (state = initialState, action) => 
        action && list[action.type] ? call(list[action.type], state, action) : state;
