/*
Kateryna Ivanova
20/03/2018

Component for a value picker from list with searchfield
*/
import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import './shipingPickup.css';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';


const shippingPickUpModal = ({
  onRequestClose,
  onSelectValue,
  isOpen,
  onSearchValue, 
  onRequestSubmit,
  shippingPickupDate,
  shippingPickupStart,
  changeDate,
  changeTime
}) => (
  <Dialog
    bodyStyle={{ padding: '0 24px' }}
    title={"Please select date and duration for shipping pickup"}
    actions={[<FlatButton
      label="close"
      primary
      onClick={() => onRequestClose()}
    /> , <FlatButton
        label="Done"
        primary
        disabled={!shippingPickupDate || !shippingPickupStart}
        onClick={() => onRequestSubmit()}
    />]}
    modal={false}
    open={isOpen}
    onRequestClose={() => onRequestClose()}
  >
      <div className="value-picker-container">
          <DatePicker
              style={{ marginTop: '10px' }}
              value={shippingPickupDate && shippingPickupDate.toDate()}
              onChange={(_, date) => changeDate(date)}
              floatingLabelText="Created Date"
          />
          <TimePicker
              format="24hr"
              hintText="From"
              value={shippingPickupStart}
              onChange={(_, value)=>changeTime('shippingPickupStart', value) }
          />
      </div>
  </Dialog>
);

shippingPickUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default shippingPickUpModal;
