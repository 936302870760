/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 07/05/2018.
 */
import types from './actionTypes';

export const fetchCoupon = id => ({
  type: types.FETCH_COUPON,
  id,
});

export const fetchCouponSuccess = data => ({
  type: types.FETCH_COUPON_SUCCESS,
  data,
});

export const fetchCouponFailed = reason => ({
  type: types.FETCH_COUPON_FAILED,
  reason,
});

export const updateCoupon = coupon => ({
  type: types.UPDATE_COUPON,
  coupon,
});

export const createCoupon = coupon => ({
  type: types.CREATE_COUPON,
  coupon,
});

export const updateCouponSuccess = () => ({
  type: types.UPDATE_COUPON_SUCCESS,
});

export const updateCouponFailed = reason => ({
  type: types.UPDATE_COUPON_FAILED,
  reason,
});

export const deleteCoupon = ids => ({
  type: types.DELETE_COUPON,
  ids,
});

export const deleteCouponSuccess = () => ({
  type: types.DELETE_COUPON_SUCCESS,
});

export const deleteCouponFailed = reason => ({
  type: types.DELETE_COUPON_FAILED,
  reason,
});
