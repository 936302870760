import React, { useMemo } from 'react';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { updateItemV2 } from '../../actions/itemV2Actions';
import { connect } from 'react-redux';

const ItemMedia = ({ itemId, item, updateItemV2 }) => {
  const videoInfo = useMemo(() => {
    if (item?.media?.mediaItems) {
      for (const m of item?.media.mediaItems) {
        if (m.type == 2) {
          return m
        }
      }
      return null
    }
  }, [item])

  const hiddenFileInput = React.useRef(null);

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleVideoFileChange = (e) => {
    updateItemV2({ id: itemId, item, itemVideo: e.target.files[0] });
  }

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant="h5" className="paymentRequest-subtitle">
          MEDIA
        </Typography>
      </Grid>
      <Paper className='info-wrapper paymentInfo-wrapper'>
        <Grid container justifyContent='center'>
          <video key={videoInfo?.content} controls>
            <source src={videoInfo?.content} type="video/mp4" />
          </video>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="track-shipping-btnWrapper">
            <input
              type="file"
              name="itemVideo"
              accept="video/*"
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              onChange={handleVideoFileChange}
            />
            <Button className={'requestPayment-main-btn'} onClick={handleUpload}>Upload New Video</Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateItemV2: data => dispatch(updateItemV2(data)),
});

export default connect(
  () => {},
  mapDispatchToProps,
)(ItemMedia);