import 'react-phone-input-2/lib/style.css';
import { useField, Field } from 'formik';
import { FieldStyled } from '../styleField';
import { makeStyles, MenuItem } from '@material-ui/core';
import ReactPhoneInput from 'react-phone-input-2';
import React from 'react'
import { userLocalStorage } from '../../../config/local_storage';
import { countries } from '../../../utils/countries';

const useStyles = makeStyles((theme) => ({
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    }
  },
}));
export const TextField = ({ ...props }) => {
  const classes = useStyles()
  const [field, meta] = useField(props);
  return (
    <>
      <Field
        as={FieldStyled}
        fullWidth='true'
        placeholder=""
        onBlur={props?.handleBlur}
        variant='outlined'
        name={field?.name}
        error={props.touched[field?.name] && Boolean(props.errors[field?.name])}
        helperText={props.touched[field?.name] && props.errors[field?.name]}
        InputProps={{
          classes: { input: classes.input }
        }}
      />
    </>
  );
};
export const CountryCodePhone = props => {
  return (
    <ReactPhoneInput
      onChange={props?.setValue}
      value={props?.value}
      enableLongNumbers={false}
      autoFormat={false}
      containerStyle={{ marginTop: '0px', border: '2px solid #1B1B1B', borderRadius: 8 }}
      inputClass={{ marginLeft: '40px', }}
      inputStyle={{ width: '100%', height: '38px' }} />
  );
};


export const SelectField = props => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;
  return (
    <Field
      {...field}
      {...props}
      disabled={props?.disabled}
      value={field?.value}
      as={FieldStyled}
      select
      fullWidth='true'
      variant='outlined'
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: () => null,
          style: { marginTop: '50px' }
        },
      }}>
      {props?.renderItems?.map((item, index) => (
        <MenuItem value={item?.title} key={`${index}-${item}`}>{item?.title}</MenuItem>
      ))}
    </Field>
  );
};


export const Country = props => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;
  return (
    <Field
      {...field}
      {...props}
      disabled='true'
      value={field?.value}
      as={FieldStyled}
      select
      fullWidth='true'
      variant='outlined'
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: () => null,
          style: { marginTop: '50px', height: 300 }
        },
      }}>
      {countries?.map((item, index) => (
        <MenuItem value={item?.iso2_name} key={`${item.id}`}>{item?.iso2_name}</MenuItem>
      ))}
    </Field>
  );
};


