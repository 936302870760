import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { IconButton } from 'material-ui';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from '../components/table/tableStyles';
import PackageDetails from '../components/package/PackageDetails';
import { fetchPackage, updatePackage } from '../actions/packageActions';
import { withRouter } from "../utils/withRouter";
import { connect } from 'react-redux';
import PaymentRequestShippingAddress from '../components/paymentsRequests/paymentRequestShippingAddress';
import TableData from '../components/table';
import { tableData } from '../utils/tableData';

const Package = ({packageReducer, fetchPackage, updatePackage, params, ...props}) => {
  const classes = useStyles();

  const { id } = params
  const packageInfo = packageReducer.package

  const { payments: { paymentsList } } = tableData

  useEffect(() => {
    fetchPackage({ id })
  }, [id])

  return (
    <>
    <div className="App-header">
      <IconButton onClick={props?.onOpenDrawer}>
        <MenuIcon color='primary' classes={{ root: classes.menuIcon }} />
        <div classes={{ root: classes.menuIcon }}></div>
      </IconButton>
    </div>
    <div className="paymentRequest-wrapper">
      <div className="paymentRequest-title-wrapper">
        <div>
          <Typography variant="h4" className="paymentRequest-title">
            PACKAGE
          </Typography>
        </div>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        xs={12}
        style={{ paddingRight: '0px' }}>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
            <PackageDetails packageInfo={ packageInfo } />
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={12} className="paymentsRequestPackage-wrapper">
            <PaymentRequestShippingAddress shupperAddress={packageInfo?.shipping_address} />
          </Grid>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
            <Typography variant="h5" className={classes.title}>PAYMENT REQUESTS</Typography>
            <div className={`${classes.table} usersTable`} >
              <TableData
                width='100%'
                tableName='payments'
                tablePagination={true}
                data={packageInfo?.payment_requests_details || []}
                headerColumn={paymentsList}
                // fetchMore={() => dispatch(fetchMoreOrders())}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = ({ packageReducer }) => ({
  packageReducer
});

const mapDispatchToProps = dispatch => ({
  fetchPackage: (data) => dispatch(fetchPackage(data)),
  updatePackage: (data) => dispatch(updatePackage(data)),
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Package));
