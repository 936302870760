import types from '../actions/actionTypes';

export const setUserId = userId => ({
	type: types.SET_USER_ID,
	payload: {
		userId,
	}
});

export const setDayParameters = (params, day) => ({
	type: types.SET_DAY_PARAMS,
	payload: {
		dayData: {...params},
		day,
	}
});

export const setDaysList = days => ({
	type: types.SET_DAYS,
	payload: {
		days,
	}
});

export const setPopupParameters = (params, popup) => ({
	type: types.SET_POPUP_PARAMS,
	payload: {
		popupData: {...params},
		popup,
	}
});

export const setPopupsList = (popups, ignoreDefaults) => ({
	type: types.SET_POPUPS,
	payload: {
		popups, ignoreDefaults
	}
});

export const saveTimetableChanges = (popups, inaccessibility, userId, newTimetable, setDefaultPopups) => ({
	type: types.SAVE_TIMETABLE_CHANGES,
	payload: {
		popups, inaccessibility, userId, newTimetable, setDefaultPopups
	}
});

export const createNewTimeTable = () => ({
	type: types.CREATE_NEW_TIMETABLE,
});

export const stopLoading = () => ({
	type: types.TIME_TABLE_STOP_LOAD,
});

export const setUpdateFlag = newTimetable => ({
	type: types.SET_UPDATE_FLAG,
	payload: {newTimetable},
});

export const setError = () => ({
	type: types.SET_TIMETABLE_SAVE_ERROR,
});

export const setSuccess = () => ({
	type: types.SET_TIMETABLE_SAVE_SUCCESS,
});

export const getDefaultValues = () => ({
	type: types.GET_DEFAULT_VALUES,
});

const setDefaultTimetable = () => ({
	type: types.SET_DEFAULT_TIMETABLE,
});

export const updateDefaultPopupsFlag = setDefaultPopups => ({
	type: types.UPDATE_DEFAULT_POPUPS_FLAG,
	payload: {
		setDefaultPopups,
	},
});
export const resetPopups = () => ({
	type: types.RESET_POPUPS,
	
});

const getHandlers = dispatch => ({
	setDaysList: days => dispatch(setDaysList(days)),
	setDayParameters: day => params => dispatch(setDayParameters(params, day)),
	setUserId: userId => dispatch(setUserId(userId)),
	setPopupParameters: popup => params => dispatch(setPopupParameters(params, popup)),
	saveTimetableChanges: (popups, inaccessibility, userId, newTimetable, setDefaultPopups) =>
		dispatch(saveTimetableChanges(popups, inaccessibility, userId, newTimetable, setDefaultPopups)),
	updateDefaultPopupsFlag: setDefaultPopups => dispatch(updateDefaultPopupsFlag(setDefaultPopups)),
	getDefaultValues: () => dispatch(getDefaultValues()),
	setDefaultTimetable: () => dispatch(setDefaultTimetable()),
	resetPopups:()=>dispatch(resetPopups())
});

export default getHandlers;
