import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  buttons: {
    marginLeft: 18
  },
  btnCancel: {
    textTransform: 'none'
  },
  btnApplyFilters: {
    textTransform: 'none',
    background: '#EDFF00',
    border: '2px solid #141b41',
    height: 32,
    padding: 15,
    '&:hover': {
      backgroundColor: '#EDFF00',
    }
  },
});

export const Buttons = ({ setFilterMenu, applyFilters }) => {
  const classes = useStyles()
  return (
    <div className={classes.buttons}>
      {/* <Button>Clear</Button> */}
      <Button onClick={() => setFilterMenu(null)} className={classes.btnCancel}>Close</Button>
      <Button onClick={applyFilters} className={classes.btnApplyFilters}>Apply</Button>
    </div>
  )
}