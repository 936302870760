/*
Kateryna Ivanova
29/03/2018

Sagas for working with a list of BO users
*/
import {takeLatest, put, call, all, select} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {fetchBOUsersSuccess, fetchBOUsersFailed} from '../actions/bousersactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectBOUsersState = state => state.boUsersReducer;

function* fetchBOUsersAsync() {
  const boUsersState = yield select(selectBOUsersState);
  const sorting = Object.keys(boUsersState.sort)
    .map(key => `&sort[${key}]=${boUsersState.sort[key]}`)
    .join('');
  const response = yield call(
    request,
    `${baseUrl}/api/bouser?limit=${boUsersState.limit}&skip=${boUsersState.offset}${sorting}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.err) {
    yield put(fetchBOUsersFailed(response.err.toString()));
  } else {
    yield put(fetchBOUsersSuccess({count: response.data.length, bousers: response.data}));
  }
}

function* fetchBOUsers() {
  yield takeLatest(
    [
      `${types.FETCH_BO_USERS}`,
      `${types.FETCH_BO_USERS_SORT_CHANGED}`,
      `${types.FETCH_MORE_BO_USERS}`,
      `${types.DELETE_BO_USER_SUCCESS}`,
      `${types.UPDATE_BO_USER_SUCCESS}`,
    ],
    fetchBOUsersAsync,
  );
}

export default function* itemssaga() {
  yield all([fetchBOUsers()]);
}
