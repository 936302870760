/* 
- This is our common Object with header data for all tables in current project. 
*/
import {CustomCell, PaymentCell} from '../components/customCell';
import {ReactComponent as Edit} from '../static/images/edit.svg';
import {ReactComponent as View} from '../static/images/view.svg';
import {ReactComponent as DeleteIcon} from '../static/images/delete_icon.svg';
import {ReactComponent as CopyIcon} from '../static/images/copy_icon.svg';
import {ReactComponent as AddIcon} from '../static/images/addIcon.svg';
import '../components/table/table.css';
import {IconButton, TableCell, TextField} from '@material-ui/core';
import '../components/table/table.css';
import {Button, makeStyles, Tooltip, Typography, withStyles} from '@material-ui/core';
import {toast} from 'react-toastify';
import {ToastNotification} from './toastify';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const tableData = {
  settings: {
    headerData: [
      {
        name: 'supply',
        label: 'Supply Countries',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (data, dataIndex, rowIndex) => {
            return dataIndex.rowIndex === 0 ? <TextField fullWidth="true" /> : data;
          },
        },
      },
      {
        name: 'demand',
        label: 'Demand Countries',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'action',
        label: '',

        options: {
          customHeadRender: first => {
            console.log('first', first);

            return (
              <TableCell>
                <IconButton
                  onClick={() => {
                    //showDocumentStatusInfo();
                  }}>
                  <AddIcon />
                </IconButton>
              </TableCell>
            );
          },
          customBodyRender: (id, value) => {
            console.log('id111', id[1]);
            console.log('id', id);

            const deletes = id[2]?.filter((i, index) => index !== value.rowIndex);
            return <DeleteIcon onClick={() => id[1](deletes)} />;
          },
        },
      },
    ],
  },
  bousers: {
    headerData: [
      {id: 'id', title: 'ID'},
      {id: 'type', title: 'Type'},
      {id: 'fullName', title: 'Full Name'},
      {id: 'email', title: 'Email'},
      {id: 'phone', title: 'Phone'},
      {id: 'location', title: 'Location'},
      {id: 'status', title: 'Status'},
      {id: 'action', title: 'Action'},
    ],
  },
  checkins: {
    columnWidths: [150, 150, 150, 150, 150, 150],
    headerData: [
      {id: 'Date', title: 'Date'},
      {id: 'Date', title: 'User'},
      {id: 'Date', title: 'Brand'},
      {id: 'Date', title: 'Status'},
      {id: 'User', title: 'Description'},
      {id: 'Brand', title: 'Image'},
    ],
  },
  collections: {
    columnWidths: [150, 200, 150, 150, 150, 200, 150, 150],
    headerData: [
      {id: 'description', title: 'Description'},
      {id: 'longDescription', title: 'Long Description'},
      {id: 'created', title: 'Created Date'},
      {id: 'updated', title: 'Updated Date'},
      {id: 'owner', title: 'Owner'},
      {id: 'items', title: 'Items'},
      {id: 'status', title: 'Status'},
      {id: 'pinnedLocation', title: 'Pinned Location'},
    ],
  },
  couponList: {
    columnWidths: [200, 200, 200],
    headerData: [
      {id: 'code', title: 'Coupon Code'},
      {id: 'discountValue', title: 'Discount Value'},
      {id: 'isEnabled', title: 'Enabled'},
    ],
  },
  events: {
    columnWidths: [150, 150, 150, 100, 350, 200, 150, 150, 100, 100],
    headerData: [
      {id: 'title', title: 'Event Name'},
      {id: 'dateStart', title: 'Start Time'},
      {id: 'dateEnd', title: 'End Time'},
      {id: 'imageUrl', title: 'Cover Photo'},
      {id: 'shuppers', title: 'Featured Shuppers'},
      {id: 'stores', title: 'Featured Stores'},
      {id: 'location', title: 'Pinned Location'},
      {id: 'type', title: 'Type'},
      {id: 'reshuffle', title: 'Reshuffle Items'},
      {id: 'viewReviews', title: 'Manage Reviews'},
    ],
  },
  itemList: {
    columnWidths: [100, 200, 150, 150, 100, 100, 150, 150],
    headerData: [
      {id: 'imageUrl', title: 'Cover Photo'},
      {id: 'description', title: 'Item Name'},
      {id: 'created', title: 'Created Date'},
      {id: 'updated', title: 'Updated Date'},
      {id: 'video', title: 'Video'},
      {id: 'price', title: 'Price'},
      {id: 'owner', title: 'Owner'},
      {id: 'event', title: 'Event'},
    ],
  },
  itemListV2: {
    columnWidths: [100, 200, 150, 150, 100, 100, 150, 150],
    headerData: [
      {id: 'itemId', title: 'Item Id'},
      {id: 'brand', title: 'Brand'},
      {id: 'created', title: 'Created Date'},
      {id: 'state', title: 'State'},
      {id: 'countryId', title: 'Country'},
      {id: 'likes', title: 'Likes'},
      {id: 'shupper', title: 'Shupper'},
    ],
  },
  securityLogs: {
    columnWidths: [250, 150, 50, 150, 150, 150, 150, 200],
    headerData: [
      {id: 'url', title: 'URL'},
      {id: 'userName', title: 'User'},
      {id: 'method', title: 'Method'},
      {id: 'created', title: 'Created'},
      {id: 'ip', title: 'IP'},
      {id: 'description', title: 'Description'},
      {id: 'response', title: 'Response'},
      {id: 'requestBody', title: 'Request Body'},
    ],
  },
  shupperoftheDayList: {
    columnWidths: [150, 150, 150, 150, 150, 150, 150],
    headerData: [
      {id: 'sunday', title: 'Sunday'},
      {id: 'monday', title: 'Monday'},
      {id: 'tuesday', title: 'Tuesday'},
      {id: 'wednesday', title: 'Wednesday'},
      {id: 'thursday', title: 'Thursday'},
      {id: 'friday', title: 'Friday'},
      {id: 'saturday', title: 'Saturday'},
    ],
  },
  storeList: {
    columnWidths: [100, 100, 150, 200, 150, 150, 200],
    headerData: [
      {id: 'coverImageURL', title: 'Cover Photo'},
      {id: 'profileImageURL', title: 'Logo'},
      {id: 'displayName', title: 'Store Name'},
      {id: 'shupperDescription', title: 'Description'},
      {id: 'created', title: 'Created Date'},
      {id: 'created', title: 'Last Updated Date'},
      {id: 'position', title: 'Coordinates'},
    ],
  },

  logisticsCanceled: {
    columnWidths: [150, 150, 250, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 150],
    headerData: [
      {id: 'created', title: 'Date + Time'},
      {id: 'user', title: 'User'},
      {id: 'userEmail', title: 'User Email'},
      {id: 'shupperName', title: 'Shupper Name'},
      {id: 'receiptsNumber', title: 'Number of Receipts'},
      {id: 'imageUri', title: 'Image uri(s)'},
      {id: 'prices', title: 'Prices'},
      {id: 'goodsValueNetTransaction', title: 'Goods Value (Net Transaction)'},
      {id: 'shippingCost', title: 'Shipping Cost'},
      {id: 'taxes', title: 'Taxes'},
      {id: 'shupperFee', title: 'Our Fee'},
      {id: 'ourFee', title: 'Shupper Fee'},
      {id: 'trackManualShupperFee', title: 'Track Manual Changes (Shupper fee)'},
      {id: 'changeStatus', title: 'Change Status'},
    ],
  },
  logisticsCompleted: {
    columnWidths: [
      150, 150, 250, 150, 100, 100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 200, 100, 200, 100, 200, 200,
      200, 200, 150, 150, 150, 150, 150, 220, 150,
    ],
    headerData: [
      {id: 'created', title: 'Date + Time'},
      {id: 'user', title: 'User'},
      {id: 'userEmail', title: 'User Email'},
      {id: 'shupperzName', title: 'Shupper Name'},
      {id: 'receiptsNumber', title: 'Number of Receipts'},
      {id: 'imageUri', title: 'Image uri(s)'},
      {id: 'promoCodeType', title: 'Promo Code Type'},
      {id: 'promoCodeDiscountValue', title: 'Promo Code Discount Value'},
      {id: 'prices', title: 'Prices'},
      {id: 'goodsValueNetTransaction', title: 'Goods Value (Net Transaction)'},
      {id: 'shippingCost', title: 'Shipping Cost'},
      {id: 'taxes', title: 'Taxes'},
      {id: 'ourFee', title: 'Our Fee'},
      {id: 'shupperFee', title: 'Shupper Fee'},
      {id: 'total', title: 'Total (Include All)'},
      {id: 'tip', title: 'Tip'},
      {id: 'totalTip', title: 'Total With Tip (Total + tip)'},
      {id: 'systemUniqueId', title: 'System  Unique ID'},
      {id: 'trackManualShupperFee', title: 'Track Manual Changes (Shupper fee)'},
      {id: 'payPalInvoiceNumber', title: 'PayPal Invoice Number'},
      {id: 'payPalTransactionId', title: 'PayPal Transaction ID'},
      {id: 'trackingCode', title: 'Tracking Code'},
      {id: 'street', title: 'Street'},
      {id: 'city', title: 'City'},
      {id: 'country', title: 'Country'},
      {id: 'zipCode', title: 'Zip Code'},
      {id: 'phoneNumber', title: 'Phone Number'},
      {id: 'state', title: 'State'},
      {id: 'id', title: 'ID'},
      {id: 'brands', title: 'Brands'},
      {id: 'prepareShipping', title: 'Prepare Shipping'},
      {id: 'changeStatus', title: 'Change Status'},
    ],
  },
  logisticsPending: {
    columnWidths: [150, 150, 250, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 200, 150],
    headerData: [
      {id: 'created', title: 'Date + Time'},
      {id: 'user', title: 'User'},
      {id: 'userEmail', title: 'User Email'},
      {id: 'shupperzName', title: 'Shupper Name'},
      {id: 'receiptsNumber', title: 'Number of Receipts'},
      {id: 'imageUri', title: 'Image uri(s)'},
      {id: 'prices', title: 'Prices'},
      {id: 'goodsValueNetTransaction', title: 'Goods Value (Net Transaction)'},
      {id: 'shippingCost', title: 'Shipping Cost'},
      {id: 'taxes', title: 'Taxes'},
      {id: 'shupperFee', title: 'Shupper Fee'},
      {id: 'ourFee', title: 'Our Fee'},
      {id: 'trackManualShupperFee', title: 'Track Manual Changes (Shupper fee)'},
      {id: 'systemUniqueId', title: 'System  Unique ID'},
      {id: 'changeStatus', title: 'Change Status'},
    ],
  },
  logisticsRefunded: {
    columnWidths: [150, 150, 250, 150, 150, 150, 150, 200, 150, 200, 150, 150],
    headerData: [
      {id: 'created', title: 'Date + Time'},
      {id: 'user', title: 'User'},
      {id: 'userEmail', title: 'User Email'},
      {id: 'shupperName', title: 'Shupper Name'},
      {id: 'total', title: 'Total (Include All)'},
      {id: 'tip', title: 'Tip'},
      {id: 'totalTip', title: 'Total With Tip (Total + tip)'},
      {id: 'payPalInvoiceNumber', title: 'PayPal Invoice Number'},
      {id: 'payPalTransactionId', title: 'PayPal Transaction ID'},
      {id: 'systemUniqueId', title: 'System Unique ID'},
      {id: 'status', title: 'Status'},
      {id: 'changeStatus', title: 'Change Status'},
    ],
  },
  logisticsRejected: {
    columnWidths: [150, 150, 250, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 150],
    headerData: [
      {id: 'created', title: 'Date + Time'},
      {id: 'user', title: 'User'},
      {id: 'userEmail', title: 'User Email'},
      {id: 'shupperzName', title: 'Shupper Name'},
      {id: 'receiptsNumber', title: 'Number of Receipts'},
      {id: 'imageUri', title: 'Image uri(s)'},
      {id: 'prices', title: 'Prices'},
      {id: 'goodsValueNetTransaction', title: 'Goods Value (Net Transaction)'},
      {id: 'shippingCost', title: 'Shipping Cost'},
      {id: 'taxes', title: 'Taxes'},
      {id: 'shupperFee', title: 'Shupper Fee'},
      {id: 'ourFee', title: 'Our Fee'},
      {id: 'trackManualShupperFee', title: 'Track Manual Changes (Shupper fee)'},
      {id: 'systemUniqueId', title: 'System  Unique ID'},
      {id: 'changeStatus', title: 'Change Status'},
    ],
  },
  logisticsShipping: {
    columnWidths: [150, 100, 100, 100, 100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 150, 150],
    headerData: [
      {id: 'created', title: 'Barcode DateTime'},
      {id: 'pic', title: 'Pic'},
      {id: 'goodsValue', title: 'Goods Value'},
      {id: 'paymentId', title: 'Payment ID'},
      {id: 'customerName', title: 'Customer Name'},
      {id: 'shupperName', title: 'Shupper Name', select: true},
      {id: 'currency', title: 'Currency'},
      {id: 'intercoms', title: 'Intercoms'},
      {id: 'goodsDescription', title: 'Goods Description'},
      {id: 'customerId', title: 'Customer ID Number'},
      {id: 'addressStreetHouse', title: 'Address: Street + House Number'},
      {id: 'addressCity', title: 'Address: City'},
      {id: 'lastMileTrackingNumber', title: 'Last Mile Tracking Number'},
      {id: 'hsCode', title: 'HS Code'},
      {id: 'phone', title: 'Phone Number'},
      {id: 'changeStatus', title: 'Change Status'},
    ],
  },
  shippingPickup: {
    columnWidths: [300, 150, 150, 100],
    headerData: [
      {id: 'Pickup time', title: 'Pickup time'},
      {id: 'shupper', title: 'Shupper'},
      {id: 'shupper address', title: 'Shupper address'},
      {id: 'status', title: 'Status'},
    ],
  },
  permissions: {
    permissionsLabel: [
      {label: 'Can access users', type: 'users'},
      {label: 'Can access events', type: 'events'},
      {label: 'Can access stores', type: 'stores'},
      {label: 'Can access payments', type: 'payments'},
      {label: 'Can manage BO users', type: 'bousers'},
      {label: 'Can access items/collections', type: 'items'},
      {label: 'Can access coupons', type: 'coupons'},
      {label: 'Can access chats', type: 'chats'},
      {label: 'Can access set shupper/supershupper', type: 'set_shupper_supershupper'},
      {label: 'Can see payments is status PENDING', type: 'seePending'},
      {label: 'Can see payments in status REJECTED', type: 'seeRejected'},
      {label: 'Can see payments in status CANCELED', type: 'seeCanceled'},
      {label: 'Can see payments in status COMPLETED', type: 'seeFulfilled'},
      {label: 'Can see payments in status REFUNDED', type: 'seeRefunded'},
      {label: 'Can see payments in status SHIPPING', type: 'seeShipping'},
      {label: 'Can Refund a Payment', type: 'refundPayment'},
      {label: 'Can Cancel a Payment', type: 'cancelPayment'},
      {label: 'Can Move Payment to Shipping', type: 'movePaymentToShipping'},
      {label: 'Can Set Shupper of the Day', type: 'setShupperOfTheDay'},
      {label: 'Can grant permissions for other user', type: 'grantPermissionsForOtherUsers'},
      {label: 'Can change payment status', type: 'changePaymentStatus'},
      {label: 'Can setup duty off timetables', type: 'canConfigureTimeTable'},
      {label: 'Can see logs', type: 'securitylogs'},
      {label: 'Can approve/decline brands', type: 'canApproveBrands'},
      {label: 'Can approve/decline shuppers', type: 'canApproveShuppers'},
      {label: 'Can approve/decline brand reviews', type: 'canManageReviews'},
      {label: 'Can manage checkins', type: 'canManageCheckins'},
      {label: 'Can forward chats', type: 'canForwardChats'},
    ],
  },
  shipping: {
    headerData: [
      {
        name: 'city',
        width: 30,
        label: 'City',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <>
                {id}
                <CopyIcon onClick={() => value.tableData[0][5](id)} className="copyIcon" />
              </>
            );
          },
        },
      },
      {
        name: 'address',
        width: 30,
        label: 'Address',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <>
                {id}
                <CopyIcon onClick={() => value.tableData[0][5](id)} className="copyIcon" />
              </>
            );
          },
        },
      },
      {
        name: 'state',
        width: 30,
        label: 'State',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <>
                {id}
                <CopyIcon onClick={() => value.tableData[0][5](id)} className="copyIcon" />
              </>
            );
          },
        },
      },
      {
        name: 'zip code',
        width: 30,
        label: 'Zip Code',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <>
                {id}
                <CopyIcon onClick={() => value.tableData[0][5](id)} className="copyIcon" />
              </>
            );
          },
        },
      },
      {
        name: 'tax id',
        width: 30,
        label: 'TAX ID',
        options: {
          filter: false,
          sort: false,
        },
      },
    ],
  },
  recentSession: {
    recentData: [
      {
        name: 'device',
        width: 30,
        label: 'Device',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'version',
        width: 30,
        label: 'Version',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'ip',
        width: 30,
        label: 'IP address',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'country',
        width: 50,
        label: 'Country',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'most recent activity',
        //width: 250,
        label: 'Recent activity',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'session status',
        width: 50,
        label: 'Session status',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            console.log('Session status', id);
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'action',
        width: 50,
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <DeleteIcon />;
          },
        },
      },
    ],
  },
  recentOrders: {
    headerData: [
      {
        name: 'orderId',
        width: 150,
        label: 'Order ID',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'createdAt',
        width: 150,
        label: 'Created At',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'shuppers',
        width: 150,
        label: 'Shuppers',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'user',
        width: 150,
        label: 'User',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'itemCost',
        width: 150,
        label: 'Item Cost',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'paymentStatus',
        width: 150,
        label: 'Payment status',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <PaymentCell id={id} />;
          },
        },
      },
      {
        name: 'packageId',
        width: 150,
        label: 'Package ID',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'action',
        width: 150,
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <View />;
          },
        },
      },
    ],
  },
  recentPackages: {
    headerData: [
      {
        name: 'packageId',
        width: 50,
        label: 'Package ID',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'fee',
        width: 50,
        label: 'Fee',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'tipAmount',
        width: 50,
        label: 'Tip Amount',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'chargeAmount',
        width: 50,
        label: 'Charge Amount',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'action',
        width: 50,
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <View />;
          },
        },
      },
    ],
  },
  recentPaymentAttempts: {
    headerData: [
      {
        name: 'time',
        width: 50,
        label: 'Time',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'totalAmount',
        width: 50,
        label: 'Total Amount',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'paymentMethod',
        width: 50,
        label: 'Payment Method',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'providerStatus',
        width: 50,
        label: 'Provider Status',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <PaymentCell id={id} />;
          },
        },
      },
      {
        name: 'failureReson',
        width: 50,
        label: 'Failure Reason',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <PaymentCell id={id} />;
          },
        },
      },
      {
        name: 'action',
        width: 50,
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <View />;
          },
        },
      },
    ],
  },
  users: {
    usersList: [
      {
        name: 'id',
        width: 50,
        label: 'Id',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'type',
        label: 'Type',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'firstName',
        label: 'First Name',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'lastName',
        label: 'Last Name',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          filter: false,
          sort: false,

          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'location',
        label: 'Location',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'create',
        label: 'Join date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}}>
                <div onClick={id[1]}>
                  <View />
                </div>
                <div style={{paddingLeft: 3}} onClick={id[2]}>
                  <Edit />
                </div>
              </div>
            );
          },
        },
      },
    ],
  },
  bousers: {
    bousersList: [
      {
        name: 'id',
        width: 50,
        label: 'Id',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'type',
        label: 'Type',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'fullName',
        label: 'Full Name',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'phone',
        label: 'Phone',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'location',
        label: 'Location',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'action',
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}}>
                <div>
                  <View />
                </div>
                <div onClick={id} style={{paddingLeft: 3}}>
                  <Edit />
                </div>
              </div>
            );
          },
        },
      },
    ],
  },
  packages: {
    packagesList: [
      {
        name: 'package_id',
        width: 50,
        label: 'Package Id',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'created at',
        width: 50,
        label: 'Created At',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'updated at',
        width: 50,
        label: 'Updated At',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'from user',
        width: 50,
        label: 'From user',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'to user',
        width: 50,
        label: 'To user',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'state',
        width: 50,
        label: 'State',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'substate',
        width: 50,
        label: 'Substate',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'actions',
        width: 50,
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}}>
                <div onClick={id} style={{paddingLeft: 3}}>
                  <View />
                </div>
              </div>
            );
          },
        },
      },
    ]
  },
  payments: {
    paymentsList: [
      {
        name: 'payment id',
        width: 50,
        label: 'Payment Id',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'created at',
        width: 50,
        label: 'Created At',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'updated at',
        width: 50,
        label: 'Updated At',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'payment status',
        width: 50,
        label: 'Payments status',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <PaymentCell id={id} />;
          },
        },
      },
      {
        name: 'shupperz',
        width: 50,
        label: 'Shupper',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'shupper country',
        width: 50,
        label: 'Shupper country',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'item amount',
        width: 50,
        label: 'Item Amount',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'sz fee',
        width: 50,
        label: 'Shuppers Fee',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'local tax',
        width: 50,
        label: 'Local Tax',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'user',
        width: 50,
        label: 'User',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'user country',
        width: 50,
        label: 'User country',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'package id',
        width: 50,
        label: 'Package Id',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'package status',
        width: 50,
        label: 'Package status',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <PaymentCell id={id} />;
          },
        },
      },
      {
        name: 'actions',
        width: 50,
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}}>
                <div onClick={id} style={{paddingLeft: 3}}>
                  <View />
                </div>
              </div>
            );
          },
        },
      },
    ],
  },
  itemsV2: {
    itemsV2List: [
      {
        name: 'item id',
        width: 50,
        label: 'Item Id',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'brand',
        width: 50,
        label: 'Brand',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'created at',
        width: 50,
        label: 'Created At',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'state',
        width: 50,
        label: 'State',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <PaymentCell id={id} />;
          },
        },
      },
      {
        name: 'country',
        width: 50,
        label: 'Country',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'likes',
        width: 50,
        label: 'Likes',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'shupper',
        width: 50,
        label: 'Shupper Name',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'actions',
        width: 50,
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}}>
                <div onClick={id} style={{paddingLeft: 3}}>
                  <View />
                </div>
              </div>
            );
          },
        },
      },
    ],
  },
  eventsV2: {
    eventsV2List: [
      {
        name: 'event id',
        width: 50,
        label: 'Event Id',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return <CustomCell id={id} />;
          },
        },
      },
      {
        name: 'shupper',
        width: 50,
        label: 'Shupper',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'shupper country',
        width: 50,
        label: 'Shupper country',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'created at',
        width: 50,
        label: 'Created At',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'state',
        width: 50,
        label: 'State',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'start date',
        width: 50,
        label: 'Start Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'end date',
        width: 50,
        label: 'End Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'brand',
        width: 50,
        label: 'Brand',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'actions',
        width: 50,
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}}>
                <div onClick={id} style={{paddingLeft: 3}}>
                  <View />
                </div>
              </div>
            );
          },
        },
      },
    ],
  },
  profileRecentPayments: {
    headerData: [
      {
        name: 'time',
        width: 50,
        label: 'Time',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'total amount',
        width: 50,
        label: 'Total Amount',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'payment methods',
        width: 50,
        label: 'Payment Methods',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'provider status',
        width: 50,
        label: 'Provider Status',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'failure reason',
        width: 50,
        label: 'Failure Reason',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'actions',
        width: 50,
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}}>
                <div style={{paddingLeft: 3}}>
                  <View />
                </div>
              </div>
            );
          },
        },
      },
    ],
  },
  recentPaymentsAttempts: {
    headerData: [
      {
        name: 'create date',
        width: 150,
        label: 'Create Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'Amount user',
        width: 150,
        label: 'Amount user',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'item cost',
        width: 150,
        label: 'Item Cost',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'local tax',
        width: 150,
        label: 'Local Tax',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'Shupperz Fee',
        width: 150,
        label: 'Shupperz Fee',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'tip',
        width: 150,
        label: 'Tip',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'import tax',
        width: 150,
        label: 'Import Tax',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'shipping',
        width: 150,
        label: 'Shipping',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'total',
        width: 150,
        label: 'Total',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'provider',
        width: 150,
        label: 'Provider',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'state',
        width: 150,
        label: 'State',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'uid',
        width: 150,
        label: 'UID',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'attempt body',
        width: 150,
        label: 'Attempt body',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'action',
        width: 150,
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            return (
              <div style={{display: 'flex'}} onClick={id}>
                <View />
              </div>
            );
          },
        },
      },
    ],
  },
  paymentRequestResponses: {
    headerData: [
      {
        name: 'create date',
        width: 150,
        label: 'Create Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'update date',
        width: 150,
        label: 'Update Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'state',
        width: 150,
        label: 'State',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'stateChangedTo',
        width: 150,
        label: 'State changed to',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'externalUID',
        width: 150,
        label: 'External UID',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'attemptBody',
        width: 150,
        label: 'Attempt body',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, value) => {
            const handleCopyToClipboard = copyValue => {
              navigator.clipboard.writeText(copyValue);
              toast.info('Copied');
            };
            return (
              <div style={{display: 'flex'}}>
                <LightTooltip placement="left" placeholder="left" interactive={true} title={id}>
                  <a>view</a>
                </LightTooltip>
                <CopyIcon style={{marginLeft: 3, cursor: 'pointer'}} onClick={() => handleCopyToClipboard(id)} />
                <ToastNotification
                  width={170}
                  color="white"
                  background="#007AFF"
                  hideBar={true}
                  position="top-right"
                  close={false}
                />
              </div>
            );
          },
        },
      },
    ],
  },
};
