import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import MenuItem from 'material-ui/MenuItem';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import Dialog from 'material-ui/Dialog';
import EditorModeEdit from 'material-ui/svg-icons/editor/mode-edit';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import SearchField from '../components/searchfield';
import PaginatedGrid from '../components/paginatedgrid';
import * as collectionsActions from '../actions/collectionsactions';
import * as collectionActions from '../actions/collectionactions';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import '../static/styles/collectionslist.css';
import '../commoncss/styles.css';
import { tableData } from '../utils/tableData';




const CollectionsList = ({ onOpenDrawer, collectionsReducer, fetchCollections, fetchMoreCollections, fetchCollectionsSortChanged, deleteCollections, updateCollection }) => {
    const navigate = useNavigate()
    const permission = decodePermissions(getPermission());
    const { collections: { columnWidths, headerData } } = tableData

    const [state, setState] = React.useState({

        selectedCollections: [],
        pinnedLocDialogOpen: false,
        pinnedLocDialogCollection: null,
        pinnedLoc: null,
    });
    const {
        selectedCollections, pinnedLocDialogCollection, pinnedLocDialogOpen, pinnedLoc,
    } = state;

    React.useEffect(() => {
        permission.items && fetchCollections()
    }, [])


    const onSetPinnedLoc = () => {

        updateCollection({ _id: pinnedLocDialogCollection._id, pinnedLocation: pinnedLoc });
        setState({ pinnedLocDialogOpen: false });
    }

    const processAction = (selectedAction) => {
        const { selectedCollections } = state;


        switch (selectedAction) {
            case 'delete':
                if (selectedCollections && selectedCollections.length > 0) {
                    const ids = selectedCollections.map(i => i._id);
                    deleteCollections(ids);
                }
                break;
            case 'edit':
                if (selectedCollections && selectedCollections.length > 0) {
                    const collection = selectedCollections[0];
                    navigate(`/collection?id=${collection._id}`);
                }
                break;
            default:
                break;
        }
    }

    const doSearch = (query) => {
        fetchCollections(query);
    }

    const handleSelection = (selectedItems) => {
        const result = [];
        selectedItems.forEach((selectedItem) => {
            result.push(collectionsReducer?.collections[selectedItem]);
        });
        setState({ selectedCollections: result });
    };


    const tableHeight = window.innerHeight - 217;
    const deleteEnabled = selectedCollections && selectedCollections.length > 0;
    const editEnabled = selectedCollections && selectedCollections.length === 1;

    return permission.items ?
        <div>
            <div className="App-header">
                <IconButton onClick={onOpenDrawer}>
                    <NavigationMenu color="white" />
                </IconButton>
                <span>Collection name</span>
                <div className="header-search-area">
                    <SearchField
                        onRequestSearch={query => doSearch({
                            ...collectionsReducer.searchQuery,
                            name: query,
                        })}
                    />
                </div>
                <span>Owner name</span>
                <div className="header-search-area">
                    <SearchField
                        onRequestSearch={query => doSearch({
                            ...collectionsReducer.searchQuery,
                            owner: query,
                        })}
                    />
                </div>
                <span className="header-placeholder" />
                <IconMenu
                    iconButtonElement={<IconButton><MoreVertIcon color="#fff" /></IconButton>}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem
                        primaryText="Edit"
                        disabled={!editEnabled}
                        onClick={() => processAction('edit')}
                    />
                    <MenuItem
                        primaryText="Delete"
                        disabled={!deleteEnabled}
                        onClick={() => processAction('delete')}
                    />
                </IconMenu>
            </div>

            <div className="items-collections-tabs">
                <Link to="/items" className="items-collections-tab">Items</Link>
                <Link to="/collections" className="items-collections-tab items-collections-tab-active">Collections</Link>
            </div>

            <PaginatedGrid
                data={collectionsReducer.collections}
                tableHeight={tableHeight}
                columnWidths={columnWidths}
                hasMore={collectionsReducer.hasMore}
                isLoading={collectionsReducer.isLoading}
                header={headerData}
                totalResults={collectionsReducer.totalCount}
                onSelectRow={handleSelection}
                selection={selectedCollections}
                sortableCols={headerData.map(item => item.id)}
                currentSort={collectionsReducer.sort}
                onSort={(column, direction) => fetchCollectionsSortChanged(column, direction)}
                items={collectionsReducer.collections.map(collection => [
                    <span><a href={`/collection?id=${collection._id}`}>{collection.description}</a></span>,
                    <span>{collection.longDescription}</span>,
                    <span>{moment(collection.created).format('lll')}</span>,
                    <span>{moment(collection.updated).format('lll')}</span>,
                    <span>{collection.owner ? collection.owner.displayName : ''}</span>,
                    <span>{collection.items.map(item => item.description).join(',')}</span>,
                    <span>{collection.status}</span>,
                    <span className="pinned-loc-in-table">
                        <IconButton
                            iconStyle={{ width: '20px', height: '20px' }}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                setState({
                                    pinnedLocDialogCollection: collection,
                                    pinnedLocDialogOpen: true,
                                    pinnedLoc: collection.pinnedLocation,
                                });
                            }}
                        >
                            <EditorModeEdit color="#cdcdcd" />
                        </IconButton>
                        <span>{collection.pinnedLocation}</span>
                    </span>,
                ])}
                onLoadMore={() => fetchMoreCollections()}
            />

            <Dialog
                contentStyle={{ width: '300px' }}
                title={`Set pinned location for ${pinnedLocDialogCollection && pinnedLocDialogCollection.displayName}`}
                actions={[<FlatButton label="Set" primary onClick={() => onSetPinnedLoc()} />]}
                modal={false}
                open={pinnedLocDialogOpen}
                onRequestClose={() => setState({ pinnedLocDialogOpen: false })}
            >
                <TextField
                    type="number"
                    floatingLabelText="Pinned Location"
                    onChange={(_, text) => setState({ pinnedLoc: text })}
                    value={pinnedLoc}
                />
            </Dialog>
        </div>
        : <NoPermission onOpenDrawer={onOpenDrawer} />;

}

CollectionsList.propTypes = {
    onOpenDrawer: PropTypes.func.isRequired,
    fetchCollections: PropTypes.func.isRequired,
    fetchMoreCollections: PropTypes.func.isRequired,
    fetchCollectionsSortChanged: PropTypes.func.isRequired,
    deleteCollections: PropTypes.func.isRequired,
    updateCollection: PropTypes.func.isRequired,
    collectionsReducer: PropTypes.object.isRequired,
};

const mapStateToProps = ({ collectionsReducer }) => ({
    collectionsReducer,
});

const mapDispatchToProps = dispatch => ({
    fetchCollections: query => dispatch(collectionsActions.fetchCollections(query)),
    fetchMoreCollections: () => dispatch(collectionsActions.fetchMoreCollections()),
    fetchCollectionsSortChanged: (column, direction) =>
        dispatch(collectionsActions.fetchCollectionsSortChanged(column, direction)),
    deleteCollections: ids => dispatch(collectionsActions.deleteCollections(ids)),
    updateCollection: data => dispatch(collectionActions.updateCollection(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CollectionsList);
