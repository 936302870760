import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import req from '../utils/networkRequest';
import uploadImage from '../utils/networkutil';
import { uploadItemFile } from '../utils/networkutil';

import {
    getCheckinsSuccess, getCheckinByIdSuccess, getShuppersSuccess, updateVideoCheckinSuccess,
    updateImageCheckinSuccess, saveCheckinByIdSuccess, getStoresSuccess, saveNewCheckinSuccess,
    deleteCheckinsSuccess

} from '../actions/checkinsActions';
import { config } from '../utils/envSetup';
const API_URL = config('baseUrl');


function* getCheckins({ payload }) {
    const { skip, limit, shupper, status } = payload;
    const response = yield call(
        req,
        `${API_URL}/api/checkin?skip=${skip}&limit=${limit}${shupper ? `&shupper=${shupper}` : ''}${status ? `&status=${status}` : ''}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    yield put(getCheckinsSuccess(response.data ? { checkins: response.data.checkins.filter(item => !item.deleted), totalCheckins: response.data.totalCheckins } : {}));
}


function* saveCheckin({ payload }) {
    const { id, data } = payload.data;
    let url = `${API_URL}${id ? `/api/checkin/${id}` : `/api/checkin`}`
    const response = yield call(
        req,
        url, {
            method: id ? 'PUT' : "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        },
    );
    if (id) {
        yield put(saveCheckinByIdSuccess(response.data ? response.data : {}));

    } else {
        yield put(saveNewCheckinSuccess(response.data ? response.data : {}));

    }
}
function* deleteCheckins({ payload }) {
    const { ids } = payload;
    let url = `${API_URL}/api/checkin`
    let data = { id: ids }
    const response = yield call(
        req,
        url, {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),

        },
    );
    yield put(deleteCheckinsSuccess(response.data ? response.data : {}));


}

function* getCheckinsById({ payload }) {
    const { id } = payload;

    const response = yield call(
        req,
        `${API_URL}/api/checkin/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    yield put(getCheckinByIdSuccess(response.data ? response.data : {}));
}

function* getShuppers() {
    const response = yield call(
        req,
        `${API_URL}/api/user/shuppers`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    yield put(getShuppersSuccess(response.data[0] ? response.data.filter(user => {
        if (user.status === 'active') return true
        else return false
    }) : {}));
}
function* getStores() {
    const response = yield call(
        req,
        `${API_URL}/api/user?limit=100&skip=0&role[]=store&status=active`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },

    );
    yield put(getStoresSuccess(response.data ? response.data.users : {}));
}
function* updateImage(action) {
    const { newImage, _id } = action.payload.data;
    let imageURL = null;

    if (newImage) {
        const response = yield call(uploadImage, 'event', _id, newImage);
        imageURL = response.data.imgUrl;
    }
    if (imageURL) yield put(updateImageCheckinSuccess(imageURL));

}
function* updateVideo(action) {
    const { _id, itemVideo } = action.payload.data;
    let videoURL = null;


    if (itemVideo) {
        const response = yield call(uploadItemFile, 'item', _id, itemVideo);

        videoURL = response.data.fileUrl;
    }
    if (videoURL) yield put(updateVideoCheckinSuccess(videoURL));

}
function* watchGetCheckins() {
    yield takeLatest(types.GET_CHECKINS, getCheckins);
}
function* watchGetCheckinByID() {
    yield takeLatest(types.GET_CHECKIN_BY_ID, getCheckinsById);
}
function* watchGetShuppers() {
    yield takeLatest(types.GET_SHUPPERS, getShuppers);
}
function* watchUpdateImage() {
    yield takeLatest(types.UPDATE_IMAGE_CHECKIN, updateImage);
}
function* watchUpdateVideo() {
    yield takeLatest(types.UPDATE_VIDEO_CHECKIN, updateVideo);
}
function* watchSaveCheckin() {
    yield takeLatest(types.SAVE_CHECKIN_BY_ID, saveCheckin);
}
function* watchSGetStores() {
    yield takeLatest(types.GET_STORES, getStores);
}
function* watchDeleteCheckins() {
    yield takeLatest(types.DELETE_CHECKINS, deleteCheckins);
}

export default function* chatsSaga() {
    yield all([
        watchGetCheckins(),
        watchGetCheckinByID(),
        watchGetShuppers(),
        watchUpdateImage(),
        watchUpdateVideo(),
        watchSaveCheckin(),
        watchSGetStores(),
        watchDeleteCheckins()
    ]);
}
