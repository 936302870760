export const zones = [
  {
    id: 1,
    name: 'Alabama',
    country: 236,
    type: 2,
    iso2_name: 'AL',
  },
  {
    id: 2,
    name: 'Alaska',
    country: 236,
    type: 2,
    iso2_name: 'AK',
  },
  {
    id: 3,
    name: 'Arizona',
    country: 236,
    type: 2,
    iso2_name: 'AZ',
  },
  {
    id: 4,
    name: 'Arkansas',
    country: 236,
    type: 2,
    iso2_name: 'AR',
  },
  {
    id: 5,
    name: 'California',
    country: 236,
    type: 2,
    iso2_name: 'CA',
  },
  {
    id: 6,
    name: 'Colorado',
    country: 236,
    type: 2,
    iso2_name: 'CO',
  },
  {
    id: 7,
    name: 'Connecticut',
    country: 236,
    type: 2,
    iso2_name: 'CT',
  },
  {
    id: 8,
    name: 'Delaware',
    country: 236,
    type: 2,
    iso2_name: 'DE',
  },
  {
    id: 9,
    name: 'Florida',
    country: 236,
    type: 2,
    iso2_name: 'FL',
  },
  {
    id: 10,
    name: 'Georgia',
    country: 236,
    type: 2,
    iso2_name: 'GA',
  },
  {
    id: 11,
    name: 'Hawaii',
    country: 236,
    type: 2,
    iso2_name: 'HI',
  },
  {
    id: 12,
    name: 'Idaho',
    country: 236,
    type: 2,
    iso2_name: 'ID',
  },
  {
    id: 13,
    name: 'Illinois',
    country: 236,
    type: 2,
    iso2_name: 'IL',
  },
  {
    id: 14,
    name: 'Indiana',
    country: 236,
    type: 2,
    iso2_name: 'IN',
  },
  {
    id: 15,
    name: 'Iowa',
    country: 236,
    type: 2,
    iso2_name: 'IA',
  },
  {
    id: 16,
    name: 'Kansas',
    country: 236,
    type: 2,
    iso2_name: 'KS',
  },
  {
    id: 17,
    name: 'Kentucky',
    country: 236,
    type: 2,
    iso2_name: 'KY',
  },
  {
    id: 18,
    name: 'Louisiana',
    country: 236,
    type: 2,
    iso2_name: 'LA',
  },
  {
    id: 19,
    name: 'Maine',
    country: 236,
    type: 2,
    iso2_name: 'ME',
  },
  {
    id: 20,
    name: 'Maryland',
    country: 236,
    type: 2,
    iso2_name: 'MD',
  },
  {
    id: 21,
    name: 'Massachusetts',
    country: 236,
    type: 2,
    iso2_name: 'MA',
  },
  {
    id: 22,
    name: 'Michigan',
    country: 236,
    type: 2,
    iso2_name: 'MI',
  },
  {
    id: 23,
    name: 'Minnesota',
    country: 236,
    type: 2,
    iso2_name: 'MN',
  },
  {
    id: 24,
    name: 'Mississippi',
    country: 236,
    type: 2,
    iso2_name: 'MS',
  },
  {
    id: 25,
    name: 'Missouri',
    country: 236,
    type: 2,
    iso2_name: 'MO',
  },
  {
    id: 26,
    name: 'Montana',
    country: 236,
    type: 2,
    iso2_name: 'MT',
  },
  {
    id: 27,
    name: 'Nebraska',
    country: 236,
    type: 2,
    iso2_name: 'NE',
  },
  {
    id: 28,
    name: 'Nevada',
    country: 236,
    type: 2,
    iso2_name: 'NV',
  },
  {
    id: 29,
    name: 'New Hampshire',
    country: 236,
    type: 2,
    iso2_name: 'NH',
  },
  {
    id: 30,
    name: 'New Jersey',
    country: 236,
    type: 2,
    iso2_name: 'NJ',
  },
  {
    id: 31,
    name: 'New Mexico',
    country: 236,
    type: 2,
    iso2_name: 'NM',
  },
  {
    id: 32,
    name: 'New York',
    country: 236,
    type: 2,
    iso2_name: 'NY',
  },
  {
    id: 33,
    name: 'North Carolina',
    country: 236,
    type: 2,
    iso2_name: 'NC',
  },
  {
    id: 34,
    name: 'North Dakota',
    country: 236,
    type: 2,
    iso2_name: 'ND',
  },
  {
    id: 35,
    name: 'Ohio',
    country: 236,
    type: 2,
    iso2_name: 'OH',
  },
  {
    id: 36,
    name: 'Oklahoma',
    country: 236,
    type: 2,
    iso2_name: 'OK',
  },
  {
    id: 37,
    name: 'Oregon',
    country: 236,
    type: 2,
    iso2_name: 'OR',
  },
  {
    id: 38,
    name: 'Pennsylvania',
    country: 236,
    type: 2,
    iso2_name: 'PA',
  },
  {
    id: 39,
    name: 'Rhode Island',
    country: 236,
    type: 2,
    iso2_name: 'RI',
  },
  {
    id: 40,
    name: 'South Carolina',
    country: 236,
    type: 2,
    iso2_name: 'SC',
  },
  {
    id: 41,
    name: 'South Dakota',
    country: 236,
    type: 2,
    iso2_name: 'SD',
  },
  {
    id: 42,
    name: 'Tennessee',
    country: 236,
    type: 2,
    iso2_name: 'TN',
  },
  {
    id: 43,
    name: 'Texas',
    country: 236,
    type: 2,
    iso2_name: 'TX',
  },
  {
    id: 44,
    name: 'Utah',
    country: 236,
    type: 2,
    iso2_name: 'UT',
  },
  {
    id: 45,
    name: 'Vermont',
    country: 236,
    type: 2,
    iso2_name: 'VT',
  },
  {
    id: 46,
    name: 'Virginia',
    country: 236,
    type: 2,
    iso2_name: 'VA',
  },
  {
    id: 47,
    name: 'Washington',
    country: 236,
    type: 2,
    iso2_name: 'WA',
  },
  {
    id: 48,
    name: 'West Virginia',
    country: 236,
    type: 2,
    iso2_name: 'WV',
  },
  {
    id: 49,
    name: 'Wisconsin',
    country: 236,
    type: 2,
    iso2_name: 'WI',
  },
  {
    id: 50,
    name: 'Wyoming',
    country: 236,
    type: 2,
    iso2_name: 'WY',
  },
  {
    id: 51,
    name: 'Afghanistan',
    country: 1,
    type: 1,
    iso2_name: null,
  },
  {
    id: 52,
    name: 'Albania',
    country: 2,
    type: 1,
    iso2_name: null,
  },
  {
    id: 53,
    name: 'Algeria',
    country: 3,
    type: 1,
    iso2_name: null,
  },
  {
    id: 54,
    name: 'American Samoa',
    country: 4,
    type: 1,
    iso2_name: null,
  },
  {
    id: 55,
    name: 'Andorra',
    country: 5,
    type: 1,
    iso2_name: null,
  },
  {
    id: 56,
    name: 'Angola',
    country: 6,
    type: 1,
    iso2_name: null,
  },
  {
    id: 57,
    name: 'Anguilla',
    country: 7,
    type: 1,
    iso2_name: null,
  },
  {
    id: 58,
    name: 'Antarctica',
    country: 8,
    type: 1,
    iso2_name: null,
  },
  {
    id: 59,
    name: 'Antigua and Barbuda',
    country: 9,
    type: 1,
    iso2_name: null,
  },
  {
    id: 60,
    name: 'Argentina',
    country: 10,
    type: 1,
    iso2_name: null,
  },
  {
    id: 61,
    name: 'Armenia',
    country: 11,
    type: 1,
    iso2_name: null,
  },
  {
    id: 62,
    name: 'Aruba',
    country: 12,
    type: 1,
    iso2_name: null,
  },
  {
    id: 63,
    name: 'Australia',
    country: 13,
    type: 1,
    iso2_name: null,
  },
  {
    id: 64,
    name: 'Austria',
    country: 14,
    type: 1,
    iso2_name: null,
  },
  {
    id: 65,
    name: 'Azerbaijan',
    country: 15,
    type: 1,
    iso2_name: null,
  },
  {
    id: 66,
    name: 'Bahamas (the)',
    country: 16,
    type: 1,
    iso2_name: null,
  },
  {
    id: 67,
    name: 'Bahrain',
    country: 17,
    type: 1,
    iso2_name: null,
  },
  {
    id: 68,
    name: 'Bangladesh',
    country: 18,
    type: 1,
    iso2_name: null,
  },
  {
    id: 69,
    name: 'Barbados',
    country: 19,
    type: 1,
    iso2_name: null,
  },
  {
    id: 70,
    name: 'Belarus',
    country: 20,
    type: 1,
    iso2_name: null,
  },
  {
    id: 71,
    name: 'Belgium',
    country: 21,
    type: 1,
    iso2_name: null,
  },
  {
    id: 72,
    name: 'Belize',
    country: 22,
    type: 1,
    iso2_name: null,
  },
  {
    id: 73,
    name: 'Benin',
    country: 23,
    type: 1,
    iso2_name: null,
  },
  {
    id: 74,
    name: 'Bermuda',
    country: 24,
    type: 1,
    iso2_name: null,
  },
  {
    id: 75,
    name: 'Bhutan',
    country: 25,
    type: 1,
    iso2_name: null,
  },
  {
    id: 76,
    name: 'Bolivia (Plurinational State of)',
    country: 26,
    type: 1,
    iso2_name: null,
  },
  {
    id: 77,
    name: 'Bonaire, Sint Eustatius and Saba',
    country: 27,
    type: 1,
    iso2_name: null,
  },
  {
    id: 78,
    name: 'Bosnia and Herzegovina',
    country: 28,
    type: 1,
    iso2_name: null,
  },
  {
    id: 79,
    name: 'Botswana',
    country: 29,
    type: 1,
    iso2_name: null,
  },
  {
    id: 80,
    name: 'Bouvet Island',
    country: 30,
    type: 1,
    iso2_name: null,
  },
  {
    id: 81,
    name: 'Brazil',
    country: 31,
    type: 1,
    iso2_name: null,
  },
  {
    id: 82,
    name: 'British Indian Ocean Territory (the)',
    country: 32,
    type: 1,
    iso2_name: null,
  },
  {
    id: 83,
    name: 'Brunei Darussalam',
    country: 33,
    type: 1,
    iso2_name: null,
  },
  {
    id: 84,
    name: 'Bulgaria',
    country: 34,
    type: 1,
    iso2_name: null,
  },
  {
    id: 85,
    name: 'Burkina Faso',
    country: 35,
    type: 1,
    iso2_name: null,
  },
  {
    id: 86,
    name: 'Burundi',
    country: 36,
    type: 1,
    iso2_name: null,
  },
  {
    id: 87,
    name: 'Cabo Verde',
    country: 37,
    type: 1,
    iso2_name: null,
  },
  {
    id: 88,
    name: 'Cambodia',
    country: 38,
    type: 1,
    iso2_name: null,
  },
  {
    id: 89,
    name: 'Cameroon',
    country: 39,
    type: 1,
    iso2_name: null,
  },
  {
    id: 90,
    name: 'Canada',
    country: 40,
    type: 1,
    iso2_name: null,
  },
  {
    id: 91,
    name: 'Cayman Islands (the)',
    country: 41,
    type: 1,
    iso2_name: null,
  },
  {
    id: 92,
    name: 'Central African Republic (the)',
    country: 42,
    type: 1,
    iso2_name: null,
  },
  {
    id: 93,
    name: 'Chad',
    country: 43,
    type: 1,
    iso2_name: null,
  },
  {
    id: 94,
    name: 'Chile',
    country: 44,
    type: 1,
    iso2_name: null,
  },
  {
    id: 95,
    name: 'China',
    country: 45,
    type: 1,
    iso2_name: null,
  },
  {
    id: 96,
    name: 'Christmas Island',
    country: 46,
    type: 1,
    iso2_name: null,
  },
  {
    id: 97,
    name: 'Cocos (Keeling) Islands (the)',
    country: 47,
    type: 1,
    iso2_name: null,
  },
  {
    id: 98,
    name: 'Colombia',
    country: 48,
    type: 1,
    iso2_name: null,
  },
  {
    id: 99,
    name: 'Comoros (the)',
    country: 49,
    type: 1,
    iso2_name: null,
  },
  {
    id: 100,
    name: 'Congo (the Democratic Republic of the)',
    country: 50,
    type: 1,
    iso2_name: null,
  },
  {
    id: 101,
    name: 'Congo (the)',
    country: 51,
    type: 1,
    iso2_name: null,
  },
  {
    id: 102,
    name: 'Cook Islands (the)',
    country: 52,
    type: 1,
    iso2_name: null,
  },
  {
    id: 103,
    name: 'Costa Rica',
    country: 53,
    type: 1,
    iso2_name: null,
  },
  {
    id: 104,
    name: 'Croatia',
    country: 54,
    type: 1,
    iso2_name: null,
  },
  {
    id: 105,
    name: 'Cuba',
    country: 55,
    type: 1,
    iso2_name: null,
  },
  {
    id: 106,
    name: 'Curaçao',
    country: 56,
    type: 1,
    iso2_name: null,
  },
  {
    id: 107,
    name: 'Cyprus',
    country: 57,
    type: 1,
    iso2_name: null,
  },
  {
    id: 108,
    name: 'Czechia',
    country: 58,
    type: 1,
    iso2_name: null,
  },
  {
    id: 109,
    name: "Côte d'Ivoire",
    country: 59,
    type: 1,
    iso2_name: null,
  },
  {
    id: 110,
    name: 'Denmark',
    country: 60,
    type: 1,
    iso2_name: null,
  },
  {
    id: 111,
    name: 'Djibouti',
    country: 61,
    type: 1,
    iso2_name: null,
  },
  {
    id: 112,
    name: 'Dominica',
    country: 62,
    type: 1,
    iso2_name: null,
  },
  {
    id: 113,
    name: 'Dominican Republic (the)',
    country: 63,
    type: 1,
    iso2_name: null,
  },
  {
    id: 114,
    name: 'Ecuador',
    country: 64,
    type: 1,
    iso2_name: null,
  },
  {
    id: 115,
    name: 'Egypt',
    country: 65,
    type: 1,
    iso2_name: null,
  },
  {
    id: 116,
    name: 'El Salvador',
    country: 66,
    type: 1,
    iso2_name: null,
  },
  {
    id: 117,
    name: 'Equatorial Guinea',
    country: 67,
    type: 1,
    iso2_name: null,
  },
  {
    id: 118,
    name: 'Eritrea',
    country: 68,
    type: 1,
    iso2_name: null,
  },
  {
    id: 119,
    name: 'Estonia',
    country: 69,
    type: 1,
    iso2_name: null,
  },
  {
    id: 120,
    name: 'Eswatini',
    country: 70,
    type: 1,
    iso2_name: null,
  },
  {
    id: 121,
    name: 'Ethiopia',
    country: 71,
    type: 1,
    iso2_name: null,
  },
  {
    id: 122,
    name: 'Falkland Islands (the) [Malvinas]',
    country: 72,
    type: 1,
    iso2_name: null,
  },
  {
    id: 123,
    name: 'Faroe Islands (the)',
    country: 73,
    type: 1,
    iso2_name: null,
  },
  {
    id: 124,
    name: 'Fiji',
    country: 74,
    type: 1,
    iso2_name: null,
  },
  {
    id: 125,
    name: 'Finland',
    country: 75,
    type: 1,
    iso2_name: null,
  },
  {
    id: 126,
    name: 'France',
    country: 76,
    type: 1,
    iso2_name: null,
  },
  {
    id: 127,
    name: 'French Guiana',
    country: 77,
    type: 1,
    iso2_name: null,
  },
  {
    id: 128,
    name: 'French Polynesia',
    country: 78,
    type: 1,
    iso2_name: null,
  },
  {
    id: 129,
    name: 'French Southern Territories (the)',
    country: 79,
    type: 1,
    iso2_name: null,
  },
  {
    id: 130,
    name: 'Gabon',
    country: 80,
    type: 1,
    iso2_name: null,
  },
  {
    id: 131,
    name: 'Gambia (the)',
    country: 81,
    type: 1,
    iso2_name: null,
  },
  {
    id: 132,
    name: 'Georgia',
    country: 82,
    type: 1,
    iso2_name: null,
  },
  {
    id: 133,
    name: 'Germany',
    country: 83,
    type: 1,
    iso2_name: null,
  },
  {
    id: 134,
    name: 'Ghana',
    country: 84,
    type: 1,
    iso2_name: null,
  },
  {
    id: 135,
    name: 'Gibraltar',
    country: 85,
    type: 1,
    iso2_name: null,
  },
  {
    id: 136,
    name: 'Greece',
    country: 86,
    type: 1,
    iso2_name: null,
  },
  {
    id: 137,
    name: 'Greenland',
    country: 87,
    type: 1,
    iso2_name: null,
  },
  {
    id: 138,
    name: 'Grenada',
    country: 88,
    type: 1,
    iso2_name: null,
  },
  {
    id: 139,
    name: 'Guadeloupe',
    country: 89,
    type: 1,
    iso2_name: null,
  },
  {
    id: 140,
    name: 'Guam',
    country: 90,
    type: 1,
    iso2_name: null,
  },
  {
    id: 141,
    name: 'Guatemala',
    country: 91,
    type: 1,
    iso2_name: null,
  },
  {
    id: 142,
    name: 'Guernsey',
    country: 92,
    type: 1,
    iso2_name: null,
  },
  {
    id: 143,
    name: 'Guinea',
    country: 93,
    type: 1,
    iso2_name: null,
  },
  {
    id: 144,
    name: 'Guinea-Bissau',
    country: 94,
    type: 1,
    iso2_name: null,
  },
  {
    id: 145,
    name: 'Guyana',
    country: 95,
    type: 1,
    iso2_name: null,
  },
  {
    id: 146,
    name: 'Haiti',
    country: 96,
    type: 1,
    iso2_name: null,
  },
  {
    id: 147,
    name: 'Heard Island and McDonald Islands',
    country: 97,
    type: 1,
    iso2_name: null,
  },
  {
    id: 148,
    name: 'Holy See (the)',
    country: 98,
    type: 1,
    iso2_name: null,
  },
  {
    id: 149,
    name: 'Honduras',
    country: 99,
    type: 1,
    iso2_name: null,
  },
  {
    id: 150,
    name: 'Hong Kong',
    country: 100,
    type: 1,
    iso2_name: null,
  },
  {
    id: 151,
    name: 'Hungary',
    country: 101,
    type: 1,
    iso2_name: null,
  },
  {
    id: 152,
    name: 'Iceland',
    country: 102,
    type: 1,
    iso2_name: null,
  },
  {
    id: 153,
    name: 'India',
    country: 103,
    type: 1,
    iso2_name: null,
  },
  {
    id: 154,
    name: 'Indonesia',
    country: 104,
    type: 1,
    iso2_name: null,
  },
  {
    id: 155,
    name: 'Iran (Islamic Republic of)',
    country: 105,
    type: 1,
    iso2_name: null,
  },
  {
    id: 156,
    name: 'Iraq',
    country: 106,
    type: 1,
    iso2_name: null,
  },
  {
    id: 157,
    name: 'Ireland',
    country: 107,
    type: 1,
    iso2_name: null,
  },
  {
    id: 158,
    name: 'Isle of Man',
    country: 108,
    type: 1,
    iso2_name: null,
  },
  {
    id: 159,
    name: 'Israel',
    country: 109,
    type: 1,
    iso2_name: null,
  },
  {
    id: 160,
    name: 'Italy',
    country: 110,
    type: 1,
    iso2_name: null,
  },
  {
    id: 161,
    name: 'Jamaica',
    country: 111,
    type: 1,
    iso2_name: null,
  },
  {
    id: 162,
    name: 'Japan',
    country: 112,
    type: 1,
    iso2_name: null,
  },
  {
    id: 163,
    name: 'Jersey',
    country: 113,
    type: 1,
    iso2_name: null,
  },
  {
    id: 164,
    name: 'Jordan',
    country: 114,
    type: 1,
    iso2_name: null,
  },
  {
    id: 165,
    name: 'Kazakhstan',
    country: 115,
    type: 1,
    iso2_name: null,
  },
  {
    id: 166,
    name: 'Kenya',
    country: 116,
    type: 1,
    iso2_name: null,
  },
  {
    id: 167,
    name: 'Kiribati',
    country: 117,
    type: 1,
    iso2_name: null,
  },
  {
    id: 168,
    name: "Korea (the Democratic People's Republic of)",
    country: 118,
    type: 1,
    iso2_name: null,
  },
  {
    id: 169,
    name: 'Korea (the Republic of)',
    country: 119,
    type: 1,
    iso2_name: null,
  },
  {
    id: 170,
    name: 'Kuwait',
    country: 120,
    type: 1,
    iso2_name: null,
  },
  {
    id: 171,
    name: 'Kyrgyzstan',
    country: 121,
    type: 1,
    iso2_name: null,
  },
  {
    id: 172,
    name: "Lao People's Democratic Republic (the)",
    country: 122,
    type: 1,
    iso2_name: null,
  },
  {
    id: 173,
    name: 'Latvia',
    country: 123,
    type: 1,
    iso2_name: null,
  },
  {
    id: 174,
    name: 'Lebanon',
    country: 124,
    type: 1,
    iso2_name: null,
  },
  {
    id: 175,
    name: 'Lesotho',
    country: 125,
    type: 1,
    iso2_name: null,
  },
  {
    id: 176,
    name: 'Liberia',
    country: 126,
    type: 1,
    iso2_name: null,
  },
  {
    id: 177,
    name: 'Libya',
    country: 127,
    type: 1,
    iso2_name: null,
  },
  {
    id: 178,
    name: 'Liechtenstein',
    country: 128,
    type: 1,
    iso2_name: null,
  },
  {
    id: 179,
    name: 'Lithuania',
    country: 129,
    type: 1,
    iso2_name: null,
  },
  {
    id: 180,
    name: 'Luxembourg',
    country: 130,
    type: 1,
    iso2_name: null,
  },
  {
    id: 181,
    name: 'Macao',
    country: 131,
    type: 1,
    iso2_name: null,
  },
  {
    id: 182,
    name: 'Madagascar',
    country: 132,
    type: 1,
    iso2_name: null,
  },
  {
    id: 183,
    name: 'Malawi',
    country: 133,
    type: 1,
    iso2_name: null,
  },
  {
    id: 184,
    name: 'Malaysia',
    country: 134,
    type: 1,
    iso2_name: null,
  },
  {
    id: 185,
    name: 'Maldives',
    country: 135,
    type: 1,
    iso2_name: null,
  },
  {
    id: 186,
    name: 'Mali',
    country: 136,
    type: 1,
    iso2_name: null,
  },
  {
    id: 187,
    name: 'Malta',
    country: 137,
    type: 1,
    iso2_name: null,
  },
  {
    id: 188,
    name: 'Marshall Islands (the)',
    country: 138,
    type: 1,
    iso2_name: null,
  },
  {
    id: 189,
    name: 'Martinique',
    country: 139,
    type: 1,
    iso2_name: null,
  },
  {
    id: 190,
    name: 'Mauritania',
    country: 140,
    type: 1,
    iso2_name: null,
  },
  {
    id: 191,
    name: 'Mauritius',
    country: 141,
    type: 1,
    iso2_name: null,
  },
  {
    id: 192,
    name: 'Mayotte',
    country: 142,
    type: 1,
    iso2_name: null,
  },
  {
    id: 193,
    name: 'Mexico',
    country: 143,
    type: 1,
    iso2_name: null,
  },
  {
    id: 194,
    name: 'Micronesia (Federated States of)',
    country: 144,
    type: 1,
    iso2_name: null,
  },
  {
    id: 195,
    name: 'Moldova (the Republic of)',
    country: 145,
    type: 1,
    iso2_name: null,
  },
  {
    id: 196,
    name: 'Monaco',
    country: 146,
    type: 1,
    iso2_name: null,
  },
  {
    id: 197,
    name: 'Mongolia',
    country: 147,
    type: 1,
    iso2_name: null,
  },
  {
    id: 198,
    name: 'Montenegro',
    country: 148,
    type: 1,
    iso2_name: null,
  },
  {
    id: 199,
    name: 'Montserrat',
    country: 149,
    type: 1,
    iso2_name: null,
  },
  {
    id: 200,
    name: 'Morocco',
    country: 150,
    type: 1,
    iso2_name: null,
  },
  {
    id: 201,
    name: 'Mozambique',
    country: 151,
    type: 1,
    iso2_name: null,
  },
  {
    id: 202,
    name: 'Myanmar',
    country: 152,
    type: 1,
    iso2_name: null,
  },
  {
    id: 203,
    name: 'Namibia',
    country: 153,
    type: 1,
    iso2_name: null,
  },
  {
    id: 204,
    name: 'Nauru',
    country: 154,
    type: 1,
    iso2_name: null,
  },
  {
    id: 205,
    name: 'Nepal',
    country: 155,
    type: 1,
    iso2_name: null,
  },
  {
    id: 206,
    name: 'Netherlands (the)',
    country: 156,
    type: 1,
    iso2_name: null,
  },
  {
    id: 207,
    name: 'New Caledonia',
    country: 157,
    type: 1,
    iso2_name: null,
  },
  {
    id: 208,
    name: 'New Zealand',
    country: 158,
    type: 1,
    iso2_name: null,
  },
  {
    id: 209,
    name: 'Nicaragua',
    country: 159,
    type: 1,
    iso2_name: null,
  },
  {
    id: 210,
    name: 'Niger (the)',
    country: 160,
    type: 1,
    iso2_name: null,
  },
  {
    id: 211,
    name: 'Nigeria',
    country: 161,
    type: 1,
    iso2_name: null,
  },
  {
    id: 212,
    name: 'Niue',
    country: 162,
    type: 1,
    iso2_name: null,
  },
  {
    id: 213,
    name: 'Norfolk Island',
    country: 163,
    type: 1,
    iso2_name: null,
  },
  {
    id: 214,
    name: 'Northern Mariana Islands (the)',
    country: 164,
    type: 1,
    iso2_name: null,
  },
  {
    id: 215,
    name: 'Norway',
    country: 165,
    type: 1,
    iso2_name: null,
  },
  {
    id: 216,
    name: 'Oman',
    country: 166,
    type: 1,
    iso2_name: null,
  },
  {
    id: 217,
    name: 'Pakistan',
    country: 167,
    type: 1,
    iso2_name: null,
  },
  {
    id: 218,
    name: 'Palau',
    country: 168,
    type: 1,
    iso2_name: null,
  },
  {
    id: 219,
    name: 'Palestine, State of',
    country: 169,
    type: 1,
    iso2_name: null,
  },
  {
    id: 220,
    name: 'Panama',
    country: 170,
    type: 1,
    iso2_name: null,
  },
  {
    id: 221,
    name: 'Papua New Guinea',
    country: 171,
    type: 1,
    iso2_name: null,
  },
  {
    id: 222,
    name: 'Paraguay',
    country: 172,
    type: 1,
    iso2_name: null,
  },
  {
    id: 223,
    name: 'Peru',
    country: 173,
    type: 1,
    iso2_name: null,
  },
  {
    id: 224,
    name: 'Philippines (the)',
    country: 174,
    type: 1,
    iso2_name: null,
  },
  {
    id: 225,
    name: 'Pitcairn',
    country: 175,
    type: 1,
    iso2_name: null,
  },
  {
    id: 226,
    name: 'Poland',
    country: 176,
    type: 1,
    iso2_name: null,
  },
  {
    id: 227,
    name: 'Portugal',
    country: 177,
    type: 1,
    iso2_name: null,
  },
  {
    id: 228,
    name: 'Puerto Rico',
    country: 178,
    type: 1,
    iso2_name: null,
  },
  {
    id: 229,
    name: 'Qatar',
    country: 179,
    type: 1,
    iso2_name: null,
  },
  {
    id: 230,
    name: 'Republic of North Macedonia',
    country: 180,
    type: 1,
    iso2_name: null,
  },
  {
    id: 231,
    name: 'Romania',
    country: 181,
    type: 1,
    iso2_name: null,
  },
  {
    id: 232,
    name: 'Russian Federation (the)',
    country: 182,
    type: 1,
    iso2_name: null,
  },
  {
    id: 233,
    name: 'Rwanda',
    country: 183,
    type: 1,
    iso2_name: null,
  },
  {
    id: 234,
    name: 'Réunion',
    country: 184,
    type: 1,
    iso2_name: null,
  },
  {
    id: 235,
    name: 'Saint Barthélemy',
    country: 185,
    type: 1,
    iso2_name: null,
  },
  {
    id: 236,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    country: 186,
    type: 1,
    iso2_name: null,
  },
  {
    id: 237,
    name: 'Saint Kitts and Nevis',
    country: 187,
    type: 1,
    iso2_name: null,
  },
  {
    id: 238,
    name: 'Saint Lucia',
    country: 188,
    type: 1,
    iso2_name: null,
  },
  {
    id: 239,
    name: 'Saint Martin (French part)',
    country: 189,
    type: 1,
    iso2_name: null,
  },
  {
    id: 240,
    name: 'Saint Pierre and Miquelon',
    country: 190,
    type: 1,
    iso2_name: null,
  },
  {
    id: 241,
    name: 'Saint Vincent and the Grenadines',
    country: 191,
    type: 1,
    iso2_name: null,
  },
  {
    id: 242,
    name: 'Samoa',
    country: 192,
    type: 1,
    iso2_name: null,
  },
  {
    id: 243,
    name: 'San Marino',
    country: 193,
    type: 1,
    iso2_name: null,
  },
  {
    id: 244,
    name: 'Sao Tome and Principe',
    country: 194,
    type: 1,
    iso2_name: null,
  },
  {
    id: 245,
    name: 'Saudi Arabia',
    country: 195,
    type: 1,
    iso2_name: null,
  },
  {
    id: 246,
    name: 'Senegal',
    country: 196,
    type: 1,
    iso2_name: null,
  },
  {
    id: 247,
    name: 'Serbia',
    country: 197,
    type: 1,
    iso2_name: null,
  },
  {
    id: 248,
    name: 'Seychelles',
    country: 198,
    type: 1,
    iso2_name: null,
  },
  {
    id: 249,
    name: 'Sierra Leone',
    country: 199,
    type: 1,
    iso2_name: null,
  },
  {
    id: 250,
    name: 'Singapore',
    country: 200,
    type: 1,
    iso2_name: null,
  },
  {
    id: 251,
    name: 'Sint Maarten (Dutch part)',
    country: 201,
    type: 1,
    iso2_name: null,
  },
  {
    id: 252,
    name: 'Slovakia',
    country: 202,
    type: 1,
    iso2_name: null,
  },
  {
    id: 253,
    name: 'Slovenia',
    country: 203,
    type: 1,
    iso2_name: null,
  },
  {
    id: 254,
    name: 'Solomon Islands',
    country: 204,
    type: 1,
    iso2_name: null,
  },
  {
    id: 255,
    name: 'Somalia',
    country: 205,
    type: 1,
    iso2_name: null,
  },
  {
    id: 256,
    name: 'South Africa',
    country: 206,
    type: 1,
    iso2_name: null,
  },
  {
    id: 257,
    name: 'South Georgia and the South Sandwich Islands',
    country: 207,
    type: 1,
    iso2_name: null,
  },
  {
    id: 258,
    name: 'South Sudan',
    country: 208,
    type: 1,
    iso2_name: null,
  },
  {
    id: 259,
    name: 'Spain',
    country: 209,
    type: 1,
    iso2_name: null,
  },
  {
    id: 260,
    name: 'Sri Lanka',
    country: 210,
    type: 1,
    iso2_name: null,
  },
  {
    id: 261,
    name: 'Sudan (the)',
    country: 211,
    type: 1,
    iso2_name: null,
  },
  {
    id: 262,
    name: 'Suriname',
    country: 212,
    type: 1,
    iso2_name: null,
  },
  {
    id: 263,
    name: 'Svalbard and Jan Mayen',
    country: 213,
    type: 1,
    iso2_name: null,
  },
  {
    id: 264,
    name: 'Sweden',
    country: 214,
    type: 1,
    iso2_name: null,
  },
  {
    id: 265,
    name: 'Switzerland',
    country: 215,
    type: 1,
    iso2_name: null,
  },
  {
    id: 266,
    name: 'Syrian Arab Republic',
    country: 216,
    type: 1,
    iso2_name: null,
  },
  {
    id: 267,
    name: 'Taiwan (Province of China)',
    country: 217,
    type: 1,
    iso2_name: null,
  },
  {
    id: 268,
    name: 'Tajikistan',
    country: 218,
    type: 1,
    iso2_name: null,
  },
  {
    id: 269,
    name: 'Tanzania, United Republic of',
    country: 219,
    type: 1,
    iso2_name: null,
  },
  {
    id: 270,
    name: 'Thailand',
    country: 220,
    type: 1,
    iso2_name: null,
  },
  {
    id: 271,
    name: 'Timor-Leste',
    country: 221,
    type: 1,
    iso2_name: null,
  },
  {
    id: 272,
    name: 'Togo',
    country: 222,
    type: 1,
    iso2_name: null,
  },
  {
    id: 273,
    name: 'Tokelau',
    country: 223,
    type: 1,
    iso2_name: null,
  },
  {
    id: 274,
    name: 'Tonga',
    country: 224,
    type: 1,
    iso2_name: null,
  },
  {
    id: 275,
    name: 'Trinidad and Tobago',
    country: 225,
    type: 1,
    iso2_name: null,
  },
  {
    id: 276,
    name: 'Tunisia',
    country: 226,
    type: 1,
    iso2_name: null,
  },
  {
    id: 277,
    name: 'Turkey',
    country: 227,
    type: 1,
    iso2_name: null,
  },
  {
    id: 278,
    name: 'Turkmenistan',
    country: 228,
    type: 1,
    iso2_name: null,
  },
  {
    id: 279,
    name: 'Turks and Caicos Islands (the)',
    country: 229,
    type: 1,
    iso2_name: null,
  },
  {
    id: 280,
    name: 'Tuvalu',
    country: 230,
    type: 1,
    iso2_name: null,
  },
  {
    id: 281,
    name: 'Uganda',
    country: 231,
    type: 1,
    iso2_name: null,
  },
  {
    id: 282,
    name: 'Ukraine',
    country: 232,
    type: 1,
    iso2_name: null,
  },
  {
    id: 283,
    name: 'United Arab Emirates (the)',
    country: 233,
    type: 1,
    iso2_name: null,
  },
  {
    id: 284,
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    country: 234,
    type: 1,
    iso2_name: null,
  },
  {
    id: 285,
    name: 'United States Minor Outlying Islands (the)',
    country: 235,
    type: 1,
    iso2_name: null,
  },
  {
    id: 286,
    name: 'United States of America (the)',
    country: 236,
    type: 1,
    iso2_name: null,
  },
  {
    id: 287,
    name: 'Uruguay',
    country: 237,
    type: 1,
    iso2_name: null,
  },
  {
    id: 288,
    name: 'Uzbekistan',
    country: 238,
    type: 1,
    iso2_name: null,
  },
  {
    id: 289,
    name: 'Vanuatu',
    country: 239,
    type: 1,
    iso2_name: null,
  },
  {
    id: 290,
    name: 'Venezuela (Bolivarian Republic of)',
    country: 240,
    type: 1,
    iso2_name: null,
  },
  {
    id: 291,
    name: 'Viet Nam',
    country: 241,
    type: 1,
    iso2_name: null,
  },
  {
    id: 292,
    name: 'Virgin Islands (British)',
    country: 242,
    type: 1,
    iso2_name: null,
  },
  {
    id: 293,
    name: 'Virgin Islands (U.S.)',
    country: 243,
    type: 1,
    iso2_name: null,
  },
  {
    id: 294,
    name: 'Wallis and Futuna',
    country: 244,
    type: 1,
    iso2_name: null,
  },
  {
    id: 295,
    name: 'Western Sahara',
    country: 245,
    type: 1,
    iso2_name: null,
  },
  {
    id: 296,
    name: 'Yemen',
    country: 246,
    type: 1,
    iso2_name: null,
  },
  {
    id: 297,
    name: 'Zambia',
    country: 247,
    type: 1,
    iso2_name: null,
  },
  {
    id: 298,
    name: 'Zimbabwe',
    country: 248,
    type: 1,
    iso2_name: null,
  },
  {
    id: 299,
    name: 'Åland Islands',
    country: 249,
    type: 1,
    iso2_name: null,
  },
];
