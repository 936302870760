import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchPackageFailed, fetchPackageSuccess, updatePackageFailed, updatePackageSuccess } from '../actions/packageActions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectPackageState = state => state.packageReducer;

function* fetchPackageAsync(action) {
  const packageState = yield select(selectPackageState);
  const { id } = packageState

  const response = yield call(
    request,
    `${baseUrl}/api/v2/package/${id}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchPackageFailed(response.err.toString()));
  } else {
    yield put(fetchPackageSuccess(response.data));
  }
}

function* updatePackageAsync(action) {
  const { uid } = action.data
  const body = action.data

  const response = yield call(
    request,
    `${baseUrl}/api/v2/package/${uid}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    },
  );

  if (response.err) {
    yield put(updatePackageFailed(response.err.toString()));
  } else {
    yield put(updatePackageSuccess(response.data));
  }
}

function* fetchPackage() {
  yield takeLatest([types.FETCH_PACKAGE_DETAILS, types.UPDATE_PACKAGE_DETAILS_SUCCESS], fetchPackageAsync);
}

function* updatePackage() {
  yield takeLatest([types.UPDATE_PACKAGE_DETAILS], updatePackageAsync);
}

export default function* packages() {
  yield all([
    updatePackage(),
    fetchPackage()
  ]);
}
