/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 17/04/2018.
 */

import types from './actionTypes';

export const fetchLogs = query => ({
  type: types.FETCH_LOGS,
  query,
});

export const fetchMoreLogs = () => ({
  type: types.FETCH_MORE_LOGS,
});

export const fetchLogsSuccess = data => ({
  type: types.FETCH_LOGS_SUCCESS,
  data,
});

export const fetchLogsFailed = reason => ({
  type: types.FETCH_LOGS_FAILED,
  reason,
});
