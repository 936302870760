import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';
import { createSelector } from 'reselect';

const initialState = {
    isLoading: false,
    reviews: [],
    activeStatus: 'pending',
    error: null,
    brandId: null,
    brandName: null,
    haveMore: false,
    skip: 0,
    backup: {},
    orientation: 0,
};

export default createReducer({
    [types.SET_BRAND_ID]: (state, { payload }) => ({
        ...state,
        isLoading: true,
        brandId: payload.id,
    }),
    [types.SET_LOAD_ERROR]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error,
    }),
    [types.SET_REVIEWS_LIST]: (state, { payload }) => ({
        ...state,
        reviews: [...payload.reviews],
        haveMore: payload.haveMore,
        skip: payload.skip,
        isLoading: false,
    }),
    [types.PREPEND_REVIEWS_LIST]: (state, { payload }) => ({
        ...state,
        reviews: [...payload.list, ...state.reviews],
        haveMore: payload.haveMore,
        skip: state.skip + payload.skip,
        isLoading: false,
    }),
    [types.CHANGE_STATUS_OF_ACTIVE_REVIEW]: (state, { payload }) => ({
        ...state,
        isLoading: true,
        activeStatus: payload.status,
        reviews: [],
    }),
    [types.SET_SAVE_REVIEW_SUCCESS]: (state) => ({
        ...state,
        success: true,
    }),
    [types.LOAD_REVIEWS_BY_BRAND]: (state) => ({
        ...state,
        error: null,
        success: null,
    }),
    [types.UPDATE_REVIEW_STATUS]: (state, { payload }) => ({
        ...state,
        reviews: state.reviews.filter((_, id) => id !== payload.index),
    }),
    [types.START_EDIT_REVIEW]: (state, { payload }) => ({
        ...state,
        backup: {
            text: state.reviews[payload.id].text,
            rating: state.reviews[payload.id].rating,
            image: state.reviews[payload.id].image
        },
    }),
    [types.CANCEL_EDIT_REVIEW]: (state, { payload }) => ({
        ...state,
        backup: {},
        reviews: Object.assign(
            [],
            state.reviews,
            { [payload.key]: { ...state.reviews[payload.id], ...state.backup } }
        )
    }),
    [types.SET_PREVIEW_IMAGE_ORIENTATION]: (state, { payload }) => ({
        ...state,
        orientation: payload.orientation,
    }),

    [types.CLEAR_PREVIEW_IMAGE_ORIENTATION]: (state) => ({
        ...state,
        orientation: 0,
    }),
    [types.SET_NEW_VALUE_REVIEW]: (state, { payload }) => ({
        ...state,
        tempModifier: { ...state.tempModifier, [payload.key]: payload.val, }
    }),
    [types.UPDATE_REVIEW_FIELDS]: (state, { payload }) => ({
        ...state,
        reviews: Object.assign(
            [],
            state.reviews,
            {
                [payload.key]:
                {
                    ...state.reviews[payload.key],
                    text: payload.data.text,
                    rating: payload.data.rating
                }
            }
        ),
        backup: null,
    }),
    [types.SET_NEW_REVIEW_IMAGE]: (state, { payload }) => ({
        ...state,
        reviews: Object.assign(
            [],
            state.reviews,
            {
                [payload.key]:
                {
                    ...state.reviews[payload.key],
                    image: payload.src,
                }
            }
        ),
    }),
    [types.SET_NEW_REVIEW_VIDEO]: (state, { payload }) => ({
        ...state,
        reviews: Object.assign(
            [],
            state.reviews,
            {
                [payload.key]:
                {
                    ...state.reviews[payload.key],
                    video: payload.src,
                    videoPoster: payload.posterSrc
                }
            }
        ),
    }),
    [types.SET_BRAND_NAME]: (state, { payload }) => ({
        ...state,
        brandName: payload.brandName,
    })
}, initialState);


// SELECTORS
const brandReviewsSelector = state => state.brandReviewsReducer;

export const reviewListProps = createSelector(
    brandReviewsSelector,
    ({ reviews, haveMore, skip }) => ({
        reviews, haveMore, skip,
    })
);

export const getProps = createSelector(
    brandReviewsSelector,
    ({
        isLoading, activeStatus, error, brandId,
        success, brandName, orientation
    }) => ({
        isLoading, activeStatus, error, brandId,
        success, brandName, orientation
    })
);