import React from 'react';
import IconButton from 'material-ui/IconButton';
import Done from 'material-ui/svg-icons/action/done';
import Cross from 'material-ui/svg-icons/content/clear';



const ShupperComponent = ({
    approveCandidate, declineCandidate, activeStatus, index,
    brand }) => (

        <div className="column-item flxr ac" key={index}>
            <div className="flxr ac message-user">
                <div className="flxc jsb afs brand-item">

                    <div className="" >
                        {`${brand}`}                        </div>

                </div>
            </div>

            <div className="accept-decline">

                <IconButton
                    disabled={activeStatus === 'approved'}
                    tooltip="Accept"
                    onClick={() => approveCandidate(brand)}
                >
                    <Done color="green" />
                </IconButton>
                <IconButton
                    disabled={activeStatus === 'declined'}
                    tooltip="Decline"
                    onClick={() => declineCandidate(brand)}
                >
                    <Cross color="red" />
                </IconButton>
            </div>
        </div>
    );



export default (ShupperComponent);
