import React from "react";
import IconButton from "material-ui/IconButton";

import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import NoPermission from "../components/nopermission";
import { connect } from "react-redux";
import Tabs from "../components/Tabs";
import { decodePermissions } from "../utils/permissionUtil";
import { getPermission } from "../utils/cookies";
import Brand from "../components/Brand";
import getHandlers from "../actions/approveBrandsActions";
import { getProps } from "../reducers/approveBrandsReducer";
import { withRouter } from "../utils/withRouter";
import "../static/styles/approveBrands.css";
import LinearProgress from "material-ui/LinearProgress";
import SuccessAlert from "../components/SuccessAlert";
import ErrorAlert from "../components/ErrorAlert";


const ApproveBrandsList = ({
    showCandidateInfo,
    brands,
    activeStatus,
    isLoading,
    approveCandidate,
    declineCandidate,
}) =>
    brands && brands.length ? (
        <React.Fragment>
            {brands.map((brand, index) => (
                <Brand
                    declineCandidate={declineCandidate}
                    approveCandidate={approveCandidate}
                    showCandidateInfo={showCandidateInfo}
                    brand={brand}
                    index={index}
                    activeStatus={activeStatus}
                />
            ))}
        </React.Fragment>
    ) : isLoading ? (
        <div className="no-content">Loading...</div>
    ) : (
        <div className="no-content">No Brands</div>
    );

class ApproveBrandsPage extends React.Component {
    constructor(props) {
        super(props);
        const permission = decodePermissions(getPermission());
        this.state = {
            permission,
            modalOpen: false,
            activeStatus: "pending",
            selectedCandidate: null,
            saveSuccess: false,
            saveError: false,
        };
    }
    componentDidMount() {
        if (this.state.permission.canApproveBrands) {
            this.props.fetchBrands("pending");
        }
    }
    componentDidUpdate(prevProps) {
        if (
            (prevProps.approveSuccess !== this.props.approveSuccess &&
                this.props.approveSuccess) ||
            (prevProps.declineSuccess !== this.props.declineSuccess &&
                this.props.declineSuccess)
        ) {
            this.setState({ saveSuccess: true, saveError: false });
            setTimeout(() => this.setState({ saveSuccess: false }), 40000);
            this.props.resetApproveBrand();
            this.props.fetchBrands(this.state.activeStatus);
        }
        if (
            prevProps.approveError !== this.props.approveError &&
            this.props.approveError
        ) {
            this.setState({ saveError: true, saveSuccess: false });
            setTimeout(() => this.setState({ saveError: false }), 40000);
            this.props.resetApproveBrand();
            this.props.fetchBrands(this.state.activeStatus);
        }
    }
    onCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    changeActiveStatus = (e) => {
        let status;
        if (e === 0) {
            status = "pending";
            this.props.changeStatus("pending");
        } else if (e === 1) {
            status = "approved";
            this.props.changeStatus("approved");
        } else {
            status = "declined";
            this.props.changeStatus("declined");
        }
        this.props.fetchBrands(status);

        this.setState({ activeStatus: status });
    };
    approveCandidate = (id) => {
        this.props.approveBrand(id);
    };
    declineCandidate = (id) => {
        this.props.declineBrand(id);
    };
    getBrands = () => {
        const { brands } = this.props;
        let results = [];
        brands.forEach((item) => {
            if (item !== null) {
                results.push(item);
            }
        });
        return results;
    };
    render() {
        const { onOpenDrawer, isLoading } = this.props;

        const { permission, activeStatus, saveSuccess, saveError } = this.state;
        return permission.canApproveBrands ? (
            <div>
                <div className="App-header">
                    <IconButton onClick={() => this.props.navigate(-1)}>
                        <NavigationArrowBack color="white" />
                    </IconButton>
                </div>
                <div className="progressbar-container">
                    {isLoading && <LinearProgress mode="indeterminate" />}
                </div>{" "}
                {saveSuccess ? (
                    <SuccessAlert
                        mainText="Success!"
                        extraText="Successfully saved"
                        onClose={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                ) : null}
                {saveError ? (
                    <ErrorAlert
                        mainText="Error!"
                        extraText="This user was approved before"
                        onClose={() => {
                            this.setState({ saveError: false });
                        }}
                    />
                ) : null}
                <div className="page-title flxr ac jc">Manage Brands</div>
                <div className="approve-brands-tabs flxc ac jc">
                    <Tabs
                        tabs={["Pending", "Approved", "Declined"]}
                        contents={
                            <ApproveBrandsList
                                approveCandidate={this.approveCandidate}
                                declineCandidate={this.declineCandidate}
                                showCandidateInfo={this.showCandidateInfo}
                                activeStatus={activeStatus}
                                isLoading={isLoading}
                                brands={this.getBrands()}
                            />
                        }
                        width="80%"
                        onChange={(e) => this.changeActiveStatus(e)}
                    />
                </div>
            </div>
        ) : (
            <NoPermission onOpenDrawer={onOpenDrawer} />
        );
    }
}

export default withRouter(connect(getProps, getHandlers)(ApproveBrandsPage));
