import {useRoutes, Navigate} from 'react-router-dom';
import {
  App,
  UserList,
  Inaccessibility,
  BOUsers,
  BOUser,
  ItemList,
  Item,
  EventListV2,
  ItemListV2,
  CollectionsList,
  Collection,
  StoreList,
  StoreDetails,
  ChatList,
  Login,
  EventList,
  Event,
  SecurityLogs,
  Coupons,
  Coupon,
  ShupperOfTheDayList,
  Pending,
  Rejected,
  Canceled,
  Completed,
  Refunded,
  Shipping,
  SinglePayment,
  ChatPage,
  BrandReview,
  ApproveBrands,
  Profile,
  UserTotalTips,
  Checkins,
  Checkin,
  ApproveShuppers,
  ViewPermissions,
  OldUserList,
  ProfileOld,
  EditShipping,
  Settings,
  Orders,
  PaymentRequests,
  ItemV2,
  EventV2,
  Packages,
  Package,
} from '../../containers';
import EditUserProfile from '../../components/profile/EditUserProfile';
import PaymentRequestResponses from '../../components/paymentsRequests/paymentRequestsResponses';

export const AppRoutes = props =>
  useRoutes([
    {
      path: '',
      element: <App {...props} />,
      children: [
        {
          path: '/',
          element: <Navigate to="/login" />,
        },
        {
          path: 'users/tip',
          element: <UserTotalTips {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'users',
          element: <UserList {...props} />,
        },
        {
          path: 'usersold',
          element: <OldUserList {...props} />,
        },
        {
          path: 'inaccessibility',
          element: <Inaccessibility {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'bousers',
          element: <BOUsers {...props} />,
        },
        {
          path: 'bouser',
          element: <BOUser {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'view-permissions',
          element: <ViewPermissions {...props} />,
        },

        {
          path: 'items',
          element: <ItemList {...props} />,
        },
        {
          path: 'item',
          element: <Item {...props} />,
        },
        {
          path: 'itemsv2',
          element: <ItemListV2 {...props} />,
        },
        {
          path: 'itemv2',
          element: <ItemV2 {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'collections',
          element: <CollectionsList {...props} />,
        },

        {
          path: 'collection',
          element: <Collection {...props} />,
        },

        {
          path: 'stores',
          element: <StoreList {...props} />,
        },

        {
          path: 'store',
          element: <StoreDetails {...props} />,
        },
        {
          path: 'chats',
          element: <ChatList {...props} />,
        },
        {
          path: 'login',
          element: <Login {...props} />,
        },
        {
          path: 'events',
          element: <EventList {...props} />,
        },
        {
          path: 'event',
          element: <Event {...props} />,
        },
        {
          path: 'eventsv2',
          element: <EventListV2 {...props} />,
        },
        {
          path: 'eventv2',
          element: <EventV2 {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'securityLogs',
          element: <SecurityLogs {...props} />,
        },
        {
          path: 'coupons',
          element: <Coupons {...props} />,
        },
        {
          path: 'coupon',
          element: <Coupon {...props} />,
        },
        {
          path: 'shupper-of-the-day',
          element: <ShupperOfTheDayList {...props} />,
        },
        {
          path: 'checkins',
          element: <Checkins {...props} />,
        },
        {
          path: 'checkin/create',
          element: <Checkin {...props} />,
        },
        {
          path: 'approve-shuppers',
          element: <ApproveShuppers {...props} />,
        },
        {
          path: 'logistics',
          children: [
            {
              path: 'pending',
              element: <Pending {...props} />,
            },
            {
              path: 'rejected',
              element: <Rejected {...props} />,
            },
            {
              path: 'canceled',
              element: <Canceled {...props} />,
            },
            {
              path: 'completed',
              element: <Completed {...props} />,
            },
            {
              path: 'refunded',
              element: <Refunded {...props} />,
            },
            {
              path: 'shipping',
              element: <Shipping {...props} />,
            },
            {
              path: 'payment',
              element: <SinglePayment {...props} />,
              children: [
                {
                  path: ':id',
                },
              ],
            },
          ],
        },
        {
          path: 'chat',
          element: <ChatPage {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'reviews',
          element: <BrandReview {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'brands',
          element: <ApproveBrands {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'profile',
          element: <Profile {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'profile/edit',
          element: <EditUserProfile {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'profile/edit-shipping',
          element: <EditShipping {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'profileold',
          element: <ProfileOld {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'payments',
          element: <Orders {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'payment-details',
          element: <PaymentRequests {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'packages',
          element: <Packages {...props} />,
        },
        {
          path: 'package',
          element: <Package {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'request-responses',
          element: <PaymentRequestResponses {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'checkin/show',
          element: <Checkin {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'checkin/edit',
          element: <Checkin {...props} />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: 'settings',
          element: <Settings {...props} />,
        },
        {
          path: '*',
          element: <Navigate to="/" />,
        },
      ],
    },
  ]);

export default AppRoutes;
