
import React from 'react';

const NoPermission = () => {
  React.useEffect(() => {
    setTimeout(() => {
      window.location = '/login';
    }, 5000);
  }, []);

  return (
    <div className="events-container">
      <div className="App-header"></div>
      <div className="message-box error-message">You do not have permission to see this page</div>
    </div>
  );
};

export default NoPermission;
