import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const FieldStyled = withStyles({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #1B1B1B',
        borderRadius: '8px',
        height: '48px',
        margin: '6px 0px 10px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#123494',
      },
    },
  },
})(TextField);