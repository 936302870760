import React, { PureComponent } from "react";
import Dialog from "material-ui/Dialog";
import CircularProgress from "material-ui/CircularProgress";
import "./package.css";
import FlatButton from "material-ui/FlatButton";
import { FormGroup, FormControl, ControlLabel, Col, Row, Checkbox } from "react-bootstrap";
import { List, ListItem } from "material-ui/List";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";


const defaultState = {
    PackagesWeight: 0.5,
    Dimensions: {
        Length: 2.4,
        Width: 2.4,
        Height: 2.4
    }
};

export default class PackageComponent extends PureComponent {

    state = {
        ...defaultState,
        validated: false,
        showDimensionsErrors: false,
        changedAddress: false,
        selected_address: null,
        show_select_address: true,
        chb: true
    };

    close = () => {
        this.props.closePackageModal();
    };

    onRequestSubmitAddress = () => {
            this.setState({
                show_select_address : false
            })
    };
    onRequestSave = () => {
        let address = null;
        
        if (this.state.changedAddress) {
                address = this.props.addresses.find(i => i._id === this.state.selected_address)
        } else {
            address = this.props.addresses[0]
        }

        let Dimensions = {
            PackagesWeight: { ...this.state.PackagesWeight },
            Dimensions: { ...this.state.Dimensions }
        };
        if (this.state.chb) {
            Dimensions = { ...defaultState };
        }
        this.props.savePackage(Dimensions, address);
    };


    changeDimensionsValue = (e) => {
        if (e.target.name === "PackagesWeight") {
            this.setState({
                [e.target.name]: e.target.value
            });
        } else {
            let Dimensions = { ...this.state.Dimensions };
            Dimensions[e.target.name] = e.target.value;
            this.setState({
                Dimensions: Dimensions
            });
        }
    };

    getValidationState = (name) => {
        switch (name) {
            case "PackagesWeight": {
                if (this.state.PackagesWeight < defaultState.PackagesWeight) {
                    return {
                        label: "text-danger",
                        input: "is-invalid"
                    };
                }
                break;
            }
            case "Height": {
                if (this.state.Dimensions.Height < defaultState.Dimensions.Height) {
                    return {
                        label: "text-danger",
                        input: "is-invalid"
                    };
                }
                break;
            }
            case "Width": {
                if (this.state.Dimensions.Width < defaultState.Dimensions.Width) {
                    return {
                        label: "text-danger",
                        input: "is-invalid"
                    };
                }
                break;
            }
            case "Length": {
                if (this.state.Dimensions.Length < defaultState.Dimensions.Length) {
                    return {
                        label: "text-danger",
                        input: "is-invalid"
                    };
                }
                break;
            }
            default : {
                return {
                    label: "",
                    input: ""
                };
            }
        }
        return {
            label: "",
            input: ""
        };
    };

    changeChbValue = (e) => {
        if (e.target.checked) {
            this.setState({
                ...defaultState,
                chb: true
            });
        } else {
            this.setState({
                chb: false
            });
        }
    };
    selectAddress = (event, index, address_id) => {
        this.setState({
            changedAddress: true,
            selected_address: address_id
        });
    };
    lookForView = () => {
        if(this.props.save_error) {
            return <SaveErrorView errors={this.props.save_error.Notification} onRequestClose={this.props.onRequestClose}/>
        }
        if(!this.props.isOpen) return null
        let addresses = this.props.addresses;
        if (this.props.package.length === 1) {
            if (addresses.length > 0) {
                if (addresses.length > 1 && this.state.show_select_address) {
                    return <ShowAddressesView
                        selectAddress={this.selectAddress}
                        addresses={addresses}
                        selected_address={this.state.selected_address}
                        user={this.props.package[0].user}/>;
                } else {
                    return <ShowWeightView  {...this.state}
                                            changeDimensionsValue={this.changeDimensionsValue}
                                            getValidationState={this.getValidationState}
                                            changeChbValue={this.changeChbValue}/>;
                }

            } else {
                return <NoRegisteredAddress user={this.props.package[0].user}/>;
            }
        } else {
            if (addresses.length === 0) {
                if (addresses.length > 1) {

                } else {
                    return <ShowWeightView  {...this.state} changeDimensionsValue={this.changeDimensionsValue}
                                            getValidationState={this.getValidationState}
                                            changeChbValue={this.changeChbValue}/>;
                }
            } else {
                return <NoRegisteredAddress user={this.props.package[0].user}/>;

            }
        }
    };
    checkOnDisabledBeforeSend = () => {
        if (this.props.search_error) {
            return true;
        }

        return this.state.PackagesWeight < defaultState.PackagesWeight ||
            this.state.Dimensions.Width < defaultState.Dimensions.Width ||
            this.state.Dimensions.Height < defaultState.Dimensions.Height ||
            this.state.Dimensions.Length < defaultState.Dimensions.Length;


    };

    renderButtons() {
        if(this.props.save_error) return null
        let arrOfButtons = [
            <FlatButton
                label="close"
                primary
                onClick={this.props.onRequestClose}
            />
        ];
        let addresses = this.props.addresses?this.props.addresses: [];
            if (addresses.length === 1 || !this.state.show_select_address) {
                arrOfButtons.push(<FlatButton
                    label="Save"
                    primary
                    disabled={this.checkOnDisabledBeforeSend()}
                    onClick={() => this.onRequestSave()}
                />);
            } else {
                arrOfButtons.push(
                    <FlatButton
                        label="Continue"
                        primary
                        disabled={!this.state.changedAddress}
                        onClick={() => this.onRequestSubmitAddress()}
                    />
                )
            }
        return arrOfButtons;
    }

    render() {
        const { isOpen, isLoadingSearch, search_error } = this.props;
     
        return <Dialog
            contentStyle={{ width: "600px" }}
            title={"Create Package"}
            modal={true}
            open={isOpen}
            onRequestClose={this.close}
            actions={this.renderButtons()}
        >
            <div className={"package-wrapper"}>
                {isLoadingSearch ?
                    <div className={"package-loader-wrapper"}>
                        <CircularProgress size={80} thickness={5}/>
                    </div> :

                    <div className={"package-inside-info"}>

                        {search_error ? search_error :
                            this.lookForView()
                        }
                    </div>
                }
            </div>
        </Dialog>;
    }
}

function NoRegisteredAddress({ user }) {
    return <p className={"package-no-user"}>User <strong>{`${user.displayName} / ${user.email}`}</strong> doesn't have
        valid address. <b/>
        Please contact with user , so he add address
    </p>;
}

function ShowWeightView({ PackagesWeight, Length, chb, Dimensions, changeDimensionsValue, getValidationState, changeChbValue }) {
    return <React.Fragment>
        <Row sm={12} md={12}>
            <Col sm={6} md={6}>
                <FormGroup
                    controlId="formBasicText"
                >
                    <ControlLabel className={getValidationState("PackagesWeight").label}>Weight</ControlLabel>
                    <FormControl
                        type="number"
                        value={PackagesWeight}
                        name={"PackagesWeight"}
                        disabled={chb}
                        required
                        className={getValidationState("PackagesWeight").input}
                        onChange={changeDimensionsValue}
                    />

                    <FormControl.Feedback/>
                </FormGroup>
            </Col>
            <Col sm={6} md={6}>
                <FormGroup
                    controlId="formBasicText"
                >
                    <ControlLabel className={getValidationState("Length").label}>Length</ControlLabel>
                    <FormControl
                        type="number"
                        className={getValidationState("Length").input}
                        value={Dimensions.Length}
                        name={"Length"}
                        disabled={chb}
                        required
                        onChange={changeDimensionsValue}
                    />

                    <FormControl.Feedback/>
                </FormGroup>
            </Col>
            <Col sm={6} md={6}>
                <FormGroup
                    controlId="formBasicText"
                >
                    <ControlLabel className={getValidationState("Width").label}>Width</ControlLabel>
                    <FormControl
                        type="number"
                        value={Dimensions.Width}
                        name={"Width"}
                        className={getValidationState("Width").input}
                        required
                        disabled={chb}

                        onChange={changeDimensionsValue}
                    />

                    <FormControl.Feedback/>
                </FormGroup>
            </Col>
            <Col sm={6} md={6}>
                <FormGroup
                    controlId="formBasicText"
                >
                    <ControlLabel className={getValidationState("Height").label}>Height</ControlLabel>
                    <FormControl
                        type="number"
                        value={Dimensions.Height}
                        name={"Height"}
                        disabled={chb}
                        className={getValidationState("Height").input}
                        required
                        onChange={changeDimensionsValue}
                    />

                    <FormControl.Feedback/>
                </FormGroup>
            </Col>
            <Col sm={12} md={12}>
                <FormGroup
                    controlId="formBasicText"
                >
                    <ControlLabel>Change data</ControlLabel>
                    <Checkbox
                        type="checkbox"
                        value={chb}
                        name={"chb"}
                        onChange={changeChbValue}
                    />

                    <FormControl.Feedback/>
                </FormGroup>
            </Col>
        </Row>
    </React.Fragment>;
}

function ShowAddressesView({ addresses, user, selected_address, selectAddress }) {
    return <div>
        <p>User <strong>{`${user.displayName} / ${user.email}`}</strong> has more than 1 address</p>
        <div className={"address-wrapper"}>
            <List>
                {addresses.map((address, index) => {
                    return <ListItem
                        key={address._id}
                        primaryText={`${index + 1} - ${address.city}/${address.address}/${address.houseNumber}`}
                        nestedItems={[
                            <p>{address.countryName}</p>,
                            <p>{`${address.city}/${address.address}/${address.houseNumber}`}</p>,
                            <p>Zip code: {address.zipCode}</p>
                        ]}
                    />;
                })}
            </List>
            <div>
                <SelectField
                    floatingLabelText="Please choose address to continue"
                    value={selected_address}
                    onChange={selectAddress}
                >
                    {addresses.map((address, index) => {
                        return <MenuItem key={address._id} value={address._id}
                                         primaryText={`${index + 1} - ${address.city}/${address.address}/${address.houseNumber}`}/>;

                    })}
                </SelectField>
            </div>
        </div>

    </div>;

}

function SaveErrorView({errors, onRequestClose}) {
    return <div>
        <p>Some Errors while saving</p>
        <List>
            {errors.map((error, index) => {
                return <ListItem
                    key={index}
                    primaryText={`${error.Message}`}
                />;
            })}
        </List>
        <FlatButton
            label="close"
            primary
            onClick={onRequestClose}
        />
        
    </div>
}