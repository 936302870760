import React from 'react';
import { connect } from 'react-redux';
import FirstPage from 'material-ui/svg-icons/navigation/first-page';
import LastPage from 'material-ui/svg-icons/navigation/last-page';
import { chatTabsSelector } from '../../reducers/chatsReducer';
import { chatTabsHandlers } from '../../actions/chatsActions';

const CHAT_TAB_WIDTH = 170;

class ChatsTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			displayScrollers: false,
			prevTabsCount: 1,
		};
		this.containerRef = null;
	}

	createRef = elem => this.containerRef = elem;




	componentWillReceiveProps({ chatCount }) {
		if (this.state.prevTabsCount !== chatCount) {
			if (this.containerRef) {
				this.setState({
					prevTabsCount: chatCount,
					displayScrollers: chatCount * CHAT_TAB_WIDTH > this.containerRef.clientWidth
				})
			}
		}
	}

	scrollLeft = () => {
		this.containerRef.scrollLeft -= 9999;
	};

	scrollRight = () => {
		this.containerRef.scrollLeft += 9999;
	};

	render() {
		const {
			displayNames, selectedChat, disconnectFromChat,
			selectedUnique, socket, changeSelectedChat
		} = this.props;
		const { displayScrollers } = this.state;
		return <React.Fragment>
			{
				displayScrollers ?
					<div className="scrollers flxr ac jsb">
						<FirstPage className="custom-button" onClick={this.scrollLeft} />
						<LastPage className="custom-button" onClick={this.scrollRight} />
					</div>
					: null
			}
			<div className="chats-tabs" ref={this.createRef}>

				{
					Object.keys(displayNames).map((val, index) => val !== '1' && displayNames[val] !== null ? (
						<div className={`tab ${selectedChat === val ? 'selected' : ''}`} onClick={changeSelectedChat(val)} key={index}>
							<div className="chat-name">{displayNames[val]}</div>
							<div
								className="close-button"
								onClick={e => {
									e.stopPropagation();
									disconnectFromChat(val, selectedUnique, socket, displayNames, selectedChat);
								}}
							>
								<div>X</div>
							</div>
						</div>
					) : null)
				}
			</div>
		</React.Fragment>
	}
}

export default connect(chatTabsSelector, chatTabsHandlers)(ChatsTabs);
