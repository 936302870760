/*
Kateryna Ivanova
21/03/2018

Helper functions for network requests
*/

import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

export default function uploadImage(key, id, file) {
  const data = new FormData();
  data.append(key, id);
  data.append('image', file);
  return request(`${baseUrl}/api/upload`, {
    method: 'POST',
    body: data,
  });
}

export function uploadItemFile(key, id, file) {
  const data = new FormData();
  data.append(key, id);
  data.append('image', file);
  return request(`${baseUrl}/api/item/upload-file`, {
    method: 'POST',
    body: data,
  });
}

export function uploadEventFile(key, id, file) {
  const data = new FormData();
  data.append(key, id);
  data.append('image', file);
  return request(`${baseUrl}/api/v2/event/upload-file`, {
    method: 'POST',
    body: data,
  });
}

