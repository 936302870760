import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';

import TableData from '../../components/table';
import { tableData } from '../../utils/tableData';

const useStyles = makeStyles({
  btn: {
    textTransform: 'none',
  },
});

export const Shipping = ({ shippingInfo, id }) => {
  const {
    shipping: { headerData },
  } = tableData;
  const navigate = useNavigate()
  const classes = useStyles();
  console.log('shippingInfo', shippingInfo)
  return (
    <div>
      <Grid container direction='row' justifyContent="space-between">
        <Grid item xs={8} >
          <Typography variant="h5">SHIPPING ADDRESS 1</Typography>
        </Grid>
        <Grid item  >
          <Button className={classes.btn} variant="text" color="secondary">
            Remove
          </Button>
          <Button onClick={() => navigate(`edit-shipping/${id}`)} className={classes.btn} variant="text" color="primary">
            Edit
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableData
            tableName="shipping"
            data={[shippingInfo]}
            headerColumn={headerData}
            tablePagination={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};