/*
Kateryna Ivanova
28/03/2018

Sagas for working with a single collection
*/
import { takeLatest, put, call, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import request from '../utils/networkRequest';
import {
  deleteCollectionSuccess,
  deleteCollectionFailed,
  updateCollectionSuccess,
  updateCollectionFailed,
  fetchCollectionFailed,
  fetchCollectionSuccess,
} from '../actions/collectionactions';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* deleteCollectionAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/collection/${action.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteCollectionFailed(response.err.toString()));
  } else {
    yield put(deleteCollectionSuccess());
  }
}

function* updateCollectionAsync(action) {
  const body = {};
  if (action.data.pinnedLocation !== undefined) body.pinnedLocation = action.data.pinnedLocation;
  if (action.data.description !== undefined) body.description = action.data.description;
  if (action.data.longDescription !== undefined) body.longDescription = action.data.longDescription;
  if (action.data.created !== undefined) body.created = action.data.created;
  if (action.data.updated !== undefined) body.updated = action.data.updated;
  if (action.data.duration !== undefined) body.duration = action.data.duration;
  if (action.data.items !== undefined) body.items = action.data.items;
  if (action.data.owner) body.owner = action.data.owner;

  const response = yield call(
    request,
    `${baseUrl}/api/collection/${action.data._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateCollectionFailed(response.err.toString()));
  } else {
    yield put(updateCollectionSuccess());
  }
}

function* fetchCollectionAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/collection/${action.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchCollectionFailed(response.err.toString()));
  } else {
    yield put(fetchCollectionSuccess(response.data));
  }
}

function* deleteCollection() {
  yield takeLatest([`${types.DELETE_COLLECTION}`], deleteCollectionAsync);
}

function* updateCollection() {
  yield takeLatest([`${types.UPDATE_COLLECTION}`], updateCollectionAsync);
}

function* fetchCollection() {
  yield takeLatest([`${types.FETCH_COLLECTION}`], fetchCollectionAsync);
}

export default function* collectionSaga() {
  yield all([
    deleteCollection(),
    updateCollection(),
    fetchCollection(),
  ]);
}
