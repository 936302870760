import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';
import { createSelector } from 'reselect';



const initialState = {
    dataUser: null,
    brands: null,
    newImage: null,
    tiers: null,
    extantion: null,
    saveUserSuccess: null,
};

const profileReducer = createReducer({
    [types.SET_USER_DATA]: (state, { payload }) => ({
        ...state,
        dataUser: payload.data,
    }),
    [types.FETCH_BRANDS_SUCCESS]: (state, { payload }) => ({
        ...state,
        brands: payload.data,
    }),
    [types.UPDATE_PROFILE_IMAGE_SUCCESS]: (state, { payload }) => ({
        ...state,
        newImage: payload.data.newImage,
    }),
    [types.FETCH_TIERS_SUCCESS]: (state, { payload }) => ({
        ...state,
        tiers: payload,
    }),
    [types.SET_EXTANTION_DATA]: (state, { payload }) => ({
        ...state,
        extantion: payload,
    }),
    [types.SAVE_PROFILE_DATA_SUCCEESS]: (state, { payload }) => ({
        ...state,
        saveUserSuccess: payload,
    }),

}, initialState);


// SELECTORS


const profileStateSelector = _ => _.profileReducer;


export const getProps = createSelector(
    profileStateSelector,

    (
        { dataUser, brands, newImage, tiers, extantion, saveUserSuccess },
    ) => ({
        dataUser, brands, newImage, tiers, extantion, saveUserSuccess
    })
);

export default profileReducer;
