/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../../actions/actionTypes';
import request from '../../utils/networkRequest';
import {
  fetchRejectedFailed, fetchRejectedSuccess
} from '../../actions/logistics/rejectedactions';
import getBaseUrl from '../../utils/envSetup';

const baseUrl = getBaseUrl();

const selectLogisticsState = state => state.logistics.rejected;

function* fetchRejectedAsync() {
  const logisticsState = yield select(selectLogisticsState);
  const sorting = Object.keys(logisticsState.sort).map(key => `&sort[${key}]=${logisticsState.sort[key]}`).join('');
  const startDate = logisticsState.startDate ? `&startDate=${logisticsState.startDate}` : '';
  const endDate = logisticsState.endDate ? `&endDate=${logisticsState.endDate}` : '';
  const searchQuery = logisticsState.searchQuery ? `&userName=${logisticsState.searchQuery}` : '';
  const response = yield call(
    request,
    `${baseUrl}/api/logistics/rejected?limit=${logisticsState.limit}&skip=${logisticsState.offset}${sorting}${startDate}${endDate}${searchQuery}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchRejectedFailed(response.err.toString()));
  } else {
    yield put(fetchRejectedSuccess(response.data));
  }
}

function* fetchRejected() {
  yield takeLatest([`${types.LOGISTICS.FETCH_REJECTED}`, `${types.LOGISTICS.FETCH_MORE_REJECTED}`,
    `${types.LOGISTICS.FETCH_REJECTED_SORT_CHANGED}`, `${types.LOGISTICS.FETCH_REJECTED_DATERANGE_CHANGED}`,
  ], fetchRejectedAsync);
}

export default function* rejectedsaga() {
  yield all([
    fetchRejected(),
  ]);
}
