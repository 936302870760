/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 17/04/2018.
 */

import types from '../actions/actionTypes';

const initialState = {
  offset: 0,
  limit: 100,
  logs: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  sort: {},
};

export default function securityLogsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LOGS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        logs: [],
        totalCount: 0,
      });
    case types.FETCH_MORE_LOGS:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_LOGS_SUCCESS: {
      const logs = [].concat(state.logs).concat(action.data.logs);
      return Object.assign({}, state, {
        offset: state.offset + state.limit,
        totalCount: action.data.count,
        logs,
        hasMore: logs.length < action.data.count,
        isLoading: false,
      });
    }
    case types.FETCH_LOGS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        hasMore: false,
      });
    default:
      return state;
  }
}
