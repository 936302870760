import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import 'react-phone-input-2/lib/style.css'
import { FieldStyled } from '../styleField'
import { Field, useField } from 'formik';
import { countries } from '../../../utils/countries';
import { zones } from '../../../utils/zones';
import { userLocalStorage } from "../../../config/local_storage";

const useStyles = makeStyles((theme) => ({
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    }
  },
}));

export const TextFields = ({ ...props }) => {
  const classes = useStyles()
  const [field, meta] = useField(props);
  return (
    <Field
      as={FieldStyled}
      fullWidth='true'
      placeholder=""
      variant='outlined'
      name={field?.name}
      error={props.touched[field?.name] && Boolean(props.errors[field?.name])}
      helperText={props.touched[field?.name] && props.errors[field?.name]}
      InputProps={{
        classes: { input: classes.input }
      }}
    />
  )
}

export const Country = props => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;

  return (
    <Field
      {...field}
      {...props}
      value={field?.value}
      placeholder='select'
      as={FieldStyled}
      select
      fullWidth='true'
      variant='outlined'
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: () => null,
          style: { marginTop: '50px', height: 300 }
        },
      }}>
      {countries?.map((item, index) => (
        <MenuItem value={item?.iso2_name} key={`${item.id}`}>{item?.iso2_name}</MenuItem>
      ))}
    </Field>
  );
};

export const Zones = props => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;

  return (
    <>
      <Typography>State</Typography>
      <Field
        {...field}
        {...props}
        value={field?.value || ''}
        as={FieldStyled}
        select
        fullWidth='true'
        variant='outlined'
        error={props.touched[field?.name] && Boolean(props.errors[field?.name])}
        helperText={props.touched[field?.name] && props.errors[field?.name]}
        SelectProps={{
          MenuProps: {
            getContentAnchorEl: () => null,
            style: { maxHeight: 300 }
          },
        }}>
        {zones?.filter(item => item?.iso2_name !== null).map((item, index) => (
          <MenuItem value={item.iso2_name} key={item?.id}>{item.iso2_name}</MenuItem>
        ))}
      </Field>
    </>
  );
};








