export const filtersObj = {
  boFilters: [
    {
      id: 0,
      title: 'Type',
      filters: [
        {id: 1, label: 'Admin', value: 'type'},
        {id: 2, label: 'local users', value: 'type'},
        {id: 3, label: 'HQ users', value: 'type'},
      ],
    },
    {
      id: 1,
      title: 'Status',
      filters: [
        {id: 0, label: 'All', value: 'status'},
        {id: 1, label: 'Active', value: 'status'},
        {id: 2, label: 'Pending', value: 'status'},
      ],
    },
    {id: 2, title: 'Country', filters: [{}]},
  ],
  userFilters: [
    {
      id: 0,
      title: 'Type',
      filters: [
        {id: 0, label: 'All', value: 'type'},
        {id: 1, label: 'user', value: 'type'},
        {id: 2, label: 'shupper', value: 'type'},
      ],
    },
    {
      id: 1,
      title: 'Status',
      filters: [
        {id: null, label: 'all', value: 'status'},
        {id: 1, label: 'enabled', value: 'status'},
        {id: 0, label: 'disabled', value: 'status'},
      ],
    },
    {id: 2, title: 'Country', filters: [{value: 'country'}]},
  ],
  paymentFilters: [
    {
      id: 0,
      title: 'Payment Status',
      filters: [
        {id: 1, label: 'Pending', value: 'payment'},
        {id: 2, label: 'Complete', value: 'payment'},
        {id: 3, label: 'Canceled', value: 'payment'},
      ],
    },
    {
      id: 1,
      title: 'Package Status',
      filters: [
        {id: 1, subId: 1, label: 'Open', value: 'package'},
        {id: 2, subState: 1, label: 'Closed - Missing information', value: 'package'},
        {id: 3, subState: 2, label: 'Closed - Ready for Shipping', value: 'package'},
        {id: 4, subState: 3, label: 'Closed - Pickup Scheduled', value: 'package'},
        {id: 5, subState: 4, label: 'Closed - Dropped', value: 'package'},
        {id: 6, subId: 3, label: 'Sent', value: 'package'},
        {id: 7, subId: 4, label: 'Received', value: 'package'},
        {id: 8, subId: 5, label: 'Confirmed', value: 'package'},
        {id: 9, subId: 6, label: 'Disputed', value: 'package'},
      ],
    },
    {
      id: 2,
      title: 'Shupper country',
      value: 'shupper',
      filters: [{id: 0, value: 'shupper country'}],
    },
    {
      id: 3,
      value: 'user',
      title: 'User country',
      filters: [{id: 0, value: 'user country'}],
    },
  ],
  itemV2Filters: [
    {
      id: 1,
      title: 'Brands',
      value: 'brands'
    },
    {
      id: 2,
      title: 'Item country',
      value: 'item',
      filters: [{id: 0, value: 'item country'}],   
    }
  ],
  eventsV2Filters: [
    {
      id: 1,
      title: 'Brands',
      value: 'brands'
    },
    {
      id: 2,
      title: 'Shupper country',
      value: 'shupper',
      filters: [{id: 0, value: 'shupper'}],   
    }
  ],
  packageFilters: [
    {
      id: 0,
      title: 'Package Status',
      filters: [
        {id: 1, subId: 1, label: 'Open', value: 'package'},
        {id: 2, subState: 1, label: 'Closed - Missing information', value: 'package'},
        {id: 3, subState: 2, label: 'Closed - Ready for Shipping', value: 'package'},
        {id: 4, subState: 3, label: 'Closed - Pickup Scheduled', value: 'package'},
        {id: 5, subState: 4, label: 'Closed - Dropped', value: 'package'},
        {id: 6, subId: 3, label: 'Sent', value: 'package'},
        {id: 7, subId: 4, label: 'Received', value: 'package'},
        {id: 8, subId: 5, label: 'Confirmed', value: 'package'},
        {id: 9, subId: 6, label: 'Disputed', value: 'package'},
      ],
    },
    {
      id: 1,
      title: 'Shupper country',
      value: 'shupper',
      filters: [{id: 0, value: 'shupper country'}],
    },
    {
      id: 2,
      value: 'user',
      title: 'User country',
      filters: [{id: 0, value: 'user country'}],
    },
  ],
};
