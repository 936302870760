import { paymentColors, tableColors } from "../utils/colorPalette"

export const CustomCell = ({ id }) => {
  const pickColor = tableColors?.find(item => item?.status === id ||
    item?.email === 'email'
  )

  const eventStatus = {
    color: pickColor?.color,

  }
  return <div style={eventStatus}>{id}</div>
}

export const PaymentCell = ({ id }) => {
  const pickColor = paymentColors?.find(item => item?.payment === id
  )
  const eventStatus = {
    color: pickColor?.color,
    textDecoration: pickColor?.underline && 'underline'
  }
  return <div style={eventStatus}>{id}</div>
}