import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';
import { createSelector } from 'reselect';



const initialState = {
    brands: [],
    approveSuccess: null,
    declineSuccess: null,
    approveError: null,
    status: "pending",
    isLoading: false,
    pendingBrands: []

};

const approveBrandsReducer = createReducer({
    [types.FETCH_BRANDS_FOR_APPROVE_SUCCESS]: (state, { payload }) => ({
        ...state,
        brands: payload.data,
        isLoading: false,
    }),
    [types.FETCH_BRANDS_FOR_APPROVE]: (state) => ({
        ...state,
        isLoading: true,
    }),
    [types.FETCH_PENDING_BRANDS_FOR_APPROVE_SUCCESS]: (state, { payload }) => ({
        ...state,
        pendingBrands: payload.data,
    }),

    [types.APPROVE_BRAND_SUCCESS]: (state) => ({
        ...state,
        approveSuccess: true,
    }),
    [types.DECLINE_BRAND_SUCCESS]: (state) => ({
        ...state,
        declineSuccess: true,
    }),
    [types.APPROVE_BRAND_ERROR]: (state) => ({
        ...state,
        approveError: true,
    }),
    [types.RESET_APPROVE_BRAND]: (state) => ({
        ...state,
        declineSuccess: false,
        approveSuccess: false,
        approveError: false,
    }),
    [types.CHANGE_STATUS]: (state, { payload }) => ({
        ...state,
        status: payload.status,
    }),


}, initialState);


// SELECTORS


const approveBrandsStateSelector = _ => _.approveBrandsReducer;


export const getProps = createSelector(
    approveBrandsStateSelector,

    (
        { brands, isLoading, approveSuccess, declineSuccess, approveError, status, pendingBrands },
    ) => ({
        brands, isLoading, approveSuccess, declineSuccess, approveError, status, pendingBrands
    })
);

export default approveBrandsReducer;
