import React from "react";

const Tab = ({ caption, onClick, index, active }) => (
  <div
    className={`flxr ac jc tab-item ${active ? "active" : null}`}
    onClick={onClick(index)}
    key={index}
  >
    <span>{caption}</span>
  </div>
);

export default class TabsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: props.defaultTab || 0
    };
  }

  selectTab = (onChange, disabled, caption) => index => () => {
    if (disabled.indexOf(caption) === -1) {
      if (this.state.selectedTab !== index) {
        this.setState({ selectedTab: index });
        onChange(index);
      }
    }
  };

  render() {
    const {
      tabs = [],
      contents = [],
      width,
      onChange = () => {},
      disabled = []
    } = this.props;
    const { selectedTab } = this.state;

    return (
      <React.Fragment>
        <div
          className="flxr ac jsb tabs-container"
          style={{ width: width ? width : "400px" }}
        >
          {tabs.map((caption, key) => (
            <Tab
              onClick={this.selectTab(onChange, disabled, caption)}
              caption={caption}
              key={key}
              index={key}
              active={key === selectedTab}
            />
          ))}
        </div>
        <div className="tabbed-content">
          {Array.isArray(contents) ? contents[selectedTab] : contents}
        </div>
      </React.Fragment>
    );
  }
}
