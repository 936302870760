import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchPackagesFailed, fetchPackagesSuccess } from '../actions/packagesActions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectPackagesState = state => state.packagesReducer;

function* fetchPackagesAsync(action) {
  const packagesState = yield select(selectPackagesState);

  const { offset, limit } = packagesState
  
  const skipLimit = `?skip=${offset}&limit=${limit}`
  const q = packagesState.searchQuery ? `&q=${packagesState.searchQuery}` : ''
  const state = packagesState.stateFilter ? `&state=${packagesState.stateFilter}` : ''
  const substate = packagesState.substateFilter ? `&substate=${packagesState.substateFilter}` : ''
  const fromCountryId= packagesState.fromCountryFilter ? `&fromCountryId=${packagesState.fromCountryFilter}` : ''
  const toCountryId = packagesState.toCountryFilter ? `&toCountryId=${packagesState.toCountryFilter}` : ''
  const fromDate = packagesState.fromDate ? `&fromDate=${packagesState.fromDate}` : ''
  const toDate = packagesState.toDate ? `&toDate=${packagesState.toDate}` : ''
  const dateFilter = packagesState?.dateFilter.from ? `&fromDate=${packagesState?.dateFilter?.from}&toDate=${packagesState?.dateFilter?.to}` : ''

  const filters = `${q}${state}${substate}${fromCountryId}${toCountryId}${fromDate}${toDate}${dateFilter}`

  const response = yield call(
    request,
    `${baseUrl}/api/v2/package${skipLimit}${filters}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchPackagesFailed(response.err.toString()));
  } else {
    yield put(fetchPackagesSuccess(response.data));
  }
}

function* fetchPackages() {
  yield takeLatest([types.FETCH_PACKAGES, types.FETCH_MORE_PACKAGES], fetchPackagesAsync);
}

export default function* packages() {
  yield all([fetchPackages()]);
}
