/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 08/05/2018.
 */
import { takeLatest, put, call, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchCouponSuccess, fetchCouponFailed, updateCouponFailed,
  updateCouponSuccess, deleteCouponSuccess, deleteCouponFailed } from '../actions/couponactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* fetchCouponAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/coupon/${action.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchCouponFailed(response.err.toString()));
  } else {
    yield put(fetchCouponSuccess(response.data));
  }
}

function* updateCouponAsync(action) {
  const body = {
    code: action.coupon.code,
    discountValue: action.coupon.discountValue,
    isEnabled: action.coupon.isEnabled,
	discountType: action.coupon.discountType,
  };
  const response = yield call(
    request,
    `${baseUrl}/api/coupon/${action.coupon._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  if (response.err) {
    yield put(updateCouponFailed(response.err.toString()));
  } else {
    yield put(updateCouponSuccess());
  }
}

function* createCouponAsync(action) {
  const response = yield call(
    request,
    `${baseUrl}/api/coupon`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: action.coupon.code,
        discountValue: action.coupon.discountValue,
        isEnabled: action.coupon.isEnabled,
	    discountType: action.coupon.discountType,
      }),
    },
  );

  if (response.err) {
    yield put(updateCouponFailed(response.err.toString()));
    return;
  }

  yield put(updateCouponSuccess());
}

function* deleteCouponAsync(action) {
  const couponIds = action.ids.join(',');
  const response = yield call(
    request,
    `${baseUrl}/api/coupon?ids=${couponIds}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(deleteCouponFailed(response.err.toString()));
  } else {
    yield put(deleteCouponSuccess());
  }
}

function* fetchItems() {
  yield takeLatest(types.FETCH_COUPON, fetchCouponAsync);
}

function* updateCoupon() {
  yield takeLatest(types.UPDATE_COUPON, updateCouponAsync);
}

function* createCoupon() {
  yield takeLatest(types.CREATE_COUPON, createCouponAsync);
}

function* deleteCoupon() {
  yield takeLatest(types.DELETE_COUPON, deleteCouponAsync);
}

export default function* couponSaga() {
  yield all([
    fetchItems(), updateCoupon(), createCoupon(), deleteCoupon(),
  ]);
}
