import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { tableData } from '../../utils/tableData';
import TableData from '../table/index';

const PaymentRequestRecentPayments = ({ getCurrencies, exchangeRate, attempts, successfulInfo, total }) => {
  const {
    recentPaymentsAttempts: { headerData },
  } = tableData;
  console.log('succesfukk ubfi', successfulInfo)
  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" style={{ margin: '24px 0px 16px' }}>
        <Typography variant="h5">RECENT PAYMENT ATTEMPTS</Typography>
      </Grid>
      <TableData
        tableName="paymentRecentAttemptsOrders"
        data={attempts}
        subData={successfulInfo}
        headerColumn={headerData}
        tablePagination={false}
        totalPrice={total}
        getCurrencies={getCurrencies}
        exchangeRate={exchangeRate}
      />
    </div>
  );
};

export default PaymentRequestRecentPayments;
