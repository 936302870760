import React from 'react';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import withConfirm from '../utils/withConfirm';

export default class SelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        }
    }

    changeRole = (val, action, close) => {
        withConfirm(() => {
            const prevStatus = this.state.value;
            this.setState({
                value: val.status,
            });
            action({ prevStatus, ...val });
            close();
            this.setState({
                value: this.props.value,
            });
        });
    }

    render() {
        const {
            handleChange, items, type, multiple, position, payment, display, close
        } = this.props;
        return (
            <Dialog
                contentStyle={{ width: '300px' }}
                title={'Change payment status'}
                modal={false}
                open={display}
                onRequestClose={close}
            >
                <DropDownMenu
                    value={this.state.value}
                    onChange={(event, ley, val) => val !== 'status' ? this.changeRole({ status: val, position, payment }, handleChange, close) : null}
                    className="filter-dropdown"
                    multiple={multiple}
                >
                    <MenuItem value={'status'} primaryText={type} />
                    {
                        items.map(item => (
                            <MenuItem key={item} value={item} primaryText={item} />
                        ))
                    }
                </DropDownMenu>
            </Dialog>
        );
    }
}