/*
Kateryna Ivanova
20/03/2018

Sagas for working with evets list
*/
import { takeLatest, put, call, all, select, takeEvery } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchEventsSuccess, fetchEventsFailed, actionDone } from '../actions/eventsactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectEventsState = state => state.eventsReducer;

function* fetchEventsAsync() {
  const eventsState = yield select(selectEventsState);
  const sorting = Object.keys(eventsState.sort).map(key => `&sort[${key}]=${eventsState.sort[key]}`).join('');
  const searchQuery = eventsState.searchQuery ? `&search=${eventsState.searchQuery}` : '';
  const startDate = eventsState.startDate ? `&startDate=${eventsState.startDate}` : '';
  const endDate = eventsState.endDate ? `&endDate=${eventsState.endDate}` : '';
  const type = eventsState.type ? `&type=${eventsState.type}` : '';
  const response = yield call(
    request,
    `${baseUrl}/api/event?limit=${eventsState.limit}&skip=${eventsState.offset}${searchQuery}${sorting}${startDate}${endDate}${type}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchEventsFailed(response.err.toString()));
  } else {
    yield put(fetchEventsSuccess(response.data));
  }
}

function* reshuffleEventItems({ payload }) {
  const response = yield call(
    request,
    `${baseUrl}/api/event/shuffle/${payload.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) console.error(response.err);
    yield put(actionDone());
}

function* fetchItems() {
  yield takeLatest([types.FETCH_EVENTS, types.FETCH_MORE_EVENTS, types.FETCH_EVENTS_SORT_CHANGED,
    types.DELETE_EVENT_SUCCESS, types.UPDATE_EVENT_SUCCESS, types.FETCH_EVENTS_DATERANGE_CHANGED,
    types.FETCH_EVENTS_FILTER_BY_TYPE], fetchEventsAsync);
}

function* watchReshuffleRequest() {
  yield takeEvery(types.RESHUFFLE_EVENT, reshuffleEventItems);
}

export default function* eventsSaga() {
  yield all([
    fetchItems(),
    watchReshuffleRequest()
  ]);
}
