import React from 'react';
import TimetableDay from './TimetableDay';

const TimeTable = ({ days, setDayParameters }) => (
    <div className="flxr ac jc timeTable">
    {
        Object.keys(days).map((dayKey, key) =>
            <TimetableDay
	            from={days[dayKey].from}
	            to={days[dayKey].to}
	            popupType={days[dayKey].popupType}
	            nextDay={days[dayKey].nextDay}
	            key={key}
	            day={dayKey}
	            radioId={`popupDay${key}`}
	            setDayParameters={setDayParameters(dayKey)}
            />
        )
    }
    </div>
);

export default TimeTable;
