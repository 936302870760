import React from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
export default class SelectComponent extends React.PureComponent {
    handleChange = (event, index, value) => {
        if(value !== this.props.selectedShipper.value) this.props.selectShipper(this.props.shippers[index])
    };
    
    render() {
        return <SelectField
            value={this.props.selectedShipper.value}
            style={{width : this.props.width}}
            onChange={this.handleChange}
            autoWidth={true}
            maxHeight={200}
        >
            {this.props.shippers.map(shipper => <MenuItem value={shipper.value} key={shipper.value} primaryText={shipper.label} />)}
        </SelectField>
    }

}