/*
Kateryna Ivanova
20/03/2018

Redux actions for events list loading
*/
import types from './actionTypes';

export const fetchStore = id => ({
  type: types.FETCH_STORE,
  id,
});

export const fetchStoreSuccess = data => ({
  type: types.FETCH_STORE_SUCCESS,
  data,
});

export const fetchStoreFailed = reason => ({
  type: types.FETCH_STORE_FAILED,
  reason,
});

export const updateStore = store => ({
  type: types.UPDATE_STORE,
  store,
});

export const createStore = store => ({
  type: types.CREATE_STORE,
  store,
});

export const updateStoreSuccess = () => ({
  type: types.UPDATE_STORE_SUCCESS,
});

export const updateStoreFailed = reason => ({
  type: types.UPDATE_STORE_FAILED,
  reason,
});

export const deleteStore = ids => ({
  type: types.DELETE_STORE,
  ids,
});

export const deleteStoreSuccess = () => ({
  type: types.DELETE_STORE_SUCCESS,
});

export const deleteStoreFailed = reason => ({
  type: types.DELETE_STORE_FAILED,
  reason,
});
