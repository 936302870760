import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import req from '../utils/networkRequest';
import uploadImage from '../utils/networkutil';

import {
  setUserData, fetchBrandsSuccess, saveProfileDataSuccess, setExtantionData,
  updateProfileImageSuccess, fetchTiersSuccess,
  saveExtantionDataSuccess
} from '../actions/profileActions';
import { config } from '../utils/envSetup';
const API_URL = config('baseUrl');


function* getUsersData({ payload }) {
  const { id } = payload;

  const response = yield call(
    req,
    `${API_URL}/api/user/${id}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  yield put(setUserData(response.data ? response.data : {}));
}
function* getExtantionData({ payload }) {

  const response = yield call(
    req,
    `${API_URL}/api/user/extention/${payload}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  yield put(setExtantionData(response.data ? response.data : {}));
}
function* saveExtantionData({ payload }) {
  const { data, id } = payload;

  const response = yield call(req, `${API_URL}/api/user/extention/${id}`, {
    method: 'POST',
    body: JSON.stringify({ extentionQuery: { brands: data.brands, tier: data.tier } }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  });

  yield put(saveExtantionDataSuccess(response.data ? response.data : {}));
}
function* fetchBrands({ payload }) {
  const { query } = payload;

  const response = yield call(
    req,
    `${API_URL}/api/event?type=checkin&&search=${query || ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  yield put(fetchBrandsSuccess(response.data ? response.data : {}));
}
function* fetchTiers() {

  const response = yield call(
    req,
    `${API_URL}/api/candidates/tiers`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  yield put(fetchTiersSuccess(response.data ? response.data : {}));
}

function* updateUserProfileImageAsync(action) {

  const { newImage, _id } = action.payload.data;
  let profileImageURL = null;
  if (newImage) {
    const response = yield call(uploadImage, 'event', _id, newImage);
    profileImageURL = response.data.imgUrl;
  }
  if (profileImageURL) yield put(updateProfileImageSuccess({ newImage: profileImageURL, _id }))
}



function* saveUserData({ payload }) {
  const { data, id } = payload;

  const response = yield call(
    req,
    `${API_URL}/api/user/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );
  if (response.err) console.err(response.err);
  yield put(saveProfileDataSuccess(response));
}





function* watchGetUserData() {
  yield takeLatest(types.GET_USER_DATA, getUsersData);
}
function* watchSaveUserData() {
  yield takeLatest(types.SAVE_PROFILE_DATA, saveUserData);
}
function* watchFetchBrands() {
  yield takeLatest(types.FETCH_BRANDS, fetchBrands);
}

function* updateUserProfileImage() {
  yield takeEvery(types.UPDATE_PROFILE_IMAGE, updateUserProfileImageAsync);
}
function* watchFetchTiers() {
  yield takeEvery(types.FETCH_TIERS, fetchTiers);
}
function* watchGetExtantionData() {
  yield takeEvery(types.GET_EXTANTION_DATA, getExtantionData);
}
function* watchSaveExtantionData() {
  yield takeEvery(types.SAVE_EXTANTION_DATA, saveExtantionData);
}
export default function* chatsSaga() {
  yield all([
    watchGetUserData(),
    watchSaveUserData(),
    watchFetchBrands(),
    // watchSaveExtensionData(),
    updateUserProfileImage(),
    watchFetchTiers(),
    watchGetExtantionData(),
    watchSaveExtantionData()
  ]);
}
