/*
Kateryna Ivanova
20/03/2018

Sagas for working with a list of stores
*/
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import { fetchStoresSuccess, fetchStoresFailed } from '../actions/storesactions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

const selectStoresState = state => state.storesReducer;

function* fetchStoresAsync() {
  const storesState = yield select(selectStoresState);
  const sorting = Object.keys(storesState.sort).map(key => `&sort[${key}]=${storesState.sort[key]}`).join('');
  const searchQuery = storesState.searchQuery ? `&name=${storesState.searchQuery}` : '';
  const startDate = storesState.startDate ? `&startDate=${storesState.startDate}` : '';
  const endDate = storesState.endDate ? `&endDate=${storesState.endDate}` : '';
  const response = yield call(
    request,
    `${baseUrl}/api/store?limit=${storesState.limit}&skip=${storesState.offset}${searchQuery}${sorting}${startDate}${endDate}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchStoresFailed(response.err.toString()));
  } else {
    yield put(fetchStoresSuccess(response.data));
  }
}

function* fetchItems() {
  yield takeLatest([`${types.FETCH_STORES}`, `${types.FETCH_MORE_STORES}`, `${types.FETCH_STORES_SORT_CHANGED}`,
    `${types.DELETE_STORE_SUCCESS}`, `${types.UPDATE_STORE_SUCCESS}`, `${types.FETCH_STORES_DATERANGE_CHANGED}`], fetchStoresAsync);
}

export default function* storesSaga() {
  yield all([
    fetchItems(),
  ]);
}
