import { Dialog } from "material-ui"

export const DialogCommon = ({ children, contentStyle, title, actions, modal, open, onRequestClose }) => {
  return (
    <Dialog
      contentStyle={contentStyle}
      title={title}
      actions={actions || null}
      modal={modal}
      open={open}
      onRequestClose={onRequestClose}
    >
      {children}
    </Dialog>
  )
}
