import types from './actionTypes';

export const fetchAllUsers = data => ({
  data,
  type: types.FETCH_ALL_USERS,
});

export const fetchAllUsersSuccess = data => ({
  type: types.FETCH_ALL_USERS_SUCCESS,
  data,
});

export const fetchAllUsersFailed = reason => ({
  type: types.FETCH_ALL_USERS_FAILED,
  reason,
});

export const fetchUsers = (query, roles, roleFilter, statusFilter, genderFilter) => ({
  type: types.FETCH_USERS,
  query,
  roles,
  roleFilter,
  statusFilter,
  genderFilter,
});

export const fetchMoreUsers = () => ({
  type: types.FETCH_MORE_USERS,
});

export const fetchUsersSuccess = data => ({
  type: types.FETCH_USERS_SUCCESS,
  data,
});

export const fetchUsersFailed = reason => ({
  type: types.FETCH_USERS_FAILED,
  reason,
});

export const fetchUsersSortChanged = (column, direction) => ({
  type: types.FETCH_USERS_SORT_CHANGED,
  column,
  direction,
});

export const updateUser = data => ({
  type: types.UPDATE_USER,
  data,
});

export const updateUserSuccess = () => ({
  type: types.UPDATE_USER_SUCCESS,
});

export const updateUserFailed = reason => ({
  type: types.UPDATE_USER_FAILED,
  reason,
});

export const bulkUpdateUsers = (ids, data) => ({
  type: types.BULK_UPDATE_USERS,
  ids,
  data,
});

export const clearUsersFilters = () => ({
  type: types.CLEAR_USERS_FILTERS,
});

export const regeneratePassword = id => ({
  type: types.USER_REGENERATE_PASSWORD,
  id,
});

export const regeneratePasswordSuccess = newPassword => ({
  type: types.USER_REGENERATE_PASSWORD_SUCCESS,
  newPassword,
});

export const regeneratePasswordFailed = reason => ({
  type: types.USER_REGENERATE_PASSWORD_FAILED,
  reason,
});

export const clearRegeneratedPassword = () => ({
  type: types.CLEAR_REGENERATED_PASSWORD,
});

export const updateUserProfileImage = user => ({
  type: types.USER_UPDATE_PROFILE_IMAGE,
  user,
});

export const getShuppersOfTheDay = () => ({
  type: types.GET_SHUPPERS_OF_THE_DAY,
});

export const getShuppersOfTheDaySuccess = data => ({
  type: types.GET_SHUPPERS_OF_THE_DAY_SUCCESS,
  data,
});

export const getShuppersOfTheDayFailed = reason => ({
  type: types.GET_SHUPPERS_OF_THE_DAY_FAILED,
  reason,
});

export const setShupperOfTheDay = (weekday, shupper) => ({
  type: types.SET_SHUPPER_OF_THE_DAY,
  weekday,
  shupper,
});

export const setShupperOfTheDaySuccess = () => ({
  type: types.SET_SHUPPER_OF_THE_DAY_SUCCESS,
});

export const setShupperOfTheDayFailed = reason => ({
  type: types.SET_SHUPPER_OF_THE_DAY_FAILED,
  reason,
});

export const removeShupperOfTheDay = weekday => ({
  type: types.REMOVE_SHUPPER_OF_THE_DAY,
  weekday,
});

export const removeShupperOfTheDaySuccess = () => ({
  type: types.REMOVE_SHUPPER_OF_THE_DAY_SUCCESS,
});

export const removeShupperOfTheDayFailed = reason => ({
  type: types.REMOVE_SHUPPER_OF_THE_DAY_FAILED,
  reason,
});

export const getOnlyShuppers = name => ({
  type: types.GET_ONLY_SHUPPERS,
  name,
});

export const getOnlyShuppersAndStores = name => ({
  type: types.GET_ONLY_SHUPPERS_AND_STORES,
  name,
});

export const getOnlyShuppersSuccess = data => ({
  type: types.GET_ONLY_SHUPPERS_SUCCESS,
  data,
});
export const getTips = (id, year) => ({
  type: types.GET_TIPS,
  payload: {
    id,
    year,
  },
});
export const getTipsSuccess = data => ({
  type: types.GET_TIPS_SUCCESS,
  payload: data,
});

export const getOnlyShuppersFailed = reason => ({
  type: types.GET_ONLY_SHUPPERS_FAILED,
  reason,
});

export const moveItemsBetweenUsers = (from, to) => ({
  type: types.MOVE_ITEMS_FROM_USER_TO_ANOTHER,
  payload: {
    from,
    to,
  },
});

export const stopLoadingUsers = () => ({
  type: types.STOP_LOADING_USERS,
});
export const setActiveTips = tips => ({
  type: types.SET_ACTIVE_TIPS,
  payload: tips,
});
