import React, { useState } from 'react';
import { IconButton, makeStyles, Menu } from '@material-ui/core';
import { Checkbox, Collapse, FormControlLabel, List, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import filter from '../../static/images/filter_button.svg'
import { countries } from '../../utils/countries';
import { CountryFilterField } from './country';
import BrandFilterField from './brand';

const useStyles = makeStyles({
  btnCancel: {
    textTransform: 'none'
  },
  btnApplyFilters: {
    textTransform: 'none',
    background: '#EDFF00',
    border: '2px solid #141b41',
    height: 32,
    padding: 15,
    '&:hover': {
      backgroundColor: '#EDFF00',
    }
  },
  filterMenu: {
    maxHeight: 400,
    width: 180,
    borderRadius: 10
  }
});

export const Filters = React.memo(({
  filters,
  name, countryName, status,
  type, filterItems,
  applyFilters, handleChange,
}) => {

  const classes = useStyles()
  const [filterMenu, setFilterMenu] = useState(null);
  const [showFilter, setShowFilter] = useState({ show: {} })
  const [inputText, setInputText] = useState("");
  const [shupperInputText, setShupperInputText] = useState('')
  const [userInputText, setUserInputText] = useState('')

  const filterBtnsCustom = {
    width: name && 200
  }
  const inputHandler = (e, name) => {
    const lowerCase = e.target.value.toLowerCase();
    const shupperText = name === 'shupper' ? e.target.value.toLowerCase() : shupperInputText;
    const userText = name === 'user' ? e.target.value.toLowerCase() : userInputText;
    setInputText(lowerCase);
    setShupperInputText(shupperText)
    setUserInputText(userText)
  };

  const country = countries?.filter((el) => {
    if (inputText === '') {
      return el.name;
    }
    else {
      return el.name.toLowerCase().includes(inputText)
    }
  })
  const shupperCountry = countries?.filter((el) => {
    switch (shupperInputText) {
      case '':
        return el.name
        break
      default:
        return el.name.toLowerCase().includes(shupperInputText)
        break
    }
  })
  const userCountry = countries?.filter((el) => {
    switch (userInputText) {
      case '':
        return el.name
        break
      default:
        return el.name.toLowerCase().includes(userInputText)
        break
    }
  })

  const handleClick = (id) => {
    setShowFilter((prevState) => ({
      ...prevState,
      show: {
        ...prevState.show,
        [id]: !prevState.show[id]
      }
    }));
  }

  return (
    <div>
      <IconButton
        aria-haspopup="true"
        disableRipple={true}
        color="inherit"
        aria-controls="profile-menu"
        onClick={e => setFilterMenu(e.currentTarget)}>
        <img src={filter} draggable={false} />
      </IconButton>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        open={Boolean(filterMenu)}
        anchorEl={filterMenu}
        onClose={() => setFilterMenu(null)}
        disableAutoFocusItem
        PaperProps={{
          className: classes.filterMenu,
          style: { width: name && 480 }
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        <List component="nav"
          aria-labelledby="nested-list-subheader">
          {filterItems?.map((item, index) => {
            return (
              <div key={`${index}-items`} style={{ backgroundColor: showFilter.show[item?.id] && '#F3F3F3', margin: 4, borderRadius: 10 }}>
                <ListItem button onClick={() => handleClick(item?.id)} >
                  <ListItemText primary={item?.title} />
                  {showFilter.show[item?.id] ? <ExpandMore /> : <ExpandLess style={{ transform: 'rotate(90deg)' }} />}
                </ListItem>
                <Collapse in={showFilter.show[item?.id]} unmountOnExit>
                  <List component="div" disablePadding >
                    <ListItem>
                      <div>
                        {item.title === 'Type' && <CheckboxFilter item={item} type={type} handleChange={handleChange} />}
                      </div>
                      <div>
                        {item?.title === 'Status' && <CheckboxFilter item={item} status={status} handleChange={handleChange} />}
                      </div>
                      <div>
                        {item.title === 'Country' &&
                          <>
                            <CountryFilterField
                              inputText={inputText}
                              inputHandler={inputHandler}
                              country={country}
                              itemName={item.name}
                              countryName={countryName}
                              handleChange={handleChange} />
                          </>
                        }</div>
                      <div>
                        {item?.title === 'Payment Status' && <CheckboxFilter name={name} item={item} payment={filters?.['payment']} handleChange={handleChange} />}
                        {item?.title === 'Package Status' && <CheckboxFilter name={name} item={item} packageFilter={filters?.['package']} handleChange={handleChange} />}
                      </div>
                      <div>
                        {item.title === 'Shupper country' &&
                          <>
                            <CountryFilterField
                              name={name}
                              inputText={shupperInputText}
                              inputHandler={inputHandler}
                              country={shupperCountry}
                              itemName={'shupper'}
                              countryName={filters?.['shupper']}
                              handleChange={handleChange} />
                          </>
                        }</div>
                      <div>
                        {item.title === 'User country' &&
                          <>
                            <CountryFilterField
                              name={name}
                              inputText={userInputText}
                              inputHandler={inputHandler}
                              country={userCountry}
                              itemName={'user'}
                              countryName={filters?.['user']}
                              handleChange={handleChange} />
                          </>
                        }
                      </div>
                      <div>
                        {item.title === 'Item country' &&
                          <>
                            <CountryFilterField
                              name={name}
                              inputText={userInputText}
                              inputHandler={inputHandler}
                              country={userCountry}
                              itemName={'item'}
                              countryName={filters?.['item']}
                              handleChange={handleChange} />
                          </>
                        }
                      </div>
                        { item.title === 'Brands' && <>
                          <BrandFilterField
                            name={name}
                            inputText={inputText}
                            inputHandler={inputHandler}
                            itemName={'brands'}
                            handleChange={handleChange}
                            selectedBrandIds={filters?.['brands']?.value}
                          />
                        </> }
                    </ListItem>
                  </List>
                </Collapse>
              </div>
            )
          })}
          <div className={`${name ? 'filterOrderBtns' : 'filterBtns'}`}>
            <Button style={filterBtnsCustom} onClick={() => setFilterMenu(null)} className={classes.btnCancel}>Close</Button>
            <Button style={filterBtnsCustom} onClick={applyFilters} className={classes.btnApplyFilters}>Apply</Button>
          </div>
        </List>
      </Menu>
    </div >
  )
})

export const CheckboxFilter = ({ name, item, type, status, payment, packageFilter, handleChange }) => {
  const widthStyle = {
    marginRight: 300,
    width: name && 300
  }
  return (
    <>
      {item?.filters?.map((i, index) => {
        return (
          <div style={name && widthStyle}>
            <FormControlLabel
              key={`${i.id}-status`}
              control={
                <Checkbox color='primary'
                  checked={
                    status?.filterName === i?.label ||
                    type?.filterName === i?.label ||
                    payment?.id === i?.id && payment?.[i?.label] ||
                    packageFilter?.id === i?.id && packageFilter?.[i?.label]}
                  name="checkedB"
                  onChange={handleChange(i?.label, i.value, i.id, '', i?.subState, i?.subId)}
                />}
              label={i.label}
            />
          </div>
        )
      })}
    </>
  )
}
