import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from 'material-ui';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import RaisedButton from 'material-ui/RaisedButton';
import { getProps } from '../reducers/timetablesReducer';
import getHandlers from '../actions/inaccessibilityActions';
import PopupPreview from '../components/inaccessiblity/PopupPreview';
import TimeTable from '../components/inaccessiblity/Timetable';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import FlatButton from 'material-ui/FlatButton';

import '../static/styles/timetables.css';
import LinearProgress from 'material-ui/LinearProgress';
import Checkbox from 'material-ui/Checkbox';
import NoPermission from '../components/nopermission';
import { withRouter } from '../utils/withRouter';


class TimetablesPage extends React.Component {

	constructor(props) {
		super(props);
		const permission = decodePermissions(getPermission());

		this.state = {
			permission,
		};
	}

	componentDidMount() {
		if (this.state.permission.canConfigureTimeTable) {
			const { setUserId, params, getDefaultValues, setDefaultTimetable } = this.props;


			const { id } = params;
			setDefaultTimetable();
			getDefaultValues();
			setUserId(id);
		}
	}

	render() {
		const {
			onOpenDrawer, inaccessibility, setDayParameters,
			setPopupParameters, popups, saveTimetableChanges,
			userId, newTimetable, isLoading, error, success,
			setDefaultPopups, updateDefaultPopupsFlag, resetPopups
		} = this.props;


		return this.state.permission.canConfigureTimeTable ? (
			<React.Fragment>
				<div className="App-header">
					<IconButton onClick={onOpenDrawer}>
						<NavigationMenu color="white" />
					</IconButton>
				</div>
				<div className="progressbar-container">
					{isLoading &&
						<LinearProgress mode="indeterminate" />
					}
				</div>
				<div className="page-title flxr ac jc">Duty Off Settings</div>
				{
					success &&
					<div className="message-box success-message">
						{`Timetable is successfully saved`}
					</div>
				}
				{
					error &&
					<div className="message-box error-message">
						{`Error occurred`}
					</div>
				}
				<div className="timetables-container flxc jc ac">

					<div className="timetable-container">
						<TimeTable days={inaccessibility} setDayParameters={setDayParameters} />
					</div>
					<div className="popups-container flxr jc ac">
						<PopupPreview
							popupIndex={1}
							popupHeader={popups[1].popupHeader}
							popupText={popups[1].popupText}
							popupPickerImage={popups[1].icon}
							setPopupParameters={setPopupParameters(1)}
						/>
						<PopupPreview
							popupIndex={2}
							popupHeader={popups[2].popupHeader}
							popupText={popups[2].popupText}
							popupPickerImage={popups[2].icon}
							setPopupParameters={setPopupParameters(2)}
						/>
					</div>
					<Checkbox
						label="Save popups for all shuppers"
						checked={setDefaultPopups}
						onCheck={() => updateDefaultPopupsFlag(!setDefaultPopups)}
						style={{ width: '270px', marginTop: '40px', }}
					/>
					<FlatButton
						label="Return to defaults"
						//className="logout-btn"
						onClick={() => {
							resetPopups()

						}}
						className="return-to-defaults"
						secondary
					/>
					<RaisedButton
						onClick={() => saveTimetableChanges(
							popups, inaccessibility, userId, newTimetable, setDefaultPopups
						)}
						label="Save Changes"
						primary
						className="save-changes"

					/>
				</div>
			</React.Fragment>
		) : <NoPermission onOpenDrawer={onOpenDrawer} />
	}
}

export default withRouter(connect(getProps, getHandlers)(TimetablesPage));
