import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import StarRatings from "react-star-ratings";
import IconButton from "material-ui/IconButton";
import TextField from "material-ui/TextField";
import Done from "material-ui/svg-icons/action/done";
import Cross from "material-ui/svg-icons/content/clear";
import EditorModeEdit from "material-ui/svg-icons/editor/mode-edit";
import DeleteIcon from "material-ui/svg-icons/content/delete-sweep";
import { urlify } from "../utils/utils";
import MediaPreview from "./chat/MediaPreview";
import { reviewActions } from "../actions/brandReviewActions";
import withConfirm from "../utils/withConfirm";
import RaisedButton from "material-ui/RaisedButton";
import ImagePicker from "./imagepicker";

class ReviewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tempModifier: {},
            editMode: false,
        };
    }

    startEdit = () => {
        const { text, rating, startEditingReview, index } = this.props;
        this.setState({ tempModifier: { text, rating }, editMode: true });
        startEditingReview(index);
    };

    cancelEdit = () => {
        const { index, cancelEditingReview } = this.props;
        this.setState({ tempModifier: {}, editMode: false });
        cancelEditingReview(index);
    };

    setNewValue = (key, val) => {
        this.setState({
            tempModifier: { ...this.state.tempModifier, [key]: val },
        });
    };

    deleteMedia = () => {
        this.setState({
            tempModifier: {
                ...this.state.tempModifier,
                image: "",
                icon: "",
                video: "",
                videoPoster: "",
            },
        });
        this.props.setNewImage(this.props.index, "")();
        this.props.setNewVideo(this.props.index, "", "")();
    };

    saveEdit = () => {
        const { index, reviewId, updateReviewFields } = this.props;
        const { tempModifier } = this.state;
        updateReviewFields(index, tempModifier, reviewId);
        this.setState({ tempModifier: {}, editMode: false });
    };

    render() {
        const {
            text,
            from,
            date,
            imageUrl,
            videoUrl,
            videoPreviewUrl,
            index,
            expandVideo,
            expandImage,
            approveReview,
            declineReview,
            rating,
            reviewId,
            activeStatus,
            brandId,
            brand,
            setPreviewImageOrientation,
        } = this.props;
        const { tempModifier, editMode, video, videoPoster } = this.state;

        return (
            <div className="column-item flxr ac" key={index}>
                <div className="flxr ac message-user">
                    <div className="flxc jsb afs">
                        <div className="flxr jsb ac">
                            <div className="user-avatar">
                                <img
                                    src={from ? from.profileImageURL : null}
                                    alt=""
                                    className="avatar"
                                />
                            </div>
                            <div className="user-name">
                                {from ? from.displayName : null}
                            </div>
                        </div>
                        <div className="message-date">
                            {moment(date).format("lll")}
                        </div>
                        {brandId && brandId !== "all" ? null : brand &&
                            brand.title ? (
                            <i
                                style={{
                                    fontSize: "8pt",
                                    borderTop: "1px solid gray",
                                }}
                            >
                                For brand "{brand.title}"
                            </i>
                        ) : null}
                    </div>
                </div>
                <div className="flxc afs jc message-text">
                    {editMode ? (
                        <TextField
                            style={{ width: "100%" }}
                            floatingLabelText="Review text"
                            onChange={(ev) => {
                                this.setNewValue("text", ev.target.value);
                            }}
                            value={tempModifier.text}
                            multiLine={true}
                        />
                    ) : (
                        <div
                            className="review-text"
                            dangerouslySetInnerHTML={{ __html: urlify(text) }}
                        />
                    )}
                    <StarRatings
                        starDimension="20px"
                        rating={editMode ? tempModifier.rating : rating}
                        starRatedColor={editMode ? "lightblue" : "gold"}
                        numOfStars={5}
                        starHoverColor={editMode ? "red" : ""}
                        style={{ cursor: "pointer" }}
                        changeRating={
                            editMode
                                ? (rating) => this.setNewValue("rating", rating)
                                : null
                        }
                        isAggregateRating={false}
                    />
                    {editMode ? (
                        <div
                            className="flxr jsb ac"
                            style={{ padding: "10px" }}
                        >
                            <RaisedButton
                                backgroundColor="#16cd2f"
                                onClick={() => withConfirm(this.saveEdit)}
                                labelStyle={{ color: "#fff" }}
                                label={"Save"}
                            />
                            <RaisedButton
                                backgroundColor="white"
                                onClick={this.cancelEdit}
                                labelStyle={{ color: "black" }}
                                label={"Cancel"}
                            />
                        </div>
                    ) : null}
                </div>
                {!editMode ? (
                    <MediaPreview
                        imageUrl={imageUrl}
                        videoUrl={video || videoUrl}
                        videoPreviewImg={videoPoster || videoPreviewUrl}
                        expandChatsVideo={expandVideo}
                        setPreviewImages={expandImage}
                        setPreviewImageOrientation={setPreviewImageOrientation}
                    />
                ) : null}
                {editMode ? (
                    <React.Fragment>
                        <ImagePicker
                            className="flxr jsb ac image-picker"
                            imageClass="picker-preview"
                            placeholderUrl=""
                            url={tempModifier.image || imageUrl || null}
                            image={tempModifier.icon || null}
                            onImageSelected={(file) =>
                                this.setNewValue("image", file)
                            }
                            onError={console.error}
                            onFileRead={(file) =>
                                this.setNewValue("icon", file)
                            }
                        />
                        <IconButton
                            disabled={activeStatus === "declined"}
                            tooltip="Delete image"
                            onClick={this.deleteMedia}
                        >
                            <DeleteIcon color="red" />
                        </IconButton>
                    </React.Fragment>
                ) : null}
                <div className="accept-decline">
                    <IconButton
                        iconStyle={{ width: "20px", height: "20px" }}
                        onClick={this.startEdit}
                    >
                        <EditorModeEdit color="#cdcdcd" />
                    </IconButton>
                    <IconButton
                        disabled={activeStatus === "approved"}
                        tooltip="Accept"
                        onClick={approveReview(reviewId, index)}
                    >
                        <Done color="green" />
                    </IconButton>
                    <IconButton
                        disabled={activeStatus === "declined"}
                        tooltip="Decline"
                        onClick={declineReview(reviewId, index)}
                    >
                        <Cross color="red" />
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default connect(null, reviewActions)(ReviewComponent);
