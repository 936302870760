import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useNavigate } from 'react-router';
import { decodePermissions } from '../../utils/permissionUtil';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import { ReactComponent as CopyIcon } from '../../static/images/copy_icon.svg'
import './table.css'
import { toast } from 'react-toastify';
import { userLocalStorage } from '../../config/local_storage';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme, useStyles } from './tableStyles';
import { convertCountryToString, convertUsersCountryToString } from '../../utils/countriesAndZones';
import getSymbolFromCurrency from 'currency-symbol-map';
import { convertCurrencyToString } from '../../utils/countriesAndZones';
import { packageDefine, packageSubstateDefine, paymentDefine, paymentDetailsState } from '../../utils/paymentUtil';
import { itemDefine } from '../../utils/itemV2Util';
import { zones } from '../../utils/zones';
import { eventsV2Define } from '../../utils/eventV2Util';

const TableData = ({
  optionsExpandableRow, fetchMore, getCurrencies,
  data, customTheme, totalPrice,
  subData, headerColumn, tableName, tablePagination,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const handleCopyToClipboard = (copyValue) => {
    navigator.clipboard.writeText(copyValue)
    toast.info('Copied', { icon: <CopyIcon style={{ backgroundColor: 'none', background: 'none', color: 'red' }} /> })
  }

  const tableData = data?.map(i => {

    const redirectToProfile = () => navigate(`/profile/${i?.uid}`)
    const redirectToBouser = () => navigate(`/bouser/${i?.id}`, { state: { userId: i?.id } })
    const editProfile = () => navigate(`/profile/edit/${i.uid}`, { state: { phone: i?.phone_number } })

    const redirectToItemV2 = () => navigate(`/itemv2/${i?.item_id}`)
    
    const redirectToPackageDetails = () => navigate(`/package/${i?.id}`)
    const redirectToPaymentDetails = () => navigate(`/payment-details/${i?.uid}`, { state: { exchangeRate: i?.exchange_rate } })
    const redirectToEventDetails = () => navigate(`/eventv2/${i?.id}`)
    const redirectToAttemptsResponses = () => navigate(`/request-responses/${i?.uid}`, { state: { requestResponses: i?.responses } })
    const getZone = zones?.filter(zone => zone?.id === i?.zone_id)
    const defineCountry = convertUsersCountryToString(i?.country)
    const defineShupperCountry = convertUsersCountryToString(i?.shupper_country_id)
    const defineAllowCountry = convertCountryToString(i?.seller_zone)
    const defineBuyerCountry = convertCountryToString(i?.buyer_zone)
    const defineZoneCountry = convertCountryToString(i?.id)
    const shupperCCY = convertCurrencyToString(i?.currency_id)
    const shupperCCYSign = getSymbolFromCurrency(shupperCCY?.[0]?.code)
    const userCCY = convertCurrencyToString(getCurrencies?.[0]?.userCCY)
    const userCCYSign = getSymbolFromCurrency(userCCY?.[0]?.code)

    const totalAmount = ((Number(subData?.amount_value) +
      Number(subData?.local_tax_value) +
      Number(subData?.shupperz_fee_value) +
      Number(subData?.successfulInfo?.tip_amount ?? 0) +
      Number(subData?.successfulInfo?.import_tax_amount ?? 0) +
      Number(subData?.successfulInfo?.shipping_amount ?? 0) +
      Number(totalPrice)) || 0)?.toFixed(2)


    const usersData = [
      `${[i?.uid?.slice(-7)]}`,
      `${[i?.type === 1 ? 'user' : 'shupper']}`,
      `${[i?.first_name]}`,
      `${[i?.last_name]}`,
      `${[i?.email]}`,
      `${[i?.phone_number]}`,
      `${[defineCountry?.[0]?.name]}`,
      `${[moment(i?.create_date).format('LL')]}`,
      `${[i?.state === 1 ? 'Active' : 'Inactive']}`,
      { 1: redirectToProfile, 2: editProfile }
    ]
    const bousersData = [
      `${[i?.id]}`,
      `${['n/a']}`,
      `${[i?.fullName]}`,
      `${[i?.email]}`,
      `${[i?.phone]}`,
      `${['n/a']}`,
      `${[i?.status ? 'Active' : 'Inactive']}`,
      redirectToBouser,

    ]
    const recentSessionData = [
      `${[i?.device]}`,
      `${[i?.version]}`,
      `${[i?.ip]}`,
      `${[i?.country]}`,
      `${[i?.activity]}`,
      `${[i?.sessionStatus]}`,
      `${['']}`,
    ]
    const shippingData = [
      `${[i?.city]}`,
      `${[i?.address1]}`,
      `${[getZone[0]?.iso2_name]}`,
      `${[i?.zip_code]}`,
      `${[i?.tax_id]}`,
      handleCopyToClipboard,
    ]
    const recentOrders = [
      `${[i?.orderId]}`,
      `${[i?.createdAt]}`,
      `${[i?.shuppers]}`,
      `${[i?.user]}`,
      `${[i?.itemCost]}`,
      `${[i?.paymentStatus]}`,
      `${[i?.packageId]}`,
      `${[]}`,
    ]
    const recentPackages = [
      `${[i?.packageId]}`,
      `${[i?.fee]}`,
      `${[i?.tipAmount]}`,
      `${[i?.chargeAmount]}`,
      `${[]}`,
    ]
    const itemsV2 = [
      `${[i?.item_id]}`,
      `${[i?.brand]}`,
      `${[i?.create_date]}`,
      `${[itemDefine[i?.state_id]]}`,
      `${[defineCountry?.[0]?.name]}`,
      `${[i?.likes]}`,
      `${[i?.shupper_full_name]}`,
      redirectToItemV2
    ]
    const paymentRecentAttemptsOrders = [
      `${[moment(i?.creation_date).format('L h:mm')]}`,
      `${[(userCCYSign ?? '') + Number(i?.amount)?.toFixed(2) ?? '']}`,
      `${[(userCCYSign ?? '') + Number(subData?.item_cost_amount || 0)?.toFixed(2)]}`,
      `${[(userCCYSign ?? '') + Number(subData?.local_tax_amount || 0)?.toFixed(2)]}`,
      `${[(userCCYSign ?? '') + Number(subData?.shupperz_fee_amount || 0)?.toFixed(2)]}`,
      `${[(userCCYSign ?? '') + Number(subData?.successfulInfo?.tip_amount || 0)?.toFixed(2)]}`,
      `${[(userCCYSign ?? '') + Number(subData?.successfulInfo?.import_tax_amount || 0)?.toFixed(2)]}`,
      `${[(userCCYSign ?? '') + Number(subData?.successfulInfo?.shipping_amount || 0)?.toFixed(2)]}`,
      `${[(userCCYSign ?? '') + Number(i?.amount)?.toFixed(2) ?? '']}`,
      `${[i?.provider_id === 1 && 'Nuvei']}`,
      `${[paymentDetailsState?.[i?.state_id]]}`,
      `${[i?.uid]}`,
      `${[]}`,
      redirectToAttemptsResponses,
    ]

    const packagesData = [
      `${[i?.id]}`,
      `${[moment(i?.create_date).format('L h:mm')]}`,
      `${[moment(i?.update_date).format('L h:mm')]}`,
      `${[i?.from_user_name]}`,
      `${[i?.to_user_name]}`,
      `${[packageDefine[i?.state_id]]}`,
      `${[packageSubstateDefine[i?.substate_id]]}`,
      redirectToPackageDetails,
    ]

    const paymentsData = [
      `${[i?.external_uid]}`,
      `${[moment(i?.creation_date).format('L h:mm')]}`,
      `${[moment(i?.update_date).format('L h:mm')]}`,
      `${[paymentDefine[i?.state_id]]}`,
      `${[i?.fup_username]}`,
      `${[i?.fup_country_name]}`,
      `${[(shupperCCYSign ?? '') + Number(i?.amount_value ?? 0)?.toFixed(2)]}`,
      `${[(shupperCCYSign ?? '') + Number(i?.shupperz_fee_value ?? 0)?.toFixed(2)]}`,
      `${[(shupperCCYSign ?? '') + Number(i?.local_tax_value ?? 0)?.toFixed(2)]}`,
      `${[i?.tup_username]}`,
      `${[i?.tup_country_name]}`,
      `${[i?.package_id]}`,
      `${[i?.op_state_id === 2 ? 'Closed-' + packageSubstateDefine[i?.op_substate_id] : packageDefine[i?.op_state_id]]}`,
      redirectToPaymentDetails,

    ]

    const eventsV2Data = [
      `${[i?.id]}`,
      `${[i?.shupper_full_name]}`,
      `${[defineShupperCountry?.[0]?.name]}`,
      `${[moment(i?.create_date).format('L h:mm')]}`,
      `${[eventsV2Define[i?.state_id]]}`,
      `${[moment(i?.from_date).format('L h:mm')]}`,
      `${[moment(i?.to_date).format('L h:mm')]}`,
      `${[i?.item_brand_name]}`,
      redirectToEventDetails,
    ]

    const recentPaymentAttemptsProfile = [
      `${[i?.time]}`,
      `${[i?.totalAmount]}`,
      `${[i?.tip]}`,
      `${[i?.deliveryCost]}`,
      `${[i?.paymentMethod]}`,
      `${[i?.providerStatus]}`,
      `${[i?.paymentStatus]}`,
      `${[i?.confirmationId]}`,
      `${[i?.failureReason]}`
    ]

    const paymentRequestResponses = [
      `${[moment(i?.create_date).format('L h:mm')]}`,
      `${[moment(i?.update_date).format('L h:mm')]}`,
      `${[i?.state]}`,
      `${[paymentDetailsState?.[i?.to_state_id]]}`,
      `${[i?.external_uid]}`,
      `${[i?.body?.FIELD_1]}`,
    ]
    const allowedCountriesData = [
      `${[!i?.id ? i?.seller : defineAllowCountry[0]?.name]}`,
      `${[!i?.id ? i?.buyer : defineBuyerCountry[0]?.name]}`,
      `${[i?.id]}`,
    ]

    const zonesData = [
      `${[!i?.id ? i?.name : defineZoneCountry?.[0]?.name]}`,
      `${[i?.taxes?.filter(tax => tax?.type_id === 1)[0]?.tax_value ?? 'Not Defined']}`,
      `${[i?.taxes?.filter(tax => tax?.type_id === 2)[0]?.tax_value ?? 'Not Defined']}`,
      `${[i?.id]}`,]


    const attributesData = [
      `${[i?.name]}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 1)[0]?.number_value]}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 2)[0]?.number_value]}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 3)[0]?.number_value]}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 4)[0]?.string_value === "true" ? 'Yes' : 'No']}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 5)[0]?.string_value === "true" ? 'Yes' : 'No']}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 6)[0]?.array_of_num_value?.[0]]}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 6)[0]?.array_of_num_value?.[1]]}`,
      `${[i?.attributes?.filter(attribute => attribute?.attribute_id === 6)[0]?.array_of_num_value?.[2]]}`,
    ]

    const defineTable = {
      users: usersData,
      bousers: bousersData,
      recentSession: recentSessionData,
      shipping: shippingData,
      recentOrders: recentOrders,
      recentPackages: recentPackages,
      itemsV2: itemsV2,
      //recentPaymentAttempts: recentPaymentAttempts,
      allowedCountries: allowedCountriesData,
      countryZones: zonesData,
      attributes: attributesData,
      recentPaymentAttemptsProfile: recentPaymentAttemptsProfile,
      payments: paymentsData,
      eventsV2: eventsV2Data,
      packages: packagesData,
      paymentRecentAttemptsOrders: paymentRecentAttemptsOrders,
      paymentRequestResponses: paymentRequestResponses

    }
    return defineTable?.[tableName]
  })

  const options = {
    selectableRows: 'none',
    rowsPerPageOptions: [10, 25, 100],
    viewColumns: false,
    selectableRows: false,
    responsive: 'standard',
    search: false,
    download: false,
    print: false,
    filter: false,
    fixedHeader: true,
    tableBodyMaxHeight: '420px',
    pagination: tablePagination && true,
    onTableChange: (action, tableState) => {
      const lastPage = tableState?.rowsPerPage * tableState?.page + tableState?.rowsPerPage === data?.length
      switch (action) {
        case "changePage":
          (tableName === 'users' || tableName === 'payments') && lastPage && fetchMore();
          break;
        case "changeRowsPerPage":
          (tableName === 'users' || tableName === 'payments') && lastPage && fetchMore();
          break;
      }
    }
  };

  return (
    <MuiThemeProvider theme={customTheme ? customTheme() : getMuiTheme()}>
      <MUIDataTable
        className={classes.container}
        data={tableData}
        columns={headerColumn}
        options={tableName === 'countryZones' || tableName === 'attributes' ? optionsExpandableRow : options}
      />
    </MuiThemeProvider>
  )
}
export default TableData

export const TablePermissionData = React.memo(({ width, data, header }) => {
  const classes = useStyles()
  return (
    <>
      <TableContainer className={classes.container} style={{ width: width }}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow >
              <TableCell />
              {header?.map((column, index) => (
                <TableCell
                  key={index.id}
                  align={'left'}
                  style={{ height: column.height }}
                >
                  <Typography className={classes.columnText}>{column.fullName || column.title}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => {
              return (
                <TableRow hover role="checkbox" key={row.code}>
                  <TableCell>{row.label}</TableCell>
                  {header?.map((bouserPermissions) => {
                    const perm = decodePermissions(bouserPermissions.permission)
                    return (
                      <TableCell key={bouserPermissions.id} align={'left'}>
                        <FormControlLabel control={<Checkbox color='primary' checked={perm[row.type]} />} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
})





