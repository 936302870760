/*
Kateryna Ivanova
20/03/2018

Sagas for authorization
*/
import 'regenerator-runtime/runtime';
import { put, call, takeEvery, all } from 'redux-saga/effects';
import types from '../actions/actionTypes';
import request from '../utils/networkRequest';
import { setUserId, logout } from '../utils/cookies';
import {
  adminLoginSuccess,
  adminLoginFailed,
  adminLogoutSuccessed,
  login2faSucceded,
  login2faFailed,
  requestPhoneFailed,
  requestPhoneSuccess,
} from '../actions/authactions';
import getBaseUrl from '../utils/envSetup';

const baseUrl = getBaseUrl();

function* adminLoginAsync(action) {
  const response = yield call(request, `${baseUrl}/api/auth/login`, {
    method: 'POST',
    body: JSON.stringify({
      login: action.login,
      password: action.password,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  });
  if (response.err) {
    yield put(adminLoginFailed(response.err.toString()));
  } else {
    setUserId(response.data.user._id, response.data.user.permission);
    localStorage.setItem('chatsToken', response.data.user.token || '');
    yield put(adminLoginSuccess(response.data.user));
  }
}

function* login2fa({
  token, authyId, login, password,
}) {
  const response = yield call(request, `${baseUrl}/api/auth/login2fa`, {
    method: 'POST',
    body: JSON.stringify({
      token,
      authyId,
      login,
      password,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  });

  if (response.data.errors) {
    yield put(login2faFailed(response.data.message));
  } else {
    yield put(login2faSucceded(response.data.user));
    setUserId(response.data.user._id, response.data.user.permission);
  }
}

function* requestPhone({ phone, countryCode, login }) {
  const response = yield call(request, `${baseUrl}/api/auth/login2fa-phone`, {
    method: 'POST',
    body: JSON.stringify({
      phone,
      countryCode,
      login,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  });

  if (response.data.errors) {
    yield put(requestPhoneFailed(response.data.message));
  } else {
    yield put(requestPhoneSuccess(response.data.authyId));
  }
}

function* adminLogoutAsync() {
  logout();
  yield put(adminLogoutSuccessed());
}

function* watchAdminLoginRequest() {
  yield takeEvery(types.ADMIN_LOGIN, adminLoginAsync);
}

function* watchAdminLogoutRequest() {
  yield takeEvery(types.ADMIN_LOGOUT, adminLogoutAsync);
}

function* watchLogin2fa() {
  yield takeEvery(types.LOGIN_2FA, login2fa);
}

function* watchRequestPhone() {
  yield takeEvery(types.REQUEST_PHONE, requestPhone);
}

export default function* authSaga() {
  yield all([
    watchAdminLoginRequest(),
    watchAdminLogoutRequest(),
    watchLogin2fa(),
    watchRequestPhone(),
  ]);
}
