
import types from './actionTypes';

export const getCheckins = (skip, limit, shupper, status) => {
    return {
        type: types.GET_CHECKINS,
        payload:
            { skip, limit, shupper, status }

    }
};


export const getCheckinsSuccess = (data) => {
    return {
        type: types.GET_CHECKINS_SUCCESS,
        payload:
            { data }

    }
};

export const selectCheckin = (checkin) => {
    return {
        type: types.SELECT_CHECKIN,
        payload:
            { checkin }

    }
};
export const getCheckinById = (id) => {
    return {
        type: types.GET_CHECKIN_BY_ID,
        payload:
            { id }

    }
};


export const getCheckinByIdSuccess = (data) => {
    return {
        type: types.GET_CHECKIN_BY_ID_SUCCESS,
        payload:
            { data }

    }
};
export const saveCheckinById = (data) => {
    return {
        type: types.SAVE_CHECKIN_BY_ID,
        payload:
            { data }

    }
};


export const saveCheckinByIdSuccess = (data) => {
    return {
        type: types.SAVE_CHECKIN_BY_ID_SUCCESS,
        payload:
            { data }

    }
};
export const saveNewCheckinSuccess = (data) => {
    return {
        type: types.SAVE_NEW_CHECKIN_SUCCESS,
        payload:
            { data }

    }
};

export const getShuppers = (id) => {
    return {
        type: types.GET_SHUPPERS,


    }
};


export const getShuppersSuccess = (data) => {
    return {
        type: types.GET_SHUPPERS_SUCCESS,
        payload:
            { data }

    }
};
export const getStores = () => {
    return {
        type: types.GET_STORES,


    }
};


export const getStoresSuccess = (data) => {
    return {
        type: types.GET_STORES_SUCCESS,
        payload:
            { data }

    }
};
export const updateVideoCheckin = (data) => {
    return {
        type: types.UPDATE_VIDEO_CHECKIN,
        payload:
            { data }

    }
};

export const updateVideoCheckinSuccess = (data) => {
    return {
        type: types.UPDATE_VIDEO_CHECKIN_SUCCESS,
        payload:
            { data }

    }
};
export const updateImageCheckin = (data) => {
    return {
        type: types.UPDATE_IMAGE_CHECKIN,
        payload:
            { data }

    }
};

export const updateImageCheckinSuccess = (data) => {
    return {
        type: types.UPDATE_IMAGE_CHECKIN_SUCCESS,
        payload:
            { data }

    }
};

export const resetCheckins = (data) => {
    return {
        type: types.RESET_CHECKINS,


    }
};

export const deleteCheckins = (ids) => {
    return {
        type: types.DELETE_CHECKINS,
        payload:
            { ids }

    }
}
export const deleteCheckinsSuccess = (data) => {
    return {
        type: types.DELETE_CHECKINS_SUCCESS,
        payload:
            { data }

    }
}
export default dispatch => ({
    getCheckins: (skip, limit, shupper, status) => dispatch(getCheckins(skip, limit, shupper, status)),
    selectCheckin: (checkin) => dispatch(selectCheckin(checkin)),
    getCheckinById: (id) => dispatch(getCheckinById(id)),
    getShuppers: () => dispatch(getShuppers()),
    updateVideoCheckin: (data) => dispatch(updateVideoCheckin(data)),
    updateImageCheckin: (data) => dispatch(updateImageCheckin(data)),
    saveCheckinById: (data) => dispatch(saveCheckinById(data)),
    resetCheckins: () => dispatch(resetCheckins()),
    getStores: () => dispatch(getStores()),
    deleteCheckins: (ids) => dispatch(deleteCheckins(ids))

});
