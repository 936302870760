/*
Kateryna Ivanova
20/03/2018

Reducer for a single event
*/
import types from '../actions/actionTypes';

const initialState = {
  event: null,
  isLoading: false,
  isUpdating: false,
  updateSuccess: false,
  updateError: null,
  isDeleting: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_EVENT:
      return Object.assign({}, state, {
        isLoading: true,
        event: null,
        updateSuccess: false,
        updateError: null,
      });
    case types.FETCH_EVENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        event: action.data,
      });
    case types.FETCH_EVENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.UPDATE_EVENT:
      return Object.assign({}, state, {
        isUpdating: true,
        updateSuccess: false,
      });
    case types.CREATE_EVENT:
      return Object.assign({}, state, {
        isUpdating: true,
        updateSuccess: false,
      });
    case types.UPDATE_EVENT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        updateSuccess: true,
      });
    case types.UPDATE_EVENT_FAILED:
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: action.reason,
        updateSuccess: false,
      });

    case types.DELETE_EVENT:
      return Object.assign({}, state, {
        isDeleting: true,
        deleteSuccess: false,
      });
    case types.DELETE_EVENT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteSuccess: true,
      });
    case types.DELETE_EVENT_FAILED:
      return Object.assign({}, state, {
        isDeleting: false,
        deleteError: action.reason,
        deleteSuccess: false,
      });
    default:
      return state;
  }
}
