/*
Kateryna Ivanova
20/03/2018

Reducer for a list of users
*/
import types from '../actions/actionTypes';
import {createReducer} from './helpers/redux-helpers';

const initialState = {
  offset: 0,
  limit: 100,
  users: [],
  searchQuery: '',
  genderFilter: '',
  locationFilter: '',
  statusFilter: '',
  typeFilter: '',
  dateFilter: {},
};

export default createReducer(
  {
    [types.FETCH_ALL_USERS]: (state, action) => ({
      ...state,
      isLoading: true,
      offset: 0,
      users: [],
      totalCount: 0,
      searchQuery: action?.data?.query === '' ? '' : action?.data?.query || state.searchQuery,
      roles: action.roles || state.roles,
      typeFilter: action?.data?.type === null ? state.typeFilter : action?.data?.type || initialState.typeFilter,
      statusFilter:
        action?.data?.statusFilter === null
          ? state.statusFilter
          : action?.data?.statusFilter || initialState.statusFilter,
      locationFilter:
        action.data?.locationFilter === null
          ? state.locationFilter
          : action?.data?.locationFilter || initialState.locationFilter,
      dateFilter:
        action?.data?.dateFilter === null ? state.dateFilter : action?.data?.dateFilter || initialState.dateFilter,
    }),

    [types.FETCH_ALL_USERS_SUCCESS]: (state, action) => ({
      ...state,
      offset: state.offset + state.limit,
      totalCount: action.data.count,
      users: [...state.users, ...action.data],

      hasMore: state?.users?.length + action?.data?.users?.length < action?.data?.count,
      isLoading: false,
    }),
    [types.FETCH_ALL_USERS_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasMore: false,
    }),
    [types.FETCH_MORE_USERS]: state => ({
      ...state,
      isLoading: true,
    }),
  },
  initialState,
);
