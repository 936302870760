import types from './actionTypes';
import { fetchUsers } from '../actions/usersactions';

export const makeConnection = dispatch => ({
    type: types.MAKE_WS_CONNECTION,
    payload: {
        dispatch
    },
});

export const setConnectionProperties = props => ({
    type: types.SET_CONNECTION_PROPS,
    payload: props,
});

export const connectToChat = (uniqueName, chatId, socket, name1, name2) => ({
    type: types.CONNECT_TO_CHAT,
    payload: {
        chatId,
        socket,
        uniqueName,
        name1,
        name2,
    },
});

export const loadChatMessages = (chatId, uniqueName, token, skip = 0, lazy = false, up = true) => ({
    type: types.LOAD_CHAT_MESSAGES,
    payload: {
        chatId, token, uniqueName, lazy, skip, up
    }
});

export const addChatMessages = (chatId, messages) => ({
    type: types.ADD_CHAT_MESSAGES,
    payload: {
        chatId, messages
    }
});

export const addNewlyMessage = (chatId, messages) => ({
    type: types.ADD_NEWLY_MESSAGE,
    payload: {
        chatId, messages
    }
});

export const removeNewlyMessages = (chatId) => ({
    type: types.REMOVE_NEWLY_MESSAGES,
    payload: {
        chatId
    }
});

export const handleAddMessage = (chatId, messages) => ({
    type: types.HANDLE_NEW_MESSAGE,
    payload: {
        chatId, messages
    }
});

export const prependChatMessages = (chatId, messages) => ({
    type: types.PREPEND_CHAT_MESSAGES,
    payload: {
        chatId, messages,
    }
});

export const loadChatsList = (friendlyName, { from, to }, support, skip, lazy = false, shouldLoadUp = true, limit, skipOld, inMessages) => ({
    type: types.LOAD_CHATS_LIST,
    payload: {
        friendlyName, from, to, skip, lazy, shouldLoadUp, support, limit, skipOld, inMessages
    }
});

export const setChatsList = (chats, full, totalCount, skip, foundMessages) => ({
    type: types.SET_CHATS_LIST,
    payload: {
        chats, full, totalCount, skip, foundMessages
    }
});

export const addChatsToList = (chats, foundMessages) => ({
    type: types.ADD_CHATS_TO_LIST,
    payload: {
        chats, foundMessages
    }
});

export const sendMessageToChat = (chatId, uniqueName, message, socket) => ({
    type: types.SEND_MESSAGE,
    payload: {
        message, chatId, socket, uniqueName
    }
});

export const selectChat = (chatId, uniqueName, socket, name1, name2, users) => ({
    type: types.SELECT_CHAT,
    payload: {
        chatId,
        uniqueName,
        socket,
        name1,
        name2,
        users
    }
});

export const changeSelectedChat = (chatId) => ({
    type: types.CHANGE_SELECTED_CHAT,
    payload: {
        chatId
    }
});

export const disconnectFromChat = (chatId, uniqueName, socket, displayNames, selectedChat) => ({
    type: types.DISCONNECT_FROM_CHAT,
    payload: {
        chatId,
        uniqueName,
        socket,
        displayNames,
        selectedChat,
    },
});

export const changeFilterParams = (params) => ({
    type: types.CHANGE_FILTER_PARAMS,
    payload: {
        ...params
    }
});

export const loadMoreChats = (skip, full, limit) => ({
    type: types.LOAD_MORE_CHATS,
    payload: {
        data: {
            skip, full, limit
        }
    }
});

export const loadMoreMessages = (skip, full, chatId) => ({
    type: types.LOAD_MORE_MESSAGES,
    payload: {
        data: {
            skip, full,
        },
        chatId
    }
});

export const loadMoreMessagesDown = (skipDown, fullDown, chatId) => ({
    type: types.LOAD_MORE_MESSAGES_DOWN,
    payload: {
        data: {
            skipDown, fullDown,
        },
        chatId
    }
});

export const setChatsToken = token => ({
    type: types.SET_CHATS_TOKEN,
    payload: {
        token
    }
});

export const setPreviewImages = previewImages => ({
    type: types.SET_PREVIEW_IMAGES,
    payload: {
        previewImages: previewImages.map(val => ({ src: val }))
    }
});

export const clearPreviewImages = () => ({
    type: types.CLEAR_PREVIEW_IMAGES,
});
export const setPreviewImageOrientation = (orientation) => ({
    type: types.SET_PREVIEW_IMAGE_ORIENTATION,
    payload: {
        orientation: orientation
    }
});
export const clearPreviewImageOrientation = () => ({
    type: types.CLEAR_PREVIEW_IMAGE_ORIENTATION,
});
export const resetTheFilters = () => ({
    type: types.RESET_CHATS_FILTERS,
});

export const setDefaultFilters = () => ({
    type: types.SET_DEFAULT_FILTERS,
});

export const setSupportId = supportId => ({
    type: types.SET_SUPPORT_ID,
    payload: {
        supportId
    }
});

export const setPaymentsData = (id, data) => ({
    type: types.SET_PAYMENT_DATA,
    payload: {
        id, data
    }
});
export const setShuppersData = (data) => ({
    type: types.SET_SHUPPERS_DATA,
    payload: data

});
export const setForwardedChats = (data) => ({
    type: types.SET_FORWARDED_CHATS,
    payload: data

});
export const setForwardedChatsSuccess = (data) => ({
    type: types.SET_FORWARDED_CHATS_SUCCESS,
    payload: data

});
export const resetForwardedChats = (data) => ({
    type: types.RESET_FORWARDED_CHATS,
    payload: data

});
export const getUsersRoles = (ids) => ({
    type: types.GET_USERS_ROLES,
    payload: {
        ids
    }
});
export const setUsersRoles = (data) => ({
    type: types.SET_USERS_ROLES,
    payload: {
        data
    }
});
export const getPaymentsData = (id, message) => ({
    type: types.GET_PAYMENT_DATA,
    payload: {
        id, message
    }
});
export const getShuppersData = () => ({
    type: types.GET_SHUPPERS_DATA,

});

export const getUserRoles = (users, forwardedMessages) => ({
    type: types.GET_USER_ROLES,
    payload: {
        users, forwardedMessages
    }
});
export const addForwardedMessageUserRoles = (users) => ({
    type: types.ADD_FORWARDED_MESSAGE_USER_ROLES,
    payload: {
        users
    }
});

export const addUserRoles = users => ({
    type: types.ADD_USER_ROLES,
    payload: {
        users
    }
});

export const removeFlagFromChat = (id, chatId) => ({
    type: types.REMOVE_FLAG_FROM_CHAT,
    payload: {
        id, chatId
    }
});

export const removeFlagInState = (id, chatId) => ({
    type: types.REMOVE_FLAG_IN_STATE,
    payload: {
        id, chatId,
    }
});

export const expandChatsVideo = (videoUrl, poster) => ({
    type: types.EXPAND_CHAT_VIDEO,
    payload: {
        videoUrl, poster
    }
});
export const clearAllShuplistItems = () => ({
    type: types.CLEAR_ALL_SHUPLIST_ITEMS,

});
export const getAllShuplistItems = (id) => ({
    type: types.GET_ALL_SHUPLIST_ITEMS,
    payload: {
        id
    }
});
export const getAllShuplistItemsSuccess = (data) => ({
    type: types.GET_ALL_SHUPLIST_ITEMS_SUCCESS,
    payload: {
        data
    }
});
export const closeChatsVideo = () => ({
    type: types.CLOSE_CHAT_VIDEO,
});

export const updateMessage = (chatId, message) => ({
    type: types.UPDATE_MESSAGE,
    payload: {
        chatId, message,
    }
});

export const jumpToMessage = (chatId, messageId, newIndex, token, message, messagesInChat) => ({
    type: types.JUMP_TO_MESSAGE,
    payload: { chatId, messageId, newIndex, token, message, messagesInChat },
});

export const getStores = () => {
    return {
        type: types.GET_STORES,


    }
};
export const filtersActions = dispatch => ({
    changeFilterParams: (...params) => dispatch(changeFilterParams(...params)),
    setNameFilter: e => dispatch(changeFilterParams({ friendlyNameNew: e.target.value })),
    resetTheFilters: () => dispatch(resetTheFilters()),
});



export const chatListActions = dispatch => ({
    selectNewChat: (id, uniqueName, socket, name1, name2, users) => () => dispatch(selectChat(id, uniqueName, socket, name1, name2, users)),
});

export const chatTabsHandlers = dispatch => ({
    changeSelectedChat: chatId => () => dispatch(changeSelectedChat(chatId)),
    disconnectFromChat: (chatId, uniqueName, socket, displayNames, selectChat) =>
        dispatch(disconnectFromChat(chatId, uniqueName, socket, displayNames, selectChat))
});

export const messageListActions = dispatch => ({
    getPaymentsData: id => dispatch(getPaymentsData(id)),
    loadMessages: (chatId, uniqueName, token, skip, lazy, up) => () =>
        dispatch(loadChatMessages(chatId, uniqueName, token, skip, lazy, up)),
    setPreviewImages: images => () => dispatch(setPreviewImages(images)),
    sendMessageToChat: (chatId, uniqueName, message, socket) => dispatch(sendMessageToChat(chatId, uniqueName, message, socket)),
    setPreviewImageOrientation: orientation => dispatch(setPreviewImageOrientation(orientation)),
    getAllShuplistItems: id => dispatch(getAllShuplistItems(id)),
    clearAllShuplistItems: () => dispatch(clearAllShuplistItems())

});

export const switcherActions = dispatch => ({
    jumpToMessage: (chatId, messageId, newIndex, token, message, messagesInChat) =>
        dispatch(jumpToMessage(chatId, messageId, newIndex, token, message, messagesInChat)),
});

export default dispatch => ({
    makeConnection: () => dispatch(makeConnection(dispatch)),
    fetchUsers: () => dispatch(fetchUsers()),
    clearPreviewImages: () => dispatch(clearPreviewImages()),
    clearPreviewImageOrientation: () => dispatch(clearPreviewImageOrientation()),
    closeChatsVideo: () => dispatch(closeChatsVideo()),
    expandChatsVideo: (videoUrl, poster) => dispatch(expandChatsVideo(videoUrl, poster)),
    loadChatsList: (friendlyName, date, support, skip, lazy, shouldLoadUp, limit, skipOld, inMessages) =>
        () => dispatch(loadChatsList(friendlyName, date, support, skip, lazy, shouldLoadUp, limit, skipOld, inMessages)),
    removeFlagFromChat: (id, chatId) => dispatch(removeFlagFromChat(id, chatId)),
    getShuppersData: () => dispatch(getShuppersData()),
    setForwardedChats: (data) => dispatch(setForwardedChats(data)),
    setForwardedChatsSuccess: (data) => dispatch(setForwardedChatsSuccess(data)),
    resetForwardedChats: () => dispatch(resetForwardedChats()),
    getUsersRoles: (ids) => dispatch(getUsersRoles(ids)),
    setUsersRoles: (data) => dispatch(setUsersRoles(data)),
    getUserRoles: (users, forwardedMessages) => dispatch(getUserRoles(users, forwardedMessages)),
    getStores: () => dispatch(getStores()),

});
