import types from '../actions/actionTypes';
import { createReducer } from './helpers/redux-helpers';
import { createSelector } from 'reselect';
import { timeTablesDefaults } from '../defaults';

const initialState = {
  userId: null,
  popups: timeTablesDefaults.defaultPopups,
  inaccessibility: timeTablesDefaults.defaultTimetable,
  error: false,
  isLoading: true,
  success: false,
  setDefaultPopups: false,
};

const inaccessibilityReducer = createReducer(
  {
    [types.SET_DEFAULT_TIMETABLE]: state => ({
      ...initialState,
      userId: state.userId,
    }),
    [types.SET_USER_ID]: (state, { payload }) => ({
      ...state,
      userId: payload.userId,
    }),
    [types.SET_DAY_PARAMS]: (state, { payload }) => ({
      ...state,
      inaccessibility: {
        ...state.inaccessibility,
        [payload.day]: { ...state.inaccessibility[payload.day], ...payload.dayData },
      },
    }),
    [types.SET_DAYS]: (state, { payload }) => ({
      ...state,
      inaccessibility: { ...payload.days },
    }),
    [types.SET_POPUPS]: (state, { payload }) => ({
      ...state,
      popups: state.popups.ignoreDefaults ? { ...state.popups } : { ...payload.popups },
      ignoreDefaults: payload.ignoreDefaults,
    }),
    [types.SET_POPUP_PARAMS]: (state, { payload }) => ({
      ...state,
      popups: {
        ...state.popups,
        [payload.popup]: { ...state.popups[payload.popup], ...payload.popupData },
      },
    }),
    [types.SAVE_TIMETABLE_CHANGES]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.CREATE_NEW_TIMETABLE]: state => ({
      ...state,
      newTimetable: true,
    }),
    [types.TIME_TABLE_STOP_LOAD]: state => ({
      ...state,
      isLoading: false,
    }),
    [types.SET_UPDATE_FLAG]: (state, { payload }) => ({
      ...state,
      newTimetable: payload.newTimetable,
    }),
    [types.SET_TIMETABLE_SAVE_ERROR]: state => ({
      ...state,
      error: true,
      success: false,
    }),
    [types.SET_TIMETABLE_SAVE_SUCCESS]: state => ({
      ...state,
      error: false,
      success: true,
    }),
    [types.UPDATE_DEFAULT_POPUPS_FLAG]: (state, { payload }) => ({
      ...state,
      setDefaultPopups: payload.setDefaultPopups,
    }),
    [types.RESET_POPUPS]: (state, { payload }) => ({
      ...state,
      popups: timeTablesDefaults.defaultPopups,
    }),
  },
  initialState,
);

// SELECTORS
const timetablesStateSelector = state => state.inaccessibilityReducer;

export const dayInfoSelector = dayId =>
  createSelector(timetablesStateSelector, ({ inaccessibility }) => inaccessibility[dayId] || null);

export const getProps = createSelector(
  timetablesStateSelector,
  ({ inaccessibility, popups, userId, newTimetable, isLoading, error, success, setDefaultPopups }) => ({
    inaccessibility,
    popups,
    userId,
    newTimetable,
    isLoading,
    error,
    success,
    setDefaultPopups,
  }),
);

export default inaccessibilityReducer;
