import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Drawer from 'material-ui/Drawer';
import FlatButton from 'material-ui/FlatButton';
import Divider from 'material-ui/Divider';
import { getUserId, getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import InfoAlert from '../components/InfoAlert';
import { fetchPendingBrands } from '../actions/approveBrandsActions';
import { adminLogout } from '../actions/authactions';
import 'bootstrap/dist/css/bootstrap.css';
import '../static/styles/App.css';
import InactivityTimer from '../utils/inactivityTimer';
import { withRouter } from '../utils/withRouter';
import { Outlet } from 'react-router-dom';
import { userLocalStorage } from '../config/local_storage';
import { fetchCountries, fetchZones } from '../actions/countryActions';

const App = ({ children, location, open, closeDrawer }) => {
  const { pendingBrands } = useSelector(({ approveBrandsReducer }) => approveBrandsReducer);
  const { userId, username } = useSelector(({ loginReducer }) => loginReducer);
  const dispatch = useDispatch();
  const permission = decodePermissions(getPermission());
  const navigate = useNavigate()
  const loggedIn = getUserId();

  const [state, setState] = React.useState({
    loggedIn,
    permission,
    pendingBrand: [],
    showAlertNewBrand: false,
  });

  const logout = () => {
    dispatch(adminLogout());
    navigate('/login', { replace: true });
    closeDrawer()
  };
  React.useEffect(() => {
    const timer = new InactivityTimer({
      timeout: 1200,
      onTimeout: () => {
        logout();
      },
      onExpired: () => {
        logout();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, [userId]);

  const fetchedCountries = userLocalStorage?.get('countries')
  const fetchedZones = userLocalStorage?.get('zones')

  React.useEffect(() => {
    if (username && !fetchedCountries) {
      dispatch(fetchCountries())
      dispatch(fetchZones())
    }
  }, [username])

  const renderMenuItem = (link, caption, flagToCheck = true, closeDrawer, additionalAction = () => { }) =>
    flagToCheck ? (
      <div className="menu-item" key={link} onClick={closeDrawer}>
        <Link to={`/${link}`} onClick={additionalAction}>
          {caption}
        </Link>
      </div>
    ) : null;

  const {
    users,
    items,
    stores,
    chats,
    events,
    bousers,
    securitylogs,
    coupons,
    payments,
    setShupperOfTheDay,
    canManageReviews,
    canManageCheckins,
    canApproveShuppers,
    canApproveBrands,
    orders
  } = permission;


  return (
    <MuiThemeProvider>
      <div className="App" style={{ height: '100vh' }}>
        <Drawer docked={false} onRequestChange={open => closeDrawer({ open })} open={open}>
          {loggedIn
            ? [
              renderMenuItem('users', 'Users', users, closeDrawer),
              renderMenuItem('settings', 'Settings', users, closeDrawer),
              // renderMenuItem('usersold', 'Old users', users, closeDrawer),
              renderMenuItem('payments', 'Payments', payments, closeDrawer),
              renderMenuItem('packages', 'Packages', users, closeDrawer),
              // renderMenuItem('items', 'Items/Collections', items, closeDrawer),
              renderMenuItem('itemsv2', 'Items', items, closeDrawer),
              renderMenuItem('eventsv2', 'Events', items, closeDrawer),
              // renderMenuItem('stores', 'Stores', stores, closeDrawer),
              renderMenuItem('chat', 'Chat', chats, closeDrawer),
              // renderMenuItem('events', 'Banners/Brands', events, closeDrawer),
              // renderMenuItem('shupper-of-the-day', 'Shupper of the day', setShupperOfTheDay, closeDrawer),
              renderMenuItem('bousers', 'Back Office Permissions', bousers, closeDrawer),
              // renderMenuItem('view-permissions', 'View permissions', bousers, closeDrawer),
              // renderMenuItem('securitylogs', 'Security Logs', securitylogs, closeDrawer),
              // renderMenuItem('coupons', 'Coupons', coupons, closeDrawer),
              // renderMenuItem('logistics/pending', 'Logistics', payments, closeDrawer),
              // renderMenuItem('reviews', 'Manage Reviews', canManageReviews, closeDrawer),
              // renderMenuItem('checkins', 'Manage Checkins', canManageCheckins, closeDrawer),
              // renderMenuItem('approve-shuppers', 'Approve Shuppers', canApproveShuppers, closeDrawer),
              // renderMenuItem('brands', 'Approve Brands', canApproveBrands, closeDrawer),
            ]
            : null}
          <Divider />
          {userId ? (
            <FlatButton label="Logout" className="logout-btn" onClick={logout} secondary />
          ) : (
            <FlatButton label="Login" className="logout-btn" href="/login" secondary />
          )}
        </Drawer>
        <div className="info-alert-group">
          {state?.showAlertNewBrand && location.pathname !== '/brands' ? (
            <InfoAlert
              mainText="Attention!"
              extraText="You have new brand for approve"
              onClose={() => {
                setState({ showAlertNewBrand: false });
              }}
              button={
                <button
                  onClick={() => {
                    navigate('/brands');
                    setState({ showAlertNewBrand: false });
                  }}
                  className="btn btn-sm btn-primary">
                  Go to Approve Brands Page
                </button>
              }
            />
          ) : null}
        </div>
        <Outlet />
      </div>
    </MuiThemeProvider>
  );
};

App.propTypes = {
  adminLogout: PropTypes.func.isRequired,
  loginReducer: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
};

export default withRouter(App);
