/*
Roman Safonov
28/03/2018

Reducer for a list of collections
*/
import types from '../actions/actionTypes';

function procesSort(currentSort, column, direction) {
  const sort = Object.assign({}, currentSort);
  if (sort[column] === direction) {
    delete sort[column];
  } else {
    sort[column] = direction;
  }
  return sort;
}

const initialState = {
  offset: 0,
  limit: 100,
  collections: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: {},
  sort: {},
  isDeleting: false,
  deletionError: null,
};

export default function collectionsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COLLECTIONS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        collections: [],
        totalCount: 0,
        searchQuery: action.query,
      });
    case types.FETCH_COLLECTIONS_SORT_CHANGED: {
      const sorting = procesSort(state.sort, action.column, action.direction);
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        collections: [],
        totalCount: 0,
        sort: sorting,
      });
    }
    case types.FETCH_MORE_COLLECTIONS:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_COLLECTIONS_SUCCESS: {
      const collections = [].concat(state.collections).concat(action.data.collections);
      return Object.assign({}, state, {
        offset: state.offset + state.limit,
        totalCount: action.data.count,
        collections,
        hasMore: collections.length < action.data.count,
        isLoading: false,
      });
    }
    case types.FETCH_COLLECTIONS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        hasMore: false,
      });
    case types.DELETE_COLLECTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        collections: [],
        totalCount: 0,
      });
    case types.DELETE_COLLECTIONS:
      return Object.assign({}, state, {
        isDeleting: true,
        deletionError: null,
      });
    case types.DELETE_COLLECTIONS_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        deletionError: null,
        isLoading: true,
        offset: 0,
        collections: [],
        totalCount: 0,
      });
    case types.DELETE_COLLECTIONS_FAILED:
      return Object.assign({}, state, {
        isDeleting: false,
        deletionError: action.reason,
      });
    case types.UPDATE_COLLECTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        collections: [],
        totalCount: 0,
      });
    default:
      return state;
  }
}
