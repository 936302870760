
import types from '../actionTypes';

export const fetchShippingPickUp = query => ({

    type: types.LOGISTICS.FETCH_SHIPPING_PICKUP,
    query,
});


export const fetchShippingPickUpSuccess = data => ({
    type: types.LOGISTICS.FETCH_SHIPPING_PICKUP_SUCCESS,
    data,
});

export const fetchShippingPickUpFailed = reason => ({
    type: types.LOGISTICS.FETCH_SHIPPING_PICKUP_FAILURE,
    reason,
});

export const saveShippingPickup = data => ({
    type: types.LOGISTICS.ADD_SHIPPING_PICKUP,
    data
})

export const saveShippingPickupSuccess = data => ({
    type: types.LOGISTICS.ADD_SHIPPING_PICKUP_SUCCESS,
    data,
});

export const saveShippingPickupFailed = reason => ({
    type: types.LOGISTICS.ADD_SHIPPING_PICKUP_FAILURE,
    reason,
});

export const fetchMoreShippingPickup = ()=> ({
    type : types.LOGISTICS.FETCH_MORE_SHIPPING_PICKUP
})

export const clearShippingPickupData = ()=> {
    return {
        type : types.LOGISTICS.CLEAR_SHIPPING_PICKUP_DATA
    } 
}

export const removeShippingItems = (ids) => (
    {
        type: types.LOGISTICS.REMOVE_SHIPPING_ITEMS,
        ids
    })


