import React, { PureComponent } from "react";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Checkbox,
  InputGroup
} from "react-bootstrap";
import RaisedButton from "material-ui/RaisedButton";

const referredFrom = {
  facebookAd: "Facebook Ad",
  instagramAd: "Instagram Ad",
  onlineBoards: "Online Boards",
  shupperzWebsite: "Shupperz website",
  friend: "A friend",
  other: "Other"
};

class RegistrationForm extends PureComponent {
  getReferredOtherValue(value) {
    if (
      value &&
      typeof value === "string" &&
      value.includes(referredFrom.other.toLowerCase())
    ) {
      return {
        value: true,
        inputValue: value
          .split(" ")
          .slice(1)
          .join(" ")
      };
    } else {
      return { value: false, inputValue: "" };
    }
  }

  render() {
    const { dataUser, candidate } = this.props;
    const refOther = this.getReferredOtherValue(
      dataUser
        ? dataUser.regForm
          ? dataUser.regForm.referred
          : ""
        : candidate.referred
    );
    let referred =
      (dataUser && dataUser.regForm && dataUser.regForm.referred) ||
      (candidate && candidate.referred);
    return (
      <React.Fragment>
        <div className="registration-form ">
          <div className="">
            <Col sm={6} md={6}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>First name *</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.firstName) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.firstName)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>Last name *</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.lastName) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.lastName)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>Email *</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.email) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.email)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>Phone Number</ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>+</InputGroup.Addon>
                  <FormControl
                    type="string"
                    value={
                      (candidate &&
                        candidate.phone.substr(1, candidate.phone.length)) ||
                      (dataUser &&
                        dataUser.regForm &&
                        dataUser.regForm.phone.substr(
                          1,
                          dataUser.regForm.phone.length
                        ))
                    }
                    disabled={true}
                  />
                </InputGroup>
                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={12} md={12}>
              <h5 className="legally-auth-title">
                How were you referred to this form?
              </h5>
              <div>
                <div className="custom-control custom-radio">
                  <input
                    onChange={e => {}}
                    type="radio"
                    className="custom-control-input "
                    disabled={true}
                    checked={referred === referredFrom.facebookAd}
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    {referredFrom.facebookAd}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    onChange={e => {}}
                    type="radio"
                    className="custom-control-input "
                    disabled={true}
                    checked={referred === referredFrom.instagramAd}
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    {referredFrom.instagramAd}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    onChange={e => {}}
                    disabled={true}
                    type="radio"
                    className="custom-control-input "
                    checked={referred === referredFrom.onlineBoards}
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    {referredFrom.onlineBoards}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    onChange={e => {}}
                    disabled={true}
                    type="radio"
                    className="custom-control-input "
                    checked={referred === referredFrom.shupperzWebsite}
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    {referredFrom.shupperzWebsite}
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    onChange={e => {}}
                    disabled={true}
                    type="radio"
                    className="custom-control-input "
                    checked={referred === referredFrom.friend}
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    {referredFrom.friend}
                  </label>
                </div>
                <div className="custom-control custom-radio preferred-other">
                  <input
                    onChange={e => {}}
                    disabled={true}
                    type="radio"
                    className="custom-control-input "
                    checked={refOther.value}
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    {referredFrom.other}
                  </label>
                  <FormControl
                    className="input-checkbox-other"
                    type="text"
                    disabled={true}
                    value={refOther.inputValue}
                    placeholder="Enter text"
                    onChange={e => {}}
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} md={12}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>
                  Link to Social Media profiles (Facebook / Instagram)
                </ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.socials) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.socials)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>Country *</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.country) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.country)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup
                controlId="formBasicText"
                value={
                  (candidate && candidate.city) ||
                  (dataUser && dataUser.regForm && dataUser.regForm.city)
                }
              >
                <ControlLabel>City *</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.city) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.city)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={12} md={12}>
              <h5 className="legally-auth-title">Are you over 18? </h5>
              <div>
                <div className="custom-control custom-radio">
                  {console.log(
                    candidate && candidate.age === true
                      ? true
                      : false ||
                        (dataUser &&
                          dataUser.regForm &&
                          dataUser.regForm.age === true)
                        ? true
                        : false,
                    candidate && candidate.age === false
                      ? true
                      : false ||
                        (dataUser &&
                          dataUser.regForm &&
                          dataUser.regForm.age &&
                          dataUser.regForm.age === false)
                        ? true
                        : false
                  )}
                  <input
                    disabled={true}
                    type="radio"
                    className="custom-control-input "
                    checked={
                      candidate && candidate.age === true
                        ? true
                        : false ||
                          (dataUser &&
                            dataUser.regForm &&
                            dataUser.regForm.age === true)
                          ? true
                          : false
                    }
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input "
                    disabled={true}
                    checked={
                      candidate && candidate.age === false
                        ? true
                        : false ||
                          (dataUser &&
                            dataUser.regForm &&
                            dataUser.regForm.age === false)
                          ? true
                          : false
                    }
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    No
                  </label>
                </div>
              </div>
            </Col>

            <Col sm={12} md={12}>
              <h5 className="legally-auth-title">
                Are you legally authorized to work in your country?{" "}
              </h5>
              <div>
                <div className="custom-control custom-radio">
                  <input
                    disabled={true}
                    type="radio"
                    className="custom-control-input "
                    checked={
                      candidate && candidate.legalWork === true
                        ? true
                        : false ||
                          (dataUser &&
                            dataUser.regForm &&
                            dataUser.regForm.legalWork === true)
                          ? true
                          : false
                    }
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input "
                    disabled={true}
                    checked={
                      candidate && candidate.legalWork === false
                        ? true
                        : false ||
                          (dataUser &&
                            dataUser.regForm &&
                            dataUser.regForm.legalWork === false)
                          ? true
                          : false
                    }
                    required=""
                  />
                  <label
                    className="custom-control-label legally-auth-radio-label"
                    onClick={e => {}}
                  >
                    No
                  </label>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>
                  Please indicate any other languages aside from English that
                  you can communicate verbally and via text
                </ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.languages) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.languages)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>What is your primary occupation</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.occupation) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.occupation)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>Which cellular device do you use</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.device) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.device)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={12} md={12}>
              <h5 className="legally-auth-title">Preferred specialty </h5>
              <div className="preferred-checkobox-group">
                <Checkbox
                  inline
                  disabled={true}
                  className="checkbox-inline-preferred"
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Women's Fashion") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Women's Fashion") +
                        1)
                  }
                >
                  Women's Fashion
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Men's Fashion") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Men's Fashion") + 1)
                  }
                >
                  Men's Fashion
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Gadgets") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Gadgets") + 1)
                  }
                >
                  Gadgets
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Kids & babies") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Kids & babies") + 1)
                  }
                >
                  Kids & babies
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate && candidate.speciality.indexOf("Makeup") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Makeup") + 1)
                  }
                >
                  Makeup
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Designers") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Designers") + 1)
                  }
                >
                  Designers
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Low cost") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Low cost") + 1)
                  }
                >
                  Low cost
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Plus size") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Plus size") + 1)
                  }
                >
                  Plus size
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Home decor") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Home decor") + 1)
                  }
                >
                  Home decor
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.speciality.indexOf("Sports gear") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.speciality.indexOf("Sports gear") + 1)
                  }
                >
                  Sports gear
                </Checkbox>
                <div className="preferred-other">
                  <Checkbox
                    inline
                    className="checkbox-inline-preferred"
                    disabled={true}
                    checked={
                      (candidate &&
                        candidate.speciality.indexOf("other") + 1) ||
                      (dataUser &&
                        dataUser.regForm &&
                        dataUser.regForm.speciality.indexOf("other") + 1)
                    }
                  >
                    other
                  </Checkbox>
                  <FormControl
                    className="input-checkbox-other"
                    type="text"
                    disabled={true}
                    value={
                      candidate && candidate.speciality.indexOf("other") + 1
                        ? candidate.speciality[
                            candidate.speciality.indexOf("other") + 1
                          ]
                        : "" ||
                          (dataUser &&
                            dataUser.regForm &&
                            dataUser.regForm.speciality.indexOf("other") + 1)
                          ? dataUser.regForm.speciality[
                              dataUser.regForm.speciality.indexOf("other") + 1
                            ]
                          : ""
                    }
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={12}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>What are your 5 favorite Brands?</ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.favoriteBrands) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.favoriteBrands)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col sm={12} md={12}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>
                  Which 5 stores are the most accessible to you?
                </ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.accesibleStores) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.accesibleStores)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>

            <Col sm={12} md={12}>
              <h5 className="legally-auth-title">
                How do you usually know about sales and promotions in your area?{" "}
              </h5>
              <div className="preferred-checkobox-group">
                <Checkbox
                  inline
                  disabled={true}
                  className="checkbox-inline-preferred"
                  checked={
                    (candidate &&
                      candidate.salesNews.indexOf("Membership cards") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.salesNews.indexOf("Membership cards") +
                        1)
                  }
                >
                  Membership cards
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.salesNews.indexOf("Newsletters") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.salesNews.indexOf("Newsletters") + 1)
                  }
                >
                  Newsletters
                </Checkbox>
                <Checkbox
                  inline
                  disabled={true}
                  className="checkbox-inline-preferred"
                  checked={
                    (candidate &&
                      candidate.salesNews.indexOf("Social Media") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.salesNews.indexOf("Social Media") + 1)
                  }
                >
                  Social Media
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate && candidate.salesNews.indexOf("Friends") + 1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.salesNews.indexOf("Friends") + 1)
                  }
                >
                  Friends
                </Checkbox>
                <Checkbox
                  inline
                  className="checkbox-inline-preferred"
                  disabled={true}
                  checked={
                    (candidate &&
                      candidate.salesNews.indexOf("I don’t get any updates") +
                        1) ||
                    (dataUser &&
                      dataUser.regForm &&
                      dataUser.regForm.salesNews.indexOf(
                        "I don’t get any updates"
                      ) + 1)
                  }
                >
                  I don’t get any updates
                </Checkbox>

                <div className="preferred-other">
                  <Checkbox
                    inline
                    className="checkbox-inline-preferred"
                    disabled={true}
                    checked={
                      (candidate && candidate.salesNews.indexOf("other") + 1) ||
                      (dataUser &&
                        dataUser.regForm &&
                        dataUser.regForm.salesNews.indexOf("other") + 1)
                    }
                  >
                    other
                  </Checkbox>
                  <FormControl
                    className="input-checkbox-other"
                    type="text"
                    value={
                      candidate && candidate.salesNews.indexOf("other") + 1
                        ? candidate.salesNews[
                            candidate.salesNews.indexOf("other") + 1
                          ]
                        : "" ||
                          (dataUser &&
                            dataUser.regForm &&
                            dataUser.regForm.salesNews.indexOf("other") + 1)
                          ? dataUser.regForm.salesNews[
                              dataUser.regForm.salesNews.indexOf("other") + 1
                            ]
                          : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={12}>
              <FormGroup controlId="formBasicText">
                <ControlLabel>
                  What else would you like us to know about you?
                </ControlLabel>
                <FormControl
                  type="text"
                  value={
                    (candidate && candidate.about) ||
                    (dataUser && dataUser.regForm && dataUser.regForm.about)
                  }
                  disabled={true}
                />

                <FormControl.Feedback />
              </FormGroup>
            </Col>
            {(candidate && candidate.cvLink) ||
            (dataUser && dataUser.regForm && dataUser.regForm.cvLink) ? (
              <Col sm={12} md={12}>
                <FormGroup controlId="formBasicText">
                  <a
                    href={
                      (candidate && candidate.cvLink) ||
                      (dataUser && dataUser.regForm && dataUser.regForm.cvLink)
                    }
                    download="CV"
                    target="_blank"
                  >
                    <RaisedButton label="Download CV" primary />
                  </a>

                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistrationForm;
