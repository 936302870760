import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import English from './locale/en.json';
import Russian from './locale/ru.json';

const lng = process.env.REACT_APP_LANGUAGE || localStorage.getItem('i18nextLng') || 'en';

i18next
  .use(initReactI18next) // Passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      lookupQuerystring: 'lang',
      order: ['querystring', 'localStorage', 'header'],
    },
    fallbackLng: lng,
    resources: {
      en: {
        common: English,
      },

      ru: {
        common: Russian,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '.',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ul', 'li'],
    },
  });

export default i18next;
