import {takeLatest, takeEvery, put, call, all, select} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {fetchOrdersSuccess, fetchOrdersFailed, fetchPaymentSuccess, fetchPaymentFailed} from '../actions/ordersActions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import uploadImage from '../utils/networkutil';

const baseUrl = getBaseUrl();

const selectOrdersState = state => state.ordersReducer;

function* fetchOrdersAsync(action) {
  const orderState = yield select(selectOrdersState);
  const dateFilter = !orderState?.dateFilter.from
    ? ''
    : `&fromDate=${orderState?.dateFilter?.from}&toDate=${orderState?.dateFilter?.to}`;

  const packageFil =
    orderState.packageFilter && orderState?.packageSubFilter === 'default'
      ? `&packageStatus=${orderState?.packageFilter}`
      : '';
  const packageSubFil =
    orderState?.packageSubFilter !== '' && orderState?.packageSubFilter !== 'default'
      ? `&packageStatus=2&packageSubStatus=${orderState?.packageSubFilter}`
      : '';
  const paymentFil = orderState.paymentFilter ? `&paymentRequestStatus=${orderState.paymentFilter}` : '';
  const userFil = orderState.userCountryFilter ? `&userCountry=${orderState.userCountryFilter} ` : '';
  const shupperFil = orderState.shupperCountryFilter ? `&shupperCountry=${orderState.shupperCountryFilter} ` : '';
  const otherFilters = `${packageFil}${packageSubFil}${paymentFil}${userFil}${shupperFil}`;
  const nameRegex = /^[a-zA-Z ]+$/;

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let searchQuery = '';
  const query = orderState?.searchQuery;

  if (query) {
    const isEmail = emailRegex.test(String(query));
    const isName = nameRegex.test(String(query));
    const search = `&search=${query}`;
    searchQuery = (isName && search) || (isEmail ? search : search);
  }

  const response = yield call(
    request,
    `${baseUrl}/api/v2/payment?limit=${orderState.limit}&skip=${orderState.offset}${searchQuery}${otherFilters}${dateFilter}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    yield put(fetchOrdersFailed(response.err.toString()));
  } else {
    yield put(fetchOrdersSuccess(response.data.data));
  }
}

function* fetchPaymentAsync(action) {
  const orderState = yield select(selectOrdersState);

  const response = yield call(request, `${baseUrl}/api/v2/payment/${action.query}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.err) {
    yield put(fetchPaymentFailed(response.err.toString()));
  } else {
    yield put(fetchPaymentSuccess(response.data.data));
  }
}

function* fetchOrders() {
  yield takeLatest([types.FETCH_ORDERS, types.FETCH_MORE_ORDERS], fetchOrdersAsync);
}
function* fetchPayment() {
  yield takeLatest([types.FETCH_PAYMENT], fetchPaymentAsync);
}

export default function* ordersSaga() {
  yield all([fetchOrders(), fetchPayment()]);
}
