import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import usersReducer from './usersReducer';
import usersReducerOld from './usersReducerOld';
import loginReducer from './loginReducer';
import eventsReducer from './eventsReducer';
import eventReducer from './eventReducer';
import storesReducer from './storesReducer';
import storeReducer from './storeReducer';
import packagesReducer from './packagesReducer';
import packageReducer from './packageReducer';
import itemReducer from './itemReducer';
import itemsReducer from './itemsReducer';
import eventsV2Reducer from './eventsV2Reducer';
import eventV2Reducer from './eventV2Reducer';
import itemsV2Reducer from './itemsV2Reducer';
import itemV2Reducer from './itemV2Reducer';
import brandsReducer from './brandsReducer';
import collectionsReducer from './collectionsReducer';
import collectionReducer from './collectionReducer';
import boUsersReducer from './boUsersReducer';
import boUserReducer from './boUserReducer';
import securityLogsReducer from './securityLogsReducer';
import couponReducer from './couponReducer';
import couponsReducer from './couponsReducer';
import canceled from './logistics/canceledReducer';
import completed from './logistics/completedReducer';
import pending from './logistics/pendingReducer';
import refunded from './logistics/refundedReducer';
import rejected from './logistics/rejectedReducer';
import shipping from './logistics/shippingReducer';
import singlePayment from './logistics/singlePaymentReducer';
import chatsReducer from './chatsReducer';
import inaccessibilityReducer from './timetablesReducer';
import brandReviewsReducer from './reviews';
import profileReducer from './profileReducer';
import checkinsReducer from './checkinsReducer';
import approveShuppersReducer from './approveShuppersReducer';
import approveBrandsReducer from './approveBrandsReducer';
import shippingPickup from './logistics/shippingPickupReducer';
import packageItem from './logistics/packageReducer';
import userProfileReducer from './userProfileReducer';
import countriesReducer from './countryReducer';
import settingsReducer from './settingsReducer';
import ordersReducer from './ordersReducer';

const reducer = combineReducers({
  usersReducer,
  userProfileReducer,
  usersReducerOld,
  loginReducer,
  eventsReducer,
  eventReducer,
  storesReducer,
  storeReducer,
  packagesReducer,
  packageReducer,
  itemReducer,
  itemsReducer,
  eventsV2Reducer,
  eventV2Reducer,
  itemV2Reducer,
  itemsV2Reducer,
  brandsReducer,
  collectionsReducer,
  collectionReducer,
  boUsersReducer,
  boUserReducer,
  securityLogsReducer,
  couponReducer,
  couponsReducer,
  brandReviewsReducer,
  chatsReducer,
  inaccessibilityReducer,
  logistics: combineReducers({
    canceled,
    completed,
    pending,
    refunded,
    rejected,
    shipping,
    singlePayment,
    shippingPickup,
    packageItem,
  }),
  routing: routerReducer,
  profileReducer,
  checkinsReducer,
  approveShuppersReducer,
  approveBrandsReducer,
  countriesReducer,
  settingsReducer,
  ordersReducer,
});

export default reducer;
