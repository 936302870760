import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';

export const withRouter = Component => {
  const Wrapper = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const query = useSearchParams();
    return <Component query={query} location={location} params={params} navigate={navigate} {...props} />;
  };

  return Wrapper;
};
