import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, TableCell, TableRow, TextField, MenuItem, Select } from '@material-ui/core';
import TableData from '../table';
import { ReactComponent as CancelIcon } from '../../static/images/deleteIcon.svg';
import { ReactComponent as Done } from '../../static/images/done.svg';
import { ReactComponent as EditIcon } from '../../static/images/editIcon.svg';
import { decodePermissions } from '../../utils/permissionUtil';
import { getPermission } from '../../utils/cookies';
import {
  getCountriesAttributes,
  editCurrentCountryAttributesData,
  updateAttributeCountry,
  addAttributeCountry,
  getZonesCountriesAttributes,
  editCurrentZoneAttributesData,
  fetchMoreCountriesAttributes,
} from '../../actions/settingsActions';
import { settingsCountriesAndZonesTab } from '../../components/table/tableStyles';
import { currencies } from '../../utils/currencies';
import { convertCurrencyToString } from '../../utils/countriesAndZones';
import { ToastNotification } from '../../utils/toastify';


const Attributes = () => {
  const permission = decodePermissions(getPermission());
  const dispatch = useDispatch();
  const attributesAllCountries = useSelector(({ settingsReducer }) => settingsReducer.countriesAttributes || {});
  const attributesUsaAllZones = useSelector(({ settingsReducer }) => settingsReducer.zonesUsaAttributes || {});

  const ccy = currencies?.map(c => {
    return { name: `${c.name}`, code: `${c.code}` }
  })
  useEffect(() => {
    if (permission.users) {
      dispatch(getCountriesAttributes());
      dispatch(getZonesCountriesAttributes(236));
    }
  }, []);
  const [isEditCountry, setIsEditCountry] = useState(false);
  const [isEditExpandRow, setIsEditExpandRow] = useState(false);
  const [currentCountryIndex, setCurrentCountryIndex] = useState(0);
  const [currentExpandRowIndex, setCurrentExpandRowIndex] = useState(0);
  const [oldData, setOldData] = useState({});
  const [allowEditNextRow, setAllowEditNextRow] = useState(true)
  const [isFieldsChanged, setIsFieldsChanged] = useState(false)

  const handleChangeCountryData = (index, evnt) => {
    const { name, value } = evnt.target;
    const newData = [...attributesAllCountries];
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (name === 'shuppersFee' && !value.match(rgx)) return
    if (name === 'shuppersFeeSteps' && !value.match(rgx)) return
    switch (name) {
      case 'currency':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 1)[0].number_value = value;
        break;
      case 'shuppersFee':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 2)[0].number_value = value || !value && null;
        break;
      case 'shuppersFeeSteps':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 3)[0].number_value = value || !value && null;
        break;
      case 'isEnabled':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 4)[0].string_value =
          value === 'Yes' ? 'true' : 'false';
        break;
      case 'isLocalTaxIncluded':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 5)[0].string_value =
          value === 'Yes' ? 'true' : 'false';
        break;
      case 'tipStep1':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 6)[0].array_of_num_value[0] = value || !value && null;
        break;
      case 'tipStep2':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 6)[0].array_of_num_value[1] = value || !value && null;
        break;
      case 'tipStep3':
        newData[index].attributes.filter(attribute => attribute.attribute_id === 6)[0].array_of_num_value[2] = value || !value && null;
        break;
      default:
        return newData;
    }
    if (isEditCountry) {
      dispatch(editCurrentCountryAttributesData(newData[index].attributes, index));
    }
    setIsFieldsChanged(true)
  };

  const handleChangeExpandableRow = (rowData, rowMeta, evnt, index) => {

    const { name, value } = evnt.target;
    const newZonesData = [...attributesUsaAllZones];

    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (name === 'shuppersFee' && !value.match(rgx)) return;
    if (name === 'shuppersFeeSteps' && !value.match(rgx)) return;
    switch (name) {
      case 'shuppersFee':
        newZonesData[index].attributes.filter(attribute => attribute.attribute_id === 2)[0].number_value = value;
        break;
      case 'shuppersFeeSteps':
        newZonesData[index].attributes.filter(attribute => attribute.attribute_id === 3)[0].number_value = value;
        break;
      case 'isEnabled':
        newZonesData[index].attributes.filter(attribute => attribute.attribute_id === 4)[0].string_value =
          value === 'Yes' ? 'true' : 'false';
        break;
      case 'zoneIsLocalTaxIncluded':
        newZonesData[index].attributes.filter(attribute => attribute.attribute_id === 5)[0].string_value =
          value === 'Yes' ? 'true' : 'false';
        break;
      case 'zoneTipStep1':
        newZonesData[index].attributes.filter(attribute => attribute.attribute_id === 6)[0].array_of_num_value[0] = value || !value && null
        break;
      case 'zoneTipStep2':
        newZonesData[index].attributes.filter(attribute => attribute.attribute_id === 6)[0].array_of_num_value[1] = value || !value && null
        break;
      case 'zoneTipStep3':
        newZonesData[index].attributes.filter(attribute => attribute.attribute_id === 6)[0].array_of_num_value[2] = value || !value && null
        break;
      default:
        return newZonesData;
    }

    if (isEditExpandRow) {
      dispatch(editCurrentZoneAttributesData(newZonesData[index].attributes, index));
    }
    setIsFieldsChanged(true)
  };

  const saveData = () => {
    const newData = isEditCountry ? [...attributesAllCountries] : [...attributesUsaAllZones];
    const newAttributesCountry = isEditCountry ? newData[currentCountryIndex].attributes : newData[currentExpandRowIndex].attributes;

    const newAttributes = newAttributesCountry.filter(attribute => attribute.attributeTypeId || attribute.number_value !== '' || attribute.string_value !== '')
      .map(attribute => {
        if (attribute.id) {
          attribute['attributeId'] = attribute.id;
          delete attribute.id;
        }
        attribute['zoneId'] = attribute.zone_id;
        delete attribute.zone_id;
        attribute['attributeTypeId'] = attribute.attribute_id;
        delete attribute.attribute_id;
        attribute['numberValue'] = attribute.number_value;
        delete attribute.number_value;
        attribute['stringValue'] = attribute.string_value;
        delete attribute.string_value;
        attribute['arrayOfNumValue'] = attribute.array_of_num_value;
        delete attribute.array_of_num_value;
        return attribute;
      });

    const attributesToUpdate = newAttributes.filter(attribute => attribute.attributeId);
    const attributesToAdd = newAttributes.filter(attribute => !attribute.attributeId);

    if (isEditCountry) {
      attributesToAdd.length > 0 &&
        dispatch(addAttributeCountry(attributesAllCountries[currentCountryIndex].id, attributesToAdd));
      attributesToUpdate.length > 0 &&
        dispatch(updateAttributeCountry(attributesAllCountries[currentCountryIndex].id, attributesToUpdate))

    } else {
      attributesToAdd.length > 0 &&
        dispatch(addAttributeCountry(attributesUsaAllZones[currentExpandRowIndex].id, attributesToAdd));;
      attributesToUpdate.length > 0 &&
        dispatch(updateAttributeCountry(attributesUsaAllZones[currentExpandRowIndex].id, attributesToUpdate))
    }

    setIsEditCountry(false);
    setIsEditExpandRow(false);
    setAllowEditNextRow(true)
    setIsFieldsChanged(false)
  };

  const editCountry = dataIndex => {
    const newData = JSON.parse(JSON.stringify(attributesAllCountries));
    for (let i = 1; i < 7; i++) {
      if (!newData[dataIndex].attributes.map(attribute => attribute.attribute_id).includes(i)) {
        newData[dataIndex].attributes.push({
          zone_id: newData[dataIndex].id,
          attribute_id: i,
          number_value: null,
          string_value: i === 4 || i === 5 ? 'false' : null,
          array_of_num_value: i === 6 && [null, null, null]
        });
      }
    }

    dispatch(editCurrentCountryAttributesData(newData[dataIndex].attributes, dataIndex));
    setCurrentCountryIndex(dataIndex);
    setIsEditCountry(true);
    setAllowEditNextRow(false)
  };

  const editExpandRow = (index, dataIndex) => {

    const newData = JSON.parse(JSON.stringify(attributesUsaAllZones));

    for (let i = 1; i < 7; i++) {
      if (!newData[index].attributes.map(attribute => attribute.attribute_id).includes(i)) {
        newData[index].attributes.push({
          zone_id: newData[index].id,
          attribute_id: i,
          number_value: null,
          string_value: i === 4 || i === 5 ? 'false' : null,
          array_of_num_value: i === 6 && [null, null, null]
        });
      }
    }

    dispatch(editCurrentZoneAttributesData(newData[index].attributes, index));
    setCurrentCountryIndex(dataIndex);
    setCurrentExpandRowIndex(index);
    setIsEditExpandRow(true);
    setAllowEditNextRow(false)
  };

  const handleFixOldValuesOfCountry = countryIndex => {
    const tempValue = JSON.parse(JSON.stringify(attributesAllCountries[countryIndex]));
    setOldData(tempValue);
  };
  const handleFixOldValuesOfExpandRow = (indexOfExpandRow) => {
    const tempValue = JSON.parse(JSON.stringify(attributesUsaAllZones[indexOfExpandRow]));
    setOldData(tempValue);
  };

  const cancelCountryChanges = countryIndex => {
    dispatch(editCurrentCountryAttributesData(oldData.attributes, countryIndex));
    setIsEditCountry(false);
    setAllowEditNextRow(true)
  };

  const cancelExpandRowChanges = (expandRowIndex, countryIndex) => {
    dispatch(editCurrentZoneAttributesData(oldData.attributes, countryIndex));
    setIsEditExpandRow(false);
    setAllowEditNextRow(true)
  };

  const attributesColumns = [
    {
      name: 'country',
      label: 'Country & Zone',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            background: '#ffffff',
            zIndex: 101,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
      },
    },
    {
      name: 'currency',
      label: 'Currency',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          const shupperCCY = convertCurrencyToString(parseFloat(data))
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name='currency'
                value={data}
                defaultValue='AED'
                displayEmpty={false}
                placeholder='aasd'
                renderValue={() => shupperCCY?.[0]?.code}
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}>
                {currencies.map(currency => (
                  <MenuItem selected={data} value={currency.id}>{currency.code}</MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            shupperCCY?.[0]?.code ?? 'Not Defined'
          );
        },
      },
    },
    {
      name: 'shuppersFee',
      label: 'Shupperz Fee',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                fullWidth={true}
                name="shuppersFee"
                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}
              />
            </div>
          ) : (
            data ?? 'Not Defined'
          );
        },
      },
    },
    {
      name: 'shuppersFeeSteps',
      label: 'Shuppers Fee Steps',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                fullWidth={true}
                name="shuppersFeeSteps"
                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}
              />
            </div>
          ) : (
            data ?? 'Not Defined'
          );
        },
      },
    },
    {
      name: 'isEnabled',
      label: 'Enabled',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                id="select"
                select
                fullWidth={true}
                name="isEnabled"
                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </div>
          ) : (
            data
          );
        },
      },
    },
    {
      name: 'isLocalTaxIncluded',
      label: 'Local Tax Included',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                id="select"
                select
                fullWidth={true}
                name="isLocalTaxIncluded"
                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </div>
          ) : (
            data
          );
        },
      },
    },
    {
      name: 'tipStep1',
      label: 'Tip Step 1',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                fullWidth={true}
                name="tipStep1"
                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}
              />
            </div>
          ) : (
            data ?? 'Not Defined'
          );
        },
      },
    },
    {
      name: 'tipStep2',
      label: 'Tip Step 2',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                fullWidth={true}
                name="tipStep2"
                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}
              />
            </div>
          ) : (
            data ?? 'Not Defined'
          );
        },
      },
    },
    {
      name: 'tipStep3',
      label: 'Tip Step 3',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
            <div>
              <TextField
                fullWidth={true}
                name="tipStep3"
                value={data}
                placeholder="Not Defined"
                onChange={evnt => handleChangeCountryData(dataIndex.rowIndex, evnt)}
              />
            </div>
          ) : (
            data ?? 'Not Defined'
          );
        },
      },
    },
    {
      name: 'action',
      label: 'Action',

      options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: 0,
            background: 'white',
            zIndex: 101,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <div>
              <div>
                {isEditCountry && dataIndex.rowIndex === currentCountryIndex ? (
                  <IconButton onClick={() => {
                    if (isFieldsChanged) {
                      saveData()
                    } else {
                      cancelCountryChanges(dataIndex.rowIndex);
                    }
                  }
                  }>
                    <Done />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      if (allowEditNextRow) {
                        editCountry(dataIndex.rowIndex);
                        handleFixOldValuesOfCountry(dataIndex.rowIndex)
                      };
                    }}>
                    <EditIcon />
                  </IconButton>
                )}
                {isEditCountry && dataIndex.rowIndex === currentCountryIndex && (
                  <IconButton
                    onClick={() => {
                      cancelCountryChanges(dataIndex.rowIndex);
                    }}

                  >
                    <CancelIcon />
                  </IconButton>
                )}
              </div>
            </div>
          );
        },
      },
    },
  ];
  const options = {
    expandableRowsHeader: false,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    search: false,
    download: false,
    print: false,
    filter: false,
    fixedHeader: true,
    tableBodyMaxHeight: '550px',
    expandableRowsOnClick: false,
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    onTableChange: (action, tableState) => {
      const lastPage = tableState?.rowsPerPage * tableState?.page + tableState?.rowsPerPage === attributesAllCountries?.length
      switch (action) {
        case "changePage":
          lastPage && dispatch(fetchMoreCountriesAttributes());
          break;
        case "changeRowsPerPage":
          lastPage && dispatch(fetchMoreCountriesAttributes());
          break;
      }
    },
    rowsPerPageOptions: [10, 25, 100],
    rowsExpanded: [currentCountryIndex],
    renderExpandableRow: (rowData, rowMeta) => {
      const defineUsa = rowData[0] === 'United States of America (the)';

      return (
        defineUsa && attributesUsaAllZones?.map((expandRow, index) => (
          <TableRow key={index}>
            <TableCell colSpan={2} style={{ position: 'sticky', left: '0', background: '#ffffff' }}>
              {expandRow?.name}

            </TableCell>
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                {'USD'}
              </TableCell>
            ) : (
              <TableCell colSpan={1}>
                {'USD'}
              </TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                <TextField
                  fullWidth={true}
                  name="shuppersFee"
                  value={expandRow?.attributes.filter(item => item.attribute_id === 2)[0]?.number_value}
                  placeholder="Not Defigned"
                  onChange={e => handleChangeExpandableRow(rowData, rowMeta, e, index)}
                />
              </TableCell>
            ) : (
              <TableCell>{expandRow?.attributes?.filter(item => item?.attribute_id === 2)[0]?.number_value ?? 'Not Defined'}</TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                <TextField
                  fullWidth={true}
                  name="shuppersFeeSteps"
                  value={expandRow?.attributes?.filter(item => item?.attribute_id === 3)[0]?.number_value}
                  placeholder="Not Defigned"
                  onChange={e => handleChangeExpandableRow(rowData, rowMeta, e, index)}
                />
              </TableCell>
            ) : (
              <TableCell>{expandRow?.attributes?.filter(item => item?.attribute_id === 3)[0]?.number_value ?? 'Not Defined'}</TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                <TextField
                  id="select"
                  select
                  fullWidth={true}
                  name="isEnabled"
                  value={expandRow?.attributes?.filter(item => item?.attribute_id === 4)[0]?.string_value === "true" ? 'Yes' : 'No'}
                  placeholder="Not Defined"
                  onChange={e => handleChangeExpandableRow(rowData, rowMeta, e, index)}>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </TableCell>
            ) : (
              <TableCell>{expandRow?.attributes?.filter(item => item?.attribute_id === 4)[0]?.string_value === "true" ? 'Yes' : 'No' ?? 'Not Defined'}</TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                <TextField
                  id="select"
                  select
                  fullWidth={true}
                  name="zoneIsLocalTaxIncluded"
                  value={expandRow?.attributes?.filter(item => item?.attribute_id === 5)[0]?.string_value === "true" ? 'Yes' : 'No'}
                  placeholder="Not Defined"
                  onChange={e => handleChangeExpandableRow(rowData, rowMeta, e, index)}>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </TableCell>
            ) : (
              <TableCell>{expandRow?.attributes?.filter(item => item?.attribute_id === 5)[0]?.string_value === "true" ? 'Yes' : 'No' ?? 'Not Defined'}</TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                <TextField
                  fullWidth={true}
                  name="zoneTipStep1"
                  value={expandRow?.attributes?.filter(item => item?.attribute_id === 6)[0]?.array_of_num_value?.[0]}
                  placeholder="Not Defined"
                  onChange={e => handleChangeExpandableRow(rowData, rowMeta, e, index)}
                />
              </TableCell>
            ) : (
              < TableCell> {expandRow?.attributes?.filter(item => item?.attribute_id === 6)[0]?.array_of_num_value?.[0] ?? 'Not Defined'}</TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                <TextField
                  fullWidth={true}
                  name="zoneTipStep2"
                  value={expandRow?.attributes?.filter(item => item?.attribute_id === 6)[0]?.array_of_num_value?.[1]}
                  placeholder="Not Defined"
                  onChange={e => handleChangeExpandableRow(rowData, rowMeta, e, index)}
                />
              </TableCell>
            ) : (
              <TableCell>{expandRow?.attributes?.filter(item => item?.attribute_id === 6)[0]?.array_of_num_value?.[1]}</TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell>
                <TextField
                  fullWidth={true}
                  name="zoneTipStep3"
                  value={expandRow?.attributes?.filter(item => item?.attribute_id === 6)[0]?.array_of_num_value?.[2]}
                  placeholder="Not Defigned"
                  onChange={e => handleChangeExpandableRow(rowData, rowMeta, e, index)}
                />
              </TableCell>
            ) : (
              <TableCell>{expandRow?.attributes?.filter(item => item.attribute_id === 6)[0]?.array_of_num_value?.[2]}</TableCell>
            )}
            {isEditExpandRow && rowMeta.dataIndex === currentCountryIndex && index === currentExpandRowIndex ? (
              <TableCell style={{ position: 'sticky', right: '0', background: '#ffffff' }}>
                <div >
                  {
                    <IconButton onClick={() => {
                      if (isFieldsChanged) {
                        saveData()
                      } else {
                        cancelExpandRowChanges(index, rowMeta.dataIndex);
                      }
                    }
                    }>
                      <Done />
                    </IconButton>
                  }
                  <IconButton
                    onClick={() => {
                      cancelExpandRowChanges(index, rowMeta.dataIndex);
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </TableCell>
            ) : (
              <TableCell style={{ position: 'sticky', right: '0', background: '#ffffff' }}>
                <div >
                  {
                    <IconButton
                      onClick={() => {
                        if (allowEditNextRow) {
                          editExpandRow(index, rowMeta.dataIndex);
                          handleFixOldValuesOfExpandRow(index, rowMeta.dataIndex);
                        }
                      }}>
                      <EditIcon />
                    </IconButton>
                  }
                </div>
              </TableCell>
            )}
          </TableRow>
        ))
      );
    },
  };

  return (
    <div className="table-wrapper">
      <TableData
        headerColumn={attributesColumns}
        width="100%"
        tableName="attributes"
        tablePagination={true}
        data={attributesAllCountries}
        optionsExpandableRow={options}
        customTheme={settingsCountriesAndZonesTab}
      />
      <ToastNotification position="top-right" hideBar={true} close={false} theme="colored" width="150px" className="toast-container" />
    </div>
  );
};

export default Attributes;
