import React, { useCallback } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { TextField } from '@material-ui/core';
import { InputAdornment, IconButton, withStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core';
import { Field } from 'formik';

const useStyles = makeStyles(theme => ({
  error: {
    width: '100%',
    marginTop: 15,
    '& .Mui-error': {
      position: 'relative',
    },
    '& .MuiFormHelperText-root': { position: 'absolute', top: 46 },
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    }
  },
}));

const InputField = withStyles({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #1B1B1B',
        borderRadius: '8px',
        height: '48px',
        margin: '6px 0px 10px',
      },
    },
  },
})(TextField);

export const LoginFields = ({ t, showPass, showPassword, loginError, ...props }) => {
  const classes = useStyles();

  return [
    <Field
      className={classes.error}
      required
      as={InputField}
      variant="outlined"
      autoComplete="email"
      name="email"
      placeholder={t('email')}
      error={props.touched.email && Boolean(props.errors.email)}
      helperText={props.touched.email && props.errors.email}
      InputProps={{
        classes: { input: classes.input }
      }}
    />,
    <br key="1" />,
    <Field
      as={InputField}
      required
      variant="outlined"
      autoComplete="current-password"
      name="password"
      className={classes.error}
      placeholder={t('password')}
      type={showPassword ? 'text' : 'password'}
      error={props.touched.password && Boolean(props.errors.password) || loginError}
      helperText={
        (props.touched.password && props.errors.password) ||
        (loginError && <div style={{ color: 'red' }}>{loginError}</div>)
      }
      InputProps={{
        classes: { input: classes.input },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={showPass}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />,
    <br key="3" />,
  ];
};

export const PhoneFields = ({ countryCode, phone, errorPhone, onChange }) => {
  const handleInput = useCallback(
    (phoneNumber, data) => {
      onChange({ phone: phoneNumber, countryCode: data.countryCode, errorPhone: '' });
    },
    [onChange],
  );
  return [
    <ReactPhoneInput key="0" defaultCountry={countryCode} value={phone} onChange={handleInput} />,
    <span key="1" className="error-phone">
      {errorPhone}
    </span>,
  ];
};
export const TokenFields = ({ authyToken, loginError, onChange, ...props }) => {
  const handleInput = useCallback(
    event => {
      onChange({ authyToken: event.target.value });
    },
    [onChange],
  );
  return [
    <Field
      as={InputField}
      key="0"
      value={authyToken}
      variant="outlined"
      id="token-input"
      onChange={handleInput}
      style={{ width: '100%' }}
      label="SMS token"
      floatingLabelFixed
      error={props.touched.authyToken && Boolean(props.errors.authyToken)}
      helperText={
        (props.touched.authyToken && props.errors.authyToken) ||
        (loginError && <div style={{ color: 'red' }}>{loginError}</div>)
      }
    />,
    <br key="1" />,
  ];
};