/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 16/04/2018.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import IconButton from 'material-ui/IconButton';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import PaginatedGrid from '../components/paginatedgrid';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';
import * as securityLogActions from '../actions/securitylogactions';

import '../static/styles/securitylogs.css';
import { tableData } from '../utils/tableData';



class SecurityLogs extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = { permission };
  }

  componentDidMount() {
    if (this.state.permission.securitylogs) {
      this.props.fetchLogs();
    }
  }

  render() {
    const { securityLogs: { columnWidths, headerData } } = tableData
    const { securityLogsReducer, onOpenDrawer, fetchMoreLogs } = this.props;
    const { permission } = this.state;
    const tableHeight = window.innerHeight - 177;

    return permission.securitylogs ?
      <div className="securitylogs-container">
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
        </div>

        <PaginatedGrid
          data={securityLogsReducer.logs}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          header={headerData}
          hasMore={securityLogsReducer.hasMore}
          isLoading={securityLogsReducer.isLoading}
          totalResults={securityLogsReducer.totalCount}
          onLoadMore={() => fetchMoreLogs()}
          items={securityLogsReducer.logs.map(log => [
            <span className="word-wrap">{log.url}</span>,
            <span>{log.user ? `${log.user.fullName} ${log.user.email}` : 'Null'}</span>,
            <span>{log.method}</span>,
            <span>{moment(log.created).format('lll')}</span>,
            <span>{log.ip}</span>,
            <span className="word-wrap">{log.result}</span>,
            <span className="word-wrap">{log.description}</span>,
            <span className="word-wrap">{JSON.stringify(log.body)}</span>,
          ])}
        />
      </div>
      : <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

SecurityLogs.propTypes = {
  fetchLogs: PropTypes.func.isRequired,
  fetchMoreLogs: PropTypes.func.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  securityLogsReducer: PropTypes.object.isRequired,
};

const mapStateToProps = ({ securityLogsReducer }) => ({
  securityLogsReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchLogs: query => dispatch(securityLogActions.fetchLogs(query)),
  fetchMoreLogs: query => dispatch(securityLogActions.fetchMoreLogs(query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityLogs);
