/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 20/06/2018.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import SetupIcon from 'material-ui/svg-icons/action/build';
import IconButton from 'material-ui/IconButton';
import { RaisedButton } from 'material-ui';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import NoPermission from '../../../components/nopermission';
import { getPermission } from '../../../utils/cookies';
import { decodePermissions } from '../../../utils/permissionUtil';
import PaginatedGrid from '../../../components/paginatedgrid';
import * as rejectedActions from '../../../actions/logistics/rejectedactions';
import ImageViewer from '../../../components/imageviewer';
import SearchField from '../../../components/searchfield';
import SelectDialog from '../../../components/SelectDialog';
import { changePaymentStatus } from '../../../actions/logistics/singlePaymentActions';
import getBaseUrl from '../../../utils/envSetup';
import { tableData } from '../../../utils/tableData';

const baseUrl = getBaseUrl();
class Rejected extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
      isOpenImageViewer: false,
      images: [],
      changeStatus: false,
      itemToChangeStatus: null,
      itemToChangeStatusKey: null,
    };
  }

  componentDidMount() {
    if (this.state.permission.seeRejected) {
      this.props.fetchRejected();
    }
  }

  openImageViewer = images => {
    this.setState({
      isOpenImageViewer: true,
      images,
    });
  };

  closeImageViewer = () => {
    this.setState({
      isOpenImageViewer: false,
    });
  };

  openStatusDialog = (itemToChangeStatus, itemToChangeStatusKey) => () => {
    this.setState({
      changeStatus: true,
      itemToChangeStatus,
      itemToChangeStatusKey,
    });
  };

  closeStatusDialog = () => {
    this.setState({
      changeStatus: false,
    });
  };

  onDateRangeSet(startDate, endDate) {
    this.props.fetchRejectedDateRangeChanges(startDate, endDate);
  }

  doSearch(query) {
    this.props.fetchRejected(query);
  }

  render() {
    const { logisticsRejected: { columnWidths, headerData } } = tableData
    const {
      onOpenDrawer, fetchMoreRejected, rejectedList, hasMore, isLoading, totalCount, sort,
      fetchRejectedSortChanged, startDate, endDate, changePaymentStatus,
    } = this.props;
    const { permission, isOpenImageViewer, images, itemToChangeStatus, itemToChangeStatusKey, changeStatus } = this.state;
    const tableHeight = window.innerHeight - 217;

    return permission.seeRejected ?
      <div className="logistics-container">
        <SelectDialog
          items={['cancelled', 'fulfilled', 'pending', 'refunded', 'rejected', 'shipping']}
          type="status"
          handleChange={changePaymentStatus}
          value="rejected"
          payment={itemToChangeStatus}
          position={itemToChangeStatusKey}
          display={changeStatus}
          close={this.closeStatusDialog}
        />
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <div className="header-search-area">
            <SearchField onRequestSearch={query => this.doSearch(query)} />
          </div>
          <div className="logistics-date-range-picker-cont">
            <DateRangePicker
              drops="down"
              opens="center"
              startDate={startDate
                ? moment(startDate)
                : moment('01/01/2017', 'DD/MM/YYYY')
              }
              endDate={endDate ? moment(endDate) : moment()}
              timePicker
              timePicker24Hour
              showDropdowns
              onApply={(ev, picker) => {
                this.onDateRangeSet(
                  picker.startDate.toDate().toJSON(),
                  picker.endDate.toDate().toJSON(),
                );
              }}
              ranges={{
                'All Times': [moment('01/01/2017', 'DD/MM/YYYY'), moment()],
                'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Month to date': [moment().startOf('month'), moment()],
              }}
            >
              <RaisedButton
                label={startDate && endDate
                  ? `${moment(startDate).format('lll')}
										- ${moment(endDate).format('lll')}`
                  : 'Pick date range'}
                primary
              />
            </DateRangePicker>
          </div>
          <a href={baseUrl + '/api/logistics/rejected?csv=true' + (startDate ?
            `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '')} download >
            <RaisedButton
              label="Download CSV"
              primary
            />
          </a>
        </div>

        <div className="logistics-collections-tabs">
          <Link to="/logistics/pending" className="logistics-collections-tab">Pending</Link>
          <Link to="/logistics/rejected" className="logistics-collections-tab logistics-collections-tab-active">Rejected</Link>
          <Link to="/logistics/canceled" className="logistics-collections-tab">Canceled</Link>
          <Link to="/logistics/completed" className="logistics-collections-tab">Completed</Link>
          <Link to="/logistics/refunded" className="logistics-collections-tab">Refunded</Link>
          <Link to="/logistics/shipping" className="logistics-collections-tab">Shipping</Link>
        </div>

        <PaginatedGrid
          data={rejectedList}
          tableHeight={tableHeight}
          columnWidths={columnWidths}
          hasMore={hasMore}
          isLoading={isLoading}
          header={headerData}
          totalResults={totalCount}
          sortableCols={headerData[0].id}
          currentSort={sort}
          onSort={(column, direction) => fetchRejectedSortChanged(column, direction)}
          items={rejectedList.map((item, key) => {
            const dateTime = moment(item.created).format("YYYY-MM-DD HH:mm:ss");
            const images = item.receipts && item.receipts.map(receipt => ({ src: receipt.imageUrl || '' }));
            return [
              <Link to={`/logistics/payment/${item._id}`}><span>{dateTime}</span></Link>,
              <span>{item.user && item.user.displayName}</span>,
              <span>{item.user && item.user.email}</span>,
              <span>{item.shupper && item.shupper.displayName}</span>,
              <span>{item.receipts.length}</span>,
              <span>
                <img
                  alt=""
                  className="avatar viewer"
                  src={images[0] ? images[0].src : ''}
                  onClick={() => images.length && this.openImageViewer(images)}
                />
              </span>,
              <span>{item.receipts.map(receipt => receipt.price).join(', ')}</span>,
              <span>{item.itemsTotalPrice}</span>,
              <span>{item.deliveryFee}</span>,
              <span>{item.taxes}</span>,
              <span>{item.payeeFee}</span>,
              <span>{item.shupperzFee}</span>,
              <span>{item.defaultShupperzFee}</span>,
              <span>
                {
                  permission.changePaymentStatus ?
                    <IconButton onClick={this.openStatusDialog(item, key)}>
                      <SetupIcon color="black" />
                    </IconButton>
                    : null
                }
              </span>
            ]
          })}
          onLoadMore={fetchMoreRejected}
        />
        <ImageViewer isOpen={isOpenImageViewer} onClose={this.closeImageViewer} images={images} />
      </div>
      :
      <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

Rejected.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
  rejectedList: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  fetchRejected: PropTypes.func.isRequired,
  fetchMoreRejected: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  fetchRejectedSortChanged: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  fetchRejectedDateRangeChanges: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  rejectedList: state.logistics.rejected.logistics,
  hasMore: state.logistics.rejected.hasMore,
  isLoading: state.logistics.rejected.isLoading,
  totalCount: state.logistics.rejected.totalCount,
  sort: state.logistics.rejected.sort,
  startDate: state.logistics.rejected.startDate,
  endDate: state.logistics.rejected.endDate,
});

const mapDispatchToProps = dispatch => ({
  fetchRejected: query => dispatch(rejectedActions.fetchRejected(query)),
  fetchMoreRejected: () => dispatch(rejectedActions.fetchMoreRejected()),
  fetchRejectedSortChanged: (column, direction) =>
    dispatch(rejectedActions.fetchRejectedSortChanged(column, direction)),
  fetchRejectedDateRangeChanges: (startDate, endDate) =>
    dispatch(rejectedActions.fetchRejectedDateRangeChanges(startDate, endDate)),
  changePaymentStatus: ({ prevStatus, payment, status, position }) =>
    dispatch(changePaymentStatus({ prevStatus, payment, status, position })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Rejected);
