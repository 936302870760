import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import NoPermission from '../components/nopermission';
import { getPermission } from '../utils/cookies';
import { decodePermissions } from '../utils/permissionUtil';

class ChatList extends Component {
  constructor(props) {
    super(props);
    const permission = decodePermissions(getPermission());
    this.state = {
      permission,
    };
  }

  render() {
    const { onOpenDrawer } = this.props;
    return this.state.permission.chats ?
      <div>
        <div className="App-header">
          <IconButton onClick={onOpenDrawer}>
            <NavigationMenu color="white" />
          </IconButton>
          <span className="header-placeholder" />
        </div>
        <iframe
          title="shuppers"
          src="http://chats.shupperz.com:7000/"
          width="100%"
          height={window.innerHeight - 60}
        >
          The browser does not support iframes.
        </iframe>
      </div>
      :
      <NoPermission onOpenDrawer={onOpenDrawer} />;
  }
}

ChatList.propTypes = {
  onOpenDrawer: PropTypes.func.isRequired,
};

export default ChatList;
