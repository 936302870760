
import types from '../actions/actionTypes';

const initialState = {
  offset: 0,
  limit: 100,
  items: [],
  totalCount: 0,
  isLoading: false,
  hasMore: false,
  searchQuery: '',
  itemCountry: null,
  brandFilter: null,
  dateFilter: {},
};

export default function itemsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ITEMS_V2:
      return Object.assign({}, state, {
        isLoading: true,
        offset: 0,
        items: [],
        totalCount: 0,
        searchQuery: action?.data?.query,
        itemCountry: action?.data?.itemCountry,
        brandFilter: action?.data?.brandFilter,
        dateFilter: action?.data?.dateFilter,
      });
    case types.FETCH_MORE_ITEMS_V2:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_ITEMS_V2_SUCCESS: {
      const items = [].concat(state.items).concat(action.data.items);
      return Object.assign({}, state, {
        offset: state.offset + state.limit,
        totalCount: action.data.count,
        items,
        hasMore: items.length < action.data.count,
        isLoading: false,
      });
    }
    case types.FETCH_ITEMS_V2_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        hasMore: false,
      });
    default:
      return state;
  }
}
