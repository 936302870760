const configObj = {
  development: {
    baseUrl: 'http://localhost:4000',
    chatsUrl: 'http://localhost:81/',
    chatsItemsPerPage: 50,
    autoRenewChatsDelay: 60, // seconds
  },
  production: {
    baseUrl: 'https://prod-at.shupperzushops.com:5000',
    chatsUrl: 'https://prod-chats.shupperzushops.com/',
    chatsItemsPerPage: 50,
    autoRenewChatsDelay: 10,
  },
};

export const config = propName => {
  const env = process.env.REACT_APP_ENV || 'development';
  if (propName == 'baseUrl' && process.env.REACT_APP_ADMIN_BACKEND_URL) return process.env.REACT_APP_ADMIN_BACKEND_URL;
  if (propName == 'chatsUrl' && process.env.REACT_APP_CHAT_BACKEND_URL) return process.env.REACT_APP_CHAT_BACKEND_URL;

  return configObj[env] ? configObj[env][propName] : null;
};

export default function getBaseUrl() {
  return config('baseUrl');
}
