export const tableColors = [
  {
    color: '#E50C31',
    status: 'Inactive',
  },
  {
    color: '#E50C31',
    status: 'invalid code',
  },

  {
    color: '#17A001',
    status: 'Active',
  },
  {
    color: '#8D8D8D',
    status: 'Offline',
  },
  {
    color: '#007AFF',
    email: 'email',
  },
];
export const paymentColors = [
  {
    color: '#E50C31',
    payment: 'Not Paid',
  },
  {
    color: '#007AFF',
    payment: 'Sent',
  },
  {
    color: '#007AFF',
    payment: 'Received',
  },
  {
    color: '#E60C31',
    payment: 'Disputed',
  },
  {
    color: '#E50C31',
    payment: 'Canceled',
  },
  {
    color: '#8D8D8D',
    payment: 'Pending',
  },
  {
    color: '#8D8D8D',
    payment: 'Not Created',
  },
  {
    color: '#17A001',
    payment: 'Completed',
  },
  {
    color: '#17A001',
    payment: 'Open',
  },
  {
    color: '#17A001',
    payment: 'Confirmed',
  },
  {
    color: '#E50C31',
    payment: 'Not Confirmed',
  },
  {
    color: '#E50C31',
    payment: 'invalid code',
  },
];
