/*
Kateryna Ivanova
20/03/2018

Reducer for user login
*/
import types from '../actions/actionTypes';
import { getUserId } from '../utils/cookies';

const initialState = {
  loginError: null,
  userId: getUserId(),
  username: null,
  authyId: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADMIN_LOGIN:
      return state;
    case types.ADMIN_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        username: action.user.fullName,
        userId: action.user._id,
        loginError: null,
        authyId: action.user.authyId,
      });
    case types.ADMIN_LOGIN_FAILED:
      return Object.assign({}, state, {
        loginError: action.reason,
        username: null,
      });
    case types.ADMIN_LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        userId: '',
        username: null,
        authyId: null,
      });
    case types.LOGIN_2FA_SUCCESS:
      return {
        ...state,
        userId: action.user._id,
        loginError: null,
      };
    case types.LOGIN_2FA_FAILED:
      return {
        ...state,
        loginError: action.reason,
      };
    case types.REQUEST_PHONE_SUCCESS:
      return {
        ...state,
        authyId: action.authyId,
        loginError: null,
      };
    case types.REQUEST_PHONE_FAILED:
      return {
        ...state,
        loginError: action.reason,
      };
    default:
      return state;
  }
}
