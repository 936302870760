import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import actionTypes from '../actions/actionTypes';
import {
	setPopupsList, setDaysList, createNewTimeTable, stopLoading,
	setUpdateFlag, setError, setSuccess,
} from '../actions/inaccessibilityActions';
import req from '../utils/networkRequest';
import { config } from '../utils/envSetup';
import uploadImage from '../utils/networkutil';
const API_URL = config('baseUrl');

const SECONDS_IN_A_DAY = 86400;

const getReqOpts = {
	method: 'GET',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
};

const denormalizeTimetable = timetable => {
	const result = {};
	let transferredFromSaturday = 0;
	for(let key in timetable) {
		const day = parseInt(key, 10);
		const { from, to, popupType, transferredTime  } = timetable[day];
		if(day === 0) transferredFromSaturday = transferredTime;
		const calculatedTo = day === 6 ? to + transferredFromSaturday : to + timetable[day+1].transferredTime ;
		result[day] = { from, to: calculatedTo, popupType, nextDay: calculatedTo > SECONDS_IN_A_DAY}
	}
	return result;
};

const normalizeTimetable = inaccessibility => Object.keys(inaccessibility).reduce(
	(acc, val) => [ ...acc, {
		from: inaccessibility[val].from,
		to: inaccessibility[val].to,
		popupType: inaccessibility[val].popupType,
		day: val,
	} ],
	[]
);

const normalizePopups = popups => Object.keys(popups).reduce(
	(acc, val) => [ ...acc, {
		popupHeader: popups[val].popupHeader,
		popupText: popups[val].popupText,
		popupType: popups[val].popupType,
		popupIcon: popups[val].icon,
		image: popups[val].image,
	}], []
);

function* getDefaultValuesDB() {
	const {data} = yield call(
		req,
		`${API_URL}/api/inaccessibility/default`,
		{ ...getReqOpts }
	);
	if(data) {
		yield true;
		const { popups } = data;
		const popupList = popups.reduce((acc, {popupType, popupHeader, popupText, popupIcon}) =>
			({ ...acc, [popupType]: { popupType, popupHeader, icon: popupIcon, popupText } }), {});
		yield put(setPopupsList(popupList));
	}
}

function* loadUsersTimetable({payload}) {
	const {userId} = payload;
	const {data} = yield call(
		req,
		`${API_URL}/api/inaccessibility/${userId}`,
		{ ...getReqOpts }
	);
	if(data) {
		const {inaccessibility, popups} = data;
		if(popups.length) {
			const popupList = popups.reduce((acc, {popupType, popupHeader, popupText, popupIcon}) =>
				({...acc, [popupType]: {popupType, popupHeader, icon: popupIcon, popupText}}), {});
			yield put(setPopupsList(popupList, true));
		}
		const timetable = inaccessibility.reduce((acc, dayObj) => ({ ...acc, [dayObj.day]: { ...dayObj }}), {});
		yield put(setDaysList(denormalizeTimetable(timetable)));
		
	} else yield put(createNewTimeTable());
	yield put(stopLoading());
}

function* uploadPopupImage(userId, icon) {
	let profileImageURL;
	const response = yield call(uploadImage, 'event', userId, icon);
	if (response.err) {
		console.log(response.err);
		return;
	}
	profileImageURL = response.data.imgUrl;
	return profileImageURL;
}

function* saveTimetableChanges({payload}) {
	const {userId, inaccessibility, popups, newTimetable, setDefaultPopups} = payload;
	const normalizedTimetable = normalizeTimetable(inaccessibility);
	const normalizedPopups = normalizePopups(popups);
	if(normalizedPopups[0].image) normalizedPopups[0].popupIcon =
		yield uploadPopupImage(userId, normalizedPopups[0].image);
	if(normalizedPopups[1].image) normalizedPopups[1].popupIcon =
		yield uploadPopupImage(userId, normalizedPopups[1].image);
	const response = yield call(
		req,
		`${API_URL}/api/inaccessibility/${userId}${setDefaultPopups ? '?defaultPopups=true' : ''}`, {
			method: newTimetable ? 'POST' : 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify( {timeTable: { inaccessibility: normalizedTimetable, popups: normalizedPopups, } })
		}
	);
	yield put(stopLoading({ newTimetable: false }));
	if(response.data) {
		yield put(setUpdateFlag(false));
		yield put(setSuccess());
	} else yield put(setError());
}

function* watchSetUserId() {
	yield takeLatest(actionTypes.SET_USER_ID, loadUsersTimetable);
}

function* watchSaveTimetable() {
	yield takeLatest(actionTypes.SAVE_TIMETABLE_CHANGES, saveTimetableChanges);
}

function* watchGetDefaultValuesDB() {
	yield takeLatest(actionTypes.GET_DEFAULT_VALUES, getDefaultValuesDB);
}

export default function* inaccessibilitySaga() {
	yield all([
		watchSetUserId(),
		watchSaveTimetable(),
		watchGetDefaultValuesDB(),
	])
}