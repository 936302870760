import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import SearchField from '../components/searchfield';
import * as usersActions from '../actions/usersactions';
import { getPermission } from '../utils/cookies';
import NoPermission from '../components/nopermission';
import { decodePermissions } from '../utils/permissionUtil';
import { withRouter } from '../utils/withRouter';
import { tableData } from '../utils/tableData';
import { IconButton, makeStyles, Menu, Typography } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Filters } from '../components/filters';
import calendar from '../static/images/calendar.svg'
import { Buttons } from '../components/filters/buttons';
import { CalendarRangePicker } from '../components/dateRangePicker';
import TableData from '../components/table/index';
import { filtersObj } from '../utils/filtersData';
import { fetchEventsV2, fetchMoreEventsV2 } from '../actions/eventsV2Actions';


const useStyles = makeStyles({
    paper: {
        padding: 15,
    },
    title: {
        marginLeft: 12,
    },
    calendarBnts: {
        marginLeft: '40%'
    },
    calendar: {
        backgroundColor: '#141B41',
        borderRadius: '8px',
        padding: 18,
        height: 48,
        width: 230,
        margin: '10px 12px',
        '&:hover': {
            backgroundColor: '#141B41',
        }
    },
    calendarText: {
        color: 'white',
        marginRight: 55
    },
    table: {
        padding: 13,
    }
});

const EventListV2 = ({ eventsV2Reducer, fetchEventsV2, fetchMoreEventsV2, onOpenDrawer, ...props }) => {
    const classes = useStyles()
    const permission = decodePermissions(getPermission());

    const [createDate, setCreateDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection"
        }
    ]);

    const [startDate, setStartDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection"
        }
    ]);

    const { events } = eventsV2Reducer;

    const [createDateMenu, setCreateDateMenu] = React.useState(null)
    const [startDateMenu, setStartDateMenu] = React.useState(null)

    const { eventsV2: { eventsV2List } } = tableData
    const { eventsV2Filters } = filtersObj
    const [filters, setFilters] = React.useState();


    useEffect(() => {
        if (permission.users) {
            fetchEventsV2();
        }
    }, [])

    const [defineFilters, setDefineFilters] = useState()

    const doSearch = (query, brandFilter, countryFilter, createDateFilter, startDateFilter) => {
        const data = {
            query,
            brandFilter,
            countryFilter,
            createDateFilter,
            startDateFilter
        }
        fetchEventsV2(data)
    }

    const handleCreateDateRange = (ranges) => {
        const { selection } = ranges;
        setCreateDate([selection]);
    }

    const handleStartDateRange = (ranges) => {
        const { selection } = ranges;
        setStartDate([selection]);
    }

    const handleChange = (filterName, value, id, itemName) => (event) => {
        setFilters(prevState => ({
            ...prevState,
            [itemName ? itemName : value]: {
              [filterName]: event.target.checked,
              value: itemName ? value : 'default',
              id,
            },
          }));
    }

    const filterNotSelected = filterName => {
        return Object.keys(filterName)?.some(item => !filterName[item]);
    };

    const applyFilters = () => {
        const createDateObj = {
            from: createDate[0]?.startDate?.setHours(0, 0, 0, 0),
            to: createDate[0]?.endDate?.setHours(23, 59, 59, 999)
        }
        const startDateObj = {
            from: startDate[0]?.startDate?.setHours(0, 0, 0, 0),
            to: startDate[0]?.endDate?.setHours(23, 59, 59, 999)
        }

        const shupperLocation = filters?.['shupper'] && filterNotSelected(filters?.['shupper']) ? '' : filters?.['shupper']?.id;
        const brandFilter = filters?.['brands']?.value;

        doSearch(null, brandFilter, shupperLocation, createDateObj, startDateObj)
    }

    return permission.users ?
        <div>
            <div className="App-header">
                <IconButton onClick={onOpenDrawer}>
                    <MenuIcon color='primary' />
                </IconButton>
                <span className="header-placeholder" />
            </div>
            <div className={classes.paper}>
                <Typography variant="h5" className={classes.title}>EVENTS</Typography>
                <div className="searchFilterContainer">
                    <div className='calendar'>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={e => setCreateDateMenu(e.currentTarget)}
                            aria-controls="profile-menu"
                            className={classes.calendar}
                        >
                            <img src={calendar} style={{ marginRight: 35 }} />
                            <div>
                                <Typography className={classes.calendarText}>Today</Typography>
                            </div>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            getContentAnchorEl={null}
                            open={Boolean(createDateMenu)}
                            anchorEl={createDateMenu}
                            onClose={() => setCreateDateMenu(null)}
                            disableAutoFocusItem
                            PaperProps={{
                                className: classes.filterMenu
                            }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}>
                            <div >
                                <CalendarRangePicker date={createDate} handleChange={handleCreateDateRange} />
                            </div>
                            <div className={classes.calendarBnts}>
                                <Buttons setFilterMenu={() => setCreateDateMenu(null)} applyFilters={applyFilters} date={createDate} />
                            </div>
                        </Menu>
                    </div>
                    <div className='calendar'>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={e => setStartDateMenu(e.currentTarget)}
                            aria-controls="profile-menu"
                            className={classes.calendar}
                        >
                            <img src={calendar} style={{ marginRight: 35 }} />
                            <div>
                                <Typography className={classes.calendarText}>Start Date</Typography>
                            </div>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            getContentAnchorEl={null}
                            open={Boolean(startDateMenu)}
                            anchorEl={startDateMenu}
                            onClose={() => setStartDateMenu(null)}
                            disableAutoFocusItem
                            PaperProps={{
                                className: classes.filterMenu
                            }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}>
                            <div >
                                <CalendarRangePicker date={startDate} handleChange={handleStartDateRange} />
                            </div>
                            <div className={classes.calendarBnts}>
                                <Buttons setFilterMenu={() => setStartDateMenu(null)} applyFilters={applyFilters} date={startDate} />
                            </div>
                        </Menu>
                    </div>
                    <div className='userFilters'>
                        <Filters
                            filters={filters}
                            name={'eventsv2'}
                            filterItems={eventsV2Filters}
                            applyFilters={applyFilters}
                            handleChange={handleChange}
                        />
                    </div>
                    <div className='userSearch' style={{ paddingTop: 12 }}>
                        <SearchField doneColor={''} onRequestSearch={query => doSearch(query, null, null, null, null)} />
                    </div>
                </div>
                <div className={`${classes.table} usersTable`} >
                    <TableData
                        search={defineFilters}
                        tableName='eventsV2'
                        hasMore={eventsV2Reducer.hasMore}
                        tablePagination={true}
                        isLoading={eventsV2Reducer.isLoading}
                        data={events}
                        headerColumn={eventsV2List}
                        totalResults={eventsV2Reducer.totalCount}
                        fetchMore={() => fetchMoreEventsV2()}
                    /></div>
            </div>
        </div >
        : <NoPermission onOpenDrawer={onOpenDrawer} />;
}

const mapStateToProps = ({ eventsV2Reducer }) => ({
    eventsV2Reducer
});

const mapDispatchToProps = dispatch => ({
    fetchEventsV2: (data) => dispatch(fetchEventsV2(data)),
    fetchMoreEventsV2: () => dispatch(fetchMoreEventsV2()),
    dispatch,
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventListV2));