/*
Roman Safonov
28/03/2018

Redux actions for collections list loading
*/
import types from './actionTypes';

export const fetchCollections = query => ({
  type: types.FETCH_COLLECTIONS,
  query,
});

export const fetchCollectionsSortChanged = (column, direction) => ({
  type: types.FETCH_COLLECTIONS_SORT_CHANGED,
  column,
  direction,
});

export const fetchMoreCollections = () => ({
  type: types.FETCH_MORE_COLLECTIONS,
});

export const fetchCollectionsSuccess = data => ({
  type: types.FETCH_COLLECTIONS_SUCCESS,
  data,
});

export const fetchCollectionsFailed = reason => ({
  type: types.FETCH_COLLECTIONS_FAILED,
  reason,
});

export const deleteCollections = ids => ({
  type: types.DELETE_COLLECTIONS,
  ids,
});

export const deleteCollectionsSuccess = () => ({
  type: types.DELETE_COLLECTIONS_SUCCESS,
});

export const deleteCollectionsFailed = reason => ({
  type: types.DELETE_COLLECTIONS_FAILED,
  reason,
});
