/*
Kateryna Ivanova
20/03/2018

Redux actions for a single event loading
*/
import types from './actionTypes';

export const fetchEvents = query => ({
  type: types.FETCH_EVENTS,
  query,
});

export const fetchEventsDateRangeChanges = (startDate, endDate) => ({
  type: types.FETCH_EVENTS_DATERANGE_CHANGED,
  startDate,
  endDate,
});

export const fetchMoreEvents = () => ({
  type: types.FETCH_MORE_EVENTS,
});

export const fetchEventsSuccess = data => ({
  type: types.FETCH_EVENTS_SUCCESS,
  data,
});

export const fetchEventsFailed = reason => ({
  type: types.FETCH_EVENTS_FAILED,
  reason,
});

export const fetchEventsSortChanged = (column, direction) => ({
  type: types.FETCH_EVENTS_SORT_CHANGED,
  column,
  direction,
});

export const fetchEventsFilterByType = (evtype, query) => ({
  type: types.FETCH_EVENTS_FILTER_BY_TYPE,
  evtype, query
});

export const reshuffleEvent = id => ({
  type: types.RESHUFFLE_EVENT,
  payload: {
    id,
  }
});

export const actionDone = () => ({
  type: types.ACTION_DONE,
});
