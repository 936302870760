import React from "react";
import IconButton from "material-ui/IconButton";

import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import NoPermission from "../components/nopermission";
import { connect } from "react-redux";
import Tabs from "../components/Tabs";
import { decodePermissions } from "../utils/permissionUtil";
import { getPermission } from "../utils/cookies";
import ImageViewer from "../components/imageviewer";
import VideoViewer from "../components/VideoViewer";
import Review from "../components/Review";
import getHandlers, { reviewListActions } from "../actions/brandReviewActions";
import { getProps, reviewListProps } from "../reducers/reviews";

import "../static/styles/brandReview.css";
import LinearProgress from "material-ui/LinearProgress";
import { withRouter } from "../utils/withRouter";

const statuses = ["pending", "approved", "declined"];

const ReviewListComponent = ({
    reviews,
    openVideo,
    openImage,
    loadMoreReviews,
    haveMore,
    brandId,
    activeStatus,
    skip,
    isLoading,
}) =>
    reviews && reviews.length ? (
        <React.Fragment>
            {reviews.map(
                (
                    {
                        video,
                        videoPreviewImg,
                        creator,
                        text,
                        image,
                        rating,
                        status,
                        createdAt,
                        _id,
                        brand,
                    },
                    index
                ) => (
                    <Review
                        videoUrl={video}
                        imageUrl={image}
                        brand={brand}
                        text={text}
                        date={createdAt}
                        reviewId={_id}
                        expandImage={openImage}
                        expandVideo={openVideo}
                        rating={rating}
                        from={creator}
                        brandId={brandId}
                        videoPreviewUrl={videoPreviewImg}
                        activeStatus={activeStatus}
                        index={index}
                    />
                )
            )}
            {haveMore ? (
                <div
                    className="column-item flxr ac jc red"
                    onClick={loadMoreReviews(brandId, activeStatus, skip)}
                >
                    Show More
                </div>
            ) : null}
        </React.Fragment>
    ) : isLoading ? (
        <div className="no-content">Loading...</div>
    ) : (
        <div className="no-content">No Reviews</div>
    );

const ReviewList = connect(
    reviewListProps,
    reviewListActions
)(ReviewListComponent);

class BrandReviewPage extends React.Component {
    constructor(props) {
        super(props);
        const permission = decodePermissions(getPermission());
        this.state = {
            permission,
            expandedImage: null,
            expandedVideo: null,
            videoPreview: null,
        };
    }
    componentDidMount() {
        const { params, setBrandId, loadReviewByBrand } = this.props;
        const { id } = params;
        setBrandId(id || "all");
        loadReviewByBrand(id || "all", "pending");
    }

    openImageViewer = (image) => () =>
        this.setState({ expandedImage: [{ src: image }] });

    closeImageViewer = () => this.setState({ expandedImage: null });

    openVideoViewer = (video, poster) =>
        this.setState({ expandedVideo: video, videoPreview: poster });

    closeVideoViewer = () => this.setState({ expandedVideo: null });

    changeActiveStatus = (id) => (status) => {

        this.props.changeActiveStatus(statuses[status], id);
    };

    render() {
        const {
            onOpenDrawer,
            brandId,
            activeStatus,
            isLoading,
            error,
            success,
            brandName,
            orientation,
        } = this.props;
        const { permission, expandedImage, expandedVideo, videoPreview } =
            this.state;
        return permission.canManageReviews ? (
            <div>
                <div className="App-header">
                    <IconButton onClick={() => this.props.navigate(-1)}>
                        <NavigationArrowBack color="white" />
                    </IconButton>
                </div>
                <div className="progressbar-container">
                    {isLoading && <LinearProgress mode="indeterminate" />}
                </div>
                <div className="page-title flxr ac jc">
                    {"Manage " +
                        (brandName ? '"' + brandName + '"' : "Brand") +
                        " Reviews"}
                </div>
                {success && (
                    <div className="message-box success-message">
                        {`Successfully saved`}
                    </div>
                )}
                {error && (
                    <div className="message-box error-message">
                        {`Error occurred`}
                    </div>
                )}
                <ImageViewer
                    isOpen={expandedImage}
                    images={expandedImage}
                    onClose={this.closeImageViewer}
                    orientation={orientation}
                />
                <VideoViewer
                    videoUrl={expandedVideo}
                    poster={videoPreview}
                    display={expandedVideo}
                    closeRequest={this.closeVideoViewer}
                />
                <div className="reviews-tabs flxc ac jc">
                    <Tabs
                        tabs={["Pending", "Approved", "Declined"]}
                        contents={
                            <div className="review-list-container">
                                <ReviewList
                                    openImage={this.openImageViewer}
                                    openVideo={this.openVideoViewer}
                                    activeStatus={activeStatus}
                                    brandId={brandId}
                                    isLoading={isLoading}
                                />
                            </div>
                        }
                        width="80%"
                        onChange={this.changeActiveStatus(brandId)}
                    />
                </div>
            </div>
        ) : (
            <NoPermission onOpenDrawer={onOpenDrawer} />
        );
    }
}

export default withRouter(connect(getProps, getHandlers)(BrandReviewPage));
