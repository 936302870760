export function getNormalizeLng(l) {
  let lng = l;
  if (lng > 180) {
    while (lng > 180) {
      lng -= 360;
    }
    return lng;
  }
  while (lng < -180) {
    lng += 360;
  }
  return lng;
}

export function getLocIfPermissionGranted() {
  return new Promise((resolve, reject) =>
    (navigator.permissions
      ? navigator.permissions.query({ name: 'geolocation' }).then(
        permission =>
          (permission.state === 'granted'
            ? navigator.geolocation.getCurrentPosition(pos => resolve(pos.coords))
            : resolve(null)),
        reject,
      )
      : reject(new Error('Permission API is not supported'))));
}

export const capitalize = str => str && str[0].toUpperCase() + str.slice(1);

export const checkPermissionByStatus = (payment, permission) =>
  payment ?
    permission[`see${capitalize(payment.status)}`] :
    false

export const validateUrl = text => /(https?:\/\/[^\s]+)/gi.test(text);

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/gi;
  return text.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  })
};

export const highlight = (text, _id, foundMessagesList, searchedText) => {
  if (foundMessagesList && foundMessagesList.includes(_id)) {
    const textRegex = new RegExp(searchedText, 'gi');
    return text.replace(textRegex, highlighted =>
      '<span class="highlighted-search">' + highlighted + '</span>'
    )
  } else return text;
};

export const ensureValue = (path, obj, defaultVal = null) => path.split('.').reduce((acc, val) => acc ? acc[val] : defaultVal, obj);



export const getParamsFromUrl = (url, positions = {}) => {
  const params = url.split(/\//);
  const keys = Object.keys(positions.length);
  return keys.length ? keys.reduce((acc, val) => ({ ...acc, [val]: ensureValue(`params.${positions[val]}`) }), {}) : ({ param: params[params.length - 1] || null })
};
