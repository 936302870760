import {takeLatest, put, call, all} from 'redux-saga/effects';
import types from '../actions/actionTypes';
import {
  setLoadBrandError,
  setReviewList,
  prependReviewList,
  setSaveReviewSuccess,
  setNewImage,
  setBrandName,
} from '../actions/brandReviewActions';
import request from '../utils/networkRequest';
import getBaseUrl from '../utils/envSetup';
import uploadImage from '../utils/networkutil';

const baseUrl = getBaseUrl();

function* fetchReviewsByBrand({payload}) {
  const response = yield call(
    request,
    `${baseUrl}/api/reviews/${payload.id}?${payload.status ? 'status=' + payload.status : ''}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.err) {
    return put(setLoadBrandError(response.Error));
  }
  yield put(setBrandName(payload.id !== 'all' || !payload.id ? response.data.brandName : 'All Brands'));
  yield put(
    setReviewList(response.data.reviews || [], response.data.reviews.length >= 20, response.data.reviews.length),
  );
}

function* lazyLoadReviewsByBrand({payload}) {
  const skip = payload.skip;
  const response = yield call(
    request,
    `${baseUrl}/api/reviews/${payload.id}?skip=${skip}${payload.status ? '&status=' + payload.status : ''}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.Error) {
    return put(setLoadBrandError(response.Error));
  }
  yield put(
    prependReviewList(response.data.reviews || [], response.data.reviews.length >= 20, response.data.reviews.length),
  );
}

function* _uploadPopupImage(reviewId, icon) {
  const response = yield call(uploadImage, 'review', reviewId, icon);
  if (response.err) {
    return put(setLoadBrandError('Upload image problem.'));
  }
  return response.data.imgUrl;
}

function* updateReview({payload}) {
  let imageUrl;
  if (payload.data && (payload.data.image || payload.data.image === '')) {
    if (payload.data.image) {
      imageUrl = yield _uploadPopupImage(payload.reviewId, payload.data.image);
    }
    if (payload.data.image === '') imageUrl = '';
    yield put(setNewImage(payload.key, imageUrl));
  }

  const response = yield call(request, `${baseUrl}/api/reviews/${payload.reviewId}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status: payload.status || null,
      text: payload.data ? payload.data.text : null,
      rating: payload.data ? payload.data.rating : null,
      imageUrl: imageUrl || imageUrl === '' ? imageUrl : null,
      video: payload.data && payload.data.video === '' ? payload.data.video : null,
      videoPoster: payload.data && (payload.data.videoPoster || '') === '' ? payload.data.videoPoster : null,
    }),
  });
  if (response.Error) {
    return put(setLoadBrandError(response.Error));
  } else yield put(setSaveReviewSuccess());
}

function* watchFetchReviewsByBrand() {
  yield takeLatest([types.LOAD_REVIEWS_BY_BRAND, types.CHANGE_STATUS_OF_ACTIVE_REVIEW], fetchReviewsByBrand);
}

function* watchUpdateReview() {
  yield takeLatest([types.UPDATE_REVIEW_STATUS, types.UPDATE_REVIEW_FIELDS], updateReview);
}

function* watchListLazyLoad() {
  yield takeLatest(types.LOAD_MORE_REVIEWS, lazyLoadReviewsByBrand);
}

export default function* eventSaga() {
  yield all([watchUpdateReview(), watchFetchReviewsByBrand(), watchListLazyLoad()]);
}
