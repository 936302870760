import React, { useEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import '../static/styles/userlist.css';
import { Grid, IconButton } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { connect } from 'react-redux';
import { fetchItemV2 } from '../actions/itemV2Actions';
import { withRouter } from '../utils/withRouter';
import ItemDetails from '../components/item/ItemDetails';
import ItemMedia from '../components/item/ItemMedia';

const ItemV2 = ({itemV2Reducer, fetchItemV2, onOpenDrawer, params, ...props}) => {
  const { id } = params
  useEffect(() => {
    fetchItemV2({ id })
  }, [id])

  const item = itemV2Reducer?.item

  return <div>
    <div className="App-header">
      <IconButton onClick={onOpenDrawer}>
        <MenuIcon color='primary' />
      </IconButton>
      <span className="header-placeholder" />
    </div>
    <div className="paymentRequest-wrapper">
      <Grid
        container 
        direction="row"
        justifyContent="space-between"
        spacing={2}
        xs={12}
        style={{ paddingRight: '0px' }}
      >
        <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
          <ItemDetails item={ item } />
        </Grid>
        <Grid item lg={6} xl={6} md={6} sm={6} xs={12} >
          <ItemMedia itemId={item?.id} item={ item } />
        </Grid>
      </Grid>
    </div>
  </div>
};

const mapStateToProps = ({itemV2Reducer}) => ({
  itemV2Reducer,
});

const mapDispatchToProps = dispatch => ({
  fetchItemV2: data => dispatch(fetchItemV2(data)),
  dispatch,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemV2));
