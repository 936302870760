import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Archived from 'material-ui/svg-icons/content/archive';
import Flag from 'material-ui/svg-icons/content/flag';
import EmptyBubble from 'material-ui/svg-icons/communication/chat-bubble-outline';
import { config } from '../../utils/envSetup';
import { chatListActions } from '../../actions/chatsActions';
import { chatListSelector } from '../../reducers/chatsReducer';
import AutoRenew from 'material-ui/svg-icons/action/autorenew';
const ITEMS_PER_DATA_SET = config('chatsItemsPerPage');

const handleLazyLoadChats = (handler, skip, chatsLimit, filters, shouldChatsLoad) => () => {
	handler(filters.friendlyName, filters.date, filters.support,
		skip + ITEMS_PER_DATA_SET, true, shouldChatsLoad, chatsLimit, skip, filters.inMessages)();
};

const checkLastMessage = (chat, userRoles) => chat.lastMessage && userRoles[chat.lastMessage.from.identity] &&
	userRoles[chat.lastMessage.from.identity].length === 1 && chat.lastMessage.flagged;

const CounterCaption = ({ text, count, display }) =>
	display ?
		<React.Fragment>
			{text}
			({count})
		</React.Fragment> : null;

const ChatsList = ({ chatList, selectNewChat = () => { }, socket, loadChatsList,
	filters, skip, shouldChatsLoad, lastMessageFrom, openUnflagDialog,
	totalCount, chatsLimit,
}) => {
	const navigate = useNavigate()
	return (
		<React.Fragment>
			<div className="column-title flxr ac">
				<AutoRenew
					className="custom-button"
					onClick={
						loadChatsList(filters.friendlyName, filters.date, filters.support, 0, false, true, chatsLimit, skip, filters.inMessages)
					}
				/>
				<CounterCaption display={!filters.flaggedOnly} count={totalCount} text="Chat channels: " />
			</div>
			{
				chatList && chatList instanceof Object && Object.keys(chatList).length ?
					[
						Object.keys(chatList).map(value => (

							(!filters.flaggedOnly || checkLastMessage(chatList[value], lastMessageFrom)) ?
								<div
									className={`column-item flxc jc ac`}
									key={value}
									onClick={() => {
										navigate(`/chat/${chatList[value].uniqueName}`);
										selectNewChat(value, chatList[value].uniqueName, socket, ...chatList[value].users.map(({ friendlyName }) => friendlyName), chatList[value].users)();

									}}
								>
									<div className="user-data flxr jsb ac">
										<div className="user-avatar">
											<img
												src={chatList[value].users[0] && chatList[value].users[0].attributes ? chatList[value].users[0].attributes.profileImageUrl : ''}
												alt=""
												className="avatar"
											/>
										</div>
										<div className="user-pair-str">
											{chatList[value].users[0] && chatList[value].users[0].friendlyName}
											{
												chatList[value].users[0] && chatList[value].users[0].online ?
													<div className="online-circle" />
													: null
											}
											{
												checkLastMessage(chatList[value], lastMessageFrom) ?
													<Flag
														onDoubleClick={openUnflagDialog(chatList[value].lastMessage || null, value)}
														onClick={e => e.stopPropagation()}
													/>
													: null
											}
											{
												chatList[value].hiddenFor && chatList[value].hiddenFor.length ?
													<Archived style={{
														color: "black",
														marginLeft: '10px',
														width: '17px',
														height: '17px'
													}} />
													: null
											}
											{
												chatList[value].messages === 0 ? <EmptyBubble style={{
													marginLeft: '10px',
													width: '17px',
													height: '17px',
													color: 'blue'
												}} /> : null
											}
											<br /><img src={require('../../static/images/double_arrow.svg')} alt="" /> <br />
											{chatList[value].users[1] && chatList[value].users[1].friendlyName}
											{
												chatList[value].users[1] && chatList[value].users[1].online ? <div className="online-circle" />
													: null
											}
										</div>
										<div className="user-avatar">
											<img
												src={chatList[value].users[1] && chatList[value].users[1].attributes ? chatList[value].users[1].attributes.profileImageUrl : ''}
												alt="" className="avatar" />
										</div>
									</div>
									<div
										className='last-message-time'>{moment(chatList[value].updatedAt).format('lll')}</div>
								</div> : null
						)),
						shouldChatsLoad ? <div className="column-item flxr ac jc red"
							onClick={handleLazyLoadChats(loadChatsList, skip, chatsLimit, filters, shouldChatsLoad)}>
							Show More
						</div> : null
					] : null
			}
		</React.Fragment>
	);
}
export default connect(chatListSelector, chatListActions)(ChatsList);