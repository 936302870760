

import React from 'react';
import FlatButton from 'material-ui/FlatButton';



const AddBrand = ({ brand, handleAddBrand, selectedBrands }) => (
    <div className="row add-brands-item">
        <div>
            <img src={brand.imageUrl} className="add-brands-image" alt="" />
            <span >{brand.title}</span>
        </div>
        {selectedBrands.map(item => item._id).indexOf(brand._id) === -1 ?
            <FlatButton label="Add" primary onClick={() => handleAddBrand(brand)} />
            : <FlatButton label="Added" primary onClick={() => handleAddBrand(brand)} disabled />}
    </div>
)


export default (AddBrand);
