import React from 'react';
import { Button, Grid, Typography, Paper } from '@material-ui/core';
import { convertUsersCountryToString, convertZoneToString } from '../../utils/countriesAndZones';
import './index.css';
import { useNavigate } from 'react-router';


const ShippingAddress = ({ address }) => {
  const navigate = useNavigate()
  const getCountry = convertUsersCountryToString(address?.country_id)
  const getZone = convertZoneToString(Number(address?.zone_id))


  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" className="shipping-address-title-wrapper">
        <Typography variant="h5">
          Shipping Address
        </Typography>
        <Typography variant="h6" color="primary" className="shipping-address-edit" onClick={() => navigate(`/profile/edit-shipping/${address?.user_id}`)}>
          Edit
        </Typography>
      </Grid>
      <Paper className="shipping-address-wrapper">
        <Grid container xs={12} justifyContent="space-between">
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">First Name</Typography>
            <Typography className="shipping-address-text">{address?.first_name ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Last Name</Typography>
            <Typography className="shipping-address-text">{address?.last_name ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Country</Typography>
            <Typography className="shipping-address-text">{getCountry?.[0]?.name ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">State</Typography>
            <Typography className="shipping-address-text">{(address?.state_id && getZone?.[0]?.name) ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">City</Typography>
            <Typography className="shipping-address-text">{address?.city ?? '-'}</Typography>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">ZIP CODE</Typography>
            <Typography className="shipping-address-text">{address?.zip_code ?? '-'}</Typography>
          </Grid>

          { address?.address1 && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Address 1</Typography>
            <Typography className="shipping-address-text">{address?.address1 ?? '-'}</Typography>
          </Grid> }

          { address?.address2 && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Address 2</Typography>
            <Typography className="shipping-address-text">{address?.address2 ?? '-'}</Typography>
          </Grid> }

          { address?.street && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Street</Typography>
            <Typography className="shipping-address-text">{address?.street ?? '-'}</Typography>
          </Grid> }

          { address?.apartment && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Apartment</Typography>
            <Typography className="shipping-address-text">{address?.apartment ?? '-'}</Typography>
          </Grid> }

          { address?.building && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Building</Typography>
            <Typography className="shipping-address-text">{address?.building ?? '-'}</Typography>
          </Grid> }

          { address?.enterance && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Entrance</Typography>
            <Typography className="shipping-address-text">{address?.enterance ?? '-'}</Typography>
          </Grid> }

          { address?.identification_number && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Identification Number</Typography>
            <Typography className="shipping-address-text">{address?.identification_number ?? '-'}</Typography>
          </Grid> }

          { address?.address_notes && <Grid xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className="shipping-address-titles">Address Notes</Typography>
            <Typography className="shipping-address-text">{address?.address_notes ?? '-'}</Typography>
          </Grid> }
        </Grid>
      </Paper>
    </div>
  );
};

export default ShippingAddress;
