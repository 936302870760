/*
Kateryna Ivanova
21/03/2018

Component for picking and image
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import './image-picker.css';

class ImagePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      MAX_FILE_SIZE: 2000000, // bytes
      modalOpen: false
    };
  }

  pictureChange(ev) {
    const { MAX_FILE_SIZE } = this.state;
    const { onImageSelected, onFileRead, onError } = this.props;
    if (ev.target.files[0] && (ev.target.files[0].size > MAX_FILE_SIZE)) {
      this.setState({
        error: `File can't be bigger than ${MAX_FILE_SIZE / 1000000}mb`,
      });
      onError();
    } else if (ev.target.files[0]) {
      this.setState({
        error: '',
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        onFileRead(e.target.result);
      };
      onImageSelected(ev.target.files[0]);
      reader.readAsDataURL(ev.target.files[0]);
    }
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { url, image, placeholderUrl, className, imageClass, disabled ,notShow} = this.props;
    const { modalOpen, error } = this.state;
    return (
      <div className={`image-picker-container ${className || ''}`}>
        <img
          src={image || url || placeholderUrl}
          alt=""
          className={`${imageClass || 'image-picker-img'}`}
          onClick={this.onOpenModal}
        /><br />
        {!notShow?<input
          disabled={disabled}
          type="file"
          name="storeImage"
          accept="image/*"
          className="im-picker-input-file"
          ref={(input) => { this.fileName = input; }}
          onChange={ev => this.pictureChange(ev)}
        />:null}
        {
          error &&
          <span className="image-picker-error">
            {error}
          </span>
        }
        <Dialog
          modal={false}
          open={modalOpen}
          onRequestClose={this.onCloseModal}
          contentStyle={{ width: '650px' }}
        >
          <img
            alt=""
            src={image || url || placeholderUrl}
            width={600}
            height={600}
          />
        </Dialog>
      </div>);
  }
}


ImagePicker.propTypes = {
  url: PropTypes.string,
  image: PropTypes.any,
  onImageSelected: PropTypes.func.isRequired,
  onFileRead: PropTypes.func.isRequired,
  placeholderUrl: PropTypes.string,
  onError: PropTypes.func.isRequired,
};

export default ImagePicker;
