import types from './actionTypes';

export const fetchCountries = () => ({
  type: types.FETCH_COUNTRIES,
});

export const fetchCountrySuccess = data => ({
  type: types.FETCH_COUNTRIES_SUCCESS,
  data,
});
export const fetchCountryFailed = reason => ({
  type: types.FETCH_COUNTRIES_FAILED,
  reason,
});

export const fetchZones = () => ({
  type: types.FETCH_ZONES,
});

export const fetchZonesSuccess = data => ({
  type: types.FETCH_ZONES_SUCCESS,
  data,
});
export const fetchZonesFailed = reason => ({
  type: types.FETCH_ZONES_FAILED,
  reason,
});
