import {userLocalStorage} from '../config/local_storage';
import {zones} from './zones';
import {currencies} from './currencies';
import {countries} from './countries';

export const convertZoneToString = countryNum => {
  const getCountry = zones?.filter(country => country?.id === countryNum);
  return getCountry;
};

export const convertCountryToString = countryNum => {
  const getCountry = countries.filter(country => country?.zone === countryNum);
  return getCountry;
};

export const convertCurrencyToString = currencyId => {
  const getCurrency = currencies.filter(currency => currency.id === currencyId);
  return getCurrency;
};

export const convertUsersCountryToString = countryNum => {
  const getUsersCountry = countries.filter(country => country?.id === countryNum);
  return getUsersCountry;
};
