

import React from 'react';
import FlatButton from 'material-ui/FlatButton';



const ShowBrand = ({ brand, handleDeleteBrands }) => (
    <div className="show-brand">
        <img src={brand.imageUrl} alt={brand.imageUrl} className="add-brands-image" />
        <span>{brand.title}</span>
        <FlatButton className="show-brand-button" label="X" default onClick={() => handleDeleteBrands(brand)} />
    </div>
)


export default (ShowBrand);
