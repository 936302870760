/**
 * Created by Slava Kravchina
 * NodeJs Developer
 * on 23/04/2018.
 */
/* eslint-disable no-restricted-globals */
export default function (cb, alertText = 'Are you sure?') {
  const isSure = confirm(alertText);
  if (isSure) {
    cb();
  }
}
